import { useEffect, useState } from 'react';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';
import { removeDuplicates } from '@/utils/removeDuplicateItemsArr';
import { ContainerQueueMonitor } from './styled';
import { errorToast } from '@/components/FormComponents/Toast';
import { useTranslation } from 'react-i18next';
import { useSocketQueueMonitor } from './hooks/useSocketQueueMonitor';
import { PanelQueuesMonitor } from './components/PanelQueuesMonitor';
import { QueuesTable } from './components/QueuesTable';
import { DetailQueues } from './components/DetailsQueue';
import { useAtom } from 'jotai';
import { selectedQueueMonitor } from '@/store/QueueMonitor';

export const QueueMonitor = () => {
  const { t } = useTranslation();
  const [fieldFilter, setFieldFilter] = useState('');
  const [, setSelectedQueue] = useAtom(selectedQueueMonitor);

  const { dataQueueMonitor, isConnected, hasErrorSocket, setHasErrorSocket } =
    useSocketQueueMonitor();

  useEffect(() => {
    setSelectedQueue(null);
  }, [fieldFilter]);

  useEffect(() => {
    return () => {
      setSelectedQueue(null);
    };
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (dataQueueMonitor.length === 0) {
        setHasErrorSocket(true);
        errorToast(t('queueMonitor.msg-error-socket'));
      }
    }, 8000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [dataQueueMonitor]);

  const filterQueuesMonitor = (dataFilter) => {
    if (dataFilter) {
      const filterByQueue = dataFilter.filter((data) =>
        data.fila.toLowerCase().includes(fieldFilter.toLowerCase())
      );
      const filterByTitle = dataFilter.filter((data) =>
        data.titulo.toLowerCase().includes(fieldFilter.toLowerCase())
      );
      const filterByCampaign = dataFilter.filter((data) =>
        data.campanha_descricao
          .toLowerCase()
          .includes(fieldFilter.toLowerCase())
      );
      const filterByList = dataFilter.filter((data) =>
        data.lista.toLowerCase().includes(fieldFilter.toLowerCase())
      );

      const filterByOperator = dataFilter.filter((data) =>
        data.operadora.toLowerCase().includes(fieldFilter.toLowerCase())
      );

      const newArrFiltered = [
        ...filterByQueue,
        ...filterByTitle,
        ...filterByCampaign,
        ...filterByList,
        ...filterByOperator,
      ];
      return removeDuplicates(newArrFiltered);
    }
  };

  const isLoading =
    (!isConnected || dataQueueMonitor.length === 0) && !hasErrorSocket;

  return (
    <ContainerQueueMonitor>
      {isLoading && <BackDropCircularLoading />}

      <PanelQueuesMonitor
        fieldFilter={fieldFilter}
        setFieldFilter={setFieldFilter}
      />

      <DetailQueues dataList={filterQueuesMonitor(dataQueueMonitor)} />

      <QueuesTable dataTable={filterQueuesMonitor(dataQueueMonitor)} />
    </ContainerQueueMonitor>
  );
};
