import { atom } from 'jotai';

export const stageFlow = atom(0);
export const templateSelected = atom({});
export const listTemplates = atom([]);
export const showMenuNodes = atom(false);
export const typeNewNode = atom(null);
export const idNodeToRemove = atom(null);

export const dataNodeErrorConnect = atom(null);

export const adminClientIdSelected = atom('0');

export const variablesList = atom([]);
export const variablesGlobal = atom([]);
export const dataDispositions = atom([]);
export const dataNlus = atom([]);

export const selectedNode = atom(null);

export const errorFieldsDataApi = atom([]);

export const isSavedFlow = atom(true);

export const confirmationChangeFlow = atom(false);

export const focusedFieldArea = atom(false);

export const lastUserUpdate = atom('');

export const hasModalOpen = atom(false);

export const voicesOptions = atom([
  {
    name: 'Donato',
    value: 'pt-BR-DonatoNeural',
    url: 'https://377f-2603-3010-1047-0-f125-4550-1dc5-8b44.ngrok-free.app/1123.wav',
  },
  {
    name: 'Antonio',
    value: 'pt-BR-AntonioNeural',
    url: 'https://377f-2603-3010-1047-0-f125-4550-1dc5-8b44.ngrok-free.app/1123.wav',
  },
  {
    name: 'Francisca',
    value: 'pt-BR-FranciscaNeural',
    url: 'https://377f-2603-3010-1047-0-f125-4550-1dc5-8b44.ngrok-free.app/1123.wav',
  },
  {
    name: 'Giovanna',
    value: 'pt-BR-GiovannaNeural',
    url: 'https://377f-2603-3010-1047-0-f125-4550-1dc5-8b44.ngrok-free.app/1123.wav',
  },
  {
    name: 'Humberto',
    value: 'pt-BR-HumbertoNeural',
    url: 'https://377f-2603-3010-1047-0-f125-4550-1dc5-8b44.ngrok-free.app/1123.wav',
  },
  {
    name: 'Julio',
    value: 'pt-BR-JulioNeural',
    url: 'https://377f-2603-3010-1047-0-f125-4550-1dc5-8b44.ngrok-free.app/1123.wav',
  },
  {
    name: 'Nico',
    value: 'pt-BR-NicolauNeural',
    url: 'https://377f-2603-3010-1047-0-f125-4550-1dc5-8b44.ngrok-free.app/1123.wav',
  },
]);
