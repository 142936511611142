import { useAtom } from 'jotai';
import { TitleComponent } from '@/components/layout/TitleComponent';
import { ContainerHomeClients } from './styled';
import { InputComponent } from '@/components/FormComponents/InputComponent';
import { BiSearch } from 'react-icons/bi';
import { InputAdornment, Stack } from '@mui/material';
import { ButtonStyled } from '@/components/FormComponents/ButtonComponent';
import { AiOutlineUserAdd } from 'react-icons/ai';
import { TableClients } from './TableClients';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';
import { useClients } from '@/hooks/Clients/useClients';
import { currentFlowClients, dataListClients } from '@/store/Clients';
import { removeDuplicates } from '@/utils/removeDuplicateItemsArr';
import { useDeferredValue, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NoPermissionCard } from '@/components/layout/NoPermissionCard';
import { usePermissionsClients } from '../../hooks/usePermissionsClients';

export const HomeClients = () => {
  const { t } = useTranslation();
  const { isSysAdmin } = usePermissionsClients();

  const { isLoadingDataClients, fetchingDataClients } = useClients(isSysAdmin);
  const [, setStateRenderClients] = useAtom(currentFlowClients);
  const [dataList] = useAtom(dataListClients);

  const [searchClient, setSearchClient] = useState('');
  const deferredSearchClient = useDeferredValue(searchClient);

  const filterAllTableUsers = (dataFilter) => {
    if (dataFilter) {
      const filterTableComercialName = dataFilter.filter((data) =>
        data.commercial_name
          .toLowerCase()
          .includes(deferredSearchClient.toLowerCase())
      );
      const filterTableTaxNumber = dataFilter.filter((data) =>
        data.tax_number
          .toLowerCase()
          .includes(deferredSearchClient.toLowerCase())
      );
      const filterTableEmail = dataFilter.filter((data) =>
        data.contact_email
          .toLowerCase()
          .includes(deferredSearchClient.toLowerCase())
      );
      const filterTableAddress = dataFilter.filter((data) =>
        data.address.toLowerCase().includes(deferredSearchClient.toLowerCase())
      );
      const filterTableZipCode = dataFilter.filter((data) =>
        data.zipcode.toLowerCase().includes(deferredSearchClient.toLowerCase())
      );

      const newArrFiltered = [
        ...filterTableComercialName,
        ...filterTableTaxNumber,
        ...filterTableEmail,
        ...filterTableAddress,
        ...filterTableZipCode,
      ];
      return removeDuplicates(newArrFiltered);
    }
  };

  const filteredData = filterAllTableUsers(dataList);

  const isLoading = fetchingDataClients || isLoadingDataClients;

  return (
    <ContainerHomeClients initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      {isLoading && <BackDropCircularLoading />}
      <TitleComponent
        textTitle={t('clients.homeClients.title-clients')}
        iconTitle='client'
      />

      {!isSysAdmin ? (
        <NoPermissionCard message={t('clients.homeClients.table.no-data')} />
      ) : (
        <>
          <InputComponent
            placeholder={t('clients.homeClients.label-searchClient')}
            value={searchClient}
            onChange={(e) => setSearchClient(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <BiSearch />
                </InputAdornment>
              ),
            }}
          />
          <div className='content_btn_new_client'>
            <ButtonStyled
              size={'small'}
              onClick={() => setStateRenderClients(1)}
            >
              <Stack direction={'row'} alignItems={'center'} spacing={1}>
                <AiOutlineUserAdd size={16} />
                <span>{t('clients.homeClients.btn-addClients')}</span>
              </Stack>
            </ButtonStyled>
          </div>
          <TableClients dataTable={filteredData} />
        </>
      )}
    </ContainerHomeClients>
  );
};
