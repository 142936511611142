import { Dialog, DialogContent } from '@mui/material';
import { useTheme } from 'styled-components';

export const DialogComponent = ({ ...props }) => {
  const theme = useTheme();
  return (
    <Dialog {...props}>
      <DialogContent
        sx={{
          padding: '0px 1rem',
          background: `${theme.colors.containers.colorCard}`,
        }}
      >
        {props.children}
      </DialogContent>
    </Dialog>
  );
};

export const DialogMenu = ({ ...props }) => {
  return <Dialog {...props}>{props.children}</Dialog>;
};
