import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import { ItemContainer } from './styled';
import { verifyIcon } from '../iconVerify';
import {
  currentPageId,
  hasSubItem,
  idSubItem,
  isCloseItem,
  isReload,
} from '@store/NavigationDashboard';

export const ItemMenu = ({
  itemId,
  itemIcon,
  itemName,
  itemAlias,
  link,
  hasExternalUrl,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const matches = useMediaQuery('(max-width:1020px)');

  const [currentPage, setCurrentId] = useAtom(currentPageId);
  const [, setSubItemId] = useAtom(idSubItem);
  const [, setHaveSubItem] = useAtom(hasSubItem);
  const [, setIsReload] = useAtom(isReload);
  const [, setIsClosed] = useAtom(isCloseItem);

  const reloadPage = async () => {
    setIsReload(true);
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(setIsReload(false));
      }, 5);
    });
  };

  const handlePageMainDashboard = (itemId) => {
    if (itemId === currentPage) {
      reloadPage();
    }
    setCurrentId(itemId);
    setHaveSubItem(false);
    setSubItemId(false);
    if (matches) {
      setIsClosed(true);
    }
  };

  const renderNameItem = (hasExternalUrl, itemName, itemAlias) => {
    const renderName = t(`dashboard.${itemName}`);

    if (renderName === `dashboard.${itemName}`) {
      return <span>{itemName}</span>;
    }

    const checkName = renderName.split('.');
    if (hasExternalUrl) {
      return <span>{itemName}</span>;
    }
    if (checkName.length === 2) {
      return <span>{itemAlias}</span>;
    }

    if (renderName === `dashboard.${itemName}`) {
      return itemName;
    }

    return <span>{renderName}</span>;
  };

  if (!link) {
    return (
      <>
        <ItemContainer
          onClick={() => handlePageMainDashboard(itemId)}
          aria-label={itemName}
          tabIndex={0}
        >
          {verifyIcon(itemIcon)}
          {renderNameItem(hasExternalUrl, itemName, itemAlias)}
        </ItemContainer>
      </>
    );
  } else {
    return (
      <>
        <ItemContainer
          onClick={() => navigate(link)}
          aria-label={itemName}
          tabIndex={0}
        >
          {verifyIcon(itemIcon)}
          {renderNameItem(hasExternalUrl, itemName, itemAlias)}
        </ItemContainer>
      </>
    );
  }
};
