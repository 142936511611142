import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ContainerStatusDiscing = styled(motion.div)`
  width: 100%;
  height: 100%;

  display: grid;
  grid-template-rows: 70px 1fr;
  justify-items: center;
  gap: 3rem;

  @media (max-width: 500px) {
    grid-template-rows: 80px 1fr;
  }
`;

export const ContentPanelDiscing = styled(motion.div)`
  width: 100%;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 1rem;

  @media (max-width: 500px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .filterQueueContent {
    width: 100%;
  }

  .sortStatusQueue {
    width: 100%;
    max-width: 278px;
  }

  button {
    height: 57%;
    padding: 0;
    margin-top: -4px;

    @media (max-width: 500px) {
      width: 100%;
      grid-column: 1/3;
      height: 30px;
    }
  }
`;

export const ContentStatusQueueDialing = styled(motion.div)`
  width: 100%;
  flex: 1;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  justify-content: space-evenly;
  align-content: flex-start;
  align-items: flex-start;
  gap: 1rem;
`;
