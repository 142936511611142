import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ContainerAddClient = styled(motion.div)`
  width: 100%;
  height: auto;
  overflow-y: auto;
  padding: 2rem 2rem;
`;

export const FormAddClient = styled.form`
  width: 100%;
  height: calc(100vh - 120px);
  display: grid;
  grid-template-rows: minmax(auto, 30px) 1fr 34px;
`;

export const MainAddClient = styled.div`
  padding: 2rem 0;
  width: 100%;
  height: 100%;
  max-height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 0.6rem;
`;

export const FooterAddClient = styled.div`
  width: 100%;
  height: 100%;
  min-height: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 1rem;

  @media (max-height: 768px) {
    padding-bottom: 10px;
  }
`;

export const ColorRadioSpan = styled.span`
  color: ${(props) => props.theme.colors.typography.baseText} !important;
  font-size: 0.9rem;
  font-weight: 500;
`;
