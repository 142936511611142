import React, { memo } from 'react';
import { Handle, Position } from 'reactflow';
import { MainNodeContainer, NodeInitContainer } from './styled';
import logoVoicce from '@/assets/imgs/voice_logo_flow.svg';
import logoVoicceWhite from '@/assets/imgs/voice_logo_flow_white.svg';
import { ImageComponent } from '@/components/layout/ImageComponent';
import { useTheme } from 'styled-components';

const InitNodeComponent = ({ data, selected }) => {
  const theme = useTheme();

  const isThemeLight = theme.title === 'Light';

  return (
    <NodeInitContainer
      selected={selected}
      errorConnect={data.errorConnect ? 1 : 0}
    >
      <MainNodeContainer>
        {isThemeLight ? (
          <ImageComponent src={logoVoicce} width={'100px'} height={'50px'} />
        ) : (
          <ImageComponent
            src={logoVoicceWhite}
            width={'100px'}
            height={'50px'}
          />
        )}
      </MainNodeContainer>
      <Handle type='source' position={Position.Right} />
    </NodeInitContainer>
  );
};

export const InitNode = memo(InitNodeComponent);
