import { useRef } from 'react';
import { useAtom } from 'jotai';

import { ImageComponent } from '@components/layout/ImageComponent';
import { InfoUserComponent } from '@components/pages/Dashboard/InfoUserComponent';
import { isCloseItemAgents } from '@store/NavigationAgentsDashboard';
import { InformationItemAgents } from '@components/pages/DashboardAgents/InformationItemAgents';

import {
  ContainerInformationItem,
  ContainerSidebarAgents,
  ContentSidebarAgents,
  DividerXAgent,
  HeaderSidebarAgents,
} from './styled';

import LogoSidebarImg from '@/assets/imgs/sm_voiccelabs_black.webp';
import LogoWhiteSidebarImg from '@/assets/imgs/sm_voiccelabs_black.webp';

import { useTheme } from 'styled-components';

export const SideBarAgent = ({ stateSidebar }) => {
  const theme = useTheme();
  const sidebarRef = useRef(null);

  const [isClose] = useAtom(isCloseItemAgents);

  const itemsStatus = isClose ? 0 : 1;
  const stateItemSidebar = 0;

  return (
    <ContainerSidebarAgents
      animate={{ width: `${stateSidebar}px` }}
      transition={{ duration: 0 }}
    >
      <ContentSidebarAgents
        ref={sidebarRef}
        animate={{ width: `${stateSidebar}px` }}
        transition={{ duration: 0 }}
      >
        <HeaderSidebarAgents
          animate={{
            x: `${stateItemSidebar}px`,
            opacity: itemsStatus,
          }}
          transition={{ duration: 0 }}
        >
          {theme.title === 'Light' ? (
            <ImageComponent
              src={LogoSidebarImg}
              width={'257px'}
              height={'71px'}
            />
          ) : (
            <ImageComponent
              src={LogoWhiteSidebarImg}
              width={'257px'}
              height={'71px'}
            />
          )}
        </HeaderSidebarAgents>
        <InfoUserComponent
          stateItem={stateItemSidebar}
          itemsStatus={itemsStatus}
          imgUser={''}
        />
        <DividerXAgent />
        <ContainerInformationItem>
          <InformationItemAgents />
        </ContainerInformationItem>
      </ContentSidebarAgents>
    </ContainerSidebarAgents>
  );
};
