import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

export default function useFormControl(dataSchema) {
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    getValues,
    resetField,
    watch,
  } = useForm({
    resolver: yupResolver(dataSchema),
  });

  return {
    handleSubmit,
    control,
    errors,
    reset,
    getValues,
    resetField,
    watch,
  };
}
