import { SimpleTable } from '@/components/Tables/SimpleTable';
import { useMemo } from 'react';
import { ContainerTableVarsApi } from './styled';
import { motion } from 'framer-motion';
import { IoCloseOutline } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';
// import { MdEdit } from 'react-icons/md';

export const TableVarsData = ({ dataTable, handleRemoveVarList }) => {
  const { t } = useTranslation();
  const columnsDetailsPosition = [
    {
      Header: t('flowBuilder.flow.nodes.dataApi.menu.table-name'),
      accessor: 'name',
    },
    {
      Header: t('flowBuilder.flow.nodes.dataApi.menu.table-path'),
      accessor: 'value',
    },
    {
      Header: t('flowBuilder.flow.nodes.dataApi.menu.table-action'),
      Cell: ({ row }) => (
        <div className='editRow'>
          <motion.button
            whileTap={{ scale: 0.98 }}
            onClick={() => handleRemoveVarList(row.original.id)}
          >
            <IoCloseOutline />
          </motion.button>
        </div>
      ),
    },
  ];

  const dataRows = useMemo(() => dataTable, [dataTable]);

  return (
    <ContainerTableVarsApi>
      <SimpleTable
        dataColumns={columnsDetailsPosition}
        dataTable={dataRows}
        textNoData={t('flowBuilder.flow.nodes.dataApi.menu.table-no-data')}
      />
    </ContainerTableVarsApi>
  );
};
