import { useTranslation } from 'react-i18next';
import { DialogComponent } from '../../DialogComponent';
import { ButtonStyled } from '../../FormComponents/ButtonComponent';
import { ContainerConfirmation, PanelConfirmation } from './styled';
import { BackDropCircularLoading } from '../LoadingComponent';

export const ConfirmationComponent = ({
  open,
  close,
  txtBtnClose = false,
  txtBtnSuccess = false,
  messageConfirm,
  actionConfirm,
  actionClose = false,
  isLoading = false,
}) => {
  const { t } = useTranslation();

  const handleConfirm = () => {
    actionConfirm();
    !isLoading && close(false);
  };

  const handleClose = () => {
    actionClose && actionClose();
    close(false);
  };

  return (
    <DialogComponent open={open} onClose={() => close(false)} fullWidth={true}>
      {isLoading && <BackDropCircularLoading />}
      <ContainerConfirmation>
        <p>{messageConfirm}</p>
        <PanelConfirmation>
          <ButtonStyled
            variantstyle='secondary'
            onClick={handleClose}
            size='small'
          >
            {!txtBtnClose ? t('dialing.btn-confirmationCancel') : txtBtnClose}
          </ButtonStyled>
          <ButtonStyled size='small' onClick={handleConfirm}>
            {!txtBtnSuccess
              ? t('dialing.btn-confirmationAccept')
              : txtBtnSuccess}
          </ButtonStyled>
        </PanelConfirmation>
      </ContainerConfirmation>
    </DialogComponent>
  );
};
