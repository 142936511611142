import { atom } from 'jotai';
import { permissions } from '@/store/AuthenticatorStore';

export const isCloseSidebarAgents = atom(false);

export const isCloseItemAgents = atom(
  (get) => get(isCloseSidebarAgents),
  (get, set, _arg) => set(isCloseSidebarAgents, _arg)
);

export const securityProfileAgents = atom((set) => {
  const { products } = set(permissions);
  const { securityProfile } = products.find(
    (component) => component.name === 'Agents'
  );

  const canDisplay = securityProfile.find((item) => item === 'display');
  const canEdit = securityProfile.find((item) => item === 'edit');
  const canCreate = securityProfile.find((item) => item === 'create');
  const canDelete = securityProfile.find((item) => item === 'delete');

  const data = {
    canDisplay: canDisplay ? true : false,
    canEdit: canEdit ? true : false,
    canCreate: canCreate ? true : false,
    canDelete: canDelete ? true : false,
  };

  return data;
});
