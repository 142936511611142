import { AiFillApi } from 'react-icons/ai';
import { BiSolidUserPin } from 'react-icons/bi';
import { BsChatSquareTextFill } from 'react-icons/bs';
import { FaDatabase } from 'react-icons/fa';
import { FaGears } from 'react-icons/fa6';
import { MdShapeLine } from 'react-icons/md';
import { PiMicrophoneSlashFill, PiPhoneCallFill } from 'react-icons/pi';
import { RiExchangeFill, RiRecordMailLine } from 'react-icons/ri';
import { TbVariableMinus } from 'react-icons/tb';
import LogoMonest from '@/assets/imgs/small-monest.svg';
import LogoDeepCenter from '@/assets/imgs/deepCenter-logo.svg';
import { TiArrowLoop } from 'react-icons/ti';

export const renderIconsFlow = (iconNameReq) => {
  switch (iconNameReq) {
    case 'dialplanVariables':
      return <BiSolidUserPin size={16} />;
    case 'ttsVoicceLabs':
      return <BsChatSquareTextFill size={16} />;
    case 'recVoz':
      return <RiRecordMailLine size={16} />;
    case 'hangUp':
      return <PiMicrophoneSlashFill size={16} />;
    case 'conditionalNode':
      return <MdShapeLine size={16} />;
    case 'disposition':
      return <FaDatabase size={16} />;
    case 'setVariables':
      return <TbVariableMinus size={17} />;
    case 'dial':
      return <PiPhoneCallFill size={17} />;
    case 'apiData':
      return <AiFillApi size={17} />;
    case 'transferQueue':
      return <RiExchangeFill size={17} />;
    case 'functionHandler':
      return <FaGears size={17} />;
    case 'monestIa':
      return <img src={LogoMonest} alt='Monest Logo' />;
    case 'deepCenter':
      return <img src={LogoDeepCenter} alt='Monest Logo' />;
    case 'loop':
      return <TiArrowLoop size={16} />;
    default:
      return '';
  }
};
