import { useState } from 'react';
import { DragTransferList } from '../DragTransferList';
import { useVariablesFlow } from '@/hooks/FlowBuilder/useVariablesFlow';
import { templateSelected, variablesGlobal } from '@/store/FlowBuilder';
import { useAtom } from 'jotai';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';
import { Stack } from '@mui/system';
import { FaGear } from 'react-icons/fa6';
import { ButtonStyled } from '@/components/FormComponents/ButtonComponent';
import { ContentConfig, FooterConfig } from '../../styled';
import { successToast } from '@/components/FormComponents/Toast';
import { useTranslation } from 'react-i18next';
import { Skeleton } from '@mui/material';

export const ListGlobalVariables = ({
  dataGlobals,
  handleClose,
  //   changeFlowConfigState,
}) => {
  const { t } = useTranslation();
  const { loadingVariables } = useVariablesFlow(1);
  const [globalVars] = useAtom(variablesGlobal);
  const [template, setTemplate] = useAtom(templateSelected);

  const [dataVarsSelected, setDataVarsSelected] = useState([]);

  const renderAvailableList = () => {
    const availableList = globalVars
      .map((element) => {
        const filterUsedVars = dataGlobals.filter(
          (item) => item.name === element.name
        );
        if (filterUsedVars.length > 0) {
          return null;
        }
        return element;
      })
      .filter((item) => Boolean(item));

    return availableList;
  };

  const checkVarsPrompt = () => {
    const { nodes } = template;

    const listNodes = nodes ? nodes : [];

    const globalVarsUsed = listNodes
      .filter((item) => item.type === 'ttsVoicceLabs')
      .map((itemPrompt) => itemPrompt.data.prompt);

    const filteredVarsPrompt =
      globalVarsUsed.length > 0 && globalVarsUsed.join(' ').match(/{{(.*?)}}/g);

    const cleanedPrompts = filteredVarsPrompt
      ? filteredVarsPrompt.map((prompts) => prompts.replace(/{{|}}/g, ''))
      : [];

    const mapPromptsUsedVars = dataGlobals
      .map((itemSelected) => {
        const hasItem =
          cleanedPrompts.length > 0 &&
          cleanedPrompts.includes(itemSelected.value);
        if (hasItem) {
          return itemSelected.value;
        }
        return null;
      })
      .filter((item) => item);

    return mapPromptsUsedVars;
  };

  const checkVarsConditions = () => {
    const { nodes } = template;

    const listNodes = nodes ? nodes : [];

    const mapTypeCondition = dataGlobals
      .map((itemSelected) => {
        const globalVarsUsed = listNodes.filter(
          (item) => item.data.typeCondition === itemSelected.name
        );
        if (globalVarsUsed.length > 0) {
          return globalVarsUsed[0];
        }

        return false;
      })
      .filter((item) => item)
      .map((item) => item.data.typeCondition);

    return mapTypeCondition;
  };

  const hasUsedGlobalVars = () => {
    const usedVarsConditions = checkVarsConditions();
    const usedVarsPrompt = checkVarsPrompt();

    const responseCheck = [...usedVarsConditions, ...usedVarsPrompt];

    return responseCheck;
  };

  const handleUpdateGlobalVars = () => {
    setTemplate((previousState) => {
      return {
        ...previousState,
        variablesGlobal: dataVarsSelected,
      };
    });
    successToast(t('flowBuilder.configs.msg-update-endPoint'));
    handleClose();
    return;
  };

  return (
    <ContentConfig>
      {loadingVariables && <BackDropCircularLoading />}
      <Stack
        direction={'row'}
        spacing={1}
        alignItems={'center'}
        className={'titleConfig'}
      >
        <FaGear />
        <h4>{t('flowBuilder.configs.title-globalVars')}</h4>
      </Stack>
      {globalVars.length > 0 ? (
        <DragTransferList
          codeList={renderAvailableList()}
          changeDataCodeList={setDataVarsSelected}
          dataSelected={dataGlobals}
          checkRemoveData={hasUsedGlobalVars()}
          typeList={'Global'}
        />
      ) : (
        <Skeleton
          variant='rectangular'
          width={'100%'}
          height={'100%'}
          sx={{ opacity: 0.4 }}
        />
      )}
      <FooterConfig>
        <div className=''></div>
        {/* <ButtonStyled size='small' onClick={() => changeFlowConfigState(1)}>
          {t('flowBuilder.configs.btn-add-globalVars')}
        </ButtonStyled> */}
        <Stack direction={'row'} spacing={1}>
          <ButtonStyled
            size='small'
            variantstyle='secondary'
            onClick={handleClose}
          >
            {t('flowBuilder.configs.btn-cancel')}
          </ButtonStyled>
          <ButtonStyled size='small' onClick={handleUpdateGlobalVars}>
            {t('flowBuilder.configs.btn-update')}
          </ButtonStyled>
        </Stack>
      </FooterConfig>
    </ContentConfig>
  );
};
