import {
  ChartQueueMonitor,
  ContainerDetailQueueMonitor,
  ContentDataDetail,
  HeaderCardDetail,
} from './styled';
import { useEffect, useState } from 'react';
import { ChartStatus } from '../ChartStatus';
import { Stack } from '@mui/system';
import { renderDate } from '@/utils/formatsDateAndTime';
import { useAtom } from 'jotai';
import { selectedQueueMonitor } from '@/store/QueueMonitor';
import { useTranslation } from 'react-i18next';

export const CardHeaderQueues = () => {
  const { t } = useTranslation();
  const renderTime = renderDate(new Date());
  const [selectedQueue] = useAtom(selectedQueueMonitor);

  return (
    <HeaderCardDetail>
      <Stack direction={'row'} justifyContent={'space-between'} width={'100%'}>
        {selectedQueue ? (
          <strong>{`${t('queueMonitor.table.th-queue')} ${
            selectedQueue.fila
          }`}</strong>
        ) : (
          <strong>{t('queueMonitor.table.th-allQueue')}</strong>
        )}
        <span>{renderTime}</span>
      </Stack>
    </HeaderCardDetail>
  );
};

export const DetailQueues = ({ dataList }) => {
  const { t } = useTranslation();
  const [selectedQueue] = useAtom(selectedQueueMonitor);

  const renderValues = (data, typeCount) => {
    const total = data
      .map((item) => item[typeCount])
      .reduce((acc, row) => acc + row, 0);
    return total;
  };

  const [data, setData] = useState([]);

  const [currentTotal, setCurrentTotal] = useState(0);
  console.log('🚀 -> currentTotal:', currentTotal);

  const [details, setDetails] = useState({
    dispositions: 0,
    campaigns: 0,
    lists: 0,
    operators: 0,
    total: 0,
  });

  const renderTotal = (dataList, typeCount = '') => {
    if (typeCount === 'dispositions') {
      const totalDispositions = dataList
        .map((item) => item.titulo.replace('CODLIG USADOS: ', '').trim().length)
        .reduce((acc, row) => acc + row, 0);
      return totalDispositions;
    }
    const total = dataList.map((item) => item[typeCount]);
    const withoutDuplicates = [...new Set(total)];
    return withoutDuplicates.length;
  };

  useEffect(() => {
    if (dataList && selectedQueue) {
      const filterSelected = dataList.filter(
        (row) => row.fila === selectedQueue.fila
      );
      if (filterSelected.length === 0) return;
      setCurrentTotal(
        filterSelected[0].discando + filterSelected[0].falando_ura
      );
    }
  }, [dataList, selectedQueue]);

  useEffect(() => {
    if (dataList) {
      const valueDialing = renderValues(dataList, 'discando');
      const valueTalking =
        valueDialing >= 10
          ? renderValues(dataList, 'falando_ura') * 10
          : renderValues(dataList, 'falando_ura');

      setDetails({
        dispositions: renderTotal(dataList, 'dispositions'),
        campaigns: renderTotal(dataList, 'campanha_descricao'),
        lists: renderTotal(dataList, 'lista'),
        operators: renderTotal(dataList, 'operadora'),
        total:
          renderValues(dataList, 'discando') +
          renderValues(dataList, 'falando_ura'),
      });

      if (!selectedQueue) {
        return setData([
          {
            data: [valueDialing, valueTalking],
          },
        ]);
      }

      const filterSelected = dataList.filter(
        (item) => item.fila === selectedQueue.fila
      );

      if (filterSelected.length > 0) {
        const dataTalking =
          filterSelected[0].discando >= 10
            ? filterSelected[0].falando_ura * 10
            : filterSelected[0].falando_ura;

        return setData([
          {
            data: [filterSelected[0].discando, dataTalking],
          },
        ]);
      }

      setData([
        {
          data: [0, 0],
        },
      ]);
    }
  }, [dataList]);

  const renderDispositions = (dataList) => {
    const replacedItem = dataList
      .replace('CODLIG USADOS: ', '')
      .split(' ')
      .filter((item) => item)
      .join(', ');

    return replacedItem === '' ? '-' : replacedItem;
  };

  return (
    <ContainerDetailQueueMonitor>
      <ChartQueueMonitor className='area-detail'>
        <CardHeaderQueues />

        {!selectedQueue ? (
          <ContentDataDetail initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
            <div className='detail-queues'>
              <div className='item-detail'>
                <span>{t('queueMonitor.table.th-campaign')}: </span>
                <strong>
                  {details.campaigns} {t('queueMonitor.table.th-campaigns')}
                </strong>
              </div>
              <div className='item-detail'>
                <span>{t('queueMonitor.table.th-list')}: </span>
                <strong>
                  {details.lists} {t('queueMonitor.table.th-lists')}
                </strong>
              </div>
              <div className='item-detail'>
                <span>Telecom: </span>
                <strong>
                  {details.operators} {t('queueMonitor.table.th-operator')}
                </strong>
              </div>
              <div className='item-detail'>
                <span>Total: </span>
                <strong>
                  {details.total} {t('queueMonitor.table.th-attendances')}
                </strong>
              </div>
              <div className='item-detail dispositions-grid'>
                <span>{t('queueMonitor.table.th-dispositions')}: </span>
                <strong>-</strong>
              </div>
            </div>
          </ContentDataDetail>
        ) : (
          <ContentDataDetail initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
            <div className='detail-queues'>
              <div className='item-detail'>
                <span>{t('queueMonitor.table.th-campaign')}: </span>
                <strong>{selectedQueue.campanha_descricao}</strong>
              </div>
              <div className='item-detail'>
                <span>{t('queueMonitor.table.th-list')}: </span>
                <strong>{selectedQueue.lista}</strong>
              </div>
              <div className='item-detail'>
                <span>Telecom: </span>
                <strong>{selectedQueue.operadora}</strong>
              </div>
              <div className='item-detail'>
                <span>Total: </span>
                <strong>
                  {currentTotal} {t('queueMonitor.table.th-attendances')}
                </strong>
              </div>
              <div className='item-detail dispositions-grid'>
                <span>{t('queueMonitor.table.th-dispositions')}: </span>
                <strong>{renderDispositions(selectedQueue.titulo)}</strong>
              </div>
            </div>
          </ContentDataDetail>
        )}
      </ChartQueueMonitor>
      <ChartQueueMonitor className='area-chart'>
        <CardHeaderQueues />
        <ChartStatus data={data} height={100} />
      </ChartQueueMonitor>
    </ContainerDetailQueueMonitor>
  );
};
