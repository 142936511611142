import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';

import { CircularProgress } from '@mui/material';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { Visibility, VisibilityOff, AlternateEmail } from '@mui/icons-material';

import { LoadingDisableScreen } from '@components/layout/LoadingComponent';
import { ImageComponent } from '@components/layout/ImageComponent';
import { ButtonStyled } from '@components/FormComponents/ButtonComponent';

import LogoMobile from '@/assets/imgs/lg_voiccelabs_black.webp';

export const FormLogin = ({
  handleLogin,
  controlForm,
  errorsFields,
  isLoading,
  handleForgotPass,
}) => {
  const { t } = useTranslation();

  const [showPassword, setShowPassword] = useState(true);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
    setShowPassword(false);
  };

  return (
    <>
      {isLoading && <LoadingDisableScreen />}
      <ImageComponent
        id='logoMobLogin'
        width={'535px'}
        height={'132px'}
        src={LogoMobile}
      />
      <p>{t('login.first-sentence')}</p>
      <form onSubmit={handleLogin} autoComplete='off'>
        <Controller
          control={controlForm}
          name='email'
          render={({ field }) => (
            <TextField
              {...field}
              type='text'
              autoComplete='false'
              fullWidth
              label={'E-mail'}
              placeholder={t('login.placeholder-email')}
              value={field.email}
              error={Boolean(errorsFields.email)}
              helperText={
                Boolean(errorsFields.email) && errorsFields.email.message
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <AlternateEmail />
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
        <Controller
          control={controlForm}
          name='password'
          render={({ field }) => (
            <TextField
              {...field}
              type={!showPassword ? 'text' : 'password'}
              name='currentPass'
              autoComplete='off'
              fullWidth
              value={field.password}
              label={t('login.label-password')}
              placeholder={t('login.placeholder-password')}
              error={Boolean(errorsFields.password)}
              helperText={
                Boolean(errorsFields.password) && errorsFields.password.message
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onMouseDown={handleMouseDownPassword}
                      onMouseUp={() => setShowPassword(true)}
                      edge='end'
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
        <ButtonStyled type='submit' aria-label='Login'>
          {isLoading ? (
            <CircularProgress
              size={26}
              sx={{
                color: 'white',
              }}
            />
          ) : (
            t('login.btn-login')
          )}
        </ButtonStyled>
      </form>
      <div className='btn_forget_pass'>
        <ButtonStyled onClick={handleForgotPass}>
          {t('forgotPass.btn-forgotPass')}
        </ButtonStyled>
      </div>
    </>
  );
};
