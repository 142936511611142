import { Stack } from '@mui/system';
import { ContainerMatchTenants } from './styled';
import { ButtonStyled } from '@/components/FormComponents/ButtonComponent';
import { PanelMatchTenants } from './PanelMatchTenants';
import { TableMatchTenants } from './TableMatchTenants';
import { useCallback, useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { useAtom } from 'jotai';
import { user } from '@/store/AuthenticatorStore';
import { useTranslation } from 'react-i18next';
import { dataSelectedTenants } from '@/store/TenantsStore';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';
import { useTenants } from '@/hooks/Tenants/useTenants';
import { NoDataTenants } from '@/components/pages/Tenants/components/NoDataTenants';
import { removeDuplicates } from '@/utils/removeDuplicateItemsArr';
import { useRenderNameTenants } from '@/components/pages/Tenants/hooks/useRenderNamesTenants';

export const ListMatchTenants = ({ changeMainStage, data }) => {
  const { t } = useTranslation();
  const { mutateCreateMatch, loadingCreateMatch } = useTenants();
  const { renderHostName, renderClientName } = useRenderNameTenants();
  const [rows, setRows] = useState([]);
  const [{ client_id }] = useAtom(user);
  const [tenantSelected] = useAtom(dataSelectedTenants);
  const [selected, setSelected] = useState(null);
  const [isNewRow, setIsNewRow] = useState(false);
  const [errorRow, setErrorRow] = useState(null);
  const [editServerSelected, setEditServerSelected] = useState('0');
  const [editClientSelected, setEditClientSelected] = useState('0');
  const [filterMatch, setFilterMatch] = useState('');

  const renderTenants = useCallback(() => {
    if (!data || data.length === 0) {
      return [];
    }
    const addedNamesTenants = data.map((tenant) => ({
      ...tenant,
      nameHost: renderHostName(tenant.server_id),
      nameClient: renderClientName(tenant.client_id),
    }));

    const filterNameTenant = addedNamesTenants.filter((data) =>
      data.nameHost.toLowerCase().includes(filterMatch.toLowerCase())
    );

    const filterClientTenant = addedNamesTenants.filter((data) =>
      data.nameClient.toLowerCase().includes(filterMatch.toLowerCase())
    );

    const dataFilter = removeDuplicates([
      ...filterNameTenant,
      ...filterClientTenant,
    ]);
    return dataFilter;
  }, [data, filterMatch]);

  useEffect(() => {
    if (data) {
      const filterSelectedData = renderTenants().filter(
        (item) => item.server_id === tenantSelected.id
      );
      setRows(filterSelectedData);
    }
  }, [data, renderTenants]);

  const dataRows = {
    rows,
    setRows,
    selected,
    setSelected,
    errorRow,
    setErrorRow,
    isNewRow,
    setIsNewRow,
    editServerSelected,
    setEditServerSelected,
    editClientSelected,
    setEditClientSelected,
  };

  const handleAddMatchTenants = () => {
    setIsNewRow(true);
    const newMatch = {
      id: uuid(),
      client_id: editClientSelected.id,
      server_id: tenantSelected.id,
      updated_at: new Date().toISOString(),
      created_at: new Date().toISOString(),
    };
    setEditClientSelected(client_id);
    setSelected(newMatch);
    setRows([newMatch, ...rows]);
  };

  const isLoading = loadingCreateMatch;

  return (
    <ContainerMatchTenants>
      {isLoading && <BackDropCircularLoading />}
      <PanelMatchTenants
        changeStage={changeMainStage}
        dataFilter={{ filterMatch, setFilterMatch }}
        isNewRow={isNewRow}
      />
      <Stack alignItems={'flex-end'} justifyContent={'flex-end'}>
        <ButtonStyled
          size={'small'}
          onClick={handleAddMatchTenants}
          disabled={isNewRow}
          title={t('tenants.addMatchTenant.btn-addMatch')}
        >
          <Stack direction={'row'} alignItems={'center'} spacing={1}>
            <span>{t('tenants.addMatchTenant.btn-addMatch')}</span>
          </Stack>
        </ButtonStyled>
      </Stack>
      {rows.length > 0 ? (
        <TableMatchTenants
          dataRows={dataRows}
          mutateCreateMatch={mutateCreateMatch}
        />
      ) : (
        <NoDataTenants
          handleStage={handleAddMatchTenants}
          typeData={'matchTenants'}
        />
      )}
    </ContainerMatchTenants>
  );
};
