import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { useTranslation } from 'react-i18next';
import { DialogComponent } from '@/components/DialogComponent';
import { ButtonStyled } from '@/components/FormComponents/ButtonComponent';
import { FormExportFlow, PanelExportFlow } from './styled';
import { useAtom } from 'jotai';
import { isSavedFlow, templateSelected } from '@/store/FlowBuilder';
import { PiExportFill } from 'react-icons/pi';
import { useForm } from 'react-hook-form';
import { ControlInput } from '@/components/FormComponents/InputComponent';
import { Alert, MenuItem } from '@mui/material';
import { ControlSelect } from '@/components/FormComponents/SelectInput';
import { DescriptionInformation } from '@/components/layout/DescriptionInformation';
import { dataListClients } from '@/store/Clients';
import { user } from '@/store/AuthenticatorStore';
import { useHandleFlow } from '@/hooks/FlowBuilder/useHandlerFlow';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';
import { successToast } from '@/components/FormComponents/Toast';
import { Box } from '@mui/system';
import { useEffect } from 'react';

export const ExportFlowToClientId = ({ open, close }) => {
  const { t } = useTranslation();
  const [listClients] = useAtom(dataListClients);
  const [template] = useAtom(templateSelected);
  const [currentUser] = useAtom(user);
  const [isSaved] = useAtom(isSavedFlow);
  const { mutateHandleFlow, loadingHandleFlow } = useHandleFlow();

  const handleClose = () => {
    close(false);
  };

  const exportSchema = yup.object().shape({
    nameExportFlow: yup
      .string()
      .required()
      .matches(
        /^[a-zA-Z0-9_]*$/,
        t('flowBuilder.exportFlow.error-name-export')
      ),
    descriptionExportFlow: yup
      .string()
      .transform((value, originalValue) =>
        originalValue === ''
          ? t('flowBuilder.exportFlow.msg-no-description')
          : value
      ),
    client_id: yup.string().test('is-zero', '', (value) => value !== '0'),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(exportSchema),
  });

  useEffect(() => {
    if (!open) {
      return reset();
    }
    return () => reset();
  }, [open]);

  const handleExportFlow = (data) => {
    const { variablesGlobal, codeLigs, nlus, flow, nodes, edges } = template;

    const dataExportNode = {
      nameIVR: data.nameExportFlow,
      descriptionIVR: data.descriptionExportFlow,
      client_id: data.client_id,
      user_id: currentUser.user_id,
      variablesGlobal,
      codeLigs,
      nlus,
      flow,
      nodes,
      edges,
    };

    mutateHandleFlow(dataExportNode, {
      onSuccess: () => {
        successToast(t('flowBuilder.exportFlow.msg-success-export'));
        handleClose();
        reset();
      },
    });
  };

  return (
    <DialogComponent open={open} onClose={() => close(false)} fullWidth={true}>
      {loadingHandleFlow && <BackDropCircularLoading />}
      <FormExportFlow onSubmit={handleSubmit(handleExportFlow)}>
        <div className='heater_details'>
          <PiExportFill />
          <h4>{t('flowBuilder.exportFlow.title')}</h4>
        </div>

        {!isSaved ? (
          <Box width={'100%'}>
            <Alert severity='error'>
              {t('flowBuilder.exportFlow.msg-error-not-save')}
            </Alert>
          </Box>
        ) : (
          <DescriptionInformation
            textDescription={t('flowBuilder.exportFlow.description')}
          />
        )}

        <ControlInput
          control={control}
          nameControl={'nameExportFlow'}
          label={t('flowBuilder.exportFlow.label-name-export')}
          error={Boolean(errors.nameExportFlow)}
          disabled={!isSaved}
          helperText={
            errors.nameExportFlow ? errors.nameExportFlow.message : ''
          }
        />
        <ControlInput
          control={control}
          nameControl={'descriptionExportFlow'}
          label={t('flowBuilder.exportFlow.label-description-export')}
          error={Boolean(errors.descriptionExportFlow)}
          disabled={!isSaved}
        />

        <ControlSelect
          control={control}
          nameControl={'client_id'}
          defaultValue={'0'}
          label={t('flowBuilder.exportFlow.label-client-export')}
          error={Boolean(errors.client_id)}
          disabled={!isSaved}
        >
          <MenuItem value={'0'} dense disabled>
            {t('flowBuilder.exportFlow.option-client-export')}
          </MenuItem>
          {listClients.length > 0 &&
            listClients.map((item) => (
              <MenuItem value={item.id} key={item.id} dense>
                {item.commercial_name}
              </MenuItem>
            ))}
        </ControlSelect>
        <PanelExportFlow>
          <ButtonStyled
            size='small'
            variantstyle='secondary'
            onClick={handleClose}
          >
            {t('flowBuilder.exportFlow.btn-cancel')}
          </ButtonStyled>
          <ButtonStyled size='small' type={'submit'} disabled={!isSaved}>
            {t('flowBuilder.exportFlow.btn-export')}
          </ButtonStyled>
        </PanelExportFlow>
      </FormExportFlow>
    </DialogComponent>
  );
};
