import {
  idNodeToRemove,
  selectedNode,
  showMenuNodes,
} from '@/store/FlowBuilder';
import { useAtom } from 'jotai';
import React, { memo } from 'react';
import { Handle, Position } from 'reactflow';
import { NodeContent, NodePlayFilesContainer } from './styled';
import { PiMonitorPlayFill } from 'react-icons/pi';
import { Stack } from '@mui/material';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { IoCheckmarkCircleOutline } from 'react-icons/io5';
import { motion } from 'framer-motion';
import { MdEdit } from 'react-icons/md';
import { shortNameRender } from '../../../utils/shortNameRender';

const PlayFilesNodeComponent = ({ id, data, selected }) => {
  const [, setIdNode] = useAtom(idNodeToRemove);
  const [, setShowMenu] = useAtom(showMenuNodes);
  const [nodeSelected, setSelectedNode] = useAtom(selectedNode);
  const { validInput, sounds } = data;

  const renderSoundsText = (text) => text && `...${text.slice(-20)}`;

  const handleNode = () => {
    setSelectedNode({ id, data });
    setShowMenu(true);
  };

  const handleRemoveNode = (idNode) => {
    setIdNode(idNode);
    idNode === nodeSelected?.id && setShowMenu(false);
  };

  const handleSelectedNode = () => {
    setSelectedNode({ id, data });
    setShowMenu(true);
  };

  return (
    <NodePlayFilesContainer
      selected={selected}
      onDoubleClick={() => handleNode()}
    >
      <div className='panelNode'>
        <motion.button
          onClick={() => handleSelectedNode()}
          whileTap={{ scale: 0.98 }}
        >
          <MdEdit size={16} />
        </motion.button>
        <motion.button
          onClick={() => handleRemoveNode(id)}
          whileTap={{ scale: 0.98 }}
        >
          <IoIosCloseCircleOutline />
        </motion.button>
      </div>
      <Handle type='target' position={Position.Left} />
      <NodeContent>
        <div className='header'>
          <PiMonitorPlayFill />
          <div className='titleNode'>
            <h4>{shortNameRender(data.label, 12)}</h4>
            <span>{data.handler}</span>
          </div>
        </div>
        <div className='main'>
          <Stack direction={'row'} alignItems={'center'} spacing={1}>
            <span>Sounds: </span>
            <span>{renderSoundsText(sounds[0])}</span>
          </Stack>
          <div className='dividerNode'></div>
          <Stack direction={'row'} alignItems={'center'} spacing={1}>
            <span>Allow skip: </span>
            {data.allowSkip ? (
              <Stack
                direction={'row'}
                alignItems={'center'}
                spacing={0.5}
                className='trueSkip'
              >
                <IoCheckmarkCircleOutline />
                <span>Yes</span>
              </Stack>
            ) : (
              <Stack
                direction={'row'}
                alignItems={'center'}
                spacing={0.5}
                className='falseSkip'
              >
                <IoIosCloseCircleOutline />
                <span>No</span>
              </Stack>
            )}
          </Stack>
          <div className='dividerNode'></div>
          <Stack
            direction={'column'}
            alignItems={'flex-start'}
            spacing={1}
            width={'100%'}
          >
            <span>Valid Input: </span>
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'flex-start'}
              spacing={1}
              width={'100%'}
            >
              {[...Array(10).keys(), '#'].map((value) => (
                <span
                  key={value}
                  className={`itemInput ${
                    validInput?.includes(value.toString())
                      ? 'itemInputSelected'
                      : ''
                  }`}
                >
                  {value}
                </span>
              ))}
            </Stack>
          </Stack>
        </div>
      </NodeContent>
      <Handle type='source' position={Position.Right} />
    </NodePlayFilesContainer>
  );
};

export const PlayFilesNode = memo(PlayFilesNodeComponent);
