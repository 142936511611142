import { ButtonStyled } from '@/components/FormComponents/ButtonComponent';
import { ContainerNewFlow, FormNewFlow, MainForm } from './styled';
import { InputComponent } from '@/components/FormComponents/InputComponent';
import { DescriptionInformation } from '@/components/layout/DescriptionInformation';
import { useState } from 'react';
import { Stack } from '@mui/system';
import { useAtom } from 'jotai';
import { templateSelected } from '@/store/FlowBuilder';
import { useTranslation } from 'react-i18next';
import { errorToast } from '@/components/FormComponents/Toast';

export const NewFlow = ({
  changeStateFlow,
  /* changeStageFlow */
}) => {
  const { t } = useTranslation();
  const [, setTemplate] = useAtom(templateSelected);
  const [nameNewFlowField, setNameNewFlowField] = useState('');
  const [nameNewFlowFieldError, setNameNewFlowFieldError] = useState('');

  const [descriptionNewFlowField, setDescriptionNewFlowField] = useState('');
  const [descriptionNewFlowFieldError, setDescriptionNewFlowFieldError] =
    useState('');

  const validateName = (value) => {
    if (!value.trim()) {
      setNameNewFlowFieldError(t('flowBuilder.newFlow.msg-name-required'));
    } else if (value.length > 50) {
      setNameNewFlowFieldError(
        t('flowBuilder.newFlow.msg-name-length', { length: '50' })
      );
    } else if (!/^[_a-zA-Z0-9]*$/.test(value)) {
      setNameNewFlowFieldError(t('flowBuilder.newFlow.msg-name-letters'));
    } else {
      setNameNewFlowFieldError('');
    }
    setNameNewFlowField(value);
  };

  const validateDescription = (value) => {
    if (value.length > 200) {
      setDescriptionNewFlowFieldError(
        t('flowBuilder.newFlow.msg-name-length', { length: '200' })
      );
    } else {
      setDescriptionNewFlowFieldError('');
    }
    setDescriptionNewFlowField(value);
  };

  const handleClearForm = () => {
    setNameNewFlowField('');
    setDescriptionNewFlowField('');
    setNameNewFlowFieldError('');
    setDescriptionNewFlowFieldError('');
  };

  const handleNewFlow = (e) => {
    e.preventDefault();

    // if (nameNewFlowFieldError.length) {
    //   return errorToast(t('flowBuilder.newFlow.msg-name-required'));
    // }

    const dataNewFlow = {
      nameIVR: nameNewFlowField,
      descriptionIVR:
        descriptionNewFlowField === ''
          ? 'No Description'
          : descriptionNewFlowField,
      flow: {},
      variablesGlobal: [],
      codeLigs: [],
      nlus: [],
      nodes: [
        {
          id: '1',
          type: 'initNode',
          data: {
            label: 'First',
            handler: 'dbQuery',
            query: 'newUser',
            next: 'inputClient',
          },
          position: { x: 60, y: 400 },
        },
      ],
      edges: [],
      published: false,
    };
    setTemplate(dataNewFlow);
    handleClearForm();
    changeStateFlow(1);
  };

  const renderId = new Date().getTime().toString();

  return (
    <ContainerNewFlow initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <DescriptionInformation
        textDescription={t('flowBuilder.newFlow.stages.new.description')}
      />
      <FormNewFlow onSubmit={handleNewFlow}>
        <MainForm>
          <InputComponent
            label={t('flowBuilder.newFlow.stages.new.label-name')}
            value={nameNewFlowField}
            onChange={(e) => validateName(e.target.value)}
            error={Boolean(nameNewFlowFieldError.length)}
            helperText={nameNewFlowFieldError}
          />

          <InputComponent
            label={t('flowBuilder.newFlow.stages.new.label-description')}
            value={descriptionNewFlowField}
            onChange={(e) => validateDescription(e.target.value)}
            error={Boolean(descriptionNewFlowFieldError.length)}
            helperText={descriptionNewFlowFieldError}
            name={renderId}
          />
        </MainForm>
        <Stack direction={'row'} spacing={1} justifyContent={'flex-end'}>
          <ButtonStyled
            type='button'
            size={'small'}
            variantstyle='secondary'
            onClick={handleClearForm}
          >
            {t('flowBuilder.newFlow.stages.btn.btn-clear')}
          </ButtonStyled>
          <ButtonStyled
            type='submit'
            size={'small'}
            disabled={
              Boolean(nameNewFlowFieldError.length) || nameNewFlowField === ''
            }
          >
            {t('flowBuilder.newFlow.stages.btn.btn-next')}
          </ButtonStyled>
        </Stack>
      </FormNewFlow>
    </ContainerNewFlow>
  );
};
