import { useId, useState } from 'react';
import { ListNlus } from './ListNlus';
import { AddNlus } from './AddNlus';

export const ConfigNlus = ({ dataNlus: listNlus, handleClose }) => {
  const [stageNlusFLow, setStageNlusFlow] = useState(0);

  const changeFlowConfigState = (stage) => setStageNlusFlow(stage);

  const flowNlus = [
    <ListNlus
      key={useId()}
      dataNlus={listNlus}
      handleClose={handleClose}
      changeFlowConfigState={changeFlowConfigState}
    />,
    <AddNlus key={useId()} changeFlowConfigState={changeFlowConfigState} />,
  ];

  return flowNlus[stageNlusFLow];
};
