import { Stack, Skeleton } from '@mui/material';

export const SkeletonLoadDash = () => {
  return (
    <Stack width={'100%'} height={'100%'} direction={'column'} spacing={2}>
      <Stack width={'100%'} height={'100%'} direction={'row'} spacing={2}>
        <Skeleton
          variant='rectangular'
          width={'100%'}
          height={'100%'}
          sx={{ borderRadius: '4px' }}
        />
        <Skeleton
          variant='rectangular'
          width={'100%'}
          height={'100%'}
          sx={{ borderRadius: '4px' }}
        />
      </Stack>
      <Stack width={'100%'} height={'100%'} direction={'row'} spacing={2}>
        <Skeleton
          variant='rectangular'
          width={'75%'}
          height={'100%'}
          sx={{ borderRadius: '4px' }}
        />
        <Skeleton
          variant='rectangular'
          width={'25%'}
          height={'100%'}
          sx={{ borderRadius: '4px' }}
        />
      </Stack>
    </Stack>
  );
};
