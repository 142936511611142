import { motion } from 'framer-motion';
import styled from 'styled-components';

export const DetailUserAccount = styled(motion.div)`
  position: relative;
  width: 90%;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
`;

export const HeaderAccordion = styled(motion.div)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  .rotate {
    transform: rotate(180deg);
    transition: all 0.2s ease;
  }

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.7rem;

    svg {
      color: ${(props) => props.theme.colors.primary};
    }
  }

  .info-sidebar {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    color: ${(props) => props.theme.colors.typography.baseText};
    gap: 0;
    .name-user {
      font-size: 0.875rem;
      font-weight: bold !important;
      /* color: ${(props) => props.theme.colors.primary}; */
      color: ${(props) => props.theme.colors.typography.baseText};
      user-select: text;
    }

    .email-user {
      padding-top: 2px;
      width: 200px;
      word-wrap: break-word;
      font-size: 0.75rem;
      font-weight: 400;
      user-select: text;
      opacity: 0.7;
      color: ${(props) => props.theme.colors.typography.baseText};
    }
  }

  svg {
    font-size: 2rem;
    color: ${(props) => props.theme.colors.typography.baseText};
  }
`;

export const SectionCollapsed = styled(motion.div)`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 12px 0 10px;
  align-items: center;
  width: 90%;
  height: auto;
  cursor: pointer;
  color: ${(props) => props.theme.colors.baseText};
  background: ${(props) => props.theme.colors.bases.gradientBg};
  border-radius: 5px;
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    font-size: 1rem;
    margin-bottom: 5px;
    font-size: 0.8rem;
  }
  .dividerUser {
    background: white;
    width: 1px;
    height: 25px;
  }
`;

export const PopoverButtonSidebar = styled(motion.div)`
  button {
    all: unset;

    &:hover {
      all: unset;
    }
  }
`;

export const BoxInfoUser = styled(motion.div)`
  padding: 8px;

  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  border-radius: 4px;
  user-select: none;

  span {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
    gap: 8px;
    cursor: pointer;
    padding: 4px 10px;
    border-radius: 4px;
    &:hover {
      background-color: ${(props) => props.theme.colors.primary};
      color: ${(props) => props.theme.colors.bases.baseWhite};
    }
  }
`;
