import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

export const CardStatusQueue = styled(motion.div)`
  min-height: 70px;
  max-width: 400px;
  background-color: ${(props) => props.theme.colors.containers.colorCard};
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  box-shadow: 1px 4px 10px -1px rgba(0, 0, 0, 0.4);
  padding: 4px;
  color: ${(props) => props.theme.colors.typography.baseText};
  border-radius: 4px;
  padding: 0.8rem;
  cursor: pointer;
  user-select: none;
  transition: all 0.2s ease;
  position: relative;

  &:hover {
    box-shadow: 1px 4px 0px -1px rgba(0, 0, 0, 0.1);
    filter: grayscale(0.1);
  }

  .cardNull {
    color: ${(props) => props.theme.colors.primary} !important;
  }

  .cardClosed {
    color: ${(props) => props.theme.colors.secondary} !important;
  }

  .cardDiscing {
    color: ${(props) => props.theme.colors.success} !important;
  }

  .colorDiscing {
    color: ${(props) => props.theme.colors.success} !important;
  }

  .colorClosed {
    color: ${(props) => props.theme.colors.secondary}!important;
  }

  .colorNull {
    color: ${(props) => props.theme.colors.primary} !important;
  }

  .contentImage {
    width: 5px;
    height: 100%;
    position: absolute;
    left: 0;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
    ${(props) =>
      props.statusQueue === 0 &&
      css`
        background-color: ${(props) => props.theme.colors.primary};
      `}
    ${(props) =>
      props.statusQueue === 1 &&
      css`
        background-color: ${(props) => props.theme.colors.secondary};
      `}
    ${(props) =>
      props.statusQueue === 2 &&
      css`
        background-color: ${(props) => props.theme.colors.success};
      `}
  }

  .contentCard {
    flex: 1;
    padding: 0 0.4rem;
    display: flex;
    flex-direction: column;

    gap: 3px;
    span {
      font-weight: 400;
      font-size: 0.9rem;
      opacity: 1;
      color: ${(props) => props.theme.colors.typography.baseText};
      padding-top: 2px;
    }
    div {
      svg {
        font-size: 0.8rem;
      }
      span {
        font-size: 0.8rem;
        text-transform: uppercase;
        opacity: 0.9;
        font-weight: 400;
      }
    }
  }
  span {
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 0.2%;
  }
`;
