import styled, { css } from 'styled-components';

export const ContainerStopWatch = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  padding: 9px 8px;
  border-radius: 4px;
  box-shadow: ${(props) => props.theme.colors.containers.baseShadows};
  ${(props) =>
    props.status
      ? css`
          background: ${(props) =>
            props.theme.colors.agentColors[props.status]};
        `
      : css`
          background: ${(props) => props.theme.colors.primary};
        `}

  span {
    color: white;
    letter-spacing: 1.4px;
  }
`;
