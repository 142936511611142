import { motion } from 'framer-motion';
import styled from 'styled-components';

export const FormEditSecurityProfile = styled(motion.div)`
  width: 100%;
  height: calc(100vh - 140px);
  display: grid;
  grid-template-rows: minmax(200px, 220px) 1fr 40px;
  gap: 1rem;

  .headerForm {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }

  .mainForm {
    margin-top: 10px;
    display: grid;
    grid-template-rows: 30px auto;
    gap: 1rem;
    overflow: hidden;
    min-height: 200px;

    .noPermissionList {
      height: 100px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 0.64rem;
      p {
        color: ${(props) => props.theme.colors.typography.baseText};
      }
      svg {
        color: ${(props) => props.theme.colors.typography.baseText};
        font-size: 1.4rem;
      }
    }
  }

  .footerForm {
    width: 100%;
    height: 100%;
    min-height: 30px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 1rem;

    @media (max-height: 768px) {
      padding-bottom: 10px;
    }
  }
`;
