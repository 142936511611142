import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { motion } from 'framer-motion';
import { MenuItem, Box, Stack } from '@mui/material';
import {
  ControlInput,
  ControlInputNumber,
} from '@/components/FormComponents/InputComponent';
import { ButtonStyled } from '@/components/FormComponents/ButtonComponent';
import { ControlRadio } from '@/components/FormComponents/RadioComponent';
import { ControlSelect } from '@/components/FormComponents/SelectInput';
import { FaArrowLeft } from 'react-icons/fa';
import { dataQueues } from '@/store/Queues';
import useFormControl from '@/hooks/useFormControl';
import {
  ContainerPropsQueue,
  FormPropsQueues,
  PanelConfigQueue,
} from './styled';
import {
  dataFlowOptions,
  dataQueueOperators,
  selectedQueue,
} from '@/store/DialingStore';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';
import { successToast } from '@/components/FormComponents/Toast';
import { useQueues } from '@/hooks/dialControl/Queues/useQueues';

export const PropsQueue = ({ controlStage }) => {
  const { t } = useTranslation();

  const { mutateUpdateQueue, loadingUpdateQueue } = useQueues(1);
  const [dataSelectedQueue] = useAtom(selectedQueue);
  const [dataListOperators] = useAtom(dataQueueOperators);
  const [listQueues] = useAtom(dataQueues);
  const [listFlows] = useAtom(dataFlowOptions);

  const labelActiveRadio = t(
    'dialControl.queues.propsQueue.options-active-radio'
  );
  const labelDisabledRadio = t(
    'dialControl.queues.propsQueue.options-disabled-radio'
  );

  const queueManagementSchema = yup.object().shape({
    typeDialing: yup.string().required(),
    transferQueue: yup.number().required(),
    // uraContext: yup.string().required(),
    ringtonesNotAnswered: yup.string().required(),
    maximumLines: yup.number().required(),
    maximumLinesPerAgent: yup.number().required(),
    uraMultiplier: yup.string().required(),
    predMultiplier: yup.string().required(),
    masterLinesGroup: yup.number().required(),
    masterLinesGroup2: yup.number().required(),
    initialHitRate: yup.string().required(),
    acwSeconds: yup.string().required(),
    currentFlow: yup.string(),
    localDial: yup.string().required(),
    ldnDial: yup.string().required(),
    filterSecretary: yup.string().required(),
    dialWithoutAgents: yup.string().required(),
  });

  const { handleSubmit, control, reset, errors } = useFormControl(
    queueManagementSchema
  );

  const handleQueueManagement = (data) => {
    const dataUpdated = {
      fila: dataSelectedQueue.fila,
      contexto_ura: dataSelectedQueue.contexto_ura,
      disca_sem_agentes: data.dialWithoutAgents === labelActiveRadio ? 1 : 0,
      disca_tipo: data.typeDialing,
      fila_transfer: data.transferQueue,
      toques_nao_atende: data.ringtonesNotAnswered,
      filtra_secretaria: data.filterSecretary === labelActiveRadio ? 1 : 0,
      max_linhas: data.maximumLines,
      maxLinhasTotal: data.maximumLines,
      max_linha_por_agente: data.maximumLinesPerAgent,
      multiplicador_ura: data.uraMultiplier,
      multiplicador_pred: data.predMultiplier,
      grupo_master_linhas: data.masterLinesGroup,
      grupo_master_linhas_2: data.masterLinesGroup2,
      taxa_acerto_inicial: data.initialHitRate,
      segundos_acw: data.acwSeconds,
      mensagem:
        dataSelectedQueue.contexto_ura === 'voicce-avi'
          ? data.currentFlow === '0'
            ? ' '
            : data.currentFlow
          : ' ',
      disca_local: data.localDial === labelActiveRadio ? 1 : 0,
      disca_ldn: data.ldnDial === labelActiveRadio ? 1 : 0,
      ativo: 1,
      cod_uf_bloq: '',
      cod_ddd_bloq: '',
    };

    mutateUpdateQueue(dataUpdated, {
      onSuccess: () => {
        reset();
        controlStage.toStage(0);
        successToast(t('dialControl.queues.propsQueue.successMessage'));
      },
    });
  };

  const handleBackStage = () => {
    controlStage.toStage(0);
  };

  const filterValueSelected =
    listFlows.length > 0 &&
    listFlows.filter((item) => item.nameIVR === dataSelectedQueue.mensagem)[0];

  const renderFlowSelected =
    dataSelectedQueue.mensagem === ''
      ? '0'
      : filterValueSelected
      ? dataSelectedQueue.mensagem
      : '0';

  const checkTypeUraVoicce = dataSelectedQueue.contexto_ura === 'voicce-avi';

  const isLoading = loadingUpdateQueue;

  return (
    <ContainerPropsQueue initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      {isLoading && <BackDropCircularLoading />}
      <FormPropsQueues onSubmit={handleSubmit(handleQueueManagement)}>
        <div className='main'>
          <Stack direction={'column'} spacing={3}>
            <PanelConfigQueue>
              <Stack direction='row' spacing={2}>
                <motion.div
                  whileTap={{ scale: 0.8 }}
                  className='icon_handle_back'
                  onClick={handleBackStage}
                >
                  <FaArrowLeft size='16' />
                </motion.div>
                <div className='itemPanel'>
                  <span>
                    <strong>
                      {`${dataSelectedQueue.fila ?? ''} - ${
                        dataSelectedQueue.campanha ?? ''
                      }`}
                    </strong>
                  </span>
                </div>
              </Stack>
            </PanelConfigQueue>
          </Stack>

          <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
            <ControlSelect
              control={control}
              nameControl={'typeDialing'}
              label={t('dialControl.queues.propsQueue.label-typeDialing')}
              defaultValue={dataSelectedQueue.disca_tipo}
              error={Boolean(errors.typeDialing)}
            >
              <MenuItem value={'0'} dense disabled>
                {t('dialControl.queues.propsQueue.option-transferQueue')}
              </MenuItem>
              <MenuItem value={dataSelectedQueue.disca_tipo} dense>
                {dataSelectedQueue.disca_tipo}
              </MenuItem>
              <MenuItem value={'pred'} dense>
                Pred
              </MenuItem>
            </ControlSelect>
          </Stack>

          <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
            <ControlSelect
              control={control}
              nameControl={'transferQueue'}
              label={t('dialControl.queues.propsQueue.label-transferQueue')}
              defaultValue={dataSelectedQueue.fila_transfer}
              error={Boolean(errors.transferQueue)}
            >
              <MenuItem value={'0'} dense>
                {t('dialControl.queues.propsQueue.option-transferQueue')}
              </MenuItem>

              {listQueues.map((item, i) => (
                <MenuItem value={item.fila} key={i} dense>
                  {item.fila}-{item.campanha}
                </MenuItem>
              ))}
            </ControlSelect>

            {checkTypeUraVoicce && (
              <ControlSelect
                control={control}
                nameControl={'currentFlow'}
                defaultValue={renderFlowSelected}
                label={t('dialControl.queues.propsQueue.label-currentFlow')}
                error={Boolean(errors.currentFlow)}
              >
                <MenuItem value={'0'} dense>
                  {t('dialControl.queues.propsQueue.option-currentFlow')}
                </MenuItem>

                {listFlows.length > 0 &&
                  listFlows.map((item, i) => (
                    <MenuItem key={i} value={item.nameIVR} dense>
                      {item.nameIVR}
                    </MenuItem>
                  ))}
              </ControlSelect>
            )}
          </Stack>

          <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
            <ControlInput
              control={control}
              nameControl={'maximumLines'}
              label={t('dialControl.queues.propsQueue.label-maximumLines')}
              defaultValue={dataSelectedQueue.max_linhas}
              type='number'
              inputProps={{ min: 0 }}
              error={Boolean(errors.maximumLines)}
            />
            <ControlInput
              control={control}
              nameControl={'maximumLinesPerAgent'}
              label={t(
                'dialControl.queues.propsQueue.label-maximumLinesPerAgent'
              )}
              defaultValue={dataSelectedQueue.max_linha_por_agente}
              type='number'
              inputProps={{ min: 0 }}
              error={Boolean(errors.maximumLinesPerAgent)}
            />
          </Stack>

          <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
            <ControlInputNumber
              control={control}
              nameControl={'uraMultiplier'}
              label={t('dialControl.queues.propsQueue.label-uraMultiplier')}
              defaultValue={dataSelectedQueue.multiplicador_ura}
              error={Boolean(errors.uraMultiplier)}
            />

            <ControlInputNumber
              control={control}
              nameControl={'predMultiplier'}
              label={t('dialControl.queues.propsQueue.label-predMultiplier')}
              defaultValue={dataSelectedQueue.multiplicador_pred}
              error={Boolean(errors.predMultiplier)}
            />
          </Stack>

          <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
            <ControlSelect
              control={control}
              nameControl={'masterLinesGroup'}
              label={t('dialControl.queues.propsQueue.label-masterLinesGroup')}
              defaultValue={dataSelectedQueue.grupo_master_linhas}
              error={Boolean(errors.masterLinesGroup)}
            >
              <MenuItem value={'0'} dense>
                Select List
              </MenuItem>

              {dataListOperators.map((item, i) => (
                <MenuItem key={i} value={item.grupo_master} dense>
                  {item.grupo_master} - {item.nome_operadora}
                </MenuItem>
              ))}
            </ControlSelect>

            <ControlSelect
              control={control}
              nameControl={'masterLinesGroup2'}
              label={t('dialControl.queues.propsQueue.label-masterLinesGroup2')}
              defaultValue={dataSelectedQueue.grupo_master_linhas_2}
              error={Boolean(errors.masterLinesGroup2)}
            >
              <MenuItem value={'0'} dense>
                Select List
              </MenuItem>
              {dataListOperators.map((item, i) => (
                <MenuItem key={i} value={item.grupo_master} dense>
                  {item.grupo_master} - {item.nome_operadora}
                </MenuItem>
              ))}
            </ControlSelect>
          </Stack>

          <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
            <ControlInputNumber
              control={control}
              nameControl={'initialHitRate'}
              label={t('dialControl.queues.propsQueue.label-initialHitRate')}
              defaultValue={parseFloat(dataSelectedQueue.taxa_acerto_inicial)}
              error={Boolean(errors.initialHitRate)}
            />

            <ControlInput
              control={control}
              nameControl={'acwSeconds'}
              label={t('dialControl.queues.propsQueue.label-acwSeconds')}
              defaultValue={dataSelectedQueue.segundos_acw}
              type='number'
              inputProps={{ min: 0 }}
              error={Boolean(errors.acwSeconds)}
            />

            <ControlInput
              control={control}
              nameControl={'ringtonesNotAnswered'}
              label={t(
                'dialControl.queues.propsQueue.label-ringtonesNotAnswered'
              )}
              defaultValue={dataSelectedQueue.toques_nao_atende}
              type='number'
              inputProps={{ min: 0 }}
              error={Boolean(errors.ringtonesNotAnswered)}
            />
          </Stack>

          <Stack
            direction={{ xs: 'column', md: 'row' }}
            spacing={2}
            justifyContent={'space-between'}
          >
            <Box width={'100%'}>
              <ControlRadio
                control={control}
                defaultValue={
                  dataSelectedQueue.disca_local
                    ? labelActiveRadio
                    : labelDisabledRadio
                }
                nameControl='localDial'
                labelRadio={t('dialControl.queues.propsQueue.label-localDial')}
                labelValue1={labelActiveRadio}
                labelValue2={labelDisabledRadio}
                error={Boolean(errors.localDial)}
              />
            </Box>
            <Box width={'100%'}>
              <ControlRadio
                control={control}
                defaultValue={
                  dataSelectedQueue.disca_ldn
                    ? labelActiveRadio
                    : labelDisabledRadio
                }
                nameControl='ldnDial'
                labelRadio={t('dialControl.queues.propsQueue.label-ldnDial')}
                labelValue1={labelActiveRadio}
                labelValue2={labelDisabledRadio}
                error={Boolean(errors.ldnDial)}
              />
            </Box>
            <Box width={'100%'}>
              <ControlRadio
                control={control}
                defaultValue={
                  dataSelectedQueue.filtra_secretaria
                    ? labelActiveRadio
                    : labelDisabledRadio
                }
                nameControl='filterSecretary'
                labelRadio={t(
                  'dialControl.queues.propsQueue.label-filterSecretary'
                )}
                labelValue1={labelActiveRadio}
                labelValue2={labelDisabledRadio}
                error={Boolean(errors.filterSecretary)}
              />
            </Box>
            <Box width={'100%'}>
              <ControlRadio
                control={control}
                defaultValue={
                  dataSelectedQueue.disca_sem_agentes
                    ? labelActiveRadio
                    : labelDisabledRadio
                }
                nameControl='dialWithoutAgents'
                labelRadio={t(
                  'dialControl.queues.propsQueue.label-dialWithoutAgents'
                )}
                labelValue1={labelActiveRadio}
                labelValue2={labelDisabledRadio}
                error={Boolean(errors.dialWithoutAgents)}
              />
            </Box>
          </Stack>
        </div>
        <div className='footer'>
          <ButtonStyled
            variantstyle={'secondary'}
            size='small'
            onClick={handleBackStage}
          >
            {t('dialControl.queues.propsQueue.btn-cancel')}
          </ButtonStyled>
          <ButtonStyled type='submit' size='small'>
            {t('dialControl.queues.propsQueue.btn-save')}
          </ButtonStyled>
        </div>
      </FormPropsQueues>
    </ContainerPropsQueue>
  );
};
