import { useTranslation } from 'react-i18next';
import { DialogComponent } from '@/components/DialogComponent';
import { ButtonStyled } from '@/components/FormComponents/ButtonComponent';
import { FormConfirmation, PanelConfirmation } from './styled';
import { InputComponent } from '@/components/FormComponents/InputComponent';
import { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { dataListClients } from '@/store/Clients';
import { SelectComponent } from '@/components/FormComponents/SelectInput';
import { Alert, MenuItem } from '@mui/material';
import { adminClientIdSelected, templateSelected } from '@/store/FlowBuilder';
import { user } from '@/store/AuthenticatorStore';
import { Box } from '@mui/system';

export const ConfirmationToSave = ({
  open,
  close,
  actionConfirm,
  actionClose = false,
}) => {
  const { t } = useTranslation();
  const [newDescription, setNewDescription] = useState('');
  const [listClients] = useAtom(dataListClients);
  const [clientSelected, setClientSelected] = useAtom(adminClientIdSelected);
  const [template] = useAtom(templateSelected);
  const { client_id } = template;

  const [currentUser] = useAtom(user);
  const { isSysAdmin } = currentUser.userRole;

  useEffect(() => {
    if (clientSelected === '0') {
      setClientSelected(client_id);
    }
  }, []);

  useEffect(() => {
    return () => setNewDescription('');
  }, []);

  const isSameCustomer = clientSelected === client_id;

  const handleConfirm = (e) => {
    e.preventDefault();
    actionConfirm(newDescription, isSameCustomer);
    setNewDescription('');
    close(false);
  };

  const handleClose = () => {
    setNewDescription('');
    actionClose && actionClose();
    close(false);
  };

  return (
    <DialogComponent open={open} onClose={() => close(false)} fullWidth={true}>
      <FormConfirmation onSubmit={handleConfirm}>
        <p>{t('flowBuilder.flow.navigationFlow.text-message-save-flow')}</p>

        {isSysAdmin && (
          <>
            {!isSameCustomer && (
              <Box width={'100%'}>
                <Alert severity='warning'>
                  {t('flowBuilder.flow.navigationFlow.msg-redirection-user')}
                </Alert>
              </Box>
            )}
            <SelectComponent
              labelSelect={'Clients'}
              value={clientSelected}
              onChange={(e) => setClientSelected(e.target.value)}
            >
              <MenuItem value={'0'} dense disabled>
                Select Client
              </MenuItem>
              {listClients.length > 0 &&
                listClients.map((item) => {
                  return (
                    <MenuItem key={item.id} value={item.id} dense>
                      {item.commercial_name.toUpperCase()}
                    </MenuItem>
                  );
                })}
            </SelectComponent>
          </>
        )}

        <InputComponent
          label={t(
            'flowBuilder.flow.navigationFlow.label-description-save-flow'
          )}
          value={newDescription}
          onChange={(e) => setNewDescription(e.target.value)}
          inputProps={{ maxLength: 60 }}
        />
        <PanelConfirmation>
          <ButtonStyled
            variantstyle='secondary'
            onClick={handleClose}
            size='small'
          >
            {t('flowBuilder.flow.navigationFlow.btn-confirmation-cancel-flow')}
          </ButtonStyled>
          <ButtonStyled size='small' type={'submit'}>
            {t('flowBuilder.flow.navigationFlow.btn-confirmation-save-flow')}
          </ButtonStyled>
        </PanelConfirmation>
      </FormConfirmation>
    </DialogComponent>
  );
};
