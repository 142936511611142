import { BoxAgent } from './BoxAgent';

import { ContainerBoxMonitor } from './styled';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTheme } from 'styled-components';
import { ContentBot } from './ContentBot';
import { HeaderCard } from './HeaderCard';

export const ContentAgent = ({
  queueName,
  campaign,
  talking,
  discing,
  agents,
  statusQueue,
}) => {
  const theme = useTheme();
  return (
    <ContainerBoxMonitor>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className='expandIconAccordion' />}
          aria-controls={`${queueName}-content`}
          id={`${queueName}-header`}
          sx={{
            backgroundColor: theme.colors.containers.primaryToDark,
            borderTopRightRadius: '2px',
            borderTopLeftRadius: '2px',
          }}
        >
          <HeaderCard
            nameQueue={queueName}
            campaign={campaign}
            talking={talking}
            discing={discing}
            statusQueue={statusQueue}
          />
        </AccordionSummary>
        <AccordionDetails
          sx={{
            backgroundColor: theme.colors.containers.colorCard,
          }}
        >
          <div className='content-agents'>
            {Object.keys(agents).map((key) => {
              return <BoxAgent key={key} infoAgent={agents[key]} />;
            })}
          </div>

          <ContentBot
            talking={talking}
            discing={discing}
            status={statusQueue}
          />
        </AccordionDetails>
      </Accordion>
    </ContainerBoxMonitor>
  );
};
