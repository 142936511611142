import { useEffect, useRef, useState } from 'react';
import * as yup from 'yup';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/system';
import { IoSaveSharp } from 'react-icons/io5';
import useFormControl from '@/hooks/useFormControl';
import { ControlInput } from '@/components/FormComponents/InputComponent';
import { DescriptionInformation } from '@/components/layout/DescriptionInformation';
import { useSaveTemplateLoadList } from '@/components/pages/LoadList/hooks/useSaveTemplate';
import { useLoadList } from '@/hooks/LoadList/useLoadList';
import { TitleComponent } from '@/components/layout/TitleComponent';
import { ButtonStyled } from '@/components/FormComponents/ButtonComponent';
import { DialogComponent } from '@/components/DialogComponent';
import {
  dataConfirmationToSave,
  dataTemplateMailing,
  hasSavedTemplateMailing,
  isLoadingChangeNewFlow,
  isLoadingRenderFlow,
  selectedTemplate,
} from '@/store/LoadList';
import {
  BtnSaveConfirmation,
  ContainerSaveTemplateMailing,
  ContentConfirmation,
  DataConfirmationContent,
  FormSaveConfirmation,
} from './styled';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';

const ConfirmationSaveModal = ({
  handleClose,
  nextAction,
  layoutSelected,
  dataLayout,
}) => {
  const { t } = useTranslation();
  const { handleSaveTemplateLoadList } = useSaveTemplateLoadList();
  const {
    mutateCreateTemplateLoadList,
    loadingCreateList,
    loadingCreateTemplateLoadList,
  } = useLoadList(false);
  const [, setHasSavedTemplate] = useAtom(hasSavedTemplateMailing);
  const [, setSelectedLayout] = useAtom(selectedTemplate);
  const [hasClickToSave, setHasClickToSave] = useState(false);
  const [, setLoadingDataFlow] = useAtom(isLoadingRenderFlow);
  const abortControllerRef = useRef(null);

  useEffect(() => {
    abortControllerRef.current = new AbortController();
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, []);

  const handleNext = () => {
    setLoadingDataFlow(true);
    setHasClickToSave(false);
    nextAction();
    handleClose();
  };

  const renderNameLayoutSelected = () => {
    if (layoutSelected === '0') {
      return '';
    }
    return dataLayout.metadata.name;
  };

  const handleSaveTemplate = () => {
    setHasClickToSave(true);
  };

  const saveTemplateSchema = yup.object().shape({
    templateName: yup
      .string()
      .required()
      .matches(
        /^[a-zA-Z0-9_-]*$/,
        'A descrição só pode conter letras, números, _ e -'
      ),
    templateDescription: yup
      .string()
      .required()
      .matches(
        /^[a-zA-Z0-9_-]*$/,
        'A descrição só pode conter letras, números, _ e -'
      ),
  });

  const { handleSubmit, control, errors, reset } =
    useFormControl(saveTemplateSchema);

  const handleCancelSaveTemplate = () => {
    setHasClickToSave(false);
    reset();
  };

  const saveTemplate = (data) => {
    setLoadingDataFlow(true);
    const { templateName, templateDescription } = data;
    const dataCreate = handleSaveTemplateLoadList(
      templateName,
      templateDescription
    );
    mutateCreateTemplateLoadList(
      { dataCreate, signal: abortControllerRef.current.signal },
      {
        onSuccess: (data) => {
          setSelectedLayout(data.metadata.id);
          reset();
          setHasSavedTemplate(true);
          handleNext();
        },
      }
    );
  };

  return (
    <ContainerSaveTemplateMailing>
      {loadingCreateTemplateLoadList && <BackDropCircularLoading />}
      <TitleComponent
        textTitle={t(
          'listLoader.importMailing.flow.saveConfirmation.title-change'
        )}
        iconTitle='config'
      />
      <ContentConfirmation>
        {!hasClickToSave ? (
          <DataConfirmationContent
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
          >
            <Stack direction={`column`} gap={1}>
              <p>
                <strong>{renderNameLayoutSelected()} </strong>
                {t(
                  'listLoader.importMailing.flow.saveConfirmation.spell-changes'
                )}
              </p>
              <p>
                {t(
                  'listLoader.importMailing.flow.saveConfirmation.spell-confirm'
                )}
              </p>
            </Stack>
            <Stack direction={`row`} gap={1} justifyContent={'space-between'}>
              <BtnSaveConfirmation
                whileTap={{ scale: 0.9 }}
                onClick={handleSaveTemplate}
                className={'btn-save'}
                title={t('listLoader.importMailing.flow.btn-save')}
              >
                <IoSaveSharp />
              </BtnSaveConfirmation>

              <Stack direction={`row`} gap={1} justifyContent={'flex-end'}>
                <ButtonStyled
                  onClick={handleClose}
                  size={'small'}
                  variantstyle={'secondary'}
                >
                  <span>
                    {t(
                      'listLoader.importMailing.flow.saveConfirmation.btn-cancel'
                    )}
                  </span>
                </ButtonStyled>

                <ButtonStyled size={'small'} onClick={handleNext}>
                  <span>
                    {t(
                      'listLoader.importMailing.flow.saveConfirmation.btn-next'
                    )}
                  </span>
                </ButtonStyled>
              </Stack>
            </Stack>
          </DataConfirmationContent>
        ) : (
          <FormSaveConfirmation
            onSubmit={handleSubmit(saveTemplate)}
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
          >
            <Stack direction={`column`} gap={3}>
              <Stack direction={`column`} gap={3}>
                <DescriptionInformation
                  textDescription={t(
                    'listLoader.importMailing.flow.description-save-template'
                  )}
                />
                <ControlInput
                  control={control}
                  nameControl={'templateName'}
                  label={t(
                    'listLoader.importMailing.flow.placeholder-save-template'
                  )}
                  error={Boolean(errors.templateName)}
                  disabled={loadingCreateList || loadingCreateTemplateLoadList}
                />
                <ControlInput
                  control={control}
                  nameControl={'templateDescription'}
                  label={t(
                    'listLoader.importMailing.flow.placeholder-save-description'
                  )}
                  error={Boolean(errors.templateDescription)}
                  disabled={loadingCreateList || loadingCreateTemplateLoadList}
                />
              </Stack>
              <Stack direction={`row`} gap={1} justifyContent={'flex-end'}>
                <ButtonStyled
                  onClick={handleCancelSaveTemplate}
                  size={'small'}
                  variantstyle={'secondary'}
                  disabled={loadingCreateList || loadingCreateTemplateLoadList}
                >
                  <span>
                    {t(
                      'listLoader.importMailing.flow.saveConfirmation.btn-cancel'
                    )}
                  </span>
                </ButtonStyled>

                <ButtonStyled
                  size={'small'}
                  type='submit'
                  disabled={loadingCreateList || loadingCreateTemplateLoadList}
                >
                  <span>{t('listLoader.importMailing.flow.btn-save')}</span>
                </ButtonStyled>
              </Stack>
            </Stack>
          </FormSaveConfirmation>
        )}
      </ContentConfirmation>
    </ContainerSaveTemplateMailing>
  );
};

export const ConfirmationToSave = () => {
  const [confirmationToSave, setConfirmationToSave] = useAtom(
    dataConfirmationToSave
  );
  const [, setLoadingDataFlow] = useAtom(isLoadingRenderFlow);
  const [layoutSelected] = useAtom(selectedTemplate);
  const [dataTemplates] = useAtom(dataTemplateMailing);
  const [, setLoadingChangeNewFlow] = useAtom(isLoadingChangeNewFlow);

  const handleCloseConfirmation = () => {
    setLoadingDataFlow(false);
    setLoadingChangeNewFlow(false);
    setConfirmationToSave({
      open: false,
      next: null,
    });
  };

  return (
    <DialogComponent
      open={confirmationToSave.open}
      onClose={handleCloseConfirmation}
      fullWidth={true}
    >
      <ConfirmationSaveModal
        handleClose={handleCloseConfirmation}
        nextAction={confirmationToSave.next}
        layoutSelected={layoutSelected}
        dataLayout={dataTemplates}
      />
    </DialogComponent>
  );
};
