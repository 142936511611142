import { useAtom } from 'jotai';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import {
  focusedFieldArea,
  isSavedFlow,
  selectedNode,
  showMenuNodes,
} from '@/store/FlowBuilder';
import { InputComponent } from '@/components/FormComponents/InputComponent';
import { ButtonStyled } from '@/components/FormComponents/ButtonComponent';
import { FooterAddNode, FormMenuNode, MainAddNode } from './styled';
import { checkLabelExistiNode } from '@/components/pages/FlowBuilder/utils/checkNameNodes';
import { errorToast } from '@/components/FormComponents/Toast';
import { useTranslation } from 'react-i18next';
import { TitleMenuNode } from '../TitleMenuNode';
import { SelectComponent } from '@/components/FormComponents/SelectInput';
import { MenuItem } from '@mui/material';
import { useQueues } from '@/hooks/dialControl/Queues/useQueues';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';
import { dataQueues } from '@/store/Queues';

const dataOptionsTrunks = [
  {
    name: 'Toledo',
    value: 'toledo',
  },
  {
    name: 'Flamboyant',
    value: 'flamboyant',
  },
  {
    name: 'PGMais',
    value: 'pgmais',
  },
  {
    name: 'JA Rezende ',
    value: 'ja rezende ',
  },
  {
    name: 'Younique',
    value: 'younique',
  },
  {
    name: 'CDL',
    value: 'cdl',
  },
];
export const TransferMenu = ({ dataNodes }) => {
  const { t } = useTranslation();
  const { loadingDataQueues } = useQueues();
  const [listQueues] = useAtom(dataQueues);

  const [, setFocusedArea] = useAtom(focusedFieldArea);
  const [, setShowMenu] = useAtom(showMenuNodes);
  const [selected, setSelected] = useAtom(selectedNode);
  const [labelNode, setLabelNode] = useState('');

  const [, setIsSaved] = useAtom(isSavedFlow);

  const [typeTransfer, setTypeTransfer] = useState('0');
  const [trunkSelected, setTrunkSelected] = useState('0');
  const [queueSelected, setQueueSelected] = useState('0');
  const [mcdu, setMcdu] = useState('');

  const [labelNodeError, setLabelNodeError] = useState('');

  const { nodes, setNodes } = dataNodes;
  const { id, data } = selected;

  useEffect(() => {
    if (selected) {
      setLabelNode(data.label);
      setTypeTransfer(data.typeTransfer);
      if (data.typeTransfer === 'queue') {
        setQueueSelected(data.transferTo);
      }
      if (data.typeTransfer === 'trunk') {
        setTrunkSelected(data.transferTo);
        setMcdu(data.mcdu ?? '');
      }
    }
  }, [selected]);

  const validateLabelNode = (value) => {
    if (!value.trim()) {
      setLabelNodeError(
        t('flowBuilder.flow.nodes.hangUp.menu.msg-name-required')
      );
    } else if (value.length > 50) {
      setLabelNodeError(
        t('flowBuilder.flow.nodes.hangUp.menu.msg-name-length', {
          length: '50',
        })
      );
    } else if (!/^[_a-zA-Z0-9]*$/.test(value)) {
      setLabelNodeError(
        t('flowBuilder.flow.nodes.hangUp.menu.msg-name-letters')
      );
    } else {
      setLabelNodeError('');
    }
    setLabelNode(value);
  };

  const closeMenu = () => {
    setShowMenu(false);
    setLabelNode('');
    setSelected(null);
  };

  const handleChildClick = (e) => {
    e.stopPropagation();
  };

  const handleSubmitNewNode = (e) => {
    e.preventDefault();

    const checkLabel = checkLabelExistiNode(id, labelNode, nodes);

    if (checkLabel) {
      return errorToast(t('flowBuilder.flow.nodes.hangUp.menu.msg-name-used'));
    }

    const renderTransferTo =
      typeTransfer === 'trunk' ? trunkSelected : queueSelected;

    const filteredNode = nodes.map((node) =>
      node.id === id
        ? {
            ...node,
            data: {
              ...node.data,
              label: labelNode,
              typeTransfer: typeTransfer,
              transferTo: renderTransferTo,
              mcdu,
            },
          }
        : node
    );
    setNodes(filteredNode);
    setIsSaved(false);
    closeMenu();
  };

  const disabledSubmit =
    typeTransfer === 'trunk' ? trunkSelected === '0' : queueSelected === 0;

  return (
    <FormMenuNode
      onClick={handleChildClick}
      onSubmit={handleSubmitNewNode}
      id='LoopMenuForm'
      name='LoopMenuForm'
    >
      {loadingDataQueues && <BackDropCircularLoading />}

      <MainAddNode>
        <TitleMenuNode
          title={t('flowBuilder.flow.nodes.transferQueue.menu.title')}
          description={t('flowBuilder.flow.nodes.transferQueue.description')}
        />

        <InputComponent
          label={t('flowBuilder.flow.nodes.transferQueue.menu.label-name')}
          value={labelNode}
          onChange={(e) => validateLabelNode(e.target.value)}
          inputProps={{ maxLength: 51 }}
          helperText={labelNodeError}
          error={Boolean(labelNodeError)}
          onFocus={() => setFocusedArea(true)}
          onBlur={() => setFocusedArea(false)}
        />

        <SelectComponent
          defaultValue='0'
          labelSelect={t(
            'flowBuilder.flow.nodes.transferQueue.menu.label-typeTransfer'
          )}
          value={typeTransfer}
          onChange={(event) => setTypeTransfer(event.target.value)}
          onFocus={() => setFocusedArea(true)}
          onBlur={() => setFocusedArea(false)}
        >
          <MenuItem value={'0'} dense disabled>
            {t(
              'flowBuilder.flow.nodes.transferQueue.menu.options-typeTransfer'
            )}
          </MenuItem>
          <MenuItem value={'trunk'} dense>
            {t('flowBuilder.flow.nodes.transferQueue.menu.options-typeTrunk')}
          </MenuItem>
          <MenuItem value={'queue'} dense>
            {t('flowBuilder.flow.nodes.transferQueue.menu.options-typeQueue')}
          </MenuItem>
        </SelectComponent>

        {typeTransfer === 'trunk' && (
          <motion.div
            className=''
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: '2rem',
            }}
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
          >
            <SelectComponent
              defaultValue='0'
              labelSelect={t(
                'flowBuilder.flow.nodes.transferQueue.menu.label-trunk'
              )}
              value={trunkSelected}
              onChange={(event) => setTrunkSelected(event.target.value)}
              onFocus={() => setFocusedArea(true)}
              onBlur={() => setFocusedArea(false)}
            >
              <MenuItem value={'0'} dense disabled>
                {t(
                  'flowBuilder.flow.nodes.transferQueue.menu.options-selectTrunk'
                )}
              </MenuItem>
              {dataOptionsTrunks.map((opt, i) => (
                <MenuItem key={i} value={opt.value} dense>
                  {opt.name}
                </MenuItem>
              ))}
            </SelectComponent>

            <InputComponent
              label={t('MCDU')}
              value={mcdu}
              onChange={(e) => setMcdu(e.target.value)}
              inputProps={{ maxLength: 40 }}
              onFocus={() => setFocusedArea(true)}
              onBlur={() => setFocusedArea(false)}
            />
          </motion.div>
        )}

        {typeTransfer === 'queue' && (
          <motion.div
            className=''
            style={{ width: '100%' }}
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
          >
            <SelectComponent
              defaultValue='0'
              labelSelect={t(
                'flowBuilder.flow.nodes.transferQueue.menu.label-queue'
              )}
              value={queueSelected}
              onChange={(event) => setQueueSelected(event.target.value)}
              onFocus={() => setFocusedArea(true)}
              onBlur={() => setFocusedArea(false)}
            >
              <MenuItem value={'0'} dense disabled>
                {t(
                  'flowBuilder.flow.nodes.transferQueue.menu.options-selectQueue'
                )}
              </MenuItem>
              {listQueues.length > 0 &&
                listQueues.map((item, i) => (
                  <MenuItem key={i} value={item.fila} dense>
                    {item.fila} - {item.campanha}
                  </MenuItem>
                ))}
            </SelectComponent>
          </motion.div>
        )}
      </MainAddNode>
      <FooterAddNode>
        <ButtonStyled
          variantstyle={'secondary'}
          size={'small'}
          onClick={closeMenu}
        >
          {t('flowBuilder.flow.nodes.loop.menu.btn-cancel')}
        </ButtonStyled>
        <ButtonStyled
          type='submit'
          size={'small'}
          disabled={
            Boolean(labelNodeError) ||
            labelNode.length === 0 ||
            typeTransfer === '0' ||
            disabledSubmit
          }
        >
          {t('flowBuilder.flow.nodes.loop.menu.btn-updated')}
        </ButtonStyled>
      </FooterAddNode>
    </FormMenuNode>
  );
};
