import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ContainerTableSchedules = styled(motion.div)`
  width: 100%;
  height: 100%;
  min-height: 200px;
  user-select: none;

  .cellDaysWeek {
    display: flex;
    justify-content: flex-start;
    gap: 8px;
    cursor: pointer;

    span {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 20px;
      width: 20px;
      background-color: ${(props) => props.theme.colors.primary};
      color: ${(props) => props.theme.colors.typography.activeTextSidebar};
      border-radius: 50%;
      font-weight: 500;
    }
  }

  table {
    tbody {
      tr {
        height: 42px;
        td {
          z-index: 1;
          padding: 0 0.8rem;

          &:nth-child(2) {
            width: 70px;
            min-width: 70px !important;
          }

          &:nth-child(3) {
            width: 70px;
            min-width: 70px !important;
          }

          &:nth-child(4) {
            width: 40px;
            min-width: 40px !important;
          }

          &:nth-child(16) {
            width: 40px;
            min-width: 40px !important;
          }

          &:first-child {
            width: 70px !important;
            min-width: 120px !important;
          }

          &:last-child {
            width: 5px;
            button {
              min-width: 8px;
              padding: 4px;
            }
          }
        }
      }
    }
  }
`;
