import { StyledButton } from './styled';

export const ButtonStyled = ({
  children,
  variantstyle = 'primary',
  isActive = 0,
  ...props
}) => {
  return (
    <StyledButton
      variant='contained'
      variantstyle={variantstyle}
      {...props}
      active={isActive}
      aria-label='Button'
    >
      {children}
    </StyledButton>
  );
};
