import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useControlTimeAgent } from '@hooks/Agents/useControlTimeAgent';
import { MdPersonPin } from 'react-icons/md';
import { ContainerServiceAgent } from './styled';

export const AvailableService = () => {
  const { t } = useTranslation();
  const { removeLocalStatus } = useControlTimeAgent();

  useEffect(() => {
    removeLocalStatus('availableStatus');
  }, []);

  return (
    <ContainerServiceAgent initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <MdPersonPin />
      <span>{t('agents.availableService.titleCard')}</span>
    </ContainerServiceAgent>
  );
};
