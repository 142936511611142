import { ButtonStyled } from '@/components/FormComponents/ButtonComponent';
import { ContainerConfig, FooterConfigForm, MainConfigForm } from './styled';
import { useVariablesFlow } from '@/hooks/FlowBuilder/useVariablesFlow';
import { useAtom } from 'jotai';
import { dataNlus, templateSelected } from '@/store/FlowBuilder';
import { ContainerGlobalVars } from '../ConfigDispositions/styled';
import { DescriptionInformation } from '@/components/layout/DescriptionInformation';
import { DragTransferList } from '../../DragTransferList';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';
import { useEndpoints } from '@/hooks/FlowBuilder/useEndpoints';

export const ConfigNLU = ({ changeStateFlow }) => {
  const { t } = useTranslation();
  const { loadingNlus } = useEndpoints();
  const [nlus] = useAtom(dataNlus);
  const [dataNLUsSelected, setDataNLUsSelected] = useState([]);
  const [, setTemplate] = useAtom(templateSelected);
  const handleBackStage = () => {
    changeStateFlow(2);
  };

  const handleNextStage = () => {
    setTemplate((previousState) => {
      return {
        ...previousState,
        nlus: dataNLUsSelected,
      };
    });
    changeStateFlow(4);
  };
  return (
    <ContainerConfig initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <MainConfigForm>
        <ContainerGlobalVars>
          {loadingNlus && <BackDropCircularLoading />}
          <DescriptionInformation
            textDescription={t('flowBuilder.newFlow.stages.nlus.description')}
          />
          {nlus.length > 0 && (
            <DragTransferList
              codeList={nlus}
              changeDataCodeList={setDataNLUsSelected}
            />
          )}
        </ContainerGlobalVars>
      </MainConfigForm>
      <FooterConfigForm>
        <ButtonStyled
          type='button'
          size={'small'}
          variantstyle='secondary'
          onClick={handleBackStage}
        >
          {t('flowBuilder.newFlow.stages.btn.btn-back')}
        </ButtonStyled>
        <ButtonStyled size={'small'} onClick={handleNextStage}>
          {t('flowBuilder.newFlow.stages.btn.btn-next')}
        </ButtonStyled>
      </FooterConfigForm>
    </ContainerConfig>
  );
};
