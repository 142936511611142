import { DialogComponent } from '@/components/DialogComponent';
import { ContentControlClient } from './styled';
import { motion } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import { FaGear } from 'react-icons/fa6';
import { ChangeClienteControl } from './ChangeClienteControl';
import { useClients } from '@/hooks/Clients/useClients';

export const ControlClient = () => {
  const contentRef = useRef(null);
  const { isLoadingDataClients } = useClients();
  const [openConfigClientUser, setOpenConfigClientUser] = useState(false);
  const [isOpenControl, setIsOpenControl] = useState(false);

  const opemConfigClientUser = () => {
    setOpenConfigClientUser(true);
  };

  const closeConfigCLientUser = () => {
    setOpenConfigClientUser(false);
  };

  const handleOpenControl = () => {
    if (openConfigClientUser) {
      return;
    }
    setIsOpenControl((prev) => !prev);
  };

  useEffect(() => {
    if (openConfigClientUser) {
      return;
    }

    const handleClickOutside = (event) => {
      if (
        contentRef.current &&
        !contentRef.current.contains(event.target) &&
        !openConfigClientUser
      ) {
        setIsOpenControl(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      if (openConfigClientUser) {
        return;
      }
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [contentRef, openConfigClientUser]);

  return (
    <ContentControlClient
      ref={contentRef}
      onClick={handleOpenControl}
      initial={{ width: '10px' }}
      animate={{
        width: isOpenControl ? '40px' : '10px',
        transition: { duration: 0.05 },
      }}
    >
      {isOpenControl && (
        <motion.button
          type='button'
          whileTap={{ scale: 0.95 }}
          initial={{ opacity: 0 }}
          animate={{
            opacity: isOpenControl ? 1 : 0,
            transition: { delay: 0.05 },
          }}
          onClick={opemConfigClientUser}
          disabled={isLoadingDataClients}
          aria-label='Show Control'
          aria-hidden={!opemConfigClientUser}
        >
          {isLoadingDataClients ? '' : <FaGear />}
        </motion.button>
      )}

      <DialogComponent
        open={openConfigClientUser}
        onClose={closeConfigCLientUser}
        fullWidth={true}
      >
        <ChangeClienteControl handleClose={closeConfigCLientUser} />
      </DialogComponent>
    </ContentControlClient>
  );
};
