export const light = {
  title: 'Light',
  colors: {
    defaultColors: {
      black: '#000000',
      white: '#FFFFFF',
      gray: '#e5e5e5',
      pink: '#ff57AB',
      green: '#43ff86',
      blue: '#4c48ff',
      yellow: '#FFb839',
      orange: '#FF4C43',
      purple: '#a03ffc',
    },

    primary: '#4c48ff',
    secondary: '#FF4C43',
    primaryLight: '#9997f7',
    primaryDark: '#2825d0',
    active: '#787878',
    success: '#0cc04b',
    error: '#ED1414',

    nodesFlow: {
      dialplanVariables: '#4c48ff',
      ttsVoicceLabs: '#009905',
      recVoz: '#2825d0',
      hangUp: '#ff0000',
      conditionalNode: '#9997f7',
      disposition: '#FFb839',

      baseEdge: '#575757',
      initNode: '#4c48ff',
      itemRecVoicceNode: '#672e93',
      setVariables: '#1815cb',
      dial: '#f01985',
      transferQueue: '#8cbe37',
      apiData: '#2bb495',
      functionHandler: '#696969',
      monestIa: 'rgb(102, 0, 204)',
      deepCenter: '#40719F',
      loop: '#ff57AB',

      successEdge: '#4c48ff',
      errorEdge: '#FF4C43',
    },

    listNodes: {
      bgCard: '#FFFFFF',
      bgBtn: '#4c48ff',
      bgCustomEdge: 'rgb(34 37 42)',
      bgSystemEdge: '#5037e9',
      bgErrorEdge: '#FF4C43',
    },

    agentColors: {
      PAUSA: '#FFb839',
      AGLIG: '#0edd56',
      EMLIG: '#4c48ff',
      POSLIG: '#FF4C43',
      LIVRE: '#101010',
      DISCONNECTED: '#9997f7',
    },
    bases: {
      primary: '#4c48ff',
      secondary: '#FF7A00',
      primaryLight: '#8381f8',
      primaryDark: '#0400ff',
      gradientBg: 'linear-gradient( 272.02deg, #4c48ff 0.94%, #4c48ff 102.07%)',
      baseWhite: '#FFFFFF',
    },
    tables: {
      tableHead: '#4c48ff',
      tableRow: '#FFFFFF',
    },
    containers: {
      baseContainer: '#e5e5e5',
      baseContainerFlow: '#e8e8e8c2',
      baseBackground: '#f5f5f5c2',
      shadowToContainers: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      baseShadows: '2px 2px 4px rgba(39, 57, 94, 0.25)',
      colorCard: '#FFFFFF',
      colorNode: '#FFFFFF',
      colorSecondNode: '#FFFFFF',
      colorCardSecondary: '#F4F4F4',
      colorCardThird: '#FFFFFF',
      dividerColor: '#c6c6c6',
      primaryToDark: '#4c48ff',
    },
    typography: {
      baseTitle: '#4c48ff',
      baseText: '#000000',
      textHeaderTable: '#FFFFFF',
      activeTextSidebar: '#FFFFFF',
      titleSidebarAgent: '#575757',
    },
  },
};
