import styled, { css } from 'styled-components';

export const NodeConditionContainer = styled.div`
  background: ${(props) => props.theme.colors.containers.colorSecondNode};
  border-radius: 4px;
  min-height: 190px;

  &::before,
  &::after {
    content: '';
    position: absolute;
    border-radius: 4px;
    left: -2px;
    top: -2px;
    background: linear-gradient(
      45deg,
      #4c48ff,
      #2825d0,
      #9997f7,
      #4c48ff,
      #2825d0,
      #9997f7
    );

    background-size: 400%;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    z-index: -1;
    animation: steam 26s linear infinite;

    ${(props) =>
      props.selected &&
      css`
        width: calc(100% + 8px);
        height: calc(100% + 8px);
        left: -4px;
        top: -4px;
        background: #00c8f0;
      `}

    ${(props) =>
      props.errorConnect &&
      css`
        background: linear-gradient(45deg, red, red, red, red, red, red);
      `}
  }

  @keyframes steam {
    0% {
      background-position: 0 0;
    }
    50% {
      background-position: 400% 0;
    }
    100% {
      background-position: 0 0;
    }
  }

  .react-flow__handle {
    background: transparent;
    width: 14px;
    height: 100%;
    border-radius: 0;
    border: none;
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 10px;
      width: 8px;
      height: 8px;
      border: 2px solid ${(props) => props.theme.colors.containers.colorCard};
      background: ${(props) => props.theme.colors.typography.baseTitle};
    }
  }

  .react-flow__handle-left {
    left: -7px;
  }

  .panelNode {
    width: 100%;
    position: absolute;
    right: 4px;
    top: 4px;
    opacity: 0;
    transition: all 0.1s ease-in-out;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 4px;
    gap: 10px;

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      background: transparent;

      svg {
        font-size: 1.3rem;
        color: #fff;
      }
    }
  }

  &:hover {
    > .panelNode {
      opacity: 1;
      /* top: -26px; */
    }
  }

  .headerNode {
    display: flex;
    flex-direction: column;
    word-break: break-all;
    font-size: 0.6rem;
  }
`;

export const NodeContent = styled.div`
  min-width: 220px;
  display: grid;
  grid-template-rows: 40px 1fr;

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    background: ${(props) => props.theme.colors.primary};
    padding: 3px;
    gap: 0.6rem;
    padding-left: 0.6rem;
    svg {
      color: #ffff;
      font-size: 1.4rem;
    }
    .titleNode {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      h4 {
        color: #ffff;
        text-transform: uppercase;
        font-size: 0.8rem;
      }
    }

    span {
      font-size: 0.6rem;
      text-transform: uppercase;
      color: #ffff;
    }
  }

  .main {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 10px;
    gap: 0.6rem;

    .trueSkip {
      span {
        font-size: 0.78rem;
        color: ${(props) => props.theme.colors.agentColors.AGLIG};
        font-weight: 600;
      }

      svg {
        color: ${(props) => props.theme.colors.agentColors.AGLIG};
      }
    }
    .falseSkip {
      span {
        font-size: 0.78rem;
        color: ${(props) => props.theme.colors.agentColors.PAUSA};
        font-weight: 600;
      }
      svg {
        color: ${(props) => props.theme.colors.agentColors.PAUSA};
      }
    }

    span {
      font-size: 0.74rem;
      color: ${(props) => props.theme.colors.typography.baseText};
    }

    .itemInput {
      background-color: red;
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 30px;
      background: ${(props) => props.theme.colors.containers.baseContainer};
      color: ${(props) => props.theme.colors.bases.baseWhite};
    }

    .itemInputSelected {
      background: ${(props) => props.theme.colors.primary};
    }

    .dividerNode {
      width: 100%;
      height: 1px;
      background-color: #d1d1d136;
    }
  }
`;

export const SubNodeConditionalContainer = styled.div`
  padding: 5px;
  width: 220px;
  border-radius: 2px;
  background: linear-gradient(45deg, #5794ff, #9bbdf8, #005af5);
  background-size: 600% 100%;
  animation: gradient 3s linear infinite;
  animation-direction: alternate;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;

  ${(props) =>
    props.typeHandler === 'successCondition' &&
    css`
      background: linear-gradient(45deg, #3f85fd, #3a80f8);
    `}

  ${(props) =>
    props.typeHandler === 'errorCondition' &&
    css`
      background: linear-gradient(45deg, #ff7a00, #ff8214);
    `} 

  @keyframes gradient {
    0% {
      background-position: 0%;
    }
    100% {
      background-position: 100%;
    }
  }

  .react-flow__handle {
    background: ${(props) => props.theme.colors.primary};
    width: 12px;
    height: 12px;
    border-radius: 10px;
    border: 2px solid ${(props) => props.theme.colors.containers.colorCard};
    right: 4px;

    ${(props) =>
      props.typeHandler === 'successCondition' &&
      css`
        background: ${(props) => props.theme.colors.primary};
      `}

    ${(props) =>
      props.typeHandler === 'errorCondition' &&
      css`
        background: ${(props) => props.theme.colors.secondary};
      `}
  }
  .headerNode {
    display: flex;
    flex-direction: column;
    word-break: break-all;
    font-size: 0.6rem;

    span {
      font-size: 0.66rem;
      font-weight: 600;
      text-transform: uppercase;
      color: #ffff;
    }
  }
`;
