import { atom } from 'jotai';
import { usePagesComponents } from '../../hooks/Navigator/usePagesComponents';

export const dataListScreens = atom([...usePagesComponents]);

export const dataInicialIdComponent = atom('');
export const currentPage = atom('');
export const idSub = atom(false);
export const haveSubItem = atom(false);
export const isClose = atom(false);
export const isReload = atom(false);
export const hiddenSidebar = atom(true);

export const resetCurrentPageId = atom(
  (get) => get(currentPage),
  (get, set, _arg) => set(currentPage, _arg)
);

export const handleToDialingStore = atom(
  (get) => get(currentPage),
  (get, set) => set(currentPage, '46694cb2-d0d7-409b-9197-295a7bd8e7a5')
);

export const currentPageId = atom(
  (get) => get(currentPage),
  (get, set, _arg) => set(currentPage, _arg)
);

export const idSubItem = atom(
  (get) => get(idSub),
  (get, set, _arg) => set(idSub, _arg)
);

export const hasSubItem = atom(
  (get) => get(haveSubItem),
  (get, set, _arg) => set(haveSubItem, _arg)
);

export const isCloseItem = atom(
  (get) => get(isClose),
  (get, set, _arg) => set(isClose, _arg)
);
