import styled from 'styled-components';

export const ContainerNotAllowedMicrophone = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 4rem;
`;

export const ContentNotAllowedMicrophone = styled.div`
  background-color: ${(props) => props.theme.colors.containers.baseContainer};
  width: 100%;
  padding: 1rem;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  .title-not-allowed {
    h1 {
      color: ${(props) => props.theme.colors.typography.baseText};
      font-size: 1.4rem;
      font-weight: 600;
    }
    svg {
      color: red;
    }
  }

  p {
    color: ${(props) => props.theme.colors.typography.baseText};
    font-size: 1rem;
  }
`;

export const TitleAccordion = styled.h3`
  color: ${(props) => props.theme.colors.typography.baseText};
  font-size: 1rem;
  font-weight: bold;
`;
