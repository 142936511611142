import { MenuItem } from '@mui/material';
import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAgents } from '@hooks/Agents/useAgents';
import { useControlTimeAgent } from '@hooks/Agents/useControlTimeAgent';
import { useLocalStorage } from '@hooks/useLocalStorage';

import {
  dataCallClient,
  dataCallInformation,
  dataTimeToTab,
  durationCallState,
} from '@store/AgentsStore';
import { user } from '@store/AuthenticatorStore';

import { timeFormatEn } from '@/utils/formatsDateAndTime';
import { ButtonStyled } from '@components/FormComponents/ButtonComponent';
import { SelectComponent } from '@components/FormComponents/SelectInput';
import { errorToast } from '@components/FormComponents/Toast';
import { BackDropCircularLoading } from '@components/layout/LoadingComponent';
import { CountDownTimer } from '@components/StopWatchComponent';

import { BsCircleFill } from 'react-icons/bs';
import { MdPhoneIphone } from 'react-icons/md';

import {
  ContainerPostConnection,
  ContentInCall,
  FormPostConnection,
  ItemInCall,
} from './styled';

export const PostConnection = () => {
  const { t } = useTranslation();
  const { getLocalStorage, removeLocalStorage } = useLocalStorage();
  const { removeLocalStatus, manegeTimeToTab } = useControlTimeAgent();
  const { mutateCloseCall, loadingCloseCall } = useAgents();
  const [itemTabPostConnection, setItemTabPostConnection] = useState('0');
  const [currentStopWatch, setCurrentStopWatch] = useState({});
  const [finalTime, setFinalTime] = useState(0);
  const [dataCall] = useAtom(dataCallInformation);
  const [currentUser] = useAtom(user);
  const [dataCurrentClient, setDataCurrentClient] = useAtom(dataCallClient);
  const [timeDuration] = useAtom(durationCallState);
  const currentExtension = currentUser && currentUser.extension;
  const [timeToTab, setCurrentTimeToTab] = useAtom(dataTimeToTab);

  const [endCallCode, setEndCallCode] = useState(null);
  const [errorSelectedCode, setErrorSelectedCode] = useState(false);

  const dataLocal = getLocalStorage('@dc');

  useEffect(() => {
    removeLocalStatus('postCallStatus');
  }, []);

  useEffect(() => {
    if (dataCall) {
      if (dataCall.linha_dados) {
        const dataUserCall = {
          clientName: dataCall.linha_dados.nome_cliente ?? '-',
          dddPhone: `(${dataCall.linha_dados.ddd ?? '-'}) ${
            dataCall.linha_dados.fone ?? '-'
          }`,
          documentClient: dataCall.linha_dados.cpf ?? '-',
          origin: dataCall.linha_dados.origem ?? '-',
          startCall: dataCall.linha_dados.no_agente_datetime ?? null,
          listDialer: dataCall.linha_dados.id_tabela_disca ?? '-',
          clientId: dataCall.linha_dados.cod_link_int ?? '-',
          joker: dataCall.linha_dados.cod_link_char ?? '-',
        };
        setDataCurrentClient(dataUserCall);
      } else if (dataLocal) {
        setDataCurrentClient(dataLocal);
      } else {
        return;
      }
    }
  }, []);

  const time = new Date();
  timeToTab
    ? manegeTimeToTab('@postCallStatus')
      ? time.setSeconds(
          time.getSeconds() +
            Number(timeToTab) -
            manegeTimeToTab('@postCallStatus')
        )
      : time.setSeconds(time.getSeconds() + Number(timeToTab))
    : time.setSeconds(time.getSeconds() + 0);

  useEffect(() => {
    if (finalTime === 1 && currentStopWatch.secondsRender === '00') {
      setErrorSelectedCode(false);
      mutateCloseCall({
        extension: currentExtension,
        code: 'AUTO',
      });
      setDataCurrentClient({});
      setCurrentTimeToTab(null);
      removeLocalStorage('@dc');
    }
  }, [finalTime, currentStopWatch]);

  useEffect(() => {
    itemTabPostConnection !== '0' && setErrorSelectedCode(false);
  }, [itemTabPostConnection]);

  useEffect(() => {
    if (dataCall) {
      if (dataCall.fila_info) {
        setEndCallCode(dataCall.fila_info.finalizacoes);
      }
    }
  }, [dataCall]);

  useEffect(() => {
    currentStopWatch.secondsRender <= 10 &&
    currentStopWatch.secondsRender !== '00'
      ? setFinalTime(1)
      : '';
    return () => setFinalTime(0);
  }, [currentStopWatch]);

  const handleCloseContact = (e) => {
    e.preventDefault();
    if (itemTabPostConnection === '0') {
      setErrorSelectedCode(true);
      errorToast('Select a disposition');
    } else {
      setErrorSelectedCode(false);
      mutateCloseCall({
        extension: currentExtension,
        code: itemTabPostConnection,
      });
      setDataCurrentClient({});
      setCurrentTimeToTab(null);
      removeLocalStorage('@dc');
    }
  };

  const verifyDataRender = (itemRender) => {
    return itemRender ?? '-';
  };

  return (
    <ContainerPostConnection
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      finaltime={finalTime}
    >
      {loadingCloseCall && <BackDropCircularLoading />}
      <div className='headerCard' finaltime={finalTime}>
        <div className='titleCall'>
          <MdPhoneIphone />
          <h3>{t('agents.postCallInformation.titleCard')}</h3>
        </div>

        <div className='statusCallCard'>
          <BsCircleFill />
          <span>{t('agents.postCallInformation.statusCard')}</span>
        </div>
      </div>
      <ContentInCall finaltime={finalTime}>
        <div className='columnItem'>
          <ItemInCall finaltime={finalTime}>
            <span>{t('agents.postCallInformation.nameCard')}:</span>
            <span>{verifyDataRender(dataCurrentClient.clientName)}</span>
          </ItemInCall>
          <ItemInCall finaltime={finalTime}>
            <span>{t('agents.postCallInformation.phoneCard')}:</span>
            <span>{verifyDataRender(dataCurrentClient.dddPhone)}</span>
          </ItemInCall>
          <ItemInCall finaltime={finalTime}>
            <span>{t('agents.postCallInformation.documentCard')}:</span>
            <span>{verifyDataRender(dataCurrentClient.documentClient)}</span>
          </ItemInCall>
        </div>

        <div className='columnItem'>
          <ItemInCall finaltime={finalTime}>
            <span>{t('agents.postCallInformation.dialerList')}:</span>
            <span>{verifyDataRender(dataCurrentClient.listDialer)}</span>
          </ItemInCall>
          <ItemInCall finaltime={finalTime}>
            <span>{t('agents.postCallInformation.clientId')}:</span>
            <span>{verifyDataRender(dataCurrentClient.clientId)}</span>
          </ItemInCall>
          <ItemInCall finaltime={finalTime}>
            <span>{t('agents.postCallInformation.joker')}:</span>
            <span>{verifyDataRender(dataCurrentClient.joker)}</span>
          </ItemInCall>
        </div>

        <div className='columnItem noBorder'>
          <ItemInCall finaltime={finalTime}>
            <span>{t('agents.postCallInformation.typeCallCard')}:</span>
            <span>{verifyDataRender(dataCurrentClient.origin)}</span>
          </ItemInCall>
          <ItemInCall finaltime={finalTime}>
            <span>{t('agents.postCallInformation.startTimeService')}:</span>
            <span>{timeFormatEn(dataCurrentClient.startCall)}</span>
          </ItemInCall>
          <ItemInCall finaltime={finalTime}>
            <span>{t('agents.postCallInformation.durationCallCard')}:</span>
            <span> {timeDuration ? timeDuration : '-'}</span>
          </ItemInCall>
        </div>

        <div className='columnStopWatch'>
          <div className='record_play'></div>
          {timeToTab && (
            <div className='timer_section'>
              <span>{t('agents.postCallInformation.timeLeft')}:</span>
              <CountDownTimer
                expiryTimestamp={time}
                setCurrentStopWatch={setCurrentStopWatch}
              />
            </div>
          )}
        </div>

        <FormPostConnection finaltime={finalTime} onSubmit={handleCloseContact}>
          {endCallCode && (
            <SelectComponent
              labelSelect={t('agents.postCallInformation.labelSelect')}
              value={itemTabPostConnection}
              onChange={(event) => setItemTabPostConnection(event.target.value)}
              error={errorSelectedCode}
              helperText={errorSelectedCode ? 'Select a disposition' : ' '}
            >
              <MenuItem value={'0'}>
                {t('agents.postCallInformation.optionSelectDefault')}
              </MenuItem>
              {endCallCode.map((item, i) => {
                let opt = `${item.cod_lig} - ${item.descricao}`;
                return (
                  <MenuItem value={item.cod_lig.toString()} key={i}>
                    {opt}
                  </MenuItem>
                );
              })}
            </SelectComponent>
          )}
          <div className='footerForm'>
            <ButtonStyled type='submit' variantstyle={'secondary'}>
              {t('agents.postCallInformation.btnEndContact')}
            </ButtonStyled>
          </div>
        </FormPostConnection>
      </ContentInCall>
    </ContainerPostConnection>
  );
};
