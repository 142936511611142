import styled from 'styled-components';

export const ContainerPauseStatus = styled.div`
  width: 100%;
  height: 100%;
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .titlePause {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    margin-bottom: 1rem;

    h2 {
      font-weight: 700;
      font-size: 1rem;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: ${(props) => props.theme.colors.typography.baseText};
    }

    svg {
      color: ${(props) => props.theme.colors.error};
      font-size: 1.2rem;
    }
  }
`;

export const FormChangePause = styled.form`
  flex: 1;
  gap: 1rem;
  display: flex;
  flex-direction: column;
  .body_pause {
  }
  .footer_pause {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
`;
