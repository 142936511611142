import styled from 'styled-components';

export const ContentPanelScheduleList = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.containers.colorCard};
  border-radius: 4px;
  box-shadow: 1px 4px 10px -1px rgba(0, 0, 0, 0.2);
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  position: relative;
  padding: 1.2rem 1rem;
  gap: 1rem;

  div {
    div {
      svg {
        color: ${(props) => props.theme.colors.typography.baseText};
      }
      span {
        display: block;
        padding-top: 1px !important;
        color: ${(props) => props.theme.colors.typography.baseText};
      }
    }
  }

  .content-btn {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    bottom: -16%;
    right: 2%;

    button {
      border-radius: 500px;
      min-width: 0 !important;
      padding: 0.6rem !important;
      svg {
        font-size: 1.4rem;
      }
    }
  }
`;

export const ContainerWeekDays = styled.div`
  max-width: 500px;
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  grid-gap: 1rem;

  @media (max-width: 500px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  span {
    font-size: 0.9rem;
    color: ${(props) => props.theme.colors.typography.baseText};
  }

  .content-days {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 1rem;

    input {
      display: none !important;
    }

    label {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 30px;
      width: 30px;
      border-radius: 50%;
      background: ${(props) => props.theme.colors.containers.baseContainer};
      cursor: pointer;
      color: ${(props) => props.theme.colors.typography.baseText};
      font-size: 0.7rem;
      border: 2px solid transparent;
      animation: all 0.3s ease-in-out;
      text-transform: uppercase;

      /* :hover {
        background: ${(props) => props.theme.colors.containers.dividerColor};
      } */
    }

    .checkedDay {
      background: ${(props) => props.theme.colors.primary};
      color: ${(props) => props.theme.colors.typography.textHeaderTable};
      /* :hover {
            border: 2px solid ${(props) => props.theme.colors.primaryDark};
        } */
    }
  }
`;
