import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ContainerConfig = styled(motion.div)`
  display: grid;
  grid-template-rows: calc(100vh - 288px) 30px;
  gap: 1rem;
`;

export const MainConfigForm = styled.main`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  /* overflow: auto; */
`;

export const FooterConfigForm = styled.footer`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 8px;
`;
