import { ContainerFlowProjects } from './styled';
import { useState } from 'react';
import { MainHomeFlow } from './MainHomeFlow';
import { CircularAbsoluteLoading } from '@/components/layout/LoadingComponent';
import { useHandleFlow } from '@/hooks/FlowBuilder/useHandlerFlow';
// import { useUsersAgents } from '@/hooks/UserAgents/useUsersAgents';

export const FlowSelectProject = ({ changeStageFlow }) => {
  const { loadingFlowTemplate, loadingImportFlow } = useHandleFlow();
  //   const { isLoadingDataUsers } = useUsersAgents(false);

  const [selectedItem, setSelectedItem] = useState('recentFlow');

  const changeStageTypeSelectFlow = (stage) => {
    setSelectedItem(stage);
  };

  const isLoading = loadingFlowTemplate || loadingImportFlow;
  //   const isLoading =
  //     loadingFlowTemplate || loadingImportFlow || isLoadingDataUsers;

  return (
    <ContainerFlowProjects
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0.1 }}
    >
      {isLoading && <CircularAbsoluteLoading />}

      <MainHomeFlow
        selectedItem={selectedItem}
        changeStageFlow={changeStageFlow}
        changeStageTypeSelectFlow={changeStageTypeSelectFlow}
      />
    </ContainerFlowProjects>
  );
};
