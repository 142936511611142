import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { AiOutlineSchedule } from 'react-icons/ai';
import { useSchedules } from '@hooks/Agents/useSchedules';
import { token } from '@store/AuthenticatorStore';
import { phoneMaskTransform } from '@/utils/phoneMakTransform';
import { ButtonStyled } from '@components/FormComponents/ButtonComponent';
import { ContainerDeleteSchedule } from './styled';
import { dataScheduleSelected } from '@/store/AgentsStore';
import { useEffect } from 'react';

export const DeleteSchedule = ({ closeModal }) => {
  const { t } = useTranslation();
  const [currentToken] = useAtom(token);
  const { mutateDeleteSchedule } = useSchedules();
  const [dataRow, setDataSelected] = useAtom(dataScheduleSelected);

  useEffect(() => {
    return () => setDataSelected(null);
  }, []);

  const handleDeleteSchedule = (e) => {
    e.preventDefault();
    mutateDeleteSchedule(
      { token: currentToken },
      {
        onSuccess: () => {
          closeModal();
        },
      }
    );
  };

  return (
    <ContainerDeleteSchedule onSubmit={handleDeleteSchedule}>
      <div className='titleDeleteSchedule'>
        <AiOutlineSchedule />
        <h2>{t('agents.scheduleAgent.DeleteSchedule.titleDeleteSchedule')}</h2>
      </div>

      <div className='mainDeleteSchedule'>
        <div className='item-to-delete'>
          <span>{t('agents.scheduleAgent.DeleteSchedule.name')}:</span>
          <span>{dataRow.name ?? '-'}</span>
        </div>
        <div className='item-to-delete'>
          <span>{t('agents.scheduleAgent.DeleteSchedule.callTarget')}:</span>
          <span>{dataRow.callTarget ?? '-'}</span>
        </div>
        <div className='item-to-delete'>
          <span>{t('agents.scheduleAgent.DeleteSchedule.phoneSchedule')}:</span>
          <span>{phoneMaskTransform(dataRow.phone) ?? '-'}</span>
        </div>
        <div className='item-to-delete'>
          <span>{t('agents.scheduleAgent.DeleteSchedule.lastContact')}:</span>
          <span>{dataRow.lastContact ?? '-'}</span>
        </div>
        <div className='item-to-delete'>
          <span>{t('agents.scheduleAgent.DeleteSchedule.note')}:</span>
          <span>{dataRow.note ?? '-'}</span>
        </div>
      </div>
      <div className='footerDeleteSchedule'>
        <ButtonStyled variantstyle={'secondary'} onClick={closeModal}>
          {t('agents.scheduleAgent.DeleteSchedule.btnCancelDeleteSchedule')}
        </ButtonStyled>
        <ButtonStyled type='submit'>
          {t('agents.scheduleAgent.DeleteSchedule.btnConfirmDeleteSchedule')}
        </ButtonStyled>
      </div>
    </ContainerDeleteSchedule>
  );
};
