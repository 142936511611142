import styled from 'styled-components';

export const ContainerTableActiveRecordings = styled.div`
  height: 100%;
  flex: 1;
  overflow: auto;
  table {
    width: 100%;

    tbody {
      tr {
        td {
          padding-left: 10px;

          @media (max-width: 1550px) {
            span {
              font-size: 0.7rem !important;
            }
          }

          &:nth-child(1) {
            min-width: 160px;
            width: 10%;
          }

          &:nth-child(2) {
            min-width: 60px;
          }

          &:nth-child(3) {
            min-width: 200px;
            width: 20%;
          }

          &:nth-child(4) {
            min-width: 130px;
            width: 10%;

            button {
              all: unset;
              opacity: 0;
              display: flex;
              justify-content: center;
              margin-left: 0.3rem;
              cursor: copy;

              svg {
                color: ${(props) => props.theme.colors.typography.baseText};
              }
            }

            &:hover {
              button {
                opacity: 1;
              }
            }
          }

          &:nth-child(5) {
            min-width: 200px;
            width: 20%;

            button {
              all: unset;
              opacity: 0;
              display: flex;
              justify-content: center;
              margin-left: 0.3rem;
              cursor: copy;

              svg {
                color: ${(props) => props.theme.colors.typography.baseText};
              }
            }

            &:hover {
              button {
                opacity: 1;
              }
            }
          }

          &:nth-child(6) {
            /* min-width: 110px !important; */

            button {
              all: unset;
              opacity: 0;
              display: flex;
              justify-content: center;
              margin-left: 0.3rem;
              cursor: copy;

              svg {
                color: ${(props) => props.theme.colors.typography.baseText};
              }
            }

            &:hover {
              button {
                opacity: 1;
              }
            }
          }

          &:nth-child(7) {
            width: 60px;
          }

          &:last-child {
            width: 70px;

            button {
              display: flex;
              justify-content: center;
              align-items: center;
              border: none;
              color: ${(props) => props.theme.colors.bases.baseWhite};
              padding: 2px;
              border-radius: 4px;
              background-color: ${(props) => props.theme.colors.primary};

              svg {
                color: ${(props) => props.theme.colors.bases.baseWhite};
              }

              &:last-child {
                background-color: ${(props) =>
                  props.theme.colors.defaultColors.orange};
              }
            }
          }
        }
        height: 40px;
      }
    }
  }

  .hasPlayedSound {
    background: ${(props) => props.theme.colors.success} !important;
  }
`;
