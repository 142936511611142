import { SelectComponent } from '@/components/FormComponents/SelectInput';
import { ContainerLatestList, ContainerNoData } from './styled';
import { Grid, MenuItem, Skeleton } from '@mui/material';
import { useEffect } from 'react';
import { useHandleFlow } from '@/hooks/FlowBuilder/useHandlerFlow';
import { CardFlow } from './CardFlow';
import { useTranslation } from 'react-i18next';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';
import { AiOutlineWarning } from 'react-icons/ai';
import { errorToast } from '@/components/FormComponents/Toast';
import { Stack } from '@mui/system';
import { ButtonStyled } from '@/components/FormComponents/ButtonComponent';
import { IoAddSharp } from 'react-icons/io5';
import { useAtom } from 'jotai';
import { hiddenSidebar } from '@/store/NavigationDashboard';
import { usePermissionsFlow } from '@/hooks/FlowBuilder/usePermissionsFlow';
import { motion } from 'framer-motion';
import { useClients } from '@/hooks/Clients/useClients';
import { dataListClients } from '@/store/Clients';
import { adminClientIdSelected, listTemplates } from '@/store/FlowBuilder';

export const LatestFlow = ({ changeStageFlow, changeStageTypeSelectFlow }) => {
  const { t } = useTranslation();

  const { permissionsCreate, isSysAdmin } = usePermissionsFlow();
  const [listClients] = useAtom(dataListClients);
  const [listTemplate] = useAtom(listTemplates);

  const { isLoadingDataClients } = useClients(isSysAdmin);

  const [clientSelected, setClientSelected] = useAtom(adminClientIdSelected);

  const {
    mutateImportFlow,
    loadingFlowTemplate,
    loadingImportFlow,
    errorList,
    mutateGetTemplatesByClientId,
    loadingGetTemplatesByClientId,
  } = useHandleFlow();

  const [, setIsVisibleSidebar] = useAtom(hiddenSidebar);

  useEffect(() => {
    if (clientSelected !== '0') {
      mutateGetTemplatesByClientId(clientSelected);
    }
  }, [clientSelected]);

  useEffect(() => {
    !loadingFlowTemplate &&
      errorList &&
      errorToast(t('flowBuilder.recentFlow.msg-error-list'));
  }, [loadingFlowTemplate, errorList]);

  const handleTemplate = (idTemplate) => {
    mutateImportFlow(idTemplate, {
      onSuccess: () => {
        changeStageFlow(1);
        setIsVisibleSidebar(false);
      },
    });
  };

  let singleItemsList = listTemplate.reduce((accumulator, currentItem) => {
    const existingItem = accumulator.find(
      (item) => item.nameIVR === currentItem.nameIVR
    );
    if (!existingItem) {
      accumulator.push(currentItem);
    } else if (!existingItem.published && currentItem.published) {
      accumulator[accumulator.indexOf(existingItem)] = currentItem;
    }
    return accumulator;
  }, []);

  const handleNewFlow = () => {
    changeStageTypeSelectFlow('newFlow');
  };

  const isLoading =
    loadingFlowTemplate ||
    loadingImportFlow ||
    isLoadingDataClients ||
    loadingGetTemplatesByClientId;

  return (
    <ContainerLatestList initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      {isLoading && <BackDropCircularLoading />}
      {isSysAdmin && (
        <SelectComponent
          labelSelect={'Clients'}
          value={clientSelected}
          onChange={(e) => setClientSelected(e.target.value)}
        >
          <MenuItem value={'0'} dense disabled>
            Select Client
          </MenuItem>
          {listClients.length > 0 &&
            listClients.map((item) => {
              return (
                <MenuItem key={item.id} value={item.id} dense>
                  {item.commercial_name.toUpperCase()}
                </MenuItem>
              );
            })}
        </SelectComponent>
      )}

      {(isSysAdmin || permissionsCreate) && (
        <Stack justifyContent={'flex-end'} alignItems={'flex-end'}>
          <ButtonStyled size={'small'}>
            <Stack
              direction={'row'}
              gap={'06px'}
              justifyContent={'center'}
              alignItems={'center'}
              padding={'4px'}
              onClick={() => handleNewFlow()}
            >
              <IoAddSharp size={16} />
              <span style={{ lineHeight: 0 }}>
                {t('flowBuilder.newFlow.title')}
              </span>
            </Stack>
          </ButtonStyled>
        </Stack>
      )}

      {loadingFlowTemplate && (
        <>
          <Grid container spacing={2}>
            {Array(9)
              .fill(undefined)
              .map((_, i) => (
                <Grid key={i} item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Skeleton variant='rectangular' width={'100%'} height={76} />
                </Grid>
              ))}
          </Grid>
          <BackDropCircularLoading />
        </>
      )}
      {listTemplate.length > 0 ? (
        <motion.div
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
        >
          <Grid container spacing={2}>
            {singleItemsList.map((card, index) => (
              <Grid item key={index} xs={12} sm={12} md={6} lg={6} xl={6}>
                <CardFlow dataCard={card} handleTemplate={handleTemplate} />
              </Grid>
            ))}
          </Grid>
        </motion.div>
      ) : (
        !loadingFlowTemplate && (
          <ContainerNoData
            initial={{ opacity: 0, y: 0 }}
            animate={{ opacity: 1, y: -10 }}
          >
            <AiOutlineWarning />
            <span>{t('flowBuilder.recentFlow.msg-no-dataFlow')}</span>
          </ContainerNoData>
        )
      )}
    </ContainerLatestList>
  );
};
