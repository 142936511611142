import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ContainerPauseStatus = styled(motion.div)`
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.theme.colors.containers.colorCard};
  border: 2px solid ${(props) => props.theme.colors.agentColors.PAUSA};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  gap: 10px;
  margin-top: 1rem;

  svg {
    color: ${(props) => props.theme.colors.agentColors.PAUSA};
    padding-bottom: 2px;
    font-size: 1.6rem;
  }

  span {
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 14px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: ${(props) => props.theme.colors.typography.baseText};
  }
`;
