import { Stack } from '@mui/system';
import { Divider } from '@mui/material';
import * as yup from 'yup';
import { addHours, addMinutes, format } from 'date-fns';
import { ButtonStyled } from '@/components/FormComponents/ButtonComponent';
import { ContentFooter, ContentMain, FormAddScheduleQueue } from './styled';
import useFormControl from '@/hooks/useFormControl';
import { ControlInput } from '@/components/FormComponents/InputComponent';
import { ControlTimePicker } from '@/components/FormComponents/TimePicker';
import { WeekDaysSelector } from '@/components/FormComponents/WeekDaysSelector';

import { TitleComponent } from '@/components/layout/TitleComponent';
import { DescriptionInformation } from '@/components/layout/DescriptionInformation';
import { Controller } from 'react-hook-form';
import { useEffect } from 'react';
import { errorToast, successToast } from '@/components/FormComponents/Toast';
import { useSchedulesQueue } from '@/hooks/dialControl/Schedules/useSchedulesQueue';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';
import { dataQueueSelected } from '@/store/ScheduleStore/SchedulerQueue';

export const AddScheduleQueue = ({ controlStage }) => {
  const { t } = useTranslation();
  const [queueSelected] = useAtom(dataQueueSelected);

  const { loadingCreateScheduleQueue, mutateCreateScheduleQueue } =
    useSchedulesQueue();

  const renderTxt = (word) => {
    return t(`dialControl.schedulesQueue.addSchedules.${word}`);
  };

  const currentTime = new Date();

  const newScheduleSchema = yup.object().shape({
    queue: yup.string(),
    timeInit: yup
      .date()
      .test(
        'is-smaller',
        'Inicio agendamento deve ser menor que Final do Agendamento',
        function (value) {
          const { timeEnd } = this.parent;
          return !timeEnd || !value || value < timeEnd;
        }
      ),
    timeEnd: yup
      .date()
      .test(
        'is-greater',
        'Final agendamento deve ser maior que Inicio do Agendamento',
        function (value) {
          const { timeInit } = this.parent;
          return !timeInit || !value || value > timeInit;
        }
      ),
    pauseInit: yup
      .date()
      .test(
        'is-smaller',
        'Inicio pausa deve ser menor que Final do pausa',
        function (value) {
          const { pauseEnd, timeInit } = this.parent;
          return !pauseEnd || !value || (value <= pauseEnd && value > timeInit);
        }
      ),
    pauseEnd: yup
      .date()
      .test(
        'is-greater',
        'Final agendamento deve ser maior que Inicio do Agendamento',
        function (value) {
          const { pauseInit, timeEnd } = this.parent;
          return (
            !pauseInit || !value || (value >= pauseInit && value < timeEnd)
          );
        }
      ),
    dayOfWeek: yup
      .array()
      .test('is-greater', 'Selecione um dia da semana', function (value) {
        return value.length > 0;
      }),
  });

  const { handleSubmit, control, errors, reset } =
    useFormControl(newScheduleSchema);

  useEffect(() => {
    errors &&
      Object.keys(errors).length > 0 &&
      errorToast('Preencha todos os campos');
  }, [errors]);

  const formatTime = (timestamp) => {
    return format(new Date(timestamp), 'HH:mm:00');
  };

  const handleCreateSchema = (data) => {
    const dataCreate = {
      fila: Number(data.queue),
      abre: formatTime(data.timeInit),
      fecha: formatTime(data.timeEnd),
      pausa_ini: formatTime(data.pauseInit),
      pausa_fim: formatTime(data.pauseEnd),
      dia_semana: data?.dayOfWeek,
      principal_agendamento: '',
      agenda_lista: '',
    };

    mutateCreateScheduleQueue(dataCreate, {
      onError: () => errorToast(renderTxt('msg-error')),
      onSuccess: () => {
        successToast(renderTxt('msg-success'));
        reset();
        controlStage.toStage(1);
      },
    });
  };

  const handleBackStage = () => {
    reset();
    controlStage.toStage(1);
  };

  return (
    <FormAddScheduleQueue
      onSubmit={handleSubmit(handleCreateSchema)}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      {loadingCreateScheduleQueue && <BackDropCircularLoading />}
      <ContentMain>
        <TitleComponent textTitle={renderTxt('title-addSchedule')} />
        <DescriptionInformation textDescription={renderTxt('description')} />
        <ControlInput
          label={renderTxt('label-queue')}
          control={control}
          nameControl={'queue'}
          defaultValue={queueSelected.fila}
          disabled
        />

        <Divider />

        <Stack direction={'row'} spacing={2}>
          <ControlTimePicker
            label={renderTxt('label-openQueue')}
            control={control}
            nameControl={'timeInit'}
            defaultValue={currentTime}
            error={Boolean(errors.timeInit)}
          />
          <ControlTimePicker
            label={renderTxt('label-closeQueue')}
            control={control}
            nameControl={'timeEnd'}
            defaultValue={addHours(currentTime, 1)}
            error={Boolean(errors.timeEnd)}
          />
        </Stack>
        <Stack direction={'row'} spacing={2}>
          <ControlTimePicker
            label={renderTxt('label-initPause')}
            control={control}
            nameControl={'pauseInit'}
            defaultValue={addMinutes(currentTime, 30)}
            error={Boolean(errors.pauseInit)}
          />
          <ControlTimePicker
            label={renderTxt('label-endPause')}
            control={control}
            nameControl={'pauseEnd'}
            defaultValue={addMinutes(currentTime, 45)}
            error={Boolean(errors.pauseEnd)}
          />
        </Stack>

        <Divider />

        <Controller
          control={control}
          defaultValue={[]}
          name={'dayOfWeek'}
          render={({ field: { onChange, value } }) => (
            <WeekDaysSelector
              selectedDays={value}
              setSelectedDays={onChange}
              errorSelectedDays={Boolean(errors.dayOfWeek)}
            />
          )}
        />
      </ContentMain>

      <ContentFooter>
        <ButtonStyled
          variantstyle={'secondary'}
          size='small'
          onClick={handleBackStage}
        >
          {renderTxt('btn-cancel')}
        </ButtonStyled>
        <ButtonStyled
          type='submit'
          size='small'
          disabled={errors && Object.keys(errors).length > 0}
        >
          {renderTxt('btn-create')}
        </ButtonStyled>
      </ContentFooter>
    </FormAddScheduleQueue>
  );
};
