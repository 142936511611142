import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';
import { Grid, MenuItem, Stack } from '@mui/material';
import { ContainerSubMenuCreate } from './styled';
import { TitleComponent } from '@/components/layout/TitleComponent';
import { SelectComponent } from '@/components/FormComponents/SelectInput';
import { dataListScreens } from '@/store/NavigationDashboard';
import { SwitchComponent } from '@/components/FormComponents/SwitchComponent';
import { InputComponent } from '@/components/FormComponents/InputComponent';
import { dataScreens } from '@/store/Screens';

export const SubMenuCreate = ({
  setDataMenuForm,
  dataMenuForm,
  useIframe,
  setUseIframe,
}) => {
  const { t } = useTranslation();
  const [listScreensFromComponent] = useAtom(dataListScreens);

  const [dataScreensList] = useAtom(dataScreens);

  const definedListMenus = dataScreensList.filter(
    (item) => item.slug === 'titleMenu'
  );

  useEffect(() => {
    if (useIframe) {
      return setDataMenuForm((previousValue) => ({
        ...previousValue,
        href: '0',
      }));
    }
    return setDataMenuForm((previousValue) => ({
      ...previousValue,
      link: '',
    }));
  }, [useIframe]);

  const validationParentIdMenu = (value) => {
    setDataMenuForm((previousValue) => ({
      ...previousValue,
      parent_id: value,
    }));
  };

  const validationHrefMenu = (value) => {
    setDataMenuForm((previousValue) => ({
      ...previousValue,
      href: value,
    }));
  };

  const validationLinkMenu = (value) => {
    setDataMenuForm((previousValue) => ({
      ...previousValue,
      link: value,
    }));
  };

  return (
    <ContainerSubMenuCreate>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TitleComponent
            textTitle={t('screens.addScreen.title-detail-subMenu')}
            style={{ width: '50%' }}
          />
        </Grid>
        <Grid item xs={6} style={{ paddingLeft: '2rem' }}>
          <Stack alignItems={'flex-end'}>
            <SwitchComponent
              value={useIframe}
              onChange={setUseIframe}
              label={t('screens.addScreen.label-externalUrl')}
            />
          </Stack>
        </Grid>
      </Grid>
      <SelectComponent
        defaultValue='0'
        labelSelect={t('screens.addScreen.label-hierarchy')}
        value={dataMenuForm.parent_id}
        onChange={(event) => validationParentIdMenu(event.target.value)}
      >
        <MenuItem value={'0'} dense disabled>
          {t('screens.addScreen.option-hierarchy')}
        </MenuItem>

        {definedListMenus.length > 0 &&
          definedListMenus.map((item, i) => (
            <MenuItem value={item.id} key={i} dense>
              {item.name}
            </MenuItem>
          ))}
      </SelectComponent>
      {useIframe ? (
        <InputComponent
          value={dataMenuForm.link}
          placeholder={'https://url.com'}
          onChange={(event) => validationLinkMenu(event.target.value)}
          label={t('screens.addScreen.label-externalUrl')}
        />
      ) : (
        <SelectComponent
          defaultValue='0'
          labelSelect={t('screens.addScreen.label-relationship')}
          value={dataMenuForm.href}
          onChange={(event) => validationHrefMenu(event.target.value)}
        >
          <MenuItem value={'0'} dense disabled>
            {t('screens.addScreen.option-relationship')}
          </MenuItem>

          {listScreensFromComponent.length > 0 &&
            listScreensFromComponent.map((item, i) => (
              <MenuItem value={item.id} key={i} dense>
                {t(`dashboard.${item.name}`)}
              </MenuItem>
            ))}
        </SelectComponent>
      )}
    </ContainerSubMenuCreate>
  );
};
