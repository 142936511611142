import { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useUsersAgents } from '@hooks/UserAgents/useUsersAgents';
import { Stack, MenuItem } from '@mui/material';
import {
  dataOptionsClients,
  stateRenderUsers,
} from '@store/UsersAgentsStore/userAgentsStore';
import { ButtonStyled } from '@components/FormComponents/ButtonComponent';
import { ControlInput } from '@components/FormComponents/InputComponent';
import { BackDropCircularLoading } from '@components/layout/LoadingComponent';
import { TitleComponent } from '@components/layout/TitleComponent';
import { DialogComponent } from '@/components/DialogComponent';
import { ControlSelect } from '@/components/FormComponents/SelectInput';
import { PasswordStep } from './PasswordStep';
import { ContainerAddNewUserAgent, FormAddNewUserAgent } from './styled';
import { ControlRadio } from '@/components/FormComponents/RadioComponent';
import { dataByClientRoutingProfile } from '@/store/UsersAgentsStore/routingProfiles';
import { errorToast } from '@/components/FormComponents/Toast';
import { useRoutingProfile } from '@/hooks/UserAgents/useRoutingProfile';

export const AddNewUserAgent = () => {
  const [, setStateRenderUsersAgents] = useAtom(stateRenderUsers);
  const [optionsClients] = useAtom(dataOptionsClients);
  const [defaultRoutingProfile, setDefaultRoutingProfile] = useAtom(
    dataByClientRoutingProfile
  );

  const { mutateRoutingByClient, loadingRoutingByClient } = useRoutingProfile(
    false,
    'add-client'
  );

  const { t } = useTranslation();

  const {
    isLoadingDataSecurityProfilesList,
    mutateCreateUserAgent,
    loadingCreateUserAgent,
    isLoadingDataClients,
    mutateAddUserRoutingProfile,
    loadingAddUserProfileUser,
  } = useUsersAgents();

  useEffect(() => {
    return () => {
      setStateRenderUsersAgents(0);
      setDefaultRoutingProfile([]);
    };
  }, []);

  const [openDialogPassword, setOpenDialogPassword] = useState(false);
  const [emailUser, setEmailUser] = useState(null);

  const addNewUserSchema = yup.object().shape({
    name: yup
      .string()
      .required(t('usersAgentsList.addUser.messages.errorNameField')),
    email: yup
      .string()
      .email()
      .required(t('usersAgentsList.addUser.messages.errorEmailField')),
    clientId: yup
      .string()
      .required(t('usersAgentsList.addUser.messages.errorClientId')),
    statusAccount: yup
      .string()
      .required(t('usersAgentsList.statusAccount.messages.errorClientId')),
    routingProfile: yup
      .string()
      .notOneOf(['0'], 'O valor "0" não é permitido.')
      .required(t('usersAgentsList.addUser.messages.errorRoutingField')),
    isSysAdmin: yup.string().required(),
    isAdmin: yup.string().required(),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(addNewUserSchema),
  });

  const watchClientId = watch('clientId', '');

  useEffect(() => {
    if (watchClientId && watchClientId !== '') {
      setValue('routingProfile', '0');
      return mutateRoutingByClient(watchClientId);
    }
  }, [watchClientId]);

  const handleErrosCreate = (typeError) => {
    switch (typeError) {
      case 'User already exists.':
        return errorToast(
          t('usersAgentsList.addUser.messages.errorHasExistUser')
        );
      default:
        return errorToast(t('usersAgentsList.addUser.messages.errorMessage'));
    }
  };

  const handleCreateUserAgent = (data) => {
    const dataCreate = {
      name: data.name,
      email: data.email,
      client_id: data.clientId,
      routing_id: data.routingProfile,
      isActive:
        data.statusAccount === t('usersAgentsList.editUser.option-active')
          ? true
          : false,
      isAdmin:
        data.isAdmin === t('usersAgentsList.addUser.option-adm') ? true : false,
      isSysAdmin:
        data.isSysAdmin === t('usersAgentsList.addUser.option-adm')
          ? true
          : false,
      user_type: 1,
    };

    setEmailUser(data.email);

    mutateCreateUserAgent(dataCreate, {
      onSuccess: (dataResponse) => {
        if (data.status === 'Error') {
          return handleErrosCreate(dataResponse.message);
        }

        const dataCreateUserRoutingDefault = {
          users_id: dataResponse.id,
          routing_id: data.routingProfile ?? '0',
        };

        mutateAddUserRoutingProfile(dataCreateUserRoutingDefault, {
          onSuccess: () => {
            setOpenDialogPassword(true);
            reset();
          },
        });
      },
    });
  };

  const filterRoutingNull = defaultRoutingProfile.filter((item) => item);

  const handleBackStage = () => {
    reset();
    setStateRenderUsersAgents(0);
    setDefaultRoutingProfile([]);
  };

  const isLoadingComponent =
    isLoadingDataSecurityProfilesList ||
    loadingAddUserProfileUser ||
    loadingCreateUserAgent ||
    isLoadingDataClients ||
    loadingRoutingByClient;

  return (
    <ContainerAddNewUserAgent initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      {isLoadingComponent && <BackDropCircularLoading />}

      <FormAddNewUserAgent
        onSubmit={handleSubmit(handleCreateUserAgent)}
        autoComplete='off'
      >
        <TitleComponent
          textTitle={t('usersAgentsList.addUser.title-addUser')}
          iconTitle='userAgents'
        />

        <div className='mainFormUsers'>
          <div className='user_account__details'>
            <TitleComponent
              textTitle={t('usersAgentsList.addUser.title-userAcc')}
            />
            <Stack spacing={1}>
              <ControlInput
                control={control}
                nameControl={'name'}
                label={t('usersAgentsList.editUser.label-name')}
                placeholder={t('usersAgentsList.editUser.label-name')}
                error={Boolean(errors.name)}
                helperText={errors.name ? errors.name.message : ' '}
              />
              <ControlInput
                control={control}
                nameControl={'email'}
                label={t('usersAgentsList.editUser.label-email')}
                placeholder={t('usersAgentsList.editUser.label-email')}
                error={Boolean(errors.email)}
                helperText={errors.email ? errors.email.message : ' '}
              />
            </Stack>
          </div>
          <div className='user_account__details'>
            <TitleComponent
              textTitle={t('usersAgentsList.addUser.title-detail')}
            />

            <Stack spacing={1}>
              <ControlSelect
                control={control}
                nameControl={'clientId'}
                label={t('usersAgentsList.addUser.label-client')}
                error={Boolean(errors.clientId)}
                helperText={errors.clientId ? errors.clientId.message : '   '}
              >
                <MenuItem value={'0'} disabled>
                  {t('usersAgentsList.addUser.option-client')}
                </MenuItem>
                {optionsClients.length > 0 &&
                  optionsClients.map((item) => (
                    <MenuItem value={item.id} key={item.id} dense>
                      {item.commercial_name}
                    </MenuItem>
                  ))}
              </ControlSelect>
            </Stack>
          </div>
          <div className='user_account__details'>
            <TitleComponent
              textTitle={t('usersAgentsList.addUser.title-access')}
            />
            <Stack spacing={3}>
              <ControlSelect
                control={control}
                nameControl={'routingProfile'}
                label={t('usersAgentsList.addUser.label-routing')}
                error={Boolean(errors.routingProfile)}
                helperText={
                  errors.routingProfile ? errors.routingProfile.message : '   '
                }
              >
                <MenuItem value={'0'} disabled dense>
                  {t('usersAgentsList.addUser.option-routing')}
                </MenuItem>
                {filterRoutingNull.length > 0 &&
                  filterRoutingNull.map((item) => (
                    <MenuItem value={item.id} key={item.id} dense>
                      {item.name}
                    </MenuItem>
                  ))}
              </ControlSelect>
            </Stack>

            <div className='container_radios__config'>
              <Stack
                direction={'row'}
                alignItems={'center'}
                spacing={3}
                border={
                  errors.statusAccount
                    ? '1px solid red'
                    : '1px solid transparent'
                }
                padding={'0 8px'}
                borderRadius={'3px'}
                width={'100%'}
              >
                <ControlRadio
                  control={control}
                  labelRadio={t('usersAgentsList.editUser.label-status')}
                  nameControl='statusAccount'
                  labelValue1={t('usersAgentsList.editUser.option-active')}
                  labelValue2={t('usersAgentsList.editUser.option-disabled')}
                />
              </Stack>
              <Stack
                direction={'row'}
                alignItems={'center'}
                spacing={3}
                border={
                  errors.isSysAdmin ? '1px solid red' : '1px solid transparent'
                }
                padding={'0 8px'}
                borderRadius={'3px'}
                width={'100%'}
              >
                <ControlRadio
                  control={control}
                  labelRadio={t('usersAgentsList.addUser.label-sysAdm')}
                  nameControl='isSysAdmin'
                  labelValue1={t('usersAgentsList.addUser.option-adm')}
                  labelValue2={t('usersAgentsList.addUser.option-user')}
                />
              </Stack>
              <Stack
                direction={'row'}
                alignItems={'center'}
                spacing={3}
                border={
                  errors.isAdmin ? '1px solid red' : '1px solid transparent'
                }
                padding={'0 8px'}
                borderRadius={'3px'}
                width={'100%'}
              >
                <ControlRadio
                  control={control}
                  labelRadio={t('usersAgentsList.addUser.label-adm')}
                  nameControl='isAdmin'
                  labelValue1={t('usersAgentsList.addUser.option-adm')}
                  labelValue2={t('usersAgentsList.addUser.option-user')}
                />
              </Stack>
            </div>
          </div>
        </div>

        <div className='footerAddNewUser'>
          <ButtonStyled
            variantstyle={'secondary'}
            onClick={handleBackStage}
            size={'small'}
          >
            {t('usersAgentsList.addUser.btn-cancel')}
          </ButtonStyled>
          <ButtonStyled type='submit' size={'small'}>
            {t('usersAgentsList.addUser.btn-confirm')}
          </ButtonStyled>
        </div>
      </FormAddNewUserAgent>
      <DialogComponent
        open={openDialogPassword}
        onClose={() => setOpenDialogPassword(false)}
        fullWidth={true}
      >
        <PasswordStep
          closeDialog={() => setOpenDialogPassword(false)}
          emailUser={emailUser}
        />
      </DialogComponent>
    </ContainerAddNewUserAgent>
  );
};
