import { useMemo, useState, useEffect } from 'react';
import { useTable, useSortBy } from 'react-table';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { ContainerTd, ContentTr, TableContent } from './styled';
import { NoDataTable } from './NoDataTable';

export const SimpleTable = ({ dataColumns, dataTable, textNoData = '' }) => {
  const [dataTableState, setDataTableState] = useState([]);

  useEffect(() => {
    if (dataTable) setDataTableState(dataTable);
  }, [dataTable]);

  const columns = useMemo(() => dataColumns, [dataColumns]);
  const data = useMemo(() => dataTableState, [dataTableState]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns: columns,
        data: data,
      },
      useSortBy
    );

  return (
    <TableContent {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup, i) => (
          <tr key={i} {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, i) => (
              <th
                key={i}
                {...column.getHeaderProps(column.getSortByToggleProps())}
              >
                {column.Header !== 'Edit' ? (
                  <div className='content_th'>
                    {column.render('Header')}
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <ArrowDropDownIcon />
                      ) : (
                        <ArrowDropUpIcon />
                      )
                    ) : (
                      <ArrowDropUpIcon style={{ color: 'transparent' }} />
                    )}
                  </div>
                ) : (
                  <div>{column.render('Header')}</div>
                )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.length > 0 ? (
          rows.map((row, j) => {
            prepareRow(row);
            return (
              <ContentTr key={j} {...row.getRowProps()}>
                {row.cells.map((cell, index) => {
                  return (
                    <ContainerTd key={index} {...cell.getCellProps()}>
                      <span>{cell.render('Cell')}</span>
                    </ContainerTd>
                  );
                })}
              </ContentTr>
            );
          })
        ) : (
          <NoDataTable textContent={textNoData} />
        )}
      </tbody>
    </TableContent>
  );
};
