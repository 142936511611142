import { useTranslation } from 'react-i18next';
import { ContainerAgentDisconnected } from './styled';

import { FiAlertCircle } from 'react-icons/fi';

export const AgentDisconnected = () => {
  const { t } = useTranslation();
  return (
    <ContainerAgentDisconnected>
      <div className='content_disconnected'>
        <FiAlertCircle />
        <span>{t('agents.errorRequestMsg.onDisconnected')}</span>
      </div>
    </ContainerAgentDisconnected>
  );
};
