import { useId, useState } from 'react';
import { ListGlobalVariables } from './ListGlobalVariables';
import { AddGlobalVariables } from './AddGlobalVariables';

export const ConfigGlobalVars = ({ dataGlobals, handleClose }) => {
  const [stageGlobalVariablesFLow, setStageGlobalVariablesFlow] = useState(0);

  const changeFlowConfigState = (stage) => setStageGlobalVariablesFlow(stage);

  const flowGlobalVariables = [
    <ListGlobalVariables
      key={useId()}
      dataGlobals={dataGlobals}
      handleClose={handleClose}
      changeFlowConfigState={changeFlowConfigState}
    />,
    <AddGlobalVariables
      key={useId()}
      changeFlowConfigState={changeFlowConfigState}
    />,
  ];

  return flowGlobalVariables[stageGlobalVariablesFLow];
};
