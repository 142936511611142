import { motion } from 'framer-motion';
import styled from 'styled-components';

export const FormEditCampaign = styled(motion.form)`
  width: 100%;
  height: calc(100vh - 60px);
  padding: 3rem 2rem;
  display: grid;
  grid-template-rows: 1fr 40px;
  overflow: scroll;
`;

export const HeaderEditCampaign = styled.header``;

export const MainEditCampaign = styled.main`
  width: 100%;
  height: 99%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 2rem;
`;

export const FooterEditCampaign = styled.footer`
  width: 100%;
  height: 100%;
  min-height: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 1rem;

  @media (max-height: 768px) {
    padding-bottom: 10px;
  }
`;

export const ContainerDispositionsCampaigns = styled.div`
  width: 100%;
`;
