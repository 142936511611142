import { Monitor } from '../../components/pages/monitoring/Monitor';
import { LineMonitoring } from '../../components/pages/monitoring/LineMonitoring';
import { SummaryMonitor } from '../../components/pages/monitoring/SummaryMonitor';
import { DashboardPage } from '../../components/pages/DashboardPage';
import { Dialing } from '../../components/pages/Dialing';
import { ListUsers } from '../../components/pages/usersAgents/ListUsers';
import { Campaigns } from '../../components/pages/dialControl/Campaigns';
import { SchedulerList } from '../../components/pages/dialControl/SchedulerList';
import { SchedulerQueue } from '../../components/pages/dialControl/SchedulerQueue';
import { LineControl } from '../../components/pages/dialControl/LineControl';
import { SystemQueueControl } from '../../components/pages/dialControl/SystemQueueControl';
import { QueueCampaign } from '../../components/pages/dialControl/QueueCampaign';
import { ListCampaign } from '../../components/pages/dialControl/ListCampaign';
import { ActiveRecording } from '../../components/pages/recordings/ActiveRecording';
import { Journey } from '../../components/pages/Journey';
import { FlowBuilder } from '../../components/pages/FlowBuilder';
import { RoutingProfile } from '../../components/pages/usersAgents/RoutingProfile';
import { Screens } from '../../components/pages/usersAgents/screens';
import { SecurityProfile } from '../../components/pages/usersAgents/SecurityProfile';
import { Tenants } from '@/components/pages/Tenants';
import { Dispositions } from '@/components/pages/Dispositions';
import { QueueManagement } from '@/components/pages/dialControl/QueueManagement';
import { OperatorControl } from '@/components/pages/dialControl/OperatorControl';
import { ListClients } from '@/components/pages/ListClients';
import { RenitencyRules } from '@/components/pages/dialControl/RenitencyRules';
import { UfManager } from '@/components/pages/dialControl/UfManager';
import { DDDManager } from '@/components/pages/dialControl/DDDManager';
import { CallOperators } from '@/components/pages/CallOperators';
import { CountInList } from '@/components/pages/reports/mailingPenetration/CountInList';
import { CallingEfforts } from '@/components/pages/reports/mailingPenetration/CallingEfforts';
import { LoadList } from '@/components/pages/LoadList';
import { QueueMonitor } from '@/components/pages/monitoring/QueueMonitor';

export const usePagesComponents = [
  {
    id: '9e0dbcff-b155-4196-9bcc-09467c93c70a',
    name: 'Dashboard',
    component: <DashboardPage />,
    link: false,
  },
  {
    id: '9a173ebf-4921-404a-b2f6-8336dbac3a13',
    name: 'Journey',
    component: <Journey />,
    link: false,
  },
  {
    id: 'd18bc654-c972-438b-bb1b-99dbb637d469',
    name: 'FlowBuilder',
    component: <FlowBuilder />,
    link: false,
  },
  {
    id: '2edd8c2d-7179-4151-8433-54ff05634e99',
    name: 'listLoader',
    component: <LoadList />,
    link: false,
  },

  {
    id: '6974579e-1d52-47ed-b9ed-1c817384bf12',
    name: 'Dialing',
    component: <Dialing />,
    link: false,
  },

  {
    id: '32808075-7b83-4112-9569-dc4092b7dbc1',
    name: 'Agents',
    component: null,
    link: '/customerService',
  },

  {
    id: '3c82e517-d0bb-4c23-b34e-947f667b2d14',
    name: 'QueueManagement',
    component: <QueueManagement />,
    link: false,
    sequence: 1,
  },

  {
    id: '9d4sA410c1-fa86-499f-bc59-f63894f386f0',
    name: 'SchedulerList',
    component: <SchedulerList />,
    link: false,
    sequence: 5,
  },

  {
    id: '1bf9012c-0281-4e37-a4ce-872e90545023',
    name: 'SchedulerQueue',
    component: <SchedulerQueue />,
    link: false,
    sequence: 6,
  },

  {
    id: '555410c1-fa86-499f-bc59-f63894f386f0',
    name: 'Campaign',
    component: <Campaigns />,
    link: false,
    sequence: 10,
  },
  {
    id: '052ac24b-9450-40b1-9931-9245c262626d',
    name: 'LineControl',
    component: <LineControl />,
    link: false,
  },
  {
    id: '018deb38-d78a-4591-b558-878872bd2976',
    name: 'OperatorControl',
    component: <OperatorControl />,
    link: false,
  },
  {
    id: '725181ff-e28c-46e7-9012-f086ffbb709f',
    name: 'SystemQueueControl',
    component: <SystemQueueControl />,
    link: false,
  },
  {
    id: '13f62899-484e-4fea-a6bf-4411e2a669e0',
    name: 'QueueCampaign',
    component: <QueueCampaign />,
    link: false,
    sequence: 8,
  },
  {
    id: 'aa050ecc-0772-4512-8541-50f68b7d97c8',
    name: 'ListCampaign',
    component: <ListCampaign />,
    link: false,
    sequence: 9,
  },
  {
    id: 'bd1ff0c1-fa86-499f-bc59-f63894f386f0',
    name: 'LineMonitoring',
    component: <LineMonitoring />,
    link: false,
    sequence: 2,
  },
  {
    id: 'e25b9a1b-b4cc-4708-8dfc-8e9582a31495',
    name: 'Monitor',
    component: <Monitor />,
    link: false,
    sequence: 3,
  },
  {
    id: 'eb236045-aedb-4ced-a2bb-888b5aeb5bd0',
    name: 'SummaryMonitor',
    component: <SummaryMonitor />,
    link: false,
    sequence: 4,
  },
  {
    id: '529330f0-3483-4c9e-a562-703361495a47',
    name: 'QueueMonitor',
    component: <QueueMonitor />,
    link: false,
    sequence: 1,
  },
  {
    id: '61715df3-4168-48ef-98c6-90e944975147',
    name: 'ActiveRecordings',
    component: <ActiveRecording />,
    link: false,
  },
  {
    id: 'b27575e8-b2f7-439d-a741-598eb7a2adc7',
    name: 'Users',
    component: <ListUsers />,
    link: false,
  },
  {
    id: '5f3b16c3-012f-41f6-a4ad-0ea04457bffe',
    name: 'Clients',
    component: <ListClients />,
    link: false,
  },
  {
    id: 'adb706c4-42e5-46a1-92c0-eb2377e34c44',
    name: 'Screens',
    component: <Screens />,
    link: false,
  },
  {
    id: 'b20e9803-20c4-44a3-a227-9f40407e8eb5',
    link: false,
    name: 'RoutingProfile',
    component: <RoutingProfile />,
  },
  {
    id: 'a3799803-20c4-44a3-a227-9f40407e8eb5',
    name: 'SecurityProfile',
    component: <SecurityProfile />,
    link: false,
  },
  {
    id: 'bff1f44c-620d-4bf0-9215-b0fd2754e705',
    name: 'Tenants',
    component: <Tenants />,
    link: false,
  },
  {
    id: '6b63d52c-b270-42c3-96b3-ddf98335c302',
    name: 'Dispositions',
    component: <Dispositions />,
    link: false,
  },
  {
    id: '09ce4f19-2089-4aa5-9c07-64da3eacfae6',
    name: 'LineControl',
    component: <LineControl />,
    link: false,
    sequence: 9,
  },
  {
    id: '280e0310-5fdc-41be-829a-e22620d96900',
    name: 'ListCampaign',
    component: <ListCampaign />,
    link: false,
  },
  {
    id: 'cce196b9-85cb-4803-ab88-12b991ab42fb',
    name: 'RenitencyRules',
    component: <RenitencyRules />,
    link: false,
    sequence: 4,
  },
  {
    id: '9669026c-4e65-4833-a532-bfd963ed0534',
    name: 'UfManager',
    component: <UfManager />,
    link: false,
    sequence: 2,
  },
  {
    id: '773a3433-77a4-4320-a5ae-402caf7f2ea0',
    name: 'DDDManager',
    component: <DDDManager />,
    link: false,
    sequence: 3,
  },
  {
    id: '24ae72ae-87c9-46a9-86e9-16c93e66ded8',
    name: 'CallOperators',
    component: <CallOperators />,
    link: false,
  },

  {
    id: '9abcb720-de8e-4475-918b-38e81dc50974',
    name: 'CountInList',
    component: <CountInList />,
    link: false,
    sequence: 1,
  },
  {
    id: '51a71c1b-8990-43c5-bc8e-7b1cf405e861',
    name: 'CallingEfforts',
    component: <CallingEfforts />,
    link: false,
    sequence: 2,
  },
];
