import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { currentConfigComponent } from '@/store/DialingStore';
import { ButtonStyled } from '@components/FormComponents/ButtonComponent';
import { Stack } from '@mui/material';
import { RiPlayListAddFill } from 'react-icons/ri';
import { BsGearFill } from 'react-icons/bs';
import { ContainerNavigationConfigQueue } from './styled.';

export const NavListConfig = () => {
  const { t } = useTranslation();

  const [currentComponent, setCurrentComponent] = useAtom(
    currentConfigComponent
  );

  return (
    <ContainerNavigationConfigQueue>
      <ButtonStyled
        size='small'
        onClick={() => setCurrentComponent(0)}
        className={
          currentComponent === 0 || currentComponent === 2
            ? ''
            : 'inativeButton'
        }
      >
        <Stack
          direction={'row'}
          spacing={1}
          alignItems='center'
          justifyContent='center'
        >
          <RiPlayListAddFill />
          <span>{t('dialing.navigationConfig.btn-newList')}</span>
        </Stack>
      </ButtonStyled>
      <ButtonStyled
        size='small'
        className={currentComponent !== 1 ? 'inativeButton' : ''}
        onClick={() => setCurrentComponent(1)}
      >
        <Stack
          direction={'row'}
          spacing={1}
          alignItems='center'
          justifyContent='center'
        >
          <BsGearFill />
          <span>{t('dialing.navigationConfig.btn-newConfig')}</span>
        </Stack>
      </ButtonStyled>
    </ContainerNavigationConfigQueue>
  );
};
