import styled from 'styled-components';

export const DetailPositionTable = styled.div`
  user-select: none;
  table {
    thead {
      tr {
        th {
          &:last-child {
            height: 36px;
            width: 140px;
            div {
            }
          }
        }
      }
    }
  }
`;
