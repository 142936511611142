import { motion } from 'framer-motion';
import { ButtonStyled } from '@/components/FormComponents/ButtonComponent';
import { Stack } from '@mui/system';
import { BiPlus } from 'react-icons/bi';
import { ItemsFieldsHeader } from '../styled';
import { SelectComponent } from '@/components/FormComponents/SelectInput';
import { MenuItem } from '@mui/material';
import { useAtom } from 'jotai';
import { errorFieldsDataApi, selectedNode } from '@/store/FlowBuilder';
import { FaRegTrashAlt } from 'react-icons/fa';
import { CheckBoxFlow } from '@/components/FormComponents/CheckboxComponent';
import { v4 as uuid } from 'uuid';
import { useTranslation } from 'react-i18next';
import { InputComponent } from '@/components/FormComponents/InputComponent';

export const BodyField = ({
  bodyInputs,
  setBodyInputs,
  setFocusedArea,
  listVariables,
}) => {
  const { t } = useTranslation();
  const [selected] = useAtom(selectedNode);
  const [errorFields, setErrorFields] = useAtom(errorFieldsDataApi);
  const { id } = selected;

  const handleCheckboxChange = (id) => {
    setBodyInputs(
      bodyInputs.map((input) => {
        if (input.idSelect === id) {
          return { ...input, disabled: !input.disabled };
        }
        return input;
      })
    );
  };

  const addBodyPair = () => {
    setBodyInputs([
      ...bodyInputs,
      { name: '', value: '0', idSelect: uuid(), disabled: false },
    ]);
  };

  const filterUsedVariablesInNode = listVariables.filter(
    (item) => item.referNode !== id
  );

  const removeBodyPair = (index) => {
    const newKeyValuePairs = [...bodyInputs];
    newKeyValuePairs.splice(index, 1);
    setBodyInputs(newKeyValuePairs);
  };

  const handleBodyInputsChange = (index, event, idField) => {
    if (errorFields.includes(idField)) {
      const removeError = errorFields.filter((item) => item !== idField);
      setErrorFields(removeError);
    }
    const newKeyValuePairs = [...bodyInputs];
    newKeyValuePairs[index]['value'] = event.target.value;
    setBodyInputs(newKeyValuePairs);
  };

  const handleNameBodyInputsChange = (index, event, idField) => {
    if (errorFields.includes(idField)) {
      console.log('🚀 -> errorFields:', errorFields);

      const removeError = errorFields.filter((item) => item !== idField);
      setErrorFields(removeError);
    }

    const newValue = event.target.value;

    // Expressão regular para aceitar apenas letras, números, "-", "_", ".", "~" e espaços
    const validCharactersRegex = /^[a-zA-Z0-9\-_.~\s]*$/;

    if (!validCharactersRegex.test(newValue)) {
      setErrorFields([idField]);
      return;
    }

    const newKeyValuePairs = [...bodyInputs];
    newKeyValuePairs[index]['name'] = newValue;
    setBodyInputs(newKeyValuePairs);
  };

  const label = { inputProps: { 'aria-label': 'Status field' } };

  const renderBodyInputs = () => {
    return bodyInputs.map((pair, index) => (
      <motion.div
        className='item-content'
        key={index}
        initial={{ opacity: 0, y: -10 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -10 }}
      >
        <Stack spacing={1} width={'100%'}>
          <InputComponent
            label={t('flowBuilder.flow.nodes.dataApi.menu.label-name')}
            size={'small'}
            value={pair.name}
            onChange={(event) =>
              handleNameBodyInputsChange(index, event, pair.idSelect)
            }
            error={errorFields.includes(pair.idSelect)}
            disabled={pair.disabled}
            onFocus={() => setFocusedArea(true)}
            onBlur={() => setFocusedArea(false)}
          />
          <SelectComponent
            value={pair.value}
            onChange={(event) =>
              handleBodyInputsChange(index, event, pair.idSelect)
            }
            disabled={pair.disabled}
            error={errorFields.includes(pair.idSelect)}
            onFocus={() => setFocusedArea(true)}
            onBlur={() => setFocusedArea(false)}
          >
            <MenuItem value={'0'} dense disabled>
              {t('flowBuilder.flow.nodes.dataApi.menu.option-variable')}
            </MenuItem>
            {filterUsedVariablesInNode.length > 0 &&
              filterUsedVariablesInNode.map((item) => {
                return (
                  <MenuItem key={item.id} value={item.id} dense>
                    {item.name}
                  </MenuItem>
                );
              })}
          </SelectComponent>
        </Stack>
        <CheckBoxFlow
          checked={!pair.disabled}
          onChange={() => handleCheckboxChange(pair.idSelect)}
          {...label}
          size='small'
        />
        <ButtonStyled onClick={() => removeBodyPair(index)} type='button'>
          <FaRegTrashAlt />
        </ButtonStyled>
      </motion.div>
    ));
  };

  return (
    <ItemsFieldsHeader>
      <div className='header_item'>
        <h4>{t('flowBuilder.flow.nodes.dataApi.menu.title-body')}</h4>
        <ButtonStyled
          onClick={addBodyPair}
          type='button'
          title={t('flowBuilder.flow.nodes.dataApi.menu.title-btn-add')}
        >
          <BiPlus size={20} />
        </ButtonStyled>
      </div>
      <div className='content_item'>{renderBodyInputs()}</div>
    </ItemsFieldsHeader>
  );
};
