export async function addNewSchedules(/* dataUpdate */) {
  /*   console.log(dataUpdate); */
  try {
    const data = [
      {
        startSchedule: '01:00:00',
        endSchedule: '02:00:00',
        phone: '100000000',
        queue: 300,
        list: 'list name 1',
        campaign: 'name campaign 1',
      },
      {
        startSchedule: '02:00:00',
        endSchedule: '03:00:00',
        phone: '2000000000',
        queue: 300,
        list: 'list name 2',
        campaign: 'name campaign 2',
      },
      {
        startSchedule: '03:00:00',
        endSchedule: '04:00:00',
        phone: '3000000000',
        queue: 300,
        list: 'list name 3',
        campaign: 'name campaign 3',
      },
      {
        startSchedule: '04:00:00',
        endSchedule: '05:00:00',
        phone: '4000000000',
        queue: 300,
        list: 'list name 4',
        campaign: 'name campaign 4',
      },
      {
        startSchedule: '05:00:00',
        endSchedule: '06:00:00',
        phone: '5000000000',
        queue: 300,
        list: 'list name 5',
        campaign: 'name campaign 5',
      },
      {
        startSchedule: '06:00:00',
        endSchedule: '07:00:00',
        phone: '6000000000',
        queue: 300,
        list: 'list name 6',
        campaign: 'name campaign 6',
      },
      {
        startSchedule: '07:00:00',
        endSchedule: '08:00:00',
        phone: '7000000000',
        queue: 300,
        list: 'list name 7',
        campaign: 'name campaign 7',
      },
      {
        startSchedule: '08:00:00',
        endSchedule: '09:00:00',
        phone: '8000000000',
        queue: 300,
        list: 'list name 8',
        campaign: 'name campaign 8',
      },
      {
        startSchedule: '09:00:00',
        endSchedule: '10:00:00',
        phone: '9000000000',
        queue: 300,
        list: 'list name 9',
        campaign: 'name campaign 9',
      },
      {
        startSchedule: '10:00:00',
        endSchedule: '11:00:00',
        phone: '1000000000',
        queue: 300,
        list: 'list name 10',
        campaign: 'name campaign 10',
      },
    ];

    return data;
  } catch (err) {
    throw new Error(err?.response?.data?.message);
  }
}
