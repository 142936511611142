import { Stack } from '@mui/system';
import { ItemsFieldsHeader } from '../styled';
import { CheckBoxFlow } from '@/components/FormComponents/CheckboxComponent';
import { useTranslation } from 'react-i18next';

export const SoundRateField = ({ soundRate, setSoundRate }) => {
  const { t } = useTranslation();
  return (
    <ItemsFieldsHeader>
      <div className='header_item'>
        <h4 style={{ fontSize: '0.72rem', fontWeight: 600 }}>
          {' '}
          {t('flowBuilder.flow.nodes.dataApi.menu.title-soundRate')}
        </h4>
        <Stack width={'100%'} alignItems={'flex-end'} paddingRight={'14px'}>
          <CheckBoxFlow
            checked={soundRate}
            onChange={(e) => setSoundRate(e.target.checked)}
            size='small'
            sx={{ color: '#3E5CAB !important' }}
          />
        </Stack>
      </div>
    </ItemsFieldsHeader>
  );
};
