import { useId, useState } from 'react';
import { ListDispositions } from './ListDispositions';
import { AddDispositions } from './AddDispositions';

export const ConfigDispositions = ({
  dataCodeLigs: listDispositions,
  handleClose,
}) => {
  const [stageDispositionsFLow, setStageDispositionsFlow] = useState(0);

  const changeFlowConfigState = (stage) => setStageDispositionsFlow(stage);

  const flowGlobalVariables = [
    <ListDispositions
      key={useId()}
      handleClose={handleClose}
      listDispositions={listDispositions}
      changeFlowConfigState={changeFlowConfigState}
    />,
    <AddDispositions
      key={useId()}
      changeFlowConfigState={changeFlowConfigState}
    />,
  ];

  return flowGlobalVariables[stageDispositionsFLow];
};
