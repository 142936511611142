export const phoneMaskTransform = (phoneToMask) => {
  if (phoneToMask) {
    const removeSpacesPhone = phoneToMask.replaceAll(' ', '');

    if (removeSpacesPhone.length === 10) {
      const separateDDD = removeSpacesPhone.substr(0, 3);
      const firstPhone = removeSpacesPhone.substr(3, 3);
      const lastPhone = removeSpacesPhone.substr(6, 8);
      const phoneWithMask = `(${separateDDD}) ${firstPhone} ${lastPhone}`;
      return phoneWithMask;
    }
    if (phoneToMask.length === 11) {
      const separateDDD = phoneToMask.substr(0, 2);
      const indicatorCellPhoneBr = phoneToMask.substr(2, 1);
      const firstPhone = phoneToMask.substr(3, 4);
      const lastPhone = phoneToMask.substr(5, 4);
      const phoneWithMask = `(${separateDDD}) ${indicatorCellPhoneBr} ${firstPhone} ${lastPhone}`;
      return phoneWithMask;
    }
    return phoneToMask;
  } else {
    return '-';
  }
};

export const removeMaskPhone = (phoneMask) => {
  if (phoneMask) {
    if (phoneMask.length === 14) {
      const formatNumberNoMask = phoneMask
        .replace('(', '')
        .replace(')', '')
        .replaceAll(' ', '')
        .replaceAll('_', '');
      return formatNumberNoMask;
    } else {
      return phoneMask;
    }
  } else {
    return '-';
  }
};
