import { ContainerIframeCreate } from './styled';
import { TitleComponent } from '@/components/layout/TitleComponent';
import { SelectComponent } from '@/components/FormComponents/SelectInput';
import { MenuItem } from '@mui/material';
import { OptionsIcon, SelectIconsInput } from '../MenuCreate/styled';
import { listIconsSelect } from '@/components/pages/Dashboard/SectionItemNavigation/iconVerify';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { Stack } from '@mui/system';

export const TitleMenu = ({
  setDataMenuForm,
  dataMenuForm,
  renderSequence = [],
}) => {
  const { t } = useTranslation();
  const validationAreaMenu = (value) => {
    setDataMenuForm((previousValue) => ({
      ...previousValue,
      areaMenu: value,
    }));
  };

  const validationIconMenu = (value) => {
    setDataMenuForm((previousValue) => ({
      ...previousValue,
      icon: value,
    }));
  };

  const validationSequenceMenu = (value) => {
    setDataMenuForm((previousValue) => ({
      ...previousValue,
      sequence: value,
    }));
  };

  let listSequence =
    renderSequence && renderSequence.length > 0
      ? Array.from(Array(renderSequence.length + 2), (_, i) => i + 1)
      : Array.from(Array(10), (_, i) => i + 1);

  useEffect(() => {
    setDataMenuForm((previousValue) => ({
      ...previousValue,
      sequence: renderSequence ? renderSequence.length + 1 : 0,
    }));
  }, []);

  return (
    <ContainerIframeCreate>
      <TitleComponent textTitle={t('screens.addScreen.title-detail-menu')} />

      <SelectComponent
        defaultValue='0'
        labelSelect={t('screens.addScreen.label-area-menu')}
        value={dataMenuForm.areaMenu}
        onChange={(event) => validationAreaMenu(event.target.value)}
      >
        <MenuItem value={'0'} dense disabled>
          {t('screens.addScreen.option-area-select')}
        </MenuItem>
        <MenuItem value={'Products'} dense>
          {t('screens.addScreen.option-area-products')}
        </MenuItem>
        <MenuItem value={'Administrator'} dense>
          {t('screens.addScreen.option-area-administrator')}
        </MenuItem>
      </SelectComponent>
      <Stack direction={'row'} spacing={1} width={'100%'}>
        <SelectIconsInput
          defaultValue='0'
          labelSelect={t('screens.addScreen.label-icon')}
          value={dataMenuForm.icon}
          onChange={(event) => validationIconMenu(event.target.value)}
        >
          <MenuItem value={'0'} dense disabled>
            {t('screens.addScreen.option-icon')}
          </MenuItem>

          {listIconsSelect.length > 0 &&
            listIconsSelect.map((item, i) => (
              <OptionsIcon value={item.name} key={i}>
                {item.icon}
                <span>{item.name}</span>
              </OptionsIcon>
            ))}
        </SelectIconsInput>
        <SelectComponent
          labelSelect='Sequence'
          value={dataMenuForm.sequence}
          onChange={(event) => validationSequenceMenu(event.target.value)}
        >
          <MenuItem value={0} dense disabled>
            {t('screens.addScreen.option-sequence')}
          </MenuItem>
          {listSequence.length > 0 &&
            listSequence.map((item, i) => (
              <MenuItem
                value={item}
                key={i}
                dense
                disabled={
                  renderSequence && renderSequence.includes(item) ? true : false
                }
              >
                {item}
              </MenuItem>
            ))}
        </SelectComponent>
      </Stack>
    </ContainerIframeCreate>
  );
};
