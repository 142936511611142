import styled from 'styled-components';

export const ContainerTableVarsApi = styled.div`
  user-select: none;
  table {
    border-collapse: collapse;
    thead {
      tr {
        th {
          height: 26px;
          width: 50%;

          &:last-child {
            width: 30px !important;
          }
        }
      }
    }
    tbody {
      tr {
        height: 22px;

        td {
          text-transform: initial;
        }
        &:nth-child(even) {
          &:after {
            background-color: #e3e3e366;
          }
        }
        &:after {
          box-shadow: none !important;
          border-radius: 2px;
        }
      }
    }
  }
`;
