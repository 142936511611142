import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ContainerAddTenant, FormAddTenant } from './styled';
import { TitleComponent } from '@/components/layout/TitleComponent';
import { useTranslation } from 'react-i18next';
import { ButtonStyled } from '@/components/FormComponents/ButtonComponent';
import { ControlInput } from '@/components/FormComponents/InputComponent';
import { Grid } from '@mui/material';
import { DescriptionInformation } from '@/components/layout/DescriptionInformation';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';
import { useTenants } from '@/hooks/Tenants/useTenants';
import { successToast } from '@/components/FormComponents/Toast';

export const AddTenant = ({ changeStage }) => {
  const { t } = useTranslation();
  const { mutateCreateTenant, loadingCreateTenant } = useTenants();

  const renderText = (spell) => {
    return t(`tenants.add.${spell}`);
  };

  const addNewTenantSchema = yup.object().shape({
    name: yup.string().required(renderText('msg-required-field')),
    host: yup.string().required(renderText('msg-required-field')),
    port: yup.string().required(renderText('msg-required-field')),
    username: yup.string().required(renderText('msg-required-field')),
    database_name: yup
      .string()
      .required(renderText('msg-required-field'))
      .matches(/^[a-zA-Z0-9_]*$/, renderText('msg-special-character')),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(addNewTenantSchema),
  });

  const handleBack = () => {
    reset();
    changeStage(0);
  };

  useEffect(() => {
    return () => handleBack();
  }, []);

  const handleSubmitNewTenant = (data) => {
    const dataCreate = {
      name: data.name,
      host: data.host,
      port: data.port,
      username: data.username,
      database_name: data.database_name,
    };
    mutateCreateTenant(dataCreate, {
      onSuccess: () => {
        successToast(renderText('msg-created-tenant'));
        handleBack();
      },
    });
  };

  const isLoading = loadingCreateTenant;

  return (
    <ContainerAddTenant initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      {isLoading && <BackDropCircularLoading />}
      <FormAddTenant onSubmit={handleSubmit(handleSubmitNewTenant)}>
        <TitleComponent textTitle={renderText('title')} iconTitle='tenants' />
        <div className='mainFormTenants'>
          <DescriptionInformation textDescription={renderText('description')} />
          <ControlInput
            control={control}
            nameControl={'name'}
            label={renderText('label-name')}
            error={Boolean(errors.name)}
            helperText={errors.name && errors.name.message}
          />
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <ControlInput
                control={control}
                nameControl={'host'}
                label={renderText('label-host')}
                error={Boolean(errors.host)}
                helperText={errors.host && errors.host.message}
              />
            </Grid>
            <Grid item xs={4}>
              <ControlInput
                control={control}
                nameControl={'port'}
                label={renderText('label-port')}
                error={Boolean(errors.port)}
                helperText={errors.port && errors.port.message}
              />
            </Grid>
          </Grid>
          <ControlInput
            control={control}
            nameControl={'username'}
            label={renderText('label-userName')}
            error={Boolean(errors.username)}
            helperText={errors.username && errors.username.message}
          />
          <ControlInput
            control={control}
            nameControl={'database_name'}
            label={renderText('label-databaseName')}
            error={Boolean(errors.database_name)}
            helperText={errors.database_name && errors.database_name.message}
          />
        </div>
        <div className='footerAddNewTenant'>
          <ButtonStyled
            variantstyle={'secondary'}
            onClick={handleBack}
            size={'small'}
          >
            {t('usersAgentsList.addUser.btn-cancel')}
          </ButtonStyled>
          <ButtonStyled type='submit' size={'small'}>
            {t('usersAgentsList.addUser.btn-confirm')}
          </ButtonStyled>
        </div>
      </FormAddTenant>
    </ContainerAddTenant>
  );
};
