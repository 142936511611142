import styled from 'styled-components';

export const ContainerAgentsComponents = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  max-width: 3840px;
  max-height: 2160px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  overflow-y: hidden;
`;

export const ContainerMainAgents = styled.div`
  flex: 1;
  height: 100%;
  z-index: 0;
  background-color: ${(props) => props.theme.colors.containers.baseBackground};
  position: relative;

  .box-verify-click {
    background-color: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 100;
  }
`;
