import styled from 'styled-components';

export const FormAddDispositions = styled.form`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1.4rem;

  .titleConfig {
    h4 {
      font-size: 0.85rem;
      text-transform: uppercase;
      font-weight: 400;
      color: ${(props) => props.theme.colors.typography.baseText}AA;
    }

    svg {
      font-size: 0.85rem;
      color: ${(props) => props.theme.colors.typography.baseTitle};
    }
  }
`;

export const HeaderConfig = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.65rem;

  button {
    background-color: gray !important;
  }

  .activeStage {
    background-color: ${(props) => props.theme.colors.primary} !important;
  }
`;

export const ContentIntents = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  h5 {
    font-size: 0.85rem;
    text-transform: uppercase;
    font-weight: 400;
    color: ${(props) => props.theme.colors.typography.baseText}AA;
  }

  ul {
    height: 30vh;
    overflow: auto;
    list-style: none;
    li {
      margin: 0 2px 8px 0;

      &:last-child {
        margin: 0 2px 0px 0;
      }
    }
  }
`;

export const PanelIntents = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`;
