import { ButtonStyled } from '@/components/FormComponents/ButtonComponent';
import { MdArrowBack, MdArrowForward } from 'react-icons/md';
import { ContainerControlList } from './styledItems';
import { useTranslation } from 'react-i18next';

export const ControlList = ({ controls, listAvailable, listSelected }) => {
  const { t } = useTranslation();
  return (
    <ContainerControlList>
      <ButtonStyled
        onClick={controls.handleSelectAllValues}
        disabled={listAvailable.length === 0}
        title={t('flowBuilder.configs.title-addALl')}
      >
        <MdArrowForward size={20} />
      </ButtonStyled>
      <ButtonStyled
        onClick={controls.handleUnselectAllValues}
        disabled={listSelected && listSelected.length === 0}
        title={t('flowBuilder.configs.title-removeALl')}
      >
        <MdArrowBack size={20} />
      </ButtonStyled>
    </ContainerControlList>
  );
};
