import { useTranslation } from 'react-i18next';
import { DialogComponent } from '@/components/DialogComponent';
import { ButtonStyled } from '@/components/FormComponents/ButtonComponent';
import { ContentDetails, PanelDetails } from './styled';
import { useAtom } from 'jotai';
import { lastUserUpdate, templateSelected } from '@/store/FlowBuilder';
import { BsFillInfoCircleFill } from 'react-icons/bs';
import { renderDate } from '@/utils/formatsDateAndTime';

export const DetailsCurrentFlow = ({ open, close }) => {
  const { t } = useTranslation();
  const [template] = useAtom(templateSelected);
  const [lastUserName] = useAtom(lastUserUpdate);

  const filterClientLastUpdate = () => {
    return lastUserName;
  };

  const handleClose = () => {
    close(false);
  };

  return (
    <DialogComponent open={open} onClose={() => close(false)} fullWidth={true}>
      <ContentDetails>
        <div className='heater_details'>
          <BsFillInfoCircleFill />
          <h4>{t('flowBuilder.flow.navigationFlow.title-detailsFlow')}</h4>
        </div>
        <div className='divider'></div>
        <div className='item-details'>
          <span>id:</span>
          <span>{template._id}</span>
        </div>
        <div className='item-details'>
          <span>{t('flowBuilder.newFlow.label-name')}:</span>
          <span>{template.nameIVR}</span>
        </div>
        <div className='item-details'>
          <span>
            {t('flowBuilder.flow.navigationFlow.label-description-save-flow')}:
          </span>
          <span>
            {template.descriptionIVR
              ? template.descriptionIVR
              : t('flowBuilder.newFlow.label-description')}
          </span>
        </div>
        <div className='item-details'>
          <span>{t('flowBuilder.flow.navigationFlow.label-user')}:</span>
          <span>{filterClientLastUpdate(template.user_id)}</span>
        </div>
        <div className='item-details'>
          <span>{t('flowBuilder.flow.navigationFlow.label-lastUpdate')}:</span>
          <span>{renderDate(template.lastUpdate)}</span>
        </div>
        <div className='item-details'>
          <span>status:</span>
          <span>
            {template.published
              ? t('flowBuilder.recentFlow.status-card-flow-published')
              : t('flowBuilder.recentFlow.status-card-flow-notPublished')}
          </span>
        </div>

        <PanelDetails>
          <ButtonStyled size='small' onClick={handleClose}>
            Ok
          </ButtonStyled>
        </PanelDetails>
      </ContentDetails>
    </DialogComponent>
  );
};
