import styled, { css } from 'styled-components';

export const NodeInitContainer = styled.div`
  background: ${(props) => props.theme.colors.containers.colorSecondNode};
  padding: 5px 10px;
  border-radius: 4px;

  &::before,
  &::after {
    content: '';
    position: absolute;
    border-radius: 4px;
    left: -2px;
    top: -2px;
    background: linear-gradient(
      45deg,
      #4c48ff,
      #ff57ab,
      #ff4c43,
      #ffb839,
      #43ff86,
      #4c48ff
    );

    background-size: 400%;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    z-index: -1;
    animation: steam 26s linear infinite;

    ${(props) =>
      props.errorConnect &&
      css`
        background: linear-gradient(45deg, red, red, red, red, red, red);
      `}
  }

  @keyframes steam {
    0% {
      background-position: 0 0;
    }
    50% {
      background-position: 400% 0;
    }
    100% {
      background-position: 0 0;
    }
  }

  .react-flow__handle {
    background: #4c48ff;
    width: 14px;
    height: 14px;
    border-radius: 10px;
    border: 2px solid ${(props) => props.theme.colors.containers.colorCard};
    right: -8px;
  }

  .panelNode {
    width: 100%;
    position: absolute;
    right: 0;
    top: 0px;
    opacity: 0;
    transition: all 0.1s ease-in-out;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 4px;
    gap: 4px;
  }

  &:hover {
    > .panelNode {
      opacity: 1;
      top: -26px;
    }
  }

  .headerNode {
    display: flex;
    flex-direction: column;
    word-break: break-all;
    font-size: 0.6rem;
  }
`;

export const MainNodeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    font-size: 0.75rem;
    color: ${(props) => props.theme.colors.typography.baseTitle};
    font-weight: 600;
    text-transform: uppercase;
  }
`;
