import { Routes, Route } from 'react-router-dom';

import { Login } from '../pages/Login';
import { Dashboard } from '../pages/Dashboard';
import { ChangePassword } from '../pages/ChangePassword';
import { CustomerService } from '../pages/CustomerService';
import { NewUserPassword } from '../pages/NewUserPassword';

import { ProtectedLayout } from '@components/ProtectedLayout';

const RoutesComponent = () => (
  <Routes>
    <Route path='/' element={<Login />} />
    <Route path='*' element={<Login />} />
    <Route path='/newPass/:token' element={<ChangePassword />} />
    <Route path='/newUser/:token' element={<NewUserPassword />} />

    <Route
      path='/dashboard'
      element={
        <ProtectedLayout>
          <Dashboard />
        </ProtectedLayout>
      }
    />

    <Route
      path='/customerService'
      element={
        <ProtectedLayout>
          <CustomerService />
        </ProtectedLayout>
      }
    />
  </Routes>
);

export default RoutesComponent;
