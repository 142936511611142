import { v4 as uuid } from 'uuid';

import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';
import { motion } from 'framer-motion';
import { RiDraggable } from 'react-icons/ri';
import { IoMdArrowRoundBack } from 'react-icons/io';
import { ContainerNavigationFlow, HandlerItemNode } from './styled';
import { successToast } from '@/components/FormComponents/Toast';
import { ConfirmationComponent } from '@/components/layout/ConfirmationComponent';
import { renderIconsFlow } from '../renderIconsFlow';
import {
  dataNodeErrorConnect,
  hasModalOpen,
  isSavedFlow,
  stageFlow,
  templateSelected,
  variablesList,
} from '@/store/FlowBuilder';
import { CircularAbsoluteLoading } from '@/components/layout/LoadingComponent';
import { useSaveFlow } from '@/hooks/FlowBuilder/useSaveFlow';
import { listNodesRender } from './ListNodesNavigation';
import { FloatNavigation } from './FloatNavigation';
import { hiddenSidebar } from '@/store/NavigationDashboard';
import { usePermissionsFlow } from '@/hooks/FlowBuilder/usePermissionsFlow';
import { useUser } from '@/hooks/UserAgents/useUser';

export const NavigationFlow = ({ dataNodes, changeStageFlow }) => {
  const { t } = useTranslation();
  const { renderLastUpdatedUserFlow } = useUser();
  const [, setNodeErrorConnect] = useAtom(dataNodeErrorConnect);

  const { permissionsCreate, permissionsEdit, isSysAdmin } =
    usePermissionsFlow();

  const { handleRenderNodes, loadingHandleFlow } = useSaveFlow(dataNodes);
  const [isSaved, setIsSaved] = useAtom(isSavedFlow);
  const [, setCurrentStage] = useAtom(stageFlow);
  const [template, setTemplate] = useAtom(templateSelected);

  const [dataImportNewData] = useState(null);

  const [confirmationImportNewData, setConfirmationImportNewData] =
    useState(false);
  const [confirmationNewFlow, setConfirmationNewFlow] = useState(false);
  const [confirmationExitFlow, setConfirmationExitFlow] = useState(false);
  const [listVariable, setListVariables] = useAtom(variablesList);
  const [, setIsVisibleSidebar] = useAtom(hiddenSidebar);

  const [, setModalIsOpened] = useAtom(hasModalOpen);

  function removeDuplicates(arr) {
    const seenIds = new Set();
    const seenNames = new Set();
    return arr.filter((item) => {
      if (seenIds.has(item.id) || seenNames.has(item.name)) {
        return false;
      } else {
        seenIds.add(item.id);
        seenNames.add(item.name);
        return true;
      }
    });
  }

  // !RENDER IMPORT FLOW
  const renderImportTemplate = (template) => {
    const { nodes, edges, flow } = template;
    let changeSelectedToFalse = nodes.map((node) => {
      return { ...node, selected: false };
    });

    let changeEdgeSelectedToFalse = edges.map((edge) => {
      return { ...edge, selected: false };
    });

    if (flow) {
      setListVariables([]);

      // ADDED VARS FROM CONTEXT
      const filterPropsVariables = Object.entries(flow)
        .filter(
          ([, value]) =>
            value.handler === 'dialplanVariables' && value.listVariables
        )
        .map(([, value]) => value.listVariables)
        .flat();

      // ADDED CONDITIONAL VARS
      const filterConditionsVariablesUtterance = Object.entries(flow)
        .filter(([, value]) => value.handler === 'recVoz' && value.utterance)
        .map(([, value]) => value.utterance);

      const filterConditionsVariablesSlot = Object.entries(flow)
        .filter(([, value]) => value.handler === 'recVoz' && value.slot)
        .map(([, value]) => value.slot);

      const dataConditionsVars = [
        ...filterConditionsVariablesUtterance,
        ...filterConditionsVariablesSlot,
      ];

      const mapCondVars = dataConditionsVars.map((item) => {
        const importVar = {
          id: item,
          name: item,
          value: item,
          parent: '',
          referNode: false,
        };

        return importVar;
      });

      const listVars = listVariable;

      listVars.forEach((item) => {
        const exists = filterPropsVariables.some(
          (varItem) => varItem.id === item.id
        );
        if (!exists) {
          filterPropsVariables.push(item);
        }
      });

      const uniqueList = removeDuplicates([
        ...mapCondVars,
        ...filterPropsVariables,
      ]);

      setListVariables(uniqueList);

      const { setNodes, setEdges } = dataNodes;

      setNodes(changeSelectedToFalse);
      setEdges(changeEdgeSelectedToFalse);
    }
  };

  const onReturnComponentReset = () => {
    setTemplate({});
    setCurrentStage(0);
  };

  useEffect(() => {
    if (template) {
      renderLastUpdatedUserFlow(template.user_id);
      renderImportTemplate(template);

      setTimeout(() => {
        setIsSaved(true);
      }, 200);
    }

    return () => {
      clearTimeout();
      setListVariables([]);
      onReturnComponentReset();
    };
  }, []);

  const onDragStart = (event, nodeType) => {
    event.dataTransfer.setData('application/reactflow', nodeType);
    event.dataTransfer.effectAllowed = 'move';
  };

  const handleConfirmationImport = useCallback(() => {
    if (dataImportNewData) {
      const { nodes, edges } = dataImportNewData;
      const { setNodes, setEdges } = dataNodes;
      setNodes(nodes);
      setEdges(edges);
      successToast('Flow importado com sucesso!');
    }
  }, [dataNodes, dataImportNewData]);

  const processExitFlow = () => {
    const { setNodes, setEdges } = dataNodes;
    setIsSaved(true);
    setNodes([]);
    setEdges([]);
    setTemplate({});
    changeStageFlow(0);
    setModalIsOpened(false);
    setNodeErrorConnect(null);
  };

  const processSaveOnExitFlow = () => {
    const renderObject = handleRenderNodes();

    if (!renderObject) {
      return;
    }

    return processExitFlow();
  };

  const handleBackStage = () => {
    setIsVisibleSidebar(true);
    if (!isSaved) {
      setModalIsOpened(true);
      return setConfirmationExitFlow(true);
    }
    return processExitFlow();
  };

  const handleConfirmationNewFlow = useCallback(() => {
    const { setNodes, setEdges } = dataNodes;
    setNodes([
      {
        id: '1',
        type: 'initNode',
        data: {
          label: 'First',
          handler: 'dbQuery',
          query: 'newUser',
          next: 'inputClient',
        },
        position: { x: 60, y: 400 },
      },
    ]);
    setEdges([]);
  }, []);

  const renderNameHandler = (type) => {
    return t(`flowBuilder.flow.navigationFlow.handlers.${type}`);
  };

  const handleCloseConfirmationModal = () => {
    setModalIsOpened(false);
    setConfirmationExitFlow(false);
  };

  const permissionsUser = permissionsCreate || permissionsEdit || isSysAdmin;

  return (
    <ContainerNavigationFlow>
      {loadingHandleFlow && <CircularAbsoluteLoading />}
      <div className='content-back'>
        <motion.button
          whileTap={{ scale: 0.95 }}
          title={'back stage'}
          onClick={handleBackStage}
        >
          <IoMdArrowRoundBack />
        </motion.button>
      </div>
      <div className='list-handlers'>
        <h4>{t('flowBuilder.flow.navigationFlow.title-handlers')}</h4>

        {listNodesRender.map((nodeType, i) => {
          if (nodeType.type === 'recVoz' && template.nlus.length === 0) {
            return (
              <HandlerItemNode
                key={i}
                onDragStart={(event) => onDragStart(event, nodeType.type)}
                draggable={false}
                typeNode={nodeType.type}
                showItem={false}
              >
                <div className='marker_item'></div>
                <RiDraggable color={'gray'} />
                {renderIconsFlow(nodeType.type)}
                <span>{renderNameHandler(nodeType.type)}</span>
              </HandlerItemNode>
            );
          }
          return (
            <HandlerItemNode
              key={i}
              onDragStart={(event) => onDragStart(event, nodeType.type)}
              draggable={permissionsUser ? nodeType.show : false}
              typeNode={nodeType.type}
              showItem={permissionsUser ? nodeType.show : false}
            >
              <div className='marker_item'></div>
              <RiDraggable color={'gray'} />
              {renderIconsFlow(nodeType.type)}
              <span>{renderNameHandler(nodeType.type)}</span>
            </HandlerItemNode>
          );
        })}
      </div>

      <FloatNavigation dataNodes={dataNodes} />

      <ConfirmationComponent
        actionConfirm={handleConfirmationImport}
        close={() => setConfirmationImportNewData(false)}
        messageConfirm={t('flowBuilder.flow.msg-confirmation-import')}
        open={confirmationImportNewData}
      />
      <ConfirmationComponent
        actionConfirm={handleConfirmationNewFlow}
        close={() => setConfirmationNewFlow(false)}
        messageConfirm={t('flowBuilder.flow.msg-confirmation-import')}
        open={confirmationNewFlow}
      />
      <ConfirmationComponent
        actionConfirm={processSaveOnExitFlow}
        actionClose={processExitFlow}
        close={handleCloseConfirmationModal}
        txtBtnClose={t('flowBuilder.flow.btn-confirmation-exit')}
        txtBtnSuccess={t('flowBuilder.flow.btn-confirmation-save')}
        messageConfirm={t('flowBuilder.flow.msg-confirmation-exit')}
        open={confirmationExitFlow}
      />
    </ContainerNavigationFlow>
  );
};
