import { FormControl } from '@mui/material';
import styled from 'styled-components';

export const StyledFormControl = styled(FormControl)`
  width: 100%;

  label {
    color: ${(props) => props.theme.colors.typography.baseText};
    line-height: 1.7375em;
  }

  .helper-color {
    color: #d32f2f;
  }

  .MuiButtonBase-root .MuiMenuItem-gutters {
    color: ${(props) => props.theme.colors.typography.baseText};
  }

  .MuiInputBase-input {
    color: ${(props) => props.theme.colors.typography.baseText};
    font-size: 0.875rem;
    padding-top: 10px;
  }

  .Mui-focused {
    color: ${(props) => props.theme.colors.typography.baseText} !important;
  }

  &:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.colors.typography.baseText};
  }
  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.colors.typography.baseText}AA;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.colors.typography.baseText}AA;
  }

  svg {
    color: ${(props) => props.theme.colors.typography.baseText};
  }
`;
