import styled from 'styled-components';

export const ContainerFabControl = styled.div`
  position: absolute;
  bottom: 0px;
  right: 0px;

  padding-right: 0px;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;

  @media (max-height: 500px) {
    display: none;
  }

  .configFlow {
    background-color: #1780c8;
    &:hover {
      background-color: #59b5f3;
    }
  }

  .detailsBtn {
    background-color: ${(props) => props.theme.colors.secondary};
    box-shadow: none;
    &:hover {
      background-color: ${(props) => props.theme.colors.secondary};
    }
  }

  .exportBtn {
    background-color: ${(props) => props.theme.colors.primaryDark};
    box-shadow: none;
    svg {
      color: white;
      font-size: 1.15rem;
    }
    &:hover {
      background-color: ${(props) => props.theme.colors.primaryDark};
    }
  }

  .importBtn {
    background-color: ${(props) => props.theme.colors.primaryDark};
    box-shadow: none;
    svg {
      color: white;
      font-size: 1.15rem;
    }
    &:hover {
      background-color: ${(props) => props.theme.colors.primaryDark};
    }
  }

  .saveBtn {
    background-color: ${(props) => props.theme.colors.success};
    box-shadow: none;
    &:hover {
      background-color: ${(props) => props.theme.colors.success};
    }
  }

  .publishBtn {
    background-color: ${(props) => props.theme.colors.primary};
    box-shadow: none;
    svg {
      font-size: 1.4rem;
    }
    &:hover {
      background-color: ${(props) => props.theme.colors.primaryDark};
    }
  }

  .listButtons {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    height: auto !important;
    margin: 1rem 0;
    gap: 1rem;
    width: 100%;
    overflow: hidden;

    .subItemsControl {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      gap: 1rem;
      svg {
        animation: none;
      }
    }
  }

  button {
    background-color: ${(props) => props.theme.colors.primary};

    svg {
      color: white;
      font-size: 1.15rem;

      @keyframes rotate {
        to {
          transform: rotate(360deg);
        }
      }
    }

    &:hover {
      background-color: ${(props) => props.theme.colors.primaryDark};
      svg {
        animation: rotate 5s linear infinite;
      }
    }
  }
`;
