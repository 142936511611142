import { ConditionNode } from './ConditionNode';
import { HangUpNode } from './HangUpNode';
import { InitNode } from './InitNode';
import { PersonaNode } from './PersonaNode';

import { PlayFilesNode } from './PlayFilesNode';
import { RecVozNode } from './RecVozNode';
import { ItemRecVoicceNode } from './RecVozNode/itemRecVoicce';
import { SubNode } from './SubNode';
import { PlayPromptNode } from './PlayPromptNode';
import { VariablesNode } from './VariablesNode';
import { DispositionNode } from './DispositionNode';
import { DataApiNode } from './DataApiNode';
import { FunctionNode } from './FunctionNode';
import { MonestNode } from './MonestNode';
import { LoopNode } from './LoopNode';
import { ItemLoopVoicce } from './LoopNode/itemLoopVoicce';
import { TransferNode } from './TransferNode';
import { DeepCenterNode } from './DeepCenterNode';

export const nodeTypes = {
  initNode: InitNode,
  dialplanVariables: PersonaNode,
  hangUp: HangUpNode,
  playFiles: PlayFilesNode,
  recVoz: RecVozNode,
  ttsVoicceLabs: PlayPromptNode,
  conditionalNode: ConditionNode,
  disposition: DispositionNode,
  setVariables: VariablesNode,
  apiData: DataApiNode,
  monestIa: MonestNode,
  deepCenter: DeepCenterNode,
  transferQueue: TransferNode,
  functionHandler: FunctionNode,
  loop: LoopNode,
  itemRecVoicceNode: ItemRecVoicceNode,
  itemLoopVoicce: ItemLoopVoicce,
  subNode: SubNode,
};
