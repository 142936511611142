import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { dataCallInformation } from '@store/AgentsStore';
import { user } from '@store/AuthenticatorStore';
import { dateFormatEn, timeFormatEn } from '@/utils/formatsDateAndTime';
import { Stack } from '@mui/material';
import {
  AgentInformationBox,
  ContentInformationItem,
  DividerAgentsSidebar,
  DividerAgentsSidebarY,
  ItemInformation,
  ItemInformationColumn,
  ListInformationBox,
  StatusLine,
  TitleInformationSidebar,
} from './styled';

export const InformationItemAgents = () => {
  const { t } = useTranslation();

  const [clientUser] = useAtom(user);
  const [dataCall] = useAtom(dataCallInformation);

  const verifyData = Object.keys(dataCall).length === 0;

  const generateStatus = (currentStatus) => {
    switch (currentStatus) {
      case 'LIVRE':
        return t('agents.dashboard.statusCallFree');
      case 'AGLIG':
        return t('agents.dashboard.statusCallWaiting');
      case 'EMLIG':
        return t('agents.dashboard.statusCallInCall');
      case 'POSLIG':
        return t('agents.dashboard.statusCallAfterCall');
      case 'PAUSA':
        return t('agents.dashboard.statusCallPause');

      default:
        return currentStatus;
    }
  };

  const generateTypeQueue = (currentType) => {
    switch (currentType) {
      case 'preditivo':
        return t('agents.dashboard.itemTypeQueue');

      default:
        return currentType;
    }
  };

  return (
    <ContentInformationItem>
      <AgentInformationBox>
        <TitleInformationSidebar firstTitle>
          {t('agents.dashboard.titleAgent')}
        </TitleInformationSidebar>
        <ItemInformation>
          <span>Login:</span>
          <span>{clientUser && clientUser.name}</span>
        </ItemInformation>
        <ItemInformation>
          <span> {t('agents.dashboard.itemBranch')}:</span>
          <span>{clientUser && clientUser.extension}</span>
        </ItemInformation>
        <StatusLine status={dataCall.estado}>
          <span>Status:</span>
          <span>
            {dataCall.estado ? generateStatus(dataCall.estado) : 'disconnected'}
          </span>
        </StatusLine>
      </AgentInformationBox>
      <br />
      <DividerAgentsSidebar />
      {!verifyData ? (
        <ListInformationBox>
          <TitleInformationSidebar>
            {t('agents.dashboard.titleInformation')}
          </TitleInformationSidebar>
          <Stack direction={'row'} spacing={1}>
            <ItemInformationColumn>
              <span>{t('agents.dashboard.itemQueue')}:</span>
              <span>{dataCall ? dataCall.fila_primaria : '-'}</span>
            </ItemInformationColumn>
            <DividerAgentsSidebarY />
            <ItemInformationColumn>
              <span>{t('agents.dashboard.itemType')}:</span>
              <span>
                {dataCall.origem ? generateTypeQueue(dataCall.origem) : '-'}
              </span>
            </ItemInformationColumn>
          </Stack>
          <DividerAgentsSidebar />
          <ItemInformationColumn>
            <span>{t('agents.dashboard.itemSituation')}:</span>
            <span>{dataCall ? generateStatus(dataCall.estado) : '-'}</span>
          </ItemInformationColumn>
          <DividerAgentsSidebar />
          <ItemInformationColumn infospacing={true}>
            <span>{t('agents.dashboard.itemLastModify')}:</span>
            <ItemInformation>
              <span>{t('agents.dashboard.itemDate')}:</span>
              <span className='noMarginInfo'>
                {dateFormatEn(dataCall.ultima_modificacao)}
              </span>
            </ItemInformation>
            <ItemInformation>
              <span>{t('agents.dashboard.itemHour')}:</span>
              <span className='noMarginInfo'>
                {timeFormatEn(dataCall.ultima_modificacao)}
              </span>
            </ItemInformation>
          </ItemInformationColumn>
        </ListInformationBox>
      ) : (
        ''
      )}
    </ContentInformationItem>
  );
};
