import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useAtom } from 'jotai';
import { motion } from 'framer-motion';
import { MdEdit } from 'react-icons/md';
import { IoClose } from 'react-icons/io5';
import { BsCheck2 } from 'react-icons/bs';
import * as yup from 'yup';
import { FaMicrophone, FaMicrophoneSlash } from 'react-icons/fa';
import { PanelSelectedLine } from './PanelSelectedLine';
import { TablePagination } from '@/components/Tables/TablePagination';
import useWindowDimensions from '@/hooks/useWidth';
import { ButtonStyled } from '@/components/FormComponents/ButtonComponent';
import { MenuItem, Stack } from '@mui/material';
import useFormControl from '@/hooks/useFormControl';
import { ControlSelect } from '@/components/FormComponents/SelectInput';
import { dataListCampaigns } from '@/store/Campaigns';
import { useListCampaign } from '@/hooks/dialControl/ListCampaigns/useListCampaigns';
import { errorToast, successToast } from '@/components/FormComponents/Toast';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';
import { selectedListCampaign } from '@/store/ListCampaigns';
import { ContainerListSelected, ContentFormListCampaign } from './styled';
import { useTranslation } from 'react-i18next';
import { formatDate } from '@/utils/formatsDateAndTime';
import { FiTrash2 } from 'react-icons/fi';
import { dataListsDialing } from '@/store/DialingStore';
import { usePermissionsListCampaigns } from '../../hooks/usePermissionsListCampaigns';

export const SelectedList = ({ controlStage }) => {
  const { t } = useTranslation();
  const { permissionsEdit /* permissionsDelete */ } =
    usePermissionsListCampaigns();
  const tableCampaigns = useRef(null);
  const { height } = useWindowDimensions();
  const [selected, setSelected] = useAtom(selectedListCampaign);
  const [listsDialing] = useAtom(dataListsDialing);
  const [heightTable, setHeightTable] = useState(0);
  const [selectedRow, setSelectedRow] = useState({});
  const [dataCampaign] = useAtom(dataListCampaigns);

  useEffect(() => {
    const filterSelected = listsDialing.filter(
      (lists) => lists.name === selected.name
    );
    if (filterSelected.length > 0) {
      setSelected(filterSelected[0]);
    } else {
      setSelected(null);
      controlStage.toStage(0);
    }
  }, [listsDialing]);

  const [filterList, setFilterList] = useState('');

  function removeDuplicates(arr) {
    return arr.filter((item, index) => arr.indexOf(item) === index);
  }

  const filterListTable = () => {
    if (selected.parents) {
      const filterByDescricao = selected.parents.filter((data) =>
        data.descricao.toLowerCase().includes(filterList.toLowerCase())
      );

      const filterByLista = selected.parents.filter((data) =>
        data.lista.toString().toLowerCase().includes(filterList.toLowerCase())
      );

      const newArrFiltered = [...filterByDescricao, ...filterByLista];
      const uniqueFiltered = removeDuplicates(newArrFiltered);

      return uniqueFiltered.sort(
        (a, b) => new Date(b.data_entrada) - new Date(a.data_entrada)
      );
    }
    return [];
  };

  const {
    mutateUpdateListCampaign,
    loadingUpdateListCampaign,
    // mutateDeleteListCampaign,
    mutateGetListCampaign,
    loadingGetListCampaign,
  } = useListCampaign();

  useEffect(() => {
    mutateGetListCampaign();
  }, []);

  const renderTxt = (word) => {
    return t(`dialControl.listCampaign.selectedList.${word}`);
  };

  const verifyLengthSelected = Object.keys(selectedRow).length;

  const updateStatusListSchema = yup.object().shape({
    campaignQueue: yup.string(),
    queueStatus: yup.string(),
  });

  const { handleSubmit, control, reset } = useFormControl(
    updateStatusListSchema
  );

  const handleSubmitUpdateQueue = (data) => {
    const dataUpdate = {
      list: selectedRow?.original?.lista,
      campaign: data.campaignQueue,
    };

    mutateUpdateListCampaign(dataUpdate, {
      onSuccess: () => {
        successToast(renderTxt('msg-success'));
        setSelectedRow({});
      },
      onError: () => errorToast(renderTxt('msg-error')),
    });
  };

  const handleEdit = useCallback((selectedRow) => {
    setSelectedRow(selectedRow);
    reset();
  }, []);

  const handleCancelEdit = () => {
    setSelectedRow({});
    reset();
  };

  const handleRemoveList = () => {
    // const dataRemoveList = {
    //   list: selectedRow.original.lista,
    //   campaign: selectedRow.original.campanha,
    // };

    //   mutateDeleteListCampaign(dataRemoveList, {
    //     onSuccess: () => successToast(renderTxt('msg-successDelete')),
    //     onError: () => errorToast(renderTxt('msg-errorDelete')),
    //   });
    setSelectedRow({});
  };

  const escFunction = useCallback((event) => {
    if (event.key === 'Escape') {
      setSelectedRow({});
      reset();
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);
    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, [escFunction]);

  useLayoutEffect(() => {
    if (tableCampaigns) {
      const tableMaxWidth = tableCampaigns.current.offsetHeight;
      const cellMinWidth = 55;
      const cellCount = Math.floor(tableMaxWidth / cellMinWidth);
      setHeightTable(cellCount);
    }
  }, [height, tableCampaigns]);

  const columnsListCampaigns = [
    {
      Header: renderTxt('tableList.th-first'),
      accessor: 'lista',
    },
    {
      Header: renderTxt('tableList.th-second'),
      accessor: 'descricao',
    },
    {
      Header: renderTxt('tableList.th-third'),
      accessor: 'data_entrada',
      Cell: ({ row }) => formatDate(row.original.data_entrada),
    },
    {
      Header: renderTxt('tableList.th-fourth'),
      accessor: 'ativo',
      Cell: ({ row }) => {
        return (
          <motion.div>
            {row.original.ativo === 1 ? (
              <div className='item-status'>
                <FaMicrophone />
                <span>{renderTxt('tableList.status-list-active')}</span>
              </div>
            ) : (
              <div className='item-status disabled-item'>
                <FaMicrophoneSlash />
                <span>{renderTxt('tableList.status-list-disabled')}</span>
              </div>
            )}
          </motion.div>
        );
      },
    },
    {
      Header: renderTxt('tableList.th-fifth'),
      accessor: 'campanha',
      Cell: ({ row }) => {
        if (selectedRow.id !== row.id) {
          return (
            <span
              className={
                verifyLengthSelected > 0
                  ? selectedRow.id !== row.id
                    ? ''
                    : 'selected-row'
                  : ''
              }
            >
              {row.original.campanha}
            </span>
          );
        }
        return (
          <motion.div
            initial={{ opacity: 0, translateX: '-80px' }}
            animate={{ opacity: 1, translateX: '0px' }}
          >
            <ControlSelect
              control={control}
              nameControl={'campaignQueue'}
              defaultValue={row.original.campanha}
            >
              {dataCampaign.map((item, i) => (
                <MenuItem key={i} value={item.campanha} dense>
                  {item.campanha}
                </MenuItem>
              ))}
            </ControlSelect>
          </motion.div>
        );
      },
    },
    {
      Header: renderTxt('tableList.th-sixth'),
      Cell: ({ row }) => {
        if (selectedRow.id !== row.id) {
          return (
            <Stack direction={'row'} justifyContent={'flex-end'}>
              <ButtonStyled
                onClick={() => handleEdit(row)}
                disabled={
                  Boolean(verifyLengthSelected && selectedRow.id !== row.id) ||
                  !permissionsEdit
                }
              >
                <MdEdit />
              </ButtonStyled>
            </Stack>
          );
        }
        return (
          <motion.div
            initial={{ opacity: 0, translateX: '-80px' }}
            animate={{ opacity: 1, translateX: '0px' }}
          >
            <Stack direction={'row'} justifyContent={'space-between'}>
              <Stack direction={'row'} spacing={1}>
                <ButtonStyled
                  variantstyle='error'
                  onClick={() => handleRemoveList()}
                  disabled
                >
                  <FiTrash2 />
                </ButtonStyled>

                <ButtonStyled
                  variantstyle='success'
                  type='submit'
                  disabled={!permissionsEdit}
                >
                  <BsCheck2 size={16} />
                </ButtonStyled>
              </Stack>

              <ButtonStyled onClick={handleCancelEdit} variantstyle='secondary'>
                <IoClose size={16} />
              </ButtonStyled>
            </Stack>
          </motion.div>
        );
      },
    },
  ];

  const isLoading = loadingGetListCampaign || loadingUpdateListCampaign;

  return (
    <ContainerListSelected initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <PanelSelectedLine
        controlMainStage={controlStage}
        filterList={filterList}
        setFilterList={setFilterList}
      />
      <form onSubmit={handleSubmit(handleSubmitUpdateQueue)}>
        {isLoading && <BackDropCircularLoading />}
        <ContentFormListCampaign ref={tableCampaigns}>
          <TablePagination
            dataColumns={columnsListCampaigns}
            dataTable={filterListTable}
            rowsPerPageDisplay={heightTable}
            textNoData={renderTxt('tableList.noData')}
            selectedRow={selectedRow?.id ?? null}
            hasSelected={verifyLengthSelected}
          />
        </ContentFormListCampaign>
      </form>
    </ContainerListSelected>
  );
};
