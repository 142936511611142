import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAgents } from '@hooks/Agents/useAgents';
import { ButtonStyled } from '@components/FormComponents/ButtonComponent';
import { SelectComponent } from '@components/FormComponents/SelectInput';
import { BackDropCircularLoading } from '@components/layout/LoadingComponent';
import { ContainerPauseStatus, FormChangePause } from './styled';
import { MenuItem } from '@mui/material';
import { MdPauseCircle } from 'react-icons/md';

export const PauseStatusDialog = ({ closeDialog, handleChangePause }) => {
  const { t } = useTranslation();
  const [pauseType, setPauseType] = useState('0');
  const [errorForm, setErrorForm] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);
  const [dataTeste] = useState([
    {
      id: 1,
      name: t('agents.pauseStatus.statusPauses.freeStatus'),
    },
  ]);

  const { loadingGetPauses } = useAgents();

  useEffect(() => {
    if (errorForm && pauseType !== '0') {
      setErrorForm(false);
    }
  }, [errorForm, pauseType]);

  const handlePause = () => {
    setDisabledButton(true);
    if (pauseType === '0') {
      setErrorForm(true);
      setDisabledButton(false);
      return;
    }
    handleChangePause(pauseType === 'free' && 'livre');
    closeDialog();
  };

  return (
    <ContainerPauseStatus>
      {loadingGetPauses && <BackDropCircularLoading />}
      <div className='titlePause'>
        <MdPauseCircle />
        <h2>{t('agents.pauseStatus.titlePause')}</h2>
      </div>

      <FormChangePause>
        <div className='body_pause'>
          <SelectComponent
            labelSelect={t('agents.pauseStatus.titlePause')}
            value={pauseType}
            onChange={(event) => setPauseType(event.target.value)}
            error={errorForm}
          >
            <MenuItem value={'0'}>
              {t('agents.pauseStatus.labelDefaultPause')}
            </MenuItem>
            {dataTeste &&
              dataTeste.map((item) => (
                <MenuItem key={item.id} value={item.name.toLowerCase()}>
                  {item.name}
                </MenuItem>
              ))}
          </SelectComponent>
        </div>
        <div className='footer_pause'>
          <ButtonStyled variantstyle={'secondary'} onClick={closeDialog}>
            {t('agents.pauseStatus.btn-cancel')}
          </ButtonStyled>
          <ButtonStyled onClick={handlePause} disabled={disabledButton}>
            {t('agents.pauseStatus.btn-save')}
          </ButtonStyled>
        </div>
      </FormChangePause>
    </ContainerPauseStatus>
  );
};
