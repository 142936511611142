import { ContentSectionLeft } from './styled';

// import LogoImg from '@/assets/imgs/logo_voicce_white.png';
import LogoImg from '@/assets/imgs/lg_voiccelabs_white.webp';
import { ImageComponent } from '@components/layout/ImageComponent';

export const LeftSideLogin = () => {
  return (
    <ContentSectionLeft>
      <ImageComponent src={LogoImg} width={'399px'} height={'99px'} />
    </ContentSectionLeft>
  );
};
