import { memo } from 'react';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import { CardStatusQueue } from './styled';
import { Stack } from '@mui/system';
import { selectedListCampaign } from '@/store/ListCampaigns';
import { IoMdArrowDroprightCircle } from 'react-icons/io';

export const CardStatusComponent = ({ dataGroup, changeFlowDialing }) => {
  const { t } = useTranslation();
  const [, setSelected] = useAtom(selectedListCampaign);

  const handleDescriptionQueue = (queue) => {
    setSelected(queue);
    changeFlowDialing(1);
  };

  return (
    <CardStatusQueue
      whileTap={{ scale: 0.99 }}
      onClick={() => handleDescriptionQueue(dataGroup)}
    >
      <div className='contentImage'></div>

      <div className='contentCard'>
        <span>
          <strong>{dataGroup.name}</strong>
        </span>
        <Stack direction={'row'} alignItems={'center'} gap={'4px'}>
          <IoMdArrowDroprightCircle />
          <span>
            {t(`dialControl.listCampaign.campaignList.label-totalLists`)}{' '}
            <strong> {dataGroup.total}</strong>{' '}
            {t(`dialControl.listCampaign.campaignList.label-lists`)}
          </span>
        </Stack>
      </div>
    </CardStatusQueue>
  );
};

export const CardStatus = memo(CardStatusComponent);
