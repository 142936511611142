import { v4 as uuidv4 } from 'uuid';
export async function getSchedules(/* dataSchedules */) {
  try {
    const data = [
      {
        id: uuidv4(),
        name: 'schedule 1',
        phone: '1234567890',
        callTarget: '00:00',
        lastContact: '00/00/00 - 00:00:00',
        note: 'note 1',
      },
      {
        id: uuidv4(),
        name: 'schedule 1',
        phone: '1234567890',
        callTarget: '00:00',
        lastContact: '00/00/00 - 00:00:00',
        note: 'note 1',
      },
      {
        id: uuidv4(),
        name: 'schedule 1',
        phone: '1234567890',
        callTarget: '00:00',
        lastContact: '00/00/00 - 00:00:00',
        note: 'note 1',
      },
      {
        id: uuidv4(),
        name: 'schedule 1',
        phone: '1234567890',
        callTarget: '00:00',
        lastContact: '00/00/00 - 00:00:00',
        note: 'note 1',
      },
      {
        id: uuidv4(),
        name: 'schedule 1',
        phone: '1234567890',
        callTarget: '00:00',
        lastContact: '00/00/00 - 00:00:00',
        note: 'note 1',
      },
      {
        id: uuidv4(),
        name: 'schedule 1',
        phone: '1234567890',
        callTarget: '00:00',
        lastContact: '00/00/00 - 00:00:00',
        note: 'note 1',
      },
      {
        id: uuidv4(),
        name: 'schedule 1',
        phone: '1234567890',
        callTarget: '00:00',
        lastContact: '00/00/00 - 00:00:00',
        note: 'note 1',
      },
      {
        id: uuidv4(),
        name: 'schedule 1',
        phone: '1234567890',
        callTarget: '00:00',
        lastContact: '00/00/00 - 00:00:00',
        note: 'note 1',
      },
      {
        id: uuidv4(),
        name: 'schedule 1',
        phone: '1234567890',
        callTarget: '00:00',
        lastContact: '00/00/00 - 00:00:00',
        note: 'note 1',
      },
      {
        id: uuidv4(),
        name: 'schedule 1',
        phone: '1234567890',
        callTarget: '00:00',
        lastContact: '00/00/00 - 00:00:00',
        note: 'note 1',
      },
      {
        id: uuidv4(),
        name: 'schedule 1',
        phone: '1234567890',
        callTarget: '00:00',
        lastContact: '00/00/00 - 00:00:00',
        note: 'note 1',
      },
      {
        id: uuidv4(),
        name: 'schedule 1',
        phone: '1234567890',
        callTarget: '00:00',
        lastContact: '00/00/00 - 00:00:00',
        note: 'note 1',
      },
      {
        id: uuidv4(),
        name: 'schedule 1',
        phone: '1234567890',
        callTarget: '00:00',
        lastContact: '00/00/00 - 00:00:00',
        note: 'note 1',
      },
      {
        id: uuidv4(),
        name: 'schedule 1',
        phone: '1234567890',
        callTarget: '00:00',
        lastContact: '00/00/00 - 00:00:00',
        note: 'note 1',
      },
      {
        id: uuidv4(),
        name: 'schedule 1',
        phone: '1234567890',
        callTarget: '00:00',
        lastContact: '00/00/00 - 00:00:00',
        note: 'note 1',
      },
    ];

    return data;
  } catch (err) {
    throw new Error(err?.response?.data?.message);
  }
}

/* -	Name
-	Phone
-	Callback target
-	Last contact
-	note */
