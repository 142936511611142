import { motion } from 'framer-motion';
import styled from 'styled-components';

export const NoDataTableRow = styled(motion.tr)`
  width: 100%;
  height: 80px;
  position: relative;

  .contentNoData {
    user-select: none;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 0.5rem;
    background-color: ${(props) => props.theme.colors.containers.colorCard};
    border-radius: 4px;

    @media (max-width: 500px) {
      display: none;
    }

    span {
      font-size: 0.85rem;
      font-weight: normal;
      word-wrap: normal;
      color: ${(props) => props.theme.colors.typography.baseText};
    }

    svg {
      color: ${(props) => props.theme.colors.secondary};
      font-size: 1.2rem;
    }
  }
`;
