import { useState } from 'react';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import { useSchedules } from '@hooks/Agents/useSchedules';

import { currentStateAgentsDial } from '@store/AgentsStore';

import {
  ContainerCallHistory,
  ContentTable,
  FooterCall,
  PanelSchedule,
} from './styled';

import { DialogComponent } from '@components/DialogComponent';
import { ButtonStyled } from '@components/FormComponents/ButtonComponent';
import { BackDropCircularLoading } from '@components/layout/LoadingComponent';

import { TableScheduleAgent } from './TableScheduleAgent';
import { MdAddCircleOutline } from 'react-icons/md';
import { AddNewSchedule } from './addNewSchedule';
import { securityProfileAgents } from '@/store/NavigationAgentsDashboard';

export const ScheduleAgent = () => {
  const { t } = useTranslation();

  const [securityAccessAgents] = useAtom(securityProfileAgents);

  const { dataSchedule, loadingDataSchedule } = useSchedules();
  const [, setCurrentStateAgents] = useAtom(currentStateAgentsDial);

  const [isOpenNewScheduleDialog, setIsOpenNewScheduleDialog] = useState(false);

  const handleNewSchedule = () => {
    setIsOpenNewScheduleDialog(true);
  };

  return (
    <ContainerCallHistory initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      {loadingDataSchedule && <BackDropCircularLoading />}
      <PanelSchedule>
        <ButtonStyled
          onClick={handleNewSchedule}
          disabled={!securityAccessAgents.canCreate}
        >
          <MdAddCircleOutline />
          <span>{t('agents.scheduleAgent.btnNewSchedule')}</span>
        </ButtonStyled>
      </PanelSchedule>
      <ContentTable>
        <TableScheduleAgent dataSchedule={dataSchedule} />
      </ContentTable>
      <FooterCall>
        <ButtonStyled
          variantstyle={'secondary'}
          onClick={() => setCurrentStateAgents(null)}
        >
          <span>{t('agents.scheduleAgent.btnCancel')}</span>
        </ButtonStyled>
      </FooterCall>

      <DialogComponent
        open={isOpenNewScheduleDialog}
        onClose={() => setIsOpenNewScheduleDialog(false)}
        fullWidth={true}
      >
        <AddNewSchedule closeModal={() => setIsOpenNewScheduleDialog(false)} />
      </DialogComponent>
    </ContainerCallHistory>
  );
};
