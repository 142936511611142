import { motion } from 'framer-motion';
import styled from 'styled-components';

export const FormCurrentLines = styled(motion.form)`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 1fr 40px;

  .main {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 1rem;
  }
`;
