// import { useAtom } from 'jotai';
// import { useEffect, useState } from 'react';
// import {
//   focusedFieldArea,
//   isSavedFlow,
//   selectedNode,
//   showMenuNodes,
//   templateSelected,
// } from '@/store/FlowBuilder';
// import { ButtonStyled } from '@/components/FormComponents/ButtonComponent';
// import { FooterAddNode, FormMenuNode, MainAddNode } from './styled';
// import { useTranslation } from 'react-i18next';
// import { TitleMenuNode } from '../TitleMenuNode';
// import { SelectComponent } from '@/components/FormComponents/SelectInput';
// import { MenuItem } from '@mui/material';

// export const DispositionForm = ({ dataNodes }) => {
//   const [template] = useAtom(templateSelected);
//   const { codeLigs } = template;
//   const { t } = useTranslation();
//   const [, setFocusedArea] = useAtom(focusedFieldArea);
//   const [, setShowMenu] = useAtom(showMenuNodes);
//   const [selected, setSelected] = useAtom(selectedNode);
//   const [, setIsSaved] = useAtom(isSavedFlow);

//   const [disposition, setDisposition] = useState('0');
//   const [dispositionError, setDispositionError] = useState('');

//   const validateDisposition = (value) => {
//     setDisposition(value);
//   };

//   const { nodes, setNodes } = dataNodes;
//   const { id, data } = selected;

//   useEffect(() => {
//     if (data) {
//       const filterSelectedDisposition = template.codeLigs
//         .filter((item) => item.name === data.label)
//         .map((item) => item.name)
//         .toString();

//       setDisposition(filterSelectedDisposition);
//     }
//   }, [data]);

//   const closeMenu = () => {
//     setShowMenu(false);
//     setSelected(null);
//   };

//   const handleChildClick = (e) => {
//     e.stopPropagation();
//   };

//   const handleSubmitNewNode = (e) => {
//     e.preventDefault();

//     if (disposition === '0') {
//       return setDispositionError(
//         t('flowBuilder.flow.nodes.dispositions.menu.msg-code-required')
//       );
//     }

//     const filteredNode = nodes.map((node) =>
//       node.id === id
//         ? {
//             ...node,
//             data: {
//               ...node.data,
//               label: disposition,
//             },
//           }
//         : node
//     );
//     setNodes(filteredNode);
//     setIsSaved(false);
//     closeMenu();
//   };

//   return (
//     <FormMenuNode
//       onClick={handleChildClick}
//       onSubmit={handleSubmitNewNode}
//       id='DispositionForm'
//       name='DispositionForm'
//     >
//       <MainAddNode>
//         <TitleMenuNode
//           title={t('flowBuilder.flow.nodes.dispositions.menu.title')}
//           description={t('flowBuilder.flow.nodes.dispositions.description')}
//         />

//         <SelectComponent
//           labelSelect={'Dispositions'}
//           value={disposition}
//           onChange={(event) => validateDisposition(event.target.value)}
//           onFocus={() => setFocusedArea(true)}
//           onBlur={() => setFocusedArea(false)}
//           helperText={dispositionError}
//           error={Boolean(dispositionError)}
//         >
//           <MenuItem value={'0'} dense disabled>
//             {t('agents.postCallInformation.optionSelectDefault')}
//           </MenuItem>
//           {codeLigs.length > 0 &&
//             codeLigs.map((item) => {
//               return (
//                 <MenuItem value={item.name} key={item.id} dense>
//                   {item.name}
//                 </MenuItem>
//               );
//             })}
//         </SelectComponent>
//       </MainAddNode>
//       <FooterAddNode>
//         <ButtonStyled
//           variantstyle={'secondary'}
//           size={'small'}
//           onClick={closeMenu}
//         >
//           {t('flowBuilder.flow.nodes.dispositions.menu.btn-cancel')}
//         </ButtonStyled>
//         <ButtonStyled
//           type='submit'
//           size={'small'}
//           disabled={disposition === '0'}
//         >
//           {t('flowBuilder.flow.nodes.dispositions.menu.btn-updated')}
//         </ButtonStyled>
//       </FooterAddNode>
//     </FormMenuNode>
//   );
// };

import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';
import {
  focusedFieldArea,
  isSavedFlow,
  selectedNode,
  showMenuNodes,
} from '@/store/FlowBuilder';
import { ButtonStyled } from '@/components/FormComponents/ButtonComponent';
import { FooterAddNode, FormMenuNode, MainAddNode } from './styled';
import { InputComponent } from '@/components/FormComponents/InputComponent';
import { checkLabelExistiNode } from '@/components/pages/FlowBuilder/utils/checkNameNodes';
import { errorToast } from '@/components/FormComponents/Toast';
import { useTranslation } from 'react-i18next';
import { TitleMenuNode } from '../TitleMenuNode';

export const DispositionForm = ({ dataNodes }) => {
  const { t } = useTranslation();
  const [, setFocusedArea] = useAtom(focusedFieldArea);
  const [, setShowMenu] = useAtom(showMenuNodes);
  const [selected, setSelected] = useAtom(selectedNode);
  const [, setIsSaved] = useAtom(isSavedFlow);

  const [labelNode, setLabelNode] = useState('');
  const [labelNodeError, setLabelNodeError] = useState('');

  const validateLabelNode = (value) => {
    if (!value.trim()) {
      setLabelNodeError(
        t('flowBuilder.flow.nodes.dispositions.menu.msg-code-required')
      );
    } else if (value.length > 4) {
      setLabelNodeError(
        t('flowBuilder.flow.nodes.dispositions.menu.msg-code-length', {
          length: '4',
        })
      );
    } else if (!/^[_a-zA-Z0-9]*$/.test(value)) {
      setLabelNodeError(
        t('flowBuilder.flow.nodes.dispositions.menu.msg-code-letters')
      );
    } else {
      setLabelNodeError('');
    }
    setLabelNode(value.toUpperCase());
  };

  const { nodes, setNodes } = dataNodes;
  const { id, data } = selected;

  useEffect(() => {
    if (data) {
      setLabelNode(data.label ? data.label : '');
    }
  }, [data]);

  const closeMenu = () => {
    setShowMenu(false);
    setLabelNode('');
    setSelected(null);
  };

  const handleChildClick = (e) => {
    e.stopPropagation();
  };

  const handleSubmitNewNode = (e) => {
    e.preventDefault();

    const checkLabel = checkLabelExistiNode(id, labelNode, nodes);

    if (checkLabel) {
      return errorToast(
        t('flowBuilder.flow.nodes.dispositions.menu.msg-code-used')
      );
    }

    if (labelNode === '') {
      setLabelNodeError(
        t('flowBuilder.flow.nodes.dispositions.menu.msg-code-required')
      );
      return;
    }

    const filteredNode = nodes.map((node) =>
      node.id === id
        ? {
            ...node,
            data: {
              ...node.data,
              label: labelNode,
            },
          }
        : node
    );
    setNodes(filteredNode);
    setIsSaved(false);
    closeMenu();
  };

  return (
    <FormMenuNode
      onClick={handleChildClick}
      onSubmit={handleSubmitNewNode}
      id='DispositionForm'
      name='DispositionForm'
    >
      <MainAddNode>
        <TitleMenuNode
          title={t('flowBuilder.flow.nodes.dispositions.menu.title')}
          description={t('flowBuilder.flow.nodes.dispositions.description')}
        />

        <InputComponent
          label={t('flowBuilder.flow.nodes.dispositions.menu.label-code')}
          value={labelNode}
          inputProps={{ maxLength: 4 }}
          onChange={(e) => validateLabelNode(e.target.value)}
          autoComplete='off'
          helperText={labelNodeError}
          error={Boolean(labelNodeError)}
          onFocus={() => setFocusedArea(true)}
          onBlur={() => setFocusedArea(false)}
        />
      </MainAddNode>
      <FooterAddNode>
        <ButtonStyled
          variantstyle={'secondary'}
          size={'small'}
          onClick={closeMenu}
        >
          {t('flowBuilder.flow.nodes.dispositions.menu.btn-cancel')}
        </ButtonStyled>
        <ButtonStyled
          type='submit'
          size={'small'}
          disabled={Boolean(labelNodeError || labelNode === '')}
        >
          {t('flowBuilder.flow.nodes.dispositions.menu.btn-updated')}
        </ButtonStyled>
      </FooterAddNode>
    </FormMenuNode>
  );
};
