import styled from 'styled-components';

export const FooterImportMailing = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;

  button {
    span {
      padding: 2px 0px 0px 0px !important;
    }
  }
`;
