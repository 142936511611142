import styled, { css } from 'styled-components';

export const ContentInformationItem = styled.div`
  width: 100%;
  height: auto;
  /* background: rgba(255, 255, 255, 0.4); */
  background: ${(props) => props.theme.colors.containers.baseBackground};
  border-radius: 8px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-shadow: ${(props) => props.theme.colors.containers.baseShadows};
`;

export const AgentInformationBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  @media (max-width: 1600px) {
    gap: 0.4rem;
  }
`;

export const ListInformationBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media (max-width: 1600px) {
    gap: 0.7rem;
  }
`;

export const TitleInformationSidebar = styled.h2`
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-align: center;
  color: ${(props) => props.theme.colors.typography.titleSidebarAgent};
  ${(props) =>
    props.firstTitle
      ? css`
          margin: 1rem;
        `
      : css`
          margin: 1rem;
        `}
`;

export const ItemInformation = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 80px 1fr;
  align-items: center;

  .noMarginInfo {
    margin-bottom: 0.6rem;
  }

  span {
    /* font-family: 'Roboto'; */
    font-style: normal;
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 14px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: ${(props) => props.theme.colors.typography.baseText};
    &:first-child {
      font-weight: 400;
      font-size: 0.625rem;
      letter-spacing: 0.1em;
      text-transform: uppercase;
    }
  }
`;

export const StatusLine = styled(ItemInformation)`
  span {
    &:last-child {
      width: calc(100% - 10px) !important;

      text-align: center;
      width: auto;
      padding: 4px;
      border-radius: 4px;
      ${(props) =>
        props.status
          ? css`
              border: 1px solid
                ${(props) => props.theme.colors.agentColors[props.status]};
              color: ${(props) => props.theme.colors.agentColors[props.status]};
            `
          : css`
              border: 1px solid ${(props) => props.theme.colors.primary};
              color: ${(props) => props.theme.colors.primary};
            `}
    }
  }
`;

export const ItemInformationColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  span {
    font-style: normal;
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 14px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: ${(props) => props.theme.colors.typography.baseText};
    &:first-child {
      font-weight: 400;
      font-size: 0.625rem;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: ${(props) => props.theme.colors.typography.baseText};

      ${(props) =>
        props.infospacing
          ? css`
              margin-bottom: 0.6rem;
            `
          : css`
              margin-bottom: 0;
            `}
    }
  }
`;

export const DividerAgentsSidebar = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${(props) => props.theme.colors.containers.dividerColor};
`;

export const DividerAgentsSidebarY = styled.div`
  width: 1px;
  height: 100%;
  background-color: ${(props) => props.theme.colors.containers.dividerColor};
`;
