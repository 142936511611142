import { ButtonStyled } from '@/components/FormComponents/ButtonComponent';
import {
  ContainerConfig,
  ContainerGlobalVars,
  FooterConfigForm,
  MainConfigForm,
} from './styled';
import { useAtom } from 'jotai';
import { dataDispositions, templateSelected } from '@/store/FlowBuilder';
import { DescriptionInformation } from '@/components/layout/DescriptionInformation';
import { DragTransferList } from '../../DragTransferList';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';
import { useDispositions } from '@/hooks/FlowBuilder/useDispositions';

export const ConfigDispositions = ({ changeStateFlow }) => {
  const { t } = useTranslation();
  const { loadingDispositions } = useDispositions();
  const [codeLigs] = useAtom(dataDispositions);

  const [, setTemplate] = useAtom(templateSelected);

  const [dataCodeLigsSelected, setDataCodeLigsSelected] = useState([]);

  const handleBackStage = () => {
    changeStateFlow(1);
  };

  const handleNextStage = () => {
    setTemplate((previousState) => {
      return {
        ...previousState,
        codeLigs: dataCodeLigsSelected,
      };
    });
    changeStateFlow(3);
  };
  return (
    <ContainerConfig initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <MainConfigForm>
        <ContainerGlobalVars>
          {loadingDispositions && <BackDropCircularLoading />}
          <DescriptionInformation
            textDescription={t(
              'flowBuilder.newFlow.stages.codeLigs.description'
            )}
          />
          {codeLigs.length > 0 && (
            <DragTransferList
              codeList={codeLigs}
              changeDataCodeList={setDataCodeLigsSelected}
            />
          )}
        </ContainerGlobalVars>
      </MainConfigForm>
      <FooterConfigForm>
        <ButtonStyled
          type='button'
          size={'small'}
          variantstyle='secondary'
          onClick={handleBackStage}
        >
          {t('flowBuilder.newFlow.stages.btn.btn-back')}
        </ButtonStyled>
        <ButtonStyled size={'small'} onClick={handleNextStage}>
          {t('flowBuilder.newFlow.stages.btn.btn-next')}
        </ButtonStyled>
      </FooterConfigForm>
    </ContainerConfig>
  );
};
