import { useAtom } from 'jotai';
import { Stack } from '@mui/system';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { Box, Divider } from '@mui/material';
import { TableSchedules } from './TableSchedules';
import { FaArrowLeft, FaCircle } from 'react-icons/fa';

import {
  listSchedulesQueue,
  dataQueueSelected,
} from '@/store/ScheduleStore/SchedulerQueue';
import {
  ContainerSelectedSchedule,
  PanelScheduleSelected,
  TableScheduleQueue,
} from './styled';
import { ButtonStyled } from '@/components/FormComponents/ButtonComponent';
import { MdAdd } from 'react-icons/md';
import { useSchedulesQueue } from '@/hooks/dialControl/Schedules/useSchedulesQueue';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';
import { NoScheduling } from './NoScheduling';
import { usePermissionsSchedulerQueue } from '../../hooks/usePermissionsSchedulerQueue';

export const SelectedScheduleQueue = ({ controlStage }) => {
  const { t } = useTranslation();
  const { permissionsCreate } = usePermissionsSchedulerQueue();
  const {
    loadingSchedulesQueue,
    mutateDeleteScheduleQueue,
    loadingDeleteScheduleQueue,
  } = useSchedulesQueue();

  const [dataListSchedules] = useAtom(listSchedulesQueue);
  const [queueSelected, setQueueSelected] = useAtom(dataQueueSelected);

  const renderTxt = (word) => {
    return t(`dialControl.schedulesQueue.listSchedules.${word}`);
  };

  const handleBackStage = () => {
    controlStage.previousStage();
    setQueueSelected({});
  };

  const renderStatus = (status) => {
    switch (status) {
      case 'DISCANDO':
        return {
          color: 'success',
          text: renderTxt('statusWord.dialing'),
        };
      case 'ABERTO':
        return {
          color: 'success',
          text: renderTxt('statusWord.dialing'),
        };
      case 'LIVRE':
        return {
          color: 'secondary',
          text: renderTxt('statusWord.free'),
        };
      case 'FECHADO':
        return {
          color: 'secondary',
          text: renderTxt('statusWord.closed'),
        };
      case 'MANUTENCAO':
        return {
          color: 'primary',
          text: `${renderTxt('statusWord.maintenance')}`,
        };
      default:
        return {
          color: 'primary',
          text: '-',
        };
    }
  };

  const handleNewScheduleQueue = () => {
    return controlStage.nextStage();
  };

  return (
    <ContainerSelectedSchedule
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      {loadingSchedulesQueue && <BackDropCircularLoading />}

      <PanelScheduleSelected status={renderStatus(queueSelected?.estado).color}>
        <Stack direction='column' spacing={2} padding={1}>
          <Stack direction='row' spacing={2}>
            <motion.div
              whileTap={{ scale: 0.8 }}
              className='icon_handle_back'
              onClick={handleBackStage}
            >
              <FaArrowLeft size='16' />
            </motion.div>
            <div className='itemPanel'>
              <span>
                <strong>
                  {`${queueSelected.fila ?? ''} - ${
                    queueSelected.campanha ?? ''
                  }`}
                </strong>
              </span>
            </div>
          </Stack>
          <Divider />
          <Stack
            direction='row'
            spacing={2}
            alignItems={'center'}
            justifyContent={'space-between'}
            width={'100%'}
          >
            <Box
              className='status_queue'
              display={'flex'}
              gap={1}
              alignItems={'center'}
            >
              <FaCircle size='8' />
              <span>{renderTxt('statusQueue')}</span>
              <span>
                <strong>{renderStatus(queueSelected.estado).text}</strong>
              </span>
            </Box>

            <Box
              className='status_queue'
              display={'flex'}
              gap={0.5}
              alignItems={'center'}
            >
              <span>{renderTxt('typeQueue')}:</span>
              <span>
                <strong>{queueSelected.disca_tipo}</strong>
              </span>
            </Box>

            <Box
              className='status_queue'
              display={'flex'}
              gap={0.5}
              alignItems={'center'}
            >
              <span>{renderTxt('totalQueue')}:</span>
              <span>
                <strong>{queueSelected.max_linhas ?? 0}</strong>
              </span>
            </Box>
          </Stack>
        </Stack>

        <div className='btn-add-content'>
          <ButtonStyled
            onClick={handleNewScheduleQueue}
            disabled={!permissionsCreate}
          >
            <MdAdd />
          </ButtonStyled>
        </div>
      </PanelScheduleSelected>
      <TableScheduleQueue>
        {dataListSchedules.length > 0 ? (
          <TableSchedules
            dataSchedule={dataListSchedules}
            controlStage={controlStage}
            mutateDeleteScheduleQueue={mutateDeleteScheduleQueue}
            loadingDeleteScheduleQueue={loadingDeleteScheduleQueue}
          />
        ) : (
          <NoScheduling controlStage={controlStage} />
        )}
      </TableScheduleQueue>
    </ContainerSelectedSchedule>
  );
};
