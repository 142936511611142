import styled from 'styled-components';
import { motion } from 'framer-motion';

export const CardLatestList = styled(motion.div)`
  width: 100%;
  height: 76px;
  background: ${(props) => props.theme.colors.containers.colorCard};
  box-shadow: 2px 2px 2px 0px rgba(56, 56, 56, 0.35);
  border-radius: 4px;
  user-select: none;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0.8rem;
  gap: 6px;
  transition: all 0.1s ease-in-out;
  border: 1px solid transparent;

  &:hover {
    box-shadow: 1px 1px 1px 1px rgba(56, 56, 56, 0.1);
    border: 1px solid ${(props) => props.theme.colors.typography.baseText}AA;
  }

  h5 {
    color: ${(props) => props.theme.colors.typography.baseTitle};
    text-transform: uppercase;
    font-size: 0.94rem;
    width: 100%;
  }

  svg {
    font-size: 0.8rem;
    color: green;
  }

  span {
    color: ${(props) => props.theme.colors.typography.baseText}AA;
    text-transform: uppercase;
    font-size: 0.7rem;

    @media (max-width: 380px) {
      display: none;
    }
  }

  .content_render_data {
    @media (max-width: 380px) {
      display: none;
    }
  }

  .description-card {
    display: flex;
    flex-direction: column;
    justify-content: center;

    p {
      color: ${(props) => props.theme.colors.typography.baseText}AA;
      font-size: 0.75rem;
      word-wrap: break-all;
    }

    .not_publish {
      background-color: ${(props) => props.theme.colors.secondary};
    }

    span {
      background-color: #05c205;
      border-radius: 4px;
      padding: 3px 6px;
      color: white;
      font-size: 0.7rem;
    }
  }
`;
