import { useAtom } from 'jotai';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { Alert, InputAdornment, TextField } from '@mui/material';
import { AlternateEmail } from '@mui/icons-material';
import { motion } from 'framer-motion';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { showForgotPass } from '@store/AuthenticatorStore';
import { forgotPassword } from '@services/sessions/forgotPassword';
import { ButtonStyled } from '@components/FormComponents/ButtonComponent';
import { CircularLoading } from '@components/layout/LoadingComponent';

import { ContainerForgotPassForm } from './styled';
import { useEffect, useState } from 'react';

export const FormForgatPass = () => {
  const { t } = useTranslation();
  const [, setShow] = useAtom(showForgotPass);

  const [hasSendedEmail, setHasSendedEmail] = useState(false);
  const [errorSendedEmail, setErrorSendedEmail] = useState(null);

  const forgotSchema = yup.object().shape({
    emailForgotPass: yup
      .string()
      .email('Email must be a valid email')
      .required(t('login.messages.errorFieldEmail')),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(forgotSchema),
  });

  const checkEmail = watch('emailForgotPass', '').length === 0;

  useEffect(() => {
    return () => {
      setErrorSendedEmail(null);
      setHasSendedEmail(false);
      reset();
    };
  }, []);

  const { mutate, isLoading } = useMutation({
    mutationFn: (email) => {
      return forgotPassword(email);
    },
    retry: false,
    onSuccess: () => {
      reset();
      setHasSendedEmail(true);
    },
    onError: (error) => {
      reset();
      const errorMessage = error.message || JSON.stringify(error);
      errorMessage === 'User does not exists!'
        ? setErrorSendedEmail(t('forgotPass.messages.errorUserDoesNotExists'))
        : setErrorSendedEmail(t('forgotPass.messages.errorMessageForgotPass'));
    },
  });

  const handleSubmitForgoPass = (data) => {
    setErrorSendedEmail(null);
    setHasSendedEmail(false);
    mutate(data.emailForgotPass);
    reset();
  };

  const handleBack = () => {
    setShow(false);
    setErrorSendedEmail(null);
    setHasSendedEmail(false);
    reset();
  };

  return (
    <ContainerForgotPassForm onSubmit={handleSubmit(handleSubmitForgoPass)}>
      <div className='title_forgot'>
        <h2>{t('forgotPass.title-recoveryPass')}</h2>
      </div>
      <div className='main_forgot'>
        <p>{t('forgotPass.recovered-sentence')}</p>
        {isLoading ? (
          <CircularLoading />
        ) : (
          <Controller
            control={control}
            name='emailForgotPass'
            render={({ field }) => (
              <TextField
                {...field}
                type='text'
                fullWidth
                label={'E-mail'}
                placeholder={'seu email'}
                value={field.emailForgotPass}
                error={Boolean(errors.emailForgotPass)}
                helperText={
                  Boolean(errors.emailForgotPass) &&
                  errors.emailForgotPass.message
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <AlternateEmail />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        )}
      </div>
      {hasSendedEmail && (
        <motion.div
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
        >
          <Alert severity='success'>
            {t('forgotPass.messages.successSendForgotPass')}
          </Alert>
        </motion.div>
      )}
      {errorSendedEmail && (
        <motion.div
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
        >
          <Alert severity='error'>{errorSendedEmail}</Alert>
        </motion.div>
      )}
      <div className='footer_forgot'>
        <ButtonStyled
          onClick={handleBack}
          variantstyle='secondary'
          disabled={isLoading}
        >
          {t('forgotPass.btn-cancel')}
        </ButtonStyled>
        <ButtonStyled
          type='submit'
          disabled={isLoading || Boolean(errors.emailForgotPass) || checkEmail}
        >
          {t('forgotPass.btn-send')}
        </ButtonStyled>
      </div>
    </ContainerForgotPassForm>
  );
};
