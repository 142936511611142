import { SelectComponent } from '@/components/FormComponents/SelectInput';
import { ContainerHeaderMonitor } from './styled';
import { MenuItem } from '@mui/material';
import { ButtonStyled } from '@/components/FormComponents/ButtonComponent';
import { HiSortDescending, HiSortAscending } from 'react-icons/hi';
import { useLineMonitoring } from '@/hooks/monitor/useLineMonitoring';
import { useTranslation } from 'react-i18next';

export const HeaderMonitoring = ({ controlStates }) => {
  const { t } = useTranslation();
  const { dataLinesMonitoring } = useLineMonitoring();

  const dataOptionQueue = dataLinesMonitoring?.map((item) => item.fila) ?? [];

  return (
    <ContainerHeaderMonitor>
      <SelectComponent
        labelSelect={t('summaryMonitor.label-queues')}
        value={controlStates.filterByQueue}
        onChange={(event) => controlStates.setFilterByQueue(event.target.value)}
      >
        <MenuItem value={'0'}>{t('summaryMonitor.option-allQueues')}</MenuItem>
        {dataOptionQueue.length > 0 &&
          dataOptionQueue.map((item, i) => {
            return (
              <MenuItem key={i} value={item}>
                {item}
              </MenuItem>
            );
          })}
      </SelectComponent>
      <div className='second_part_control'>
        <SelectComponent
          labelSelect={t('summaryMonitor.label-status')}
          value={controlStates.filterByStatus}
          onChange={(event) =>
            controlStates.setFilterByStatus(event.target.value)
          }
        >
          <MenuItem value={'0'}>
            {t('summaryMonitor.option-allStatus')}
          </MenuItem>
          <MenuItem value={'1'}>{t('summaryMonitor.option-discing')}</MenuItem>
          <MenuItem value={'2'}>{t('summaryMonitor.option-free')}</MenuItem>
          <MenuItem value={'3'}>{t('summaryMonitor.option-paused')}</MenuItem>
        </SelectComponent>

        <ButtonStyled
          onClick={() => controlStates.handleOrderQueue()}
          title={t('summaryMonitor.aria-btnOrder')}
        >
          {controlStates.sortByQueue ? (
            <HiSortAscending />
          ) : (
            <HiSortDescending />
          )}
        </ButtonStyled>
      </div>
    </ContainerHeaderMonitor>
  );
};
