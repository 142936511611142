import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { MenuItem } from '@mui/material';
import { SelectComponent } from '@components/FormComponents/SelectInput';

import { RiLayoutGridFill } from 'react-icons/ri';
import { FaListUl } from 'react-icons/fa';

import { TabMonitorContainer } from './styled.js';
import { ButtonStyled } from '@/components/FormComponents/ButtonComponent/index.jsx';

export const TabMonitor = ({
  ordination,
  dataList,
  queueFilter,
  statusFilter,
}) => {
  const { t } = useTranslation();

  const renderStatusQueue = [
    {
      type: 'free',
      name: t('monitor.option-free'),
      value: '1',
    },
    {
      type: 'discing',
      name: t('monitor.option-discing'),
      value: '2',
    },
    {
      type: 'inCall',
      name: t('monitor.option-inCall'),
      value: '3',
    },
    {
      type: 'inPause',
      name: t('monitor.option-inPause'),
      value: '4',
    },
  ];

  const [listQueueSelect, setListQueueSelect] = useState([]);

  const generateListQueueSelect = (dataList) => {
    const listQueue = dataList.map((infoQueue) => {
      return infoQueue.fila;
    });

    const clearListQueue = [...new Set(listQueue)];
    setListQueueSelect(clearListQueue.sort());
  };

  useEffect(() => {
    if (dataList) {
      generateListQueueSelect(dataList);
    }
  }, [dataList]);

  const handleChangeOrientation = (statusList) => {
    ordination.setOrdinationType(statusList);
  };

  return (
    <TabMonitorContainer>
      <div className='select-list'>
        <SelectComponent
          labelSelect={t('monitor.label-filterQueueMonitor')}
          value={queueFilter.filterQueue}
          onChange={(event) => queueFilter.setFilterQueue(event.target.value)}
        >
          <MenuItem value={'0'} dense>
            {t('monitor.label-filterQueueMonitor')}
          </MenuItem>
          {listQueueSelect &&
            listQueueSelect.map((infoQueue) => {
              return (
                <MenuItem value={infoQueue} key={infoQueue} dense>
                  {infoQueue}
                </MenuItem>
              );
            })}
        </SelectComponent>

        <div className='select-status'>
          <SelectComponent
            labelSelect={t('monitor.label-filterStatus')}
            value={statusFilter.filterStatus}
            onChange={(event) =>
              statusFilter.setFilterStatus(event.target.value)
            }
          >
            <MenuItem value={'0'} dense>
              {t('monitor.label-filterStatus')}
            </MenuItem>
            {renderStatusQueue.map((item) => (
              <MenuItem value={item.value} key={item.value} dense>
                {item.name}
              </MenuItem>
            ))}
          </SelectComponent>
        </div>
      </div>

      <div className='disposition-list'>
        <ButtonStyled
          onClick={() => handleChangeOrientation('layoutBox')}
          className={
            ordination.ordinationType === 'layoutBox' ? 'buttonActive' : ''
          }
        >
          <RiLayoutGridFill />
        </ButtonStyled>

        <ButtonStyled
          onClick={() => handleChangeOrientation('layoutList')}
          className={
            ordination.ordinationType === 'layoutList' ? 'buttonActive' : ''
          }
        >
          <FaListUl />
        </ButtonStyled>
      </div>
    </TabMonitorContainer>
  );
};
