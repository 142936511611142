import { motion } from 'framer-motion';
import styled from 'styled-components';

export const FormEditGroup = styled(motion.form)`
  width: 100%;
  height: calc(100vh - 60px);
  padding: 2rem 2rem;

  display: grid;
  grid-template-rows: 1fr 40px;
`;

export const HeaderEditGroup = styled.header``;

export const MainEditGroup = styled.main`
  width: 100%;
  height: 99%;
  padding: 1rem 0;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 2rem;
`;

export const FooterEditGroup = styled.footer`
  width: 100%;
  height: 100%;
  min-height: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 1.2rem;

  @media (max-height: 768px) {
    padding-bottom: 10px;
  }
`;

export const TableEditConfig = styled.table`
  width: 100%;
  height: 74%;
  border-collapse: collapse;
  user-select: text;

  thead {
    tr {
      th {
        background-color: ${(props) => props.theme.colors.tables.tableHead};
        padding: 0.8rem;
        color: ${(props) => props.theme.colors.typography.textHeaderTable};
        font-size: 0.8rem;
        /* line-height: 0.8rem; */
        text-align: left;

        &:first-child {
          border-top-left-radius: 3px;
          padding-left: 1rem;
        }
        &:last-child {
          border-top-right-radius: 3px;
          padding-right: 1rem;
        }
      }
    }
  }

  tbody {
    tr {
      td {
        background-color: ${(props) => props.theme.colors.tables.tableRow};

        border-top: 4px solid
          ${(props) => props.theme.colors.containers.baseBackground};
        padding: 0rem 1rem;
        font-size: 0.8rem;
        line-height: 1rem;
        color: ${(props) => props.theme.colors.typography.baseText};

        input {
        }

        &:first-child {
          padding-left: 1rem;
          border-top-left-radius: 3px;
          border-bottom-left-radius: 3px;
          font-weight: bold;
        }
        &:last-child {
          padding-right: 1rem;
          border-top-right-radius: 3px;
          border-bottom-right-radius: 3px;
        }
      }
    }
  }
`;

export const ContentCallAttempts = styled(motion.div)`
  width: 100%;
  padding: 1rem 0;
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  gap: 8px;
`;
