import styled from 'styled-components';

export const ContainerEditSchedule = styled.div`
  width: 100%;
  height: 100%;
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.6rem;

  .titleNewSchedule {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 0.4rem;

    h2 {
      font-weight: 700;
      font-size: 0.75rem;
      line-height: 14px;
      letter-spacing: 0.1em;
      text-transform: uppercase;

      color: ${(props) => props.theme.colors.typography.baseText};
    }

    svg {
      font-size: 1rem;
      color: ${(props) => props.theme.colors.primary};
    }
  }
`;

export const FormEditSchedule = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  .mainForm {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 0.8rem;

    .timers {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1rem;
      margin-bottom: 1rem;
    }
  }
  .footerNewSchedule {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
`;
