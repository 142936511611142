import { useSummaryMonitor } from '@/hooks/monitor/useSummaryMonitor';
import { ContainerSummary } from './styled';
import { TableSummary } from './TableSummary';
import { SelectComponent } from '@/components/FormComponents/SelectInput';
import { MenuItem } from '@mui/material';
import { useEffect, useState } from 'react';
import { Stack } from '@mui/system';

export const SummaryMonitor = () => {
  const { dataSummaryMonitor } = useSummaryMonitor();

  const [filterByQueue, setFilterByQueue] = useState('0');

  const [filteredQueue, setFilteredQueue] = useState([]);
  const [optionsQueue, setOptionsQueue] = useState([]);

  const renderQueuesOptions = () => {
    const renderOptionsQueues =
      dataSummaryMonitor && dataSummaryMonitor.map((item) => item.queue);

    if (renderOptionsQueues) {
      const cleanList = [...new Set(renderOptionsQueues)];
      return setOptionsQueue(cleanList);
    } else {
      return [];
    }
  };

  const filterDataTable = (dataFilter) => {
    if (dataFilter) {
      const filterTableByQueue = dataFilter.filter((data) =>
        data.queue.toLowerCase().includes(filterByQueue.toLowerCase())
      );

      return setFilteredQueue(filterTableByQueue);
    } else {
      return [];
    }
  };

  useEffect(() => {
    filterDataTable(dataSummaryMonitor);
  }, [filterByQueue, dataSummaryMonitor]);

  useEffect(() => {
    renderQueuesOptions();
  }, [dataSummaryMonitor]);

  return (
    <ContainerSummary initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <Stack direction={'row'} spacing={2}>
        <SelectComponent
          labelSelect='Filter Queue'
          value={filterByQueue}
          onChange={(event) => setFilterByQueue(event.target.value)}
        >
          <MenuItem value={'0'}>All Queues</MenuItem>
          {optionsQueue.map((item, i) => (
            <MenuItem value={item} key={i}>
              {item}
            </MenuItem>
          ))}
        </SelectComponent>
      </Stack>
      <TableSummary dataTable={filteredQueue} />
    </ContainerSummary>
  );
};
