import { TitleComponent } from '@/components/layout/TitleComponent';
import { ContainerListCallOperators } from './styled';
import { useState } from 'react';
import { InputComponent } from '@/components/FormComponents/InputComponent';
import { InputAdornment } from '@mui/material';
import { BiSearch } from 'react-icons/bi';
import { ButtonStyled } from '@/components/FormComponents/ButtonComponent';
import { Stack } from '@mui/system';
import { BsPhoneVibrate } from 'react-icons/bs';
import { TableCallOperator } from './TableCallOperator';
import { useCallOperators } from '@/hooks/CallOperators';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';
import { useAtom } from 'jotai';
import { dataCallOperators } from '@/store/CallOperator';
import { removeDuplicates } from '@/utils/removeDuplicateItemsArr';
import { useTranslation } from 'react-i18next';
import { useCallOperatorsPermissions } from '../../hooks/useCallOperatorsPermissions';
import { NoPermissionCard } from '@/components/layout/NoPermissionCard';

export const ListCallOperators = ({ changeStage }) => {
  const { t } = useTranslation();
  const { permissionsCreate, isSysAdmin } = useCallOperatorsPermissions();
  const { loadingDataOperators } = useCallOperators();
  const [listCallOperators] = useAtom(dataCallOperators);
  const [searchOperator, setSearchOperator] = useState('');

  const filterAllTableUsers = (dataFilter) => {
    if (dataFilter) {
      const filterTableCodOperator = dataFilter.filter((data) =>
        data.cod_operadora.toLowerCase().includes(searchOperator.toLowerCase())
      );

      const filterTableNameOperator = dataFilter.filter((data) =>
        data.nome_operadora.toLowerCase().includes(searchOperator.toLowerCase())
      );

      const newArrFiltered = [
        ...filterTableCodOperator,
        ...filterTableNameOperator,
      ];
      return removeDuplicates(newArrFiltered);
    }
  };

  const renderText = (spell) => {
    return t(`callOperators.list.${spell}`);
  };

  const filteredData = filterAllTableUsers(listCallOperators);

  const isLoading = loadingDataOperators;

  return (
    <ContainerListCallOperators
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      {isLoading && <BackDropCircularLoading />}
      <TitleComponent
        textTitle={renderText('title')}
        iconTitle='callOperator'
      />
      {!isSysAdmin ? (
        <NoPermissionCard message={t('callOperators.list.table.no-data')} />
      ) : (
        <>
          <InputComponent
            placeholder={renderText('label-filter')}
            value={searchOperator}
            onChange={(e) => setSearchOperator(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <BiSearch />
                </InputAdornment>
              ),
            }}
          />

          <div className='content_btn_new_tenant'>
            <ButtonStyled
              size={'small'}
              onClick={() => changeStage(1)}
              disabled={!permissionsCreate}
            >
              <Stack direction={'row'} alignItems={'center'} spacing={1}>
                <BsPhoneVibrate size={16} />
                <span>{renderText('btn-addCallOperator')}</span>
              </Stack>
            </ButtonStyled>
          </div>
          <TableCallOperator
            changeStage={changeStage}
            dataTable={filteredData}
          />
        </>
      )}
    </ContainerListCallOperators>
  );
};
