import { InputComponent } from '@/components/FormComponents/InputComponent';
import { DescriptionInformation } from '@/components/layout/DescriptionInformation';
import { TitleComponent } from '@/components/layout/TitleComponent';
import { FormCurrentLines } from './styled';
import { ButtonStyled } from '@/components/FormComponents/ButtonComponent';
import { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { dataSelectedLinesControl, remainingLines } from '@/store/LineControl';
import { useLineControl } from '@/hooks/dialControl/LineControl/useLineControl';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';
// import { errorToast, successToast } from '@/components/FormComponents/Toast';
import { useTranslation } from 'react-i18next';

export const CurrentLines = ({ controlMainStage }) => {
  const { t } = useTranslation();
  const { loadingUpdateCurrentLines, mutateUpdateCurrentLines } =
    useLineControl();
  const [totalLinesField, setTotalLinesField] = useState(0);
  const [remaining, setRemaining] = useAtom(remainingLines);

  const renderTxt = (word) => {
    return t(`dialControl.lineControl.selectedCampaign.currentLines.${word}`);
  };

  const [selected] = useAtom(dataSelectedLinesControl);

  //   useEffect(() => {
  //     selected && setTotalLinesField(selected.currentLines);
  //   }, []);

  useEffect(() => {
    setRemaining(selected?.maxLinhasTotal - totalLinesField);
  }, [totalLinesField]);

  const handleChangeLines = async (e) => {
    e.preventDefault();
    // mutateUpdateCurrentLines(totalLinesField, {
    //   onSuccess: () => {
    //     successToast(renderTxt('msg-success'));
    //     controlMainStage.toStage(0);
    //   },
    //   onError: () => {
    //     errorToast(renderTxt('msg-error'));
    //   },
    // });
  };

  const handleBackStage = () => {
    controlMainStage.toStage(0);
  };

  return (
    <FormCurrentLines
      onSubmit={handleChangeLines}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      {loadingUpdateCurrentLines && <BackDropCircularLoading />}
      <div className='main'>
        <TitleComponent textTitle={renderTxt('title')} />
        <DescriptionInformation textDescription={renderTxt('description')} />
        <InputComponent
          label={renderTxt('label-currentLines')}
          type='number'
          value={totalLinesField}
          onChange={(e) => setTotalLinesField(e.target.value)}
          inputProps={{ min: 0 }}
          error={remaining < 0}
          helperText={remaining < 0 ? 'Limite de linhas excedido' : ' '}
        />
      </div>
      <div className='footer'>
        <ButtonStyled
          variantstyle={'secondary'}
          size='small'
          onClick={handleBackStage}
        >
          {renderTxt('btn-cancel')}
        </ButtonStyled>
        <ButtonStyled type='submit' size='small' disabled={remaining < 0}>
          {renderTxt('btn-save')}
        </ButtonStyled>
      </div>
    </FormCurrentLines>
  );
};
