import { ButtonStyled } from '@/components/FormComponents/ButtonComponent';
import {
  ContainerConfig,
  ContainerGlobalVars,
  FooterConfigForm,
  MainConfigForm,
} from './styled';
import { DescriptionInformation } from '@/components/layout/DescriptionInformation';
import { useVariablesFlow } from '@/hooks/FlowBuilder/useVariablesFlow';
import { useAtom } from 'jotai';
import { templateSelected, variablesGlobal } from '@/store/FlowBuilder';
import { DragTransferList } from '../../DragTransferList';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';

export const ConfigVars = ({ changeStateFlow }) => {
  const { t } = useTranslation();
  const { loadingVariables } = useVariablesFlow(1);
  const [, setTemplate] = useAtom(templateSelected);
  const [globalVars] = useAtom(variablesGlobal);

  const [dataVarsSelected, setDataVarsSelected] = useState([]);

  const handleBackStage = () => {
    changeStateFlow(0);
  };

  const handleNextStage = () => {
    changeStateFlow(2);
  };

  const handleAddVars = () => {
    setTemplate((previousState) => {
      return {
        ...previousState,
        variablesGlobal: dataVarsSelected,
      };
    });

    handleNextStage();
  };

  return (
    <ContainerConfig initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <MainConfigForm>
        <ContainerGlobalVars>
          {loadingVariables && <BackDropCircularLoading />}
          <DescriptionInformation
            textDescription={t(
              'flowBuilder.newFlow.stages.globalVars.description'
            )}
          />
          {globalVars.length > 0 && (
            <DragTransferList
              codeList={globalVars}
              changeDataCodeList={setDataVarsSelected}
            />
          )}
        </ContainerGlobalVars>
      </MainConfigForm>
      <FooterConfigForm>
        <ButtonStyled
          type='button'
          size={'small'}
          variantstyle='secondary'
          onClick={handleBackStage}
        >
          {t('flowBuilder.newFlow.stages.btn.btn-back')}
        </ButtonStyled>
        <ButtonStyled size={'small'} onClick={handleAddVars}>
          {t('flowBuilder.newFlow.stages.btn.btn-next')}
        </ButtonStyled>
      </FooterConfigForm>
    </ContainerConfig>
  );
};
