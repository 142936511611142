import { useState } from 'react';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { MenuItem, Box, Stack } from '@mui/material';
import { TableCampaigns } from './TableCampaigns';
import { SelectComponent } from '@/components/FormComponents/SelectInput';
import { ButtonStyled } from '@/components/FormComponents/ButtonComponent';
import { useCampaigns } from '@/hooks/dialControl/Campaigns/useCampaigns';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';
import { dataListCampaigns } from '@/store/Campaigns';
import { ContainerListCampaigns } from './styled';
import { user } from '@/store/AuthenticatorStore';
import { useClients } from '@/hooks/Clients/useClients';
import { useDispositionsDialing } from '@/hooks/Dispositions/useDispositionsDialing';
import { usePermissionsCampaigns } from '../../hooks/usePermissionsCampaigns';

export const ListCampaigns = ({ controlStage }) => {
  const { t } = useTranslation();
  const { permissionsCreate, isSysAdmin } = usePermissionsCampaigns();
  const { currentStage, nextStage } = controlStage;
  const { loadingDataDisposition } = useDispositionsDialing();
  const { loadingDataCampaigns } = useCampaigns(currentStage);
  const { isLoadingDataClients } = useClients();
  const [filterCampaign, setFilterCampaign] = useState('0');
  const [dataCampaigns] = useAtom(dataListCampaigns);

  const [currentUser] = useAtom(user);

  const filterClientIdCampaigns = isSysAdmin
    ? dataCampaigns
    : dataCampaigns.filter((item) => item.id_cliente === currentUser.client_id);

  const handleCreateCampaign = () => {
    nextStage();
  };

  const isLoading =
    isLoadingDataClients || loadingDataCampaigns || loadingDataDisposition;

  return (
    <ContainerListCampaigns initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      {isLoading && <BackDropCircularLoading />}
      <Stack direction={'column'} spacing={2} justifyContent={'space-between'}>
        <Box>
          <SelectComponent
            labelSelect={t(
              'dialControl.campaigns.listCampaigns.labelFilterCampaign'
            )}
            value={filterCampaign}
            onChange={(event) => setFilterCampaign(event.target.value)}
          >
            <MenuItem value={'off'} disabled dense>
              {t('dialControl.campaigns.listCampaigns.optionsFilterCampaign')}
            </MenuItem>
            <MenuItem value={'0'} dense>
              {t('dialControl.campaigns.listCampaigns.optionsAllCampaigns')}
            </MenuItem>
            {filterClientIdCampaigns.length > 0 &&
              filterClientIdCampaigns.map((item, i) => (
                <MenuItem value={item.id} key={i} dense>
                  {item.id} - {item.campanha}
                </MenuItem>
              ))}
          </SelectComponent>
        </Box>
        <Stack direction={'row'} spacing={1} justifyContent={'flex-end'}>
          <ButtonStyled
            variantstyle='primary'
            onClick={handleCreateCampaign}
            size={'small'}
            disabled={!permissionsCreate}
          >
            {t('dialControl.campaigns.listCampaigns.btn-add-campaign')}
          </ButtonStyled>
        </Stack>
      </Stack>
      <TableCampaigns
        filterCampaign={filterCampaign}
        filterClientIdCampaigns={filterClientIdCampaigns}
        controlStage={controlStage}
      />
    </ContainerListCampaigns>
  );
};
