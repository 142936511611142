import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useUsersAgents } from '@hooks/UserAgents/useUsersAgents';

import {
  dataOptionsClients,
  dataSelectedUser,
  stateRenderUsers,
} from '@store/UsersAgentsStore/userAgentsStore';

import { Stack, MenuItem } from '@mui/material';
import { ContainerEditUserAgent, FormEditUserAgent } from './styled';

import { ButtonStyled } from '@components/FormComponents/ButtonComponent';
import { ControlInput } from '@components/FormComponents/InputComponent';
import { TitleComponent } from '@components/layout/TitleComponent';
import { BackDropCircularLoading } from '@components/layout/LoadingComponent';
import { errorToast, successToast } from '@components/FormComponents/Toast';
import { ResetPasswordStep } from './ResetPasswordStep';
import { DialogComponent } from '@/components/DialogComponent';
import { useMutation } from 'react-query';
import { forgotPassword } from '@/services/sessions/forgotPassword';
import { ControlDataPicker } from '@/components/FormComponents/DataPickerComponent';
import { ControlSelect } from '@/components/FormComponents/SelectInput';
import { ControlRadio } from '@/components/FormComponents/RadioComponent';
import { dataByClientRoutingProfile } from '@/store/UsersAgentsStore/routingProfiles';
import { useRoutingProfile } from '@/hooks/UserAgents/useRoutingProfile';
import { user } from '@/store/AuthenticatorStore';
import { usePermissionsUsers } from '../../hooks/usePermissionsUsers';

export const EditUser = () => {
  const { t } = useTranslation();
  const { isAdmin } = usePermissionsUsers();
  const [optionsClients] = useAtom(dataOptionsClients);
  const [currentUser] = useAtom(user);
  const [dataEdit, setDataSelected] = useAtom(dataSelectedUser);
  const [, setStateRenderUsersAgents] = useAtom(stateRenderUsers);
  const [defaultRoutingProfile] = useAtom(dataByClientRoutingProfile);
  const [fetchedRoutingProfile, setFetchedRoutingProfile] = useState(false);

  const hasPermissionUpdateUser = dataEdit.id === currentUser.user_id;

  const { mutateRoutingByClient, loadingRoutingByClient } = useRoutingProfile(
    false,
    'edit-client'
  );

  const {
    isLoadingDataSecurityProfilesList,
    mutateUpdateUserAgent,
    loadingUpdateUserAgent,
    mutateAddUserRoutingProfile,
    loadingAddUserProfileUser,
  } = useUsersAgents();

  useEffect(() => {
    return () => setStateRenderUsersAgents(0);
  }, []);

  const [stateResetPassword, setStateResetPassword] = useState(false);

  const editUserSchema = yup.object().shape({
    nameUserAgent: yup
      .string()
      .required(t('usersAgentsList.editUser.messages.errorName')),
    emailUserAgent: yup.string().required(),
    clientId: yup
      .string()
      .required(t('usersAgentsList.addUser.messages.errorClientId')),
    statusAccount: yup.string().required(),
    routingProfile: yup
      .string()
      .required()
      .notOneOf(['0'], t('usersAgentsList.editUser.messages.errorRouting')),
    isSysAdmin: yup.string().required(),
    isAdmin: yup.string().required(),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(editUserSchema),
  });

  const watchClientId = watch('clientId', '');

  useEffect(() => {
    if (dataEdit && dataEdit.client_id !== '' && !fetchedRoutingProfile) {
      return mutateRoutingByClient(dataEdit.client_id, {
        onSuccess: (data) => {
          const filterSelected = data
            .filter((item) => item.id === dataEdit.routingProfile)[0]
            .id.toString();
          setValue('routingProfile', filterSelected);
          setFetchedRoutingProfile(true);
        },
      });
    }
  }, []);

  useEffect(() => {
    if (watchClientId && watchClientId !== '' && fetchedRoutingProfile) {
      setValue('routingProfile', '0');
      return mutateRoutingByClient(watchClientId);
    }
  }, [watchClientId]);

  const handleBackEditComponent = () => {
    setStateRenderUsersAgents(0);
    setDataSelected(null);
  };

  const handleUpdateUserAgent = (data) => {
    const dataUpdate = {
      userId: dataEdit.id,
      name: data.nameUserAgent,
      email: data.emailUserAgent,
      client_id: data.clientId,
      routing_id: data.routingProfile,
      user_type: 1,
      isActive:
        data.statusAccount === t('usersAgentsList.editUser.option-active')
          ? true
          : false,
      isAdmin:
        data.isAdmin === t('usersAgentsList.addUser.option-adm') ? true : false,
      isSysAdmin:
        data.isSysAdmin === t('usersAgentsList.addUser.option-adm')
          ? true
          : false,
    };

    const dataUpdateRoutingId = {
      users_id: dataEdit.id,
      routing_id: data.routingProfile,
    };

    mutateUpdateUserAgent(dataUpdate, {
      onSuccess: () => {
        if (dataEdit.routingProfile !== data.routingProfile) {
          return mutateAddUserRoutingProfile(dataUpdateRoutingId, {
            onSuccess: () => {
              handleBackEditComponent();
              successToast(
                t('usersAgentsList.editUser.messages.successMessage')
              );
            },
          });
        }
        handleBackEditComponent();
        successToast(t('usersAgentsList.editUser.messages.successMessage'));
      },
    });
  };

  const { mutate, isLoading: loadingChangePass } = useMutation({
    mutationFn: (email) => {
      return forgotPassword(email);
    },
    retry: false,
    onSuccess: () => {
      setStateResetPassword(true);
    },
    onError: () => {
      errorToast(t('usersAgentsList.editUser.messages.errorChangePass'));
    },
  });

  const handleResetUserPassword = () => {
    const dataEmail = dataEdit.email ? dataEdit.email.toLowerCase() : '';
    mutate(dataEmail);
  };

  const isLoading =
    isLoadingDataSecurityProfilesList ||
    loadingUpdateUserAgent ||
    loadingChangePass ||
    loadingAddUserProfileUser ||
    loadingRoutingByClient;

  return (
    <ContainerEditUserAgent initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      {isLoading && <BackDropCircularLoading />}

      <FormEditUserAgent
        onSubmit={handleSubmit(handleUpdateUserAgent)}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
      >
        <TitleComponent
          textTitle={t('usersAgentsList.editUser.title-edit')}
          iconTitle='userAgents'
        />

        <div className='mainFormUsers'>
          <div className='user_account__details'>
            <TitleComponent
              textTitle={t('usersAgentsList.editUser.title-user')}
            />
            <Stack spacing={2}>
              <ControlInput
                control={control}
                defaultValue={dataEdit.name}
                nameControl={'nameUserAgent'}
                label={t('usersAgentsList.editUser.label-name')}
                placeholder={t('usersAgentsList.editUser.label-name')}
                error={Boolean(errors.nameUserAgent)}
                helperText={
                  errors.nameUserAgent ? errors.nameUserAgent.message : ' '
                }
              />

              <ControlInput
                control={control}
                defaultValue={dataEdit.email}
                nameControl={'emailUserAgent'}
                label={t('usersAgentsList.editUser.label-email')}
                InputProps={{
                  readOnly: true,
                }}
                error={Boolean(errors.emailUserAgent)}
                helperText={
                  errors.emailUserAgent ? errors.emailUserAgent.message : ' '
                }
              />
            </Stack>
          </div>

          <div className='user_account__details'>
            <TitleComponent
              textTitle={t('usersAgentsList.addUser.title-detail')}
            />
            <Stack spacing={1}>
              <ControlSelect
                control={control}
                nameControl={'clientId'}
                defaultValue={dataEdit.client_id}
                label={t('usersAgentsList.addUser.label-client')}
                error={Boolean(errors.clientId)}
                helperText={errors.clientId ? errors.clientId.message : '   '}
              >
                <MenuItem value={'0'} disabled>
                  {t('usersAgentsList.addUser.option-client')}
                </MenuItem>
                {optionsClients.length > 0 &&
                  optionsClients.map((item) => (
                    <MenuItem value={item.id} key={item.id} dense>
                      {item.commercial_name}
                    </MenuItem>
                  ))}
              </ControlSelect>
            </Stack>
            <Stack direction={'row'} spacing={2}>
              <ControlDataPicker
                label={t('usersAgentsList.editUser.label-createdAt')}
                control={control}
                nameControl={'createdAt'}
                defaultValue={
                  dataEdit.created_at
                    ? new Date(dataEdit.created_at)
                    : new Date()
                }
                disableOpenPicker
                readOnly
                disabled
              />
              {dataEdit.last_access && (
                <ControlDataPicker
                  control={control}
                  label={t('usersAgentsList.editUser.label-lastAccess')}
                  nameControl={'lastAccess'}
                  defaultValue={
                    dataEdit.last_access ? new Date(dataEdit.last_access) : null
                  }
                  disableOpenPicker
                  readOnly
                  disabled
                />
              )}
            </Stack>
          </div>

          <div className='user_account__details'>
            <TitleComponent
              textTitle={t('usersAgentsList.editUser.title-access')}
            />
            <Stack spacing={4}>
              <Stack direction={'column'} spacing={4}>
                <Stack spacing={3}>
                  <ControlSelect
                    control={control}
                    nameControl={'routingProfile'}
                    // defaultValue={dataEdit.routingProfile}
                    label={t('usersAgentsList.addUser.label-routing')}
                    error={Boolean(errors.routingProfile)}
                    helperText={
                      errors.routingProfile
                        ? errors.routingProfile.message
                        : '   '
                    }
                  >
                    <MenuItem value={'0'} disabled dense>
                      {t('usersAgentsList.addUser.option-routing')}
                    </MenuItem>
                    {defaultRoutingProfile.length > 0 &&
                      defaultRoutingProfile.map((item) => {
                        return !item.client_id ? (
                          <MenuItem value={item.id} key={item.id} dense>
                            {item.name}
                          </MenuItem>
                        ) : (
                          <MenuItem
                            value={item.id}
                            key={item.id}
                            dense
                            // disabled
                          >
                            {item.name}
                          </MenuItem>
                        );
                      })}
                  </ControlSelect>
                </Stack>

                <div className='container_radios__config'>
                  <Stack
                    direction={'row'}
                    alignItems={'center'}
                    spacing={3}
                    border={
                      errors.statusAccount
                        ? '1px solid red'
                        : '1px solid transparent'
                    }
                    padding={'0 8px'}
                    borderRadius={'3px'}
                    width={'100%'}
                  >
                    <ControlRadio
                      control={control}
                      labelRadio={t('usersAgentsList.editUser.label-status')}
                      defaultValue={
                        dataEdit.isActive
                          ? t('usersAgentsList.editUser.option-active')
                          : t('usersAgentsList.editUser.option-disabled')
                      }
                      nameControl='statusAccount'
                      labelValue1={t('usersAgentsList.editUser.option-active')}
                      labelValue2={t(
                        'usersAgentsList.editUser.option-disabled'
                      )}
                    />
                  </Stack>
                  <Stack
                    direction={'row'}
                    alignItems={'center'}
                    spacing={3}
                    border={
                      errors.isSysAdmin
                        ? '1px solid red'
                        : '1px solid transparent'
                    }
                    padding={'0 8px'}
                    borderRadius={'3px'}
                    width={'100%'}
                  >
                    <ControlRadio
                      control={control}
                      labelRadio={t('usersAgentsList.addUser.label-sysAdm')}
                      defaultValue={
                        dataEdit.isSysAdmin
                          ? t('usersAgentsList.addUser.option-adm')
                          : t('usersAgentsList.addUser.option-user')
                      }
                      nameControl='isSysAdmin'
                      labelValue1={t('usersAgentsList.addUser.option-adm')}
                      labelValue2={t('usersAgentsList.addUser.option-user')}
                    />
                  </Stack>
                  <Stack
                    direction={'row'}
                    alignItems={'center'}
                    spacing={3}
                    border={
                      errors.isAdmin ? '1px solid red' : '1px solid transparent'
                    }
                    padding={'0 8px'}
                    borderRadius={'3px'}
                    width={'100%'}
                  >
                    <ControlRadio
                      control={control}
                      labelRadio={t('usersAgentsList.addUser.label-adm')}
                      defaultValue={
                        dataEdit.isAdmin
                          ? t('usersAgentsList.addUser.option-adm')
                          : t('usersAgentsList.addUser.option-user')
                      }
                      nameControl='isAdmin'
                      labelValue1={t('usersAgentsList.addUser.option-adm')}
                      labelValue2={t('usersAgentsList.addUser.option-user')}
                    />
                  </Stack>
                </div>
              </Stack>
            </Stack>
          </div>
        </div>

        <div className='footerEditUser'>
          <ButtonStyled
            onClick={handleResetUserPassword}
            size={'small'}
            disabled={!isAdmin}
          >
            {t('usersAgentsList.editUser.btnPass')}
          </ButtonStyled>
          <Stack direction={`row`} gap={1}>
            <ButtonStyled
              variantstyle={'secondary'}
              onClick={handleBackEditComponent}
              size={'small'}
            >
              {t('usersAgentsList.editUser.btn-cancel')}
            </ButtonStyled>
            <ButtonStyled
              type='submit'
              size={'small'}
              disabled={hasPermissionUpdateUser || !isAdmin}
            >
              {t('usersAgentsList.editUser.btn-confirm')}
            </ButtonStyled>
          </Stack>
        </div>
      </FormEditUserAgent>
      <DialogComponent
        open={stateResetPassword}
        onClose={() => setStateResetPassword(false)}
        fullWidth={true}
      >
        <ResetPasswordStep
          closeDialog={() => setStateResetPassword(false)}
          emailUser={dataEdit.email}
        />
      </DialogComponent>
    </ContainerEditUserAgent>
  );
};
