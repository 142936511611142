import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';
import { Box, MenuItem } from '@mui/material';

import {
  dataInicialIdComponent,
  resetCurrentPageId,
} from '@store/NavigationDashboard';

import { InputComponent } from '@components/FormComponents/InputComponent';
import { ButtonStyled } from '@components/FormComponents/ButtonComponent';
import { SelectComponent } from '@components/FormComponents/SelectInput';
import { DialogComponent } from '@components/DialogComponent';

import { ResetPasswordUser } from './ResetPasswordUser';

import { BsGear } from 'react-icons/bs';
import { AiOutlineUser } from 'react-icons/ai';

import { ContainerProfile, DataUser, FormProfile, TitleUsers } from './styled';
import { successToast } from '@/components/FormComponents/Toast';
import { user } from '@/store/AuthenticatorStore';

export const Profile = () => {
  const [currentUser] = useAtom(user);

  const {
    t,
    i18n: { logger, resolvedLanguage, changeLanguage },
  } = useTranslation();

  const [, resetCurrentId] = useAtom(resetCurrentPageId);

  const [dialogResetPassword, setDialogResetPassword] = useState(false);
  const [nameUser, setNameUser] = useState(currentUser.name);
  const [emailUser, setEmailUser] = useState(currentUser.email);
  const [languageSelect, setLanguageSelect] = useState(resolvedLanguage);
  const [inicialIdComponent] = useAtom(dataInicialIdComponent);
  const listLangs = Object.keys(logger.options.resources);

  const handleResetPassword = () => {
    setDialogResetPassword(true);
  };

  const handleProfile = (e) => {
    e.preventDefault();
    localStorage.setItem('lang', languageSelect);
    changeLanguage(languageSelect);
    successToast(t('profileSettings.messages.successUpdate'));
  };

  const handleBack = () => {
    resetCurrentId(inicialIdComponent);
  };

  return (
    <>
      <ContainerProfile initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
        <DataUser>
          <TitleUsers>
            <AiOutlineUser />
            <h2>{t('profileSettings.title-dataUser')}</h2>
          </TitleUsers>
          <InputComponent
            label={t('profileSettings.label-nameUser')}
            value={nameUser}
            onChange={(event) => setNameUser(event.target.value)}
            inputProps={{
              readOnly: true,
            }}
          />
          <InputComponent
            label={'E-mail'}
            value={emailUser}
            onChange={(event) => setEmailUser(event.target.value)}
            inputProps={{
              readOnly: true,
            }}
          />
          <Box height={'100%'}>
            <ButtonStyled
              type='button'
              onClick={handleResetPassword}
              size={'small'}
              //   disabled={!securityProfileRender.canEdit}
            >
              <span>{t('profileSettings.btn-resetPassword')}</span>
            </ButtonStyled>
          </Box>
        </DataUser>
        <FormProfile onSubmit={handleProfile}>
          <TitleUsers>
            <BsGear />
            <h2>{t('profileSettings.title-preferences')}</h2>
          </TitleUsers>
          <SelectComponent
            labelSelect={t('profileSettings.label-language')}
            value={languageSelect}
            onChange={(event) => setLanguageSelect(event.target.value)}
          >
            {listLangs.map((lang, i) => (
              <MenuItem value={lang} key={i}>
                {lang === 'en' && t('profileSettings.option-enLanguage')}
                {lang === 'pt' && t('profileSettings.option-ptBrLanguage')}
              </MenuItem>
            ))}
          </SelectComponent>

          <div className='footerProfiles'>
            <ButtonStyled
              type='button'
              variantstyle={'secondary'}
              onClick={handleBack}
              size={'small'}
            >
              {t('profileSettings.btn-back')}
            </ButtonStyled>
            <ButtonStyled type='submit' size={'small'}>
              {t('profileSettings.btn-send')}
            </ButtonStyled>
          </div>
        </FormProfile>
      </ContainerProfile>

      <DialogComponent
        open={dialogResetPassword}
        onClose={() => setDialogResetPassword(false)}
        fullWidth={true}
      >
        <ResetPasswordUser
          closeDialog={() => setDialogResetPassword(false)}
          emailUser={emailUser}
        />
      </DialogComponent>
    </>
  );
};
