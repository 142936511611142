import { useTranslation } from 'react-i18next';
import { MdPauseCircle } from 'react-icons/md';
import { ContainerPauseStatus } from './styled';

export const PauseStatus = () => {
  const { t } = useTranslation();
  return (
    <ContainerPauseStatus initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <MdPauseCircle />
      <span>{t('agents.pauseStatus.titlePause')}</span>
    </ContainerPauseStatus>
  );
};
