import { memo } from 'react';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import { dataInfoQueue, selectedQueue } from '@/store/DialingStore';

import { CardStatusQueue } from './styled';
import { Stack } from '@mui/system';
import { MdRadioButtonChecked } from 'react-icons/md';

export const CardStatusComponent = ({
  dataQueue,
  changeFlowDialing,
  mutateDataInfoQueue,
}) => {
  const { t } = useTranslation();
  const [, setSelectedQueue] = useAtom(selectedQueue);

  const generatorColorStatus = (statusQueue) => {
    switch (statusQueue) {
      case 'VAZIO':
        return 'colorNull';
      case 'MANUTENCAO':
        return 'colorNull';
      case 'FECHADO':
        return 'colorClosed';
      case 'PARADO':
        return 'colorClosed';
      case 'ABERTO':
        return 'colorDiscing';
      case 'DISCANDO':
        return 'colorDiscing';
      default:
        return 'cardNull';
    }
  };

  const renderStatusQueue = (statusQueue) => {
    switch (statusQueue) {
      case 'VAZIO':
        return 0;
      case 'MANUTENCAO':
        return 0;
      case 'FECHADO':
        return 1;
      case 'PARADO':
        return 1;
      case 'ABERTO':
        return 2;
      case 'DISCANDO':
        return 2;
      default:
        return 0;
    }
  };

  const generatorText = (text) => {
    switch (text) {
      case 'VAZIO':
        return 'option-empty';
      case 'MANUTENCAO':
        return 'option-empty';
      case 'FECHADO':
        return 'option-closed';
      case 'PARADO':
        return 'option-closed';
      case 'ABERTO':
        return 'option-discing';
      case 'DISCANDO':
        return 'option-discing';
      default:
        return 'option-empty';
    }
  };

  const handleDescriptionQueue = (queue) => {
    mutateDataInfoQueue(queue.fila, {
      onSuccess: () => {
        setSelectedQueue(queue);
        changeFlowDialing(1);
      },
    });
  };

  return (
    <CardStatusQueue
      whileTap={{ scale: 0.99 }}
      onClick={() => handleDescriptionQueue(dataQueue)}
      statusQueue={renderStatusQueue(dataQueue.estado)}
    >
      <div className='contentImage'></div>

      <div className='contentCard'>
        <span>
          <strong>{dataQueue.campanha}</strong>
        </span>
        <Stack direction={'row'} alignItems={'center'} gap={'4px'}>
          <MdRadioButtonChecked
            className={generatorColorStatus(dataQueue.estado)}
          />
          <span className={generatorColorStatus(dataQueue.estado)}>
            {t(`dialing.${generatorText(dataQueue.estado)}`)}
          </span>
        </Stack>
      </div>
      <span>{dataQueue.fila}</span>
    </CardStatusQueue>
  );
};

export const CardStatus = memo(CardStatusComponent);
