import { useTranslation } from 'react-i18next';
import { DialogComponent } from '@/components/DialogComponent';
import { ButtonStyled } from '@/components/FormComponents/ButtonComponent';
import { ContainerConfig, HeaderConfig, MainConfig } from './styled';
import { useAtom } from 'jotai';
import { templateSelected } from '@/store/FlowBuilder';
import { useEffect, useId, useState } from 'react';
import { ConfigGlobalVars } from './stages/ConfigGlobalVars';
import { ConfigDispositions } from './stages/ConfigDispositions';
import { ConfigNlus } from './stages/ConfigNlus';

export const ConfigCurrentFlow = ({ open, close }) => {
  const { t } = useTranslation();
  const [template] = useAtom(templateSelected);
  const [stagesConfig, setStagesConfig] = useState(0);

  const { codeLigs, variablesGlobal, nlus } = template;

  useEffect(() => {
    return () => setStagesConfig(0);
  }, []);

  const handleClose = () => {
    close(false);
  };

  const handleChangeStage = (stage) => {
    setStagesConfig(stage);
  };

  const flowConfig = [
    <ConfigGlobalVars
      key={useId()}
      dataGlobals={variablesGlobal ?? []}
      handleClose={handleClose}
    />,
    <ConfigDispositions
      key={useId()}
      dataCodeLigs={codeLigs ?? []}
      handleClose={handleClose}
    />,
    <ConfigNlus
      key={useId()}
      dataNlus={nlus ?? []}
      handleClose={handleClose}
    />,
  ];

  return (
    <DialogComponent
      open={open}
      onClose={() => close(false)}
      fullWidth={true}
      maxWidth={'lg'}
    >
      <ContainerConfig>
        <HeaderConfig>
          <ButtonStyled
            size='small'
            className={stagesConfig === 0 ? 'activeStage' : ''}
            onClick={() => handleChangeStage(0)}
          >
            {t('flowBuilder.configs.btn-globalVars')}
          </ButtonStyled>
          <ButtonStyled
            size='small'
            className={stagesConfig === 1 ? 'activeStage' : ''}
            onClick={() => handleChangeStage(1)}
          >
            {t('flowBuilder.configs.btn-dispositions')}
          </ButtonStyled>
          <ButtonStyled
            size='small'
            className={stagesConfig === 2 ? 'activeStage' : ''}
            onClick={() => handleChangeStage(2)}
          >
            {t('flowBuilder.configs.btn-endPoint')}
          </ButtonStyled>
        </HeaderConfig>
        <MainConfig>{flowConfig[stagesConfig]}</MainConfig>
      </ContainerConfig>
    </DialogComponent>
  );
};
