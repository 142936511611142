import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { getCallHistory } from '@services/agents/callHistory/getCallHistory';

import { errorToast } from '@components/FormComponents/Toast';

export const useCallHistory = () => {
  const { t } = useTranslation();

  const { data: dataCallHistory, isLoading: loadingCallHistory } = useQuery(
    'allCallsHistories',
    async () => {
      return await getCallHistory();
    },
    {
      retry: false,
      onError: () => errorToast(t('agents.callHistory.errorRequesCallHistory')),
    }
  );

  return { dataCallHistory, loadingCallHistory };
};
