import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ContentControlClient = styled(motion.div)`
  position: absolute;
  bottom: 10%;
  right: 10px;
  width: 10px;
  height: 50px;
  background-color: ${(props) => props.theme.colors.defaultColors.blue};
  border-radius: 10px 0px 0px 10px;
  transition: width 0.2s ease;

  display: flex;
  justify-content: center;
  align-items: center;

  button {
    all: unset;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.3s ease;

    svg {
      font-size: 1.4rem;
      color: white;
    }
  }
`;
