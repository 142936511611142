import { useState } from 'react';
import { useAtom } from 'jotai';
import { Box, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  ContainerCampaign,
  ContentPanelCampaigns,
  ContentStatusCampaigns,
} from './styled';
import { SelectComponent } from '@/components/FormComponents/SelectInput';
import { NoDataCard } from '@/components/layout/NoDataCard';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';
import { dataListsDialing } from '@/store/DialingStore';
import { CardStatus } from './CardStatus';
import { useListCampaign } from '@/hooks/dialControl/ListCampaigns/useListCampaigns';

export const CampaignList = ({ controlStage }) => {
  const { t } = useTranslation();
  const { loadingListsDialing } = useListCampaign();

  const [listsDialing] = useAtom(dataListsDialing);

  const [filterQueue, setFilterQueue] = useState('0');

  const filterCampaignList = () => {
    const list = listsDialing;
    if (filterQueue === '0') {
      return list;
    }
    const filtered = list.filter((data) => data.name.includes(filterQueue));
    return filtered;
  };

  const renderTxt = (word) => {
    return t(`dialControl.listCampaign.campaignList.${word}`);
  };

  const isLoading = loadingListsDialing;

  return (
    <ContainerCampaign>
      {isLoading && <BackDropCircularLoading />}
      <ContentPanelCampaigns>
        <Box width={'100%'}>
          <SelectComponent
            labelSelect={renderTxt('label-filterCampaign')}
            value={filterQueue}
            onChange={(event) => setFilterQueue(event.target.value)}
          >
            <MenuItem value={'0'} dense>
              {renderTxt('option-filterCampaign')}
            </MenuItem>
            {listsDialing &&
              listsDialing.map((item, i) => (
                <MenuItem value={item.name} key={i} dense>
                  {item.name}
                </MenuItem>
              ))}
          </SelectComponent>
        </Box>
      </ContentPanelCampaigns>
      <ContentStatusCampaigns
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
      >
        {listsDialing && listsDialing.length > 0 ? (
          filterCampaignList().map((item, i) => (
            <CardStatus
              key={i}
              dataGroup={item}
              changeFlowDialing={controlStage.toStage}
            />
          ))
        ) : (
          <NoDataCard />
        )}
      </ContentStatusCampaigns>
    </ContainerCampaign>
  );
};
