import { io } from 'socket.io-client';
import { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { user } from '@/store/AuthenticatorStore';

export const useSocketQueueMonitor = (clientIdInput) => {
  const [, setSocket] = useState(null);
  const [dataQueueMonitor, setDataQueueMonitor] = useState([]);
  const [isConnected, setIsConnected] = useState(false);
  const [hasErrorSocket, setHasErrorSocket] = useState(false);
  const [currentUser] = useAtom(user);

  const url = import.meta.env.VITE_BASE_URL;

  useEffect(() => {
    const newSocket = io(url, {
      query: {
        client_id: currentUser.client_id,
      },
    });

    setSocket(newSocket);

    newSocket.on('connect', () => {
      setIsConnected(true);
    });

    newSocket.on('summaryUpdate', (message) => {
      if (message.erro === 1) {
        return setHasErrorSocket(true);
      }

      const mergeData = message.map((itemList) => itemList.data);

      const unifiedObject = mergeData.reduce((acc, currentItem) => {
        return { ...acc, ...currentItem.filas };
      }, {});

      const item = [
        ...Object.entries(unifiedObject).map(([key, value]) => ({
          fila: key,
          ...value,
        })),
      ];
      setHasErrorSocket(false);
      return setDataQueueMonitor(item);
    });

    newSocket.on('disconnect', () => {
      setIsConnected(false);
    });

    return () => {
      newSocket.disconnect();
    };
  }, [url]);

  return {
    dataQueueMonitor,
    isConnected,
    hasErrorSocket,
    setHasErrorSocket,
  };
};
