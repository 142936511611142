import { MdClose, MdOutlineCheck } from 'react-icons/md';
import {
  TableContainer,
  StyledTable,
  BtnCancel,
  TextTable,
  BtnConfirm,
} from './styled';
import { motion } from 'framer-motion';
import { IoMdTrash } from 'react-icons/io';
import { SelectComponent } from '@/components/FormComponents/SelectInput';
import { MenuItem } from '@mui/material';
import { renderDate } from '@/utils/formatsDateAndTime';
import { useAtom } from 'jotai';
import { dataListClients } from '@/store/Clients';
import { DialogComponent } from '@/components/DialogComponent';
import { useState } from 'react';
import { RemoveMatchTenant } from './RemoveMatchTenant';
import { dataSelectedMatchTenants } from '@/store/TenantsStore';
import { useTranslation } from 'react-i18next';
import { successToast } from '@/components/FormComponents/Toast';
import { useRenderNameTenants } from '@/components/pages/Tenants/hooks/useRenderNamesTenants';
import { usePermissionsTenants } from '@/components/pages/Tenants/hooks/usePermissionsTenants';

export const TableMatchTenants = ({ dataRows, mutateCreateMatch }) => {
  const { t } = useTranslation();
  const [listClients] = useAtom(dataListClients);
  const { renderHostName, renderClientName } = useRenderNameTenants();
  const { permissionsEdit } = usePermissionsTenants();

  const [dialogRemoveMatch, setDialogRemoveMatch] = useState(false);
  const [, setSelectedMatch] = useAtom(dataSelectedMatchTenants);

  const renderText = (spell) => {
    return t(`tenants.addMatchTenant.table.${spell}`);
  };

  const handleCloseDialogRemove = () => {
    setDialogRemoveMatch(false);
  };

  const handleRemoveTenant = (dataMatch) => {
    setSelectedMatch(dataMatch);
    setDialogRemoveMatch(true);
  };

  const {
    rows,
    setRows,
    selected,
    setSelected,
    isNewRow,
    setIsNewRow,
    setEditServerSelected,
    editClientSelected,
    setEditClientSelected,
  } = dataRows;

  const handlerCancelEdit = (idCancel) => {
    if (isNewRow) {
      const filterNewRow = rows.filter((row) => row.id !== idCancel);
      setRows(filterNewRow);
      setIsNewRow(false);
      setSelected(null);
      setEditServerSelected('0');
      setEditClientSelected('0');
    }
    setSelected(null);
    setEditServerSelected('0');
    setEditClientSelected('0');
  };

  const confirmEdit = (newData) => {
    const data = {
      client_id: editClientSelected,
      server_id: newData.server_id,
    };

    mutateCreateMatch(data, {
      onSuccess: () => {
        setSelectedMatch(null);
        setSelected(null);
        setIsNewRow(false);
        successToast('Sucesso ao adicionar Agregado');
      },
    });
  };

  return (
    <>
      <TableContainer>
        <StyledTable>
          <thead>
            <tr>
              <th>{renderText('th-match')}</th>
              <th>{renderText('th-client')}</th>
              <th>{renderText('th-createdAt')}</th>
              <th>{renderText('th-updatedAt')}</th>
              <th>{renderText('th-action')}</th>
            </tr>
          </thead>
          <tbody>
            {rows.map((row) => {
              return selected?.id === row.id ? (
                <motion.tr
                  key={row.id}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                >
                  <td>
                    <TextTable selected={0}>
                      {renderHostName(row.server_id)}
                    </TextTable>
                  </td>
                  <td>
                    <TextTable selected={0}>
                      <SelectComponent
                        defaultValue='0'
                        disableHelper
                        value={editClientSelected}
                        onChange={(e) => setEditClientSelected(e.target.value)}
                      >
                        <MenuItem value={'0'} dense disabled>
                          {renderText('option-client')}
                        </MenuItem>
                        {listClients.map((client, i) => (
                          <MenuItem key={i} value={client.id} dense>
                            {client.commercial_name}
                          </MenuItem>
                        ))}
                      </SelectComponent>
                    </TextTable>
                  </td>
                  <td>
                    <TextTable selected={0}>
                      {renderDate(row.created_at)}
                    </TextTable>
                  </td>
                  <td>
                    <TextTable selected={0}>
                      {renderDate(row.updated_at)}
                    </TextTable>
                  </td>
                  <td>
                    <div className={'actionTable'}>
                      <BtnConfirm
                        type='button'
                        onClick={() => confirmEdit(row)}
                      >
                        <MdOutlineCheck />
                      </BtnConfirm>
                      <BtnCancel
                        onClick={() => handlerCancelEdit(row.id)}
                        type='button'
                      >
                        <MdClose />
                      </BtnCancel>
                    </div>
                  </td>
                </motion.tr>
              ) : (
                <motion.tr
                  key={row.id}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                >
                  <td>
                    <TextTable selected={selected ? 1 : 0}>
                      {renderHostName(row.server_id)}
                    </TextTable>
                  </td>
                  <td>
                    <TextTable selected={selected ? 1 : 0}>
                      {renderClientName(row.client_id)}
                    </TextTable>
                  </td>
                  <td>
                    <TextTable selected={selected ? 1 : 0}>
                      {renderDate(row.created_at)}
                    </TextTable>
                  </td>
                  <td>
                    <TextTable selected={selected ? 1 : 0}>
                      {renderDate(row.updated_at)}
                    </TextTable>
                  </td>
                  <td>
                    <div className={'actionTable'}>
                      <BtnCancel
                        type='button'
                        onClick={() => handleRemoveTenant(row)}
                        disabled={selected || !permissionsEdit}
                      >
                        <IoMdTrash />
                      </BtnCancel>
                    </div>
                  </td>
                </motion.tr>
              );
            })}
          </tbody>
        </StyledTable>
      </TableContainer>

      <DialogComponent
        open={dialogRemoveMatch}
        onClose={handleCloseDialogRemove}
        fullWidth={true}
      >
        <RemoveMatchTenant closeDialog={handleCloseDialogRemove} />
      </DialogComponent>
    </>
  );
};
