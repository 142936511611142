import { memo, useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';

import { Button, Tooltip } from '@mui/material';
import { Popover } from '@mui/material';

import {
  currentPageId,
  hasSubItem,
  idSubItem,
} from '@store/NavigationDashboard';
import { user } from '@store/AuthenticatorStore';
import {
  currentUserAgent,
  dataCallInformation,
  isLoadingComponent,
} from '@store/AgentsStore';

import useAuth from '@/hooks/Authenticator/useAuth';
import { useAgents } from '@hooks/Agents/useAgents';
import { useControlTimeAgent } from '@hooks/Agents/useControlTimeAgent';

import {
  DetailUserAccount,
  HeaderAccordion,
  BoxInfoUser,
  PopoverButtonSidebar,
} from './styled';

import {
  RiArrowDropDownLine,
  RiDashboard3Line,
  RiLogoutBoxLine,
} from 'react-icons/ri';
import { BsFillPersonLinesFill } from 'react-icons/bs';
import { PiUserCircleThin } from 'react-icons/pi';

const InfoUser = ({ stateItem, sidebarIsOpem }) => {
  const { t } = useTranslation();
  const { logOut } = useAuth();
  const { removeLocalStatus } = useControlTimeAgent();
  const navigate = useNavigate();
  const location = useLocation();
  const currentLocation = location.pathname.toLowerCase();

  const { mutateAgentLogout, mutateGoOutSystem } = useAgents();
  const [userAgent, setUserAgent] = useAtom(currentUserAgent);

  const [clientUser] = useAtom(user);

  const [anchorEl, setAnchorEl] = useState(null);
  const [userName, setUserName] = useState();
  const [userEmail, setEmail] = useState();

  const [userType, setUserType] = useState(null);

  const [, setCurrentId] = useAtom(currentPageId);
  const [, setSubItemId] = useAtom(idSubItem);
  const [, setHaveSubItem] = useAtom(hasSubItem);
  const [, setIsLoadingAgent] = useAtom(isLoadingComponent);
  const [, setDataCall] = useAtom(dataCallInformation);

  useEffect(() => {
    if (clientUser) {
      setUserType(clientUser.user_type);
      setUserName(clientUser.name);
      setEmail(clientUser.email);
    }
  }, [clientUser]);

  const verifyStringLength = (string) => {
    if (!string) {
      return '...';
    }
    return string.length > 28 ? string.slice(0, 28) + '...' : string;
  };

  const handlePageMainDashboard = (itemId) => {
    setCurrentId(itemId);
    setHaveSubItem(false);
    setSubItemId(false);
  };

  const handleLogOut = () => {
    setIsLoadingAgent(true);
    logOut();
    setAnchorEl(null);
  };

  const handleDashboard = () => {
    removeLocalStatus();
    setIsLoadingAgent(true);
    if (userAgent) {
      mutateAgentLogout(
        {
          extension: clientUser.extension,
        },
        {
          onSuccess: () => {
            setDataCall({});
            userAgent.stop();
            setUserAgent(null);
            window.location.href = '/dashboard';
          },
        }
      );
    } else {
      navigate('/dashboard');
    }
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleClickProfile = () => {
    setAnchorEl(null);
    navigate('/dashboard');
    handlePageMainDashboard('profile', false, false);
  };

  return (
    <DetailUserAccount
      animate={{ x: `${stateItem}px`, opacity: sidebarIsOpem }}
      transition={{ duration: 0.3 }}
    >
      <HeaderAccordion>
        <div>
          <PiUserCircleThin />
          <motion.div className='info-sidebar'>
            <span className='name-user' title={userName && userName}>
              {userName}
            </span>

            <Tooltip title={userEmail} arrow>
              <span className='email-user'>
                {verifyStringLength(userEmail)}
              </span>
            </Tooltip>
          </motion.div>
        </div>

        <PopoverButtonSidebar>
          <Button
            aria-label='Navigation Profile User'
            aria-describedby={id}
            variant='contained'
            onClick={handleClick}
          >
            <RiArrowDropDownLine className={open && 'rotate'} />
          </Button>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <BoxInfoUser>
              {currentLocation === '/dashboard' ? (
                <motion.span onClick={handleClickProfile}>
                  <BsFillPersonLinesFill />
                  {t('dashboard.profileUser')}
                </motion.span>
              ) : (
                userType === 1 && (
                  <motion.span onClick={handleDashboard}>
                    <RiDashboard3Line />
                    Dashboard
                  </motion.span>
                )
              )}

              <motion.span onClick={handleLogOut}>
                <RiLogoutBoxLine />
                Logout
              </motion.span>
            </BoxInfoUser>
          </Popover>
        </PopoverButtonSidebar>
      </HeaderAccordion>
    </DetailUserAccount>
  );
};

export const InfoUserComponent = memo(InfoUser);
