import 'reactflow/dist/style.css';
import { motion } from 'framer-motion';
import { useCallback, useEffect, useState } from 'react';
import { ContainerFlowConstructor, ContentFlow } from './styled';
import ReactFlow, {
  Background,
  MarkerType,
  addEdge,
  useReactFlow,
  Panel,
} from 'reactflow';
import { nodeTypes } from '../nodes/nodeTypes';
import { useNodeFlow } from '@/hooks/FlowBuilder/useNodeFlow';
import { useTheme } from 'styled-components';
import { edgeTypes } from '../edges/edgeTypes';
import { HeaderFlow } from './HeaderFlow';
import { useAtom } from 'jotai';
import {
  dataNodeErrorConnect,
  focusedFieldArea,
  hasModalOpen,
  isSavedFlow,
  showMenuNodes,
  templateSelected,
} from '@/store/FlowBuilder';
import { AiOutlineZoomIn, AiOutlineZoomOut } from 'react-icons/ai';
import { HiMiniViewfinderCircle } from 'react-icons/hi2';
import { errorToast } from '@/components/FormComponents/Toast';
import { useTranslation } from 'react-i18next';
import useWindowDimensions from '@/hooks/useWidth';
import { usePermissionsFlow } from '@/hooks/FlowBuilder/usePermissionsFlow';
import HelperLinesRenderer from '../../utils/HelperLines';
import SquareFootIcon from '@mui/icons-material/SquareFoot';
import useCopyPaste from '../../utils/useCopyPaste';

const proOptions = { account: 'paid-pro', hideAttribution: true };

export const FlowConstructor = ({ dataNodes }) => {
  const { t } = useTranslation();
  const [showMenu] = useAtom(showMenuNodes);
  const [modalIsOpened] = useAtom(hasModalOpen);
  const [nodeErrorConnect, setNodeErrorConnect] = useAtom(dataNodeErrorConnect);

  const { zoomIn, zoomOut, fitView } = useReactFlow();
  const { copy, paste } = useCopyPaste();

  const { permissionsCreate, permissionsEdit, isSysAdmin } =
    usePermissionsFlow();

  const [isFocusedField] = useAtom(focusedFieldArea);
  const [showRules, setShowRules] = useState(true);
  const [, setIsSaved] = useAtom(isSavedFlow);
  const theme = useTheme();
  const { handlerAddNodeByType, renderSubItensConditional } = useNodeFlow();
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [template] = useAtom(templateSelected);
  const { height, width } = useWindowDimensions();

  useEffect(() => {
    if (reactFlowInstance) {
      fitView({
        maxZoom: '0.8',
        duration: 200,
      });
    }
  }, [template, reactFlowInstance]);

  useEffect(() => {
    if (reactFlowInstance) {
      fitView({
        maxZoom: '0.8',
        duration: 200,
      });
    }
  }, [height, width]);

  useEffect(() => {
    if (nodeErrorConnect) {
      fitView({
        nodes: [nodeErrorConnect],
        padding: 0.1,
        includeHiddenNodes: false,
        maxZoom: '0.9',
        duration: 200,
      });
    }
  }, [nodeErrorConnect]);

  const handleFitView = () => {
    fitView({
      maxZoom: '0.8',
      duration: 200,
    });
  };

  const renderColorMarkerEnd = (typeNode) => {
    if (typeNode !== 'successCondition') {
      return theme.colors.nodesFlow.errorEdge;
    }
    return theme.colors.nodesFlow.successEdge;
  };

  const renderTypeNode = (typeNode) => {
    if (typeNode !== 'successCondition') {
      return 'errorEdge';
    }
    return 'successEdge';
  };

  const renderEdge = (idSource) => {
    const filteredEdge = dataNodes.nodes
      .filter((node) => node.id === idSource)
      .map((node) => node.type);

    const filteredEdgeType = dataNodes.nodes
      .filter((node) => node.id === idSource)
      .map((node) => node.data.handler);

    switch (filteredEdge[0]) {
      case 'initNode':
        return {
          type: 'edgeColor',
          animated: false,
          markerEnd: {
            type: MarkerType.ArrowClosed,
            width: 18,
            height: 18,
            color: `${theme.colors.nodesFlow.initNode}`,
            typeEdge: filteredEdge[0],
          },
        };

      case 'disposition':
        return {
          type: 'edgeColor',
          animated: false,
          markerEnd: {
            type: MarkerType.ArrowClosed,
            width: 18,
            height: 18,
            color: `${theme.colors.nodesFlow.disposition}`,
            typeEdge: filteredEdge[0],
          },
        };

      case 'ttsVoicceLabs':
        return {
          type: 'edgeColor',
          animated: false,
          markerEnd: {
            type: MarkerType.ArrowClosed,
            width: 16,
            height: 16,
            color: `${theme.colors.nodesFlow.ttsVoicceLabs}`,
            typeEdge: 'ttsVoicceLabs',
          },
        };
      case 'setVariables':
        return {
          type: 'edgeColor',
          animated: false,
          markerEnd: {
            type: MarkerType.ArrowClosed,
            width: 16,
            height: 16,
            color: `${theme.colors.nodesFlow.setVariables}`,
            typeEdge: 'setVariables',
          },
        };
      case 'functionHandler':
        return {
          type: 'edgeColor',
          animated: false,
          markerEnd: {
            type: MarkerType.ArrowClosed,
            width: 16,
            height: 16,
            color: `${theme.colors.nodesFlow.functionHandler}`,
            typeEdge: 'functionHandler',
          },
        };

      case 'itemRecVoicceNode':
        return {
          type: 'edgeColor',
          animated: false,
          markerEnd: {
            type: MarkerType.ArrowClosed,
            width: 18,
            height: 18,
            color: `${theme.colors.nodesFlow.itemRecVoicceNode}`,
            typeEdge: 'itemRecVoicceNode',
          },
        };

      case 'subNode':
        return {
          type: 'edgeColor',
          animated: false,
          markerEnd: {
            type: MarkerType.ArrowClosed,
            width: 18,
            height: 18,
            color: renderColorMarkerEnd(filteredEdgeType[0]),
            typeEdge: renderTypeNode(filteredEdgeType[0]),
          },
        };
      default:
        return {
          type: 'edgeColor',
          animated: false,
          markerEnd: {
            type: MarkerType.ArrowClosed,
            width: 18,
            height: 18,
            color: `red`,
            typeEdge: 'initNode',
          },
        };
    }
  };

  const checkTargetUsedNodes = (idNodeSource) => {
    const searchUserEdgeTarget = dataNodes.edges.filter(
      (item) => item.source === idNodeSource
    );

    if (searchUserEdgeTarget.length > 0) {
      return true;
    }

    return false;
  };

  const onConnect = useCallback(
    (params) => {
      const hasUsedTargetNode = checkTargetUsedNodes(params.source);

      if (hasUsedTargetNode) {
        return errorToast(t('flowBuilder.flow.msg-error-hasConnected'));
      }
      setIsSaved(false);

      const removeErrorNodeConnect = dataNodes.nodes.map((item) => {
        const filterSubNode = dataNodes.nodes
          .filter((item) => item.id === params.source)
          .map((item) => item.parentNode)
          .toString();

        const filterSuccessParentParams = params.source.replace(
          /sub_id_|_[^_ ]+$/g,
          ''
        );

        if (
          item.id === params.source ||
          item.id === filterSuccessParentParams ||
          item.id === filterSubNode
        ) {
          setNodeErrorConnect(null);
          return {
            ...item,
            data: {
              ...item.data,
              errorConnect: false,
            },
          };
        }

        return item;
      });

      //   takeSnapshot();
      dataNodes.setNodes(removeErrorNodeConnect);

      dataNodes.setEdges((eds) =>
        addEdge({ ...params, ...renderEdge(params?.source) }, eds)
      );
    },
    [dataNodes, setIsSaved]
  );

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const renderLabelHangUp = () => {
    const filterHangups = dataNodes.nodes.filter(
      (item) => item.data.handler === 'hangUp'
    );
    if (!filterHangups || filterHangups.length === 0) {
      return 'end';
    }
    return `end_${filterHangups.length + 1}`;
  };

  const renderLabelLoop = () => {
    const filterHangups = dataNodes.nodes.filter(
      (item) => item.data.handler === 'loop'
    );
    if (!filterHangups || filterHangups.length === 0) {
      return 'loop';
    }
    return `loop_${filterHangups.length + 1}`;
  };

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();
      //   takeSnapshot();
      const type = event.dataTransfer.getData('application/reactflow');
      if (typeof type === 'undefined' || !type) {
        return;
      }

      const position = reactFlowInstance.screenToFlowPosition({
        x: event.clientX,
        y: event.clientY,
      });

      const newNodeToAdd = handlerAddNodeByType(
        type,
        position,
        renderLabelHangUp(),
        renderLabelLoop()
      );
      //   takeSnapshot();
      dataNodes.setNodes((nds) => nds.concat(newNodeToAdd));

      const subItemsRender = renderSubItensConditional(
        newNodeToAdd.id,
        newNodeToAdd.type
      );

      if (type === 'dialplanVariables') {
        return subItemsRender.map((item) =>
          dataNodes.setNodes((nds) => nds.concat(item))
        );
      }

      if (type === 'apiData') {
        subItemsRender.map((item) =>
          dataNodes.setNodes((nds) => nds.concat(item))
        );
        return;
      }

      if (type === 'ttsVoicceLabs') {
        return subItemsRender.map((item) =>
          dataNodes.setNodes((nds) => nds.concat(item))
        );
      }
      if (type === 'conditionalNode') {
        return subItemsRender.map((item) =>
          dataNodes.setNodes((nds) => nds.concat(item))
        );
      }

      if (type === 'loop') {
        return subItemsRender.map((item) => {
          return dataNodes.setNodes((nds) => nds.concat(item));
        });
      }

      if (type === 'transferQueue') {
        return subItemsRender.map((item) => {
          return dataNodes.setNodes((nds) => nds.concat(item));
        });
      }
    },
    [reactFlowInstance, dataNodes]
  );

  const onKeyDown = (event) => {
    if (
      (event.key === 'Delete' || event.key === 'Backspace') &&
      !isFocusedField &&
      !showMenu &&
      !modalIsOpened
    ) {
      const { nodes, edges } = dataNodes.selectedNodes;
      if (edges.length > 0) {
        for (let i = 0; i < edges.length; i++) {
          //   takeSnapshot();
          dataNodes.removeEdge(edges[i].id);
          setIsSaved(false);
        }
      } else if (nodes.length > 0) {
        for (let i = 0; i < nodes.length; i++) {
          //   takeSnapshot();
          dataNodes.removeNode(nodes[i].id);
          setIsSaved(false);
        }
      }
    } else if (
      (event.ctrlKey || event.metaKey) &&
      event.key === 'c' &&
      !isFocusedField &&
      !showMenu &&
      !modalIsOpened
    ) {
      //   takeSnapshot();
      copy();
    } else if (
      (event.ctrlKey || event.metaKey) &&
      event.key === 'v' &&
      !isFocusedField &&
      !showMenu &&
      !modalIsOpened
    ) {
      //   takeSnapshot();
      paste();
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', onKeyDown);
    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, [onKeyDown]);

  const permissionsUser = permissionsCreate || permissionsEdit || isSysAdmin;

  return (
    <ContainerFlowConstructor>
      <HeaderFlow dataNodes={dataNodes} />

      <ContentFlow>
        <div className='bg-water-mark'></div>
        <ReactFlow
          nodes={dataNodes.nodes}
          edges={dataNodes.edges}
          nodesDraggable={permissionsUser}
          elementsSelectable={permissionsUser}
          nodesConnectable={permissionsUser}
          onNodesChange={dataNodes.handleNodesChange}
          onEdgesChange={dataNodes.handleEdgesChange}
          //   onNodeDragStart={onNodeDragStart}
          onConnect={onConnect}
          onInit={setReactFlowInstance}
          onDrop={onDrop}
          onDragOver={onDragOver}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          onSelectionChange={dataNodes.setSelectedNodes}
          deleteKeyCode={null}
          zoomOnDoubleClick={false}
          minZoom={-0.5}
          proOptions={proOptions}
          selectNodesOnDrag={false}
          multiSelectionKeyCode='Shift'
        >
          <Background
            style={{
              background: `${theme.colors.containers.baseContainerFlow}`,
            }}
          />

          {showRules && (
            <HelperLinesRenderer
              horizontal={dataNodes.helperLineHorizontal}
              vertical={dataNodes.helperLineVertical}
            />
          )}

          <Panel position='bottom-left'>
            <div className='panel-control-flow'>
              <motion.button
                onClick={() => setShowRules(!showRules)}
                title={'Show rules'}
                whileTap={{ scale: 0.95 }}
                whileHover={{ opacity: 0.85 }}
                style={{
                  opacity: showRules ? 1 : 0.5,
                }}
              >
                <SquareFootIcon />
              </motion.button>
              <motion.button
                onClick={() => zoomIn({ duration: 200 })}
                title={'Zoom In'}
                whileTap={{ scale: 0.95 }}
                whileHover={{ opacity: 0.85 }}
              >
                <AiOutlineZoomIn />
              </motion.button>
              <motion.button
                onClick={() => zoomOut({ minZoom: 0, duration: 200 })}
                title={'Zoom Out'}
                whileTap={{ scale: 0.95 }}
                whileHover={{ opacity: 0.85 }}
              >
                <AiOutlineZoomOut />
              </motion.button>
              <motion.button
                onClick={handleFitView}
                title={'Fit Flow'}
                whileTap={{ scale: 0.95 }}
                whileHover={{ opacity: 0.85 }}
              >
                <HiMiniViewfinderCircle />
              </motion.button>
            </div>
          </Panel>
        </ReactFlow>
      </ContentFlow>
    </ContainerFlowConstructor>
  );
};
