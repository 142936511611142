import { useLayoutEffect, useRef, useState } from 'react';
import useWindowDimensions from '@/hooks/useWidth';
import { TablePagination } from '@/components/Tables/TablePagination';
import { ContainerTableSchedules } from './styled';
import { ButtonStyled } from '@/components/FormComponents/ButtonComponent';
import { MdClose, MdEdit } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';

import { isValid, parse } from 'date-fns';
import { selectedScheduleQueue } from '@/store/ScheduleStore/SchedulerList';
import { useDaysOfWeek } from '../../../hooks/useDaysOfWeek';
import { Stack } from '@mui/system';
import { ConfirmationComponent } from '@/components/layout/ConfirmationComponent';
import { successToast } from '@/components/FormComponents/Toast';
import { usePermissionsSchedulerList } from '../../../hooks/usePermissionsSchedulerList';

export const TableSchedules = ({
  dataSchedule,
  controlStage,
  deleteSelectedSchedule,
}) => {
  const { t } = useTranslation();
  const { permissionsEdit, permissionsDelete } = usePermissionsSchedulerList();
  const { daysOfWeek } = useDaysOfWeek();
  const { height } = useWindowDimensions();

  const [scheduleSelected, setScheduleSelected] = useAtom(
    selectedScheduleQueue
  );
  const [confirmDelete, setConfirmDelete] = useState(false);

  const handleRemoveSchedule = (dataRemove) => {
    setScheduleSelected(dataRemove);
    setConfirmDelete(true);
  };

  const deleteSchedule = () => {
    const { id } = scheduleSelected;
    deleteSelectedSchedule(id, {
      onSuccess: () => {
        successToast(
          t('dialControl.schedulesList.listSchedules.success-remove')
        );
        setScheduleSelected(null);
      },
    });
  };

  const txtSchedules = (word) =>
    t(`dialControl.schedulesList.listSchedules.table-schedules.${word}`);

  const tableSchedules = useRef(null);

  const formatTime = (timeEdit) => {
    if (!timeEdit) {
      return new Date();
    }
    const date = parse(timeEdit, 'HH:mm:ss', new Date());
    if (!isValid(date)) {
      return;
    }
    return date;
  };

  const renderDayOfWeek = (currentDays) => {
    const filterWeekDay = daysOfWeek.filter(
      (day) => day.numberDay === Number(currentDays)
    );
    if (filterWeekDay.length === 0) {
      return '-';
    }
    return <span title={filterWeekDay[0].title}>{filterWeekDay[0].title}</span>;
  };

  const handleEditSchedule = (scheduleSelected) => {
    const dataUpdate = {
      ...scheduleSelected,
      horario: formatTime(scheduleSelected.horario),
      horario_termino: formatTime(scheduleSelected.horario_termino),
    };

    setScheduleSelected(dataUpdate);
    controlStage.toStage(3);
  };

  const ColumnsCampaigns = [
    {
      Header: txtSchedules('th-first'),
      accessor: 'dia_semana',
      Cell: ({ row }) => renderDayOfWeek(row.original.dia_semana),
    },

    {
      Header: txtSchedules('th-second'),
      accessor: 'horario',
    },
    {
      Header: txtSchedules('th-third'),
      accessor: 'horario_termino',
    },
    {
      Header: txtSchedules('th-fourth'),
      accessor: 'fila',
    },
    {
      Header: txtSchedules('th-fifth'),
      accessor: 'id_tabela_lista',
    },
    {
      Header: '1',
      accessor: 'cod_lig1',
      Cell: ({ row }) => row.original.cod_lig1 ?? '-',
    },
    {
      Header: '2',
      accessor: 'cod_lig2',
      Cell: ({ row }) => row.original.cod_lig2 ?? '-',
    },
    {
      Header: '3',
      accessor: 'cod_lig3',
      Cell: ({ row }) => row.original.cod_lig3 ?? '-',
    },
    {
      Header: '4',
      accessor: 'cod_lig4',
      Cell: ({ row }) => row.original.cod_lig4 ?? '-',
    },
    {
      Header: '5',
      accessor: 'cod_lig5',
      Cell: ({ row }) => row.original.cod_lig5 ?? '-',
    },
    {
      Header: '6',
      accessor: 'cod_lig6',
      Cell: ({ row }) => row.original.cod_lig6 ?? '-',
    },
    {
      Header: '7',
      accessor: 'cod_lig7',
      Cell: ({ row }) => row.original.cod_lig7 ?? '-',
    },
    {
      Header: '8',
      accessor: 'cod_lig8',
      Cell: ({ row }) => row.original.cod_lig8 ?? '-',
    },
    {
      Header: '9',
      accessor: 'cod_lig9',
      Cell: ({ row }) => row.original.cod_lig9 ?? '-',
    },
    {
      Header: '10',
      accessor: 'cod_lig10',
      Cell: ({ row }) => row.original.cod_lig10 ?? '-',
    },
    {
      Header: txtSchedules('th-sixth'),
      accessor: 'posicao',
    },
    {
      Header: txtSchedules('th-seventh'),
      Cell: ({ row }) => {
        return (
          <Stack direction={'row'} spacing={'2px'}>
            <ButtonStyled
              onClick={() => handleEditSchedule(row.original)}
              disabled={!permissionsEdit}
            >
              <MdEdit />
            </ButtonStyled>
            <ButtonStyled
              onClick={() => handleRemoveSchedule(row.original)}
              variantstyle='secondary'
              disabled={!permissionsDelete}
            >
              <MdClose />
            </ButtonStyled>
          </Stack>
        );
      },
    },
  ];

  const [heightTable, setHeightTable] = useState(0);

  useLayoutEffect(() => {
    if (tableSchedules) {
      const tableMaxWidth = tableSchedules.current.offsetHeight;
      const cellMinWidth = 60;
      const cellCount = Math.floor(tableMaxWidth / cellMinWidth);
      setHeightTable(cellCount);
    }
  }, [height, tableSchedules]);

  const variants = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
  };

  return (
    <ContainerTableSchedules
      ref={tableSchedules}
      variants={variants}
      initial={'hidden'}
      animate={dataSchedule.length > 0 && 'visible'}
    >
      <TablePagination
        dataColumns={ColumnsCampaigns}
        dataTable={dataSchedule}
        textNoData={'No data Schedules'}
        rowsPerPageDisplay={heightTable}
      />

      <ConfirmationComponent
        actionConfirm={deleteSchedule}
        messageConfirm={t('dialControl.schedulesList.listSchedules.msg-remove')}
        open={confirmDelete}
        close={setConfirmDelete}
      />
    </ContainerTableSchedules>
  );
};
