import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';
import {
  focusedFieldArea,
  isSavedFlow,
  selectedNode,
  showMenuNodes,
} from '@/store/FlowBuilder';
import { InputComponent } from '@/components/FormComponents/InputComponent';
import { ButtonStyled } from '@/components/FormComponents/ButtonComponent';
import { FooterAddNode, FormMenuNode, MainAddNode } from './styled';
import { checkLabelExistiNode } from '@/components/pages/FlowBuilder/utils/checkNameNodes';
import { errorToast } from '@/components/FormComponents/Toast';
import { useTranslation } from 'react-i18next';
import { TitleMenuNode } from '../TitleMenuNode';

export const HangUp = ({ dataNodes }) => {
  const { t } = useTranslation();
  const [, setFocusedArea] = useAtom(focusedFieldArea);
  const [, setShowMenu] = useAtom(showMenuNodes);
  const [selected, setSelected] = useAtom(selectedNode);
  const [labelNode, setLabelNode] = useState('');
  const [labelNodeError, setLabelNodeError] = useState('');
  const [, setIsSaved] = useAtom(isSavedFlow);

  const { nodes, setNodes } = dataNodes;
  const { id, data } = selected;

  useEffect(() => {
    if (selected) {
      setLabelNode(data.label);
    }
  }, [selected]);

  const validateLabelNode = (value) => {
    if (!value.trim()) {
      setLabelNodeError(
        t('flowBuilder.flow.nodes.hangUp.menu.msg-name-required')
      );
    } else if (value.length > 50) {
      setLabelNodeError(
        t('flowBuilder.flow.nodes.hangUp.menu.msg-name-length', {
          length: '50',
        })
      );
    } else if (!/^[_a-zA-Z0-9]*$/.test(value)) {
      setLabelNodeError(
        t('flowBuilder.flow.nodes.hangUp.menu.msg-name-letters')
      );
    } else {
      setLabelNodeError('');
    }
    setLabelNode(value);
  };

  const closeMenu = () => {
    setShowMenu(false);
    setLabelNode('');
    setSelected(null);
  };

  const handleChildClick = (e) => {
    e.stopPropagation();
  };

  const handleSubmitNewNode = (e) => {
    e.preventDefault();

    const checkLabel = checkLabelExistiNode(id, labelNode, nodes);

    if (checkLabel) {
      return errorToast(t('flowBuilder.flow.nodes.hangUp.menu.msg-name-used'));
    }

    const filteredNode = nodes.map((node) =>
      node.id === id
        ? {
            ...node,
            data: {
              ...node.data,
              label: labelNode,
            },
          }
        : node
    );
    setNodes(filteredNode);
    setIsSaved(false);
    closeMenu();
  };

  return (
    <FormMenuNode
      onClick={handleChildClick}
      onSubmit={handleSubmitNewNode}
      id='HangUpForm'
      name='HangUpForm'
    >
      <MainAddNode>
        <TitleMenuNode
          title={t('flowBuilder.flow.nodes.hangUp.menu.title')}
          description={t('flowBuilder.flow.nodes.hangUp.description')}
        />

        <InputComponent
          label={t('flowBuilder.flow.nodes.hangUp.menu.label-name')}
          value={labelNode}
          onChange={(e) => validateLabelNode(e.target.value)}
          inputProps={{ maxLength: 51 }}
          helperText={labelNodeError}
          error={Boolean(labelNodeError)}
          onFocus={() => setFocusedArea(true)}
          onBlur={() => setFocusedArea(false)}
        />
      </MainAddNode>
      <FooterAddNode>
        <ButtonStyled
          variantstyle={'secondary'}
          size={'small'}
          onClick={closeMenu}
        >
          {t('flowBuilder.flow.nodes.hangUp.menu.btn-cancel')}
        </ButtonStyled>
        <ButtonStyled
          type='submit'
          size={'small'}
          disabled={Boolean(labelNodeError) || labelNode.length === 0}
        >
          {t('flowBuilder.flow.nodes.hangUp.menu.btn-updated')}
        </ButtonStyled>
      </FooterAddNode>
    </FormMenuNode>
  );
};
