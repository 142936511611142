import { ButtonStyled } from '@/components/FormComponents/ButtonComponent';
import { motion } from 'framer-motion';
import { useAtom } from 'jotai';
import { DetailRouting, FooterStage, ListScreenAdd, MainStage } from './styled';
import { Stack } from '@mui/material';
import { dataRoutesProfileSelected } from '@/store/UsersAgentsStore/userAgentsStore';
import { useTranslation } from 'react-i18next';

export const CompleteNewRouting = ({ handleBackRouting }) => {
  const { t } = useTranslation();
  const [dataNewRouting, setDataNewRouting] = useAtom(
    dataRoutesProfileSelected
  );

  const handleRoutingProfile = () => {
    setDataNewRouting(null);
    handleBackRouting();
  };

  return (
    <ListScreenAdd>
      <MainStage>
        <motion.div
          className='success-checkmark'
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          <div className='check-icon'>
            <span className='icon-line line-tip'></span>
            <span className='icon-line line-long'></span>
            <div className='icon-circle'></div>
            <div className='icon-fix'></div>
          </div>
        </motion.div>

        <p>
          {t(
            'routingProfile.addRoutingProfile.successCreatedRouting.description'
          )}
        </p>
        <DetailRouting>
          <Stack width={'100%'} spacing={1}>
            <div className='item__screen'>
              <span>
                {t(
                  'routingProfile.addRoutingProfile.successCreatedRouting.label-name'
                )}
                :
              </span>
              <span>{dataNewRouting.name}</span>
            </div>
            <div className='item__screen'>
              <span>
                {t(
                  'routingProfile.addRoutingProfile.successCreatedRouting.label-description'
                )}
                :
              </span>
              <span>{dataNewRouting.description}</span>
            </div>
            <div className='item__screen'>
              <span>
                {t(
                  'routingProfile.addRoutingProfile.successCreatedRouting.label-client'
                )}
                :
              </span>
              <span>{dataNewRouting.client_id}</span>
            </div>
            <div className='item__screen'>
              <span>
                {t(
                  'routingProfile.addRoutingProfile.successCreatedRouting.label-totalScreens'
                )}
                :
              </span>
              <span>0</span>
            </div>
          </Stack>
        </DetailRouting>
      </MainStage>
      <FooterStage>
        <ButtonStyled
          type='button'
          size={'small'}
          onClick={handleRoutingProfile}
        >
          {t(
            'routingProfile.addRoutingProfile.successCreatedRouting.btn-confirm'
          )}
        </ButtonStyled>
      </FooterStage>
    </ListScreenAdd>
  );
};
