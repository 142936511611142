import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';
import {
  focusedFieldArea,
  isSavedFlow,
  selectedNode,
  showMenuNodes,
  templateSelected,
  variablesList,
} from '@/store/FlowBuilder';
import { InputComponent } from '@/components/FormComponents/InputComponent';
import { ButtonStyled } from '@/components/FormComponents/ButtonComponent';
import { MenuItem } from '@mui/material';
import { FooterAddNode, FormMenuNode, MainAddNode } from './styled';
import { SelectComponent } from '@/components/FormComponents/SelectInput';
import { FieldConditions } from './FieldConditions';
import { ItemConditional } from './ItemConditional';
import { checkLabelExistiNode } from '@/components/pages/FlowBuilder/utils/checkNameNodes';
import { errorToast } from '@/components/FormComponents/Toast';
import { useTranslation } from 'react-i18next';
import { TitleMenuNode } from '../TitleMenuNode';
import { ConditionItem } from './FieldConditions/styled';

const dataOptionsCondition = ['COD_LIG'];

export const ConditionalForm = ({ dataNodes }) => {
  const { t } = useTranslation();
  const [, setFocusedArea] = useAtom(focusedFieldArea);
  const [, setIsSaved] = useAtom(isSavedFlow);

  const [template] = useAtom(templateSelected);
  const globalVars = template.variablesGlobal;
  const [listVariables] = useAtom(variablesList);

  const [, setShowMenu] = useAtom(showMenuNodes);
  const [selected, setSelected] = useAtom(selectedNode);

  const [labelNode, setLabelNode] = useState('');
  const [labelNodeError, setLabelNodeError] = useState('');

  const [conditionType, setConditionType] = useState('');
  const [conditionTypeError, setConditionTypeError] = useState('');

  const [allErrors, setAllErros] = useState('');

  const [conditionsList, setConditionsList] = useState([]);
  const [conditionSelected, setConditionSelected] = useState({});

  const [dataOptionsConditions, setDataOptionsConditions] = useState([]);

  const { nodes, setNodes, edges, setEdges } = dataNodes;
  const { id, data } = selected;

  useEffect(() => {
    const mapVarsContext = listVariables
      ? listVariables.map((item) => {
          return { name: item.name, parent: false };
        })
      : [];

    const mapVarsGlobal = globalVars
      ? globalVars.map((item) => {
          return { name: item.name.toUpperCase(), parent: 'global' };
        })
      : [];

    const updateVariables = (mapVars) => {
      setDataOptionsConditions(() => [
        ...mapVarsContext,
        ...mapVars,
        ...mapVarsGlobal,
      ]);
    };

    if (dataOptionsCondition && dataOptionsCondition.length > 0) {
      const mapVars = dataOptionsCondition.map((item) => {
        return { name: item, parent: false };
      });
      updateVariables(mapVars);
    } else {
      updateVariables([]);
    }
  }, [listVariables, dataOptionsCondition, globalVars]);

  const validateLabelNode = (value) => {
    if (!value.trim()) {
      setLabelNodeError(
        t('flowBuilder.flow.nodes.conditions.menu.msg-name-required')
      );
    } else if (value.length > 50) {
      setLabelNodeError(
        t('flowBuilder.flow.nodes.conditions.menu.msg-name-length', {
          length: '50',
        })
      );
    } else if (!/^[_a-zA-Z0-9]*$/.test(value)) {
      setLabelNodeError(
        t('flowBuilder.flow.nodes.conditions.menu.msg-name-letters')
      );
    } else {
      setLabelNodeError('');
    }
    setLabelNode(value);
  };

  const validateConditionType = (value) => {
    if (!value.trim()) {
      setConditionTypeError(
        t('flowBuilder.flow.nodes.conditions.menu.msg-condition-required')
      );
    } else {
      setConditionTypeError('');
    }
    setConditionType(value);
  };

  useEffect(() => {
    if (conditionsList.length > 0 && allErrors) {
      setAllErros('');
    }
  }, [conditionsList]);

  useEffect(() => {
    if (selected) {
      setLabelNode(data.label);
      setConditionType(data.typeCondition);
      setConditionsList(data.conditionsList);
    }
  }, [selected]);

  const renderPositionSubNode = (index) => {
    if (index <= 0) {
      return {
        x: 10,
        y: 80,
      };
    }
    return {
      x: 10,
      y: 80 + index * 30,
    };
  };

  const closeMenu = () => {
    setShowMenu(false);
    setLabelNode('');
    setSelected(null);
  };

  const handleChildClick = (e) => {
    e.stopPropagation();
  };

  const handleSubmitNewNode = (e) => {
    e.preventDefault();

    const checkLabel = checkLabelExistiNode(id, labelNode, nodes);

    if (checkLabel) {
      return errorToast(
        t('flowBuilder.flow.nodes.conditions.menu.msg-name-used')
      );
    }

    if (conditionsList.length === 0) {
      setAllErros(true);
      return;
    }

    if (labelNode === '') {
      setLabelNodeError(
        t('flowBuilder.flow.nodes.conditions.menu.msg-name-required')
      );
      return;
    }

    if (conditionType === '0' || conditionType === '') {
      setConditionTypeError(
        t('flowBuilder.flow.nodes.conditions.menu.msg-type-required')
      );
      return;
    }

    const filteredNode = nodes.map((node) =>
      node.id === id
        ? {
            ...node,
            data: {
              ...node.data,
              label: labelNode,
              typeCondition: conditionType,
              conditionsList: conditionsList,
            },
          }
        : node
    );

    const filteredSubNode = filteredNode.map((node) =>
      node.parentNode === id ? null : node
    );
    setNodes(filteredSubNode.filter(Boolean));

    const new_Id = new Date().getTime();
    if (conditionsList.length > 0) {
      const dataSubItemsConditions = conditionsList.map((item, i) => {
        const filteredDataSubNode = nodes.filter(
          (node) => node.parentNode === id
        );

        const checkSubNodes = filteredDataSubNode.filter(
          (itemCheck) =>
            itemCheck.data.label === item.condition &&
            itemCheck.data.value === item.value
        );

        const subNode = {
          id:
            checkSubNodes.length === 0
              ? `sub_id_${id}_${new_Id * i}`
              : checkSubNodes[0].id,
          type: 'subNode',
          data: {
            label: item.condition,
            value: item.value,
            handler: 'successCondition',
          },
          position: renderPositionSubNode(i),
          parentNode: id,
          extent: 'parent',
          className: 'nodrag',
          selectable: false,
        };

        return subNode;
      });

      const checkErrorSubNodes = filteredNode.filter(
        (item) => item.data.label === 'Error' && item.parentNode === id
      );

      const errorSub = {
        ...checkErrorSubNodes[0],
        position: {
          x: 10,
          y: 80 + conditionsList.length * 30,
        },
      };

      dataSubItemsConditions.push(errorSub);

      dataSubItemsConditions.map((item) =>
        dataNodes.setNodes((nds) => nds.concat(item))
      );
    }
    setIsSaved(false);

    closeMenu();
  };

  const handleRemoveCondition = (idCondition) => {
    const getItemRemove = conditionsList.filter(
      (item) => item.id === idCondition
    );

    const filteredDataSubNode = nodes
      .filter(
        (node) =>
          node.parentNode === id &&
          node.data.label === getItemRemove[0].condition &&
          node.data.value === getItemRemove[0].value
      )
      .map((item) => item.id)
      .toString();

    const filterEdge = edges.filter(
      (item) => item.source !== filteredDataSubNode
    );

    setEdges(filterEdge);

    const getItemToRemove = conditionsList.filter(
      (item) => item.id !== idCondition
    );
    setConditionsList(getItemToRemove);
  };

  return (
    <FormMenuNode
      onClick={handleChildClick}
      onSubmit={handleSubmitNewNode}
      id='formConditional'
      name='formConditional'
    >
      <MainAddNode>
        <TitleMenuNode
          title={t('flowBuilder.flow.nodes.conditions.menu.title')}
          description={t('flowBuilder.flow.nodes.conditions.description')}
        />

        <InputComponent
          label={t('flowBuilder.flow.nodes.conditions.menu.label-name')}
          value={labelNode}
          onChange={(e) => validateLabelNode(e.target.value)}
          autoComplete='off'
          helperText={labelNodeError}
          error={Boolean(labelNodeError)}
          onFocus={() => setFocusedArea(true)}
          onBlur={() => setFocusedArea(false)}
        />

        <SelectComponent
          labelSelect={t(
            'flowBuilder.flow.nodes.conditions.menu.label-condition'
          )}
          value={conditionType}
          onChange={(e) => validateConditionType(e.target.value)}
          helperText={conditionTypeError}
          error={Boolean(conditionTypeError)}
        >
          <MenuItem value={'0'} disabled dense>
            {t('flowBuilder.flow.nodes.conditions.menu.option-condition')}
          </MenuItem>

          {dataOptionsConditions &&
            dataOptionsConditions.map((item, i) => (
              <ConditionItem key={i} value={item.name} dense>
                {item.name}{' '}
                {item.parent ? (
                  <span style={{ fontSize: '0.75rem' }}>System</span>
                ) : (
                  ''
                )}
              </ConditionItem>
            ))}
        </SelectComponent>
        <div className='dividerItem'></div>

        <FieldConditions
          dataConditions={{
            conditionsList,
            setConditionsList,
            conditionSelected,
            setConditionSelected,
            dataOptionsConditions,
          }}
        />
        <div className='dividerItem'></div>
        <div className='itens_valid_input'>
          <h4>
            {t('flowBuilder.flow.nodes.conditions.menu.title-listCondition')}
          </h4>
          {conditionsList.length > 0 &&
            conditionsList.map((item) => {
              return (
                <ItemConditional
                  key={item.id}
                  typeCondition={item.condition}
                  valueCondition={item.value}
                  handleRemoveCondition={() => handleRemoveCondition(item.id)}
                />
              );
            })}
          {conditionsList.length === 0 && (
            <div className='contentMessageList'>
              <span>
                {t('flowBuilder.flow.nodes.conditions.menu.title-noCondition')}
              </span>
            </div>
          )}
        </div>
      </MainAddNode>

      <FooterAddNode>
        <ButtonStyled
          variantstyle={'secondary'}
          size={'small'}
          onClick={closeMenu}
        >
          {t('flowBuilder.flow.nodes.conditions.menu.btn-cancel')}
        </ButtonStyled>
        <ButtonStyled
          type='submit'
          size={'small'}
          disabled={Boolean(
            labelNodeError ||
              allErrors ||
              labelNode === '' ||
              conditionsList.length === 0
          )}
        >
          {t('flowBuilder.flow.nodes.conditions.menu.btn-updated')}
        </ButtonStyled>
      </FooterAddNode>
    </FormMenuNode>
  );
};
