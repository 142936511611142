export async function getVariablesFlow() {
  try {
    const data = [
      {
        id: '0bdc3fdc-4164-4aa7-a2f2-2551af88d83e',
        name: 'TESTE',
        value: 'TESTE',
        parent: 'global-var',
        referNode: false,
      },
      {
        id: 'd88a87e3-7b2e-453e-8ea7-a90a73532cc2',
        name: 'DDD',
        value: 'DDD',
        parent: 'global-var',
        referNode: false,
      },
      {
        id: '12a64458-5d8b-4460-8575-479ff7f58ebf',
        name: 'FONE',
        value: 'FONE',
        parent: 'global-var',
        referNode: false,
      },
      {
        id: '4df3f5b3-089c-4328-8dcb-6bdacc7d19c6',
        name: 'NOME',
        value: 'NOME',
        parent: 'global-var',
        referNode: false,
      },
      {
        id: '107da43e-4019-47cc-bd06-78e8d84c89f6',
        name: 'COD_LINK_INT',
        value: 'COD_LINK_INT',
        parent: 'global-var',
        referNode: false,
      },

      {
        id: '0ca22c80-5c69-4646-b714-9e657fe87fd8',
        name: 'COD_LINK_CHAR',
        value: 'COD_LINK_CHAR',
        parent: 'global-var',
        referNode: false,
      },
      {
        id: '0c4e1075-12a0-4b24-9a89-150d264f6ba8',
        name: 'CONTRATO',
        value: 'CONTRATO',
        parent: 'global-var',
        referNode: false,
      },
      {
        id: '07b022af-8628-4dd9-a19e-e0cbefcdbe8c',
        name: 'CPF_COMPLETO',
        value: 'CPF_COMPLETO',
        parent: 'global-var',
        referNode: false,
      },
      {
        id: 'fb958480-2ea6-49f3-a2e8-0eb8fc72ce02',
        name: 'PRIMEIRO_NOME',
        value: 'PRIMEIRO_NOME',
        parent: 'global-var',
        referNode: false,
      },
      {
        id: 'b06a24d8-8d13-48b1-8846-8f6822a5da95',
        name: 'TP_TELEFONE',
        value: 'TP_TELEFONE',
        parent: 'global-var',
        referNode: false,
      },
      {
        id: '3508ddac-f6d8-4f95-a856-ad67dcdc5dfc',
        name: 'CPF_3',
        value: 'CPF_3',
        parent: 'global-var',
        referNode: false,
      },
      {
        id: '95d663db-5b8b-4776-9665-7b17074c85e6',
        name: 'VALOR',
        value: 'VALOR',
        parent: 'global-var',
        referNode: false,
      },
      {
        id: '341813af-23f1-417a-a492-a5fe6e65c8ac',
        name: 'CORINGA_1',
        value: 'CORINGA_1',
        parent: 'global-var',
        referNode: false,
      },
      {
        id: 'd1f28662-5693-4bb3-b880-a5163a6c4e8d',
        name: 'CORINGA_2',
        value: 'CORINGA_2',
        parent: 'global-var',
        referNode: false,
      },
      {
        id: 'c866a2f6-033b-4342-b80b-6ce3a549ea8b',
        name: 'CORINGA_3',
        value: 'CORINGA_3',
        parent: 'global-var',
        referNode: false,
      },
      {
        id: 'c1cdf3c0-b82d-423d-8c20-2ff5e9499644',
        name: 'CORINGA_4',
        value: 'CORINGA_4',
        parent: 'global-var',
        referNode: false,
      },
      {
        id: '82edd21c-8907-4883-a941-a91e9c22cffe',
        name: 'CORINGA_5',
        value: 'CORINGA_5',
        parent: 'global-var',
        referNode: false,
      },
      {
        id: '3722a675-e840-451d-982e-0ebaa8693fc2',
        name: 'CORINGA_6',
        value: 'CORINGA_6',
        parent: 'global-var',
        referNode: false,
      },
      {
        id: '56b79f42-aa26-405a-af30-1aa1d240a6a2',
        name: 'CORINGA_7',
        value: 'CORINGA_7',
        parent: 'global-var',
        referNode: false,
      },
      {
        id: '375a32af-efd8-46c0-9ec8-48fce3c05684',
        name: 'CORINGA_8',
        value: 'CORINGA_8',
        parent: 'global-var',
        referNode: false,
      },
      {
        id: 'eeae909f-96f7-4129-b49c-92dced32e13c',
        name: 'CORINGA_9',
        value: 'CORINGA_9',
        parent: 'global-var',
        referNode: false,
      },
      {
        id: 'd3bb5ec0-7c69-4095-92ab-268c7a6d6b8f',
        name: 'CORINGA_10',
        value: 'CORINGA_10',
        parent: 'global-var',
        referNode: false,
      },
      {
        id: '8107f8a0-f934-4ebe-8d26-bb7f77a59b6e',
        name: 'FILA_TRANSFER',
        value: 'FILA_TRANSFER',
        parent: 'global-var',
        referNode: false,
      },
    ];

    return new Promise((resolve) => {
      setTimeout(() => resolve(data), 500);
    });
  } catch (err) {
    throw new Error(err?.response?.data?.message);
  }
}
