import { useAtom } from 'jotai';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  focusedFieldArea,
  isSavedFlow,
  selectedNode,
  showMenuNodes,
  templateSelected,
  variablesList,
} from '@/store/FlowBuilder';
import { InputComponent } from '@/components/FormComponents/InputComponent';
import { ButtonStyled } from '@/components/FormComponents/ButtonComponent';
import {
  FieldInputUrl,
  FooterAddNode,
  FormMenuNode,
  MainAddNode,
} from './styled';
import { SelectComponent } from '@/components/FormComponents/SelectInput';
import { CircularProgress, Grid, MenuItem, Stack } from '@mui/material';
import { useApiDataFlow } from '@/hooks/FlowBuilder/useApiDataFlow';
import { DialogComponent } from '@/components/DialogComponent';
import { ResponseDataApiFlow } from './ResponseDataApiFlow';
import { errorToast } from '@/components/FormComponents/Toast';
import { PiArrowBendDownRightBold } from 'react-icons/pi';
import { shortNameRender } from '@/components/pages/FlowBuilder/utils/shortNameRender';
import { ParameterField } from './ParameterField';
import { BodyField } from './BodyField';
import { HeaderField } from './HeaderField';
import { checkLabelExistiNode } from '@/components/pages/FlowBuilder/utils/checkNameNodes';
import { SoundRateField } from './SoundRateField';
import { useTranslation } from 'react-i18next';
import { TitleMenuNode } from '../TitleMenuNode';
import { WaitResponse } from './WaitResponse';
import { BiPlus } from 'react-icons/bi';

export const DataApi = ({ dataNodes }) => {
  const { t } = useTranslation();
  const {
    handleApiData,
    loadingApiData,
    generateURLWithParams,
    generateHeadersRequest,
    generateBodyRequest,
    checkFieldHaveValue,
    listAllVars,
  } = useApiDataFlow();

  const [, setFocusedArea] = useAtom(focusedFieldArea);
  const [, setIsSaved] = useAtom(isSavedFlow);
  const [, setShowMenu] = useAtom(showMenuNodes);
  const [selected, setSelected] = useAtom(selectedNode);

  const [labelNode, setLabelNode] = useState('');
  const [labelNodeError, setLabelNodeError] = useState('');

  const [urlNode, setUrlNode] = useState('');
  const [urlNodeError, setUrlNodeError] = useState('');

  const [methodNode, setMethodNode] = useState('GET');

  const [headersInputs, setHeadersInputs] = useState([]);
  const [parametersInputs, setParametersInputs] = useState([]);
  const [bodyInputs, setBodyInputs] = useState([]);
  const [soundRate, setSoundRate] = useState(false);
  const [waitResponse, setWaitResponse] = useState(false);

  const [dataResponseTest, setDataResponseTest] = useState(null);
  const [showResponseData, setShowResponseData] = useState(false);

  const { nodes, setNodes } = dataNodes;
  const { id, data } = selected;

  // AREA RENDER VARS URL
  const selectGlobalRef = useRef(null);
  const [template] = useAtom(templateSelected);
  const globalVars = template.variablesGlobal;
  const [listVariables] = useAtom(variablesList);
  const [variables, setVariables] = useState([]);
  const [showGlobalVariables, setShowGlobalVariables] = useState(false);
  const [selectedVar, setSelectedVar] = useState('');

  useEffect(() => {
    const mapVarsContext = listVariables
      ? listVariables.map((item) => {
          return { name: item.name, value: item.name, parent: false };
        })
      : [];

    const mapVarsGlobal = globalVars
      ? globalVars.map((item) => {
          return { name: item.name, value: item.value, parent: item.parent };
        })
      : [];

    setVariables(() => [...mapVarsContext, ...mapVarsGlobal]);
  }, [listVariables, globalVars, template]);

  const handleOpenListVars = () => {
    return setShowGlobalVariables(true);
  };

  const validateSelectedVar = (value) => {
    setUrlNode((previousState) => previousState + `{{${value}}}`);
    setSelectedVar('0');
  };

  // END RENDER VARS

  useEffect(() => {
    if (selected) {
      setLabelNode(data?.label ?? '');
      setMethodNode(data?.method ?? 'GET');
      setUrlNode(data?.url ?? '');
      setSoundRate(data?.soundRate ?? false);
      setHeadersInputs(data?.headers ?? []);
      setParametersInputs(data?.parameters ?? []);
      setBodyInputs(data?.body ?? []);
      setWaitResponse(data?.waitApi ?? false);
    }
  }, [selected]);

  useEffect(() => {
    return () => {
      setUrlNodeError('');
    };
  }, [selected]);

  const validateLabelNode = (value) => {
    if (!value.trim()) {
      setLabelNodeError(
        t('flowBuilder.flow.nodes.dataApi.menu.msg-name-required')
      );
    } else if (value.length > 50) {
      setLabelNodeError(
        t('flowBuilder.flow.nodes.dataApi.menu.msg-name-length', {
          length: '50',
        })
      );
    } else if (!/^[_a-zA-Z0-9]*$/.test(value)) {
      setLabelNodeError(
        t('flowBuilder.flow.nodes.dataApi.menu.msg-name-letters')
      );
    } else {
      setLabelNodeError('');
    }
    setLabelNode(value);
  };

  const validateUri = (value) => {
    if (!value.trim()) {
      setUrlNodeError(
        t('flowBuilder.flow.nodes.dataApi.menu.msg-url-required')
      );
      setUrlNodeError('');
    }
    setUrlNode(value);
  };

  const closeMenu = () => {
    setShowMenu(false);
    setWaitResponse(false);
    setLabelNode('');
    setSelected(null);
    setHeadersInputs([]);
    setBodyInputs([]);
  };

  const handleChildClick = (e) => {
    e.stopPropagation();
  };

  const ListByNodeId = useCallback(
    () => listAllVars.filter((item) => item.referNode === id),
    [listAllVars, id]
  );

  const filterListByNodeId = ListByNodeId();

  const handleSubmitNewNode = (e) => {
    e.preventDefault();

    if (
      checkFieldHaveValue(headersInputs) ||
      checkFieldHaveValue(parametersInputs) ||
      checkFieldHaveValue(bodyInputs)
    ) {
      return errorToast(t('flowBuilder.flow.nodes.dataApi.menu.msg-name-used'));
    }

    const checkLabel = checkLabelExistiNode(id, labelNode, nodes);

    if (checkLabel) {
      return errorToast(
        t('flowBuilder.flow.nodes.dataApi.menu.msg-variable-define')
      );
    }

    const filteredNode = nodes.map((node) => {
      if (node.id === id) {
        const dataRequest = {
          url: generateURLWithParams(parametersInputs, urlNode).urlResult,
          method: methodNode,
          headers: generateHeadersRequest(headersInputs),
          body: generateBodyRequest(bodyInputs),
        };

        return {
          ...node,
          data: {
            ...node.data,
            label: labelNode,
            url: urlNode,
            method: methodNode,
            headers: headersInputs,
            parameters: parametersInputs,
            body: bodyInputs,
            configRequest: dataRequest,
            soundRate: soundRate,
            response: filterListByNodeId,
            waitApi: waitResponse,
          },
        };
      }
      return node;
    });

    setNodes(filteredNode);
    setIsSaved(false);
    closeMenu();
  };

  const handleRequest = async () => {
    if (
      checkFieldHaveValue(headersInputs) ||
      checkFieldHaveValue(parametersInputs) ||
      checkFieldHaveValue(bodyInputs)
    ) {
      return errorToast(
        t('flowBuilder.flow.nodes.dataApi.menu.msg-error-create')
      );
    }

    const dataRequest = {
      url: generateURLWithParams(parametersInputs, urlNode).urlResultValue,
      method: methodNode,
      headers: generateHeadersRequest(headersInputs),
      body: generateBodyRequest(bodyInputs),
    };

    const response = await handleApiData(dataRequest);

    setDataResponseTest(response);
    setShowResponseData(true);
  };

  return (
    <FormMenuNode
      onClick={handleChildClick}
      onSubmit={handleSubmitNewNode}
      id='DataApiForm'
      name='DataApiForm'
    >
      <MainAddNode>
        <TitleMenuNode
          title={t('flowBuilder.flow.nodes.dataApi.menu.title')}
          description={t('flowBuilder.flow.nodes.dataApi.description')}
        />
        <InputComponent
          label={t('flowBuilder.flow.nodes.dataApi.menu.label-name')}
          value={labelNode}
          onChange={(e) => validateLabelNode(e.target.value)}
          inputProps={{ maxLength: 51 }}
          helperText={labelNodeError}
          error={Boolean(labelNodeError)}
          onFocus={() => setFocusedArea(true)}
          onBlur={() => setFocusedArea(false)}
        />

        <Stack width={'100%'}>
          <SelectComponent
            defaultValue='0'
            labelSelect={t(
              'flowBuilder.flow.nodes.playPrompt.menu.label-global-vars'
            )}
            inputRef={selectGlobalRef}
            open={showGlobalVariables}
            onClose={() => setShowGlobalVariables(false)}
            onOpen={() => setShowGlobalVariables(true)}
            value={selectedVar}
            onChange={(event) => validateSelectedVar(event.target.value)}
          >
            <MenuItem value={'0'} disabled dense>
              {t('flowBuilder.flow.nodes.playPrompt.menu.option-variable')}
            </MenuItem>

            {variables.length > 0 &&
              variables.map((item, i) => {
                return (
                  item.parent && (
                    <MenuItem key={i} value={item.value} dense>
                      {item.value}
                    </MenuItem>
                  )
                );
              })}
          </SelectComponent>
        </Stack>

        <Grid container spacing={1}>
          <Grid item xs={3}>
            <SelectComponent
              labelSelect={t(
                'flowBuilder.flow.nodes.dataApi.menu.label-method'
              )}
              value={methodNode}
              onChange={(event) => setMethodNode(event.target.value)}
            >
              <MenuItem value={'GET'} dense>
                GET
              </MenuItem>
              <MenuItem value={'POST'} dense>
                POST
              </MenuItem>
            </SelectComponent>
          </Grid>
          <Grid item xs={9}>
            <FieldInputUrl>
              <InputComponent
                label={t('flowBuilder.flow.nodes.dataApi.menu.label-url')}
                value={urlNode}
                onChange={(e) => validateUri(e.target.value)}
                inputProps={{ maxLength: 251 }}
                helperText={urlNodeError}
                error={Boolean(urlNodeError)}
                placeholder={'https://api.com'}
                onFocus={() => setFocusedArea(true)}
                onBlur={() => setFocusedArea(false)}
              />

              <div className='btn_play_prompt'>
                <div className='item-btn'>
                  <ButtonStyled
                    size={'small'}
                    onClick={handleOpenListVars}
                    title={t(
                      'flowBuilder.flow.nodes.playPrompt.menu.title-btn-add-global'
                    )}
                    sx={{
                      width: '20px',
                      minWidth: '10px',
                      height: '20px',
                      borderRadius: '50px',
                    }}
                  >
                    <BiPlus />
                  </ButtonStyled>
                </div>
              </div>
            </FieldInputUrl>
          </Grid>
        </Grid>

        <Stack width={'100%'} spacing={1.4}>
          <div className='dividerItem'></div>

          <HeaderField
            headersInputs={headersInputs}
            listVariables={listAllVars}
            setFocusedArea={setFocusedArea}
            setHeadersInputs={setHeadersInputs}
          />
          <div className='dividerItem'></div>
          <ParameterField
            listVariables={listAllVars}
            parametersInputs={parametersInputs}
            setParametersInputs={setParametersInputs}
            setFocusedArea={setFocusedArea}
          />
          {methodNode === 'POST' && (
            <>
              <div className='dividerItem'></div>
              <BodyField
                listVariables={listAllVars}
                bodyInputs={bodyInputs}
                setBodyInputs={setBodyInputs}
                setFocusedArea={setFocusedArea}
              />
            </>
          )}
          <div className='dividerItem'></div>

          <SoundRateField soundRate={soundRate} setSoundRate={setSoundRate} />
          {soundRate && (
            <>
              <div className='dividerItem'></div>
              <WaitResponse value={waitResponse} setValue={setWaitResponse} />
            </>
          )}

          <div className='dividerItem'></div>
        </Stack>
        <Stack
          width={'100%'}
          direction={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <h4>
            {t('flowBuilder.flow.nodes.dataApi.menu.title-captureResponse')}:
          </h4>
          {filterListByNodeId.length === 0 ? (
            <ButtonStyled
              variantstyle={'secondary'}
              size={'small'}
              type='button'
              onClick={handleRequest}
              disabled={urlNodeError || urlNode === ''}
            >
              {!loadingApiData ? (
                t('flowBuilder.flow.nodes.dataApi.menu.btn-send-request')
              ) : (
                <CircularProgress size={20} />
              )}
            </ButtonStyled>
          ) : (
            <ButtonStyled
              variantstyle={'primary'}
              size={'small'}
              type='button'
              onClick={handleRequest}
            >
              {!loadingApiData ? (
                t('flowBuilder.flow.nodes.dataApi.menu.btn-edit-response')
              ) : (
                <CircularProgress size={20} />
              )}
            </ButtonStyled>
          )}
        </Stack>

        <Stack
          width={'100%'}
          direction={'column'}
          alignItems={'flex-start'}
          justifyContent={'space-between'}
          className='content-list-vars'
          spacing={1.4}
        >
          <h4>
            {t('flowBuilder.flow.nodes.dataApi.menu.title-listVariables')}:
          </h4>
          <ul>
            {listAllVars.length > 0 &&
              filterListByNodeId.map((item) => {
                return (
                  <li key={item.id} title={`${item.name}=${item.value}`}>
                    <PiArrowBendDownRightBold />
                    <span>
                      <strong>{shortNameRender(item.name, 20)}</strong> ={' '}
                      {shortNameRender(item.value, 20)}
                    </span>
                  </li>
                );
              })}
          </ul>
        </Stack>
      </MainAddNode>
      <FooterAddNode>
        <ButtonStyled
          variantstyle={'secondary'}
          size={'small'}
          onClick={closeMenu}
        >
          {t('flowBuilder.flow.nodes.dataApi.menu.btn-cancel')}
        </ButtonStyled>
        <ButtonStyled
          type='submit'
          size={'small'}
          disabled={Boolean(labelNodeError) || labelNode.length === 0}
        >
          {t('flowBuilder.flow.nodes.dataApi.menu.btn-updated')}
        </ButtonStyled>
      </FooterAddNode>

      <DialogComponent
        open={showResponseData}
        onClose={() => setShowResponseData(false)}
        fullWidth={true}
        maxWidth={'lg'}
      >
        <ResponseDataApiFlow
          dataResponse={dataResponseTest}
          nodes={nodes}
          setNodes={setNodes}
          onClose={() => setShowResponseData(false)}
        />
      </DialogComponent>
    </FormMenuNode>
  );
};
