import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ContainerAddScreen = styled(motion.div)`
  width: 100%;
  height: auto;
  overflow-y: auto;
  padding: 3rem 2rem;
`;

export const FormAddScreen = styled.form`
  width: 100%;
  height: calc(100vh - 138px);
  display: grid;
  grid-template-rows: minmax(auto, 30px) 1fr;
`;

export const MainAddScreen = styled.div`
  width: 100%;
  height: 100%;
  max-height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 3rem;
`;

export const FooterAddScreen = styled.div`
  width: 100%;
  height: 100%;
  min-height: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 1rem;

  @media (max-height: 768px) {
    padding-bottom: 10px;
  }
`;
