import { Stack } from '@mui/system';
import { useAtom } from 'jotai';
import { CardLatestList } from './styled';
import { renderDate } from '@/utils/formatsDateAndTime';
import { FaRegCircleDot } from 'react-icons/fa6';
import { useTranslation } from 'react-i18next';
import { shortNameRender } from '@/components/pages/FlowBuilder/utils/shortNameRender';
import { useEffect, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { isCloseItem } from '@/store/NavigationDashboard';

export const CardFlow = ({ dataCard, handleTemplate }) => {
  const { t } = useTranslation();
  const matchesLarge = useMediaQuery('(max-width:1370px)');
  const [isClose] = useAtom(isCloseItem);

  const [totalLengthTitle, setTotalLengthTitle] = useState(34);

  useEffect(() => {
    if (matchesLarge) {
      return setTotalLengthTitle(28);
    } else {
      return setTotalLengthTitle(isClose ? 40 : 50);
    }
  }, [matchesLarge, isClose]);

  const renderDataShortLabel = (labelNode, subSplit) => {
    if (labelNode.length > subSplit) {
      const shortLabel = labelNode.substring(0, subSplit);
      return `${shortLabel}...`;
    }
    return labelNode;
  };

  return (
    <CardLatestList
      onClick={() => handleTemplate(dataCard.relatedObjectId)}
      title={dataCard.nameIVR ?? ''}
    >
      <Stack justifyContent={'space-between'} direction={'row'}>
        <Stack
          direction={'row'}
          spacing={1}
          alignItems={'center'}
          width={'70%'}
          overflow={'hidden'}
        >
          <FaRegCircleDot />
          <h5>{shortNameRender(dataCard.nameIVR, totalLengthTitle)}</h5>
        </Stack>
        <span className='content_render_data'>
          {renderDate(dataCard.lastUpdate)}
        </span>
      </Stack>
      <div className='description-card'>
        <Stack
          direction={'row'}
          spacing={1}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <p>
            {dataCard.descriptionIVR
              ? renderDataShortLabel(dataCard.descriptionIVR, 60)
              : renderDataShortLabel(
                  t('flowBuilder.recentFlow.label-no-description'),
                  60
                )}
          </p>
          {dataCard.published ? (
            <span>
              {t('flowBuilder.recentFlow.status-card-flow-published')}
            </span>
          ) : (
            <span className='not_publish'>
              {t('flowBuilder.recentFlow.status-card-flow-notPublished')}
            </span>
          )}
        </Stack>
      </div>
    </CardLatestList>
  );
};
