import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/system';
import { useAtom } from 'jotai';
import * as yup from 'yup';
import useFormControl from '@/hooks/useFormControl';
import { ButtonStyled } from '@/components/FormComponents/ButtonComponent';
import { FooterEditGroup, FormEditGroupDDD, MainEditGroup } from './styled';
import { ControlInput } from '@/components/FormComponents/InputComponent';
import { TableDDD } from './TableDDD';
import { dataSelectedDDDManager } from '@/store/DialingStore';
import { user } from '@/store/AuthenticatorStore';
import { useDDDManager } from '@/hooks/dialControl/DDDManager';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';

export const EditGroupDDD = ({ controlStage }) => {
  const { t } = useTranslation();
  const { mutateUpdateGroupDDD, loadingUpdateGroupDDD } = useDDDManager();

  const renderText = (spell) => {
    return t(`dialControl.dddManager.create.${spell}`);
  };

  const [currentUser] = useAtom(user);

  const [selectedDDDManager, setSelectedDDDManager] = useAtom(
    dataSelectedDDDManager
  );

  const handleBackGroup = () => {
    controlStage.toStage(0);
    setSelectedDDDManager(null);
  };

  const addDDDManageSchema = yup.object().shape({
    nameGroup: yup.string().required(),
    description: yup.string().required(),
  });

  const { handleSubmit, control, errors, reset } =
    useFormControl(addDDDManageSchema);

  const [rows, setRows] = useState([]);
  const [selected, setSelected] = useState(null);
  const [errorRow, setErrorRow] = useState(null);

  useEffect(() => {
    if (selectedDDDManager) {
      setRows(selectedDDDManager.schedules);
    }
  }, []);

  const dataRows = {
    rows,
    setRows,
    selected,
    setSelected,
    errorRow,
    setErrorRow,
  };

  const handleEditGroupUf = (data) => {
    const dataUpdate = {
      cod_ddd_bloq: data.nameGroup,
      descricao: data.description,
      id_cliente: currentUser.client_id,
    };

    mutateUpdateGroupDDD(dataUpdate, {
      onSuccess: () => {
        handleBackGroup();
        reset();
      },
    });
  };

  return (
    <FormEditGroupDDD
      onSubmit={handleSubmit(handleEditGroupUf)}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      {loadingUpdateGroupDDD && <BackDropCircularLoading />}
      <MainEditGroup>
        <Stack width={'100%'} direction={'column'} spacing={2}>
          <ControlInput
            control={control}
            defaultValue={selectedDDDManager.name}
            nameControl={'nameGroup'}
            label={renderText('label-name')}
            error={Boolean(errors.nameGroup)}
            InputProps={{
              readOnly: true,
            }}
          />
          <ControlInput
            control={control}
            defaultValue={selectedDDDManager.description}
            nameControl={'description'}
            label={renderText('label-description')}
            error={Boolean(errors.description)}
            // InputProps={{
            //   readOnly: true,
            // }}
          />
        </Stack>

        <TableDDD dataRows={dataRows} />
      </MainEditGroup>
      <FooterEditGroup>
        <ButtonStyled
          variantstyle={'secondary'}
          onClick={handleBackGroup}
          size={'small'}
        >
          {renderText('btn-cancel')}
        </ButtonStyled>
        <ButtonStyled
          type='submit'
          size={'small'}
          //   disabled={rows.length === 0 || Boolean(errorRow) || Boolean(selected)}
        >
          {t(`dialControl.ufManager.edit.btn-update`)}
        </ButtonStyled>
      </FooterEditGroup>
    </FormEditGroupDDD>
  );
};
