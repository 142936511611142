import { Stack } from '@mui/system';
import { FaGear } from 'react-icons/fa6';
import { ContentConfig, FooterConfig } from '../../styled';
import { ButtonStyled } from '@/components/FormComponents/ButtonComponent';
import { useTranslation } from 'react-i18next';

export const AddGlobalVariables = ({ changeFlowConfigState }) => {
  const { t } = useTranslation();
  return (
    <ContentConfig>
      <Stack
        direction={'row'}
        spacing={1}
        alignItems={'center'}
        className={'titleConfig'}
      >
        <FaGear />
        <h4> {t('flowBuilder.configs.btn-add-globalVars')}</h4>
      </Stack>

      <Stack
        direction={'column'}
        spacing={1}
        alignItems={'center'}
        height={'60vh'}
      ></Stack>

      <FooterConfig>
        <div className=''></div>
        <Stack direction={'row'} spacing={1}>
          <ButtonStyled
            size='small'
            variantstyle='secondary'
            onClick={() => changeFlowConfigState(0)}
          >
            {t('flowBuilder.configs.btn-cancel')}
          </ButtonStyled>
          <ButtonStyled size='small'>
            {t('flowBuilder.configs.btn-add')}
          </ButtonStyled>
        </Stack>
      </FooterConfig>
    </ContentConfig>
  );
};
