import { Stack } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { ButtonStyled } from '@components/FormComponents/ButtonComponent';
import { ContainerRemoveDisposition, FormRemoveDisposition } from './styled';
import { BackDropCircularLoading } from '@components/layout/LoadingComponent';
import { TitleComponent } from '@components/layout/TitleComponent';
import { useAtom } from 'jotai';
import { dataSelectedDisposition } from '@/store/DialingStore';
import { successToast } from '@/components/FormComponents/Toast';

export const RemoveDisposition = ({ closeDialog, dataRemove }) => {
  const { t } = useTranslation();

  const [dispositionSelected] = useAtom(dataSelectedDisposition);

  const handleRemoveDisposition = (e) => {
    e.preventDefault();
    if (dispositionSelected?.origin === 'ura') {
      dataRemove.mutateDeleteUraDisposition(dispositionSelected?.id, {
        onSuccess: () => {
          successToast(t('dispositions.remove.msg-success-delete'));
          closeDialog();
        },
      });
      return;
    }
    dataRemove.mutateDeleteMailingDisposition(dispositionSelected?.id, {
      onSuccess: () => {
        successToast(t('dispositions.remove.msg-success-delete'));
        closeDialog();
      },
    });
    return;
  };

  const isLoading =
    dataRemove.loadingDeleteUraDisposition ||
    dataRemove.loadingDeleteMailingDisposition;

  return (
    <ContainerRemoveDisposition>
      {isLoading && <BackDropCircularLoading />}

      <TitleComponent
        textTitle={t('dispositions.home.table.title-remove-confirmation')}
        iconTitle='config'
      />

      <FormRemoveDisposition onSubmit={handleRemoveDisposition}>
        <p>
          {t('dispositions.home.table.text-remove-confirmation')}
          <strong></strong>
        </p>
        <Stack direction={`row`} gap={1} justifyContent={'flex-end'}>
          <ButtonStyled
            variantstyle={'secondary'}
            onClick={closeDialog}
            size='size'
          >
            {t('dispositions.home.table.btn-cancel')}
          </ButtonStyled>
          <ButtonStyled type='submit' size='size'>
            {t('dispositions.home.table.btn-confirm')}
          </ButtonStyled>
        </Stack>
      </FormRemoveDisposition>
    </ContainerRemoveDisposition>
  );
};
