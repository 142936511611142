import { useState } from 'react';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';

import { useAgents } from '@hooks/Agents/useAgents';

import {
  currentStateAgentsDial,
  currentUserAgent,
  sessionStateAgent,
} from '@store/AgentsStore';

import { errorToast } from '@components/FormComponents/Toast';
import { ButtonStyled } from '@components/FormComponents/ButtonComponent';
import { InputComponent } from '@components/FormComponents/InputComponent';

import {
  MdOutlineMic,
  MdOutlineMicOff,
  MdPersonPin,
  MdPhoneDisabled,
} from 'react-icons/md';

import {
  ContainerDialAgents,
  ContainerInDial,
  FormNumberToDial,
  TitleAgents,
} from './styled';

export const DialAgent = () => {
  const { t } = useTranslation();
  const { callOptions } = useAgents();
  const [, setCurrentStateAgents] = useAtom(currentStateAgentsDial);
  const [userAgent] = useAtom(currentUserAgent);
  const [currentSession] = useAtom(sessionStateAgent);

  const [inCallDial, setInCallDial] = useState(false);
  const [phoneToDial, setPhoneToDial] = useState('');
  const [isHold, setIsHold] = useState(false);

  const handleDial = (e) => {
    e.preventDefault();

    if (phoneToDial === '') {
      return errorToast('Informe um número');
    }

    const formatNumberNoMask = phoneToDial
      .replace('(', '')
      .replace(')', '')
      .replaceAll(' ', '')
      .replaceAll('_', '');

    if (formatNumberNoMask.length < 10) {
      return errorToast('Informe um número correto');
    }

    const urlToCall = `sip:${formatNumberNoMask}@dialer.voiccelabs.com`;
    userAgent.call(urlToCall, callOptions);
    setInCallDial(true);
  };

  const handleHangUpDial = () => {
    if (currentSession) {
      currentSession.terminate();
    }
    setInCallDial(false);
  };

  const handleHoldDial = () => {
    if (currentSession) {
      if (currentSession.isOnHold().local) {
        currentSession.unhold();
        setIsHold(false);
      } else {
        currentSession.hold();
        setIsHold(true);
      }
    }
  };

  return (
    <ContainerDialAgents
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
    >
      <TitleAgents>
        <MdPersonPin />
        <h2>{t('agents.dial.title')}</h2>
      </TitleAgents>
      {!inCallDial ? (
        <FormNumberToDial onSubmit={handleDial}>
          <div className='contentForm'>
            <InputMask
              mask='(999) 999 9999'
              value={phoneToDial}
              onChange={(e) => setPhoneToDial(e.target.value)}
            >
              {() => <InputComponent label={t('agents.dial.label')} />}
            </InputMask>
          </div>
          <div className='footerForm'>
            <ButtonStyled
              variantstyle={'secondary'}
              onClick={() => setCurrentStateAgents(null)}
            >
              <span>{t('agents.dial.btnCancel')}</span>
            </ButtonStyled>
            <ButtonStyled type='submit'>
              <span>{t('agents.dial.btnDial')}</span>
            </ButtonStyled>
          </div>
        </FormNumberToDial>
      ) : (
        <ContainerInDial>
          <div className='contentInDial'>
            <div className='statusDial'></div>
            <span>
              Em ligação com <strong>{phoneToDial}</strong>
            </span>
          </div>
          <div className='footerInDial'>
            <ButtonStyled variantstyle={'secondary'} onClick={handleHoldDial}>
              {isHold ? <MdOutlineMic /> : <MdOutlineMicOff />}
              {!isHold
                ? t('agents.callInformation.btnMuteCard')
                : t('agents.callInformation.btnUnMuteCard')}
            </ButtonStyled>
            <ButtonStyled onClick={handleHangUpDial}>
              <MdPhoneDisabled />
              {t('agents.callInformation.btnHangUp')}
            </ButtonStyled>
          </div>
        </ContainerInDial>
      )}
    </ContainerDialAgents>
  );
};
