import * as yup from 'yup';
import {
  ContainerLineControl,
  FooterFormCampaign,
  FormCampaignOperator,
  MainFormCampaign,
} from './styled';
import { PanelOperator } from './PanelOperator';
import useFormControl from '@/hooks/useFormControl';
import { ControlSelect } from '@/components/FormComponents/SelectInput';
import { DescriptionInformation } from '@/components/layout/DescriptionInformation';
import { ButtonStyled } from '@/components/FormComponents/ButtonComponent';
import { MenuItem } from '@mui/material';
import { useOperatorControl } from '@/hooks/dialControl/operatorsControl/useOperatorControl';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';
import { useAtom } from 'jotai';
import { dataSelectedLinesControl } from '@/store/LineControl';
import { successToast } from '@/components/FormComponents/Toast';
import { useTranslation } from 'react-i18next';

export const CampaignOperator = ({ controlStage }) => {
  const { t } = useTranslation();
  const [selected] = useAtom(dataSelectedLinesControl);
  const {
    loadingDataOperators,
    // dataOperators,
    mutateUpdateOperators,
    loadingUpdateOperators,
  } = useOperatorControl(controlStage);

  const campaignOperatorSchema = yup.object().shape({
    mobileOperator: yup.string().required(),
    fixedOperator: yup.string().required(),
  });

  const renderTxt = (word) => {
    return t(`dialControl.operatorControl.campaignOperator.${word}`);
  };

  const { handleSubmit, control, errors, reset } = useFormControl(
    campaignOperatorSchema
  );

  const handleUpdateOperators = (data) => {
    const dataUpdate = {
      //   ...selected,
      mobileOperator: data.mobileOperator,
      fixedOperator: data.fixedOperator,
    };
    mutateUpdateOperators(dataUpdate, {
      onSuccess: () => {
        successToast(renderTxt('msg-successUpdate'));
        controlStage.toStage(0);
        reset();
      },
    });
  };

  const handleBackStage = () => {
    controlStage.toStage(0);
    reset();
  };

  const isLoading = loadingDataOperators || loadingUpdateOperators;

  return (
    <ContainerLineControl initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      {isLoading && <BackDropCircularLoading />}
      <PanelOperator controlMainStage={handleBackStage} />
      <FormCampaignOperator onSubmit={handleSubmit(handleUpdateOperators)}>
        <MainFormCampaign>
          <DescriptionInformation textDescription={renderTxt('description')} />
          <ControlSelect
            control={control}
            label={renderTxt('label-mobileOperator')}
            nameControl={'mobileOperator'}
            // defaultValue={selected ? selected.mobileOperator : '1'}
            defaultValue={'1'}
            error={Boolean(errors.mobileOperator)}
          >
            <MenuItem value={'0'} dense disabled>
              {renderTxt('option-mobileOperator')}
            </MenuItem>
            <MenuItem value={'1'} dense>
              {selected.nome_operadora}
            </MenuItem>
            {/* {dataOperators &&
              dataOperators.map((operator) => (
                <MenuItem value={operator.name} key={operator.id}>
                  {operator.name}
                </MenuItem>
              ))} */}
          </ControlSelect>

          <ControlSelect
            control={control}
            label={renderTxt('label-fixedOperator')}
            nameControl={'fixedOperator'}
            // defaultValue={selected.fixedOperator ? selected.fixedOperator : '0'}
            defaultValue={'1'}
            error={Boolean(errors.fixedOperator)}
          >
            <MenuItem value={'0'} dense disabled>
              {renderTxt('option-fixedOperator')}
            </MenuItem>
            <MenuItem value={'1'} dense>
              {selected.nome_operadora}
            </MenuItem>
            {/* {dataOperators &&
              dataOperators.map((operator) => (
                <MenuItem value={operator.name} key={operator.id}>
                  {operator.name}
                </MenuItem>
              ))} */}
          </ControlSelect>
        </MainFormCampaign>
        <FooterFormCampaign>
          <ButtonStyled
            variantstyle={'secondary'}
            size='small'
            onClick={handleBackStage}
          >
            {renderTxt('btn-cancel')}
          </ButtonStyled>
          <ButtonStyled type='submit' size='small'>
            {renderTxt('btn-save')}
          </ButtonStyled>
        </FooterFormCampaign>
      </FormCampaignOperator>
    </ContainerLineControl>
  );
};
