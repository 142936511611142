import { useEffect } from 'react';
import { useAtom } from 'jotai';

import { useTranslation } from 'react-i18next';

import { useDialingQueues } from '@hooks/Dialing/useDialingQueues';

import {
  CodListToAdd,
  dataInfoCampaign,
  listOptionsCampaign,
  selectedQueue,
  valueCheckboxAllPositions,
  valueFieldPhonePosition,
  valueFieldQueue,
} from '@/store/DialingStore';

import { ButtonStyled } from '@components/FormComponents/ButtonComponent';
import { CheckboxWithLabel } from '@components/FormComponents/CheckboxComponent';
import { SelectComponent } from '@components/FormComponents/SelectInput';
import { errorToast, successToast } from '@components/FormComponents/Toast';

import { MenuItem, Skeleton } from '@mui/material';
import { Stack } from '@mui/system';

import {
  ContainerTransferList,
  FooterNewListConfig,
  FormNewListConfig,
} from './styled';
import { DragTransferList } from './DragTransferList';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';

export const NewList = ({ changeFlowDialing }) => {
  const { t } = useTranslation();
  const { mutateNewQueue, loadingNewList, isLoadingCodList } =
    useDialingQueues();
  const [dataCodLists, setDataCodLigs] = useAtom(dataInfoCampaign);
  const [fieldCheckbox, setFieldCheckbox] = useAtom(valueCheckboxAllPositions);
  const [fieldQueue, setFieldQueue] = useAtom(valueFieldQueue);
  const [fieldPhonePosition, setFieldPhonePosition] = useAtom(
    valueFieldPhonePosition
  );

  useEffect(() => {
    return () => {
      setDataCodLigs(null);
      setFieldQueue('');
      setFieldPhonePosition('');
      setFieldCheckbox(false);
    };
  }, []);

  const [selectedInfoQueue] = useAtom(selectedQueue);
  const [codListSelected, setCodListSelected] = useAtom(CodListToAdd);

  const [listCampaign] = useAtom(listOptionsCampaign);

  const numberQueue = selectedInfoQueue && selectedInfoQueue.fila;

  const renderListCodLigs = dataCodLists?.[0]?.codligs ?? null;

  const treatmentPropsCodList = (arrCodList) => {
    const newData = [];
    if (arrCodList.length > 0) {
      arrCodList.map((item) => {
        const separateString = item.split('-');
        newData.push(separateString[0].replace(/\s/g, ''));
      });
    }
    return newData;
  };

  const handleNewListToQueue = (e) => {
    e.preventDefault();
    if (codListSelected.length === 0) {
      errorToast('Select CodLigs');
      return false;
    }

    const dataNewList = {
      queue: numberQueue,
      list: fieldQueue,
      codLig: treatmentPropsCodList(codListSelected),
      phonePosition: !fieldCheckbox ? fieldPhonePosition : 0,
      phonePositionAll: fieldCheckbox,
    };

    mutateNewQueue(dataNewList, {
      onSuccess: () => {
        successToast(t('dialing.newListConfig.messages.successAddedQueue'));
        changeFlowDialing(1);
      },
    });
  };

  const positionsPhone = Array(10).fill(null);

  const isLoading = loadingNewList || isLoadingCodList || !renderListCodLigs;

  function removeDuplicateDispositions(items) {
    const uniqueItems = [];
    if (items) {
      const seenIds = new Set();
      items.forEach((item) => {
        if (!seenIds.has(item.cod_lig)) {
          seenIds.add(item.cod_lig);
          uniqueItems.push(item);
        }
      });
    }

    return uniqueItems;
  }

  return (
    <FormNewListConfig
      onSubmit={handleNewListToQueue}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      {isLoading && <BackDropCircularLoading />}
      <SelectComponent
        labelSelect={t('dialing.newListConfig.label-queue')}
        value={fieldQueue}
        onChange={(event) => setFieldQueue(event.target.value)}
      >
        {listCampaign ? (
          listCampaign.map((opt, i) => {
            return (
              <MenuItem value={Number(opt.lista)} key={i} dense>
                {opt.lista} - {opt.descricao}
              </MenuItem>
            );
          })
        ) : (
          <MenuItem value={0} key={0} dense disabled>
            {t('dialing.newListConfig.label-noList')}
          </MenuItem>
        )}
      </SelectComponent>
      <SelectComponent
        labelSelect={t('dialing.newListConfig.label-telephonePosition')}
        value={fieldPhonePosition}
        disabled={fieldCheckbox}
        onChange={(event) => setFieldPhonePosition(event.target.value)}
      >
        {positionsPhone.map((_, i) => {
          return (
            <MenuItem value={i + 1} key={i}>
              {i + 1}
            </MenuItem>
          );
        })}
      </SelectComponent>
      <CheckboxWithLabel
        label={t('dialing.newListConfig.label-queuePositions')}
        checked={fieldCheckbox}
        onChange={(e) => setFieldCheckbox(e.target.checked)}
      />
      <ContainerTransferList>
        <h2>{t('dialing.newListConfig.title-connectionCodes')}</h2>
        <div className='contentTransfer'>
          {renderListCodLigs ? (
            <DragTransferList
              codeList={removeDuplicateDispositions(renderListCodLigs)}
              changeDataCodeList={setCodListSelected}
            />
          ) : (
            <Skeleton variant='rectangular' width={'100%'} height={'34  0px'} />
          )}
        </div>
      </ContainerTransferList>

      <FooterNewListConfig>
        <ButtonStyled
          //   onClick={() => setCurrentComponent(2)}
          disabled={loadingNewList}
          size={'small'}
        >
          {t('dialing.newListConfig.btn-queueLog')}
        </ButtonStyled>
        <Stack spacing={1} direction='row'>
          <ButtonStyled
            variantstyle='secondary'
            onClick={() => changeFlowDialing(1)}
            disabled={loadingNewList}
            size={'small'}
          >
            {t('dialing.newListConfig.btn-comeBack')}
          </ButtonStyled>
          <ButtonStyled type='submit' disabled={loadingNewList} size={'small'}>
            {t('dialing.newListConfig.btn-apply')}
          </ButtonStyled>
        </Stack>
      </FooterNewListConfig>
    </FormNewListConfig>
  );
};
