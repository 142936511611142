import { useLayoutEffect, useRef, useState } from 'react';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { Tooltip } from '@mui/material';
import { MdEdit } from 'react-icons/md';
import { TablePagination } from '@/components/Tables/TablePagination';
import useWindowDimensions from '@/hooks/useWidth';
import { dataOptionsClients } from '@/store/UsersAgentsStore/userAgentsStore';
import { useSecurityProfileRender } from '@/hooks/Navigator/useSecurityProfileRender';
import { ItemSecurityRow } from '@/components/pages/usersAgents/ListUsers/stages/HomeUsers/TableUsersAgents/styled';

import {
  dataSelectedSecurityProfiles,
  stateRenderSecurityProfiles,
} from '@/store/UsersAgentsStore/securityProfiles';
import {
  ContainerTableSecurity,
  ContainerTableSecurityProfile,
} from './styled';

export const TableSecurityProfile = ({ dataSecurityTable }) => {
  const { t } = useTranslation();
  const { permissionComponent } = useSecurityProfileRender();
  const [, setCurrentStateSecurity] = useAtom(stateRenderSecurityProfiles);
  const [, setSelectSecurity] = useAtom(dataSelectedSecurityProfiles);

  const [dataClients] = useAtom(dataOptionsClients);

  const renderClientFilter = (idClient) => {
    const filterClient = dataClients
      .filter((item) => item.id === idClient)
      .map((item) => item.commercial_name)
      .toString();
    return filterClient ?? '-';
  };

  const { height, width } = useWindowDimensions();
  const tableSecurityRef = useRef(null);

  const [heightTable, setHeightTable] = useState(0);

  useLayoutEffect(() => {
    if (tableSecurityRef) {
      const tableMaxWidth = tableSecurityRef.current.offsetHeight;
      const cellMinWidth = width < 500 ? 220 : width > 1440 ? 60 : 90;
      const cellCount = Math.floor(tableMaxWidth / cellMinWidth);
      setHeightTable(cellCount);
    }
  }, [height, width]);

  const renderPermissions = (dataPermissions) => {
    if (!dataPermissions || dataPermissions.length === 0) return [];

    const dataPermission = dataPermissions.map((item) => ({
      name: item.name,
      permission: item.permission,
    }));
    return dataPermission;
  };

  const verifyPermissions = (item) => {
    if (item) {
      const separateItem = item.split('.');
      if (separateItem[1] !== 'ALL') {
        return <ItemSecurityRow>{item}</ItemSecurityRow>;
      }
    }
  };

  const handleEditSecurityProfile = (dataRow) => {
    setSelectSecurity(dataRow);
    setCurrentStateSecurity(1);
  };

  const columnsSecurityProfile = [
    {
      Header: t('securityProfile.homeSecurityProfiles.table.th-first'),
      accessor: 'name',
    },

    {
      Header: 'Cliente',
      accessor: 'client_id',
      Cell: ({ row }) =>
        row.original.client_id
          ? renderClientFilter(row.original.client_id)
          : '-',
    },

    {
      Header: t('securityProfile.homeSecurityProfiles.table.th-third'),
      accessor: 'permissions',
      Cell: ({ row }) => {
        return (
          <ContainerTableSecurity>
            {row.original.permissions.length > 0 &&
              renderPermissions(row.original.permissions).map((item, i) => {
                return (
                  <Tooltip
                    title={item.name}
                    arrow
                    placement='bottom'
                    key={i}
                    style={{ cursor: 'pointer' }}
                  >
                    {verifyPermissions(item.name)}
                  </Tooltip>
                );
              })}
          </ContainerTableSecurity>
        );
      },
    },

    {
      Header: t('securityProfile.homeSecurityProfiles.table.th-fifth'),
      Cell: ({ row }) => (
        <div className='editRow'>
          <motion.button
            whileTap={{ scale: 0.98 }}
            onClick={() => handleEditSecurityProfile(row.original)}
            disabled={!permissionComponent('update')}
          >
            <MdEdit />
          </motion.button>
        </div>
      ),
    },
  ];

  return (
    <ContainerTableSecurityProfile ref={tableSecurityRef}>
      <TablePagination
        dataColumns={columnsSecurityProfile}
        dataTable={dataSecurityTable}
        textNoData={t('routingProfile.homeRoutingProfiles.table.noData')}
        rowsPerPageDisplay={heightTable}
      />
    </ContainerTableSecurityProfile>
  );
};
