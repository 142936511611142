import { useState } from 'react';
import { DragTransferList } from '../DragTransferList';
import { useAtom } from 'jotai';
import { dataNlus, templateSelected } from '@/store/FlowBuilder';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';
import { Stack } from '@mui/system';
import { FaGear } from 'react-icons/fa6';
import { ContentConfig, FooterConfig } from '../../styled';
import { ButtonStyled } from '@/components/FormComponents/ButtonComponent';
import { successToast } from '@/components/FormComponents/Toast';
import { useTranslation } from 'react-i18next';
import { Skeleton } from '@mui/material';
import { useEndpoints } from '@/hooks/FlowBuilder/useEndpoints';

export const ListNlus = ({
  dataNlus: listNlus,
  handleClose,
  changeFlowConfigState,
}) => {
  const { t } = useTranslation();
  const { loadingNlus, isFetchingNlus } = useEndpoints();
  const [nlus] = useAtom(dataNlus);
  const [template, setTemplate] = useAtom(templateSelected);
  const [dataNlusSelected, setDatNlusSelected] = useState([]);

  const renderAvailableList = () => {
    const availableList = nlus
      .map((element) => {
        const filterUsedVars = listNlus.filter(
          (item) => item.name === element.name
        );
        if (filterUsedVars.length > 0) {
          return null;
        }
        return element;
      })
      .filter((item) => Boolean(item));

    return availableList;
  };

  const hasUsedEndpoints = () => {
    const { nodes } = template;

    const listNodes = nodes ? nodes : [];

    const mapListEndpoints = listNlus
      .map((itemSelected) => {
        const dispositionsUsed = listNodes.filter(
          (item) => item.data.nluEndpoint === itemSelected.name
        );
        if (dispositionsUsed.length > 0) {
          return dispositionsUsed[0];
        }
        return false;
      })
      .filter((item) => item)
      .map((item) => item.data.nluEndpoint);

    return mapListEndpoints;
  };

  const handleUpdateEndpoints = () => {
    setTemplate((previousState) => {
      return {
        ...previousState,
        nlus: dataNlusSelected,
      };
    });
    successToast(t('flowBuilder.configs.endpoints.msg-updated-addedEndpoint'));
    handleClose();
    return;
  };

  const isLoading = isFetchingNlus || loadingNlus;

  return (
    <ContentConfig>
      {isLoading && <BackDropCircularLoading />}
      <Stack
        direction={'row'}
        spacing={1}
        alignItems={'center'}
        className={'titleConfig'}
      >
        <FaGear />
        <h4>{t('flowBuilder.configs.endpoints.title-endPoint')}</h4>
      </Stack>
      {nlus.length > 0 ? (
        <DragTransferList
          codeList={renderAvailableList()}
          changeDataCodeList={setDatNlusSelected}
          dataSelected={listNlus}
          checkRemoveData={hasUsedEndpoints()}
          typeList={'Endpoints'}
        />
      ) : (
        <Skeleton
          variant='rectangular'
          width={'100%'}
          height={'100%'}
          sx={{ opacity: 0.4 }}
        />
      )}
      <FooterConfig>
        <ButtonStyled size='small' onClick={() => changeFlowConfigState(1)}>
          {t('flowBuilder.configs.endpoints.btn-add-endPoint')}
        </ButtonStyled>
        <Stack direction={'row'} spacing={1}>
          <ButtonStyled
            size='small'
            variantstyle='secondary'
            onClick={handleClose}
          >
            {t('flowBuilder.configs.btn-cancel')}
          </ButtonStyled>
          <ButtonStyled size='small' onClick={handleUpdateEndpoints}>
            {t('flowBuilder.configs.btn-update')}
          </ButtonStyled>
        </Stack>
      </FooterConfig>
    </ContentConfig>
  );
};
