import { useState } from 'react';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import { currentStateAgentsDial } from '@store/AgentsStore';

import { useCallHistory } from '@hooks/Agents/useCallHistory';

import { InputAdornment } from '@mui/material';
import { InputComponent } from '@components/FormComponents/InputComponent';
import { ButtonStyled } from '@components/FormComponents/ButtonComponent';
import { BackDropCircularLoading } from '@components/layout/LoadingComponent';

import { ContainerCallHistory, ContentTable, FooterCall } from './styled';

import { BiSearch } from 'react-icons/bi';
import { TableCallHistory } from './TableSummary';

export const CallHistory = () => {
  const { t } = useTranslation();
  const { dataCallHistory, loadingCallHistory } = useCallHistory();
  const [filterCallHistory, setFilterCallHistory] = useState('');

  const [, setCurrentStateAgents] = useAtom(currentStateAgentsDial);

  function removeDuplicates(arr) {
    return arr.filter((item, index) => arr.indexOf(item) === index);
  }

  const filterAllTableCallHistory = (dataFilter) => {
    if (dataFilter) {
      const filterTableAgent =
        dataFilter &&
        dataFilter.filter((data) =>
          data.agent.toLowerCase().includes(filterCallHistory.toLowerCase())
        );

      const filterTableList =
        dataFilter &&
        dataFilter.filter((data) =>
          data.list.toLowerCase().includes(filterCallHistory.toLowerCase())
        );

      const filterTableCampaign =
        dataFilter &&
        dataFilter.filter((data) =>
          data.campaign.toLowerCase().includes(filterCallHistory.toLowerCase())
        );

      const filterTableQueue =
        dataFilter &&
        dataFilter.filter((data) =>
          data.queue
            .toString()
            .toLowerCase()
            .includes(filterCallHistory.toLowerCase())
        );

      const filterTablePhone =
        dataFilter &&
        dataFilter.filter((data) =>
          data.phone
            .toString()
            .toLowerCase()
            .includes(filterCallHistory.toLowerCase())
        );

      const newArrFiltered = [
        ...filterTableList,
        ...filterTableAgent,
        ...filterTableCampaign,
        ...filterTableQueue,
        ...filterTablePhone,
      ];
      return removeDuplicates(newArrFiltered);
    }
  };

  const filteredData = filterAllTableCallHistory(dataCallHistory);

  return (
    <ContainerCallHistory initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      {loadingCallHistory && <BackDropCircularLoading />}
      <InputComponent
        value={filterCallHistory}
        onChange={(e) => setFilterCallHistory(e.target.value)}
        placeholder={t('agents.callHistory.labelFilter')}
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <BiSearch />
            </InputAdornment>
          ),
        }}
      />

      <ContentTable>
        <TableCallHistory dataTable={filteredData} />
      </ContentTable>
      <FooterCall>
        <ButtonStyled
          variantstyle={'secondary'}
          onClick={() => setCurrentStateAgents(null)}
        >
          <span>{t('agents.callHistory.btnCancel')}</span>
        </ButtonStyled>
      </FooterCall>
    </ContainerCallHistory>
  );
};
