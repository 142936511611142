import { v4 as uuid } from 'uuid';

export const useNodeFlow = () => {
  const handlerAddNodeByType = (
    typeNode,
    position,
    totalNodesEnd,
    renderLabelLoop
  ) => {
    let id = uuid();
    const getId = () => `node_id_${id}`;

    switch (typeNode) {
      case 'hangUp':
        return {
          id: getId(),
          type: typeNode,
          position,
          data: {
            label: totalNodesEnd,
            handler: `${typeNode}`,
          },
        };
      case 'playFiles':
        return {
          id: getId(),
          type: typeNode,
          position,
          data: {
            label: '',
            handler: 'playFiles',
            validInput: [],
            allowSkip: false,
            sounds: [],
          },
        };
      case 'ttsVoicceLabs':
        return {
          id: getId(),
          type: typeNode,
          position,
          data: {
            label: '',
            handler: 'ttsVoicceLabs',
            fileName: '',
            prompt: '',
          },
        };
      case 'recVoz':
        return {
          id: getId(),
          type: typeNode,
          position,
          data: {
            label: '',
            handler: 'recVoz',
            timeout: 5,
            allowSkip: false,
            nluEndpoint: '',
            validInput: [],
            fileName: '',
            qtdRepesca: 0,
            utterance: '',
            slot: '',
          },
        };
      case 'conditionalNode':
        return {
          id: getId(),
          type: typeNode,
          position,
          data: {
            label: '',
            handler: 'conditionalNode',
            conditions: {
              nextError: 'Error',
            },
            typeCondition: '',
            conditionsList: [],
          },
        };
      case 'dialplanVariables':
        return {
          id: getId(),
          type: typeNode,
          position,
          data: {
            label: '',
            handler: 'dialplanVariables',
            type: 'get',
            language: '0',
            voice: '0',
            prosodyRate: '0',
            valueVoice: '',
            next: '',
            nextError: '',
          },
        };
      case 'setVariables':
        return {
          id: getId(),
          type: typeNode,
          position,
          data: {
            label: '',
            handler: 'setVariables',
            listVariables: [],
          },
        };
      case 'apiData':
        return {
          id: getId(),
          type: typeNode,
          position,
          data: {
            label: '',
            handler: typeNode,
            url: '',
            method: 'GET',
            headers: [],
            parameters: [],
            body: [],
            configRequest: {},
            response: [],
            soundRate: true,
            waitApi: true,
          },
        };
      case 'functionHandler':
        return {
          id: getId(),
          type: typeNode,
          position,
          data: {
            label: '',
            handler: typeNode,
          },
        };
      case 'monestIa':
        return {
          id: getId(),
          type: typeNode,
          position,
          data: {
            label: ``,
            handler: `${typeNode}`,
          },
        };
      case 'deepCenter':
        return {
          id: getId(),
          type: typeNode,
          position,
          data: {
            label: ``,
            handler: `${typeNode}`,
          },
        };
      case 'loop':
        return {
          id: getId(),
          type: typeNode,
          position,
          data: {
            label: renderLabelLoop,
            handler: 'loop',
            attempts: 0,
            looping: '',
            complete: '',
          },
        };
      case 'transferQueue':
        return {
          id: getId(),
          type: typeNode,
          position,
          data: {
            label: ``,
            handler: `${typeNode}`,
            typeTransfer: '',
            transferTo: '',
            mcdu: '',
          },
        };

      default:
        return {
          id: getId(),
          type: typeNode,
          position,
          data: {
            handler: `${typeNode}`,
          },
        };
    }
  };

  const renderPositionByType = (type) => {
    switch (type) {
      case 'dialplanVariables':
        return {
          success: {
            x: 10,
            y: 130,
          },
          error: {
            x: 10,
            y: 160,
          },
        };

      case 'apiData':
        return {
          success: {
            x: 10,
            y: 100,
          },
          error: {
            x: 10,
            y: 130,
          },
        };

      case 'transferQueue':
        return {
          success: {
            x: 10,
            y: 105,
          },
          error: {
            x: 10,
            y: 135,
          },
        };

      default:
        return {
          success: {
            x: 10,
            y: 140,
          },
          error: {
            x: 10,
            y: 170,
          },
        };
    }
  };

  const renderSubItensConditional = (id, type) => {
    const subItems = [
      {
        id: `sub_id_${id}_${'success'}`,
        type: 'subNode',
        data: {
          label: 'Success',
          handler: 'successCondition',
        },
        position: renderPositionByType(type).success,
        parentNode: id,
        extent: 'parent',
        className: 'nodrag',
        selectable: false,
      },
      {
        id: `sub_id_${id}_${'error'}`,
        type: 'subNode',
        data: {
          label: 'Error',
          handler: 'errorCondition',
        },
        position: renderPositionByType(type).error,
        parentNode: id,
        extent: 'parent',
        className: 'nodrag',
        selectable: false,
      },
    ];

    const subItemsConditional = [
      {
        id: `sub_id_${id}_${'success'}`,
        type: 'subNode',
        data: {
          label: 'Set a condition',
          handler: 'successCondition',
        },
        position: {
          x: 10,
          y: 80,
        },
        parentNode: id,
        extent: 'parent',
        className: 'nodrag',
        selectable: false,
      },
      {
        id: `sub_id_${id}_${'error'}`,
        type: 'subNode',
        data: {
          label: 'Error',
          handler: 'errorCondition',
        },
        position: {
          x: 10,
          y: 110,
        },
        parentNode: id,
        extent: 'parent',
        className: 'nodrag',
        selectable: false,
      },
    ];

    const subItemsLoop = [
      {
        id: `sub_id_${id}_${'looping'}`,
        type: 'itemLoopVoicce',
        data: {
          label: 'Looping',
          handler: 'loopingVoicce',
        },
        position: {
          x: 10,
          y: 80,
        },
        parentNode: id,
        extent: 'parent',
        className: 'nodrag',
        selectable: false,
      },
      {
        id: `sub_id_${id}_${'loop'}`,
        type: 'itemLoopVoicce',
        data: {
          label: 'Complete',
          handler: 'completeVoicce',
        },
        position: {
          x: 10,
          y: 110,
        },
        parentNode: id,
        extent: 'parent',
        className: 'nodrag',
        selectable: false,
      },
    ];

    if (type === 'conditionalNode') {
      return subItemsConditional;
    }

    if (type === 'loop') {
      return subItemsLoop;
    }

    return subItems;
  };

  return { handlerAddNodeByType, renderSubItensConditional };
};
