import {
  idNodeToRemove,
  selectedNode,
  showMenuNodes,
} from '@/store/FlowBuilder';
import { useAtom } from 'jotai';
import React, { memo } from 'react';
import { Handle, Position } from 'reactflow';
import { NodeContent, NodeFunctionContainer } from './styled';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { motion } from 'framer-motion';
import { MdEdit } from 'react-icons/md';
import { FaGears } from 'react-icons/fa6';
import { shortNameRender } from '../../../utils/shortNameRender';
import { usePermissionsFlow } from '@/hooks/FlowBuilder/usePermissionsFlow';

const FunctionNodeComponent = ({ id, data, selected }) => {
  const { permissionsEdit, permissionsDelete, isSysAdmin } =
    usePermissionsFlow();
  const [, setIdNode] = useAtom(idNodeToRemove);
  const [, setShowMenu] = useAtom(showMenuNodes);
  const [nodeSelected, setSelectedNode] = useAtom(selectedNode);

  const handleNode = () => {
    setSelectedNode({ id, data });
    setShowMenu(true);
  };

  const handleRemoveNode = (idNode) => {
    setIdNode(idNode);
    setShowMenu(false);
    idNode === nodeSelected?.id && setShowMenu(false);
  };

  const handleSelectedNode = () => {
    setSelectedNode({ id, data });
    setShowMenu(true);
  };

  const permissionNodeEdit = permissionsEdit || isSysAdmin;
  const permissionNodeDelete = permissionsDelete || isSysAdmin;

  return (
    <NodeFunctionContainer
      selected={selected}
      onDoubleClick={() => handleNode()}
      errorConnect={data.errorConnect ? 1 : 0}
    >
      <div className='panelNode'>
        {permissionNodeEdit && (
          <motion.button
            onClick={() => handleSelectedNode()}
            whileTap={{ scale: 0.98 }}
          >
            <MdEdit size={16} />
          </motion.button>
        )}
        {permissionNodeDelete && (
          <motion.button
            onClick={() => handleRemoveNode(id)}
            whileTap={{ scale: 0.98 }}
          >
            <IoIosCloseCircleOutline />
          </motion.button>
        )}
      </div>

      <Handle type='target' position={Position.Left} />
      <NodeContent>
        <div className='header'>
          <FaGears size={16} />
          <div className='titleNode'>
            <h4>{shortNameRender(data.label, 12)}</h4>
            <span>Function</span>
          </div>
        </div>
        <div className='main'></div>
      </NodeContent>
      <Handle type='source' position={Position.Right} />
    </NodeFunctionContainer>
  );
};

export const FunctionNode = memo(FunctionNodeComponent);
