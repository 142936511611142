import {
  ReactFlowProvider,
  useEdgesState,
  useNodesState,
  applyNodeChanges,
} from 'reactflow';
import {
  dataNodeErrorConnect,
  idNodeToRemove,
  isSavedFlow,
  showMenuNodes,
  variablesList,
} from '@/store/FlowBuilder';
import { useAtom } from 'jotai';
import { FlowConstructor } from './FlowConstructor';
import { MenuNode } from './MenuNode';
import { NavigationFlow } from './NavigationFlow';
import { useCallback, useEffect, useState } from 'react';
import { errorToast } from '@/components/FormComponents/Toast';
import { ContainerFlowComponent } from './styled';
import { useVariablesFlow } from '@/hooks/FlowBuilder/useVariablesFlow';
import { LoadingComponent } from '@/components/layout/LoadingComponent';
import { getHelperLines } from '../utils/utils';

export const FlowBuilderComponent = ({ changeStageFlow }) => {
  const { loadingVariables } = useVariablesFlow();
  const [isSaved, setIsSaved] = useAtom(isSavedFlow);
  const [, setShowMenu] = useAtom(showMenuNodes);
  const [idNodeRemove, setIdNodeRemove] = useAtom(idNodeToRemove);
  const [listVariables, setListVariables] = useAtom(variablesList);
  const [, setNodeErrorConnect] = useAtom(dataNodeErrorConnect);

  const [nodes, setNodes] = useNodesState([
    {
      id: '1',
      type: 'initNode',
      data: {
        label: 'First',
        handler: 'dbQuery',
        query: 'newUser',
        next: 'inputClient',
      },
      position: { x: 60, y: 400 },
    },
  ]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [selectedNodes, setSelectedNodes] = useState({
    nodes: [],
    edges: [],
  });

  const [helperLineHorizontal, setHelperLineHorizontal] = useState();
  const [helperLineVertical, setHelperLineVertical] = useState();

  const removeSelectedOnSaveTemplate = () => {
    setSelectedNodes({
      nodes: [],
      edges: [],
    });
  };

  const customApplyNodeChanges = useCallback((changes, nodes) => {
    setHelperLineHorizontal(undefined);
    setHelperLineVertical(undefined);
    if (
      changes.length === 1 &&
      changes[0].type === 'position' &&
      changes[0].dragging &&
      changes[0].position
    ) {
      const helperLines = getHelperLines(changes[0], nodes);
      changes[0].position.x =
        helperLines.snapPosition.x ?? changes[0].position.x;
      changes[0].position.y =
        helperLines.snapPosition.y ?? changes[0].position.y;
      setHelperLineHorizontal(helperLines.horizontal);
      setHelperLineVertical(helperLines.vertical);
    }

    return applyNodeChanges(changes, nodes);
  }, []);

  const checkUsedVariables = (idNode) => {
    const checkWorldVariable = (stringVar) => {
      const regexVariable = /\{\{.*?\}\}/;
      const hasVariable = regexVariable.exec(stringVar);
      return hasVariable ? hasVariable[0] : '';
    };

    const checkPromptsNodes = nodes
      .filter((item) => item.data.handler === 'ttsVoicceLabs')
      .map((item) => item.data.prompt)
      .map((item) => item.split(' '))
      .map((item) => checkWorldVariable(item))
      .flat();

    const checkConditionsNodes = nodes
      .filter((item) => item.data.handler === 'conditionalNode')
      .map((item) => `{{${item.data.typeCondition.toLowerCase()}}}`);

    const checkDataApiNodes = nodes
      .filter((item) => item.data.handler === 'apiData')
      .map((item) => item.data.response.map((item) => `{{${item.name}}}`))
      .flat();

    const varsUsedInNodes = [
      ...checkPromptsNodes,
      ...checkConditionsNodes,
      ...checkDataApiNodes,
    ];

    const checkExistingVariables = listVariables
      .filter((item) => item.parent === idNode)
      .map((item) => varsUsedInNodes.includes(`{{${item.name}}}`));

    const checkExistingRefVariables = listVariables.filter(
      (item) => item.referNode === idNode
    );

    if (checkExistingRefVariables.length > 0) {
      return true;
    }

    if (checkExistingVariables.includes(true)) {
      return true;
    }
    return false;
  };

  const checkConditionVar = (idNode) => {
    const getNameNode = nodes
      .filter((item) => item.id === idNode)
      .map((item) => {
        return {
          utterance: `$${item.data.label}-utterance`,
          slot: `$${item.data.label}_slot`,
        };
      })[0];

    const checkConditionsNodes = nodes.filter(
      (item) => item.data.handler === 'conditionalNode'
    );

    if (checkConditionsNodes.length > 0) {
      const checkUtterance = checkConditionsNodes.filter(
        (item) => item.data.typeCondition === getNameNode.utterance
      );
      const checkSlot = checkConditionsNodes.filter(
        (item) => item.data.typeCondition === getNameNode.slot
      );

      const checkUsedVars = checkUtterance.length > 0 || checkSlot.length > 0;
      return checkUsedVars;
    }

    return false;
  };

  const handleRemoveCondVar = (list, idNode) => {
    const getNameNode = nodes
      .filter((item) => item.id === idNode)
      .map((item) => {
        return {
          utterance: `$${item.data.label}_utterance`,
          slot: `$${item.data.label}_slot`,
        };
      })[0];

    const filterUtterance = list.filter(
      (item) => item.name !== getNameNode.utterance
    );

    const filterSlot = filterUtterance.filter(
      (item) => item.name !== getNameNode.slot
    );

    return filterSlot;
  };

  const removeNode = (idNode) => {
    if (idNode === '1') {
      return;
    }

    const isUsedVar = checkUsedVariables(idNode);
    const isUserCondVar = checkConditionVar(idNode);

    if (isUsedVar || isUserCondVar) {
      errorToast('Variables in use');
      return;
    }

    const listRemovedCondVars = handleRemoveCondVar(listVariables, idNode);

    const updateVariables = listRemovedCondVars.filter(
      (item) => item.parent !== idNode
    );

    const updatedNodes = nodes.filter((node) => {
      return node.id !== idNode && node.parentNode !== idNode;
    });

    const updatedEdges = edges.filter((edge) => {
      return (
        edge.source !== idNode &&
        edge.target !== idNode &&
        edge.source !== idNode.parentNode &&
        edge.target !== idNode.parentNode
      );
    });

    let nodesToRemove = [idNode];

    nodesToRemove.forEach((nodeId) => {
      const parentNodeId = nodes.find((node) => node.id === nodeId)?.parentNode;
      if (parentNodeId) {
        nodesToRemove.push(parentNodeId);
      }
    });

    const finalUpdatedNodes = updatedNodes.filter(
      (node) => !nodesToRemove.includes(node.id)
    );

    setListVariables(updateVariables);
    setNodes(finalUpdatedNodes);
    setEdges(updatedEdges);
    setShowMenu(false);
  };

  const removeEdge = (idEdge) => {
    const updatedEdges = edges.filter((edge) => {
      return edge.id !== idEdge;
    });
    setEdges(updatedEdges);
  };

  const handleNodesChange = useCallback(
    (changes) => {
      setNodes((nodes) => customApplyNodeChanges(changes, nodes));
      if (changes.length > 0) {
        if (
          changes[0].selected === false ||
          changes[0].dragging === false ||
          changes[0].type === 'select'
        ) {
          return;
        }
        if (changes[0].id !== '1') {
          setIsSaved(false);
        }
      }
    },
    [setNodes, customApplyNodeChanges]
  );

  const handleEdgesChange = (changedEdges) => {
    const idSelectedEdge = changedEdges
      .filter((item) => item.selected)
      .map((item) => item.id)
      .toString();

    const getIdSelectedEdge = edges
      .filter((item) => item.id === idSelectedEdge)
      .map((item) => item.target)
      .toString();

    const searchNodeTarget = nodes.map((item) => {
      if (item.id === getIdSelectedEdge) {
        return {
          ...item,
          selected: true,
        };
      } else {
        return {
          ...item,
          selected: false,
        };
      }
    });

    setNodes(searchNodeTarget);

    onEdgesChange(changedEdges);
    if (changedEdges.length > 0) {
      if (
        changedEdges[0].type === 'select' ||
        changedEdges[1]?.type === 'select'
      ) {
        return;
      }
      if (changedEdges[0].id !== '1') {
        setIsSaved(false);
      }
    }
  };

  useEffect(() => {
    // CHECK THE FLOW IS SAVED WHEN YOU EXIT THE PAGE
    const confirmExit = (e) => {
      const message =
        'Tem certeza que deseja sair? As alterações não salvas serão perdidas.';
      if (typeof e === 'undefined') {
        e = window.event;
      }
      if (e) {
        e.returnValue = message;
      }
      return message;
    };
    !isSaved && window.addEventListener('beforeunload', confirmExit);
    return () => {
      window.removeEventListener('beforeunload', confirmExit);
    };
  }, [isSaved]);

  useEffect(() => {
    return () => {
      setIsSaved(true);
      setNodeErrorConnect(null);
    };
  }, []);

  useEffect(() => {
    if (idNodeRemove) {
      removeNode(idNodeRemove);
      setIdNodeRemove(null);
    }
  }, [idNodeRemove]);

  const dataNodes = {
    nodes,
    setNodes,
    edges,
    setEdges,
    onEdgesChange,
    handleNodesChange,
    handleEdgesChange,
    setSelectedNodes,
    selectedNodes,
    removeSelectedOnSaveTemplate,

    removeNode,
    removeEdge,
    helperLineHorizontal,
    helperLineVertical,
  };

  const isLoading = loadingVariables;

  return (
    <ReactFlowProvider>
      <ContainerFlowComponent
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.1 }}
      >
        {isLoading && <LoadingComponent />}
        <NavigationFlow
          dataNodes={dataNodes}
          changeStageFlow={changeStageFlow}
        />
        <FlowConstructor dataNodes={dataNodes} />
        <MenuNode dataNodes={dataNodes} />
      </ContainerFlowComponent>
    </ReactFlowProvider>
  );
};
