import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';
import { ButtonStyled } from '../../../../FormComponents/ButtonComponent';

export const ContainerInCall = styled(motion.div)`
  width: 100%;
  height: 170px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background: ${(props) => props.theme.colors.containers.colorCard};
  border: 2px solid ${(props) => props.theme.colors.primary};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  gap: 1rem;
  padding: 1rem;

  .headerCard {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .titleCall {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 4px;

      h3 {
        font-style: normal;
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 14px;
        letter-spacing: 0.1em;
        text-transform: capitalize;

        color: ${(props) => props.theme.colors.typography.baseText};
      }

      svg {
        color: ${(props) => props.theme.colors.primary};
      }
    }

    .statusCallCard {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 4px;
      width: 100%;
      max-width: 140px;

      svg {
        color: ${(props) => props.theme.colors.primary};
        font-size: 0.45rem;
        padding-bottom: 2px;
      }

      span {
        /* font-family: 'Roboto'; */
        font-style: normal;
        font-weight: 700;
        font-size: 0.75rem;
        line-height: 14px;
        letter-spacing: 0.1em;
        text-transform: uppercase;

        color: #575757;
      }
    }
  }

  @media (max-width: 560px) {
    height: auto;

    .headerCard {
      flex-direction: column;
      justify-content: flex-start;
      gap: 1rem;
      align-items: flex-start;

      .titleCall {
        display: none;
      }

      .statusCallCard {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 4px;
        width: 100%;
      }
    }
  }
`;

export const ContentInCall = styled.div`
  flex: 1;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
  gap: 1rem;

  .columnItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 8px;
    border-right: 1px solid
      ${(props) => props.theme.colors.containers.dividerColor};
  }

  .noBorder {
    border-right: none;
  }

  .columnButtonsItem {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 0.6rem;

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      width: 100%;
      max-width: 140px;
      padding: 0.2rem;
    }
  }

  @media (max-width: 560px) {
    grid-template-columns: repeat(1, 1fr);

    .columnButtonsItem {
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
    }
    .columnItem {
      padding-bottom: 1rem;
      border-right: 0px solid
        ${(props) => props.theme.colors.containers.dividerColor};

      border-bottom: 1px solid
        ${(props) => props.theme.colors.containers.dividerColor}A0;
    }
  }
`;

export const BtnMute = styled(ButtonStyled)`
  ${(props) =>
    props.muted
      ? css`
          background-color: ${(props) => props.theme.colors.error} !important;
        `
      : css``}
`;

export const ItemInCall = styled.div`
  flex: 1;
  width: 100%;

  display: flex;
  flex-direction: column;

  span {
    font-weight: 400;
    font-size: 0.625rem;
    line-height: 0.688rem;
    letter-spacing: 0.1em;
    text-transform: uppercase;

    color: ${(props) => props.theme.colors.primary};

    &:last-child {
      font-style: normal;
      font-weight: 700;
      font-size: 0.75rem;
      line-height: 0.875rem;
      letter-spacing: 0.1em;
      text-transform: uppercase;

      color: #787878;
    }
  }

  @media (max-width: 560px) {
    flex-direction: row;
    align-items: center;
    gap: 1rem;

    span {
      font-weight: 400;
      font-size: 0.8rem;
      line-height: 1;
      letter-spacing: 0.1em;
      text-transform: uppercase;

      color: ${(props) => props.theme.colors.primary};

      &:last-child {
        font-style: normal;
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 0rem;
        letter-spacing: 0.1em;
        text-transform: uppercase;

        color: #787878;
      }
    }
  }
`;
