export async function getDispositions() {
  try {
    const data = [
      {
        id: '081af844-7bc1-4bf8-9b71-ece5ae9f0629',
        name: 'LF02',
        value: 'LF02',
      },
      {
        id: 'c35ccc03-edc8-4834-8ba1-d74dd0e733fd',
        name: 'GR01',
        value: 'GR01',
      },
      {
        id: '046bc2ee-8cdb-4794-a9f3-b77e88255864',
        name: 'GR05',
        value: 'GR05',
      },
      {
        id: '7b20009c-9c80-4d2f-9551-0a69bd5d21db',
        name: 'ADEF',
        value: 'ADEF',
      },
      {
        id: 'a51a38b7-5d8f-4f04-97e6-4a8bdcba6f7f',
        name: 'K219',
        value: 'K219',
      },
      {
        id: '8d43eb38-2322-473b-84d6-5a6cb5a7f0d3',
        name: 'COMP',
        value: 'COMP',
      },
      {
        id: '19fe57e9-cb56-4b99-ab3e-b53aff4b5498',
        name: 'INCO',
        value: 'INCO',
      },
      {
        id: '384b2977-de7e-4d15-93b6-18d1fd11b657',
        name: 'TEST',
        value: 'TEST',
      },
    ];

    return new Promise((resolve) => {
      setTimeout(() => resolve(data), 500);
    });
  } catch (err) {
    throw new Error(err?.response?.data?.message);
  }
}
