import { Stack } from '@mui/system';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { ButtonStyled } from '@components/FormComponents/ButtonComponent';
import { ContainerRemoveTenant, FormRemoveTenant } from './styled';
import { TitleComponent } from '@components/layout/TitleComponent';
import { dataSelectedTenants } from '@/store/TenantsStore';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';

export const RemoveSchedulerRow = ({
  closeDialog,
  handleDeleteItem,
  isLoading,
}) => {
  const { t } = useTranslation();
  const [selectedData] = useAtom(dataSelectedTenants);

  const renderText = (spell) => {
    return t(`dialControl.ufManager.remove.${spell}`);
  };

  const handleRemoveSchedulerRow = () => {
    handleDeleteItem();
  };

  return (
    <ContainerRemoveTenant>
      {isLoading && <BackDropCircularLoading />}

      <TitleComponent
        textTitle={renderText('title')}
        iconTitle='callOperator'
      />

      <FormRemoveTenant>
        <p>
          {renderText('sentence')}
          <strong>{selectedData?.name ?? ''}</strong>
        </p>
        <Stack direction={`row`} gap={1} justifyContent={'flex-end'}>
          <ButtonStyled
            variantstyle={'secondary'}
            onClick={closeDialog}
            size='size'
            type='button'
          >
            {renderText('btn-cancel')}
          </ButtonStyled>
          <ButtonStyled
            type='button'
            size='size'
            onClick={handleRemoveSchedulerRow}
          >
            {renderText('btn-confirm')}
          </ButtonStyled>
        </Stack>
      </FormRemoveTenant>
    </ContainerRemoveTenant>
  );
};
