import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ContainerScheduleMailing = styled(motion.div)`
  width: 100%;
  height: 100%;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 40px;
`;

export const MainScheduleMailing = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 2rem;
`;

export const FooterScheduleMailing = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;

  button {
    span {
      padding: 2px 0px 0px 0px !important;
    }
  }
`;
