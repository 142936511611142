import { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { useIdleTimer } from 'react-idle-timer';
import { Dialog } from '@mui/material';
import { DialogContent } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

import { isIdle, schemaScreens, user } from '@store/AuthenticatorStore';
import {
  currentPage,
  hiddenSidebar,
  idSubItem,
  isCloseItem,
} from '@store/NavigationDashboard';

import { ModalLogoutWhenIdle } from '@components/pages/Dashboard/ModalLogoutWhenIdle';

import { Main } from './Main';
import { Sidebar } from './Sidebar';

import { ContentDashboard, ContainerDashboard } from './styled';
import { LoadingComponent } from '@/components/layout/LoadingComponent';
import { useNavigationAccess } from '@/hooks/Navigator/useNavigationAccess';
import { useServersControl } from '@/hooks/Dialing/useServersControl';

export const Dashboard = () => {
  const { loadingGetProfileUser } = useNavigationAccess();
  const { isLoadingServers } = useServersControl();

  const matches = useMediaQuery('(max-width:1020px)');
  const [currentUser] = useAtom(user);
  const [screenSchema] = useAtom(schemaScreens);
  const [currentPageRender] = useAtom(currentPage);
  const [idSub] = useAtom(idSubItem);
  const [isVisibleSidebar] = useAtom(hiddenSidebar);
  const [, setCurrentNameDashboard] = useState('');

  useEffect(() => {
    if (screenSchema && screenSchema.length > 0) {
      const filterNameCurrentItem = screenSchema
        .filter((item) => item.id === currentPageRender)
        .map((item) => item.name);

      if (idSub) {
        const filterNameCurrentSubItem = screenSchema
          .filter((item) => item.id === currentPageRender)
          .map((item) => item.subItem)
          .flat();

        const currentSubItem = filterNameCurrentSubItem
          .filter((item) => item.id === idSub)
          .map((item) => item.name)
          .flat();

        return setCurrentNameDashboard(String(currentSubItem) ?? '');
      }
      return setCurrentNameDashboard(String(filterNameCurrentItem) ?? '');
    }
  }, [currentPageRender]);

  const [isClose, setIsClose] = useAtom(isCloseItem);
  const [isInactive, setIsInactive] = useAtom(isIdle);

  const timeout = 900000; // 15min
  const handleOnIdle = () => setIsInactive(true);

  useIdleTimer({
    timeout,
    onIdle: handleOnIdle,
  });

  const isFlowBuilder = !isVisibleSidebar;

  useEffect(() => {
    if (isFlowBuilder) {
      return;
    }
    matches ? setIsClose(true) : setIsClose(false);
  }, [setIsClose, matches, isVisibleSidebar]);

  const renderNavigationSidebar = () => {
    return !isClose ? '300' : '0';
  };

  const isLoading =
    !screenSchema || !currentUser || loadingGetProfileUser || isLoadingServers;

  if (isLoading) {
    return <LoadingComponent />;
  } else {
    return (
      <ContainerDashboard initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
        <ContentDashboard>
          <Sidebar
            style={{ zIndex: 1000 }}
            stateSidebar={renderNavigationSidebar()}
          />
          <Main style={{ zIndex: 1000 }} isFlowBuilder={isFlowBuilder} />
        </ContentDashboard>
        <Dialog open={isInactive}>
          <DialogContent sx={{ padding: '0px 2rem' }}>
            <ModalLogoutWhenIdle />
          </DialogContent>
        </Dialog>
      </ContainerDashboard>
    );
  }
};
