import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

export const ContainerPostConnection = styled(motion.div)`
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background: ${(props) => props.theme.colors.containers.colorCard};
  ${(props) =>
    props.finaltime
      ? css`
          border: 2px solid ${(props) => props.theme.colors.error};
        `
      : css`
          border: 2px solid ${(props) => props.theme.colors.secondary};
        `}

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  gap: 1rem;
  padding: 1rem;
  .headerCard {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .titleCall {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 4px;

      h3 {
        font-style: normal;
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 14px;
        letter-spacing: 0.1em;
        text-transform: uppercase;

        color: ${(props) => props.theme.colors.typography.baseText};
      }

      svg {
        ${(props) =>
          props.finaltime
            ? css`
                color: ${(props) => props.theme.colors.error};
              `
            : css`
                color: ${(props) => props.theme.colors.secondary};
              `}
      }
    }

    .statusCallCard {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 4px;

      svg {
        ${(props) =>
          props.finaltime
            ? css`
                color: ${(props) => props.theme.colors.error};
              `
            : css`
                color: ${(props) => props.theme.colors.secondary};
              `}
        font-size: 0.45rem;
        padding-bottom: 2px;
      }

      span {
        font-style: normal;
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 14px;
        letter-spacing: 0.1em;
        text-transform: uppercase;

        color: ${(props) => props.theme.colors.typography.baseText};
      }
    }
  }

  @media (max-width: 560px) {
    height: auto;
    .headerCard {
      flex-direction: column;
      justify-content: flex-start;
      gap: 1rem;
      align-items: flex-start;

      .titleCall {
        display: none;
      }

      .statusCallCard {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 4px;
        width: 100%;
      }
    }
  }
`;

export const ContentInCall = styled.div`
  flex: 1;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
  gap: 1rem;

  .columnItem {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 8px;
    border-right: 1px solid
      ${(props) => props.theme.colors.containers.dividerColor};
  }

  .noBorder {
    border-right: none;
  }

  .columnStopWatch {
    display: flex;
    height: 100%;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-end;

    @media (max-width: 560px) {
      height: auto;
    }

    .record_play button {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 4px;
      border: 1px solid transparent;
      background-color: transparent;
      padding: 4px;
      border-radius: 4px;

      &:hover {
        border: 1px solid ${(props) => props.theme.colors.secondary};
      }

      span {
        font-weight: 400;
        font-size: 0.8rem;
        display: flex;
        align-items: center;
        letter-spacing: 0.115em;
        text-transform: uppercase;
        color: ${(props) => props.theme.colors.typography.baseText};
      }
      svg {
        color: ${(props) => props.theme.colors.secondary};
        font-size: 0.875rem;
      }
    }

    .timer_section {
      span {
        font-weight: 400;
        font-size: 10px;
        line-height: 16px;
        display: flex;
        align-items: center;
        letter-spacing: 0.115em;
        text-transform: uppercase;
        color: ${(props) => props.theme.colors.typography.baseText};

        &:last-child {
          font-weight: 700;
          font-size: 1.2rem;
          line-height: 16px;
          display: flex;
          align-items: center;
          letter-spacing: 0.13em;
          text-transform: uppercase;

          ${(props) =>
            props.finaltime
              ? css`
                  color: ${(props) => props.theme.colors.error};
                `
              : css`
                  color: ${(props) => props.theme.colors.secondary};
                `}
        }
      }
      @media (max-width: 560px) {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 1rem;
        span {
          &:last-child {
            font-weight: 700;
            font-size: 0.875rem;
          }
        }
      }
    }
  }

  @media (max-width: 560px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    .columnItem {
      width: 100%;
      padding-bottom: 1rem;
      border-right: 0px solid
        ${(props) => props.theme.colors.containers.dividerColor};

      border-bottom: 1px solid
        ${(props) => props.theme.colors.containers.dividerColor}A0;
    }
  }
`;

export const ItemInCall = styled.div`
  flex: 1;
  width: 100%;

  display: flex;
  flex-direction: column;

  span {
    font-weight: 400;
    font-size: 0.625rem;
    line-height: 0.688rem;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    ${(props) =>
      props.finaltime
        ? css`
            color: ${(props) => props.theme.colors.error};
          `
        : css`
            color: ${(props) => props.theme.colors.secondary};
          `}

    &:last-child {
      font-style: normal;
      font-weight: 600;
      font-size: 0.75rem;
      line-height: 0.875rem;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: ${(props) => props.theme.colors.typography.baseText};
    }
  }

  @media (max-width: 560px) {
    flex-direction: row;
    align-items: center;
    gap: 1rem;

    span {
      font-weight: 400;
      font-size: 0.875rem;
      letter-spacing: 0.1em;
      text-transform: uppercase;

      &:last-child {
        font-style: normal;
        font-weight: 500;
        font-size: 0.875rem;
        letter-spacing: 0.1em;
        text-transform: uppercase;

        color: #787878;
      }
    }
  }
`;

export const FormPostConnection = styled.form`
  grid-column: 1/5;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    ${(props) =>
      props.finaltime
        ? css`
            border-color: ${(props) => props.theme.colors.error};
          `
        : css`
            border-color: ${(props) => props.theme.colors.secondary};
          `}
  }
  &:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    ${(props) =>
      props.finaltime
        ? css`
            border-color: ${(props) => props.theme.colors.error};
          `
        : css`
            border-color: ${(props) => props.theme.colors.secondary};
          `}
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    ${(props) =>
      props.finaltime
        ? css`
            border-color: ${(props) => props.theme.colors.error};
          `
        : css`
            border-color: ${(props) => props.theme.colors.secondary};
          `}
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input {
    color: ${(props) => props.theme.colors.baseText};
  }

  .MuiInputLabel-outlined {
    color: ${(props) => props.theme.colors.baseText};
  }

  .MuiInputLabel-outlined.Mui-focused {
    color: ${(props) => props.theme.colors.baseText};
  }

  .footerForm {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    button {
      ${(props) =>
        props.finaltime
          ? css`
              background-color: ${(props) =>
                props.theme.colors.error} !important;
            `
          : css``}
    }
  }
`;
