/* eslint-disable jsx-a11y/tabindex-no-positive */
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { useAtom } from 'jotai';
import { RxHamburgerMenu } from 'react-icons/rx';
import { MdDarkMode, MdOutlineDarkMode } from 'react-icons/md';

import {
  currentPageId,
  dataInicialIdComponent,
  idSubItem,
  isCloseItem,
  resetCurrentPageId,
} from '@store/NavigationDashboard';
import { setCurrentTheme } from '@store/ThemeStore/theme';

import { ImageComponent } from '@/components/layout/ImageComponent';
import { schemaScreens } from '@/store/AuthenticatorStore';

import LogoSidebarImg from '@/assets/imgs/sm_voiccelabs_black.webp';
import LogoWhiteSidebarImg from '@/assets/imgs/sm_voiccelabs_white.webp';

// import LogoSidebarImg from '@/assets/imgs/logo_voicce_blue_sm.png';
// import LogoWhiteSidebarImg from '@/assets/imgs/logo_voicce_white_sm.png';

import { NavbarContainer } from './styled';

export const NavBar = ({ isFlowBuilder }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [themeAtom, changeTheme] = useAtom(setCurrentTheme);
  const [screenSchema, setScreenSchema] = useAtom(schemaScreens);
  const [isClose, setIsClose] = useAtom(isCloseItem);

  const [inicialIdComponent] = useAtom(dataInicialIdComponent);
  const [, resetCurrentId] = useAtom(resetCurrentPageId);

  const [currentPage] = useAtom(currentPageId);
  const [idSub, resetSubId] = useAtom(idSubItem);

  const renderNameItem = (itemName) => {
    const renderName = t(`dashboard.${itemName}`);
    const checkName = renderName.split('.');
    if (checkName.length === 2) {
      return <span>{itemName}</span>;
    }
    return <span>{renderName}</span>;
  };

  const generateTitlePage = () => {
    const filterNameCurrentItem = screenSchema
      .filter((item) => item.id === currentPage)
      .map((item) => item.alias);
    if (idSub) {
      const filterNameCurrentSubItem = screenSchema
        .filter((item) => item.id === currentPage)
        .map((item) => item.subItem)[0];

      const filterNameCurrentItem = filterNameCurrentSubItem
        .filter((item) => item.id === idSub)
        .map((item) => item.alias);

      return renderNameItem(filterNameCurrentItem.toString());
    }
    return renderNameItem(filterNameCurrentItem.toString());
  };

  useEffect(() => {
    if (isFlowBuilder) {
      setIsClose(true);
    }
  }, [isFlowBuilder]);

  const closeMenu = () => {
    setIsClose(!isClose);
  };

  const handleHome = () => {
    resetSubId(false);
    closeMenu();
    resetCurrentId(inicialIdComponent);
    setScreenSchema(
      screenSchema.map((item) => {
        return {
          ...item,
          isOpenMenu: false,
        };
      })
    );
  };

  return (
    <NavbarContainer>
      {isFlowBuilder && (
        <div className='btn-nav-flow'>
          <button onClick={closeMenu} aria-label='Button to navigate menu'>
            <RxHamburgerMenu className={!isClose && 'rotate'} />
          </button>

          {isClose &&
            (theme.title === 'Light' ? (
              <ImageComponent
                src={LogoSidebarImg}
                width={'129px'}
                height={'35.64px'}
                alt={'Logo Voicce Labs'}
                onClick={handleHome}
                whileTap={{
                  scale: 0.99,
                }}
              />
            ) : (
              <ImageComponent
                src={LogoWhiteSidebarImg}
                width={'129px'}
                height={'35.64px'}
                alt={'Logo Voicce Labs'}
                onClick={handleHome}
                whileTap={{
                  scale: 0.99,
                }}
              />
            ))}
        </div>
      )}

      <div className='btn-nav'>
        <button onClick={closeMenu} aria-label='Button to navigate menu'>
          <RxHamburgerMenu className={!isClose && 'rotate'} />
        </button>
      </div>

      <div>
        <div className='titleComponent'>
          <h1>{generateTitlePage()}</h1>
        </div>

        <div className='controls_nav'>
          <div className=''></div>
          {themeAtom === 'light' ? (
            <MdOutlineDarkMode onClick={changeTheme} />
          ) : (
            <MdDarkMode onClick={changeTheme} />
          )}
        </div>
      </div>
    </NavbarContainer>
  );
};
