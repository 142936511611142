import { Stack } from '@mui/system';
import { FaGear } from 'react-icons/fa6';
import { FooterConfig } from '../../../styled';
import { FormAddDispositions } from './styled';
import { ButtonStyled } from '@/components/FormComponents/ButtonComponent';
import { useTranslation } from 'react-i18next';
import { DescriptionInformation } from '@/components/layout/DescriptionInformation';
import * as yup from 'yup';
import useFormControl from '@/hooks/useFormControl';
import { ControlInput } from '@/components/FormComponents/InputComponent';
import { useDispositions } from '@/hooks/FlowBuilder/useDispositions';
import { successToast } from '@/components/FormComponents/Toast';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';

export const AddDispositions = ({ changeFlowConfigState }) => {
  const { t } = useTranslation();
  const { mutateCreateDisposition, loadingCreateDisposition } =
    useDispositions();
  const dispositionSchema = yup.object().shape({
    nameDisposition: yup
      .string()
      .required(t('flowBuilder.configs.dispositions.msg-disposition-required'))
      .min(4, t('flowBuilder.configs.dispositions.msg-disposition-totalChars'))
      .max(4, t('flowBuilder.configs.dispositions.msg-disposition-totalChars'))
      .matches(
        /^[\w]+$/,
        t('flowBuilder.configs.dispositions.msg-disposition-chars')
      ),
  });

  const { handleSubmit, control, reset, errors } =
    useFormControl(dispositionSchema);

  const handleSubmitNewDisposition = (data) => {
    const dataDispositions = {
      name: data.nameDisposition,
      value: data.nameDisposition,
    };

    mutateCreateDisposition(dataDispositions, {
      onSuccess: () => {
        successToast(
          t('flowBuilder.configs.dispositions.msg-success-addedDispositions')
        );
        reset();
        changeFlowConfigState(0);
      },
    });
  };

  return (
    <FormAddDispositions onSubmit={handleSubmit(handleSubmitNewDisposition)}>
      {loadingCreateDisposition && <BackDropCircularLoading />}
      <Stack
        direction={'row'}
        spacing={1}
        alignItems={'center'}
        className={'titleConfig'}
      >
        <FaGear />
        <h4> {t('flowBuilder.configs.dispositions.btn-add-dispositions')}</h4>
      </Stack>

      <Stack
        direction={'column'}
        spacing={3}
        alignItems={'center'}
        height={'60vh'}
        width={'100%'}
      >
        <DescriptionInformation
          textDescription={t(
            'flowBuilder.configs.dispositions.description-newDisposition'
          )}
        />

        <ControlInput
          label={t(
            'flowBuilder.configs.dispositions.label-name-newDispositions'
          )}
          control={control}
          nameControl={'nameDisposition'}
          error={Boolean(errors.nameDisposition)}
          helperText={
            errors.nameDisposition ? errors.nameDisposition.message : ' '
          }
          inputProps={{ maxLength: 4 }}
        />
      </Stack>

      <FooterConfig>
        <div className=''></div>
        <Stack direction={'row'} spacing={1}>
          <ButtonStyled
            size='small'
            variantstyle='secondary'
            onClick={() => changeFlowConfigState(0)}
          >
            {t('flowBuilder.configs.btn-cancel')}
          </ButtonStyled>
          <ButtonStyled size='small' type={'submit'}>
            {t('flowBuilder.configs.btn-add')}
          </ButtonStyled>
        </Stack>
      </FooterConfig>
    </FormAddDispositions>
  );
};
