import { useMutation, useQuery } from 'react-query';
import { errorToast } from '@/components/FormComponents/Toast';
import { useAtom } from 'jotai';
import { dataDispositions } from '@/store/FlowBuilder';
import { useTranslation } from 'react-i18next';
import { getDispositions } from '@/services/flowBuilder/dispositions/getDispositions';
import { createDispositions } from '@/services/flowBuilder/dispositions/createDispositions';

export const useDispositions = () => {
  const { t } = useTranslation();
  const [, setDispositions] = useAtom(dataDispositions);

  const {
    isLoading: loadingDispositions,
    refetch: refetchListDispositions,
    isFetching: isFetchingDispositions,
  } = useQuery(
    'dispositionsFlow',
    async () => {
      return await getDispositions();
    },
    {
      onSuccess: (data) => setDispositions(data),
      onError: () => errorToast(t('flowBuilder.configs.msg-error-getEndpoint')),
    }
  );

  const {
    mutate: mutateCreateDisposition,
    isLoading: loadingCreateDisposition,
  } = useMutation({
    mutationFn: async (dataCreateDispositions) => {
      return await createDispositions(dataCreateDispositions);
    },

    onSuccess: () => {
      refetchListDispositions();
    },
    onError: () => {
      errorToast(
        t('flowBuilder.configs.dispositions.msg-error-addedDispositions')
      );
    },
  });

  return {
    loadingDispositions,
    mutateCreateDisposition,
    loadingCreateDisposition,
    isFetchingDispositions,
  };
};
