import styled from 'styled-components';
import { motion } from 'framer-motion';

export const FormMenuNode = styled(motion.form)`
  width: 400px;
  height: 100%;
  padding: 1rem;
  background-color: ${(props) => props.theme.colors.containers.colorNode};
  display: grid;
  grid-template-rows: 1fr 40px;
  gap: 1rem;
  overflow: scroll;
`;

export const MainAddNode = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 2rem;
  padding: 1rem 0 0 0.5rem;

  .dataPrev {
    width: 300px;
    height: 200px;
    overflow: auto;
  }

  .dividerItem {
    width: 100%;
    height: 1px;
    background-color: #d1d1d136;
  }

  span {
    font-size: 0.8rem;
    color: ${(props) => props.theme.colors.typography.baseText};
  }

  .content_code_block {
    width: 100%;
    background-color: rgb(30, 30, 30);
    padding: 16px 0 0 0;
  }

  h4 {
    text-transform: uppercase;
    font-size: 0.8rem;
    color: ${(props) => props.theme.colors.typography.baseText};
  }

  button {
    svg {
      color: #fff;
    }
  }

  .itens_valid_input {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    font-size: 0.7rem;
    gap: 4px;
    span {
      padding: 4px;
      width: 100%;
      background-color: #57525282;
    }
  }

  .content-list-vars {
    ul {
      list-style: none;
      width: 100%;
      padding: 4px 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 0.3rem;
      flex-direction: column;

      @media (max-width: 1550px) {
        max-height: 120px;
        overflow: auto;
      }

      li {
        width: 100%;
        background-color: ${(props) => props.theme.colors.nodesFlow.apiData};
        border-radius: 3px;
        padding: 4px 8px;

        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 0.2rem;

        svg {
          color: white;
        }
        span {
          /* color: ${(props) => props.theme.colors.typography.baseText}; */
          color: white;
          font-size: 0.9rem;
        }
      }
    }
  }
`;

export const ItemsFieldsHeader = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .header_item {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 40px;
    align-items: center;
    h4 {
      text-transform: uppercase;
      font-size: 0.72rem;
      font-weight: 600;
      color: ${(props) => props.theme.colors.typography.baseText};
    }

    button {
      min-width: 16px;
      width: 20px;
      height: 20px;
      padding: 1px;
      border-radius: 50%;
      margin-left: 6px;
      /* background-color: transparent !important;
      svg {
        color: ${(props) => props.theme.colors.typography.baseText};
      } */
    }
  }
  .header_item_sound {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 40px;
    align-items: center;
    h4 {
      text-transform: uppercase;
      font-size: 0.8rem;
      color: ${(props) => props.theme.colors.typography.baseText};
    }

    button {
      min-width: 16px;
      width: 20px;
      height: 20px;
      padding: 1px;
      border-radius: 50%;
      margin-left: 6px;
      /* background-color: transparent !important;
      svg {
        color: ${(props) => props.theme.colors.typography.baseText};
      } */
    }
  }
  .content_item {
    display: flex;
    flex-direction: column;

    .item-content {
      padding-top: 10px;
      display: grid;
      grid-template-columns: 1fr 18px 18px;
      align-items: center;
      justify-items: center;
      gap: 8px;

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 16px;
        width: 20px;
        height: 20px;
        padding: 2px;
        border-radius: 50%;
        background: transparent !important;
        box-shadow: none !important;

        svg {
          font-size: 1rem;
          color: #9c9c9c !important;
        }
      }
    }
  }
`;

export const FooterAddNode = styled.footer`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
`;

export const FieldInputUrl = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  .btn_play_prompt {
    position: absolute;
    right: 14px;
    bottom: -6px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.6rem;

    .item-btn {
      background-color: white;
      border-radius: 50px;

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        svg {
          font-size: 1rem;
        }
      }
    }
  }

  .MuiInputBase-input {
    font-size: 14px;
    height: 24px;
  }
`;
