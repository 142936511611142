import { ButtonStyled } from '@/components/FormComponents/ButtonComponent';
import { ContainerDetails, MainDetails } from './styled';
import { useTranslation } from 'react-i18next';
import { TitleComponent } from '@/components/layout/TitleComponent';
import { Stack } from '@mui/system';
import { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import {
  dataErrorsInCsv,
  dataMailing,
  dataTemplateMailing,
  dataTotalRows,
  fileDataImport,
  selectedTemplate,
} from '@/store/LoadList';
import { Divider } from '@mui/material';
import { PiInfoFill } from 'react-icons/pi';
import { DialogComponent } from '@/components/DialogComponent';
import { renderDate } from '@/utils/formatsDateAndTime';

const DetailsFlow = ({ handleCloseDetails }) => {
  const { t } = useTranslation();
  const [mailingData] = useAtom(dataMailing);
  const [selectedLayout] = useAtom(selectedTemplate);
  const [dataTemplates] = useAtom(dataTemplateMailing);
  const [file] = useAtom(fileDataImport);
  const [errosInCsv] = useAtom(dataErrorsInCsv);
  const [totalRows] = useAtom(dataTotalRows);

  const [dataDetail, setDataDetails] = useState({
    campaign: '',
    description: '',
    template: '',
    file: '',
    size: '',
    errors: '',
    totalRows: '',
    createdAt: '',
  });

  function formatBytes(bytes) {
    if (bytes < 1024) {
      return `${bytes} bytes`;
    } else if (bytes < 1024 * 1024) {
      const kilobytes = bytes / 1024;
      return `${kilobytes.toFixed(2)} KB`;
    } else {
      const megabytes = bytes / (1024 * 1024);
      return `${megabytes.toFixed(2)} MB`;
    }
  }

  const renderTemplateSelected = (idSelected) => {
    if (dataTemplates) {
      return dataTemplates.metadata.name;
    }
    return idSelected;
  };

  useEffect(() => {
    if (file && mailingData) {
      setDataDetails({
        campaign: mailingData.campaign,
        description: mailingData.description,
        template: renderTemplateSelected(selectedLayout),
        file: file.name,
        size: formatBytes(file.size),
        errors: errosInCsv.length,
        totalRows: totalRows,
        createdAt: dataTemplates.metadata.createdAt,
      });
    }
  }, [mailingData]);

  return (
    <ContainerDetails>
      <TitleComponent
        textTitle={t('listLoader.importMailing.flow.detailFlow.title')}
        iconTitle='mailing'
      />
      <MainDetails
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
      >
        <Stack direction={'row'} spacing={1}>
          <span>{t('listLoader.importMailing.flow.detailFlow.campaign')}:</span>
          <span>
            <strong>{dataDetail.campaign}</strong>
          </span>
        </Stack>
        <Stack direction={'row'} spacing={1}>
          <span>
            {t('listLoader.importMailing.flow.detailFlow.description')}:
          </span>
          <span>
            <strong>{dataDetail.description}</strong>
          </span>
        </Stack>
        <Stack direction={'row'} spacing={1}>
          <span>{t('listLoader.importMailing.flow.detailFlow.template')}:</span>
          <span>
            <strong>{dataDetail.template}</strong>
          </span>
        </Stack>
        <Stack direction={'row'} spacing={1}>
          <span>
            {t('listLoader.importMailing.flow.detailFlow.createdAt')}:
          </span>
          <span>
            <strong>{renderDate(dataDetail.createdAt)}</strong>
          </span>
        </Stack>
        <Divider />
        <Stack direction={'row'} spacing={1}>
          <span>{t('listLoader.importMailing.flow.detailFlow.file')}:</span>
          <span>
            <strong>{dataDetail.file}</strong>
          </span>
        </Stack>
        <Stack
          direction={'row'}
          spacing={1}
          width={'90%'}
          justifyContent={'space-between'}
        >
          <Stack direction={'row'} spacing={1}>
            <span>{t('listLoader.importMailing.flow.detailFlow.size')}:</span>
            <span>
              <strong>{dataDetail.size}</strong>
            </span>
          </Stack>
          <Stack direction={'row'} spacing={1}>
            <span>{t('listLoader.importMailing.flow.detailFlow.errors')}:</span>
            <span>
              <strong className={dataDetail.errors > 0 ? 'hasErrors' : ''}>
                {dataDetail.errors}
              </strong>
            </span>
          </Stack>
          <Stack direction={'row'} spacing={1}>
            <span>
              {t('listLoader.importMailing.flow.detailFlow.totalRows')}:
            </span>
            <span>
              <strong>{dataDetail.totalRows}</strong>
            </span>
          </Stack>
        </Stack>
      </MainDetails>
      <div className='footerDetails'>
        <ButtonStyled size='small' onClick={handleCloseDetails}>
          <span>{t('Ok')}</span>
        </ButtonStyled>
      </div>
    </ContainerDetails>
  );
};

export const DetailsTemplate = () => {
  const { t } = useTranslation();
  const [hasOpemDetails, setHasOpemDetails] = useState(false);

  const handleOpenDetails = () => {
    setHasOpemDetails(true);
  };

  const handleCloseDetails = () => {
    setHasOpemDetails(false);
  };

  return (
    <>
      <ButtonStyled
        onClick={handleOpenDetails}
        title={t('Details')}
        className='no-animation-detail '
        aria-hidden={hasOpemDetails}
      >
        <PiInfoFill />
      </ButtonStyled>

      <DialogComponent
        open={hasOpemDetails}
        onClose={handleCloseDetails}
        fullWidth={true}
      >
        <DetailsFlow handleCloseDetails={handleCloseDetails} />
      </DialogComponent>
    </>
  );
};
