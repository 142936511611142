import {
  idNodeToRemove,
  selectedNode,
  showMenuNodes,
} from '@/store/FlowBuilder';
import { useAtom } from 'jotai';
import React, { memo } from 'react';
import { Handle, Position } from 'reactflow';
import { NodeConditionContainer, NodeContent } from './styled';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { motion } from 'framer-motion';
import { MdEdit } from 'react-icons/md';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { shortNameRender } from '../../../utils/shortNameRender';
import { TiArrowLoop } from 'react-icons/ti';
import { usePermissionsFlow } from '@/hooks/FlowBuilder/usePermissionsFlow';

const LoopNodeComponent = ({ id, data, selected }) => {
  const { t } = useTranslation();
  const { permissionsEdit, permissionsDelete, isSysAdmin } =
    usePermissionsFlow();
  const [, setIdNode] = useAtom(idNodeToRemove);
  const [, setShowMenu] = useAtom(showMenuNodes);
  const [nodeSelected, setSelectedNode] = useAtom(selectedNode);

  const handleNode = () => {
    setSelectedNode({ id, data });
    setShowMenu(true);
  };

  const handleRemoveNode = (idNode) => {
    setIdNode(idNode);
    idNode === nodeSelected?.id && setShowMenu(false);
  };

  const handleSelectedNode = () => {
    setSelectedNode({ id, data });
    setShowMenu(true);
  };

  const renderHeightNode = () => {
    const totalItems = data?.conditionsList?.length ?? 0;
    const heightItem = 28;
    const totalHeight = totalItems <= 1 ? 140 : 130 + totalItems * heightItem;
    return `${totalHeight}px`;
  };

  const permissionNodeEdit = permissionsEdit || isSysAdmin;
  const permissionNodeDelete = permissionsDelete || isSysAdmin;

  return (
    <NodeConditionContainer
      selected={selected}
      onDoubleClick={() => handleNode()}
      errorConnect={data.errorConnect ? 1 : 0}
      heighNode={renderHeightNode}
    >
      <div className='panelNode'>
        {permissionNodeEdit && (
          <motion.button
            onClick={() => handleSelectedNode()}
            whileTap={{ scale: 0.98 }}
          >
            <MdEdit size={16} />
          </motion.button>
        )}
        {permissionNodeDelete && (
          <motion.button
            onClick={() => handleRemoveNode(id)}
            whileTap={{ scale: 0.98 }}
          >
            <IoIosCloseCircleOutline />
          </motion.button>
        )}
      </div>

      <Handle type='target' position={Position.Left} />
      <NodeContent>
        <div className='header'>
          <TiArrowLoop size={16} />
          <div className='titleNode'>
            <h4>{shortNameRender(data.label, 12)}</h4>
          </div>
        </div>
        <div className='main'>
          <Stack direction={'row'} alignItems={'center'} spacing={1}>
            <span>{t('flowBuilder.flow.nodes.loop.menu.label-attempts')}:</span>
            <span>
              <strong>{data.attempts}</strong>
            </span>
          </Stack>
        </div>
      </NodeContent>
    </NodeConditionContainer>
  );
};

export const LoopNode = memo(LoopNodeComponent);
