import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { TitleComponent } from '@/components/layout/TitleComponent';
import { FormReleaseLines } from './styled';
import { DescriptionInformation } from '@/components/layout/DescriptionInformation';
import { ButtonStyled } from '@/components/FormComponents/ButtonComponent';
import useFormControl from '@/hooks/useFormControl';
import { ControlInput } from '@/components/FormComponents/InputComponent';
import { useLineControl } from '@/hooks/dialControl/LineControl/useLineControl';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';
import { errorToast, successToast } from '@/components/FormComponents/Toast';

export const ReleaseLines = ({ controlStage }) => {
  const { t } = useTranslation();
  const { mutateReleaseLines, loadingReleaseLines } = useLineControl();

  const renderTxt = (word) => {
    return t(`dialControl.lineControl.selectedCampaign.releaseLines.${word}`);
  };

  const releaseLinesSchema = yup.object().shape({
    newLines: yup.number(),
  });

  const { handleSubmit, control, errors, reset } =
    useFormControl(releaseLinesSchema);

  const handleReleaseLines = (data) => {
    mutateReleaseLines(data, {
      onSuccess: () => {
        successToast(renderTxt('msg-success'));
        controlStage.toStage(0);
        reset();
      },
      onError: () => {
        errorToast(renderTxt('msg-error'));
      },
    });
  };

  const handleBack = () => {
    controlStage.previousStage();
  };

  return (
    <FormReleaseLines
      onSubmit={handleSubmit(handleReleaseLines)}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      {loadingReleaseLines && <BackDropCircularLoading />}
      <div className='main'>
        <TitleComponent textTitle={renderTxt('title')} />
        <DescriptionInformation textDescription={renderTxt('description')} />
        <ControlInput
          label={renderTxt('label-newLines')}
          control={control}
          nameControl={'newLines'}
          defaultValue={'0'}
          type='number'
          error={Boolean(errors.newLines)}
        />
      </div>
      <div className='footer'>
        <ButtonStyled
          variantstyle={'secondary'}
          size='small'
          onClick={handleBack}
        >
          {renderTxt('btn-cancel')}
        </ButtonStyled>
        <ButtonStyled type='submit' size='small'>
          {renderTxt('btn-add')}
        </ButtonStyled>
      </div>
    </FormReleaseLines>
  );
};
