import React, { memo } from 'react';
import { Handle, Position } from 'reactflow';

import { SubNodeContainer } from './styled';
import { useTranslation } from 'react-i18next';

const SubNodeComponent = ({ data }) => {
  const { t } = useTranslation();

  const renderLabelSubNode =
    data.label === 'Success'
      ? t('flowBuilder.flow.nodes.subNode.success')
      : t('flowBuilder.flow.nodes.subNode.error');

  if (data.label === 'timeout') {
    return (
      <SubNodeContainer typeHandler={'timeOutCondition'}>
        <div className='headerNode'>
          <span>{t('flowBuilder.flow.nodes.recVoz.label-timeOutNode')}</span>
        </div>
        <Handle type='source' position={Position.Right} />
      </SubNodeContainer>
    );
  }
  return (
    <SubNodeContainer typeHandler={data.handler}>
      <div className='headerNode'>
        <span>
          {!data.value ? renderLabelSubNode : data.label}{' '}
          {`${data?.value ?? ''}`}
        </span>
      </div>
      <Handle type='source' position={Position.Right} />
    </SubNodeContainer>
  );
};

export const SubNode = memo(SubNodeComponent);
