import { motion } from 'framer-motion';
import styled from 'styled-components';

export const FormEditGroupUf = styled(motion.form)`
  width: 100%;
  height: calc(100vh - 60px);
  padding: 2rem 2rem;
  display: grid;
  grid-template-rows: 1fr 40px;
`;

export const MainEditGroup = styled.main`
  width: 100%;
  height: 99%;
  padding: 1rem 0;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 2rem;
  @media (max-width: 1550px) {
    gap: 1rem;
  }
`;
export const FooterEditGroup = styled.footer`
  width: 100%;
  height: 100%;
  min-height: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 1.2rem;

  @media (max-height: 768px) {
    padding-bottom: 10px;
  }
`;
