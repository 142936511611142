import { IoIosCloseCircleOutline } from 'react-icons/io';
import { motion } from 'framer-motion';
import { MdEdit } from 'react-icons/md';
import styled from 'styled-components';
import { PiArrowBendDownRightBold } from 'react-icons/pi';
import { shortNameRender } from '@/components/pages/FlowBuilder/utils/shortNameRender';

const ContainerItem = styled.div`
  background: #607a9d;
  box-shadow: 6px 4px 5px -3px rgba(0, 0, 0, 0.24);

  width: 100%;
  padding: 4px;
  border-radius: 3px;
  display: grid;
  grid-template-columns: 1fr 40px;
  .description {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;

    svg {
      color: ${(props) => props.theme.colors.typography.textHeaderTable};
      font-size: 0.85rem;
    }
    span {
      font-size: 0.8rem;
      color: ${(props) => props.theme.colors.typography.textHeaderTable};
      font-weight: 600;
    }
  }

  .panel-item {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 4px;

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: transparent;
      border: none;

      svg {
        font-size: 0.9rem;
        color: ${(props) => props.theme.colors.bases.baseWhite};
      }
    }
  }
`;

export const ItemVariable = ({ itemVariable, handleRemove, handleEdit }) => {
  const { name, value } = itemVariable;
  return (
    <ContainerItem title={`${name} - ${value}`}>
      <div className='description'>
        <PiArrowBendDownRightBold />
        <span>
          {shortNameRender(name, 16)}: {shortNameRender(value, 16)}
        </span>
      </div>
      <div className='panel-item'>
        <motion.button
          onClick={handleEdit}
          whileTap={{ scale: 0.85 }}
          type='button'
        >
          <MdEdit />
        </motion.button>
        <motion.button
          onClick={handleRemove}
          whileTap={{ scale: 0.85 }}
          type='button'
        >
          <IoIosCloseCircleOutline size={'1.05rem'} />
        </motion.button>
      </div>
    </ContainerItem>
  );
};
