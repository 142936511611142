import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ContainerTableQueues = styled(motion.div)`
  width: 100%;
  height: 100%;
  min-height: 200px;
  user-select: none;
  overflow-y: auto;

  table {
    position: relative !important;
    width: 100%;
    thead {
      position: sticky;
      top: 0;
      z-index: 99999;
    }
    tbody {
      tr {
        height: 46px;
        td {
          z-index: 1;
          padding: 0 0.8rem;

          overflow: hidden;
          word-wrap: break-word;

          span {
            color: ${(props) => props.theme.colors.typography.baseText};
          }

          &:nth-child(1) {
            width: 60px;
          }
        }
      }
    }

    tfoot {
      position: sticky;
      bottom: 0;
      z-index: 99999;
    }
  }

  .colorDiscing {
    color: ${(props) => props.theme.colors.typography.baseText} !important;
  }

  .colorTalking {
    color: ${(props) => props.theme.colors.primary} !important;
  }

  .colorNull {
    color: ${(props) => props.theme.colors.secondary} !important;
  }

  @media (max-width: 1300px) {
    table {
      tbody {
        tr {
          height: 54px;
        }
      }
    }
  }
`;
