import { useCallback, useEffect, useId, useMemo, useState } from 'react';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { ContainerLoadList } from './styled';
import { ImportMailing } from './steps/ImportMailing';
import { CampaignSelect } from './steps/CampaignSelect';
import { ScheduleMailing } from './steps/ScheduleMailing';
import { PaginatorFlow } from '@/components/FormComponents/FlowPagination';
import { CompleteImport } from './steps/CompleteImport';
import { errorToast } from '@/components/FormComponents/Toast';
import {
  dataErrorsInCsv,
  dataMailing,
  dataPreviewImport,
  dataTotalRows,
  fileDataImport,
  isLoadingRenderFlow,
  useHeaderCsv,
} from '@/store/LoadList';
import { useStageControlLists } from './hooks/useStageControlLists';

const getWorkerInstance = () => {
  if (!window.workerInstance) {
    window.workerInstance = new Worker(
      new URL('../../../workers/mailing/index.js', import.meta.url),
      {
        type: 'module',
      }
    );
  }
  return window.workerInstance;
};

export const LoadList = () => {
  const { t } = useTranslation();

  const {
    stateProgress,
    setStateProgress,
    setProgress,
    currentStateMailing,
    nextProgress,
    setCurrentStateMailing,
  } = useStageControlLists();

  const [worker, setWorker] = useState(null);

  const [, setLoadingImport] = useAtom(isLoadingRenderFlow);
  const [file, setFile] = useAtom(fileDataImport);
  const [previewList, setPreviewList] = useAtom(dataPreviewImport);
  const [totalRows, setTotalRows] = useAtom(dataTotalRows);
  const [, setMailingData] = useAtom(dataMailing);
  const [, setErrosInCsv] = useAtom(dataErrorsInCsv);
  const [, setRenderHeaderCsv] = useAtom(useHeaderCsv);
  const [errorImport, setErrorImport] = useState(null);

  const resetConfigs = (killWorker = true) => {
    setFile(null);
    setPreviewList(null);
    setTotalRows(0);
    setStateProgress(0);
    setCurrentStateMailing(0);
    setProgress(0);
    setLoadingImport(false);
    setRenderHeaderCsv(false);
    setErrorImport(null);
    if (worker && killWorker) {
      worker.terminate();
      worker.onmessage = null;
      worker.onerror = null;
    }
  };

  useEffect(() => {
    const newWorker = getWorkerInstance();
    setWorker(newWorker);

    return () => {
      resetConfigs();
      newWorker.onmessage = null;
      newWorker.onerror = null;
    };
  }, []);

  useEffect(() => {
    if (worker) {
      if (file) {
        worker.postMessage({
          type: 'previewFile',
          dataFile: file,
        });
        nextProgress();
      }
    }
  }, [file]);

  const actions = useMemo(
    () => ({
      percent: (data) => setProgress(data.percent),
      previewList: (data) => {
        setPreviewList(data.preview);
        setTotalRows(data.totalRows);
      },
      isLoadingImport: (data) => setLoadingImport(data.dataLoading),
      isLoadingImportMailing: (data) => setLoadingImport(data.dataLoading),
      percentageImportMailing: (data) => setProgress(data.percent),
      dataCsvDownload: (data) => {
        const blob = new Blob([data.csv], {
          type: 'text/csv;charset=utf-8;',
        });
        setMailingData((prevData) => ({
          ...prevData,
          dataFile: blob,
        }));
        nextProgress();
      },
      errorImportCSV: (data) => setErrosInCsv(data.dataError),
      errorPreview: (data) => setErrorImport(data),
    }),
    []
  );

  const checkEmptyFile = (previewLista, totalRows) => {
    if (previewLista == null) return false;

    const allEmpty = previewList.flat().every((preview) => preview === '');
    const isSingleElementArray =
      previewList.length <= 1 || totalRows.length <= 1;

    return allEmpty || isSingleElementArray;
  };

  useEffect(() => {
    if (checkEmptyFile(previewList, totalRows)) {
      errorToast(t('listLoader.importMailing.msg-error-empty'));
      resetConfigs(false);
    }
  }, [previewList, totalRows]);

  useEffect(() => {
    if (errorImport) {
      errorToast(t('listLoader.importMailing.flow.msg-error-created'));
      resetConfigs();
    }
  }, [errorImport]);

  const handleWorkerMessage = useCallback(
    (event) => {
      const { type, ...data } = event.data;
      if (actions[type]) {
        actions[type](data);
      }
    },
    [actions]
  );

  useEffect(() => {
    if (!worker || !actions) return;
    worker.addEventListener('message', handleWorkerMessage);
    return () => {
      worker.removeEventListener('message', handleWorkerMessage);
    };
  }, [worker, handleWorkerMessage]);

  const flowMailing = [
    <CampaignSelect key={useId()} />,
    <ImportMailing key={useId()} worker={worker} />,
    <ScheduleMailing key={useId()} />,
    <CompleteImport key={useId()} />,
  ];

  return (
    <ContainerLoadList>
      <PaginatorFlow
        stageFlow={stateProgress}
        isImportStage={false}
        hasCreateCampaign={currentStateMailing === 4}
      />
      {flowMailing[currentStateMailing]}
    </ContainerLoadList>
  );
};
