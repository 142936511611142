import {
  idNodeToRemove,
  selectedNode,
  showMenuNodes,
} from '@/store/FlowBuilder';
import { useAtom } from 'jotai';
import React, { memo } from 'react';
import { Handle, Position } from 'reactflow';
import { NodeContent, NodeTabulationContainer } from './styled';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { motion } from 'framer-motion';
import { MdEdit } from 'react-icons/md';
import { TbVariableMinus } from 'react-icons/tb';
import { PiArrowBendDownRightBold, PiHandPointing } from 'react-icons/pi';
import { useTranslation } from 'react-i18next';
import { shortNameRender } from '../../../utils/shortNameRender';
import { usePermissionsFlow } from '@/hooks/FlowBuilder/usePermissionsFlow';

const VariablesNodeComponent = ({ id, data, selected }) => {
  const { t } = useTranslation();

  const { permissionsEdit, permissionsDelete, isSysAdmin } =
    usePermissionsFlow();

  const [, setIdNode] = useAtom(idNodeToRemove);
  const [, setShowMenu] = useAtom(showMenuNodes);
  const [nodeSelected, setSelectedNode] = useAtom(selectedNode);

  function removeDuplicates(arr) {
    const seenIds = new Set();
    const seenNames = new Set();
    return arr.filter((item) => {
      if (seenIds.has(item.id) || seenNames.has(item.name)) {
        return false;
      } else {
        seenIds.add(item.id);
        seenNames.add(item.name);
        return true;
      }
    });
  }

  const handleNode = () => {
    setSelectedNode({ id, data });
    setShowMenu(true);
  };

  const handleRemoveNode = (idNode) => {
    setIdNode(idNode);
    setShowMenu(false);
    idNode === nodeSelected?.id && setShowMenu(false);
  };

  const handleSelectedNode = () => {
    setSelectedNode({ id, data });
    setShowMenu(true);
  };

  const filterListVars = data.listVariables.filter(
    (item) => item.parent === id
  );

  const removedDuplicates = removeDuplicates(filterListVars);

  const permissionNodeEdit = permissionsEdit || isSysAdmin;
  const permissionNodeDelete = permissionsDelete || isSysAdmin;

  return (
    <NodeTabulationContainer
      selected={selected}
      errorConnect={data.errorConnect ? 1 : 0}
      onDoubleClick={() => handleNode()}
    >
      <div className='panelNode'>
        {permissionNodeEdit && (
          <motion.button
            onClick={() => handleSelectedNode()}
            whileTap={{ scale: 0.98 }}
          >
            <MdEdit size={16} />
          </motion.button>
        )}
        {permissionNodeDelete && (
          <motion.button
            onClick={() => handleRemoveNode(id)}
            whileTap={{ scale: 0.98 }}
          >
            <IoIosCloseCircleOutline />
          </motion.button>
        )}
      </div>

      <Handle type='target' position={Position.Left} />
      <NodeContent>
        <div className='header'>
          <TbVariableMinus size={16} />
          <div className='titleNode'>
            <h4>{shortNameRender(data.label, 12)}</h4>
            <span>{t('flowBuilder.flow.nodes.variables.label')}</span>
          </div>
        </div>
        <div className='main'>
          <ul>
            {removedDuplicates.length === 0 ? (
              <div
                className='no-variables-content '
                onClick={() => handleNode()}
              >
                <PiHandPointing />
                <span>
                  {t('flowBuilder.flow.nodes.variables.label-add-vars')}
                </span>
              </div>
            ) : (
              removedDuplicates.map((item) => {
                return (
                  <li key={item.id}>
                    <PiArrowBendDownRightBold />
                    <span>
                      <strong>{shortNameRender(item.name, 12)}</strong>
                    </span>
                    <span className='responseValueVar'>
                      {' '}
                      = {shortNameRender(item.value, 12)}
                    </span>
                  </li>
                );
              })
            )}
          </ul>
        </div>
      </NodeContent>
      <Handle type='source' position={Position.Right} />
    </NodeTabulationContainer>
  );
};

export const VariablesNode = memo(VariablesNodeComponent);
