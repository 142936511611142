import { Controller } from 'react-hook-form';
import { InputComponent } from '../InputComponent';
import { StyledAutoComplete } from './styled';
import { useTranslation } from 'react-i18next';
import { useId } from 'react';

export const AutoCompleteSelect = ({
  valueAutoComplete,
  optionsList,
  labelAutoComplete,
  helperText = '',
  errorAutoComplete,
  changeAutoComplete,
  readOnlyAutoComplete = false,
  disabled = false,
}) => {
  const { t } = useTranslation();

  return (
    <StyledAutoComplete
      value={valueAutoComplete ?? null}
      onChange={(event, newValue) => changeAutoComplete(newValue)}
      readOnly={readOnlyAutoComplete}
      size='small'
      id={`autocomplete_${useId()}`}
      options={optionsList}
      getOptionLabel={(option) => option.label ?? ''}
      isOptionEqualToValue={(option, value) => option.value == value?.value}
      noOptionsText={t('components.autoCompleteInput.noData')}
      disabled={disabled}
      renderInput={(params) => (
        <InputComponent
          label={labelAutoComplete ?? ''}
          {...params}
          helperText={helperText}
          error={errorAutoComplete}
          margin='dense'
          InputProps={{
            ...params.InputProps,
          }}
        />
      )}
    />
  );
};

export const ControlAutoComplete = ({
  control,
  nameControl,
  optionsList,
  ...rest
}) => {
  return (
    <Controller
      control={control}
      name={nameControl}
      render={({ field: { onChange, value } }) => (
        <AutoCompleteSelect
          {...rest}
          valueAutoComplete={value}
          changeAutoComplete={onChange}
          optionsList={optionsList}
          labelAutoComplete={rest.label}
        />
      )}
    />
  );
};
