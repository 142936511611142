import { ContainerNavigationFlowMailing } from './styled';
import { useAtom } from 'jotai';
import {
  dataConfirmationToSave,
  dataErrorsInCsv,
  hasSavedTemplateMailing,
  isLoadingChangeNewFlow,
  isLoadingRenderFlow,
  listTemplateMetadata,
} from '@/store/LoadList';
import { MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SelectComponent } from '@/components/FormComponents/SelectInput';
import { useEffect, useState } from 'react';
import { ErrorsList } from '../../../components/ErrorsList';
import LegendListsPopover from '../../../components/LegendListsPopover';

export const PanelFlowMailing = ({
  layoutSelected,
  setLayoutSelected,
  hasErrorColumnsFromCsv,
  mutateDataTemplate,
}) => {
  const { t } = useTranslation();
  const [hasSavedTemplate, setHasSavedTemplate] = useAtom(
    hasSavedTemplateMailing
  );
  const [errorsInCsv] = useAtom(dataErrorsInCsv);
  const [layoutSelectedConfig, setLayoutSelectedConfig] = useState('0');
  const [, setConfirmationToSave] = useAtom(dataConfirmationToSave);
  const [errorsRows, setErrorsRows] = useState([]);
  const [, setLoadingDataFlow] = useAtom(isLoadingRenderFlow);
  const [, setLoadingChangeNewFlow] = useAtom(isLoadingChangeNewFlow);

  const [metadataTemplates, setMetadataTemplates] =
    useAtom(listTemplateMetadata);

  const controller = new AbortController();

  useEffect(() => {
    if (errorsInCsv) {
      setErrorsRows(errorsInCsv);
    }
  }, [errorsInCsv]);

  useEffect(() => {
    if (layoutSelected !== '0') {
      setLayoutSelectedConfig(layoutSelected);
    }
  }, [layoutSelected]);

  const changeCurrenTemplate = (idTemplate) => {
    setLoadingDataFlow(true);
    mutateDataTemplate(
      { idTemplate: idTemplate, signal: controller.signal },
      {
        onSuccess: () => {
          setLayoutSelected(idTemplate);
          setHasSavedTemplate(true);
          setLoadingChangeNewFlow(false);
        },
      }
    );
  };

  const handleChangeTemplate = (idTemplate) => {
    setLoadingDataFlow(true);
    setLoadingChangeNewFlow(true);
    setLayoutSelectedConfig(idTemplate);
    const removeInitialNodes = metadataTemplates.filter(
      (metadata) => metadata.hasImported
    );
    setMetadataTemplates(removeInitialNodes);
    if (!hasSavedTemplate) {
      return setConfirmationToSave({
        open: true,
        next: () => changeCurrenTemplate(idTemplate),
      });
    }
    return changeCurrenTemplate(idTemplate);
  };

  return (
    <ContainerNavigationFlowMailing>
      <div className='content-detail'>
        <SelectComponent
          size={'small'}
          value={layoutSelectedConfig}
          onChange={(e) => handleChangeTemplate(e.target.value)}
        >
          <MenuItem value={'0'} dense disabled>
            {t('listLoader.importMailing.flow.option-template')}
          </MenuItem>

          {metadataTemplates.map((layout, i) => {
            return (
              <MenuItem key={i} value={layout.id} dense>
                {layout.name}
              </MenuItem>
            );
          })}
        </SelectComponent>

        <LegendListsPopover />

        {errorsRows.length > 0 && (
          <ErrorsList
            errorsRows={errorsRows}
            hasErrorColumnsFromCsv={hasErrorColumnsFromCsv}
          />
        )}
      </div>
    </ContainerNavigationFlowMailing>
  );
};
