import { errorToast, successToast } from '@/components/FormComponents/Toast';
import { useHandleFlow } from './useHandlerFlow';
import { useAtom } from 'jotai';
import {
  adminClientIdSelected,
  dataNodeErrorConnect,
  isSavedFlow,
  templateSelected,
} from '@/store/FlowBuilder';
import { useTranslation } from 'react-i18next';
import { sysAdmin, user } from '@/store/AuthenticatorStore';

export const useSaveFlow = (dataNodes) => {
  const { t } = useTranslation();
  const [clientSelected] = useAtom(adminClientIdSelected);
  const [, setIsSaved] = useAtom(isSavedFlow);
  const [currentUser] = useAtom(user);
  const [isSysAdmin] = useAtom(sysAdmin);
  const [, setNodeErrorConnect] = useAtom(dataNodeErrorConnect);

  const [template, setTemplate] = useAtom(templateSelected);
  const { mutateHandleFlow, loadingHandleFlow, refetch } = useHandleFlow();

  const filterIdProps = (nextId) => {
    const { nodes, edges } = dataNodes;
    const filteredEdges = edges.filter((item) => item.source === nextId);

    if (filteredEdges.length === 0) {
      return null;
    }

    const idNextNode = filteredEdges[0].target;

    const filteredNode = nodes.filter((item) => item.id === idNextNode);

    if (filteredNode.length === 0) {
      return null;
    }

    const nextNode = filteredNode[0].data.label;
    return nextNode;
  };

  const filterNextConditions = (nextId, valueCondition, labelCondition) => {
    const { nodes, edges } = dataNodes;

    const filteredSubs = nodes.filter(
      (item) =>
        item.parentNode === nextId &&
        item.data.label === labelCondition &&
        item.data.value === valueCondition
    );
    const idSuBNode = filteredSubs[0].id;
    const filteredEdges = edges.filter((item) => item.source === idSuBNode);
    if (filteredEdges.length === 0) {
      return null;
    }
    const idNextNode = filteredEdges[0].target;
    const filteredNode = nodes.filter((item) => item.id === idNextNode);
    if (filteredNode.length === 0) {
      return null;
    }

    const nextNode = filteredNode[0].data.label;
    return nextNode;
  };

  const filterErrorCondition = (nodeId) => {
    const { nodes } = dataNodes;
    const filteredSubs = nodes.filter(
      (item) =>
        item.parentNode === nodeId && item.data.handler === 'errorCondition'
    );
    const nextError = filterIdProps(filteredSubs[0].id);
    return nextError;
  };

  const filterSuccessCondition = (nodeId) => {
    const { nodes } = dataNodes;
    const filteredSubs = nodes.filter(
      (item) =>
        item.parentNode === nodeId && item.data.handler === 'successCondition'
    );
    const next = filterIdProps(filteredSubs[0].id);
    return next;
  };

  const filterNextStageLoop = (nodeId, typeSubNode) => {
    const { nodes } = dataNodes;
    const filteredSubs = nodes.filter(
      (item) => item.parentNode === nodeId && item.data.handler === typeSubNode
    );
    const next = filterIdProps(filteredSubs[0].id);
    return next;
  };

  const renderGotoValues = (nodeId) => {
    const { nodes } = dataNodes;
    const filteredSubs = nodes.filter((item) => item.parentNode === nodeId);

    const renderSubNode = filteredSubs.map((item) => {
      return {
        [item.data.label]: filterIdProps(item.id),
      };
    });

    const toObjectSubNodes = renderSubNode.reduce(
      (accumulatedObject, currentNode) => {
        const key = Object.keys(currentNode)[0];
        accumulatedObject[key] = currentNode[key];
        return accumulatedObject;
      },
      {}
    );

    return toObjectSubNodes;
  };

  const renderNextSubNodes = (nodeId) => {
    const { nodes } = dataNodes;
    const filteredSubs = nodes.filter((item) => item.parentNode === nodeId);

    const renderSubNode = filteredSubs.map((item) => {
      return {
        [item.data.label === 'Success' ? 'next' : 'nextError']: filterIdProps(
          item.id
        ),
      };
    });

    const toObjectSubNodes = renderSubNode.reduce(
      (accumulatedObject, currentNode) => {
        const key = Object.keys(currentNode)[0];
        accumulatedObject[key] = currentNode[key];
        return accumulatedObject;
      },
      {}
    );

    return toObjectSubNodes;
  };

  const renderLabelNode = (dataLabel) => {
    return dataLabel;
  };

  const renderTTSNode = (node) => {
    const dynamicLabelNameSetTTs = renderLabelNode(node.data.label);
    const dynamicLabelNamePlayTTs = `${renderLabelNode(
      node.data.label
    )}_PlayPrompt`;

    const pathSound = `sound:/var/lib/asterisk/sounds/globalVoicce/testes/${node.data.fileName}`;

    return [
      {
        [dynamicLabelNameSetTTs]: {
          prompt: node.data.prompt,
          handler: node.data.handler,
          next: dynamicLabelNamePlayTTs,
          fileName: node.data.fileName,
        },
      },
      {
        [dynamicLabelNamePlayTTs]: {
          handler: 'playFiles',
          validInput: [],
          allowSkip: true,
          sounds: [pathSound],
          ...renderNextSubNodes(node.id),
        },
      },
    ];
  };

  const renderConditionalList = (dataList, typeCondition, nodeId) => {
    const createCondToRender = dataList.map((item) => {
      return {
        var1: typeCondition.toLowerCase(),
        var2: item.value,
        compare: item.condition,
        goTo: filterNextConditions(nodeId, item.value, item.condition),
      };
    });

    return createCondToRender;
  };

  const checkNullableNodes = () => {
    const { nodes, setNodes } = dataNodes;
    if (nodes.length <= 1) {
      return errorToast(t('flowBuilder.flow.msg-erro-blank'));
    }

    const listObjectNode = nodes
      .map((node) => {
        if (node.type === 'initNode') {
          const dynamicLabelName = renderLabelNode(node.data.label);
          return {
            [dynamicLabelName]: {
              handler: 'dbQuery',
              query: 'newUser',
              next: filterIdProps(node.id),
            },
          };
        }
        if (node.type === 'playFiles') {
          const dynamicLabelName = renderLabelNode(node.data.label);
          return {
            [dynamicLabelName]: {
              allowSkip: node.data.allowSkip,
              handler: node.data.handler,
              next: filterIdProps(node.id),
              sounds: node.data.sounds,
              validInput: node.data.validInput,
            },
          };
        }
        if (node.type === 'recVoz') {
          const dynamicLabelName = renderLabelNode(node.data.label);
          return {
            [dynamicLabelName]: {
              handler: node.data.handler,
              timeout: node.data.timeout,
              allowSkip: node.data.allowSkip,
              nluEndpoint: node.data.nluEndpoint,
              validInput: node.data.validInput,
              qtdRepesca: Number(node.data.qtdRepesca),
              goTo: renderGotoValues(node.id),
              slot: `$${dynamicLabelName}_slot`,
              utterance: `$${dynamicLabelName}_utterance`,
            },
          };
        }
        if (node.type === 'hangUp') {
          const dynamicLabelName = renderLabelNode(node.data.label);
          return {
            [dynamicLabelName]: {
              handler: node.data.handler,
            },
          };
        }
        if (node.type === 'ttsVoicceLabs') {
          return renderTTSNode(node);
        }
        if (node.type === 'conditionalNode') {
          const dynamicLabelName = renderLabelNode(node.data.label);
          return {
            [dynamicLabelName]: {
              handler: node.data.handler,
              nextError: filterErrorCondition(node.id),
              conditions: renderConditionalList(
                node.data.conditionsList,
                node.data.typeCondition,
                node.id
              ),
            },
          };
        }
        if (node.type === 'dialplanVariables') {
          const dynamicLabelName = renderLabelNode(node.data.label);
          return {
            [dynamicLabelName]: {
              handler: node.data.handler,
              language: node.data.language,
              voice: node.data.voice,
              valueVoice: node.data.valueVoice,
              prosodyRate: node.data.prosodyRate,
              type: 'get',
              ...renderNextSubNodes(node.id),
            },
          };
        }
        if (node.type === 'disposition') {
          const dynamicLabelName = renderLabelNode(node.data.label) ?? '';
          return {
            [dynamicLabelName]: {
              handler: node.data.handler,
              cod_lig: node.data.label,
              next: filterIdProps(node.id),
            },
          };
        }
        if (node.type === 'setVariables') {
          const dynamicLabelName = renderLabelNode(node.data.label);
          return {
            [dynamicLabelName]: {
              handler: 'dialplanVariables',
              type: 'set',
              listVariables: node.data.listVariables,
              next: filterIdProps(node.id),
            },
          };
        }
        if (node.type === 'apiData') {
          const dynamicLabelName = renderLabelNode(node.data.label);
          return {
            [dynamicLabelName]: {
              handler: node.data.handler,
              method: node.data.method,
              headers: node.data.headers,
              parameters: node.data.parameters,
              soundRate: node.data.soundRate,
              waitApi: node.data.waitApi,
              response: node.data.response,
              configRequest: node.data.configRequest,
              next: filterSuccessCondition(node.id),
              nextError: filterErrorCondition(node.id),
            },
          };
        }
        if (node.type === 'monestIa') {
          const dynamicLabelName = renderLabelNode(node.data.label) ?? '';
          return {
            [dynamicLabelName]: {
              handler: node.data.handler,
              label: node.data.label,
              next: filterIdProps(node.id),
            },
          };
        }
        if (node.type === 'deepCenter') {
          const dynamicLabelName = renderLabelNode(node.data.label) ?? '';
          return {
            [dynamicLabelName]: {
              handler: node.data.handler,
              label: node.data.label,
              next: filterIdProps(node.id),
            },
          };
        }
        if (node.type === 'loop') {
          const dynamicLabelName = renderLabelNode(node.data.label) ?? '';
          return {
            [dynamicLabelName]: {
              handler: node.data.handler,
              label: node.data.label,
              attempts: node.data.attempts,
              looping: filterNextStageLoop(node.id, 'loopingVoicce'),
              complete: filterNextStageLoop(node.id, 'completeVoicce'),
            },
          };
        }
        if (node.type === 'transferQueue') {
          const dynamicLabelName = renderLabelNode(node.data.label);
          return {
            [dynamicLabelName]: {
              handler: node.data.handler,
              typeTransfer: node.data.typeTransfer,
              transferTo: node.data.transferTo,
              mcdu: node.data.mcdu ? node.data.mcdu : false,
              next: filterSuccessCondition(node.id),
              nextError: filterErrorCondition(node.id),
            },
          };
        }

        return null;
      })
      .filter((item) => item !== null);

    const flatListNodes = listObjectNode.flat();

    function hasEmpty(obj) {
      return Object.keys(obj).some(function (key) {
        return key === '';
      });
    }

    let checkEmptyLabels = flatListNodes.filter(hasEmpty);

    if (checkEmptyLabels.length > 0) {
      errorToast(t('flowBuilder.flow.msg-erro-name'));
      return null;
    }

    const checkNextNullable = flatListNodes
      .filter((node) => {
        for (let key in node) {
          if (node[key].handler === 'conditionalNode') {
            const listConditions = node[key]['conditions'];
            const filterNullGoto = listConditions.filter(
              (item) => item.goTo === null
            );
            if (filterNullGoto.length > 0) {
              return true;
            }
          }

          if (node[key].handler === 'loop') {
            if (!node[key].complete || !node[key].looping) {
              return true;
            }
          }

          if (node[key].handler === 'recVoz') {
            const listGoto = node[key].goTo;
            for (let value in listGoto) {
              if (listGoto[value] === null) {
                return true;
              }
            }
          }

          if (node[key].next === null || node[key].nextError === null) {
            return true;
          }
        }
        return false;
      })
      .map((item) => {
        const nameNode = Object.keys(item)[0];
        const regex = /_PlayPrompt/;
        const hasPromptNode = regex.test(nameNode);

        if (hasPromptNode) {
          const separateNameNode = nameNode.split('_')[0];
          return separateNameNode;
        }

        return nameNode;
      });

    const setErrorNode = nodes.map((item) => {
      const hasNull = checkNextNullable.includes(item.data.label);

      if (hasNull) {
        setNodeErrorConnect(item);
        return {
          ...item,
          data: {
            ...item.data,
            errorConnect: true,
          },
        };
      }
      return {
        ...item,
        data: {
          ...item.data,
          errorConnect: false,
        },
      };
    });

    setNodes(setErrorNode);

    if (checkNextNullable.length > 0) {
      errorToast(t('flowBuilder.flow.msg-erro-connect'));
      return null;
    }

    return true;
  };

  const renderListVars = (id, listVars) => {
    const filterVarNode = listVars.filter((item) => item.parent === id);
    return filterVarNode;
  };

  const handleRenderNodes = (description = '') => {
    const { nodes } = dataNodes;

    if (nodes.length <= 1) {
      return errorToast(t('flowBuilder.flow.msg-erro-blank'));
    }

    const listObjectNode = nodes
      .map((node) => {
        if (node.type === 'initNode') {
          const dynamicLabelName = renderLabelNode(node.data.label);
          return {
            [dynamicLabelName]: {
              handler: 'dbQuery',
              query: 'newUser',
              next: filterIdProps(node.id),
            },
          };
        }
        if (node.type === 'playFiles') {
          const dynamicLabelName = renderLabelNode(node.data.label);
          return {
            [dynamicLabelName]: {
              allowSkip: node.data.allowSkip,
              handler: node.data.handler,
              next: filterIdProps(node.id),
              sounds: node.data.sounds,
              validInput: node.data.validInput,
            },
          };
        }
        if (node.type === 'recVoz') {
          const dynamicLabelName = renderLabelNode(node.data.label);
          return {
            [dynamicLabelName]: {
              handler: node.data.handler,
              timeout: node.data.timeout,
              allowSkip: node.data.allowSkip,
              nluEndpoint: node.data.nluEndpoint,
              validInput: node.data.validInput,
              qtdRepesca: Number(node.data.qtdRepesca),
              goTo: renderGotoValues(node.id),
              slot: `$${dynamicLabelName}_slot`,
              utterance: `$${dynamicLabelName}_utterance`,
            },
          };
        }
        if (node.type === 'hangUp') {
          const dynamicLabelName = renderLabelNode(node.data.label);
          return {
            [dynamicLabelName]: {
              handler: node.data.handler,
            },
          };
        }
        if (node.type === 'ttsVoicceLabs') {
          return renderTTSNode(node);
        }
        if (node.type === 'conditionalNode') {
          const dynamicLabelName = renderLabelNode(node.data.label);
          return {
            [dynamicLabelName]: {
              handler: node.data.handler,
              nextError: filterErrorCondition(node.id),
              conditions: renderConditionalList(
                node.data.conditionsList,
                node.data.typeCondition,
                node.id
              ),
            },
          };
        }
        if (node.type === 'dialplanVariables') {
          const dynamicLabelName = renderLabelNode(node.data.label);
          return {
            [dynamicLabelName]: {
              handler: node.data.handler,
              language: node.data.language,
              voice: node.data.voice,
              valueVoice: node.data.valueVoice,
              prosodyRate: node.data.prosodyRate,
              type: 'get',
              ...renderNextSubNodes(node.id),
            },
          };
        }
        if (node.type === 'disposition') {
          const dynamicLabelName = renderLabelNode(node.data.label) ?? '';
          return {
            [dynamicLabelName]: {
              handler: node.data.handler,
              cod_lig: node.data.label,
              next: filterIdProps(node.id),
            },
          };
        }
        if (node.type === 'setVariables') {
          const dynamicLabelName = renderLabelNode(node.data.label);
          return {
            [dynamicLabelName]: {
              handler: 'dialplanVariables',
              type: 'set',
              listVariables: renderListVars(node.id, node.data.listVariables),
              next: filterIdProps(node.id),
            },
          };
        }
        if (node.type === 'apiData') {
          const dynamicLabelName = renderLabelNode(node.data.label);
          return {
            [dynamicLabelName]: {
              handler: node.data.handler,
              method: node.data.method,
              headers: node.data.headers,
              parameters: node.data.parameters,
              soundRate: node.data.soundRate,
              waitApi: node.data.waitApi,
              response: node.data.response,
              configRequest: node.data.configRequest,
              next: filterSuccessCondition(node.id),
              nextError: filterErrorCondition(node.id),
            },
          };
        }

        if (node.type === 'monestIa') {
          const dynamicLabelName = renderLabelNode(node.data.label) ?? '';
          return {
            [dynamicLabelName]: {
              handler: node.data.handler,
              label: node.data.label,
              next: filterIdProps(node.id),
            },
          };
        }
        if (node.type === 'deepCenter') {
          const dynamicLabelName = renderLabelNode(node.data.label) ?? '';
          return {
            [dynamicLabelName]: {
              handler: node.data.handler,
              label: node.data.label,
              next: filterIdProps(node.id),
            },
          };
        }

        if (node.type === 'loop') {
          const dynamicLabelName = renderLabelNode(node.data.label) ?? '';
          return {
            [dynamicLabelName]: {
              handler: node.data.handler,
              label: node.data.label,
              attempts: node.data.attempts,
              looping: filterNextStageLoop(node.id, 'loopingVoicce'),
              complete: filterNextStageLoop(node.id, 'completeVoicce'),
            },
          };
        }

        if (node.type === 'transferQueue') {
          const dynamicLabelName = renderLabelNode(node.data.label);
          return {
            [dynamicLabelName]: {
              handler: node.data.handler,
              typeTransfer: node.data.typeTransfer,
              transferTo: node.data.transferTo,
              mcdu: node.data.mcdu ? node.data.mcdu : false,
              next: filterSuccessCondition(node.id),
              nextError: filterErrorCondition(node.id),
            },
          };
        }

        return null;
      })
      .filter((item) => item !== null);

    const flatListNodes = listObjectNode.flat();

    function hasEmpty(obj) {
      return Object.keys(obj).some(function (key) {
        return key === '';
      });
    }

    let checkEmptyLabels = flatListNodes.filter(hasEmpty);

    if (checkEmptyLabels.length > 0) {
      errorToast(t('flowBuilder.flow.msg-erro-name'));
      return null;
    }

    const checkNextNullable = flatListNodes.filter((node) => {
      for (let key in node) {
        if (node[key].handler === 'conditionalNode') {
          const listConditions = node[key]['conditions'];
          const filterNullGoto = listConditions.filter(
            (item) => item.goTo === null
          );
          if (filterNullGoto.length > 0) {
            return true;
          }
        }

        if (node[key].handler === 'loop') {
          if (!node[key].complete || !node[key].looping) {
            return true;
          }
        }

        if (node[key].handler === 'recVoz') {
          const listGoto = node[key].goTo;
          for (let value in listGoto) {
            if (listGoto[value] === null) {
              return true;
            }
          }
        }

        if (node[key].next === null || node[key].nextError === null) {
          return true;
        }
      }
      return false;
    });

    if (checkNextNullable.length > 0) {
      errorToast(t('flowBuilder.flow.msg-erro-connect'));
      return null;
    }

    const objetoResultante = flatListNodes.reduce((acumulador, objeto) => {
      const chave = Object.keys(objeto)[0];
      if (chave === '' || chave === '_PlayPrompt' || !chave || !chave.length) {
        return false;
      }
      acumulador[chave] = objeto[chave];
      return acumulador;
    }, {});

    if (!objetoResultante) {
      return errorToast(t('flowBuilder.flow.msg-erro-name'));
    }

    const flowData = {
      nameIVR: template.nameIVR,
      descriptionIVR: description,
      variablesGlobal: template.variablesGlobal,
      codeLigs: template.codeLigs,
      nlus: template.nlus,
      flow: objetoResultante,
      nodes: dataNodes.nodes,
      edges: dataNodes.edges,
      client_id:
        clientSelected !== '0' && isSysAdmin
          ? clientSelected
          : currentUser.client_id,
      user_id: currentUser.user_id,
    };

    mutateHandleFlow(flowData, {
      onSuccess: (data) => {
        setTemplate(data.objeto);
        successToast(t('flowBuilder.flow.msg-save-flow'));
        setIsSaved(true);
        refetch();
      },
    });

    return flowData;
  };

  return { handleRenderNodes, loadingHandleFlow, checkNullableNodes };
};
