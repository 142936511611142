import { useTheme } from 'styled-components';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  ContainerNotAllowedMicrophone,
  ContentNotAllowedMicrophone,
  TitleAccordion,
} from './styled';

import { BiErrorCircle, BiRightArrowAlt } from 'react-icons/bi';
import { Stack } from '@mui/material';
import { List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const NotAllowedMicrophone = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const itensAllowPermissionChrome = [
    t('agents.notAllowedMicrophone.chromeTutorial.firstStep'),
    t('agents.notAllowedMicrophone.chromeTutorial.secondStep'),
    t('agents.notAllowedMicrophone.chromeTutorial.thirtyStep'),
    t('agents.notAllowedMicrophone.chromeTutorial.fourthStep'),
    t('agents.notAllowedMicrophone.chromeTutorial.fifthStep'),
    t('agents.notAllowedMicrophone.chromeTutorial.sixthStep'),
  ];

  return (
    <ContainerNotAllowedMicrophone>
      <ContentNotAllowedMicrophone>
        <Stack
          direction={'row'}
          alignItems='center'
          spacing={2}
          className='title-not-allowed'
        >
          <BiErrorCircle size={30} />
          <h1>{t('agents.notAllowedMicrophone.title')}</h1>
        </Stack>
        <p>{t('agents.notAllowedMicrophone.firstSentence')}</p>
        <div>
          <Accordion sx={{ background: theme.colors.containers.colorCard }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1a-content'
              id='panel1a-header'
            >
              <TitleAccordion>Google Chrome</TitleAccordion>
            </AccordionSummary>
            <AccordionDetails>
              <List sx={{ color: theme.colors.typography.baseText }}>
                {itensAllowPermissionChrome.map((item, i) => {
                  return (
                    <ListItem key={i}>
                      <ListItemIcon>
                        <BiRightArrowAlt
                          color={theme.colors.typography.baseTitle}
                        />
                      </ListItemIcon>
                      <ListItemText>{item}</ListItemText>
                    </ListItem>
                  );
                })}
              </List>
            </AccordionDetails>
          </Accordion>
        </div>
      </ContentNotAllowedMicrophone>
    </ContainerNotAllowedMicrophone>
  );
};
