import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ContainerConfig = styled(motion.div)`
  display: grid;
  grid-template-rows: 1fr 30px;
`;

export const MainConfigForm = styled.main`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 95%;

  p {
    color: ${(props) => props.theme.colors.typography.baseTitle};
  }

  .success-checkmark {
    width: 80px;
    height: 80px;
    margin: 0 auto;
    user-select: none;

    .check-icon {
      width: 80px;
      height: 80px;
      position: relative;
      border-radius: 50%;
      box-sizing: content-box;
      border: 4px solid #4caf50;

      &::before {
        top: 3px;
        left: -2px;
        width: 30px;
        transform-origin: 100% 50%;
        border-radius: 100px 0 0 100px;
      }

      &::after {
        top: 0;
        left: 30px;
        width: 60px;
        transform-origin: 0 50%;
        border-radius: 0 100px 100px 0;
        animation: rotate-circle 4.25s ease-in;
      }

      &::before,
      &::after {
        content: '';
        height: 100px;
        position: absolute;
        background: transparent;
        transform: rotate(-45deg);
      }

      .icon-line {
        height: 5px;
        background-color: #4caf50;
        display: block;
        border-radius: 2px;
        position: absolute;
        z-index: 10;

        &.line-tip {
          top: 46px;
          left: 14px;
          width: 25px;
          transform: rotate(45deg);
          animation: icon-line-tip 0.75s;
        }

        &.line-long {
          top: 38px;
          right: 8px;
          width: 47px;
          transform: rotate(-45deg);
          animation: icon-line-long 0.75s;
        }
      }

      .icon-circle {
        top: -4px;
        left: -4px;
        z-index: 10;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        position: absolute;
        box-sizing: content-box;
        border: 4px solid rgba(76, 175, 80, 0.5);
      }

      .icon-fix {
        top: 8px;
        width: 5px;
        left: 26px;
        z-index: 1;
        height: 85px;
        position: absolute;
        transform: rotate(-45deg);
        background-color: transparent;
      }
    }
  }

  @keyframes rotate-circle {
    0% {
      transform: rotate(-45deg);
    }
    5% {
      transform: rotate(-45deg);
    }
    12% {
      transform: rotate(-405deg);
    }
    100% {
      transform: rotate(-405deg);
    }
  }

  @keyframes icon-line-tip {
    0% {
      width: 0;
      left: 1px;
      top: 19px;
    }
    54% {
      width: 0;
      left: 1px;
      top: 19px;
    }
    70% {
      width: 50px;
      left: -8px;
      top: 37px;
    }
    84% {
      width: 17px;
      left: 21px;
      top: 48px;
    }
    100% {
      width: 25px;
      left: 14px;
      top: 45px;
    }
  }

  @keyframes icon-line-long {
    0% {
      width: 0;
      right: 46px;
      top: 54px;
    }
    65% {
      width: 0;
      right: 46px;
      top: 54px;
    }
    84% {
      width: 55px;
      right: 0px;
      top: 35px;
    }
    100% {
      width: 47px;
      right: 8px;
      top: 38px;
    }
  }
`;

export const FooterConfigForm = styled.footer`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 8px;
`;

export const DescriptionFlow = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.containers.colorCard};
  border-radius: 3px;
  padding: 1rem;
  display: flex;
  gap: 1rem;

  .item__screen {
    display: flex;
    gap: 1rem;

    .item__data_list {
      width: 100%;
      ul {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 4px;
        li {
          margin-left: -4px;
          list-style: none;
          font-size: 0.75rem;
          color: ${(props) => props.theme.colors.typography.baseText};
        }
      }
    }
    span {
      font-size: 0.8rem;
      &:first-child {
        font-weight: bold;
        color: ${(props) => props.theme.colors.typography.baseTitle};
        width: 120px;
      }
      &:last-child {
        color: ${(props) => props.theme.colors.typography.baseText};
      }
    }
  }
`;
