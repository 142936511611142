import styled, { css } from 'styled-components';
import { Button } from '@mui/material';

export const StyledButton = styled(Button)`
  ${(props) =>
    props.active
      ? css`
          background-color: ${props.theme.colors.active} !important;
        `
      : css`
          background-color: ${props.theme.colors[
            props.variantstyle
          ]} !important;
        `}

  &:disabled {
    opacity: 0.6;
    color: ${(props) => props.theme.colors.bases.baseWhite} !important;
  }
`;
