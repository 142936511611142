import { TitleComponent } from '@/components/layout/TitleComponent';
import { Button, Tooltip, Zoom } from '@mui/material';
import { HiOutlineInformationCircle } from 'react-icons/hi';
import styled from 'styled-components';

export const ContainerTitleMenuNode = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  button {
    min-width: 30px;

    svg {
      color: ${(props) => props.theme.colors.typography.baseText} !important;
      font-size: 1.2rem;
    }
  }
`;

export const TitleMenuNode = ({
  title = 'Title',
  description = 'No Description',
}) => {
  return (
    <ContainerTitleMenuNode>
      <TitleComponent textTitle={title} />
      <Tooltip
        TransitionComponent={Zoom}
        placement='left-end'
        arrow
        title={description}
      >
        <Button>
          <HiOutlineInformationCircle />
        </Button>
      </Tooltip>
    </ContainerTitleMenuNode>
  );
};
