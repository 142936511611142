import { InputAdornment, Stack } from '@mui/material';
import { useAtom } from 'jotai';
import { useCallback, useDeferredValue, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiSearch } from 'react-icons/bi';
import { RiRouteLine } from 'react-icons/ri';
import {
  dataRoutingProfile,
  stateRenderRoutingProfiles,
} from '@store/UsersAgentsStore/routingProfiles';
import { ButtonStyled } from '@components/FormComponents/ButtonComponent';
import { InputComponent } from '@components/FormComponents/InputComponent';
import { useRoutingProfile } from '@hooks/UserAgents/useRoutingProfile';
import { BackDropCircularLoading } from '@components/layout/LoadingComponent';
import { TitleComponent } from '@components/layout/TitleComponent';
import { TableRouting } from './TableRoutingProfile';
import { useClients } from '@/hooks/Clients/useClients';
import { usePermissionsRoutingProfile } from '../../hooks/usePermissionsRoutingProfile';
import { NoPermissionCard } from '@/components/layout/NoPermissionCard';

export const HomeRoutingProfile = () => {
  const { t } = useTranslation();
  const { permissionsCreate, isSysAdmin } = usePermissionsRoutingProfile();

  const { isLoadingDataClients } = useClients(true);

  const { isLoadingRoutingProfile, isFetchingRouting } = useRoutingProfile();
  const [dataRoutingProfileTable] = useAtom(dataRoutingProfile);

  const [, setCurrentRenderRoutingProfile] = useAtom(
    stateRenderRoutingProfiles
  );

  const handleAddRoute = useCallback(
    () => setCurrentRenderRoutingProfile({ current: 1, previous: 0 }),
    [setCurrentRenderRoutingProfile]
  );

  const [filteredData, setFilteredData] = useState('');
  const deferredFilteredData = useDeferredValue(filteredData);

  const filterTableNameRouting = dataRoutingProfileTable.filter((data) =>
    data.name.toLowerCase().includes(deferredFilteredData.toLowerCase())
  );

  const isDataLoading =
    isLoadingRoutingProfile || isLoadingDataClients || isFetchingRouting;

  return (
    <>
      {isDataLoading && <BackDropCircularLoading />}

      <TitleComponent
        textTitle={t('routingProfile.homeRoutingProfiles.title')}
        iconTitle='routingProfile'
      />

      {!isSysAdmin ? (
        <NoPermissionCard
          message={t('routingProfile.homeRoutingProfiles.table.noData')}
        />
      ) : (
        <>
          <InputComponent
            value={filteredData}
            onChange={(e) => setFilteredData(e.target.value)}
            placeholder={t(
              'routingProfile.homeRoutingProfiles.placeholder-searchField'
            )}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <BiSearch />
                </InputAdornment>
              ),
            }}
          />
          <div className='content_btn_new_route'>
            <ButtonStyled
              onClick={handleAddRoute}
              size={'small'}
              disabled={!permissionsCreate}
            >
              <Stack direction={'row'} alignItems={'center'} spacing={1}>
                <RiRouteLine size={16} />
                <span>{t('routingProfile.homeRoutingProfiles.btn-add')}</span>
              </Stack>
            </ButtonStyled>
          </div>
          <TableRouting dataTable={filterTableNameRouting} />
        </>
      )}
    </>
  );
};
