import { Stack } from '@mui/system';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { ButtonStyled } from '@components/FormComponents/ButtonComponent';
import { ContainerRemoveTenant, FormRemoveTenant } from './styled';
import { TitleComponent } from '@components/layout/TitleComponent';
import { dataSelectedTenants } from '@/store/TenantsStore';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';

export const RemoveCallOperator = ({
  closeDialog,
  handleDeleteItem,
  loadingDeleteOperator,
}) => {
  const { t } = useTranslation();
  const [selectedData] = useAtom(dataSelectedTenants);

  const handleRemoveRoutingProfile = (e) => {
    e.preventDefault();
    handleDeleteItem();
  };

  return (
    <ContainerRemoveTenant>
      {loadingDeleteOperator && <BackDropCircularLoading />}

      <TitleComponent
        textTitle={'Remove Call Operator'}
        iconTitle='callOperator'
      />

      <FormRemoveTenant onSubmit={handleRemoveRoutingProfile}>
        <p>
          Are you sure you want to remove the call Operator?
          <strong>{selectedData?.name ?? ''}</strong>
        </p>
        <Stack direction={`row`} gap={1} justifyContent={'flex-end'}>
          <ButtonStyled
            variantstyle={'secondary'}
            onClick={closeDialog}
            size='size'
          >
            {t('tenants.home.table.btn-cancel')}
          </ButtonStyled>
          <ButtonStyled type='submit' size='size'>
            {t('tenants.home.table.btn-confirm')}
          </ButtonStyled>
        </Stack>
      </FormRemoveTenant>
    </ContainerRemoveTenant>
  );
};
