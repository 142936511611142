import { useAtom } from 'jotai';
import { ContainerNavBarAgents } from './styled';
import { RxHamburgerMenu } from 'react-icons/rx';

import { MdDarkMode, MdOutlineDarkMode } from 'react-icons/md';
import { isCloseItemAgents } from '@store/NavigationAgentsDashboard';
import { setCurrentTheme } from '@store/ThemeStore/theme';
import { useTranslation } from 'react-i18next';

export const NavBarAgent = () => {
  const { t } = useTranslation();
  const [themeAtom, changeTheme] = useAtom(setCurrentTheme);
  const [isClose, setIsClose] = useAtom(isCloseItemAgents);

  const closeMenu = () => {
    setIsClose(!isClose);
  };

  return (
    <ContainerNavBarAgents>
      <button onClick={closeMenu} aria-label='navbar'>
        <RxHamburgerMenu className={!isClose && 'rotate'} />
      </button>
      <div>
        <div className='titleComponent'>
          <h1>{t('agents.dashboard.titleNavigation')}</h1>
        </div>
        {themeAtom === 'light' ? (
          <MdOutlineDarkMode onClick={changeTheme} />
        ) : (
          <MdDarkMode onClick={changeTheme} />
        )}
      </div>
    </ContainerNavBarAgents>
  );
};
