import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

import { useAgents } from '@hooks/Agents/useAgents';
import { useLocalStorage } from '@hooks/useLocalStorage';
import { useControlTimeAgent } from '@hooks/Agents/useControlTimeAgent';

import { user } from '@store/AuthenticatorStore';
import {
  dataCallClient,
  dataCallInformation,
  initCallState,
  sessionStateAgent,
} from '@store/AgentsStore';

import { ButtonStyled } from '@components/FormComponents/ButtonComponent';
import { timeFormatEn } from '@/utils/formatsDateAndTime';
import { StopWatchComponent } from '@components/StopWatchComponent';

import { BtnMute, ContainerInCall, ContentInCall, ItemInCall } from './styled';
import {
  MdOutlineMic,
  MdPhoneDisabled,
  MdPhoneIphone,
  MdOutlineMicOff,
} from 'react-icons/md';
import { BsCircleFill } from 'react-icons/bs';

export const InCallService = () => {
  const { t } = useTranslation();
  const { saveLocalStorage } = useLocalStorage();
  const { removeLocalStatus } = useControlTimeAgent();
  const { mutateCloseCurrentCall } = useAgents();
  const [dataCall] = useAtom(dataCallInformation);
  const [dataCurrentClient, setDataCurrentClient] = useAtom(dataCallClient);
  const [currentSession] = useAtom(sessionStateAgent);
  const [currentUser] = useAtom(user);
  const [, setInitCallState] = useAtom(initCallState);
  const [isMuted, setIsMuted] = useState(false);

  useEffect(() => {
    removeLocalStatus('inCallStatus');
  }, []);

  useEffect(() => {
    if (dataCall) {
      if (dataCall.linha_dados) {
        if (dataCall.linha_dados) {
          const dataUserCall = {
            clientName: dataCall.linha_dados.nome_cliente ?? '-',
            dddPhone: `(${dataCall.linha_dados.ddd ?? '-'}) ${
              dataCall.linha_dados.fone ?? '-'
            }`,
            documentClient: dataCall.linha_dados.cpf ?? '-',
            origin: dataCall.linha_dados.origem ?? '-',
            startCall: dataCall.linha_dados.no_agente_datetime ?? null,
            listDialer: dataCall.linha_dados.id_tabela_disca ?? '-',
            clientId: dataCall.linha_dados.cod_link_int ?? '-',
            joker: dataCall.linha_dados.cod_link_char ?? '-',
          };
          saveLocalStorage('@dc', dataUserCall);
          setDataCurrentClient(dataUserCall);
        }
      }
    }
  }, []);

  const handleMuteCall = () => {
    if (currentSession) {
      if (!currentSession.isMuted().audio) {
        currentSession.mute();
        setIsMuted(true);
      } else {
        currentSession.unmute();
        setIsMuted(false);
      }
    }
  };

  const handleHangUpCall = () => {
    mutateCloseCurrentCall({ extension: currentUser.extension });
  };

  const initCurrentCall = timeFormatEn(new Date());

  useEffect(() => {
    return () => setInitCallState(initCurrentCall);
  }, []);

  return (
    <ContainerInCall initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <div className='headerCard'>
        <div className='titleCall'>
          <MdPhoneIphone />
          <h3>{t('agents.callInformation.titleCard')}</h3>
        </div>

        <div className='statusCallCard'>
          <BsCircleFill />
          <span>{t('agents.callInformation.statusCard')}</span>
        </div>
      </div>
      <ContentInCall>
        <div className='columnItem'>
          <ItemInCall>
            <span>{t('agents.callInformation.nameCard')}:</span>
            <span>{dataCurrentClient.clientName}</span>
          </ItemInCall>
          <ItemInCall>
            <span>{t('agents.callInformation.phoneCard')}:</span>
            <span>{dataCurrentClient.dddPhone}</span>
          </ItemInCall>
          <ItemInCall>
            <span>{t('agents.callInformation.documentCard')}:</span>
            <span>{dataCurrentClient.documentClient}</span>
          </ItemInCall>
        </div>

        <div className='columnItem'>
          <ItemInCall>
            <span>{t('agents.callInformation.nameDialerList')}:</span>
            <span>{dataCurrentClient.listDialer}</span>
          </ItemInCall>
          <ItemInCall>
            <span>{t('agents.callInformation.clientId')}:</span>
            <span>{dataCurrentClient.clientId}</span>
          </ItemInCall>
          <ItemInCall>
            <span>{t('agents.callInformation.joker')}:</span>
            <span>{dataCurrentClient.joker}</span>
          </ItemInCall>
        </div>

        <div className='columnItem noBorder'>
          <ItemInCall>
            <span>{t('agents.callInformation.typeCallCard')}:</span>
            <span>{dataCurrentClient.origin}</span>
          </ItemInCall>
          <ItemInCall>
            <span>{t('agents.callInformation.startTimeService')}:</span>
            <span> {timeFormatEn(dataCurrentClient.startCall)}</span>
          </ItemInCall>
          <ItemInCall>
            <span>{t('agents.callInformation.durationCallCard')}:</span>
            <span>
              <StopWatchComponent noBg />
            </span>
          </ItemInCall>
        </div>

        <div className='columnButtonsItem'>
          <BtnMute onClick={handleMuteCall} muted={isMuted}>
            {isMuted ? <MdOutlineMic /> : <MdOutlineMicOff />}
            {isMuted
              ? t('agents.callInformation.btnUnMuteCard')
              : t('agents.callInformation.btnMuteCard')}
          </BtnMute>
          <ButtonStyled variantstyle='secondary' onClick={handleHangUpCall}>
            <MdPhoneDisabled />
            {t('agents.callInformation.btnHangUp')}
          </ButtonStyled>
        </div>
      </ContentInCall>
    </ContainerInCall>
  );
};
