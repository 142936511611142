import { useState } from 'react';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { Stack, MenuItem, Box } from '@mui/material';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ButtonStyled } from '@/components/FormComponents/ButtonComponent';
import { currentFlowScreens, dataScreens } from '@/store/Screens';
import { TitleComponent } from '@/components/layout/TitleComponent';
import { ControlInput } from '@/components/FormComponents/InputComponent';
import { ControlSelect } from '@/components/FormComponents/SelectInput';
import { ControlRadio } from '@/components/FormComponents/RadioComponent';
import { MenuCreate } from './MenuCreate';
import { SubMenuCreate } from './SubMenuCreate';
import { successToast } from '@/components/FormComponents/Toast';
import { TitleMenu } from './TitleMenu';
import { useScreens } from '@/hooks/UserAgents/useScreens';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';
import {
  ContainerAddScreen,
  FooterAddScreen,
  FormAddScreen,
  MainAddScreen,
} from './styled';

export const CreateScreen = () => {
  const { t } = useTranslation();
  const [, setStateRenderScreens] = useAtom(currentFlowScreens);
  const { mutateCreateScreen, loadingCreateScreen } = useScreens();
  const [dataListScreens] = useAtom(dataScreens);

  const [useIframe, setUseIframe] = useState(false);

  const addNewClientSchema = yup.object().shape({
    name: yup.string().required(),
    description: yup.string().required(),
    type: yup.string().required(),
    isActive: yup.string(),
  });

  const [dataMenuForm, setDataMenuForm] = useState({
    areaMenu: '0',
    parent_id: '0',
    href: '0',
    icon: '0',
    sequence: 0,
    link: '',
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(addNewClientSchema),
  });

  const watchTypeSelect = watch('type');

  const resetForm = () => (
    reset(),
    setDataMenuForm({
      areaMenu: '0',
      parent_id: '0',
      href: '0',
      icon: '0',
      sequence: 0,
      link: '',
    })
  );

  const renderHref = (href, hasExternalUrl, link) => {
    if (!hasExternalUrl) {
      return href === '0' ? false : dataMenuForm.href;
    }
    return link;
  };

  const handleCreateScreen = (data) => {
    const dataNew = {
      isActive: data.isActive === t('screens.addScreen.option-enable'),
      name: data.name,
      description: data.description === '' ? false : data.description,
      slug: data.type,
      href: renderHref(dataMenuForm.href, useIframe, dataMenuForm.link),
      area: dataMenuForm.areaMenu === '0' ? false : dataMenuForm.areaMenu,
      icon: dataMenuForm.icon === '0' ? false : dataMenuForm.icon,
      locate_icon: dataMenuForm.icon,
      parent_id:
        !dataMenuForm?.parent_id || dataMenuForm?.parent_id === '0'
          ? false
          : dataMenuForm.parent_id,
      sequence: dataMenuForm.sequence,
      hasExternalUrl: useIframe,
    };

    mutateCreateScreen(dataNew, {
      onSuccess: () => {
        successToast(t('screens.addScreen.msg-success-create'));
        setStateRenderScreens(0);
        resetForm();
      },
    });
  };

  const renderSequence = (() => {
    if (dataListScreens) {
      const filterSequenceMenu = dataListScreens
        .map((item) => Number(item.sequence))
        .sort(function (a, b) {
          return a - b;
        });

      const cleanList = filterSequenceMenu.filter(
        (item, index) => filterSequenceMenu.indexOf(item) === index
      );

      return cleanList;
    }
  })();

  const renderTypeScreenCreate = ((typeScreen) => {
    switch (typeScreen) {
      case 'menu':
        return (
          <MenuCreate
            setDataMenuForm={setDataMenuForm}
            dataMenuForm={dataMenuForm}
            renderSequence={renderSequence}
            useIframe={useIframe}
            setUseIframe={setUseIframe}
          />
        );
      case 'submenu':
        return (
          <SubMenuCreate
            setDataMenuForm={setDataMenuForm}
            dataMenuForm={dataMenuForm}
            useIframe={useIframe}
            setUseIframe={setUseIframe}
          />
        );
      case 'titleMenu':
        return (
          <TitleMenu
            setDataMenuForm={setDataMenuForm}
            dataMenuForm={dataMenuForm}
            renderSequence={renderSequence}
          />
        );

      default:
        return null;
    }
  })(watchTypeSelect);

  const handleBack = () => {
    resetForm();
    setStateRenderScreens(0);
  };

  return (
    <ContainerAddScreen initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      {loadingCreateScreen && <BackDropCircularLoading />}
      <FormAddScreen onSubmit={handleSubmit(handleCreateScreen)}>
        <MainAddScreen>
          <TitleComponent
            textTitle={t('screens.addScreen.title')}
            iconTitle='screen'
          />
          <Stack spacing={1}>
            <Stack
              direction={'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
              spacing={2}
            >
              <Box>
                <TitleComponent
                  textTitle={t('screens.addScreen.sub-title-information')}
                />
              </Box>
              <Stack
                direction={'row'}
                alignItems={'center'}
                spacing={3}
                border={
                  errors.isActive ? '1px solid red' : '1px solid transparent'
                }
                padding={'0 8px'}
                borderRadius={'3px'}
              >
                <ControlRadio
                  control={control}
                  label='Status'
                  nameControl='sActive'
                  defaultValue={t('screens.addScreen.option-enable')}
                  labelValue1={t('screens.addScreen.option-enable')}
                  labelValue2={t('screens.addScreen.option-disable')}
                />
              </Stack>
            </Stack>
            <Stack direction={'row'} alignItems={'center'} spacing={3}>
              <ControlInput
                control={control}
                nameControl={'name'}
                label={t('screens.addScreen.label-name')}
                placeholder={t('screens.addScreen.label-name')}
                autoComplete='off'
                error={Boolean(errors.name)}
                helperText={errors.name ? errors.name.message : ' '}
              />
              <ControlInput
                control={control}
                nameControl={'description'}
                label={t('screens.addScreen.label-description')}
                placeholder={t('screens.addScreen.label-description')}
                autoComplete='off'
                error={Boolean(errors.description)}
                helperText={
                  errors.description ? errors.description.message : ' '
                }
              />
            </Stack>
            <ControlSelect
              control={control}
              nameControl={'type'}
              label={t('screens.addScreen.label-type')}
              error={Boolean(errors.type)}
              helperText={errors.type ? errors.type.message : '   '}
            >
              <MenuItem value={'0'} dense disabled>
                {t('screens.addScreen.option-select-type')}
              </MenuItem>
              <MenuItem value={'menu'} dense>
                {t('screens.addScreen.option-type-menu')}
              </MenuItem>
              <MenuItem value={'titleMenu'} dense>
                {t('screens.addScreen.option-type-title')}
              </MenuItem>
              <MenuItem value={'submenu'} dense>
                {t('screens.addScreen.option-type-subMenu')}
              </MenuItem>
            </ControlSelect>
          </Stack>

          <Stack spacing={3}>
            <Stack direction={'row'} spacing={2}>
              {renderTypeScreenCreate}
            </Stack>
          </Stack>
        </MainAddScreen>
        <FooterAddScreen>
          <ButtonStyled
            variantstyle={'secondary'}
            size={'small'}
            onClick={handleBack}
          >
            {t('screens.addScreen.btn-cancel')}
          </ButtonStyled>
          <ButtonStyled type='submit' size={'small'}>
            {t('screens.addScreen.btn-confirm')}
          </ButtonStyled>
        </FooterAddScreen>
      </FormAddScreen>
    </ContainerAddScreen>
  );
};
