import { ContainerPanelQueueMonitor } from './styled';
import { InputAdornment } from '@mui/material';
import { InputComponent } from '@/components/FormComponents/InputComponent';
import { BiSearch } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';

export const PanelQueuesMonitor = ({ fieldFilter, setFieldFilter }) => {
  const { t } = useTranslation();

  return (
    <ContainerPanelQueueMonitor>
      <InputComponent
        placeholder={t('queueMonitor.placeHolder-filter')}
        value={fieldFilter}
        onChange={(e) => setFieldFilter(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <BiSearch />
            </InputAdornment>
          ),
        }}
      />
    </ContainerPanelQueueMonitor>
  );
};
