import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

export const ContainerAgents = styled(motion.main)`
  width: 100%;
  height: calc(100vh - 70px);
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 2rem;
  overflow-y: scroll;
`;

export const ContainerMainAgents = styled.div`
  width: 100%;
  height: 100%;

  display: grid;
  grid-template-rows: 120px 1fr;
  padding: 0rem;

  @media (max-width: 1280px) {
    grid-template-rows: 140px 1fr;
  }

  /*   @media (max-width: 768px) {
    grid-template-rows: 140px 1fr;
  } */
`;

export const AgentsPanelControl = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1rem;

  .panel_control {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .active_button {
      background-color: ${(props) => props.theme.colors.active} !important;
    }

    .opacity_button {
      opacity: 0.9 !important;
    }

    ${(props) =>
      props.status === 'LIVRE' || props.status === 'PAUSA'
        ? css`
            @media (max-width: 1280px) {
              flex-direction: column;
              gap: 1rem;
            }
          `
        : css``}

    button {
      ${(props) =>
        props.status
          ? css`
              background-color: ${(props) =>
                props.theme.colors.agentColors[props.status]} !important;
            `
          : css``}
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      padding: 0.65rem 1rem;

      &:disabled {
        opacity: 0.3 !important;

        svg {
          color: ${(props) => props.theme.colors.bases.baseWhite} !important;
        }
      }

      @media (max-width: 530px) {
        padding: 0.7rem 0.4rem;
        font-size: 0.45rem;
      }
      svg {
        font-size: 1rem;

        @media (max-width: 530px) {
          display: none;
        }
      }

      span {
        font-style: normal;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 0px;
        letter-spacing: 0.115em;
        text-transform: uppercase;
        padding-top: 1px;
        color: ${(props) => props.theme.colors.bases.baseWhite};
        line-height: 0 !important;

        @media (max-width: 530px) {
          line-height: 1 !important;
        }
      }
    }

    .btn_out_dialer {
      button {
        background-color: ${(props) =>
          props.theme.colors.agentColors.DISCONNECTED} !important;

        svg {
          @media (max-width: 1070px) {
            display: block;
          }
          @media (max-width: 490px) {
            display: none;
          }
        }
      }
    }

    .panel_part {
      display: flex;
      justify-content: space-between;
      gap: 1rem;

      ${(props) =>
        props.status === 'LIVRE' || props.status === 'PAUSA'
          ? css`
              @media (max-width: 1280px) {
                justify-content: flex-start;
              }
            `
          : css``}
    }
  }
`;
