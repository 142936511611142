import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ContainerCallHistory = styled(motion.div)`
  width: 100%;
  display: grid;
  grid-template-rows: auto 1fr 54px;
  gap: 2rem;

  @media (max-width: 1620px) {
    grid-template-rows: auto 1fr 50px;
  }

  @media (max-width: 1100px) {
    padding-top: 1rem;
  }
`;

export const ContentTable = styled.div`
  width: 100%;
  height: 56vh;
  user-select: none;

  overflow-x: auto;

  @media (max-width: 1620px) {
    height: calc(46vh);
  }

  @media (max-width: 1440px) {
    height: calc(47vh);
  }

  @media (max-width: 1100px) {
    height: calc(44vh);
  }
`;

export const FooterCall = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 0.5rem;
`;
