import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ContainerEditCallOperator = styled(motion.div)`
  width: 100%;
  height: auto;
  overflow-y: auto;
  padding: 2.5rem 2rem 1rem 2rem;
`;

export const FormCallOperator = styled.form`
  width: 100%;
  height: calc(100vh - 130px);
  display: grid;
  grid-template-rows: 30px 1fr 30px;

  .mainCallOperator {
    padding: 3rem 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 1rem;
  }

  .footerAddNewCallOperator {
    width: 100%;
    height: 100%;
    min-height: 30px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 1rem;

    @media (max-height: 768px) {
      padding-bottom: 10px;
    }
  }
`;
