// import { motion } from 'framer-motion';
import styled from 'styled-components';

export const FormAddNewScreen = styled.form`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 1fr 40px;
  padding: 2rem 0 0 0;
`;
export const MainForm = styled.main`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const FooterForm = styled.footer`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  padding: 0.35rem 0;
`;
