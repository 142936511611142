import { useState } from 'react';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { InputAdornment, Stack } from '@mui/material';
import { BiSearch } from 'react-icons/bi';
import { FaArrowLeft } from 'react-icons/fa';
import { TbScreenShare } from 'react-icons/tb';
import { ContentScreens, HeaderListScreens } from './styled';
import { TitleComponent } from '@/components/layout/TitleComponent';
import { InputComponent } from '@/components/FormComponents/InputComponent';
import { ButtonStyled } from '@/components/FormComponents/ButtonComponent';
import { TableScreens } from './TableScreens';
import { currentFlowScreens, dataScreens } from '@/store/Screens';
import { removeDuplicates } from '@/utils/removeDuplicateItemsArr';
import { stateRenderRoutingProfiles } from '@/store/UsersAgentsStore/routingProfiles';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';
import { stateRenderUsers } from '@/store/UsersAgentsStore/userAgentsStore';
import { stateRenderSecurityProfiles } from '@/store/UsersAgentsStore/securityProfiles';
import { useScreens } from '@/hooks/UserAgents/useScreens';
import { usePermissionsScreens } from '../../hooks/usePermissionsScreens';
import { NoPermissionCard } from '@/components/layout/NoPermissionCard';

export const HomeScreens = ({ origin }) => {
  const { t } = useTranslation();
  const { isSysAdmin, permissionsCreate } = usePermissionsScreens();
  const {
    isLoadingDataScreen,
    isFetchingCreate,
    mutateDeleteScreen,
    loadingDeleteScreen,
  } = useScreens();
  const [dataListScreens] = useAtom(dataScreens);
  const [, setStateRenderScreens] = useAtom(currentFlowScreens);
  const [, setCurrentRenderRoutingProfile] = useAtom(
    stateRenderRoutingProfiles
  );
  const [, setCurrentStateSecurity] = useAtom(stateRenderSecurityProfiles);

  const [, setStateRenderUsersAgents] = useAtom(stateRenderUsers);

  const handleBackRoutesProfile = () => {
    if (origin === 'userAgents') {
      return setStateRenderUsersAgents(0);
    }

    if (origin === 'securityProfile') {
      return setCurrentStateSecurity(0);
    }

    setCurrentRenderRoutingProfile((previousState) => ({
      current: previousState.previous,
      previous: 0,
    }));
  };

  const [searchScreen, setSearchScreen] = useState('');

  const filterAllTableScreens = (dataFilter) => {
    if (!dataFilter || !searchScreen) {
      return dataFilter;
    }

    try {
      const filterTableName = dataFilter.filter((data) =>
        data.name.toLowerCase().includes(searchScreen.toLowerCase())
      );
      const newArrFiltered = [...filterTableName];
      return removeDuplicates(newArrFiltered);
    } catch (err) {
      return dataFilter;
    }
  };

  const filteredData = filterAllTableScreens(dataListScreens);

  const isLoading =
    isLoadingDataScreen || isFetchingCreate || loadingDeleteScreen;

  return (
    <ContentScreens initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      {isLoading && <BackDropCircularLoading />}
      <HeaderListScreens>
        <motion.div
          whileTap={{ scale: 0.8 }}
          className='icon_handle_back'
          onClick={() => handleBackRoutesProfile()}
          type='button'
        >
          <FaArrowLeft />
        </motion.div>
        <TitleComponent textTitle={'Screens'} />
      </HeaderListScreens>

      {!isSysAdmin ? (
        <NoPermissionCard message={t('screens.table.no-data')} />
      ) : (
        <>
          <InputComponent
            placeholder={t('screens.label-filter-screens')}
            value={searchScreen}
            onChange={(e) => setSearchScreen(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <BiSearch />
                </InputAdornment>
              ),
            }}
          />
          <div className='content_btn_add_screen'>
            <ButtonStyled
              size={'small'}
              onClick={() => setStateRenderScreens(1)}
              title={t('screens.btn-add-screen')}
              disabled={!permissionsCreate}
            >
              <Stack direction={'row'} alignItems={'center'} spacing={1}>
                <TbScreenShare size={16} />
                <span>{t('screens.btn-add-screen')}</span>
              </Stack>
            </ButtonStyled>
          </div>
          <TableScreens
            dataTable={filteredData}
            mutateDeleteScreen={mutateDeleteScreen}
          />
        </>
      )}
    </ContentScreens>
  );
};
