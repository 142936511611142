import { useCallback, useState } from 'react';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { InputAdornment, Stack } from '@mui/material';
import { BiSearch } from 'react-icons/bi';
import { FaArrowLeft } from 'react-icons/fa';
import { TbScreenShare } from 'react-icons/tb';
import { ContentScreens, HeaderListScreens } from './styled';
import { TitleComponent } from '@/components/layout/TitleComponent';
import { InputComponent } from '@/components/FormComponents/InputComponent';
import { ButtonStyled } from '@/components/FormComponents/ButtonComponent';
import { TableScreens } from './TableScreens';
import { removeDuplicates } from '@/utils/removeDuplicateItemsArr';
import {
  currentStageEditFlow,
  dataRoutingProfile,
  stateRenderRoutingProfiles,
} from '@/store/UsersAgentsStore/routingProfiles';
import { dataRoutesProfileSelected } from '@/store/UsersAgentsStore/userAgentsStore';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';
import { useRoutingProfile } from '@/hooks/UserAgents/useRoutingProfile';

export const HomeScreensRouting = ({ handleChange, origin }) => {
  const { t } = useTranslation();

  const { mutateDeleteScreenRouting, loadingDeleteScreenRouting } =
    useRoutingProfile();

  const [, setStageEditFlow] = useAtom(currentStageEditFlow);
  const [dataRouteSelected] = useAtom(dataRoutesProfileSelected);
  const [listRoutingProfile] = useAtom(dataRoutingProfile);

  const [, setCurrentRenderRoutingProfile] = useAtom(
    stateRenderRoutingProfiles
  );

  const filterRenderScreenSelected = useCallback(() => {
    const { id } = dataRouteSelected;

    const filterSelected = listRoutingProfile
      .filter((item) => item.id === id)
      .map((item) => item.screens)
      .flat();

    return filterSelected;
  }, [listRoutingProfile]);

  const changeStageEditScreen = () => {
    handleChange(1);
  };

  const handleBackRoutesProfile = () => {
    if (origin) {
      return setStageEditFlow(0);
    }
    return setCurrentRenderRoutingProfile((previousState) => ({
      current: previousState.previous,
      previous: 0,
    }));
  };

  const [searchScreen, setSearchScreen] = useState('');

  const filterAllTableScreens = (dataFilter) => {
    if (!dataFilter || !searchScreen) {
      return dataFilter;
    }

    try {
      const filterTableName = dataFilter.filter((data) =>
        data.name.toLowerCase().includes(searchScreen.toLowerCase())
      );
      const newArrFiltered = [...filterTableName];
      return removeDuplicates(newArrFiltered);
    } catch (err) {
      return dataFilter;
    }
  };

  const filteredData = filterAllTableScreens(filterRenderScreenSelected());

  return (
    <ContentScreens
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      origin={origin ? 1 : 0}
    >
      {loadingDeleteScreenRouting && <BackDropCircularLoading />}
      <HeaderListScreens>
        <motion.div
          whileTap={{ scale: 0.8 }}
          className='icon_handle_back'
          onClick={() => handleBackRoutesProfile()}
          type='button'
        >
          <FaArrowLeft />
        </motion.div>
        <TitleComponent
          textTitle={t('routingProfile.homeRoutingProfiles.title-screens')}
        />
      </HeaderListScreens>
      <InputComponent
        placeholder={t('screens.label-filter-screens')}
        value={searchScreen}
        onChange={(e) => setSearchScreen(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <BiSearch />
            </InputAdornment>
          ),
        }}
      />
      <div className='content_btn_add_screen'>
        <ButtonStyled
          size={'small'}
          onClick={changeStageEditScreen}
          title={t('screens.btn-add-screen')}
        >
          <Stack
            direction={'row'}
            alignItems={'center'}
            spacing={1}
            width={'100%'}
          >
            <TbScreenShare size={16} />
            <span>{t('screens.btn-add-screen')}</span>
          </Stack>
        </ButtonStyled>
      </div>
      <TableScreens
        dataTable={filteredData}
        mutateDeleteScreenRouting={mutateDeleteScreenRouting}
      />
    </ContentScreens>
  );
};
