import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';

import { Stack } from '@mui/material';

import { useDialingQueues } from '@hooks/Dialing/useDialingQueues';

import { BackDropCircularLoading } from '@components/layout/LoadingComponent';
import { ConfirmationComponent } from '@components/layout/ConfirmationComponent';

import { ControlsDataQueue } from './ControlsDataQueue';
import { TableQueue } from './TableComponent';
import { dataInfoQueue } from '@/store/DialingStore';

import {
  ContainerDataQueue,
  PanelDetailQueue,
  TableDetailQueue,
} from './styled';

import { FaArrowLeft } from 'react-icons/fa';
import { PanelStatusQueue } from './PanelStatusQueue';
import { PanelRestrictions } from './PanelRestrictions';
import { useAtom } from 'jotai';

export const DataQueue = ({ changeFlowDialing }) => {
  const { t } = useTranslation();

  const {
    mutateCleanList,
    loadingCleanList,
    mutateReplicateList,
    loadingReplicateList,
    mutateAdvanceList,
    loadingAdvanceList,
  } = useDialingQueues();

  const [infoDataQueue, setDataQueue] = useAtom(dataInfoQueue);
  const [openRemoveConfirm, setOpenRemoveConfirm] = useState(false);

  const loadingTable =
    loadingCleanList || loadingReplicateList || loadingAdvanceList;

  const handleBack = () => {
    changeFlowDialing(0);
    setDataQueue(null);
  };

  return (
    <ContainerDataQueue initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      {loadingTable && <BackDropCircularLoading />}
      <PanelDetailQueue>
        <Stack direction='column' spacing={2} padding={1}>
          <Stack direction='row' spacing={1}>
            <motion.div whileTap={{ scale: 0.8 }}>
              <FaArrowLeft
                onClick={handleBack}
                size='16'
                className='svgBackstage'
              />
            </motion.div>
            <div className='itemPanel'>
              <span>
                <strong>
                  {infoDataQueue?.queueInfo.fila} -
                  {infoDataQueue?.queueInfo.campanha}
                </strong>
              </span>
            </div>
          </Stack>
          <Stack direction='row' spacing={3}>
            <div className='detail_queue'>
              <span>{infoDataQueue?.queueInfo.contexto_ura}</span>
              <Stack spacing={2} direction='row'>
                <span>{infoDataQueue?.queueInfo.disca_tipo}</span>
                <span>
                  {t('dialing.tableDiscingQueue.totalLines')}:
                  {infoDataQueue?.queueInfo.max_linhas}
                </span>
              </Stack>
            </div>
          </Stack>
        </Stack>
        <PanelStatusQueue />
        <PanelRestrictions />
      </PanelDetailQueue>

      <ControlsDataQueue
        setOpenRemoveConfirm={setOpenRemoveConfirm}
        mutateCleanList={mutateCleanList}
        mutateAdvanceList={mutateAdvanceList}
        mutateReplicateList={mutateReplicateList}
        changeFlowDialing={changeFlowDialing}
      />

      <TableDetailQueue>
        <TableQueue dataInfoQueue={infoDataQueue} />
      </TableDetailQueue>

      <ConfirmationComponent
        actionConfirm={() =>
          mutateCleanList({
            queue: infoDataQueue.queueInfo.fila,
          })
        }
        messageConfirm={t('dialing.messages.confirmationReplicate')}
        open={openRemoveConfirm}
        close={() => setOpenRemoveConfirm(false)}
      />
    </ContainerDataQueue>
  );
};
