import { MdOutlineClose } from 'react-icons/md';
import { ItemIntentStyled } from './styled';
import { motion } from 'framer-motion';

export const ItemIntent = ({ intent, removeIntent }) => {
  return (
    <ItemIntentStyled>
      <span>{intent}</span>
      <motion.button
        whileTap={{ scale: 1.1 }}
        onClick={() => removeIntent(intent)}
        type='button'
      >
        <MdOutlineClose />
      </motion.button>
    </ItemIntentStyled>
  );
};
