import { motion } from 'framer-motion';
import { Stack } from '@mui/system';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { MenuItem } from '@mui/material';
import { SwitchComponent } from '@/components/FormComponents/SwitchComponent';
import { DescriptionInformation } from '@/components/layout/DescriptionInformation';
import { SelectComponent } from '@/components/FormComponents/SelectInput';
import {
  listTemplateMetadata,
  selectedTemplate,
  showEmptyColumnsImported,
  useHeaderCsv,
} from '@/store/LoadList';

export const PanelImportList = ({
  useLayout,
  setUseLayout,
  mutateDataTemplate,
}) => {
  const { t } = useTranslation();
  const [renderHeaderCsv, setRenderHeaderCsv] = useAtom(useHeaderCsv);
  const [metadataTemplates] = useAtom(listTemplateMetadata);
  const [selectedLayout, setSelectedLayout] = useAtom(selectedTemplate);
  const [, setShowNodesNulls] = useAtom(showEmptyColumnsImported);

  const controller = new AbortController();

  const changeSelectLayout = (idSelected) => {
    mutateDataTemplate(
      { idTemplate: idSelected, signal: controller.signal },
      {
        onSuccess: (data) => {
          setSelectedLayout(idSelected);
          setRenderHeaderCsv(data.metadata.useHeaderCsv);
          setShowNodesNulls(data.metadata.showNodesNulls);
        },
      }
    );
  };

  return (
    <>
      <Stack width={'100%'} direction={'row'} justifyContent={'space-between'}>
        <SwitchComponent
          label={
            useLayout
              ? t('listLoader.importMailing.label-use-template')
              : t('listLoader.importMailing.label-use-new-template')
          }
          value={useLayout}
          onChange={setUseLayout}
          disabled={metadataTemplates.length === 0}
        />

        <SwitchComponent
          label={
            renderHeaderCsv
              ? t('listLoader.importMailing.label-include-headers')
              : t('listLoader.importMailing.label-remove-headers')
          }
          value={renderHeaderCsv}
          onChange={setRenderHeaderCsv}
          disabled={useLayout && selectedLayout !== '0'}
        />
      </Stack>

      {useLayout && (
        <motion.div
          className='content-template-select'
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
        >
          <DescriptionInformation
            textDescription={t('listLoader.importMailing.label-description')}
          />
          <SelectComponent
            labelSelect={t('listLoader.importMailing.label-template')}
            value={selectedLayout}
            onChange={(e) => changeSelectLayout(e.target.value)}
          >
            <MenuItem value={'0'} dense disabled>
              {t('listLoader.importMailing.option-template')}
            </MenuItem>
            {metadataTemplates.map((template, i) => (
              <MenuItem key={i} value={template.id} dense>
                {template.name}
              </MenuItem>
            ))}
          </SelectComponent>
        </motion.div>
      )}
    </>
  );
};
