import styled from 'styled-components';

export const TableContent = styled.table`
  width: 100%;
  user-select: text;

  @media (max-width: 600px) {
    width: 300px;
  }

  thead {
    tr {
      user-select: none;
      position: relative;
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: ${(props) => props.theme.colors.tables.tableHead};
        border-radius: 3px;
      }

      th {
        &:last-child {
          width: 20px;
        }

        div {
          position: relative;
          z-index: 2;
          min-width: 60px;
          text-align: initial;
          padding: 10px;
          font-size: 0.8rem;
          font-weight: 300;
          text-transform: uppercase;
          color: ${(props) => props.theme.colors.typography.textHeaderTable};

          &:nth-child(1) {
            padding-left: 10px;
          }

          @media (max-width: 1300px) {
            font-size: 0.8rem;
            padding: 15px 5px;

            &:nth-child(1),
            &:nth-child(2) {
              min-width: 40px;
            }
          }

          @media (max-width: 799px) {
            font-size: 0.875rem;
          }
        }
      }
    }
  }

  .content_th {
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    svg {
      font-size: 1rem;
    }
  }
`;

export const ContentTr = styled.tr`
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.theme.colors.tables.tableRow};
    border-radius: 5px;
    box-shadow: 2px 4px 5px 0px rgba(0, 0, 0, 0.15);
  }

  &:hover:after {
    background-color: ${(props) =>
      props.theme.colors.containers.colorCardSecondary}AA;
  }
`;

export const ContainerTd = styled.td`
  position: relative;
  z-index: 1;
  min-width: 100px;
  padding: 10px;
  text-transform: lowercase;

  &:nth-child(3) {
    text-transform: capitalize;
  }

  span {
    font-size: 0.8rem;
    font-weight: normal;
    word-wrap: normal;
    color: ${(props) => props.theme.colors.typography.baseText};

    @media (max-width: 799px) {
      font-size: 0.7rem;
    }
  }

  .editRow {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 4px;

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      color: ${(props) => props.theme.colors.bases.baseWhite};
      padding: 4px;
      border-radius: 4px;

      &:first-child {
        background-color: ${(props) => props.theme.colors.error};
      }
      &:last-child {
        background-color: ${(props) => props.theme.colors.bases.primary};
      }
    }
  }

  .flex_div {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    svg {
      color: ${(props) => props.theme.colors.secondary};
      font-size: 1.1rem;
      margin-right: 10px;
    }
  }
`;
