import { motion } from 'framer-motion';
import styled from 'styled-components';

export const TableCurrentList = styled.div`
  width: 100%;
  height: 100%;
  user-select: text;

  table {
    width: 100%;
    tbody {
      tr {
        height: 40px;
        max-width: 200px;
        td {
          z-index: 1;
          padding: 0 0.8rem;

          &:last-child {
            min-width: 80px;
            max-width: 100px;
          }
        }
      }
    }
  }
`;

export const NoDataTableRow = styled(motion.tr)`
  width: 100%;
  height: 80px;
  position: relative;

  .contentNoData {
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    background-color: ${(props) => props.theme.colors.containers.colorCard};
    border-radius: 4px;

    span {
      font-size: 1rem;
      font-weight: normal;
      word-wrap: normal;
      color: ${(props) => props.theme.colors.typography.baseText};
    }

    svg {
      color: ${(props) => props.theme.colors.secondary};
      font-size: 1.4rem;
    }
  }
`;
