import styled from 'styled-components';

export const ContainerNavBarAgents = styled.div`
  background: ${(props) => props.theme.colors.containers.baseContainer};
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.25);

  @media (max-width: 400px) {
    padding: 0 40px;
  }

  .rotate {
    transform: rotate(-90deg);
  }

  .logoNavbar {
    width: 150px;

    @media (min-width: 1020px) {
      display: none;
    }
  }

  button {
    background: transparent;
    border: none;

    @media (min-width: 1020px) {
      display: none;
    }

    svg {
      color: ${(props) => props.theme.colors.primary};
      font-size: 1.2rem;
      transition: all 0.1s ease-in-out;
    }
  }
  div {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    .titleComponent {
      flex: 1;
      h1 {
        color: ${(props) => props.theme.colors.typography.baseText};
        font-size: 1.2rem;
        font-weight: 400;
        letter-spacing: 0.125em;
        text-transform: uppercase;
      }
    }
    svg {
      color: ${(props) => props.theme.colors.primary};
      font-size: 1.2rem;
    }
  }
`;
