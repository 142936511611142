import styled, { css } from 'styled-components';

export const NodeTabulationContainer = styled.div`
  background: ${(props) => props.theme.colors.containers.colorSecondNode};
  border-radius: 4px;

  &::before,
  &::after {
    content: '';
    position: absolute;
    border-radius: 4px;
    left: -2px;
    top: -2px;
    background: linear-gradient(
      45deg,
      #3250b3,
      #8294ce,
      #1743d3,
      #3250b3,
      #8294ce,
      #1743d3
    );

    background-size: 400%;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    z-index: -1;
    animation: steam 26s linear infinite;

    ${(props) =>
      props.selected &&
      css`
        width: calc(100% + 8px);
        height: calc(100% + 8px);
        left: -4px;
        top: -4px;
        background: #00c8f0;
      `}

    ${(props) =>
      props.errorConnect &&
      css`
        background: linear-gradient(45deg, red, red, red, red, red, red);
      `}
  }

  @keyframes steam {
    0% {
      background-position: 0 0;
    }
    50% {
      background-position: 400% 0;
    }
    100% {
      background-position: 0 0;
    }
  }

  .react-flow__handle {
    background: transparent;
    width: 20px;
    height: 100%;
    border-radius: 0;
    border: none;
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 10px;
      width: 8px;
      height: 8px;
      border: 2px solid ${(props) => props.theme.colors.containers.colorCard};
      background: ${(props) => props.theme.colors.nodesFlow.setVariables};
    }
  }

  .react-flow__handle-left {
    left: -7px;
  }

  .react-flow__handle-right {
    right: -16px;
  }

  .panelNode {
    width: 100%;
    position: absolute;
    right: 4px;
    top: 4px;
    opacity: 0;
    transition: all 0.1s ease-in-out;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 4px;
    gap: 10px;

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      background: transparent;

      svg {
        font-size: 1.3rem;
        color: #fff;
      }
    }
  }

  &:hover {
    > .panelNode {
      opacity: 1;
      /* top: -26px; */
    }
  }

  .headerNode {
    display: flex;
    flex-direction: column;
    word-break: break-all;
    font-size: 0.6rem;
  }
`;

export const NodeContent = styled.div`
  min-width: 220px;
  display: grid;
  grid-template-rows: 40px 1fr;

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    background: ${(props) => props.theme.colors.nodesFlow.setVariables};
    padding: 3px;
    gap: 0.6rem;
    padding-left: 0.6rem;
    svg {
      color: #ffff;
      font-size: 1.4rem;
    }
    .titleNode {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      h4 {
        color: #ffff;
        text-transform: uppercase;
        font-size: 0.8rem;
      }
    }

    span {
      font-size: 0.6rem;
      text-transform: uppercase;
      color: #ffff;
    }
  }

  .main {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 10px;
    gap: 0.6rem;

    .no-variables-content {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 0.4rem;
      border: 1px solid #80808052;
      border-radius: 3px;
      padding: 2px;
      svg {
        font-size: 1rem;
        color: ${(props) => props.theme.colors.nodesFlow.setVariables};
      }
      span {
        font-size: 0.75rem;
        /* color: ${(props) => props.theme.colors.nodesFlow.setVariables}; */
        color: #808080;
        font-weight: 600;
      }
    }

    ul {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 0.4rem;

      li {
        width: 100%;
        background-color: #ebebeb;
        border-radius: 2px;
        padding: 4px 4px;

        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 0.2rem;

        list-style: none;

        svg {
          color: green;
          font-size: 0.78rem;
        }

        span {
          font-size: 0.78rem;
          /* color: ${(props) => props.theme.colors.bases.baseWhite}; */
          color: ${(props) => props.theme.colors.nodesFlow.setVariables};
          font-weight: 600;
        }

        .responseValueVar {
          color: gray;
          font-weight: 400;
          font-style: italic;
          font-size: 0.65rem;
        }
      }
    }
  }
`;
