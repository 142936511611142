import { Accordion } from '@mui/material';
import { motion } from 'framer-motion';
import styled from 'styled-components';

export const FormEditSchedules = styled(motion.form)`
  width: 100%;
  flex: 1;

  width: 100%;
  height: 100%;

  display: grid;
  grid-template-rows: auto 1fr;
  gap: 2rem;

  .header {
    nav {
      div {
        button {
          line-height: 11px;
          padding: 8px 10px;
          &:first-child {
          }
          &:last-child {
          }
        }
      }
    }
  }

  .main {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    flex: 1;
    gap: 1.6rem;

    .content-dispositions {
      width: 100%;
      height: 100%;
      border-radius: 3px;
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      gap: 4px;
      padding: 4px;
      cursor: pointer;
      transition: background-color 0.2s ease;

      &:hover {
        background-color: ${(props) =>
          props.theme.colors.containers.dividerColor}AA;
      }

      .item-disposition {
        padding: 4px;
        background-color: ${(props) =>
          props.theme.colors.containers.baseContainer};
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        border-radius: 3px;

        span {
          color: ${(props) => props.theme.colors.typography.baseText}AA;
          font-size: 0.7rem;
          &:nth-child(2) {
            color: ${(props) => props.theme.colors.typography.baseText};
            font-size: 0.75rem;
          }
        }
      }
    }
  }

  .footer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 1rem;

    button {
      line-height: 11px;
      padding: 8px 10px;
    }
  }
`;

export const AccordionsStyled = styled(Accordion)`
  width: 100%;
  background-color: ${(props) =>
    props.theme.colors.containers.colorCard} !important;

  svg {
    color: ${(props) => props.theme.colors.typography.baseText} !important;
  }
`;

export const TitleAccordion = styled.h3`
  font-size: 1rem;
  line-height: 1.6rem;
  letter-spacing: 0.01em;
  color: ${(props) => props.theme.colors.typography.baseText};
`;
