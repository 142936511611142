import styled from 'styled-components';
import { motion } from 'framer-motion';

export const FormMenuNode = styled(motion.form)`
  width: 320px;
  height: 100%;
  padding: 1rem;
  display: grid;
  grid-template-rows: 1fr 40px;
  gap: 1rem;
  background-color: ${(props) => props.theme.colors.containers.colorNode};
`;

export const MainAddNode = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 2rem;
  padding: 1rem 0 0 0.5rem;

  h4 {
    font-size: 0.85rem;
    font-weight: 400;
    text-transform: uppercase;
    color: ${(props) => props.theme.colors.typography.baseText};
  }

  .audio-content {
    width: 100%;
    opacity: 0;
    pointer-events: none;
  }
`;

export const FooterAddNode = styled.footer`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
`;
