import { TitleComponent } from '@/components/layout/TitleComponent';
import { ContainerHomeCodLigs } from './styled';
import { InputComponent } from '@/components/FormComponents/InputComponent';
import { BiSearch } from 'react-icons/bi';
import { InputAdornment, Stack } from '@mui/material';
import { ButtonStyled } from '@/components/FormComponents/ButtonComponent';
import { RiSoundModuleLine } from 'react-icons/ri';
import { TableDispositions } from './TableDispositions';
import { useTranslation } from 'react-i18next';

import { useState } from 'react';
import { removeDuplicates } from '@/utils/removeDuplicateItemsArr';
import { useDispositionsDialing } from '@/hooks/Dispositions/useDispositionsDialing';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';
import { useAtom } from 'jotai';
import { dataDispositionsDialing } from '@/store/DialingStore';
import { usePermissionsDispositions } from '../../hooks/usePermissionsDispositions';
import { NoPermissionCard } from '@/components/layout/NoPermissionCard';

export const HomeDispositions = ({ changeStage }) => {
  const { t } = useTranslation();
  const { permissionsCreate, isSysAdmin } = usePermissionsDispositions();
  const { loadingDataDisposition } = useDispositionsDialing();
  const [listsDispositions] = useAtom(dataDispositionsDialing);

  const [searchDisposition, setSearchDisposition] = useState('');

  const filterAllTableTenants = (dataFilter) => {
    if (dataFilter) {
      const filterDisposition = dataFilter.filter((data) =>
        data.cod_lig.toLowerCase().includes(searchDisposition.toLowerCase())
      );

      const filterDescription = dataFilter.filter((data) =>
        data.descricao.toLowerCase().includes(searchDisposition.toLowerCase())
      );

      const newArrFiltered = [...filterDisposition, ...filterDescription];
      return removeDuplicates(newArrFiltered);
    }
  };

  const filteredData = filterAllTableTenants(listsDispositions);

  const isLoading = loadingDataDisposition;

  return (
    <ContainerHomeCodLigs initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      {isLoading && <BackDropCircularLoading />}
      <TitleComponent
        textTitle={t('dispositions.home.title')}
        iconTitle='config'
      />

      {!isSysAdmin ? (
        <NoPermissionCard message={t('dispositions.home.table.no-data')} />
      ) : (
        <>
          <InputComponent
            placeholder={t('dispositions.home.placeholder-filterDisposition')}
            value={searchDisposition}
            onChange={(e) => setSearchDisposition(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <BiSearch />
                </InputAdornment>
              ),
            }}
          />
          <div className='content_btn_new_codLigs'>
            <ButtonStyled
              size={'small'}
              onClick={() => changeStage(1)}
              disabled={!permissionsCreate}
            >
              <Stack direction={'row'} alignItems={'center'} spacing={1}>
                <RiSoundModuleLine size={16} />
                <span>{t('dispositions.home.btn-addDisposition')}</span>
              </Stack>
            </ButtonStyled>
          </div>
          <TableDispositions
            dataTable={filteredData}
            changeStage={changeStage}
          />
        </>
      )}
    </ContainerHomeCodLigs>
  );
};
