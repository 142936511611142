import { useAtom } from 'jotai';

import * as yup from 'yup';
import useFormControl from '@/hooks/useFormControl';
import { ContainerPanelActive, FooterPanel, FormActiveRecords } from './styled';
import {
  ControlInput,
  ControlInputMask,
} from '@/components/FormComponents/InputComponent';
import { Grid, MenuItem, Stack } from '@mui/material';
import { AccordionComponent } from '@/components/layout/AccordionComponent';
import { DescriptionInformation } from '@/components/layout/DescriptionInformation';
import { Box } from '@mui/system';
import { ButtonStyled } from '@/components/FormComponents/ButtonComponent';
import { IoIosSearch } from 'react-icons/io';
import { TitleComponent } from '@/components/layout/TitleComponent';
import { ControlSelect } from '@/components/FormComponents/SelectInput';
import { ControlPhoneInput } from '@/components/FormComponents/PhoneInput';
import { useEffect } from 'react';
import { errorToast } from '@/components/FormComponents/Toast';
import { useTranslation } from 'react-i18next';
import useActiveRecordings from '@/hooks/recordings/useActiveRecordings';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';
import { useQueues } from '@/hooks/dialControl/Queues/useQueues';
import { dataQueues } from '@/store/Queues';
import { ControlDataPicker } from '@/components/FormComponents/DataPickerComponent';
import { format } from 'date-fns';
import { dateSearchRecordings } from '@/store/Recordings/PanelRecords';
import { dataDispositionsDialing } from '@/store/DialingStore';
import { useDispositionsDialing } from '@/hooks/Dispositions/useDispositionsDialing';
import { ControlAutoComplete } from '@/components/FormComponents/AutoCompleteSelect';
import { useListsByClient } from '@/hooks/dialControl/ListsDialing/useListsByClient';

export const PanelSearchActiveRecordings = ({ controlStage }) => {
  const { t } = useTranslation();
  const { loadingDataQueues } = useQueues();
  const { loadingListsByClient, listsByClient } = useListsByClient();
  const { loadingDispositionsDialing } = useDispositionsDialing();

  const [listQueues] = useAtom(dataQueues);
  const [listsDispositions] = useAtom(dataDispositionsDialing);

  const [, setDateSearch] = useAtom(dateSearchRecordings);

  const { mutateDataRecordings, loadingDataRecordings } = useActiveRecordings();

  const renterText = (word = '') => t(`activeRecordings.panel.${word}`);

  const currentLang = window.localStorage.getItem('lang');

  const searchActiveRecordingsSchema = yup.object().shape({
    list: yup.object(),
    loginAgent: yup.string(),
    extension: yup.string(),
    disposition: yup.string(),
    callType: yup.string(),
    queue: yup.string().required(),
    dateCall: yup.string(),
    customerName: yup.string(),
    cpf: yup.string(),
    codeArea: yup.string(),
    phone: yup.string(),
    internalCode: yup.string(),
    charCode: yup.string(),
  });

  const { handleSubmit, control, errors, reset } = useFormControl(
    searchActiveRecordingsSchema
  );

  const objError = Object.keys(errors);

  useEffect(() => {
    const arrError = objError.includes('queue');
    arrError && errorToast(renterText('form.msg-error'));
  }, [errors]);

  const formateCpf = (cpf) => {
    return cpf.replace(/[.-]/g, '');
  };

  const formateDate = (date) => {
    const dataInit = format(new Date(date), 'yyyy-MM-dd');
    return dataInit;
  };
  const formatePhone = (phone) => {
    return phone.replace(/[\s-]/g, '');
  };

  const handleSearchActiveRecords = (data) => {
    const dataSearch = {
      cod_lig: data.disposition,
      lig_tipo: data.callType,
      id_tabela_lista: data.list?.value ?? '',
      nome_cliente: data.customerName,
      data_ligacao: formateDate(data.dateCall),
      fila: data.queue,
      ramal: data.extension,
      agente_login: data.loginAgent,
      ddd: data.codeArea,
      fone: formatePhone(data.phone),
      cpf: formateCpf(data.cpf),
      cod_link_int: data.internalCode,
      cod_link_char: data.charCode,
    };

    setDateSearch({
      dateSearch: data.dateCall,
      queueSearch: data.queue,
    });

    mutateDataRecordings(dataSearch, {
      onSuccess: () => {
        reset();
        controlStage.toList();
      },
    });
  };

  const renderOptionsLists = listsByClient?.map((item) => {
    return {
      label: `${item.lista} - ${item.descricao}`,
      value: item.lista,
    };
  });

  const sortedAscListQueue = listQueues?.sort((a, b) => a.fila - b.fila);

  const isLoading =
    loadingDataRecordings ||
    loadingDataQueues ||
    loadingListsByClient ||
    loadingDispositionsDialing;

  return (
    <ContainerPanelActive initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      {isLoading && <BackDropCircularLoading />}
      <TitleComponent textTitle={renterText('title')} />
      <FormActiveRecords onSubmit={handleSubmit(handleSearchActiveRecords)}>
        <DescriptionInformation textDescription={renterText('description')} />
        <Stack spacing={2}>
          <Stack width={'100%'} justifyContent={'flex-start'}>
            <AccordionComponent
              opemText={renterText('opemAdvancedSearch')}
              closeText={renterText('closeAdvancedSearch')}
            >
              <ControlAutoComplete
                control={control}
                nameControl={'list'}
                label={renterText('form.list')}
                optionsList={renderOptionsLists}
                error={Boolean(errors.list)}
              />

              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <ControlInput
                    control={control}
                    nameControl={'loginAgent'}
                    label={renterText('form.loginAgent')}
                    error={Boolean(errors.loginAgent)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <ControlInput
                    control={control}
                    nameControl={'extension'}
                    label={renterText('form.extension')}
                    error={Boolean(errors.extension)}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <ControlSelect
                    control={control}
                    nameControl={'disposition'}
                    label={renterText('form.disposition')}
                    defaultValue={''}
                    error={Boolean(errors.disposition)}
                  >
                    <MenuItem value={'0'} disabled dense>
                      {renterText('form.option-code')}
                    </MenuItem>
                    {listsDispositions.length > 0 &&
                      listsDispositions.map((item, i) => (
                        <MenuItem value={item.cod_lig} key={i} dense>
                          {item.cod_lig} - {item.descricao}
                        </MenuItem>
                      ))}
                  </ControlSelect>
                </Grid>
                <Grid item xs={6}>
                  <ControlSelect
                    control={control}
                    nameControl={'callType'}
                    label={renterText('form.callType')}
                    defaultValue={''}
                    error={Boolean(errors.callType)}
                  >
                    <MenuItem value={'0'} disabled dense>
                      {renterText('form.option-type')}
                    </MenuItem>
                    <MenuItem value={'PRED'} dense>
                      PRED
                    </MenuItem>
                    <MenuItem value={'CLICK'} dense>
                      CLICK
                    </MenuItem>
                    <MenuItem value={'MANU'} dense>
                      MANUAL
                    </MenuItem>
                  </ControlSelect>
                </Grid>
              </Grid>
            </AccordionComponent>
          </Stack>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '1rem',
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <ControlSelect
                  control={control}
                  nameControl={'queue'}
                  label={renterText('form.queue')}
                  defaultValue={''}
                  error={Boolean(errors.queue)}
                >
                  <MenuItem value={'0'} disabled dense>
                    {renterText('form.option-queue')}
                  </MenuItem>
                  {sortedAscListQueue.map((item, i) => (
                    <MenuItem key={i} value={item.fila} dense>
                      {item.fila} - {item.campanha}
                    </MenuItem>
                  ))}
                </ControlSelect>
              </Grid>

              <Grid item xs={6}>
                <ControlDataPicker
                  label={'Date'}
                  control={control}
                  nameControl={'dateCall'}
                  defaultValue={new Date()}
                  disableOpenPicker
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <ControlInput
                  control={control}
                  nameControl={'customerName'}
                  label={renterText('form.customerName')}
                  error={Boolean(errors.customerName)}
                />
              </Grid>
              <Grid item xs={6}>
                <ControlInputMask
                  control={control}
                  nameControl={'cpf'}
                  label={'CPF'}
                  mask={'999.999.999-99'}
                  error={Boolean(errors.cpf)}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={4}>
                <ControlInput
                  control={control}
                  nameControl={'codeArea'}
                  label={renterText('form.codeArea')}
                  error={Boolean(errors.codeArea)}
                  InputProps={{
                    inputProps: {
                      maxLength: currentLang === 'pt' ? 2 : 3,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={8}>
                <ControlPhoneInput
                  control={control}
                  nameControl={'phone'}
                  label={renterText('form.phone')}
                  error={Boolean(errors.phone)}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <ControlInput
                  control={control}
                  nameControl={'internalCode'}
                  label={renterText('form.intCode')}
                  error={Boolean(errors.internalCode)}
                />
              </Grid>
              <Grid item xs={6}>
                <ControlInput
                  control={control}
                  nameControl={'charCode'}
                  label={renterText('form.charCode')}
                  error={Boolean(errors.charCode)}
                />
              </Grid>
            </Grid>
          </Box>
        </Stack>

        <FooterPanel>
          <ButtonStyled type='submit' disabled={objError.length > 0}>
            <IoIosSearch size={18} /> {renterText('form.btn-search')}
          </ButtonStyled>
        </FooterPanel>
      </FormActiveRecords>
    </ContainerPanelActive>
  );
};
