import {
  idNodeToRemove,
  selectedNode,
  showMenuNodes,
} from '@/store/FlowBuilder';
import { useAtom } from 'jotai';
import React, { memo } from 'react';
import { Handle, Position } from 'reactflow';
import { Stack } from '@mui/material';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { motion } from 'framer-motion';
import { MdEdit } from 'react-icons/md';
import { BsChatSquareTextFill } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';
import { shortNameRender } from '../../../utils/shortNameRender';
import { NodeContent, NodeTTSContainer } from './styled';
import { usePermissionsFlow } from '@/hooks/FlowBuilder/usePermissionsFlow';

const PlayPromptNodeComponent = ({ id, data, selected }) => {
  const { t } = useTranslation();
  const { permissionsEdit, permissionsDelete, isSysAdmin } =
    usePermissionsFlow();
  const [, setIdNode] = useAtom(idNodeToRemove);
  const [, setShowMenu] = useAtom(showMenuNodes);
  const [nodeSelected, setSelectedNode] = useAtom(selectedNode);

  const handleNode = () => {
    setSelectedNode({ id, data });
    setShowMenu(true);
  };

  const handleRemoveNode = (idNode) => {
    setIdNode(idNode);
    idNode === nodeSelected?.id && setShowMenu(false);
  };

  const handleSelectedNode = () => {
    setSelectedNode({ id, data });
    setShowMenu(true);
  };

  const permissionNodeEdit = permissionsEdit || isSysAdmin;
  const permissionNodeDelete = permissionsDelete || isSysAdmin;

  return (
    <NodeTTSContainer
      selected={selected}
      errorConnect={data.errorConnect ? 1 : 0}
      onDoubleClick={() => handleNode()}
    >
      <div className='panelNode'>
        {permissionNodeEdit && (
          <motion.button
            onClick={() => handleSelectedNode()}
            whileTap={{ scale: 0.98 }}
          >
            <MdEdit size={16} />
          </motion.button>
        )}
        {permissionNodeDelete && (
          <motion.button
            onClick={() => handleRemoveNode(id)}
            whileTap={{ scale: 0.98 }}
          >
            <IoIosCloseCircleOutline />
          </motion.button>
        )}
      </div>

      <Handle type='target' position={Position.Left} />
      <NodeContent>
        <div className='header'>
          <BsChatSquareTextFill size={16} />
          <div className='titleNode'>
            <h4>{shortNameRender(data.label, 12)}</h4>
            <span>{t('flowBuilder.flow.nodes.playPrompt.label')}</span>
          </div>
        </div>
        <div className='main'>
          <Stack
            direction={'column'}
            alignItems={'flex-start'}
            spacing={1}
            width={'100%'}
            maxWidth={'220px'}
          >
            <span>
              <strong>
                {t('flowBuilder.flow.nodes.playPrompt.label-prompt')}:{' '}
              </strong>
            </span>
            <div className='field-prompt-render'>
              <span>
                {data?.prompt === ''
                  ? t('flowBuilder.flow.nodes.playPrompt.placeholder-prompt')
                  : data.prompt}
              </span>
            </div>
          </Stack>
        </div>
      </NodeContent>
    </NodeTTSContainer>
  );
};

export const PlayPromptNode = memo(PlayPromptNodeComponent);
