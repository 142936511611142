import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ContainerTableQueues } from './styled';
import { TableColorRow } from '@/components/Tables/TableColorRow';
import { Stack } from '@mui/material';
import { HiPhoneArrowUpRight } from 'react-icons/hi2';
import { FaMicrophone } from 'react-icons/fa6';
import { useAtom } from 'jotai';
import { selectedQueueMonitor } from '@/store/QueueMonitor';

export const QueuesTable = ({ dataTable }) => {
  const { t } = useTranslation();
  const [selectedQueue, setSelectedQueue] = useAtom(selectedQueueMonitor);

  const data = useMemo(() => dataTable, [dataTable]);

  const ColumnsQueuesMonitor = [
    {
      Header: t('queueMonitor.table.th-queue'),
      accessor: 'fila',
    },

    {
      Header: t('queueMonitor.table.th-campaign'),
      accessor: 'campanha_descricao',
    },
    {
      Header: t('queueMonitor.table.th-list'),
      accessor: 'lista',
    },

    {
      Header: t('queueMonitor.table.th-dialing'),
      accessor: 'discando',
      Cell: ({ row }) => {
        const cellItem = row.original.discando;
        return (
          <Stack direction={'row'} spacing={1}>
            <HiPhoneArrowUpRight
              className={cellItem > 0 ? 'colorDiscing' : 'colorNull'}
            />
            <span className={cellItem > 0 ? 'colorDiscing' : 'colorNull'}>
              <strong>{cellItem}</strong>
            </span>
          </Stack>
        );
      },
      Footer: ({ data }) => {
        const total = data
          .map((item) => item.discando)
          .reduce((acc, row) => acc + row, 0);
        return (
          <Stack direction={'row'} spacing={1}>
            <HiPhoneArrowUpRight />
            <span>
              <strong>{total}</strong>
            </span>
          </Stack>
        );
      },
    },
    {
      Header: t('queueMonitor.table.th-talking'),
      accessor: 'falando_ura',
      Cell: ({ row }) => {
        const cellItem = row.original.falando_ura;
        return (
          <Stack direction={'row'} spacing={1}>
            <FaMicrophone
              className={cellItem > 0 ? 'colorTalking' : 'colorNull'}
            />
            <span className={cellItem > 0 ? 'colorTalking' : 'colorNull'}>
              <strong>{cellItem}</strong>
            </span>
          </Stack>
        );
      },
      Footer: ({ data }) => {
        const total = data
          .map((item) => item.falando_ura)
          .reduce((acc, row) => acc + row, 0);
        return (
          <Stack direction={'row'} spacing={1}>
            <FaMicrophone />
            <span>
              <strong>{total}</strong>
            </span>
          </Stack>
        );
      },
    },
    {
      //   Header: t('queueMonitor.table.th-operator'),
      Header: t('Telecom'),
      accessor: 'operadora',
      Footer: ({ data }) => {
        const countDiscing = data
          .map((item) => item.discando)
          .reduce((acc, row) => acc + row, 0);

        const countTalking = data
          .map((item) => item.falando_ura)
          .reduce((acc, row) => acc + row, 0);

        return `Total: ${countDiscing + countTalking}`;
      },
    },
  ];

  const handleSelectRow = (dataRow) => {
    if (selectedQueue && dataRow.fila === selectedQueue.fila) {
      return setSelectedQueue(null);
    }
    return setSelectedQueue(dataRow);
  };

  return (
    <ContainerTableQueues>
      <TableColorRow
        dataColumns={ColumnsQueuesMonitor}
        dataTable={data}
        textNoData={t('queueMonitor.table.noData')}
        useFooter={true}
        handleClickRow={handleSelectRow}
        selected={selectedQueue?.fila}
      />
    </ContainerTableQueues>
  );
};
