import { FormHelperText, InputLabel, Select } from '@mui/material';
import { StyledFormControl } from './styled';
import { Controller } from 'react-hook-form';
import { useId } from 'react';

export const SelectComponent = ({
  children,
  labelSelect = '',
  helperText = '',
  disableHelper = false,
  ...rest
}) => {
  const generateIdSelect = useId();

  return (
    <StyledFormControl size='small'>
      <InputLabel
        htmlFor={`uncontrolled-native.${generateIdSelect}`}
        disabled={rest.disabled}
      >
        {labelSelect}
      </InputLabel>
      <Select
        inputProps={{
          id: `uncontrolled-native.${generateIdSelect}`,
        }}
        labelId={labelSelect}
        label={labelSelect}
        {...rest}
      >
        {children}
      </Select>
      {!disableHelper && (
        <FormHelperText className='helper-color'>{helperText}</FormHelperText>
      )}
    </StyledFormControl>
  );
};

// !TO USE CONTROL COMPONENT WITH REACT HOOK FORMS
export const ControlSelect = ({
  control,
  nameControl,
  defaultValue = '',
  ...rest
}) => {
  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={nameControl}
      render={({ field: { onChange, value } }) => (
        <SelectComponent
          labelSelect={rest.label}
          value={value}
          onChange={onChange}
          slotProps={{
            textField: {
              error: Boolean(rest.error),
            },
          }}
          {...rest}
        >
          {rest.children}
        </SelectComponent>
      )}
    />
  );
};
