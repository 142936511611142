import {
  idNodeToRemove,
  selectedNode,
  showMenuNodes,
} from '@/store/FlowBuilder';
import { useAtom } from 'jotai';
import React, { memo } from 'react';
import { Handle, Position } from 'reactflow';
import { NodeContent, NodeHangUpContainer } from './styled';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { motion } from 'framer-motion';
import { MdEdit } from 'react-icons/md';
import { shortNameRender } from '../../../utils/shortNameRender';
import LogoDeepCenter from '@/assets/imgs/sm_white_deepCenter.svg';
import { usePermissionsFlow } from '@/hooks/FlowBuilder/usePermissionsFlow';
import GifDeepCenter from '@/assets/imgs/deepNew.gif';

const DeepCenterNodeComponent = ({ id, data, selected }) => {
  const { permissionsEdit, permissionsDelete, isSysAdmin } =
    usePermissionsFlow();
  const [, setIdNode] = useAtom(idNodeToRemove);
  const [, setShowMenu] = useAtom(showMenuNodes);
  const [nodeSelected, setSelectedNode] = useAtom(selectedNode);

  const handleNode = () => {
    setSelectedNode({ id, data });
    setShowMenu(true);
  };

  const handleRemoveNode = (idNode) => {
    setIdNode(idNode);
    idNode === nodeSelected?.id && setShowMenu(false);
  };

  const handleSelectedNode = () => {
    setSelectedNode({ id, data });
    setShowMenu(true);
  };

  const permissionNodeEdit = permissionsEdit || isSysAdmin;
  const permissionNodeDelete = permissionsDelete || isSysAdmin;

  return (
    <NodeHangUpContainer
      selected={selected}
      onDoubleClick={() => handleNode()}
      errorConnect={data.errorConnect ? 1 : 0}
    >
      <div className='panelNode'>
        {permissionNodeEdit && (
          <motion.button
            onClick={() => handleSelectedNode()}
            whileTap={{ scale: 0.98 }}
          >
            <MdEdit size={16} />
          </motion.button>
        )}
        {permissionNodeDelete && (
          <motion.button
            onClick={() => handleRemoveNode(id)}
            whileTap={{ scale: 0.98 }}
          >
            <IoIosCloseCircleOutline />
          </motion.button>
        )}
      </div>
      <Handle type='target' position={Position.Left} />
      <NodeContent>
        <div className='header'>
          <img
            src={LogoDeepCenter}
            width={'23px'}
            height={'19px'}
            alt='logo Deep Center'
          />
          <div className='titleNode'>
            <h4>{shortNameRender(data.label, 8)}</h4>
            <span>Deep Center</span>
          </div>
        </div>
        <div className='main'>
          <img
            src={GifDeepCenter}
            width={'128px'}
            height={'50px'}
            alt='logo Deep Center'
          />
        </div>
      </NodeContent>
      <Handle type='source' position={Position.Right} />
    </NodeHangUpContainer>
  );
};

export const DeepCenterNode = memo(DeepCenterNodeComponent);
