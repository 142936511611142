import { errorToast } from '@/components/FormComponents/Toast';
import { v4 as uuid } from 'uuid';

import { useState, useCallback, useEffect, useRef } from 'react';
import { useReactFlow, useStore } from 'reactflow';

export function useCopyPaste() {
  const mousePosRef = useRef({ x: 0, y: 0 });
  const rfDomNode = useStore((state) => state.domNode);

  const { getNodes, setNodes, setEdges, screenToFlowPosition } = useReactFlow();
  const [bufferedNodes, setBufferedNodes] = useState([]);

  useEffect(() => {
    const events = ['copy', 'paste'];

    if (rfDomNode) {
      const preventDefault = (e) => e.preventDefault();
      const onMouseMove = (event) => {
        mousePosRef.current = {
          x: event.clientX,
          y: event.clientY,
        };
      };

      for (const event of events) {
        rfDomNode.addEventListener(event, preventDefault);
      }

      rfDomNode.addEventListener('mousemove', onMouseMove);

      return () => {
        for (const event of events) {
          rfDomNode.removeEventListener(event, preventDefault);
        }

        rfDomNode.removeEventListener('mousemove', onMouseMove);
      };
    }
  }, [rfDomNode]);

  function renderNameCopy(nameNode) {
    const newRandomNum = Math.floor(Math.random() * 100);

    if (nameNode.includes('-COPY')) {
      const replaceCopy = nameNode.replace(/-COPY.*/, '');
      return `${replaceCopy}-COPY-${newRandomNum}`;
    } else {
      return `${nameNode}-COPY-${newRandomNum}`;
    }
  }

  const copy = useCallback(() => {
    const nodes = getNodes();
    const selectedNodes = nodes.filter((node) => node.selected);
    const checkCopyFirstNode = selectedNodes.filter(
      (node) => node.id === '1'
    ).length;
    if (checkCopyFirstNode > 0) {
      return errorToast('Init Handler não pode ser copiado!');
    }
    const parentNodesSet = new Set();
    selectedNodes.forEach((selectedNode) => {
      const parentNodes = nodes.filter(
        (node) => node.parentNode === selectedNode.id
      );
      parentNodes.forEach((parentNode) => parentNodesSet.add(parentNode));
      selectedNode.parentNodes = parentNodes;
    });
    // Converte o conjunto de nós pais em um array
    const parentNodes = Array.from(parentNodesSet);
    // Combina nós selecionados e seus nós pais
    setBufferedNodes([...selectedNodes, ...parentNodes]);
  }, [getNodes, setBufferedNodes]);

  const paste = useCallback(() => {
    const now = Date.now();

    const renderNewId = (dataField = []) => {
      if (dataField.length > 0) {
        return dataField.map((param) => ({
          ...param,
          idSelect: uuid(),
        }));
      }
      return [];
    };

    const newNodes = bufferedNodes.map((node) => {
      const id = `${node.id}-${now}`;
      const x = node.parentNode ? node.position.x : node.position.x + 30;
      const y = node.parentNode ? node.position.y : node.position.y + 40;
      const copyLabel = node.parentNode
        ? node.data.label
        : renderNameCopy(node.data.label);

      if (node.data.handler === 'apiData') {
        return {
          ...node,
          id,
          position: { x, y },
          parentNode: node.parentNode ? `${node.parentNode}-${now}` : false,
          data: {
            ...node.data,
            label: copyLabel,
            parameters: renderNewId(node.data.parameters),
            headers: renderNewId(node.data.headers),
            body: renderNewId(node.data.body),
          },
        };
      }

      return {
        ...node,
        id,
        position: { x, y },
        parentNode: node.parentNode ? `${node.parentNode}-${now}` : false,
        data: { ...node.data, label: copyLabel },
      };
    });

    setNodes((nodes) => {
      return [
        ...nodes.map((node) => ({ ...node, selected: false })),
        ...newNodes,
      ];
    });
  }, [
    bufferedNodes,
    screenToFlowPosition,
    setNodes,
    setEdges,
    setBufferedNodes,
  ]);

  return { copy, paste };
}

export default useCopyPaste;
