import { useTranslation } from 'react-i18next';
import { TableCurrentList } from './styled';
import { TablePagination } from '@/components/Tables/TablePagination';
import useWindowDimensions from '@/hooks/useWidth';
import { useLayoutEffect, useRef, useState } from 'react';

export const TableCallHistory = ({ dataTable }) => {
  const { t } = useTranslation();
  const { height } = useWindowDimensions();
  const tableCallHistoryRef = useRef(null);

  const [heightTable, setHeightTable] = useState(0);

  useLayoutEffect(() => {
    if (tableCallHistoryRef) {
      const tableMaxWidth = tableCallHistoryRef.current.offsetHeight;
      const cellMinWidth = 52;
      const cellCount = Math.floor(tableMaxWidth / cellMinWidth);
      setHeightTable(cellCount);
    }
  }, [height]);

  const ColumnCallHistory = [
    {
      Header: t('agents.callHistory.tableHeaderOne'),
      accessor: 'id',
    },
    {
      Header: t('agents.callHistory.tableHeaderTwo'),
      accessor: 'start',
    },
    {
      Header: t('agents.callHistory.tableHeaderThree'),
      accessor: 'end',
    },
    {
      Header: t('agents.callHistory.tableHeaderFour'),
      accessor: 'phone',
    },
    {
      Header: t('agents.callHistory.tableHeaderFive'),
      accessor: 'queue',
    },
    {
      Header: t('agents.callHistory.tableHeaderSix'),
      accessor: 'list',
    },
    {
      Header: t('agents.callHistory.tableHeaderSeven'),
      accessor: 'campaign',
    },
  ];

  return (
    <TableCurrentList ref={tableCallHistoryRef}>
      <TablePagination
        dataColumns={ColumnCallHistory}
        dataTable={dataTable}
        textNoData={t('agents.callHistory.noDataTable')}
        rowsPerPageDisplay={heightTable}
      />
    </TableCurrentList>
  );
};
