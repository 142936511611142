import JsSIP from 'jssip';
import { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';

import { user } from '@store/AuthenticatorStore';
import { isCloseItemAgents } from '@store/NavigationAgentsDashboard';
import { currentUserAgent, isRegisteredSocket } from '@store/AgentsStore';

import useAuth from '@/hooks/Authenticator/useAuth';

import { errorToast } from '@components/FormComponents/Toast';
import { MainAgent } from '@components/pages/DashboardAgents/Navigation/MainAgent';
import { NavBarAgent } from '@components/pages/DashboardAgents/Navigation/NavBarAgent';
import { SideBarAgent } from '@components/pages/DashboardAgents/Navigation/SideBarAgent';

import { ContainerAgentsComponents, ContainerMainAgents } from './styled';
import { useNavigationAccess } from '@/hooks/Navigator/useNavigationAccess';

export const CustomerService = () => {
  const { t } = useTranslation();
  useNavigationAccess();

  const { logOut } = useAuth();

  //   useEffect(() => {
  //     errorToast(t('agents.errorRequestMsg.onRegistered'));
  //     new Promise((resolve) => {
  //       setTimeout(() => {
  //         resolve(logOut());
  //       }, 3000);
  //     });
  //   }, []);

  const matches = useMediaQuery('(max-width:1020px)');
  const [isClose, setIsClose] = useAtom(isCloseItemAgents);
  const [currentUser] = useAtom(user);

  const [userAgent, setUserAgent] = useAtom(currentUserAgent);
  const [userCount, setUserCount] = useState(0);
  const [, setIsRegistered] = useAtom(isRegisteredSocket);

  useEffect(() => {
    matches ? setIsClose(true) : setIsClose(false);
  }, [setIsClose, matches]);

  const socket = new JsSIP.WebSocketInterface(import.meta.env.VITE_SOCKET_URL);

  useEffect(() => {
    if (currentUser) {
      console.log('🚀 -> currentUser:', currentUser);
      const configuration = {
        sockets: [socket],
        uri: `sip:${currentUser.extension}@dialer.voiccelabs.com`,
        password: import.meta.env.VITE_SOCKET_PASS,
      };

      const phone = new JsSIP.UA(configuration);
      phone.on('registrationFailed', () => {
        console.log('error connection');
        // errorToast(t('agents.errorRequestMsg.onRegistered'));
        // new Promise((resolve) => {
        //   setTimeout(() => {
        //     resolve(logOut());
        //   }, 3000);
        // });
      });
      phone.on('registered', () => setIsRegistered(true));
      phone.on('disconnected', () => {
        console.log('disconnected');
        errorToast(t('agents.errorRequestMsg.onRegistered'));
        // logOut();
        /*  errorToast(t('agents.errorRequestMsg.onRegistered')); */
      });

      setUserAgent(phone);
    }
  }, [currentUser]);

  useEffect(() => {
    if (userAgent && userCount < 1) {
      setUserCount((userCount) => userCount + 1);
      userAgent.start();
    }
  }, [userAgent, userCount]);

  const stateSidebar = !isClose ? '300' : '0';

  return (
    <ContainerAgentsComponents>
      <SideBarAgent style={{ zIndex: 1000 }} stateSidebar={stateSidebar} />
      <ContainerMainAgents>
        <NavBarAgent />
        {matches && !isClose && (
          <div
            className='box-verify-click'
            onClick={() => setIsClose(true)}
          ></div>
        )}
        <MainAgent />
      </ContainerMainAgents>
    </ContainerAgentsComponents>
  );
};
