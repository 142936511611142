import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { MdSubtitles, MdSubtitlesOff } from 'react-icons/md';
import { ButtonStyled } from '@/components/FormComponents/ButtonComponent';
import { isLoadingRenderFlow, useHeaderCsv } from '@/store/LoadList';

import { useSaveTemplateLoadList } from '../../../../hooks/useSaveTemplate';

export const HeadersCsvFlow = () => {
  const { t } = useTranslation();
  const [renderHeaderCsv] = useAtom(useHeaderCsv);
  const [, setIsLoadingChangeHeaders] = useAtom(isLoadingRenderFlow);
  const { handleChangeHeaderCsv } = useSaveTemplateLoadList();

  const handleChangeHeaderImport = () => {
    setIsLoadingChangeHeaders(true);
    setTimeout(() => {
      handleChangeHeaderCsv();
    }, 200);
  };

  const usedHeaderCsvText = t(
    'listLoader.importMailing.flow.configs.title-usedHeader'
  );
  const removedHeaderCsvText = t(
    'listLoader.importMailing.flow.configs.title-removedHeader'
  );

  return (
    <ButtonStyled
      title={renderHeaderCsv ? usedHeaderCsvText : removedHeaderCsvText}
      className='no-animation-detail '
      onClick={handleChangeHeaderImport}
      variantstyle={renderHeaderCsv ? 'success' : 'primary'}
    >
      {renderHeaderCsv ? <MdSubtitles /> : <MdSubtitlesOff />}
    </ButtonStyled>
  );
};
