import { useState } from 'react';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { Stack, Box, MenuItem } from '@mui/material';
import { ContainerListUf } from './styled';
import { ButtonStyled } from '@/components/FormComponents/ButtonComponent';
import { SelectComponent } from '@/components/FormComponents/SelectInput';

import { TableListDDDs } from './TableListDDDs';
import { useDDDManager } from '@/hooks/dialControl/DDDManager';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';
import { dataDDDManager } from '@/store/DialingStore';
import { DialogComponent } from '@/components/DialogComponent';
import { CreateGroupDDD } from '../CreateGroupDDD';
import { useClients } from '@/hooks/Clients/useClients';
import { usePermissionsDDDManager } from '@/components/pages/dialControl/DDDManager/hooks/usePermissionsDDDManager';

export const ListDDD = ({ controlStage }) => {
  const { t } = useTranslation();
  const { permissionsCreate } = usePermissionsDDDManager();
  const { isLoadingDataClients } = useClients();

  const {
    loadingDataDDDs,
    mutateGetDataDDD,
    loadingGetDataDDD,
    mutateCreateGroupDDD,
    loadingCreateGroupDDD,
    mutateDeleteGroupDDD,
    loadingDeleteGroupDDD,
  } = useDDDManager();

  const [listDDDManagers] = useAtom(dataDDDManager);

  const [filterGroup, setFilterGroup] = useState('0');

  const [openCreateGroup, setOpenGroup] = useState(false);

  const renderText = (spell) => {
    return t(`dialControl.dddManager.list.${spell}`);
  };

  const controlStageCreate = {
    handleCreate: () => setOpenGroup(true),
    handleClose: () => setOpenGroup(false),
  };

  const filterDDDManager = listDDDManagers.filter(
    (data) => data.cod_ddd_bloq === filterGroup
  );

  const filteredData =
    filterDDDManager.length === 0 || filterGroup === '0'
      ? listDDDManagers
      : filterDDDManager;

  const isLoading =
    loadingDataDDDs || loadingGetDataDDD || isLoadingDataClients;

  return (
    <ContainerListUf initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      {isLoading && <BackDropCircularLoading />}
      <Stack direction={'column'} spacing={2} justifyContent={'space-between'}>
        <Box>
          <SelectComponent
            labelSelect={renderText('label-filter-ddd')}
            value={filterGroup}
            onChange={(e) => setFilterGroup(e.target.value)}
          >
            <MenuItem value={'0'} dense>
              {renderText('option-filter-ddd')}
            </MenuItem>
            {listDDDManagers.length > 0 &&
              listDDDManagers.map((item, i) => (
                <MenuItem value={item.cod_ddd_bloq} key={i} dense>
                  {item.cod_ddd_bloq} - {item.descricao}
                </MenuItem>
              ))}
          </SelectComponent>
        </Box>

        <Stack
          direction={'row'}
          spacing={1}
          justifyContent={'flex-end'}
          height={'100%'}
        >
          <ButtonStyled
            variantstyle='primary'
            size={'small'}
            onClick={controlStageCreate.handleCreate}
            title={renderText('btn-add-ddd')}
            disabled={!permissionsCreate}
          >
            {renderText('btn-add-ddd')}
          </ButtonStyled>
        </Stack>
      </Stack>

      <TableListDDDs
        dataTable={filteredData}
        controlStage={controlStage}
        mutateGetDataDDD={mutateGetDataDDD}
        mutateDeleteGroupDDD={mutateDeleteGroupDDD}
        loadingDeleteGroupDDD={loadingDeleteGroupDDD}
      />

      <DialogComponent
        open={openCreateGroup}
        onClose={controlStageCreate.handleClose}
        fullWidth={true}
      >
        <CreateGroupDDD
          controlStage={controlStageCreate}
          dataReqCreate={{
            mutateCreateGroupDDD,
            loadingCreateGroupDDD,
          }}
        />
      </DialogComponent>
    </ContainerListUf>
  );
};
