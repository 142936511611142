import { useAtom } from 'jotai';
import { useSecurityProfileRender } from '../Navigator/useSecurityProfileRender';
import { sysAdmin } from '@/store/AuthenticatorStore';

export const usePermissionsFlow = () => {
  const { permissionComponent } = useSecurityProfileRender();
  const [isSysAdmin] = useAtom(sysAdmin);

  const permissionsCreate = permissionComponent('create');
  const permissionsEdit = permissionComponent('update');
  const permissionsDelete = permissionComponent('delete');
  const permissionsDisplay = permissionComponent('display');

  return {
    permissionsCreate,
    permissionsEdit,
    permissionsDelete,
    permissionsDisplay,
    isSysAdmin,
  };
};
