import { useAtom } from 'jotai';
import {
  currentStateAgentsDial,
  dataCallInformation,
} from '@store/AgentsStore';
import { ScheduleAgent } from './ScheduleAgent';
import { CallHistory } from './CallHistory';
import { DialAgent } from './DialAgent';
import { AgentsContent } from './styled';
import { AvailableService } from './AvailableService';
import { InCallService } from './InCallService';
import { PostConnection } from './PostConnection';
import { PauseStatus } from './PauseStatus';

const flowAgents = [
  <ScheduleAgent key={0} />,
  <CallHistory key={1} />,
  <DialAgent key={2} />,
];

export const ContentAgents = () => {
  const [currentStateAgents] = useAtom(currentStateAgentsDial);
  const [dataCall] = useAtom(dataCallInformation);

  const renderContent = () => {
    return flowAgents[currentStateAgents];
  };

  switch (dataCall.estado) {
    case 'AGLIG':
      return <AvailableService />;
    case 'EMLIG':
      return <InCallService />;
    case 'POSLIG':
      return <PostConnection />;
    case 'PAUSA':
      return currentStateAgents === null ? (
        <PauseStatus />
      ) : (
        <AgentsContent>{renderContent()}</AgentsContent>
      );
    default:
      return <AgentsContent>{renderContent()}</AgentsContent>;
  }
};
