import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';

import { addNewSchedules } from '@services/agents/schedules/addNewSchedule';
import { deleteSchedules } from '@services/agents/schedules/deleteSchedule';
import { getSchedules } from '@services/agents/schedules/getSchedules';
import { updateSchedules } from '@services/agents/schedules/udpdateSchedule';

import { errorToast, successToast } from '@components/FormComponents/Toast';

export const useSchedules = () => {
  const { t } = useTranslation();

  const { data: dataSchedule, isLoading: loadingDataSchedule } = useQuery(
    'allSchedules',
    async () => {
      return await getSchedules();
    },
    {
      retry: false,
      onError: () => errorToast(t('agents.scheduleAgent.errorRequesSchedule')),
    }
  );

  const { mutate: mutateUpdateSchedule } = useMutation({
    mutationFn: async (dataLog) => {
      return await updateSchedules(dataLog);
    },
    retry: false,
    onError: () => errorToast(t('agents.scheduleAgent.errorUpdateSchedule')),
    onSuccess: () =>
      successToast(t('agents.scheduleAgent.successUpdateSchedule')),
  });

  const { mutate: mutateDeleteSchedule } = useMutation({
    mutationFn: async (dataLog) => {
      return await deleteSchedules(dataLog);
    },
    retry: false,
    onError: () =>
      errorToast(
        t('agents.scheduleAgent.DeleteSchedule.msgErrorDeleteSchedule')
      ),
    onSuccess: () =>
      successToast(
        t('agents.scheduleAgent.DeleteSchedule.msgSuccessDeleteSchedule')
      ),
  });

  const { mutate: mutateAddNewSchedule } = useMutation({
    mutationFn: async (dataLog) => {
      return await addNewSchedules(dataLog);
    },
    retry: false,
    onError: () =>
      errorToast(t('agents.scheduleAgent.newSchedule.msgErrorNewSchedule')),
    onSuccess: () =>
      successToast(t('agents.scheduleAgent.newSchedule.msgSuccessAddSchedule')),
  });

  return {
    dataSchedule,
    loadingDataSchedule,
    mutateUpdateSchedule,
    mutateDeleteSchedule,
    mutateAddNewSchedule,
  };
};
