import { useAtom } from 'jotai';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack, Box } from '@mui/material';
import { TitleComponent } from '@/components/layout/TitleComponent';
import {
  ContainerAddClient,
  FooterAddClient,
  FormAddClient,
  MainAddClient,
} from './styled';
import { ButtonStyled } from '@/components/FormComponents/ButtonComponent';
import { currentFlowClients } from '@/store/Clients';
import { ControlInput } from '@/components/FormComponents/InputComponent';
import { ControlRadio } from '@/components/FormComponents/RadioComponent';
import { useTranslation } from 'react-i18next';
import { useClients } from '@/hooks/Clients/useClients';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';
import { successToast } from '@/components/FormComponents/Toast';

export const CreateClient = () => {
  const { t } = useTranslation();
  const { mutateCreateClient, loadingCreateClient } = useClients();
  const [, setStateRenderClients] = useAtom(currentFlowClients);

  const addNewClientSchema = yup.object().shape({
    isActive: yup
      .string()
      .required(t('clients.createClient.messages.error-field')),
    taxNumber: yup
      .string()
      .required(t('clients.createClient.messages.error-field')),
    companyName: yup
      .string()
      .required(t('clients.createClient.messages.error-field')),
    commercialName: yup
      .string()
      .required(t('clients.createClient.messages.error-field')),
    contactEmail: yup
      .string()
      .email(t('clients.createClient.messages.error-field'))
      .required(t('clients.createClient.messages.error-field')),
    zipcode: yup
      .string()
      .required(t('clients.createClient.messages.error-field')),
    numberAddress: yup
      .string()
      .required(t('clients.createClient.messages.error-field')),
    address: yup
      .string()
      .required(t('clients.createClient.messages.error-field')),
    city: yup.string().required(t('clients.createClient.messages.error-field')),
    state: yup
      .string()
      .required(t('clients.createClient.messages.error-field')),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(addNewClientSchema),
  });

  const handleCreateClient = (data) => {
    const formatAddress = `${data.numberAddress} ${data.address} - ${data.city} - ${data.state}`;

    const dataUpdate = {
      tax_number: data.taxNumber,
      isActive:
        data.isActive === t('clients.createClient.options-statusActive'),
      company_name: data.companyName,
      commercial_name: data.commercialName,
      contact_email: data.contactEmail,
      address: formatAddress,
      zipcode: data.zipcode,
    };

    mutateCreateClient(dataUpdate, {
      onSuccess: () => {
        setStateRenderClients(0);
        successToast(t('clients.createClient.messages.success-created'));
      },
    });
  };

  return (
    <ContainerAddClient initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      {loadingCreateClient && <BackDropCircularLoading />}
      <FormAddClient onSubmit={handleSubmit(handleCreateClient)}>
        <TitleComponent
          textTitle={t('clients.createClient.title-addClient')}
          iconTitle='client'
        />
        <MainAddClient>
          <Stack spacing={2}>
            <Stack
              direction={'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
              spacing={2}
            >
              <Box>
                <TitleComponent
                  textTitle={t('clients.createClient.title-information')}
                />
              </Box>

              <Stack
                direction={'row'}
                alignItems={'center'}
                spacing={3}
                border={
                  errors.isActive ? '1px solid red' : '1px solid transparent'
                }
                padding={'0 8px'}
                borderRadius={'3px'}
              >
                <ControlRadio
                  control={control}
                  label='  '
                  nameControl='isActive'
                  labelValue1={t('clients.createClient.options-statusActive')}
                  labelValue2={t('clients.createClient.options-statusDisabled')}
                />
              </Stack>
            </Stack>

            <ControlInput
              control={control}
              nameControl={'taxNumber'}
              label={t('clients.createClient.label-taxNumber')}
              placeholder={t('clients.createClient.label-taxNumber')}
              error={Boolean(errors.taxNumber)}
              helperText={errors.taxNumber ? errors.taxNumber.message : ' '}
              inputProps={{ maxLength: 14 }}
              autoComplete='off'
            />

            <Stack spacing={1} direction={'row'}>
              <ControlInput
                control={control}
                nameControl={'companyName'}
                label={t('clients.createClient.label-companyName')}
                placeholder={t('clients.createClient.label-companyName')}
                autoComplete='off'
                error={Boolean(errors.companyName)}
                helperText={
                  errors.companyName ? errors.companyName.message : ' '
                }
              />

              <ControlInput
                control={control}
                nameControl={'commercialName'}
                label={t('clients.createClient.label-commercialName')}
                placeholder={t('clients.createClient.label-commercialName')}
                autoComplete='off'
                error={Boolean(errors.commercialName)}
                helperText={
                  errors.commercialName ? errors.commercialName.message : ' '
                }
              />
            </Stack>
          </Stack>
          <Stack spacing={2}>
            <TitleComponent
              textTitle={t('clients.createClient.title-contact')}
            />
            <ControlInput
              control={control}
              nameControl={'contactEmail'}
              label={t('clients.createClient.label-contactEmail')}
              placeholder={t('clients.createClient.label-contactEmail')}
              autoComplete='off'
              error={Boolean(errors.contactEmail)}
              helperText={
                errors.contactEmail ? errors.contactEmail.message : ' '
              }
            />
          </Stack>
          <Stack spacing={2}>
            <TitleComponent
              textTitle={t('clients.createClient.title-address')}
            />
            <ControlInput
              control={control}
              nameControl={'zipcode'}
              label={t('clients.createClient.label-zipcode')}
              placeholder={t('clients.createClient.label-zipcode')}
              inputProps={{ maxLength: 9 }}
              autoComplete='off'
              error={Boolean(errors.zipcode)}
              helperText={errors.zipcode ? errors.zipcode.message : ' '}
            />
            <Stack spacing={1} direction={'row'}>
              <ControlInput
                control={control}
                nameControl={'numberAddress'}
                label={t('clients.createClient.label-numberAddress')}
                placeholder={t('clients.createClient.label-numberAddress')}
                inputProps={{ maxLength: 9 }}
                autoComplete='off'
                error={Boolean(errors.numberAddress)}
                helperText={
                  errors.numberAddress ? errors.numberAddress.message : ' '
                }
              />

              <ControlInput
                control={control}
                nameControl={'address'}
                label={t('clients.createClient.label-address')}
                placeholder={t('clients.createClient.label-address')}
                autoComplete='off'
                error={Boolean(errors.address)}
                helperText={errors.address ? errors.address.message : ' '}
              />
            </Stack>
            <Stack spacing={1} direction={'row'}>
              <ControlInput
                control={control}
                nameControl={'city'}
                label={t('clients.createClient.label-city')}
                placeholder={t('clients.createClient.label-city')}
                autoComplete='off'
                error={Boolean(errors.city)}
                helperText={errors.city ? errors.city.message : ' '}
              />
              <ControlInput
                control={control}
                nameControl={'state'}
                label={t('clients.createClient.label-state')}
                placeholder={t('clients.createClient.label-state')}
                autoComplete='off'
                error={Boolean(errors.state)}
                helperText={errors.state ? errors.state.message : ' '}
              />
            </Stack>
          </Stack>
        </MainAddClient>
        <FooterAddClient>
          <ButtonStyled
            variantstyle={'secondary'}
            size={'small'}
            onClick={() => setStateRenderClients(0)}
          >
            {t('clients.createClient.btn-cancel')}
          </ButtonStyled>
          <ButtonStyled type='submit' size={'small'}>
            {t('clients.createClient.btn-confirm')}
          </ButtonStyled>
        </FooterAddClient>
      </FormAddClient>
    </ContainerAddClient>
  );
};
