import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { MenuItem } from '@mui/material';
import useFormControl from '@/hooks/useFormControl';
import { ButtonStyled } from '@/components/FormComponents/ButtonComponent';
import { TitleComponent } from '@/components/layout/TitleComponent';
import { DescriptionInformation } from '@/components/layout/DescriptionInformation';
import { FormNewQueue } from './styled';
import { dataQueues, dataServersControl } from '@/store/Queues';
import { ControlSelect } from '@/components/FormComponents/SelectInput';
import { successToast } from '@/components/FormComponents/Toast';
import { useCampaigns } from '@/hooks/dialControl/Campaigns/useCampaigns';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';
import { dataListCampaigns } from '@/store/Campaigns';
import { useQueues } from '@/hooks/dialControl/Queues/useQueues';
import { user } from '@/store/AuthenticatorStore';

export function NewQueue({ controlStage }) {
  const { t } = useTranslation();

  const [currentUser] = useAtom(user);
  const [serversControl] = useAtom(dataServersControl);
  const { loadingDataCampaigns } = useCampaigns(0);
  const { loadingCreateQueue, mutateCreateQueue } = useQueues(1);

  const [dataCampaigns] = useAtom(dataListCampaigns);
  const [listQueues] = useAtom(dataQueues);

  const filterClientIdCampaigns = currentUser.userRole.isSysAdmin
    ? dataCampaigns
    : dataCampaigns.filter((item) => item.id_cliente === currentUser.client_id);

  function getListQueuesAvailable(usedQueues, serversControl) {
    const listQueuesAvailable = [];
    for (
      let index = serversControl.first_queue;
      index < serversControl.last_queue;
      index++
    ) {
      if (!usedQueues.has(index)) {
        listQueuesAvailable.push(index);
      }
    }
    return listQueuesAvailable;
  }

  const usedQueues = new Set(listQueues.map((item) => item.fila));
  const availableQueues = getListQueuesAvailable(usedQueues, serversControl);

  const queueManagementSchema = yup.object().shape({
    queue: yup.string().required(),
    campaign: yup.string().required(),
  });

  const { handleSubmit, control, reset, errors } = useFormControl(
    queueManagementSchema
  );

  const handleBackStage = () => {
    controlStage.toStage(0);
  };

  const handleCreate = (data) => {
    const dataCreateQueue = {
      fila: Number(data.queue),
      campanha: data.campaign,
    };

    mutateCreateQueue(dataCreateQueue, {
      onSuccess: () => {
        reset();
        successToast(t('dialControl.queues.newQueue.messages.success-created'));
        controlStage.toStage(0);
      },
    });
  };

  const isLoading = loadingCreateQueue || loadingDataCampaigns;

  return (
    <FormNewQueue
      onSubmit={handleSubmit(handleCreate)}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      {isLoading && <BackDropCircularLoading />}

      <div className='main'>
        <TitleComponent textTitle={t('dialControl.queues.newQueue.title')} />
        <DescriptionInformation
          textDescription={t('dialControl.queues.newQueue.description')}
        />
        <ControlSelect
          control={control}
          nameControl={'queue'}
          label={t('dialControl.queues.newQueue.label-queue')}
          error={Boolean(errors.queue)}
        >
          {availableQueues.map((item, i) => (
            <MenuItem value={item} key={i} dense>
              {item}
            </MenuItem>
          ))}
        </ControlSelect>

        <ControlSelect
          control={control}
          nameControl={'campaign'}
          label={t('dialControl.queues.newQueue.label-campaign')}
          error={Boolean(errors.campaign)}
        >
          <MenuItem value={'0'} dense disabled>
            Select Campaign
          </MenuItem>
          {filterClientIdCampaigns.map((item, i) => (
            <MenuItem value={item.campanha} key={i} dense>
              {item.campanha} - {item.descricao}
            </MenuItem>
          ))}
        </ControlSelect>
      </div>
      <div className='footer'>
        <ButtonStyled
          variantstyle={'secondary'}
          size='small'
          onClick={handleBackStage}
        >
          {t('dialControl.queues.newQueue.btn-cancel')}
        </ButtonStyled>
        <ButtonStyled type='submit' size='small'>
          {t('dialControl.queues.newQueue.btn-submit')}
        </ButtonStyled>
      </div>
    </FormNewQueue>
  );
}
