import { useTranslation } from 'react-i18next';
import { ButtonStyled } from '@components/FormComponents/ButtonComponent';
import { TableConfigQueue } from './TableConfigQueue';
import { FooterConfigQueue, FormConfig, TableConfigContent } from './styled.';
import { useAtom } from 'jotai';
import { selectedQueue } from '@/store/DialingStore';
import { successToast } from '@/components/FormComponents/Toast';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';

import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSettingsDialing } from '@/hooks/Dialing/useSettingsDialing';
import { DescriptionInformation } from '@/components/layout/DescriptionInformation';
import { Stack } from '@mui/system';

export const ConfigList = ({ changeFlowDialing }) => {
  const { t } = useTranslation();
  const {
    loadingDataOperators,
    loadingSaveSettingsQueue,
    mutateSaveSettingsQueue,
  } = useSettingsDialing();
  const [dataQueueSelected] = useAtom(selectedQueue);

  const configDialingOperatorSchema = yup.object().shape({
    totalLinesAvailable: yup.number().required(),
    usedLines: yup.number().required(),
    maximumLinesByAgent: yup.number().required(),
    landline: yup.string().required(),
    mobilePhone: yup.string().required(),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(configDialingOperatorSchema),
  });

  const handleBackStage = () => {
    reset();
    return changeFlowDialing(1);
  };

  const handleChangeInfoLine = (data) => {
    const { fila, campanha } = dataQueueSelected;
    const dataConfig = {
      fila: fila,
      campanha: campanha,
      max_linhas: data.usedLines,
      max_linha_por_agente: data.maximumLinesByAgent,
      grupo_master_linhas: data.landline,
      grupo_master_linhas_2: data.mobilePhone,
      total_linhas_disponivel: data.totalLinesAvailable,
    };

    mutateSaveSettingsQueue(dataConfig, {
      onSuccess: () => {
        successToast(t('dialing.configQueue.msg-success-save-configs'));
        reset();
        changeFlowDialing(0);
      },
    });
  };

  const isLoading = loadingDataOperators || loadingSaveSettingsQueue;

  return (
    <FormConfig
      onSubmit={handleSubmit(handleChangeInfoLine)}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      {isLoading && <BackDropCircularLoading />}
      <Stack spacing={3}>
        <DescriptionInformation
          textDescription={t('dialing.configQueue.description')}
        />
      </Stack>
      <TableConfigContent>
        <TableConfigQueue
          dataQueueSelected={dataQueueSelected}
          control={control}
          errors={errors}
        />
      </TableConfigContent>
      <FooterConfigQueue>
        <ButtonStyled
          variantstyle='secondary'
          onClick={handleBackStage}
          size={'small'}
        >
          {t('dialing.newListConfig.btn-comeBack')}
        </ButtonStyled>
        <ButtonStyled type='submit' size={'small'}>
          {t('dialing.newListConfig.btn-apply')}
        </ButtonStyled>
      </FooterConfigQueue>
    </FormConfig>
  );
};
