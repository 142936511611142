import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { MenuItem, Stack } from '@mui/material';
import useFormControl from '@/hooks/useFormControl';
import { successToast } from '@/components/FormComponents/Toast';
import { TitleComponent } from '@/components/layout/TitleComponent';
import { ControlSelect } from '@/components/FormComponents/SelectInput';
import { ControlInput } from '@/components/FormComponents/InputComponent';
import { ButtonStyled } from '@/components/FormComponents/ButtonComponent';

import { useAtom } from 'jotai';
import { dataListClients } from '@/store/Clients';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';
import { useCampaigns } from '@/hooks/dialControl/Campaigns/useCampaigns';
import { user } from '@/store/AuthenticatorStore';
import {
  ContainerDispositionsCampaigns,
  FooterEditCampaign,
  FormEditCampaign,
  HeaderEditCampaign,
  MainEditCampaign,
} from './styled';
import { dataUsedSequenceList, selectedCampaign } from '@/store/Campaigns';
import { DragTransferDisposition } from './DragTransferList';
import { useState } from 'react';

import { dataDispositionsDialing } from '@/store/DialingStore';

const rangeSequencialNumberList = Array.from({ length: 999 }, (_, i) => i + 1);

export const EditCampaign = ({ controlStage }) => {
  const { t } = useTranslation();

  const [listsDispositions] = useAtom(dataDispositionsDialing);

  const { currentStage, toStage } = controlStage;

  const [currentUser] = useAtom(user);
  const [usedSequenceList] = useAtom(dataUsedSequenceList);
  const [listClients] = useAtom(dataListClients);

  const [dispositionSelected, setDispositionSelected] = useState([]);

  const [selectedDataCampaign, setSelectedDataCampaign] =
    useAtom(selectedCampaign);

  const {
    mutateUpdateCampaign,
    loadingUpdateCampaign,
    mutateSendDispositionsCampaign,
    loadingSendDispositionsCampaign,
  } = useCampaigns(currentStage);

  const readOnlyChangeClient =
    currentUser.client_id === '29904593-681a-4ad6-97c8-e26483468b73' ||
    currentUser.userRole.isSysAdmin;

  const editCampaignSchema = yup.object().shape({
    newCampaignName: yup
      .string()
      .required(
        t(
          'dialControl.campaigns.createCampaign.errosInputs.error-newCampaignName'
        )
      ),
    newCampaignDescription: yup
      .string()
      .required(
        t(
          'dialControl.campaigns.createCampaign.errosInputs.error-newCampaignDescription'
        )
      ),
    listSuffix: yup
      .string()
      .required(
        t('dialControl.campaigns.createCampaign.errosInputs.error-listSuffix')
      ),
    clientSelected: yup
      .string()
      .required(
        t(
          'dialControl.campaigns.createCampaign.errosInputs.error-clientSelected'
        )
      ),
    sequentialNumber: yup
      .string()
      .required(
        t(
          'dialControl.campaigns.createCampaign.errosInputs.error-sequentialNumber'
        )
      ),
  });

  const { handleSubmit, control, errors, reset } =
    useFormControl(editCampaignSchema);

  const handleBackStage = () => {
    setSelectedDataCampaign(null);
    toStage(0);
  };

  const renderDispositionsSelected = (listDispositions, campaignSelected) => {
    const formatDispositions = listDispositions.map((item) => {
      const separateString = item.split('-');
      const [disposition, description] = separateString;
      return {
        cod_lig: disposition.trim(),
        descricao: description.trim(),
      };
    });

    const dataDispositionsCampaign = {
      idCampanha: campaignSelected ? campaignSelected.toString() : '',
      codigoLigacao: formatDispositions,
    };

    return dataDispositionsCampaign;
  };

  const handleEditCampaign = (data) => {
    const dataEdit = {
      id: selectedDataCampaign.id,
      campanha: data.newCampaignName,
      descricao: data.newCampaignDescription,
      id_cliente: data.clientSelected,
      sequencial_lista: data.sequentialNumber,
      sufixo_cria_lista: data.listSuffix,
    };

    const dispositionsCampaign = renderDispositionsSelected(
      dispositionSelected,
      selectedDataCampaign.id
    );

    mutateUpdateCampaign(dataEdit, {
      onSuccess: () => {
        mutateSendDispositionsCampaign(dispositionsCampaign, {
          onSuccess: () => {
            successToast(
              t('dialControl.campaigns.createCampaign.success-updated')
            );
            reset();
            handleBackStage();
          },
        });
      },
    });
  };

  const isLoading = loadingUpdateCampaign || loadingSendDispositionsCampaign;

  return (
    <FormEditCampaign
      onSubmit={handleSubmit(handleEditCampaign)}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      {isLoading && <BackDropCircularLoading />}

      <MainEditCampaign>
        <Stack width={'100%'} spacing={2}>
          <TitleComponent
            textTitle={t(
              'dialControl.campaigns.createCampaign.title-information'
            )}
          />

          <ControlSelect
            control={control}
            nameControl={'clientSelected'}
            label={t('dialControl.campaigns.createCampaign.label-client')}
            defaultValue={selectedDataCampaign.id_cliente}
            readOnly={!readOnlyChangeClient}
            error={Boolean(errors.clientSelected)}
            helperText={
              errors.clientSelected ? errors.clientSelected.message : '   '
            }
          >
            <MenuItem value={'0'} disabled dense>
              {t('dialControl.campaigns.createCampaign.options-client')}
            </MenuItem>
            {listClients.length > 0 &&
              listClients.map((item, i) => (
                <MenuItem value={item.id} key={i} dense>
                  {item.commercial_name}
                </MenuItem>
              ))}
          </ControlSelect>
          <Stack width={'100%'} direction={'row'} spacing={2}>
            <ControlInput
              control={control}
              nameControl={'newCampaignName'}
              defaultValue={selectedDataCampaign.campanha}
              label={t('dialControl.campaigns.createCampaign.label-campaign')}
              error={Boolean(errors.newCampaignName)}
              helperText={
                errors.newCampaignName ? errors.newCampaignName.message : '  '
              }
            />

            <ControlInput
              control={control}
              nameControl={'newCampaignDescription'}
              defaultValue={selectedDataCampaign.descricao}
              label={t(
                'dialControl.campaigns.createCampaign.label-description'
              )}
              error={Boolean(errors.newCampaignDescription)}
              helperText={
                errors.newCampaignDescription
                  ? errors.newCampaignDescription.message
                  : ''
              }
            />
          </Stack>
        </Stack>

        <Stack width={'100%'} spacing={2}>
          <TitleComponent
            textTitle={t('dialControl.campaigns.createCampaign.title-detail')}
          />
          <Stack width={'100%'} direction={'row'} spacing={2}>
            <ControlSelect
              control={control}
              nameControl={'sequentialNumber'}
              defaultValue={Number(selectedDataCampaign.sequencial_lista)}
              labelSelect={t(
                'dialControl.campaigns.createCampaign.label-sequential'
              )}
              error={Boolean(errors.sequentialNumber)}
              helperText={
                errors.sequentialNumber ? errors.sequentialNumber.message : ''
              }
            >
              <MenuItem value={'select'} disabled dense>
                {t('dialControl.campaigns.createCampaign.options-sequential')}
              </MenuItem>
              {rangeSequencialNumberList.map((numb) => (
                <MenuItem
                  key={numb}
                  value={numb}
                  dense
                  disabled={usedSequenceList.includes(numb)}
                >
                  {numb}
                </MenuItem>
              ))}
            </ControlSelect>

            <ControlInput
              control={control}
              nameControl={'listSuffix'}
              defaultValue={selectedDataCampaign.sufixo_cria_lista}
              label={t('dialControl.campaigns.createCampaign.label-suffix')}
              error={Boolean(errors.listSuffix)}
              helperText={errors.listSuffix ? errors.listSuffix.message : ''}
            />
          </Stack>
        </Stack>

        <ContainerDispositionsCampaigns>
          <DragTransferDisposition
            codeList={listsDispositions}
            changeDataCodeList={setDispositionSelected}
            usedDispositions={selectedDataCampaign.dispositions}
          />
        </ContainerDispositionsCampaigns>
      </MainEditCampaign>

      <FooterEditCampaign>
        <ButtonStyled
          variantstyle={'secondary'}
          onClick={handleBackStage}
          size={'small'}
        >
          {t('usersAgentsList.addUser.btn-cancel')}
        </ButtonStyled>
        <ButtonStyled type='submit' size={'small'}>
          {t('dialControl.campaigns.createCampaign.btn-update-campaign')}
        </ButtonStyled>
      </FooterEditCampaign>
    </FormEditCampaign>
  );
};
