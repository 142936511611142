import styled from 'styled-components';
import { motion } from 'framer-motion';

export const NavbarContainer = styled(motion.div)`
  background: ${(props) => props.theme.colors.containers.baseContainer};
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.25);

  .img__logo__mobile {
    margin-top: 2px;
  }
  @media (max-width: 400px) {
    padding: 0 40px;
  }

  .rotate {
    transform: rotate(-90deg);
  }

  .logoNavbar {
    width: 150px;

    @media (min-width: 1020px) {
      display: none;
    }
  }

  .btn-nav-flow {
    width: 240px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
      margin-top: 2px;
    }
    button {
      background: transparent;
      border: none;
      user-select: none;

      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      padding: 5px;

      svg {
        color: ${(props) => props.theme.colors.primary};
        font-size: 1.4rem;
        transition: all 0.2s;
      }
    }
    @media (max-width: 1020px) {
      display: none;
    }
  }

  .btn-nav {
    width: 50px;
    button {
      background: transparent;
      border: none;
      user-select: none;

      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      padding: 5px;

      svg {
        color: ${(props) => props.theme.colors.primary};
        font-size: 1.4rem;
        transition: all 0.2s;
      }
      @media (min-width: 1020px) {
        display: none;
      }
    }
  }

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    .titleComponent {
      user-select: none;
      flex: 1;
      h1 {
        color: ${(props) => props.theme.colors.typography.baseText};
        /* font-size: 1.2rem; */
        font-size: 1rem;
        line-height: 0;
        padding-top: 6px;
      }
    }

    .controls_nav {
      width: 100px;
      height: 100%;

      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem;

      svg {
        color: ${(props) => props.theme.colors.primary};
        font-size: 1.2rem;
      }
    }
  }
`;
