import styled from 'styled-components';

export const ContainerTableCampaign = styled.div`
  width: 100%;
  height: 100%;
  min-height: 200px;
  user-select: none;
  padding-top: 20px;

  table {
    tbody {
      tr {
        height: 42px;
        td {
          z-index: 1;
          padding: 0 0.8rem;

          &:nth-child(1) {
            width: 100px;
            min-width: 100px;
          }

          &:nth-child(2) {
            width: 31%;
            min-width: 200px;
          }

          &:nth-child(3) {
            width: 31%;
            min-width: 200px;
          }

          &:nth-child(4) {
            width: 31%;
            min-width: 200px;
          }

          &:last-child {
            width: 40px;
            button {
              padding: 0;
              height: 20px;
              width: 20px;
              min-width: 20px !important;
            }
          }
        }
      }
    }
  }
`;
