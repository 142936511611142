import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useSchedules } from '@hooks/Agents/useSchedules';

import { ButtonStyled } from '@components/FormComponents/ButtonComponent';
import { InputComponent } from '@components/FormComponents/InputComponent';
import { errorToast } from '@components/FormComponents/Toast';

import { ContainerAddNewSchedule, FormNewSchedule } from './styled';
import { AiOutlineSchedule } from 'react-icons/ai';
import InputMask from 'react-input-mask';
import { removeMaskPhone } from '@/utils/phoneMakTransform';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
/* import { addMinutes } from 'date-fns'; */
import ptBR from 'date-fns/locale/pt-BR';
// import { TimePickerComponent } from '@/components/FormComponents/TimePickerComponent-OLD';

export const AddNewSchedule = ({ closeModal }) => {
  const { t } = useTranslation();
  const { mutateAddNewSchedule } = useSchedules();

  const [nameAddField, setNameAddField] = useState('');
  const [errorFieldName, setErrorFieldName] = useState(false);

  const [phoneAddField, setPhoneAddField] = useState('');
  const [errorFieldPhone, setErrorFieldPhone] = useState(false);

  const [callTargetAddField, setCallTargetAddField] = useState('');
  const [errorFieldCallTarget, setErrorFieldCallTarget] = useState(false);

  const [lastContactAddField, setLastContactAddField] = useState('');
  const [errorFieldLastContact, setErrorFieldLastContact] = useState(false);

  const [noteAddField, setNoteAddField] = useState('');
  const [errorFieldNote, setErrorFieldNote] = useState(false);

  const [initTimeEditField, setInitTimeEditField] = useState();

  useEffect(() => {
    errorFieldName && nameAddField.length > 0 && setErrorFieldName(false);
    errorFieldPhone && phoneAddField.length > 0 && setErrorFieldPhone(false);
    errorFieldCallTarget &&
      callTargetAddField.length > 0 &&
      setErrorFieldCallTarget(false);
    errorFieldLastContact &&
      lastContactAddField.length > 0 &&
      setErrorFieldLastContact(false);
    errorFieldNote && noteAddField.length > 0 && setErrorFieldNote(false);
  }, [
    errorFieldPhone,
    phoneAddField,
    errorFieldName,
    nameAddField,
    errorFieldCallTarget,
    callTargetAddField,
    errorFieldLastContact,
    lastContactAddField,
    errorFieldNote,
    noteAddField,
  ]);

  const hasErrorForm =
    errorFieldPhone ||
    errorFieldName ||
    errorFieldNote ||
    errorFieldLastContact ||
    errorFieldCallTarget;

  const handleSubmitNewSchedule = (e) => {
    e.preventDefault();

    if (nameAddField.length === 0) {
      setErrorFieldName(true);
    }

    if (callTargetAddField.length === 0) {
      setErrorFieldCallTarget(true);
    }

    if (lastContactAddField.length === 0) {
      setErrorFieldLastContact(true);
    }

    if (noteAddField.length === 0) {
      setErrorFieldNote(true);
    }

    if (
      phoneAddField.length === 0 ||
      removeMaskPhone(phoneAddField).length < 14
    ) {
      setErrorFieldPhone(true);
    }

    if (phoneAddField.length === 0) {
      return errorToast(
        t('agents.scheduleAgent.newSchedule.msgErrorNewSchedule')
      );
    }

    console.log({
      name: nameAddField,
      phone: removeMaskPhone(phoneAddField),
      callTarget: callTargetAddField,
      lastContact: lastContactAddField,
      note: noteAddField,
    });

    mutateAddNewSchedule();
    closeModal();
  };

  return (
    <ContainerAddNewSchedule>
      <div className='titleNewSchedule'>
        <AiOutlineSchedule />
        <h2>{t('agents.scheduleAgent.newSchedule.titleNewSchedule')}</h2>
      </div>
      <FormNewSchedule onSubmit={handleSubmitNewSchedule}>
        <div className='mainForm'>
          <InputComponent
            label={t('agents.scheduleAgent.newSchedule.nameSchedule')}
            value={nameAddField}
            onChange={(e) => setNameAddField(e.target.value)}
            helperText={
              errorFieldName
                ? t('agents.scheduleAgent.newSchedule.msgRequireName')
                : ' '
            }
            error={errorFieldName}
          />

          <InputMask
            mask='(999) 999 9999'
            value={phoneAddField}
            onChange={(e) => setPhoneAddField(e.target.value)}
          >
            {() => (
              <InputComponent
                label={t('agents.scheduleAgent.newSchedule.phoneSchedule')}
                helperText={
                  errorFieldPhone
                    ? t(
                        'agents.scheduleAgent.newSchedule.defaultOptionPhoneSchedule'
                      )
                    : ' '
                }
                error={errorFieldPhone}
              />
            )}
          </InputMask>

          <InputComponent
            label={t('agents.scheduleAgent.newSchedule.callTargetSchedule')}
            value={callTargetAddField}
            onChange={(e) => setCallTargetAddField(e.target.value)}
            helperText={
              errorFieldCallTarget
                ? t('agents.scheduleAgent.newSchedule.msgRequireCallTarget')
                : ' '
            }
            error={errorFieldCallTarget}
          />

          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={ptBR}
          >
            {/* <TimePickerComponent
              initializedDate={new Date()}
              labelPicker={t(
                'agents.scheduleAgent.newSchedule.lastContactSchedule'
              )}
              selectedStatedTime={setInitTimeEditField}
            /> */}
          </LocalizationProvider>
          <br />

          <InputComponent
            label={t('agents.scheduleAgent.newSchedule.lastContactSchedule')}
            value={lastContactAddField}
            onChange={(e) => setLastContactAddField(e.target.value)}
            helperText={errorFieldLastContact ? ' ' : ' '}
            error={errorFieldLastContact}
          />

          <InputComponent
            label={t('agents.scheduleAgent.newSchedule.noteSchedule')}
            value={noteAddField}
            onChange={(e) => setNoteAddField(e.target.value)}
            helperText={
              errorFieldNote
                ? t('agents.scheduleAgent.newSchedule.msgRequireNote')
                : ' '
            }
            error={errorFieldNote}
          />
        </div>
        <div className='footerNewSchedule'>
          <ButtonStyled variantstyle={'secondary'} onClick={closeModal}>
            {t('agents.scheduleAgent.newSchedule.btnCancelNewSchedule')}
          </ButtonStyled>
          <ButtonStyled type='submit' disabled={hasErrorForm}>
            {t('agents.scheduleAgent.newSchedule.btnConfirmNewSchedule')}
          </ButtonStyled>
        </div>
      </FormNewSchedule>
    </ContainerAddNewSchedule>
  );
};
