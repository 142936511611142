import { useAtom } from 'jotai';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack, Box } from '@mui/material';
import { TitleComponent } from '@/components/layout/TitleComponent';
import {
  ContainerAddClient,
  FooterAddClient,
  FormAddClient,
  MainAddClient,
} from './styled';
import { ButtonStyled } from '@/components/FormComponents/ButtonComponent';
import { currentFlowClients, selectedClient } from '@/store/Clients';
import { ControlInput } from '@/components/FormComponents/InputComponent';
import { ControlRadio } from '@/components/FormComponents/RadioComponent';
import { useTranslation } from 'react-i18next';
import { useClients } from '@/hooks/Clients/useClients';
import { successToast } from '@/components/FormComponents/Toast';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';

export const EditClient = () => {
  const { t } = useTranslation();
  const { mutateUpdateClients, loadingUpdateClients } = useClients();
  const [, setStateRenderClients] = useAtom(currentFlowClients);
  const [dataSelectedClient, setDataSelectedClient] = useAtom(selectedClient);

  const editClientSchema = yup.object().shape({
    isActive: yup
      .string()
      .required(t('clients.editClient.messages.error-field')),
    taxNumber: yup
      .string()
      .required(t('clients.editClient.messages.error-field')),
    companyName: yup
      .string()
      .required(t('clients.editClient.messages.error-field')),
    commercialName: yup
      .string()
      .required(t('clients.editClient.messages.error-field')),
    contactEmail: yup
      .string()
      .required(t('clients.editClient.messages.error-field')),
    zipcode: yup
      .string()
      .required(t('clients.editClient.messages.error-field')),
    numberAddress: yup
      .number()
      .required(t('clients.editClient.messages.error-field')),
    address: yup
      .string()
      .required(t('clients.editClient.messages.error-field')),
    city: yup.string().required(t('clients.editClient.messages.error-field')),
    state: yup.string().required(t('clients.editClient.messages.error-field')),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(editClientSchema),
  });

  const handleCreateClient = (data) => {
    const formatAddress = `${data.numberAddress} ${data.address} - ${data.city} - ${data.state}`;

    const dataUpdate = {
      id: dataSelectedClient.id,
      tax_number: data.taxNumber,
      isActive: data.isActive === t('clients.editClient.options-statusActive'),
      company_name: data.companyName,
      commercial_name: data.commercialName,
      contact_email: data.contactEmail,
      address: formatAddress,
      zipcode: data.zipcode,
    };

    mutateUpdateClients(dataUpdate, {
      onSuccess: () => {
        successToast(t('clients.editClient.messages.success-updated'));
        setStateRenderClients(0);
        setDataSelectedClient(null);
      },
    });
  };

  return (
    <ContainerAddClient initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      {loadingUpdateClients && <BackDropCircularLoading />}
      <FormAddClient onSubmit={handleSubmit(handleCreateClient)}>
        <TitleComponent
          textTitle={t('clients.editClient.title-addClient')}
          iconTitle='client'
        />
        <MainAddClient>
          <Stack spacing={2}>
            <Stack
              direction={'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
              spacing={2}
            >
              <Box>
                <TitleComponent
                  textTitle={t('clients.editClient.title-information')}
                />
              </Box>

              <Stack direction={'row'} alignItems={'center'} spacing={3}>
                <ControlRadio
                  control={control}
                  defaultValue={
                    dataSelectedClient.isActive
                      ? t('clients.editClient.options-statusActive')
                      : t('clients.editClient.options-statusDisabled')
                  }
                  nameControl='isActive'
                  labelValue1={t('clients.editClient.options-statusActive')}
                  labelValue2={t('clients.editClient.options-statusDisabled')}
                />
              </Stack>
            </Stack>

            <ControlInput
              control={control}
              nameControl={'taxNumber'}
              defaultValue={dataSelectedClient?.tax_number ?? '-'}
              label={t('clients.editClient.label-taxNumber')}
              placeholder={t('clients.editClient.label-taxNumber')}
              error={Boolean(errors.taxNumber)}
              helperText={errors.taxNumber ? errors.taxNumber.message : ' '}
              inputProps={{ maxLength: 14 }}
            />

            <Stack spacing={1} direction={'row'}>
              <ControlInput
                control={control}
                nameControl={'companyName'}
                defaultValue={dataSelectedClient?.company_name ?? '-'}
                label={t('clients.editClient.label-companyName')}
                placeholder={t('clients.editClient.label-companyName')}
                error={Boolean(errors.companyName)}
                helperText={
                  errors.companyName ? errors.companyName.message : ' '
                }
              />

              <ControlInput
                control={control}
                nameControl={'commercialName'}
                defaultValue={dataSelectedClient?.commercial_name ?? '-'}
                label={t('clients.editClient.label-commercialName')}
                placeholder={t('clients.editClient.label-commercialName')}
                error={Boolean(errors.commercialName)}
                helperText={
                  errors.commercialName ? errors.commercialName.message : ' '
                }
              />
            </Stack>
          </Stack>
          <Stack spacing={2}>
            <TitleComponent textTitle={t('clients.editClient.title-contact')} />
            <ControlInput
              control={control}
              nameControl={'contactEmail'}
              defaultValue={dataSelectedClient?.contact_email ?? '-'}
              label={t('clients.editClient.label-contactEmail')}
              placeholder={t('clients.editClient.label-contactEmail')}
              error={Boolean(errors.contactEmail)}
              helperText={
                errors.contactEmail ? errors.contactEmail.message : ' '
              }
            />
          </Stack>
          <Stack spacing={2}>
            <TitleComponent textTitle={t('clients.editClient.title-address')} />
            <ControlInput
              control={control}
              nameControl={'zipcode'}
              defaultValue={dataSelectedClient?.zipcode ?? '-'}
              label={t('clients.editClient.label-zipcode')}
              placeholder={t('clients.editClient.label-zipcode')}
              inputProps={{ maxLength: 9 }}
              error={Boolean(errors.zipcode)}
              helperText={errors.zipcode ? errors.zipcode.message : ' '}
            />
            <Stack spacing={1} direction={'row'}>
              <ControlInput
                control={control}
                nameControl={'numberAddress'}
                defaultValue={dataSelectedClient?.numberAddress ?? '-'}
                label={t('clients.editClient.label-numberAddress')}
                placeholder={t('clients.editClient.label-numberAddress')}
                inputProps={{ maxLength: 9 }}
                error={Boolean(errors.numberAddress)}
                helperText={
                  errors.numberAddress ? errors.numberAddress.message : ' '
                }
              />

              <ControlInput
                control={control}
                nameControl={'address'}
                defaultValue={dataSelectedClient?.street ?? '-'}
                label={t('clients.editClient.label-address')}
                placeholder={t('clients.editClient.label-address')}
                error={Boolean(errors.address)}
                helperText={errors.address ? errors.address.message : ' '}
              />
            </Stack>
            <Stack spacing={1} direction={'row'}>
              <ControlInput
                control={control}
                nameControl={'city'}
                defaultValue={dataSelectedClient?.city ?? '-'}
                label={t('clients.editClient.label-city')}
                placeholder={t('clients.editClient.label-city')}
                error={Boolean(errors.city)}
                helperText={errors.city ? errors.city.message : ' '}
              />
              <ControlInput
                control={control}
                nameControl={'state'}
                defaultValue={dataSelectedClient?.state ?? '-'}
                label={t('clients.editClient.label-state')}
                placeholder={t('clients.editClient.label-state')}
                error={Boolean(errors.state)}
                helperText={errors.state ? errors.state.message : ' '}
              />
            </Stack>
          </Stack>
        </MainAddClient>
        <FooterAddClient>
          <ButtonStyled
            variantstyle={'secondary'}
            size={'small'}
            onClick={() => setStateRenderClients(0)}
          >
            {t('clients.editClient.btn-cancel')}
          </ButtonStyled>
          <ButtonStyled type='submit' size={'small'}>
            {t('clients.editClient.btn-confirm')}
          </ButtonStyled>
        </FooterAddClient>
      </FormAddClient>
    </ContainerAddClient>
  );
};
