import styled, { css } from 'styled-components';

export const SubNodeContainer = styled.div`
  padding: 5px;
  width: 220px;
  border-radius: 2px;
  background: linear-gradient(45deg, #5794ff, #9bbdf8, #005af5);
  background-size: 600% 100%;
  animation: gradient 3s linear infinite;
  animation-direction: alternate;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;

  ${(props) =>
    props.typeHandler === 'successCondition' &&
    css`
      background: linear-gradient(45deg, #4c48ff, #4c48ff);
    `}

  ${(props) =>
    props.typeHandler === 'timeOutCondition' &&
    css`
      background: linear-gradient(45deg, #ff57ab, #ff57ab);
    `}

  ${(props) =>
    props.typeHandler === 'errorCondition' &&
    css`
      background: linear-gradient(45deg, #ff4c43, #ff4c43);
    `} 

  @keyframes gradient {
    0% {
      background-position: 0%;
    }
    100% {
      background-position: 100%;
    }
  }

  .react-flow__handle {
    background: transparent;
    width: 30px;
    height: 100%;
    border-radius: 0;
    border: none;
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 10px;
      width: 8px;
      height: 8px;
      left: 10px;
      border: 2px solid ${(props) => props.theme.colors.containers.colorCard};
      ${(props) =>
        props.typeHandler === 'successCondition' &&
        css`
          background: ${(props) => props.theme.colors.primary};
        `}

      ${(props) =>
        props.typeHandler === 'timeOutCondition' &&
        css`
          background: #ff57ab;
        `}

    ${(props) =>
        props.typeHandler === 'errorCondition' &&
        css`
          background: ${(props) => props.theme.colors.secondary};
        `}
    }
  }

  .headerNode {
    display: flex;
    flex-direction: column;
    word-break: break-all;
    font-size: 0.6rem;

    span {
      font-size: 0.66rem;
      font-weight: 600;
      text-transform: uppercase;
      color: #ffff;
    }
  }
`;
