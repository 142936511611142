import styled from 'styled-components';
import { motion } from 'framer-motion';

export const FormMenuNode = styled(motion.form)`
  width: 320px;
  height: 100%;
  padding: 1rem;
  background-color: ${(props) => props.theme.colors.containers.colorNode};
  display: grid;
  grid-template-rows: 1fr 40px;
  gap: 1rem;
`;

export const MainAddNode = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 2rem;
  padding: 1rem 0 0 0.5rem;

  h4 {
    font-size: 0.7rem;
    font-weight: 400;
    text-transform: uppercase;
    color: ${(props) => props.theme.colors.typography.baseText};
  }
  .itens_valid_input {
    width: 100%;
    max-height: 400px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 4px;

    overflow: auto;

    .contentMessageList {
      user-select: none;
      background-color: ${(props) =>
        props.theme.colors.containers.baseBackground};
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1rem;
      width: 100%;
      border-radius: 3px;

      span {
        text-transform: uppercase;
        font-size: 0.8rem;
        color: ${(props) => props.theme.colors.typography.baseText};
      }
    }
  }
`;

export const FooterAddNode = styled.footer`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
`;
