import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { TableSchedule } from './styled';
import useWindowDimensions from '@/hooks/useWidth';
import { useLayoutEffect, useRef, useState } from 'react';
import { TablePagination } from '@/components/Tables/TablePagination';
import { MdClose, MdEdit } from 'react-icons/md';
import { DialogComponent } from '@/components/DialogComponent';
import { DeleteSchedule } from '../deleteSchedule';
import { useAtom } from 'jotai';
import { dataScheduleSelected } from '@/store/AgentsStore';
import { EditSchedule } from '../editSchedule';
import { securityProfileAgents } from '@/store/NavigationAgentsDashboard';

export const TableScheduleAgent = ({ dataSchedule }) => {
  const { t } = useTranslation();
  const { height } = useWindowDimensions();
  const tableScheduleRef = useRef(null);
  const [, setDataSelected] = useAtom(dataScheduleSelected);
  const [securityAccessAgents] = useAtom(securityProfileAgents);

  const [heightTable, setHeightTable] = useState(0);

  const [isOpenEditScheduleDialog, setIsOpenEditScheduleDialog] =
    useState(false);

  const [isOpenDeleteScheduleDialog, setIsOpenDeleteScheduleDialog] =
    useState(false);

  const handleOpenEditSchedule = (dataRow) => {
    setIsOpenEditScheduleDialog(true);
    setDataSelected(dataRow);
  };

  const handleDeleteSchedule = (dataRow) => {
    setIsOpenDeleteScheduleDialog(true);
    setDataSelected(dataRow);
  };

  useLayoutEffect(() => {
    if (tableScheduleRef) {
      const tableMaxWidth = tableScheduleRef.current.offsetHeight;
      const cellMinWidth = 52;
      const cellCount = Math.floor(tableMaxWidth / cellMinWidth);
      setHeightTable(cellCount);
    }
  }, [height]);

  const ColumnSchedule = [
    {
      Header: t('agents.scheduleAgent.tableHeaderOne'),
      accessor: 'name',
    },
    {
      Header: t('agents.scheduleAgent.tableHeaderTwo'),
      accessor: 'phone',
    },
    {
      Header: t('agents.scheduleAgent.tableHeaderThree'),
      accessor: 'callTarget',
    },
    {
      Header: t('agents.scheduleAgent.tableHeaderFour'),
      accessor: 'lastContact',
    },
    {
      Header: t('agents.scheduleAgent.tableHeaderFive'),
      accessor: 'note',
    },
    {
      Header: t('agents.scheduleAgent.tableHeaderSeven'),
      Cell: ({ row }) => {
        return (
          <div className='editRow'>
            <motion.button
              disabled={!securityAccessAgents.canDelete}
              whileTap={{ scale: 0.98 }}
              onClick={() => handleDeleteSchedule(row.original)}
            >
              <MdClose />
            </motion.button>
            <motion.button
              disabled={!securityAccessAgents.canEdit}
              whileTap={{ scale: 0.98 }}
              onClick={() => handleOpenEditSchedule(row.original)}
            >
              <MdEdit />
            </motion.button>
          </div>
        );
      },
    },
  ];

  return (
    <TableSchedule ref={tableScheduleRef}>
      <TablePagination
        dataColumns={ColumnSchedule}
        dataTable={dataSchedule}
        textNoData={t('agents.scheduleAgent.noSchedules')}
        rowsPerPageDisplay={heightTable}
      />

      <DialogComponent
        open={isOpenEditScheduleDialog}
        onClose={() => setIsOpenEditScheduleDialog(false)}
        fullWidth={true}
      >
        <EditSchedule closeModal={() => setIsOpenEditScheduleDialog(false)} />
      </DialogComponent>

      <DialogComponent
        open={isOpenDeleteScheduleDialog}
        onClose={() => setIsOpenDeleteScheduleDialog(false)}
        fullWidth={true}
      >
        <DeleteSchedule
          closeModal={() => setIsOpenDeleteScheduleDialog(false)}
        />
      </DialogComponent>
    </TableSchedule>
  );
};
