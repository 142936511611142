import { useAtom } from 'jotai';
import { useEffect } from 'react';
import { useStopwatch, useTimer } from 'react-timer-hook';
import { durationCallState } from '@store/AgentsStore';
import { ContainerStopWatch } from './styled';

export const StopWatchComponent = ({
  statusQueue,
  noBg = false,
  durationStage,
}) => {
  const [, setDurationCall] = useAtom(durationCallState);

  const stopwatchOffset = new Date();
  stopwatchOffset.setSeconds(stopwatchOffset.getSeconds() + durationStage);

  const { seconds, minutes, hours, reset } = useStopwatch({
    autoStart: true,
    offsetTimestamp: stopwatchOffset,
  });

  useEffect(() => {
    durationStage === 0 && reset();
  }, [durationStage]);

  const secondsRender = seconds < 10 ? `0${seconds}` : seconds;
  const minutesRender = minutes < 10 ? `0${minutes}` : minutes;
  const hoursRender = hours < 10 ? `0${hours}` : hours;

  const timeRender = `${hoursRender}:${minutesRender}:${secondsRender}`;

  useEffect(() => {
    return () => setDurationCall(timeRender);
  }, []);

  if (noBg) {
    return <span>{timeRender}</span>;
  } else {
    return (
      <ContainerStopWatch status={statusQueue}>
        <span>{timeRender}</span>
      </ContainerStopWatch>
    );
  }
};

export const CountDownTimer = ({ expiryTimestamp, setCurrentStopWatch }) => {
  const { seconds, minutes, hours } = useTimer({
    expiryTimestamp,
  });

  const secondsRender = seconds < 10 ? `0${seconds}` : seconds;
  const minutesRender = minutes <= 9 ? `0${minutes}` : minutes;
  const hoursRender = hours < 10 ? `0${hours}` : hours;

  const timeRender = `${hoursRender}:${minutesRender}:${secondsRender}`;

  useEffect(() => {
    setCurrentStopWatch({
      secondsRender,
    });
  }, [timeRender]);

  return (
    <div style={{ textAlign: 'center' }}>
      <div>
        <span>{timeRender}</span>
      </div>
    </div>
  );
};
