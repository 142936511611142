/* eslint-disable no-prototype-builtins */
import AceEditor from 'react-ace';
import { motion } from 'framer-motion';
import {
  ContainerResponseDataApi,
  FooterResponse,
  HeaderResponse,
  MainResponse,
} from './styled';

import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-noconflict/ext-language_tools';

import { ButtonStyled } from '@/components/FormComponents/ButtonComponent';
import { useEffect, useState } from 'react';
import { AiFillApi } from 'react-icons/ai';
import { Stack } from '@mui/system';
import { InputComponent } from '@/components/FormComponents/InputComponent';
import { IoMdCheckmarkCircleOutline } from 'react-icons/io';
import { IoCloseCircleOutline } from 'react-icons/io5';
import { FaCircleArrowRight } from 'react-icons/fa6';
import { TableVarsData } from './TableVarsData';
import { SelectComponent } from '@/components/FormComponents/SelectInput';
import { MenuItem } from '@mui/material';
import { useAtom } from 'jotai';
import { selectedNode, variablesList } from '@/store/FlowBuilder';
import { MdModeEdit } from 'react-icons/md';
import { useTranslation } from 'react-i18next';

export const ResponseDataApiFlow = ({
  dataResponse,
  onClose,
  nodes,
  setNodes,
}) => {
  const { t } = useTranslation();
  const [valueFilterResponseData, setValueFilterResponseData] = useState('');
  const [nameVarApiFiltered, setNameVarApiFiltered] = useState('');
  const [errorNameVarApiFiltered, setErrorNameVarApiFiltered] = useState('');
  const [selectedHandler] = useAtom(selectedNode);
  const [selectedVar, setSelectedVar] = useState(null);

  const [variablesFlow, setVariablesFlow] = useAtom(variablesList);
  const [dataFilterResponse, setDataFilterResponse] = useState();

  const resetFieldsFormData = () => {
    setNameVarApiFiltered('');
    setValueFilterResponseData('');
    setDataFilterResponse({});
    setSelectedVar(null);
  };

  useEffect(() => {
    return () => resetFieldsFormData();
  }, []);

  const validateNameVarApi = (value) => {
    if (!value.trim()) {
      setErrorNameVarApiFiltered(
        t('flowBuilder.flow.nodes.dataApi.menu.msg-name-required')
      );
    } else {
      setErrorNameVarApiFiltered('');
    }
    setNameVarApiFiltered(value);
  };

  const getValueFromPath = (obj, path) => {
    let current = obj;
    for (let i = 0; i < path.length; i++) {
      if (path[i].includes('[') && path[i].includes(']')) {
        const [key, index] = path[i].replace(']', '').split('[');
        if (
          current[key] &&
          Array.isArray(current[key]) &&
          current[key].length > Number(index)
        ) {
          current = current[key][Number(index)];
        } else {
          return null;
        }
      } else {
        current = current[path[i]];
      }
      if (current === undefined) {
        return null;
      }
    }
    return current;
  };

  useEffect(() => {
    if (valueFilterResponseData.includes('$')) {
      let varsFiltered = valueFilterResponseData
        .split('.')
        .map((v) => v.replace('$', ''));

      let arrayIndex = null;
      const arrayMatch =
        varsFiltered[varsFiltered.length - 1].match(/\[(\d+)\]$/);
      if (arrayMatch) {
        arrayIndex = parseInt(arrayMatch[1], 10);
        varsFiltered[varsFiltered.length - 1] = varsFiltered[
          varsFiltered.length - 1
        ].replace(/\[\d+\]$/, '');
      }
      if (varsFiltered.length === 1 && varsFiltered[0] === '') {
        setDataFilterResponse(null);
        return;
      }

      const result = getValueFromPath(dataResponse.data, varsFiltered);

      setDataFilterResponse(result);
    }
  }, [valueFilterResponseData]);

  const handleAddPathVar = (id) => {
    const updatedVariables = variablesFlow.map((item) => {
      if (item.name === nameVarApiFiltered) {
        if (!item.referNode) {
          return {
            ...item,
            referNode: id,
            value: valueFilterResponseData,
          };
        }
      }

      return item;
    });

    const filteredNode = nodes.map((node) => {
      if (node.data.handler === 'setVariables') {
        return {
          ...node,
          data: {
            ...node.data,
            listVariables: updatedVariables,
          },
        };
      }
      return node;
    });
    setNodes(filteredNode);
    setNameVarApiFiltered('0');
    setVariablesFlow(updatedVariables);
    setValueFilterResponseData('');
    setDataFilterResponse();
  };

  const handleRemoveVarList = (dataRowId) => {
    const removeItemList = variablesFlow.map((item) => {
      if (item.id === dataRowId) {
        return {
          ...item,
          value: 'null',
          referNode: false,
        };
      }
      return item;
    });
    setVariablesFlow(removeItemList);

    const filteredNode = nodes.map((node) => {
      if (node.data.handler === 'setVariables') {
        return {
          ...node,
          data: {
            ...node.data,
            listVariables: removeItemList,
          },
        };
      }
      return node;
    });
    setNodes(filteredNode);
  };

  const filterVarsByNode = variablesFlow.filter(
    (item) => item.referNode === selectedHandler.id
  );

  return (
    <ContainerResponseDataApi>
      <HeaderResponse>
        <Stack direction={'row'} justifyContent={'space-between'}>
          <div className='title-header'>
            <AiFillApi size={17} />
            <h4>
              {t('flowBuilder.flow.nodes.dataApi.menu.title-response-api')}
            </h4>
          </div>

          {dataResponse.statusCode || dataResponse.status === 'Error' ? (
            <span className='errorResponse'>
              status:{' '}
              <strong>
                {dataResponse.status} -{' '}
                {dataResponse.statusCode
                  ? dataResponse.statusCode
                  : dataResponse.data}
              </strong>
            </span>
          ) : (
            <span className=''>
              status: <strong>{dataResponse.status}</strong>
            </span>
          )}
        </Stack>
      </HeaderResponse>

      <MainResponse>
        <div className='itemResponse'>
          <h4>
            {t('flowBuilder.flow.nodes.dataApi.menu.title-response-data')}
          </h4>
          <div className='body-response'>
            <AceEditor
              mode='json'
              theme='github'
              width='100%'
              height={
                dataResponse.statusCode || dataResponse.status === 'Error'
                  ? '100px'
                  : '240px'
              }
              readOnly={true}
              setOptions={{ useWorker: false, showPrintMargin: false }}
              name='body_request_editor'
              editorProps={{ $blockScrolling: true }}
              value={JSON.stringify(dataResponse.data, null, 2)}
              showGutter={true}
            />
          </div>
        </div>

        {dataResponse.statusCode || dataResponse.status === 'Error' ? (
          ''
        ) : (
          <>
            <div className='itemResponse'>
              <h4>
                {t('flowBuilder.flow.nodes.dataApi.menu.title-response-filter')}
              </h4>
              <Stack direction={'row'} spacing={1}>
                <InputComponent
                  size={'small'}
                  value={valueFilterResponseData}
                  onChange={(e) => setValueFilterResponseData(e.target.value)}
                  placeholder={t(
                    'flowBuilder.flow.nodes.dataApi.menu.label-response-filter'
                  )}
                  InputProps={{
                    endAdornment:
                      typeof dataFilterResponse === 'string' ||
                      typeof dataFilterResponse === 'boolean' ||
                      typeof dataFilterResponse === 'number' ? (
                        <IoMdCheckmarkCircleOutline color='green' size={20} />
                      ) : (
                        <IoCloseCircleOutline color='red' size={20} />
                      ),
                  }}
                />
                <SelectComponent
                  defaultValue='0'
                  labelSelect={t(
                    'flowBuilder.flow.nodes.dataApi.menu.label-name-var'
                  )}
                  size={'small'}
                  value={nameVarApiFiltered}
                  onChange={(e) => validateNameVarApi(e.target.value)}
                  disabled={
                    !(
                      (typeof dataFilterResponse === 'string' ||
                        typeof dataFilterResponse === 'boolean' ||
                        typeof dataFilterResponse === 'number') &&
                      !errorNameVarApiFiltered
                    )
                  }
                  error={Boolean(errorNameVarApiFiltered)}
                  helperText={errorNameVarApiFiltered}
                >
                  <MenuItem value={'0'} dense disabled>
                    {t('flowBuilder.flow.nodes.dataApi.menu.option-variable')}
                  </MenuItem>
                  {variablesFlow.length > 0 &&
                    variablesFlow
                      .filter(
                        (item) => !item.referNode && item.value === 'null'
                      )
                      .map((item) => {
                        return (
                          <MenuItem key={item.id} value={item.name} dense>
                            {item.name}
                          </MenuItem>
                        );
                      })}
                </SelectComponent>

                {!selectedVar ? (
                  <motion.button
                    whileTap={{ scale: 0.98 }}
                    onClick={() => handleAddPathVar(selectedHandler.id)}
                    title={t(
                      'flowBuilder.flow.nodes.dataApi.menu.title-btn-add-var'
                    )}
                    disabled={
                      !(
                        (typeof dataFilterResponse === 'string' ||
                          typeof dataFilterResponse === 'boolean' ||
                          typeof dataFilterResponse === 'number') &&
                        !errorNameVarApiFiltered
                      )
                    }
                  >
                    <FaCircleArrowRight />
                  </motion.button>
                ) : (
                  <motion.button
                    whileTap={{ scale: 0.98 }}
                    title={t(
                      'flowBuilder.flow.nodes.dataApi.menu.title-btn-edit-var'
                    )}
                    disabled={
                      !(
                        (typeof dataFilterResponse === 'string' ||
                          typeof dataFilterResponse === 'number') &&
                        !errorNameVarApiFiltered
                      )
                    }
                  >
                    <MdModeEdit />
                  </motion.button>
                )}
              </Stack>
              <div className='body-response'>
                <AceEditor
                  mode='json'
                  theme='github'
                  width='100%'
                  height='160px'
                  readOnly={true}
                  setOptions={{
                    useWorker: false,
                    enableBasicAutocompletion: false,
                    enableLiveAutocompletion: false,
                    enableSnippets: false,
                    showLineNumbers: false,
                    tabSize: 2,
                    showPrintMargin: false,
                  }}
                  name='body_request_editor'
                  editorProps={{ $blockScrolling: true }}
                  value={JSON.stringify(dataFilterResponse, null, 2)}
                />
              </div>
            </div>
            <Stack direction={'column'} spacing={1}>
              <h4>
                {t('flowBuilder.flow.nodes.dataApi.menu.title-api-variables')}
              </h4>

              <div className='render-vars-api'>
                <TableVarsData
                  dataTable={filterVarsByNode}
                  handleRemoveVarList={handleRemoveVarList}
                />
              </div>
            </Stack>
          </>
        )}
      </MainResponse>

      <FooterResponse>
        <ButtonStyled
          variantstyle={'secondary'}
          size={'small'}
          onClick={onClose}
        >
          {t('flowBuilder.flow.nodes.dataApi.menu.btn-cancel')}
        </ButtonStyled>
        <ButtonStyled size={'small'} onClick={onClose}>
          oK
        </ButtonStyled>
      </FooterResponse>
    </ContainerResponseDataApi>
  );
};
