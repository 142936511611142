import { Stack } from '@mui/material';
import { useAtom } from 'jotai';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { openDialogInfoStatus } from '@/store/DialingStore';
import { ButtonStyled } from '@components/FormComponents/ButtonComponent';
import {
  ContainerDetailListQueue,
  ContainerStateDetailQueue,
  FooterDetailQueue,
  NavigationDetailQueue,
} from './styled';
import { TableDetailPosition } from './TableDetailPosition';
import { TableTotalPhoneCode } from './TableTotalPhoneCode';

export const DetailQueue = ({ selectedList }) => {
  const { t } = useTranslation();
  const [, setOpenDialog] = useAtom(openDialogInfoStatus);
  const [flowStateDetailQueue, setFlowStateDetailQueue] = useState(0);

  const detailTableToRende = [
    <TableDetailPosition
      key={0}
      dataTable={selectedList.posicao_telefone ?? []}
    />,
    <TableTotalPhoneCode key={1} dataTable={selectedList.qtd_cod_lig ?? []} />,
  ];

  return (
    <ContainerDetailListQueue>
      <h2>
        {t('dialing.detailLineQueue.titleDetail')}{' '}
        {selectedList && selectedList.id_tabela_lista}
      </h2>
      <NavigationDetailQueue>
        <ButtonStyled
          size='small'
          onClick={() => setFlowStateDetailQueue(0)}
          className={
            flowStateDetailQueue === 0 || flowStateDetailQueue === 2
              ? ''
              : 'inativeButton'
          }
        >
          <Stack
            direction={'row'}
            spacing={1}
            alignItems='center'
            justifyContent='center'
          >
            <span>{t('dialing.detailLineQueue.nabBtnPositions')}</span>
          </Stack>
        </ButtonStyled>
        <ButtonStyled
          size='small'
          className={flowStateDetailQueue !== 1 ? 'inativeButton' : ''}
          onClick={() => setFlowStateDetailQueue(1)}
        >
          <Stack
            direction={'row'}
            spacing={1}
            alignItems='center'
            justifyContent='center'
          >
            <span>{t('dialing.detailLineQueue.navBtnCalls')}</span>
          </Stack>
        </ButtonStyled>
      </NavigationDetailQueue>
      <ContainerStateDetailQueue>
        {detailTableToRende[flowStateDetailQueue]}
      </ContainerStateDetailQueue>
      <FooterDetailQueue>
        <ButtonStyled
          size='small'
          variantstyle='secondary'
          onClick={() => setOpenDialog(false)}
        >
          {t('dialing.newListConfig.btn-comeBack')}
        </ButtonStyled>
      </FooterDetailQueue>
    </ContainerDetailListQueue>
  );
};
