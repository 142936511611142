import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useControlTimeAgent } from '@hooks/Agents/useControlTimeAgent';
import { ButtonStyled } from '@components/FormComponents/ButtonComponent';
import { StopWatchComponent } from '@components/StopWatchComponent';
import { Stack } from '@mui/material';
import { MdOutlinePauseCircleFilled } from 'react-icons/md';
import { HiPhoneOutgoing } from 'react-icons/hi';

export const FreeStatus = ({
  handleOpenAudio,
  isLoading,
  dataCall,
  setPauseDialog,
}) => {
  const { t } = useTranslation();
  const { manegeStopWatch, removeLocalStatus } = useControlTimeAgent();

  useEffect(() => {
    removeLocalStatus('freeStatus');
  }, []);

  return (
    <div className='dial_part'>
      <Stack direction={`row`} spacing={2}>
        <ButtonStyled onClick={() => setPauseDialog(true)} disabled={isLoading}>
          <MdOutlinePauseCircleFilled />
          <span>{t('agents.panelAgents.btnStartPause')}</span>
        </ButtonStyled>
        <ButtonStyled onClick={handleOpenAudio} disabled={isLoading}>
          <HiPhoneOutgoing />
          <span>{t('agents.panelAgents.btnGetInDialer')}</span>
        </ButtonStyled>
        <StopWatchComponent
          statusQueue={dataCall && dataCall.estado}
          durationStage={manegeStopWatch('@freeStatus')}
        />
      </Stack>
    </div>
  );
};
