import styled from 'styled-components';

export const ContainerConfirmation = styled.div`
  width: 100%;
  height: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1.4rem;

  p {
    color: ${(props) => props.theme.colors.typography.baseText};
    font-weight: 400;
  }
`;

export const PanelConfirmation = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  width: 100%;
`;
