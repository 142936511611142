import { useEffect, useState } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { Column } from './Column';
import { ControlList } from './ControlList';
import { StyledColumns } from './styledItems';
import { errorToast } from '@/components/FormComponents/Toast';
import { useTranslation } from 'react-i18next';

window['__react-beautiful-dnd-disable-dev-warnings'] = true;

export const DragTransferList = ({
  codeList,
  changeDataCodeList,
  dataSelected = [],
  checkRemoveData = [],
  typeList = '',
}) => {
  const { t } = useTranslation();
  const initialColumns = {
    available: {
      id: 'available',
      list: codeList,
    },
    selected: {
      id: 'selected',
      list: dataSelected,
    },
  };

  const [columns, setColumns] = useState(initialColumns);

  useEffect(() => {
    changeDataCodeList(columns.selected.list);
  }, [columns]);

  const renderMessageError = () => {
    switch (typeList) {
      case 'Global':
        return errorToast(t('flowBuilder.configs.msg-error-used-globals'));
      case 'Endpoints':
        return errorToast(t('flowBuilder.configs.msg-error-used-endpoints'));
      case 'Dispositions':
        return errorToast(t('flowBuilder.configs.msg-error-used-dispositions'));
      default:
        return errorToast(t('flowBuilder.configs.msg-error-default'));
    }
  };

  const onDragEnd = ({ source, destination }) => {
    if (destination === undefined || destination === null) return null;
    if (
      source.droppableId === destination.droppableId &&
      destination.index === source.index
    )
      return null;
    const start = columns[source.droppableId];
    const end = columns[destination.droppableId];
    if (end.id === 'available') {
      const itemSelectedLabel = start.list
        .filter((_, idx) => idx === source.index)
        .map((item) => item.name)
        .toString();

      const checkUsedItem = checkRemoveData.includes(itemSelectedLabel);
      if (checkUsedItem) {
        return renderMessageError();
      }
    }

    if (start === end) {
      const newList = start.list.filter((_, idx) => idx !== source.index);
      newList.splice(destination.index, 0, start.list[source.index]);
      const newCol = {
        id: start.id,
        list: newList,
      };
      setColumns((state) => ({ ...state, [newCol.id]: newCol }));
      return null;
    } else {
      const newStartList = start.list.filter((_, idx) => idx !== source.index);
      const newStartCol = {
        id: start.id,
        list: newStartList,
      };
      const newEndList = end.list;
      newEndList.splice(destination.index, 0, start.list[source.index]);
      const newEndCol = {
        id: end.id,
        list: newEndList,
      };
      setColumns((state) => ({
        ...state,
        [newStartCol.id]: newStartCol,
        [newEndCol.id]: newEndCol,
      }));
      return null;
    }
  };

  const handleToSelectedList = (itemSelected, colSelected) => {
    const { list: listAvailable } = columns.available;
    const { list: listSelected } = columns.selected;

    if (colSelected === 'available') {
      const hasItemAvailable = listAvailable.filter(
        (item) => item !== itemSelected
      );

      const newListSelected = [...listSelected, itemSelected];

      return setColumns((prevState) => ({
        ...prevState,
        available: {
          ...prevState.available,
          list: hasItemAvailable,
        },
        selected: {
          ...prevState.selected,
          list: newListSelected,
        },
      }));
    }

    const checkUsedItem = checkRemoveData.includes(itemSelected.name);

    if (checkUsedItem) {
      return renderMessageError();
    }

    const hasItemSelected = listSelected.filter(
      (item) => item !== itemSelected
    );

    const newListAvailable = [...listAvailable, itemSelected];

    return setColumns((prevState) => ({
      ...prevState,
      available: {
        ...prevState.available,
        list: newListAvailable,
      },
      selected: {
        ...prevState.selected,
        list: hasItemSelected,
      },
    }));
  };

  const handleSelectAllValues = () => {
    const { list: listAvailable } = columns.available;
    const { list: listSelected } = columns.selected;

    return setColumns((prevState) => ({
      ...prevState,
      available: {
        ...prevState.available,
        list: [],
      },
      selected: {
        ...prevState.selected,
        list: [...listSelected, ...listAvailable],
      },
    }));
  };

  const handleUnselectAllValues = () => {
    const { list: listAvailable } = columns.available;
    const { list: listSelected } = columns.selected;

    if (checkRemoveData.length > 0) {
      return renderMessageError();
    }

    return setColumns((prevState) => ({
      ...prevState,
      available: {
        ...prevState.available,
        list: [...listSelected, ...listAvailable],
      },
      selected: {
        ...prevState.selected,
        list: [],
      },
    }));
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <StyledColumns>
        <ControlList
          listAvailable={columns.available.list}
          listSelected={columns.selected.list}
          controls={{ handleSelectAllValues, handleUnselectAllValues }}
        />
        {Object.values(columns).map((col) => (
          <Column
            col={col}
            key={col.id}
            handleToSelectedList={handleToSelectedList}
          />
        ))}
      </StyledColumns>
    </DragDropContext>
  );
};
