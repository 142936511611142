import { useState } from 'react';
import { DragTransferList } from '../DragTransferList';
import { useAtom } from 'jotai';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';
import { dataDispositions, templateSelected } from '@/store/FlowBuilder';
import { Stack } from '@mui/system';
import { FaGear } from 'react-icons/fa6';
import { ButtonStyled } from '@/components/FormComponents/ButtonComponent';
import { ContentConfig, FooterConfig } from '../../styled';
import { successToast } from '@/components/FormComponents/Toast';
import { useTranslation } from 'react-i18next';
import { Skeleton } from '@mui/material';
import { useDispositions } from '@/hooks/FlowBuilder/useDispositions';

export const ListDispositions = ({
  listDispositions,
  handleClose,
  changeFlowConfigState,
}) => {
  const { t } = useTranslation();
  const { loadingDispositions, isFetchingDispositions } = useDispositions();
  const [dispositions] = useAtom(dataDispositions);
  const [template, setTemplate] = useAtom(templateSelected);

  const [dataDispositionsSelected, setDataDispositionsSelected] = useState([]);

  const renderAvailableList = () => {
    const availableList = dispositions
      .map((element) => {
        const filterUsedDispositions = listDispositions.filter(
          (item) => item.name === element.name
        );
        if (filterUsedDispositions.length > 0) {
          return null;
        }
        return element;
      })
      .filter((item) => Boolean(item));

    return availableList;
  };

  const hasUsedDisposition = () => {
    const { nodes } = template;

    const listNodes = nodes ? nodes : [];

    const mapListDisposition = listDispositions
      .map((itemSelected) => {
        const dispositionsUsed = listNodes.filter(
          (item) => item.data.label === itemSelected.name
        );

        if (dispositionsUsed.length > 0) {
          return dispositionsUsed[0];
        }
        return false;
      })
      .filter((item) => item)
      .map((item) => item.data.label);

    return mapListDisposition;
  };

  const handleUpdateDispositions = () => {
    setTemplate((previousState) => {
      return {
        ...previousState,
        codeLigs: dataDispositionsSelected,
      };
    });
    successToast(
      t('flowBuilder.configs.dispositions.msg-updated-addedDispositions')
    );
    handleClose();
    return;
  };

  const isLoading = isFetchingDispositions || loadingDispositions;

  return (
    <ContentConfig>
      {isLoading && <BackDropCircularLoading />}
      <Stack
        direction={'row'}
        spacing={1}
        alignItems={'center'}
        className={'titleConfig'}
      >
        <FaGear />
        <h4>{t('flowBuilder.configs.dispositions.title-dispositions')}</h4>
      </Stack>
      {dispositions.length > 0 ? (
        <DragTransferList
          codeList={renderAvailableList()}
          changeDataCodeList={setDataDispositionsSelected}
          dataSelected={listDispositions}
          checkRemoveData={hasUsedDisposition()}
          typeList={'Dispositions'}
        />
      ) : (
        <Skeleton
          variant='rectangular'
          width={'100%'}
          height={'100%'}
          sx={{ opacity: 0.4 }}
        />
      )}
      <FooterConfig>
        <ButtonStyled size='small' onClick={() => changeFlowConfigState(1)}>
          {t('flowBuilder.configs.dispositions.btn-add-dispositions')}
        </ButtonStyled>
        <Stack direction={'row'} spacing={1}>
          <ButtonStyled
            size='small'
            variantstyle='secondary'
            onClick={handleClose}
          >
            {t('flowBuilder.configs.btn-cancel')}
          </ButtonStyled>
          <ButtonStyled size='small' onClick={handleUpdateDispositions}>
            {t('flowBuilder.configs.btn-update')}
          </ButtonStyled>
        </Stack>
      </FooterConfig>
    </ContentConfig>
  );
};
