import styled from 'styled-components';

export const TableSchedule = styled.div`
  width: 100%;
  height: 100%;
  user-select: none;

  table {
    tbody {
      tr {
        height: 40px;
        max-width: 200px;
        td {
          z-index: 1;
          padding: 0 0.8rem;
          &:last-child {
            width: 80px;
          }
        }
      }
    }
  }
`;
