import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MenuItem } from '@mui/material';
import { SelectComponent } from '@components/FormComponents/SelectInput';
import { CardStatus } from './CardStatus';

import {
  ContainerStatusDiscing,
  ContentPanelDiscing,
  ContentStatusQueueDialing,
} from './styled';
import { ButtonStyled } from '@/components/FormComponents/ButtonComponent';
import { HiSortAscending, HiSortDescending } from 'react-icons/hi';
import { NoDataStatus } from './NoDataStatus';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';
import { useDialingQueues } from '@/hooks/Dialing/useDialingQueues';

export const StatusQueues = ({
  dataQueues,
  changeFlowDialing,
  isLoadingData,
}) => {
  const { t } = useTranslation();
  const { mutateDataInfoQueue, isLoadingDataQueue } = useDialingQueues();

  const [filterQueue, setFilterQueue] = useState('');
  const [sortByStatus, setSortByStatus] = useState('');
  const [sortByQueue, setSortByQueue] = useState(false);

  useEffect(() => {
    setSortByStatus('0');
  }, [filterQueue]);

  const dataOptionsStatus = [
    {
      value: '0',
      name: t('dialing.option-allStatus'),
    },
    {
      value: '1',
      name: t('dialing.option-discing'),
    },
    {
      value: '2',
      name: t('dialing.option-empty'),
    },
    {
      value: '3',
      name: t('dialing.option-closed'),
    },
  ];

  const handleSortByQueue = () => setSortByQueue(!sortByQueue);

  const filterByQueue =
    dataQueues && dataQueues.filter((q) => q.fila === filterQueue);

  const renderQueues =
    dataQueues && filterByQueue.length === 0 ? dataQueues : filterByQueue;

  const filterByStatus = () => {
    switch (sortByStatus) {
      case 1:
        return renderQueues.filter((q) => q.estado === 'DISCANDO');
      case 2:
        return renderQueues.filter(
          (q) => q.estado === 'VAZIO' || q.estado === 'MANUTENCAO'
        );
      case 3:
        return renderQueues.filter(
          (q) => q.estado === 'FECHADO' || q.estado === 'PARADO'
        );
      default:
        return renderQueues;
    }
  };

  let orderQueue = !sortByQueue
    ? (a, b) => a.fila - b.fila
    : (a, b) => b.fila - a.fila;

  const renderQueuesSort = dataQueues && filterByStatus().sort(orderQueue);

  const isLoading = isLoadingData || isLoadingDataQueue;

  return (
    <ContainerStatusDiscing initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      {isLoading && <BackDropCircularLoading />}
      <ContentPanelDiscing>
        {dataQueues && (
          <>
            <div className='filterQueueContent'>
              <SelectComponent
                labelSelect={t('dialing.label-selectFilter')}
                value={filterQueue}
                onChange={(event) => setFilterQueue(event.target.value)}
              >
                <MenuItem value={0} dense>
                  {t('dialing.option-all')}
                </MenuItem>
                {dataQueues.map((queue, i) => (
                  <MenuItem value={queue.fila} key={i} dense>
                    {queue.fila} - {queue.campanha}
                  </MenuItem>
                ))}
              </SelectComponent>
            </div>
            <div className='sortStatusQueue'>
              <SelectComponent
                labelSelect={t('dialing.label-statusQueue')}
                value={sortByStatus}
                onChange={(event) => setSortByStatus(event.target.value)}
              >
                {dataOptionsStatus.map((item) => (
                  <MenuItem key={item.value} value={+item.value} dense>
                    {item.name}
                  </MenuItem>
                ))}
              </SelectComponent>
            </div>
            <ButtonStyled
              onClick={handleSortByQueue}
              title='Sort By Queue'
              size={'small'}
            >
              {sortByQueue ? (
                <HiSortDescending size={20} />
              ) : (
                <HiSortAscending size={20} />
              )}
            </ButtonStyled>
          </>
        )}
      </ContentPanelDiscing>
      <ContentStatusQueueDialing
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
      >
        {dataQueues && renderQueuesSort.length > 0
          ? renderQueuesSort.map((queue, i) => (
              <CardStatus
                key={i}
                dataQueue={queue}
                changeFlowDialing={changeFlowDialing}
                mutateDataInfoQueue={mutateDataInfoQueue}
              />
            ))
          : dataQueues && <NoDataStatus />}
      </ContentStatusQueueDialing>
    </ContainerStatusDiscing>
  );
};
