import { useEffect, useRef, useState } from 'react';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import {
  currentUserAgent,
  dataCallInformation,
  exitDialler,
  isAllowedMicrophone,
  isLoadingComponent,
  isRegisteredSocket,
  newMessagesFromSip,
  playerAudioCall,
  sessionStateAgent,
} from '@store/AgentsStore';
import { user } from '@store/AuthenticatorStore';
import { useAgents } from '@hooks/Agents/useAgents';
import { BackDropCircularLoading } from '@components/layout/LoadingComponent';
import { TitleComponent } from '@components/layout/TitleComponent';
import { PanelControlAgents } from '../../PanelControlAgents';
import { ContentAgents } from '../../ContentAgents';
import {
  AgentsPanelControl,
  ContainerAgents,
  ContainerMainAgents,
} from './styled';
import { NotAllowedMicrophone } from '../../ContentAgents/NotAllowedMicrophone';

import AlertInitCall from '@/assets/sounds/ring.mp3';
import { AgentDisconnected } from '../../ContentAgents/AgentDisconnected';

export const MainAgent = () => {
  const [currentUser] = useAtom(user);

  const { t } = useTranslation();
  const audioRef = useRef(null);
  const audioVerifyPermissionRef = useRef(null);

  const [isLoadingAgent] = useAtom(isLoadingComponent);

  const { mutateAuthAgent, mutateGetAgents } = useAgents();

  const [isRegistered] = useAtom(isRegisteredSocket);
  const [userAgent] = useAtom(currentUserAgent);
  const [isExited] = useAtom(exitDialler);
  const [dataCall] = useAtom(dataCallInformation);
  const [playerAudio, setPlayerAudio] = useAtom(playerAudioCall);
  const [sessionState, setSessionState] = useAtom(sessionStateAgent);
  const [isAllowed, setIsAllowed] = useAtom(isAllowedMicrophone);
  const [newMessages, setNewMessages] = useAtom(newMessagesFromSip);

  const [ringSound, setRingSound] = useState(AlertInitCall);

  const currentUserData = {
    extension: currentUser && currentUser.extension,
    queue: currentUser && currentUser.queue,
  };

  //   const verifyPermissionNavigator = navigator.mediaDevices
  //     .getUserMedia({ audio: true })
  //     .then(() => setIsAllowed(false))
  //     .catch(() => setIsAllowed(true));

  //   // check audio permission
  //   useEffect(() => {
  //     verifyPermissionNavigator;
  //   }, [isAllowed, verifyPermissionNavigator]);

  useEffect(() => {
    setRingSound(new window.Audio(AlertInitCall));
  }, []);

  useEffect(() => {
    setPlayerAudio(audioRef.current);
  }, [audioRef.current, playerAudio]);

  const sendSound = () => {
    ringSound.play();
  };

  const eventHandlers = {
    progress: function (e) {
      console.log('call is in progress', e);
    },
    failed: function (e) {
      console.log('call failed with cause: ' + e.cause);
    },
    ended: function (e) {
      console.log('call ended with cause: ' + e.cause);
    },
    confirmed: function (e) {
      console.log('call confirmed', e);
    },
  };

  const callOptions = {
    eventHandlers: eventHandlers,
    mediaConstraints: {
      audio: true,
      video: false,
    },
  };

  // when new message fetch new status
  useEffect(() => {
    if (newMessages) {
      const separateSignalString = newMessages.split('|');

      console.log({ newMessages, msgReceive: separateSignalString });

      if (separateSignalString[0] === 'ScreenPop') {
        sendSound();
      }
    }
  }, [newMessages]);

  // check socket register and agent auth
  useEffect(() => {
    if (isRegistered) {
      mutateGetAgents(
        {
          extension: currentUserData.extension,
          queue: currentUserData.queue,
        },
        {
          onSuccess: (data) => {
            if (data.estado === 'LOGOUT') {
              mutateAuthAgent({
                extension: currentUser.extension,
                agentLogin: currentUser.name,
              });
            }
          },
        }
      );
    }
  }, [isRegistered, userAgent]);

  // create session
  useEffect(() => {
    userAgent &&
      userAgent.on('newRTCSession', async (ev) => {
        var newSession = ev.session;
        if (sessionState) {
          sessionState.terminate();
        }
        let session = newSession;
        setSessionState(newSession);
        if (session.direction === 'outgoing') {
          console.log('recebendo chamada');
          session.connection.addEventListener('addstream', (event) => {
            playerAudio.srcObject = event.stream;
            playerAudio.play();
          });
        }
        if (session.direction === 'incoming') {
          console.log('chamada primaria');
          sendSound();

          mutateGetAgents({
            extension: currentUserData.extension,
            queue: currentUserData.queue,
          });

          //   session.answer(callOptions);

          session.connection.addEventListener('addstream', (event) => {
            playerAudio.srcObject = event.stream;
            playerAudio.play();
          });
        }

        userAgent &&
          userAgent.on('newMessage', (e) => {
            setNewMessages(e.request.body);
          });
      });
  }, []);

  if (!isAllowed) {
    return (
      <ContainerAgents>
        {isLoadingAgent && <BackDropCircularLoading />}
        <ContainerMainAgents>
          <AgentsPanelControl
            status={dataCall ? dataCall.estado : 'DISCONNECTED'}
          >
            <TitleComponent
              textTitle={t('agents.dashboard.titleMain')}
              iconTitle='agents'
            />

            {isExited ? <AgentDisconnected /> : <PanelControlAgents />}
          </AgentsPanelControl>
          {isExited ? ' ' : <ContentAgents />}
        </ContainerMainAgents>
        <div className='players-box'>
          <audio ref={audioRef} autoPlay={false} />
          <audio ref={audioVerifyPermissionRef} autoPlay={false} muted />
        </div>
      </ContainerAgents>
    );
  } else {
    return <NotAllowedMicrophone />;
  }
};
