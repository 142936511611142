import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { TitleComponent } from '@/components/layout/TitleComponent';
import { ContentChangeClienteControl } from './styled';
import { ButtonStyled } from '@/components/FormComponents/ButtonComponent';
import { ControlSelect } from '@/components/FormComponents/SelectInput';
import { MenuItem } from '@mui/material';
import { useAtom } from 'jotai';
import { dataListClients } from '@/store/Clients';
import { user } from '@/store/AuthenticatorStore';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';
import { DescriptionInformation } from '@/components/layout/DescriptionInformation';
import { useTranslation } from 'react-i18next';
import { useControlClient } from '@/hooks/UserAgents/useControlClient';
import {
  dataDashboardCharts,
  selectedQueueToSearchCharts,
} from '@/store/DashboardPage';

export const ChangeClienteControl = ({ handleClose }) => {
  const { t } = useTranslation();

  const [listClients] = useAtom(dataListClients);
  const [currentUser] = useAtom(user);

  const [, setQueueSelected] = useAtom(selectedQueueToSearchCharts);
  const [, setDashData] = useAtom(dataDashboardCharts);

  const { mutateUpdateClientUser, loadingUpdateClientUser } =
    useControlClient();

  const changeClientSchema = yup.object().shape({
    client: yup.string(),
  });

  const { handleSubmit, control, reset } = useForm({
    resolver: yupResolver(changeClientSchema),
  });

  const handleSubmitChange = (data) => {
    setQueueSelected('0');
    setDashData((previous) => ({
      ...previous,
      queuesDashboard: [],
    }));
    const { user_id } = currentUser;

    const dataUpdate = {
      userId: user_id,
      client_id: data.client,
    };

    return mutateUpdateClientUser(dataUpdate, {
      onSuccess: () => {
        reset();
      },
    });
  };

  return (
    <ContentChangeClienteControl>
      {loadingUpdateClientUser && <BackDropCircularLoading />}
      <div className='header'>
        <TitleComponent
          iconTitle='client'
          textTitle={t('controlClient.title')}
        />
      </div>
      <div className='main'>
        <form onSubmit={handleSubmit(handleSubmitChange)}>
          <DescriptionInformation
            textDescription={t('controlClient.description')}
          />

          <ControlSelect
            control={control}
            nameControl={'client'}
            label={t('controlClient.label-client')}
            defaultValue={currentUser.client_id}
          >
            <MenuItem value={'0'} disabled dense>
              {t('controlClient.options-client')}
            </MenuItem>
            {listClients.length > 0 &&
              listClients.map((item, i) => (
                <MenuItem value={item.id} key={i} dense>
                  {item.commercial_name}
                </MenuItem>
              ))}
          </ControlSelect>

          <div className='footer'>
            <ButtonStyled
              variantstyle={'secondary'}
              size='small'
              onClick={handleClose}
            >
              {t('controlClient.btn-cancel')}
            </ButtonStyled>
            <ButtonStyled type='submit' size='small'>
              {t('controlClient.btn-salve')}
            </ButtonStyled>
          </div>
        </form>
      </div>
    </ContentChangeClienteControl>
  );
};
