import { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { useAtom } from 'jotai';
import { Stack } from '@mui/material';
import { ContentVariables } from './styled';
import { InputComponent } from '@/components/FormComponents/InputComponent';
import { ButtonStyled } from '@/components/FormComponents/ButtonComponent';
import { MdAddCircleOutline, MdCheck, MdClose } from 'react-icons/md';
import {
  focusedFieldArea,
  variablesGlobal,
  variablesList,
} from '@/store/FlowBuilder';
import { RiErrorWarningFill } from 'react-icons/ri';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

export const FieldVariables = ({
  varSelected,
  setVarSelected,
  selectedId,
  dataNodes,
}) => {
  const { t } = useTranslation();
  const [listVars, setListVariables] = useAtom(variablesList);
  const [listGlobalVars] = useAtom(variablesGlobal);
  const [, setFocusedArea] = useAtom(focusedFieldArea);
  const [itemVariableName, setItemVariableName] = useState('');
  const [nameVariableError, setNameVariableError] = useState('');

  const [itemVariableValue, setItemVariableValue] = useState('');
  const [valueVariableError, setValueVariableError] = useState('');

  useEffect(() => {
    if (varSelected) {
      setItemVariableName(varSelected?.name ?? '');
      setItemVariableValue(varSelected?.value ?? '');
    } else {
      setItemVariableName('');
      setItemVariableValue('');
    }
  }, [varSelected]);

  useEffect(() => {
    return () => {
      setItemVariableName('');
      setItemVariableValue('');
    };
  }, []);

  const validateItemVariableName = (value) => {
    if (!value.trim()) {
      setNameVariableError(
        t('flowBuilder.flow.nodes.variables.menu.msg-item-required')
      );
    } else if (value.length > 20) {
      setNameVariableError(
        t('flowBuilder.flow.nodes.variables.menu.msg-name-length', {
          length: '20',
        })
      );
    } else {
      setNameVariableError('');
    }
    setItemVariableName(value);
  };

  const validateItemVariableValue = (value) => {
    if (value.length > 120) {
      setValueVariableError(
        t('flowBuilder.flow.nodes.variables.menu.msg-value-length', {
          length: '120',
        })
      );
    } else {
      setValueVariableError('');
    }
    setItemVariableValue(value);
  };

  const handleNewVariable = () => {
    const { nodes, setNodes } = dataNodes;

    if (itemVariableName === '') {
      setNameVariableError(
        t('flowBuilder.flow.nodes.variables.menu.msg-item-required')
      );
      return;
    }

    const hasExistNameVariable = listVars.filter(
      (item) => item.name === itemVariableName
    );

    const hasExistNameGlobalVariable = listGlobalVars.filter(
      (item) => item.name === itemVariableName
    );

    if (
      hasExistNameVariable.length > 0 ||
      hasExistNameGlobalVariable.length > 0
    ) {
      return setNameVariableError(
        t('flowBuilder.flow.nodes.variables.menu.msg-name-used')
      );
    }

    const newVar = {
      id: uuid(),
      name: itemVariableName,
      value: itemVariableValue === '' ? 'null' : itemVariableValue,
      parent: selectedId,
      referNode: false,
    };

    const filteredNode = nodes.map((node) =>
      node.id === selectedId
        ? {
            ...node,
            data: {
              ...node.data,
              listVariables: [...node.data.listVariables, newVar],
            },
          }
        : node
    );
    setNodes(filteredNode);

    setItemVariableValue('');
    setItemVariableName('');
    return setListVariables((listVariables) => [...listVariables, newVar]);
  };

  const changeNameVariable = (currentPrompt, newPrompt, previewsPrompt) => {
    const replacedPrompt = currentPrompt.replace(previewsPrompt, newPrompt);
    return replacedPrompt;
  };

  const changeTypeConditionVariable = (
    currentPrompt,
    newPrompt,
    previewsPrompt
  ) => {
    if (currentPrompt.toUpperCase() === previewsPrompt.toUpperCase()) {
      return newPrompt.toUpperCase();
    }
    return currentPrompt;
  };

  const changeListVariables = (currentList, previewsNameVariable, newName) => {
    const updatedValue = currentList.map((item) => {
      if (item.name === previewsNameVariable) {
        return {
          ...item,
          name: newName,
        };
      }
      return item;
    });
    return updatedValue;
  };

  const renderNewNameVariable = () => {
    const { nodes, setNodes } = dataNodes;

    const checkVariablesNodes = nodes.map((item) => {
      if (item.data.handler === 'ttsVoicceLabs') {
        return {
          ...item,
          data: {
            ...item.data,
            prompt: changeNameVariable(
              item.data.prompt,
              itemVariableName,
              varSelected.name
            ),
          },
        };
      }

      if (item.data.handler === 'conditionalNode') {
        return {
          ...item,
          data: {
            ...item.data,
            typeCondition: changeTypeConditionVariable(
              item.data.typeCondition,
              itemVariableName,
              varSelected.name
            ).toUpperCase(),
          },
        };
      }

      if (item.data.handler === 'setVariables') {
        return {
          ...item,
          data: {
            ...item.data,
            listVariables: changeListVariables(
              item.data.listVariables,
              varSelected.name,
              itemVariableName
            ),
          },
        };
      }

      return item;
    });

    setNodes(checkVariablesNodes);
  };

  const handleEditVariable = () => {
    const updateVariables = listVars.map((item) => {
      if (item.id === varSelected.id) {
        return {
          ...item,
          name: itemVariableName,
          value: itemVariableValue,
        };
      }
      return item;
    });

    renderNewNameVariable();
    setListVariables(updateVariables);
    setItemVariableValue('');
    setVarSelected(null);
    return;
  };

  const handleCancelEdit = () => {
    setVarSelected(null);
    setItemVariableName('');
    setItemVariableValue('');
  };

  return (
    <ContentVariables>
      <h4>{t('flowBuilder.flow.nodes.variables.menu.title-area-vars')}</h4>
      <InputComponent
        label={t('flowBuilder.flow.nodes.variables.menu.label-name')}
        value={itemVariableName}
        autoComplete='off'
        inputProps={{ maxLength: 20 }}
        onChange={(e) => validateItemVariableName(e.target.value)}
        helperText={nameVariableError}
        error={Boolean(nameVariableError)}
        onFocus={() => setFocusedArea(true)}
        onBlur={() => setFocusedArea(false)}
      />
      <Stack
        justifyContent={'flex-end'}
        width={'100%'}
        direction={'row'}
        spacing={1}
      >
        <InputComponent
          label={t('flowBuilder.flow.nodes.variables.menu.label-value')}
          value={itemVariableValue}
          autoComplete='off'
          inputProps={{ maxLength: 120 }}
          onChange={(e) => validateItemVariableValue(e.target.value)}
          helperText={valueVariableError}
          error={Boolean(valueVariableError)}
          onFocus={() => setFocusedArea(true)}
          onBlur={() => setFocusedArea(false)}
        />
        {varSelected ? (
          <Stack direction={'row'} justifyContent={'flex-end'} spacing={0.4}>
            <ButtonStyled
              type='button'
              size={'small'}
              variantstyle='secondary'
              onClick={handleCancelEdit}
            >
              <MdClose size={16} />
            </ButtonStyled>
            <ButtonStyled
              type='button'
              size={'small'}
              onClick={handleEditVariable}
              disabled={Boolean(valueVariableError)}
            >
              <MdCheck size={18} />
            </ButtonStyled>
          </Stack>
        ) : (
          <ButtonStyled
            type='button'
            size={'small'}
            onClick={handleNewVariable}
            disabled={Boolean(valueVariableError) || Boolean(nameVariableError)}
          >
            <MdAddCircleOutline />
          </ButtonStyled>
        )}
      </Stack>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{
          opacity: itemVariableValue === '' ? 1 : 0,
          y: itemVariableValue === '' ? 0 : 20,
        }}
      >
        <Stack
          justifyContent={'flex-star'}
          alignItems={'center'}
          direction={'row'}
          spacing={1}
          width={'100%'}
          className='warning-description'
        >
          <RiErrorWarningFill size={14} />
          <span>
            {t('flowBuilder.flow.nodes.variables.menu.label-description')}
          </span>
        </Stack>
      </motion.div>
    </ContentVariables>
  );
};
