import { useId, useState } from 'react';
import { NewFlowPaginator } from './NewFlowPaginator';
import { ContainerNewFlow } from './styled';
import { NewFlow } from './Stages/NewFlow';
import { ConfigVars } from './Stages/ConfigVars';
import { CreatedFlow } from './Stages/CreatedFlow';
import { ConfigDispositions } from './Stages/ConfigDispositions';
import { ConfigNLU } from './Stages/ConfigNLU';

export const CurrentNewFlow = ({ changeStageFlow }) => {
  const [stageNewFlow, setStageNewFlow] = useState(0);

  const changeStateCreateFlow = (stage) => setStageNewFlow(stage);

  const renderStagesFlow = [
    <NewFlow changeStateFlow={changeStateCreateFlow} key={useId()} />,
    <ConfigVars changeStateFlow={changeStateCreateFlow} key={useId()} />,
    <ConfigDispositions
      changeStateFlow={changeStateCreateFlow}
      key={useId()}
    />,
    <ConfigNLU changeStateFlow={changeStateCreateFlow} key={useId()} />,
    <CreatedFlow changeStateFlow={changeStageFlow} key={useId()} />,
  ];

  return (
    <ContainerNewFlow initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <NewFlowPaginator stageFlow={stageNewFlow} />
      {renderStagesFlow[stageNewFlow]}
    </ContainerNewFlow>
  );
};
