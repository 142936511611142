import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { RiErrorWarningLine } from 'react-icons/ri';

import useAuth from '@/hooks/Authenticator/useAuth';
import { useLocalStorage } from '@hooks/useLocalStorage';

import { ButtonStyled } from '@components/FormComponents/ButtonComponent';

import { ContainerModalLogout } from './styled';

function ModalLogoutWhenIdle() {
  const { t } = useTranslation();
  const { logOut } = useAuth();
  const { removeLocalStorage } = useLocalStorage();

  useEffect(() => {
    removeLocalStorage('@s');
  }, []);

  return (
    <ContainerModalLogout onSubmit={logOut}>
      <div className='header_modal'>
        <RiErrorWarningLine size={32} />
        <h2>{t('logOutWhenIdle.title-idle')}</h2>
      </div>
      <div className='content_modal'>
        <p>{t('logOutWhenIdle.firstSentence')}</p>
        <p>{t('logOutWhenIdle.secondSentence')}</p>
      </div>
      <div className='footer_modal'>
        <ButtonStyled schema_type='primary' type='submit'>
          OK
        </ButtonStyled>
      </div>
    </ContainerModalLogout>
  );
}

export { ModalLogoutWhenIdle };
