import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAtom } from 'jotai';
import { MenuItem, CircularProgress } from '@mui/material';

import { ControlInput } from '@/components/FormComponents/InputComponent';
import { ControlSelect } from '@/components/FormComponents/SelectInput';
import { useTranslation } from 'react-i18next';
import { dataListClients } from '@/store/Clients';
import { FooterForm, FormAddRoutingProfile, MainForm } from './styled';
import { ButtonStyled } from '@/components/FormComponents/ButtonComponent';
import { DescriptionInformation } from '@/components/layout/DescriptionInformation';
import { dataRoutesProfileSelected } from '@/store/UsersAgentsStore/userAgentsStore';
import { useRoutingProfile } from '@/hooks/UserAgents/useRoutingProfile';

export const DetailNewRouting = ({ handleBackRouting, handleNextStage }) => {
  const { t } = useTranslation();
  const { mutateCreateRoutingProfile, loadingCreateRoutingProfile } =
    useRoutingProfile();
  const [dataClientsList] = useAtom(dataListClients);
  const [, setDataNewRouting] = useAtom(dataRoutesProfileSelected);

  const addNewQueueToUserSchema = yup.object().shape({
    nameProfile: yup
      .string()
      .required(
        t(
          'routingProfile.addRoutingProfile.detailsAddRouting.messages.errorFieldName'
        )
      ),
    descriptionProfile: yup
      .string()
      .required(
        t(
          'routingProfile.addRoutingProfile.detailsAddRouting.messages.errorFieldDescription'
        )
      ),
    client_id: yup.string(),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(addNewQueueToUserSchema),
  });

  const handleSaveProfile = async (data) => {
    const dataNewProfileWithClient = {
      name: data.nameProfile,
      description: data.descriptionProfile,
      client_id: data.client_id,
    };

    const dataNewProfileClientNull = {
      name: data.nameProfile,
      description: data.descriptionProfile,
    };

    const dataNewProfile =
      data.client_id === ''
        ? dataNewProfileClientNull
        : dataNewProfileWithClient;

    mutateCreateRoutingProfile(dataNewProfile, {
      onSuccess: (data) => {
        setDataNewRouting(data);
        handleNextStage(1);
      },
    });
  };

  const handleBack = () => {
    handleBackRouting();
  };

  return (
    <FormAddRoutingProfile onSubmit={handleSubmit(handleSaveProfile)}>
      <MainForm>
        <DescriptionInformation
          textDescription={t(
            'routingProfile.addRoutingProfile.detailsAddRouting.description'
          )}
        />
        <ControlInput
          control={control}
          nameControl={'nameProfile'}
          label={t(
            'routingProfile.addRoutingProfile.detailsAddRouting.label-name'
          )}
          placeholder={t(
            'routingProfile.addRoutingProfile.detailsAddRouting.label-name'
          )}
          disabled={loadingCreateRoutingProfile}
          error={Boolean(errors.nameProfile)}
          helperText={errors.nameProfile ? errors.nameProfile.message : ' '}
        />

        <ControlInput
          control={control}
          nameControl={'descriptionProfile'}
          disabled={loadingCreateRoutingProfile}
          label={t(
            'routingProfile.addRoutingProfile.detailsAddRouting.label-description'
          )}
          placeholder={t(
            'routingProfile.addRoutingProfile.detailsAddRouting.label-description'
          )}
          error={Boolean(errors.descriptionProfile)}
          helperText={
            errors.descriptionProfile ? errors.descriptionProfile.message : ' '
          }
        />

        <ControlSelect
          control={control}
          nameControl={'client_id'}
          label={t(
            'routingProfile.addRoutingProfile.detailsAddRouting.label-client'
          )}
          defaultValue={''}
          error={Boolean(errors.client_id)}
          disabled={loadingCreateRoutingProfile}
          helperText={errors.client_id ? errors.client_id.message : '   '}
        >
          <MenuItem value={'0'} disabled dense>
            {t(
              'routingProfile.addRoutingProfile.detailsAddRouting.option-client'
            )}
          </MenuItem>
          {dataClientsList.length > 0 &&
            dataClientsList.map((item) => (
              <MenuItem key={item.id} value={item.id} dense>
                {item.commercial_name}
              </MenuItem>
            ))}
        </ControlSelect>
      </MainForm>
      <FooterForm>
        <ButtonStyled
          variantstyle={'secondary'}
          size={'small'}
          onClick={handleBack}
        >
          {t('routingProfile.addRoutingProfile.detailsAddRouting.btn-cancel')}
        </ButtonStyled>
        <ButtonStyled type='submit' size={'small'}>
          {loadingCreateRoutingProfile ? (
            <CircularProgress size={18} />
          ) : (
            t('routingProfile.addRoutingProfile.detailsAddRouting.btn-confirm')
          )}
        </ButtonStyled>
      </FooterForm>
    </FormAddRoutingProfile>
  );
};
