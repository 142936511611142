import styled from 'styled-components';

export const ContainerResponseDataApi = styled.div`
  padding: 2rem 10px;
  display: grid;
  grid-template-rows: 20px 1fr 30px;
  gap: 1.2rem;

  .body-response {
    border: 1px solid ${(props) => props.theme.colors.active};
  }

  h4 {
    text-transform: uppercase;
    font-size: 0.8rem;
    color: ${(props) => props.theme.colors.typography.baseText};
  }

  .ace_string {
    color: ${(props) => props.theme.colors.typography.baseTitle};
  }
`;

export const HeaderResponse = styled.header`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  font-size: 1rem;
  .title-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    color: ${(props) => props.theme.colors.primary};
  }

  strong {
    color: ${(props) => props.theme.colors.bases.baseWhite};
    padding: 3px 10px;
    background-color: ${(props) => props.theme.colors.success};
    border-radius: 30px;
    font-weight: 400;
    font-size: 0.8rem;
  }

  .errorResponse {
    strong {
      color: ${(props) => props.theme.colors.bases.baseWhite};
      padding: 3px 10px;
      background-color: ${(props) => props.theme.colors.error};
      border-radius: 30px;
      font-weight: 400;
      font-size: 0.8rem;
    }
  }

  .content-header {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
`;

export const NavResponse = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;

  .active-btn-nav-api {
    background: ${(props) => props.theme.colors.primary} !important;
  }

  button {
    background: ${(props) => props.theme.colors.active} !important;
  }
`;

export const MainResponse = styled.header`
  display: flex;
  flex-direction: column;
  /* gap: 2rem; */
  gap: 1rem;

  .itemResponse {
    display: flex;
    flex-direction: column;
    gap: 10px;

    button {
      all: unset;
      width: 40px;
      height: 36px;

      display: flex;
      flex-direction: center;
      align-items: center;
      background: ${(props) => props.theme.colors.primary};
      border-radius: 4px;
      margin: 0 0 0 6px;
      padding: 2px;
      svg {
        padding-left: 2px;
        color: white;
      }

      &:disabled {
        background-color: gray;
        cursor: not-allowed;
      }
    }
  }

  .render-vars-api {
    height: 100px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow: scroll;
  }

  h4 {
    text-transform: uppercase;
    font-size: 0.7rem;
    color: ${(props) => props.theme.colors.typography.baseText};
  }
`;

export const FooterResponse = styled.footer`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
`;
