import {
  idNodeToRemove,
  selectedNode,
  showMenuNodes,
} from '@/store/FlowBuilder';
import { useAtom } from 'jotai';
import React, { memo } from 'react';
import { Handle, Position } from 'reactflow';
import { NodeConditionContainer, NodeContent } from './styled';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { motion } from 'framer-motion';
import { MdEdit } from 'react-icons/md';
import { Stack } from '@mui/material';
import { BiSolidUserPin } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';
import { shortNameRender } from '../../../utils/shortNameRender';
import { usePermissionsFlow } from '@/hooks/FlowBuilder/usePermissionsFlow';

const PersonaNodeComponent = ({ id, data, selected }) => {
  const { t } = useTranslation();
  const { permissionsEdit, permissionsDelete, isSysAdmin } =
    usePermissionsFlow();

  const { language, voice, prosodyRate } = data;

  const [, setIdNode] = useAtom(idNodeToRemove);
  const [, setShowMenu] = useAtom(showMenuNodes);
  const [nodeSelected, setSelectedNode] = useAtom(selectedNode);

  const handleNode = () => {
    setSelectedNode({ id, data });
    setShowMenu(true);
  };

  const handleRemoveNode = (idNode) => {
    setIdNode(idNode);
    idNode === nodeSelected?.id && setShowMenu(false);
  };

  const handleSelectedNode = () => {
    setSelectedNode({ id, data });
    setShowMenu(true);
  };

  const permissionNodeEdit = permissionsEdit || isSysAdmin;
  const permissionNodeDelete = permissionsDelete || isSysAdmin;

  return (
    <NodeConditionContainer
      selected={selected}
      errorConnect={data.errorConnect ? 1 : 0}
      onDoubleClick={() => handleNode()}
    >
      <div className='panelNode'>
        {permissionNodeEdit && (
          <motion.button
            onClick={() => handleSelectedNode()}
            whileTap={{ scale: 0.98 }}
          >
            <MdEdit size={16} />
          </motion.button>
        )}
        {permissionNodeDelete && (
          <motion.button
            onClick={() => handleRemoveNode(id)}
            whileTap={{ scale: 0.98 }}
          >
            <IoIosCloseCircleOutline />
          </motion.button>
        )}
      </div>

      <Handle type='target' position={Position.Left} />
      <NodeContent typeNode={data.handler}>
        <div className='header'>
          <BiSolidUserPin size={16} />
          <div className='titleNode'>
            <h4>{shortNameRender(data.label, 12)}</h4>
            <span>{t('flowBuilder.flow.nodes.persona.label')}</span>
          </div>
        </div>
        <div className='main'>
          <Stack direction={'row'} alignItems={'center'} spacing={1}>
            <span>{t('flowBuilder.flow.nodes.persona.label-language')}: </span>
            <span>
              <strong>
                {language === '0'
                  ? t('flowBuilder.flow.nodes.persona.default-language')
                  : language}
              </strong>
            </span>
          </Stack>
          <Stack direction={'row'} alignItems={'center'} spacing={1}>
            <span>{t('flowBuilder.flow.nodes.persona.label-voice')}: </span>
            <span>
              <strong>
                {voice === '0'
                  ? t('flowBuilder.flow.nodes.persona.default-voice')
                  : voice}
              </strong>
            </span>
          </Stack>

          <Stack direction={'row'} alignItems={'center'} spacing={1}>
            <span>
              {t('flowBuilder.flow.nodes.persona.label-prosodyRate')}:{' '}
            </span>
            <span>
              <strong>{prosodyRate}</strong>
            </span>
          </Stack>
        </div>
      </NodeContent>
    </NodeConditionContainer>
  );
};

export const PersonaNode = memo(PersonaNodeComponent);
