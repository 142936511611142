import * as yup from 'yup';
import useFormControl from '@/hooks/useFormControl';
import { ButtonStyled } from '@/components/FormComponents/ButtonComponent';
import { FooterCreateGroup, FormCreateGroup, MainCreateGroup } from './styled';
import { ControlInput } from '@/components/FormComponents/InputComponent';
import { Stack } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { successToast } from '@/components/FormComponents/Toast';
import { useRenitency } from '@/hooks/dialControl/Renitency/useRenitency';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';
import { TitleComponent } from '@/components/layout/TitleComponent';
import { DescriptionInformation } from '@/components/layout/DescriptionInformation';
import { ControlSelect } from '@/components/FormComponents/SelectInput';
import { MenuItem } from '@mui/material';
import { useAtom } from 'jotai';
import { user } from '@/store/AuthenticatorStore';
import { dataListClients } from '@/store/Clients';

export const CreateGroupRenitency = ({ controlStage }) => {
  const { t } = useTranslation();
  const [currentUser] = useAtom(user);
  const [listClients] = useAtom(dataListClients);
  const { mutateCreateRenitency, loadingCreateRenitency } = useRenitency();

  const isVoicceLabsClient =
    currentUser.client_id === '29904593-681a-4ad6-97c8-e26483468b73';

  const renderTextCreate = (spell) => {
    return t(`dialControl.renitency.create.${spell}`);
  };

  const addCampaignSchema = yup.object().shape({
    description: yup.string().required(),
    client_id: yup.string().required(),
  });

  const { handleSubmit, control, errors, reset } =
    useFormControl(addCampaignSchema);

  const handleCreateGroup = (data) => {
    const dataCreate = {
      descricao: data.description,
      id_cliente: data.client_id,
    };

    mutateCreateRenitency(dataCreate, {
      onSuccess: () => {
        successToast(renderTextCreate('messages.success-created'));
        reset();
        controlStage();
      },
    });
  };

  const handleBackGroup = () => {
    reset();
    controlStage();
  };

  return (
    <FormCreateGroup
      onSubmit={handleSubmit(handleCreateGroup)}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      {loadingCreateRenitency && <BackDropCircularLoading />}
      <MainCreateGroup>
        <TitleComponent
          textTitle={renderTextCreate('title')}
          iconTitle='addCampaign'
        />
        <DescriptionInformation
          textDescription={renderTextCreate('description')}
        />
        <Stack width={'100%'} direction={'column'} spacing={2}>
          <Stack width={'100%'} direction={'column'} spacing={2}>
            <ControlSelect
              control={control}
              nameControl={'client_id'}
              defaultValue={currentUser.client_id}
              label={renderTextCreate('label-client')}
              error={Boolean(errors.client_id)}
              disabled={!isVoicceLabsClient}
            >
              <MenuItem value={'0'} dense disabled>
                {t('flowBuilder.exportFlow.option-client-export')}
              </MenuItem>
              {listClients.length > 0 &&
                listClients.map((item) => (
                  <MenuItem value={item.id} key={item.id} dense>
                    {item.commercial_name}
                  </MenuItem>
                ))}
            </ControlSelect>

            <ControlInput
              control={control}
              nameControl={'description'}
              label={renderTextCreate('label-description')}
              error={Boolean(errors.description)}
            />
          </Stack>
        </Stack>
      </MainCreateGroup>
      <FooterCreateGroup>
        <ButtonStyled
          variantstyle={'secondary'}
          onClick={handleBackGroup}
          size={'small'}
        >
          {renderTextCreate('btn-cancel')}
        </ButtonStyled>
        <ButtonStyled type='submit' size={'small'}>
          {renderTextCreate('btn-create')}
        </ButtonStyled>
      </FooterCreateGroup>
    </FormCreateGroup>
  );
};
