import styled from 'styled-components';

export const ContainerAgentDisconnected = styled.div`
  background-color: ${(props) => props.theme.colors.containers.baseContainer};
  width: 100%;
  padding: 1rem;
  border: 2px solid red;
  border-radius: 4px;

  .content_disconnected {
    display: flex;
    align-items: center;
    gap: 10px;

    svg {
      font-size: 1.2rem;
      color: red;
    }

    span {
      font-weight: 700;
      font-size: 0.75rem;
      letter-spacing: 0.1em;
      text-transform: uppercase;

      color: ${(props) => props.theme.colors.typography.baseText};
    }
  }
`;
