import { ButtonStyled } from '@/components/FormComponents/ButtonComponent';
import { Stack } from '@mui/system';
import { PiFileCsv, PiFilePdf } from 'react-icons/pi';
import { RiFileExcel2Line } from 'react-icons/ri';
import {
  BtnPanel,
  ContainerListExtDownload,
  ContentListExtDownload,
} from './styled';
import { saveAs } from 'file-saver';

import { format } from 'date-fns';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { MdOutlineFileDownload } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';
import { progressDownload, workerLibDownload } from '@/store/workersStore';
import { useEffect, useState } from 'react';
import { errorToast } from '@/components/FormComponents/Toast';

export const DownloadData = ({
  panelRef,
  showPanelDownload,
  handlePanelDownloads,
  dataDownload,
  disabled,
}) => {
  const { t } = useTranslation();
  const currentTime = format(new Date(), 'dd-MM-yyyy');
  const dataNameDocument = `esforco_${currentTime}`;

  const [workerDownload] = useAtom(workerLibDownload);
  const [, setProgress] = useAtom(progressDownload);
  const [loadingWorker, setLoadingWorker] = useState(false);

  const renderText = (spell) => {
    return t(`reports.callingEfforts.listCallingEfforts.${spell}`);
  };

  useEffect(() => {
    workerDownload.onmessage = (event) => {
      if (event.data.type === 'error') {
        errorToast(renderText('msg-error-download'));
        setProgress(0);
        setLoadingWorker(false);
      }
      if (event.data.type === 'percent') {
        setProgress(event.data.percentage);
      }
      if (event.data.type === 'csv') {
        const blob = new Blob([event.data.csv], {
          type: 'text/csv;charset=utf-8;',
        });
        saveAs(blob, event.data.nameFile);
        setLoadingWorker(false);
      }
      if (event.data.type === 'xls') {
        const blob = new Blob([event.data.xls], {
          type: 'application/octet-stream',
        });
        saveAs(blob, event.data.nameFile);
        setLoadingWorker(false);
      }
      if (event.data.type === 'pdf') {
        const blob = new Blob([event.data.pdf], {
          type: 'application/octet-stream',
        });
        saveAs(blob, `${event.data.nameFile}.pdf`);
        setLoadingWorker(false);
      }
    };
  }, []);

  const handleDownloadCSV = (dataDownload, nameFile) => {
    setLoadingWorker(true);
    const nameCSV = `${nameFile}.csv`;
    workerDownload.postMessage({
      type: 'sendFileCSV',
      dataJson: dataDownload,
      nameFile: nameCSV,
    });
  };

  const handleDownloadXLS = (dataDownload, nameFile) => {
    setLoadingWorker(true);
    const nameXLS = `${nameFile}.xlsx`;
    workerDownload.postMessage({
      type: 'sendFileXLS',
      dataJson: dataDownload,
      nameFile: nameXLS,
    });
  };

  const handleDownloadPDF = (dataDownload, nameFile) => {
    setLoadingWorker(true);
    const namePDF = `${nameFile}`;
    workerDownload.postMessage({
      type: 'sendFilePDF',
      dataJson: dataDownload,
      nameFile: namePDF,
    });
  };

  return (
    <ContainerListExtDownload>
      <ContentListExtDownload
        ref={panelRef}
        initial={{ opacity: 0, x: -100 }}
        animate={{
          opacity: showPanelDownload ? 1 : 0,
          x: showPanelDownload ? 0 : -100,
          display: showPanelDownload ? 'flex' : 'none',
        }}
      >
        <ButtonStyled
          size={'small'}
          onClick={() => handleDownloadPDF(dataDownload, dataNameDocument)}
          title={renderText('title-btn-pdf')}
          disabled={loadingWorker}
        >
          <PiFilePdf size={21} />
        </ButtonStyled>
        <ButtonStyled
          size={'small'}
          onClick={() => handleDownloadCSV(dataDownload, dataNameDocument)}
          title={renderText('title-btn-csv')}
          disabled={loadingWorker}
        >
          <PiFileCsv size={21} />
        </ButtonStyled>
        <ButtonStyled
          size={'small'}
          onClick={() => handleDownloadXLS(dataDownload, dataNameDocument)}
          title={renderText('title-btn-xls')}
          disabled={loadingWorker}
        >
          <RiFileExcel2Line size={18} />
        </ButtonStyled>
      </ContentListExtDownload>
      <BtnPanel
        size={'small'}
        onClick={handlePanelDownloads}
        show={showPanelDownload ? 1 : 0}
        disabled={disabled}
      >
        {showPanelDownload ? (
          <Stack direction={'row'} alignItems={'center'} spacing={1}>
            <IoIosCloseCircleOutline size={20} />
            <span>{renderText('btn-download-close')}</span>
          </Stack>
        ) : (
          <Stack direction={'row'} alignItems={'center'} spacing={1}>
            <MdOutlineFileDownload size={18} />
            <span>{renderText('btn-download')}</span>
          </Stack>
        )}
      </BtnPanel>
    </ContainerListExtDownload>
  );
};
