import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ContainerDialAgents = styled(motion.div)`
  width: 100%;
  background: ${(props) => props.theme.colors.containers.colorCard};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
`;

export const TitleAgents = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;

  h2 {
    font-weight: 700;
    font-size: 0.75rem;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: ${(props) => props.theme.colors.typography.baseText};
  }

  svg {
    color: ${(props) => props.theme.colors.typography.baseTitle};
  }
`;

export const FormNumberToDial = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  .contentForm {
    width: 100%;
  }
  .footerForm {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 0.5rem;
  }
`;

export const ContainerInDial = styled.div`
  width: 100%;
  padding: 0.3rem;
  .contentInDial {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    .statusDial {
      width: 6px;
      height: 6px;
      background-color: ${(props) => props.theme.colors.success};
      border-radius: 50%;
    }
    span {
      font-weight: 400;
      font-size: 0.75rem;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: ${(props) => props.theme.colors.typography.baseText};
    }
  }
  .footerInDial {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 0.5rem;

    button {
      display: flex;
      gap: 0.4rem;
    }
  }
`;
