import { ButtonStyled } from '@/components/FormComponents/ButtonComponent';
import { Divider, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { FaArrowLeft } from 'react-icons/fa';
import { useAtom } from 'jotai';
import { selectedQueue } from '@/store/DialingStore';
import { ContainerWeekDays, ContentPanelScheduleList } from './styled';
import { MdAdd } from 'react-icons/md';
import { useDaysOfWeek } from '../../../hooks/useDaysOfWeek';
import { usePermissionsSchedulerList } from '../../../hooks/usePermissionsSchedulerList';

export const PanelFilter = ({ handleNewSchema, filters, controlStage }) => {
  const { t } = useTranslation();
  const { daysOfWeek } = useDaysOfWeek();
  const [queueSelected] = useAtom(selectedQueue);
  const { permissionsCreate } = usePermissionsSchedulerList();

  const txtSchedules = (word) =>
    t(`dialControl.schedulesList.listSchedules.${word}`);

  const handleDayClick = (day) => {
    if (filters.selectedDaysFilter.includes(day)) {
      filters.setSelectedDaysFilter(
        filters.selectedDaysFilter.filter((selectedDay) => selectedDay !== day)
      );
    } else {
      filters.setSelectedDaysFilter([...filters.selectedDaysFilter, day]);
    }
  };

  return (
    <ContentPanelScheduleList>
      <Stack direction='column' spacing={2}>
        <Stack direction='row' spacing={2}>
          <motion.div
            whileTap={{ scale: 0.8 }}
            className='icon_handle_back'
            onClick={controlStage}
          >
            <FaArrowLeft size='16' />
          </motion.div>
          <div /* className='itemPanel' */>
            <span>
              <strong>
                {`${queueSelected.fila ?? ''} - ${
                  queueSelected.campanha ?? ''
                }`}
              </strong>
            </span>
          </div>
        </Stack>
      </Stack>
      <Divider />
      <ContainerWeekDays>
        <span>{txtSchedules('label-filterDayWeek')}:</span>
        <div className='content-days'>
          {daysOfWeek.map((day, index) => (
            <motion.label
              whileTap={{ scale: 0.95 }}
              key={index}
              title={day.title}
              className={
                filters.selectedDaysFilter.includes(index)
                  ? 'weekday checkedDay'
                  : 'weekday'
              }
            >
              <input
                type='checkbox'
                value={day}
                id={day.title}
                name={day.title}
                className=''
                checked={filters.selectedDaysFilter.includes(index)}
                onChange={() => handleDayClick(index)}
              />
              <strong>{day.title.substring(0, 1)}</strong>
            </motion.label>
          ))}
        </div>
      </ContainerWeekDays>

      <div className='content-btn'>
        <ButtonStyled
          variantstyle='primary'
          size={'small'}
          onClick={handleNewSchema}
          title={txtSchedules('btn-addSchedule')}
          disabled={!permissionsCreate}
        >
          <MdAdd />
        </ButtonStyled>
      </div>
    </ContentPanelScheduleList>
  );
};
