import { useEffect } from 'react';
import { useAtom } from 'jotai';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack, MenuItem, CircularProgress } from '@mui/material';
import { IoIosAddCircle } from 'react-icons/io';
import { useRoutingProfile } from '@hooks/UserAgents/useRoutingProfile';
import { stateRenderRoutingProfiles } from '@store/UsersAgentsStore/routingProfiles';
import { ButtonStyled } from '@components/FormComponents/ButtonComponent';
import { ControlInput } from '@components/FormComponents/InputComponent';
import { TitleComponent } from '@components/layout/TitleComponent';
import { successToast } from '@components/FormComponents/Toast';
import { dataRoutesProfileSelected } from '@/store/UsersAgentsStore/userAgentsStore';
import { ControlSelect } from '@/components/FormComponents/SelectInput';
import { dataListClients } from '@/store/Clients';
import {
  ContainerAddNewRoute,
  ContentAddLayout,
  FormAddRoutingProfile,
} from './styled';
import { useClients } from '@/hooks/Clients/useClients';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';

export const EditDetailRouting = ({ changeStageEditFlow }) => {
  const { t } = useTranslation();
  const { isLoadingDataClients } = useClients(true);
  const { mutateEditRoutingProfile, loadingEditRoutingProfile } =
    useRoutingProfile();

  const [dataClientsList] = useAtom(dataListClients);
  const [dataEdit, setDataEdit] = useAtom(dataRoutesProfileSelected);
  const [, setCurrentRenderRoutingProfile] = useAtom(
    stateRenderRoutingProfiles
  );

  const handleLScreensRoutesProfile = () => {
    changeStageEditFlow(1);
  };

  const handleBack = () => {
    setDataEdit(null);
    setCurrentRenderRoutingProfile({ current: 0, previous: 0 });
  };

  const addNewQueueToUserSchema = yup.object().shape({
    namProfile: yup
      .string()
      .required(t('routingProfile.addRoutingProfile.messageErrorFieldName')),
    descriptionProfile: yup
      .string()
      .required(
        t('routingProfile.addRoutingProfile.messages.errorFieldDescription')
      ),
    client_id: yup.string().required(),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(addNewQueueToUserSchema),
    defaultValues: {
      namProfile: dataEdit?.name,
      descriptionProfile: dataEdit?.description,
    },
  });

  useEffect(() => {
    return () => {
      reset();
    };
  }, []);

  const handleSaveProfile = (data) => {
    const dataSaveProfile = {
      id: dataEdit.id,
      name: data.namProfile,
      description: data.descriptionProfile,
      client_id: data.client_id,
    };

    mutateEditRoutingProfile(dataSaveProfile, {
      onSuccess: () => {
        setDataEdit(null);
        setCurrentRenderRoutingProfile({ current: 0, previous: 0 });
        successToast(
          t('routingProfile.editRoutingProfile.messages.successEditProfile')
        );
      },
    });
  };

  return (
    <ContainerAddNewRoute initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      {isLoadingDataClients && <BackDropCircularLoading />}
      <TitleComponent
        textTitle={'Edit Details Routing Profile'}
        iconTitle='routingProfile'
      />

      <FormAddRoutingProfile onSubmit={handleSubmit(handleSaveProfile)}>
        <Stack spacing={2}>
          <TitleComponent
            textTitle={t(
              'routingProfile.addRoutingProfile.detailsAddRouting.subTitleDetails'
            )}
          />

          <ControlInput
            control={control}
            nameControl={'namProfile'}
            label={t(
              'routingProfile.addRoutingProfile.detailsAddRouting.label-name'
            )}
            placeholder={t('routingProfile.addRoutingProfile.label-name')}
            error={Boolean(errors.namProfile)}
            helperText={errors.namProfile ? errors.namProfile.message : ' '}
            disabled={loadingEditRoutingProfile}
          />

          <ControlInput
            control={control}
            nameControl={'descriptionProfile'}
            label={t(
              'routingProfile.addRoutingProfile.detailsAddRouting.label-description'
            )}
            placeholder={t(
              'routingProfile.addRoutingProfile.label-description'
            )}
            disabled={loadingEditRoutingProfile}
            error={Boolean(errors.descriptionProfile)}
            helperText={
              errors.descriptionProfile
                ? errors.descriptionProfile.message
                : ' '
            }
          />

          <ControlSelect
            control={control}
            nameControl={'client_id'}
            defaultValue={dataEdit?.client_id ?? '0'}
            label={t(
              'routingProfile.addRoutingProfile.detailsAddRouting.label-client'
            )}
            error={Boolean(errors.client_id)}
            helperText={errors.client_id ? errors.client_id.message : '   '}
            disabled={loadingEditRoutingProfile}
          >
            <MenuItem value={'0'} disabled dense>
              {t(
                'routingProfile.addRoutingProfile.detailsAddRouting.option-client'
              )}
            </MenuItem>
            {dataClientsList.length > 0 &&
              dataClientsList.map((item, i) => (
                <MenuItem key={i} value={item.id} dense>
                  {item.commercial_name}
                </MenuItem>
              ))}
          </ControlSelect>
        </Stack>
        <ContentAddLayout>
          <div className='content_add_layout'>
            <TitleComponent
              textTitle={t(
                'routingProfile.editRoutingProfile.title-addScreens'
              )}
            />
            <Stack>
              <span>
                {t('routingProfile.editRoutingProfile.description-addScreens')}
              </span>
              <div className='btn_add_routing'>
                <ButtonStyled
                  onClick={handleLScreensRoutesProfile}
                  size={'small'}
                >
                  {loadingEditRoutingProfile ? (
                    <CircularProgress size={18} />
                  ) : (
                    <Stack direction={'row'} alignItems={'center'} spacing={1}>
                      <IoIosAddCircle />
                      <span>
                        {t('routingProfile.editRoutingProfile.btn-addScreens')}
                      </span>
                    </Stack>
                  )}
                </ButtonStyled>
              </div>
            </Stack>
          </div>

          <div className='footerForm'>
            <ButtonStyled
              variantstyle={'secondary'}
              onClick={handleBack}
              size={'small'}
            >
              Cancel
              {/* {t('routingProfile.editRoutingProfile.btn-cancel')} */}
            </ButtonStyled>
            <ButtonStyled type='submit' size={'small'}>
              {loadingEditRoutingProfile ? (
                <CircularProgress size={18} />
              ) : (
                <Stack
                  direction={'row'}
                  alignItems={'center'}
                  spacing={1}
                  style={{ color: 'FFF' }}
                  className='btn-updated-routing'
                >
                  <span>
                    {t('routingProfile.editRoutingProfile.btn-updateProfile')}
                  </span>
                </Stack>
              )}
            </ButtonStyled>
          </div>
        </ContentAddLayout>
      </FormAddRoutingProfile>
    </ContainerAddNewRoute>
  );
};
