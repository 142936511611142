import { Stack } from '@mui/system';
import { FaGear } from 'react-icons/fa6';
import { FooterConfig } from '../../../styled';
import { ButtonStyled } from '@/components/FormComponents/ButtonComponent';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import useFormControl from '@/hooks/useFormControl';
import {
  ControlInput,
  InputComponent,
} from '@/components/FormComponents/InputComponent';
import { useEffect, useState } from 'react';
import { ItemIntent } from './ItemIntent';
import { ContentIntents, FormAddEndpoint, PanelIntents } from './styled';
import { successToast } from '@/components/FormComponents/Toast';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';
import { useEndpoints } from '@/hooks/FlowBuilder/useEndpoints';

export const AddNlus = ({ changeFlowConfigState }) => {
  const { t } = useTranslation();
  const { mutateCreateEndpoint, loadingCreateEndpoint } = useEndpoints();

  const [nameNewIntent, setNameNewIntent] = useState('');
  const [listAddIntents, setListAddIntents] = useState([]);
  const [errorAddIntent, setErrorAddIntent] = useState(false);

  useEffect(() => {
    const hasIntent = listAddIntents.includes(nameNewIntent);
    if (hasIntent || nameNewIntent === '') {
      return setErrorAddIntent(true);
    }
    return setErrorAddIntent(false);
  }, [nameNewIntent, setErrorAddIntent]);

  const nluSchema = yup.object().shape({
    nameEndpoint: yup
      .string()
      .required()
      .matches(/^[\w]+$/, 'Apenas letras, números e "_" são permitidos'),
  });

  const { handleSubmit, control, reset, errors } = useFormControl(nluSchema);

  const handleSubmitNewEndpoint = (data) => {
    const dataNewEndpoint = {
      name: data.nameEndpoint,
      type: data.nameEndpoint,
      data: listAddIntents,
    };

    mutateCreateEndpoint(dataNewEndpoint, {
      onSuccess: () => {
        successToast(
          t('flowBuilder.configs.endpoints.msg-success-addedEndpoint')
        );
        reset();
        setErrorAddIntent(false);
        setListAddIntents([]);
        changeFlowConfigState(0);
      },
    });
  };

  const handleNewIntent = (nameIntent) => {
    setListAddIntents((previousState) => [
      nameIntent.toLowerCase(),
      ...previousState,
    ]);
    setNameNewIntent('');
  };

  const handleRemoveNewIntent = (nameIntent) => {
    const removeIntent = listAddIntents.filter((item) => item !== nameIntent);
    setListAddIntents(removeIntent);
  };

  return (
    <FormAddEndpoint onSubmit={handleSubmit(handleSubmitNewEndpoint)}>
      {loadingCreateEndpoint && <BackDropCircularLoading />}
      <Stack
        direction={'row'}
        spacing={1}
        alignItems={'center'}
        className={'titleConfig'}
      >
        <FaGear />
        <h4> {t('flowBuilder.configs.endpoints.btn-add-endPoint')}</h4>
      </Stack>

      <Stack
        direction={'column'}
        spacing={1}
        alignItems={'center'}
        height={'60vh'}
        width='100%'
        paddingTop={'1rem'}
      >
        <PanelIntents>
          <ControlInput
            label={t('flowBuilder.configs.endpoints.label-name-newEndpoint')}
            control={control}
            nameControl={'nameEndpoint'}
            error={Boolean(errors.nameEndpoint)}
            helperText={errors.nameEndpoint ? errors.nameEndpoint.message : ' '}
            // inputProps={{ maxLength: 51 }}
          />
          <Stack direction={'row'} gap={1}>
            <InputComponent
              label={t('flowBuilder.configs.endpoints.label-name-intent')}
              value={nameNewIntent}
              onChange={(e) => setNameNewIntent(e.target.value)}
            />
            <ButtonStyled
              size={'small'}
              type='button'
              onClick={() => handleNewIntent(nameNewIntent)}
              disabled={errorAddIntent}
            >
              {t('flowBuilder.configs.btn-add')}
            </ButtonStyled>
          </Stack>
          <ContentIntents>
            {listAddIntents.length > 0 && <h5>List Intents</h5>}
            <ul>
              {listAddIntents.length > 0 &&
                listAddIntents.map((intents, i) => (
                  <li key={i}>
                    <ItemIntent
                      intent={intents}
                      removeIntent={handleRemoveNewIntent}
                    />
                  </li>
                ))}
            </ul>
          </ContentIntents>
        </PanelIntents>
      </Stack>

      <FooterConfig>
        <div className=''></div>
        <Stack direction={'row'} spacing={1}>
          <ButtonStyled
            size='small'
            variantstyle='secondary'
            onClick={() => changeFlowConfigState(0)}
          >
            {t('flowBuilder.configs.btn-cancel')}
          </ButtonStyled>
          <ButtonStyled size='small' type='submit'>
            {t('flowBuilder.configs.btn-add')}
          </ButtonStyled>
        </Stack>
      </FooterConfig>
    </FormAddEndpoint>
  );
};
