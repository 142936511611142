import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ContainerStatusQueues } from './styled';
import { MenuItem, Stack } from '@mui/material';
import { motion } from 'framer-motion';

import {
  dataDashboardCharts,
  selectedQueueToSearchCharts,
} from '@/store/DashboardPage';
import { DataPickerComponent } from '@/components/FormComponents/DataPickerComponent';
import { ButtonStyled } from '@/components/FormComponents/ButtonComponent';
import { IoSearchOutline } from 'react-icons/io5';
import { format, sub } from 'date-fns';
import { SelectComponent } from '@/components/FormComponents/SelectInput';
import { useLocalStorage } from '@/hooks/useLocalStorage';

import smLogoVoice from '@/assets/imgs/icon-sm-voicce.svg';

const StatusQueue = ({ handleSearchQueue, loadingDashboardPage }) => {
  const { saveLocalStorage, getLocalStorage } = useLocalStorage();
  const [dashboardDataCharts] = useAtom(dataDashboardCharts);
  const { queuesDashboard } = dashboardDataCharts;

  const [count, setCount] = useState(0);

  const renderQueueDashboard = () => {
    const queueStorePreferences = getLocalStorage('@p');
    if (queueStorePreferences) {
      const hasQueueSelected = queuesDashboard.filter(
        (item) => item.fila === queueStorePreferences
      );
      if (hasQueueSelected.length === 0) {
        return queuesDashboard[0].fila.toString();
      }

      return queueStorePreferences;
    }
    const removeClosedQueue = queuesDashboard.filter(
      (item) => item.estado !== 'FECHADO'
    );

    if (removeClosedQueue.length > 0) {
      return removeClosedQueue[0].fila.toString();
    }
    return queuesDashboard[0].fila.toString();
  };

  const { t } = useTranslation();

  const [queueSelected, setQueueSelected] = useAtom(
    selectedQueueToSearchCharts
  );

  const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);

  const [fieldInitialDate, setFieldInitialDate] = useState(
    sub(new Date(), {
      days: 1,
    })
  );
  const [fieldEndedDate, setFieldEndedDate] = useState(new Date());

  const searchDataChartsToday = () => {
    const filterQueueSelected = queuesDashboard.filter(
      (queue) => queue.fila === Number(queueSelected)
    );

    const dateToday = new Date();
    const dataFormatToday = format(dateToday, 'yyyy-MM-dd');

    if (filterQueueSelected.length > 0) {
      const dataSearch = {
        queue: Number(queueSelected),
        first_date: dataFormatToday,
        last_date: dataFormatToday,
      };

      saveLocalStorage('@p', queueSelected);
      !showAdvancedSearch && handleSearchQueue(dataSearch);
      return;
    }

    const dataSearch = {
      queue: queuesDashboard[0].fila,
      first_date: dataFormatToday,
      last_date: dataFormatToday,
    };

    setQueueSelected(dataSearch.queue.toString());
    saveLocalStorage('@p', dataSearch.queue);
    !showAdvancedSearch && handleSearchQueue(dataSearch);
  };

  useEffect(() => {
    if (queuesDashboard.length > 0 && count === 0) {
      setCount(1);
      setQueueSelected(renderQueueDashboard());
      saveLocalStorage('@p', renderQueueDashboard());
    }
  }, [count, queuesDashboard, queueSelected]);

  useEffect(() => {
    if (!queueSelected) {
      !showAdvancedSearch && setQueueSelected('0');
    }
    if (queueSelected !== '0') {
      return searchDataChartsToday();
    }
  }, [queueSelected, queuesDashboard, showAdvancedSearch]);

  const selectAdvancedSearch = () => {
    setShowAdvancedSearch(!showAdvancedSearch);
  };

  const handlerAdvanced = () => {
    const dataInit = format(fieldInitialDate, 'yyyy-MM-dd');
    const dataEnd = format(fieldEndedDate, 'yyyy-MM-dd');

    const dataSearch = {
      queue: Number(queueSelected),
      first_date: dataInit,
      last_date: dataEnd,
    };
    saveLocalStorage('@p', queueSelected);
    handleSearchQueue(dataSearch);
  };

  const checkDatesSelected = fieldInitialDate <= fieldEndedDate;

  return (
    <ContainerStatusQueues initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <Stack direction={'column'} spacing={1}>
        <Stack direction={'row'} spacing={2}>
          <motion.div
            initial={{ width: '100%' }}
            animate={{ width: showAdvancedSearch ? '49.5%' : '100%' }}
          >
            {!loadingDashboardPage && (
              <SelectComponent
                labelSelect={t('dashboardPage.label-selectQueue')}
                value={queueSelected}
                onChange={(event) => setQueueSelected(event.target.value)}
                className='selector-queue-dash'
              >
                <MenuItem value={'0'} dense disabled>
                  {t('dashboardPage.label-selectQueue')}
                </MenuItem>
                {queuesDashboard.length > 0 &&
                  queuesDashboard.map((queue, i) => (
                    <MenuItem key={i} value={queue.fila} dense>
                      <Stack
                        direction={'row'}
                        alignItems={'center'}
                        gap={'8px'}
                      >
                        <img
                          src={smLogoVoice}
                          alt='voicce'
                          width={'20px'}
                          height={'20px'}
                        />
                        <span>
                          <strong>{queue.fila}</strong> - {queue.campanha}
                        </span>
                      </Stack>
                    </MenuItem>
                  ))}
              </SelectComponent>
            )}
          </motion.div>
          {showAdvancedSearch && (
            <motion.div
              initial={{ opacity: 0, width: '50%' }}
              animate={{ opacity: 1 }}
            >
              <Stack direction={'row'} spacing={1}>
                <DataPickerComponent
                  label={t('dashboardPage.label-dateInit')}
                  value={fieldInitialDate}
                  onChange={setFieldInitialDate}
                  disableFuture
                  slotProps={{
                    textField: {
                      error: !checkDatesSelected,
                    },
                  }}
                />
                <DataPickerComponent
                  label={t('dashboardPage.label-dateEnd')}
                  value={fieldEndedDate}
                  disableFuture
                  onChange={setFieldEndedDate}
                  slotProps={{
                    textField: {
                      error: !checkDatesSelected,
                    },
                  }}
                />
                <ButtonStyled
                  variantstyle='primary'
                  size={'small'}
                  onClick={handlerAdvanced}
                  title={t('dashboardPage.label-openSearch')}
                  disabled={!checkDatesSelected}
                >
                  <IoSearchOutline size={'1rem'} />
                </ButtonStyled>
              </Stack>
            </motion.div>
          )}
        </Stack>
        <div className='content__advanced_search'>
          <motion.button
            whileTap={{ scale: 0.995 }}
            onClick={selectAdvancedSearch}
            type='button'
            title={
              showAdvancedSearch
                ? t('dashboardPage.label-closeSearch')
                : t('dashboardPage.label-openSearch')
            }
          >
            {showAdvancedSearch
              ? t('dashboardPage.label-closeSearch')
              : t('dashboardPage.label-openSearch')}
          </motion.button>
        </div>
      </Stack>
    </ContainerStatusQueues>
  );
};

export { StatusQueue };
