import { useMemo, useState, useEffect } from 'react';
import { useTable, useSortBy, usePagination } from 'react-table';
import { useTranslation } from 'react-i18next';

import {
  ContainerTable,
  ContainerTd,
  ContentTr,
  PaginationContent,
  TableContent,
} from './styled';

import { NoDataTable } from './NoDataTable';

import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

export const TablePagination = ({
  dataColumns,
  dataTable,
  textNoData = '',
  rowsPerPageDisplay,
  pagination = true,
  selectedRow = null,
  hasSelected = null,
}) => {
  const { t } = useTranslation();
  const [dataTableState, setDataTableState] = useState([]);

  useEffect(() => {
    if (dataTable) setDataTableState(dataTable);
  }, [dataTable]);

  const columns = useMemo(() => dataColumns, [dataColumns]);
  const data = useMemo(() => dataTableState, [dataTableState]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    setPageSize,
    state,
    gotoPage,
    pageCount,
  } = useTable(
    {
      columns: columns,
      data: data,
    },
    useSortBy,
    usePagination
  );

  const { pageIndex } = state;

  useEffect(() => {
    if (rowsPerPageDisplay) setPageSize(rowsPerPageDisplay);
  }, [rowsPerPageDisplay]);

  return (
    <ContainerTable>
      <div className='content_table_grid'>
        <TableContent {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup, i) => (
              <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, i) => (
                  <th
                    key={i}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    title={column.render('Header')}
                  >
                    {column.Header !== 'Edit' ? (
                      <div className='content_th'>
                        {column.render('Header')}
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <ArrowDropDownIcon />
                          ) : (
                            <ArrowDropUpIcon />
                          )
                        ) : (
                          <ArrowDropUpIcon style={{ color: 'transparent' }} />
                        )}
                      </div>
                    ) : (
                      <div>{column.render('Header')}</div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.length > 0 ? (
              page.map((row, j) => {
                prepareRow(row);

                return (
                  <ContentTr
                    key={j}
                    {...row.getRowProps()}
                    // className={row.id !== selectedRow ? 'unselected-row' : ''}
                    className={
                      hasSelected
                        ? row.id === selectedRow
                          ? 'selected-row'
                          : 'unselected-row'
                        : ''
                    }
                  >
                    {row.cells.map((cell, index) => {
                      return (
                        <ContainerTd
                          key={index}
                          {...cell.getCellProps()}
                          data-label={cell.column.Header}
                        >
                          <span>{cell.render('Cell')}</span>
                        </ContainerTd>
                      );
                    })}
                  </ContentTr>
                );
              })
            ) : (
              <NoDataTable textContent={textNoData} />
            )}
          </tbody>
        </TableContent>
      </div>

      {pagination && (
        <PaginationContent>
          <button
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
            aria-label='back to first page'
            tabIndex={0}
            type='button'
          >
            <KeyboardDoubleArrowLeftIcon />
          </button>
          <button
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
            aria-label='back to previous page'
            tabIndex={0}
            type='button'
          >
            <KeyboardArrowLeftIcon />
          </button>

          <span>
            {t('components.tablePagination.Page')}{' '}
            <strong>
              {pageIndex + 1} {t('components.tablePagination.of')}{' '}
              {pageOptions.length}
            </strong>
          </span>

          <button
            onClick={() => nextPage()}
            disabled={!canNextPage}
            aria-label='go to next page'
            tabIndex={0}
            type='button'
          >
            <KeyboardArrowRightIcon />
          </button>
          <button
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
            aria-label='go to last page'
            tabIndex={0}
            type='button'
          >
            <KeyboardDoubleArrowRightIcon />
          </button>
        </PaginationContent>
      )}
    </ContainerTable>
  );
};
