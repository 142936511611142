import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';
import InputMask from 'react-input-mask';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { MenuItem } from '@mui/material';
import { addMinutes } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

import { useSchedules } from '@hooks/Agents/useSchedules';

import { token } from '@store/AuthenticatorStore';

// import { TimePickerComponent } from '@/components/FormComponents/TimePickerComponent-OLD';
import { ButtonStyled } from '@components/FormComponents/ButtonComponent';
import { InputComponent } from '@components/FormComponents/InputComponent';
import { SelectComponent } from '@components/FormComponents/SelectInput';
import { errorToast } from '@components/FormComponents/Toast';
import { removeMaskPhone } from '@/utils/phoneMakTransform';

import { ContainerEditSchedule, FormEditSchedule } from './styled';
import { AiOutlineSchedule } from 'react-icons/ai';
import { dataScheduleSelected } from '@/store/AgentsStore';

export const EditSchedule = ({ closeModal }) => {
  const [dataRow, setDataSelected] = useAtom(dataScheduleSelected);
  const [currentToken] = useAtom(token);
  const { t } = useTranslation();
  const { mutateUpdateSchedule } = useSchedules();

  const [initTimeEditField, setInitTimeEditField] = useState(
    addMinutes(new Date(), 2)
  );
  const [endTimeEditField, setEndTimeEditField] = useState(
    addMinutes(new Date(), 62)
  );

  const [phoneEditField, setPhoneEditField] = useState('');
  const [queueEditField, setQueueEditField] = useState('0');
  const [listEditField, setListEditField] = useState('0');
  const [campaignEditField, setCampaignEditField] = useState('0');

  const [errorFieldPhone, setErrorFieldPhone] = useState(false);
  const [errorFieldQueue, setErrorFieldQueue] = useState(false);
  const [errorFieldList, setErrorFieldList] = useState(false);
  const [errorFieldCampaign, setErrorFieldCampaign] = useState(false);

  /*   const convertDateToEdit = (dateToEdit) => {
    if (dateToEdit) {
      const separateDate = dateToEdit.split(':');
      return new Date(
        0,
        0,
        0,
        separateDate[0],
        separateDate[1],
        separateDate[2]
      );
    } else {
      return '-';
    }
  }; */

  useEffect(() => {
    return () => setDataSelected(null);
  }, []);

  useEffect(() => {
    if (dataRow) {
      setPhoneEditField(dataRow.phone);
      /*  setQueueEditField(
        dataRow.queue ? dataRow.queue.toString() : dataRow.queue
      ); */
      /*       setListEditField('0');
      setCampaignEditField('0'); */
    }
  }, []);

  useEffect(() => {
    errorFieldPhone && phoneEditField.length > 0 && setErrorFieldPhone(false);
    errorFieldQueue && queueEditField !== '0' && setErrorFieldQueue(false);
    errorFieldList && listEditField !== '0' && setErrorFieldList(false);
    errorFieldCampaign &&
      campaignEditField !== '0' &&
      setErrorFieldCampaign(false);
  }, [
    errorFieldPhone,
    phoneEditField,
    errorFieldQueue,
    queueEditField,
    errorFieldList,
    listEditField,
    errorFieldCampaign,
    campaignEditField,
  ]);

  const hasErrorForm =
    errorFieldPhone || errorFieldQueue || errorFieldList || errorFieldCampaign;

  const handleSubmitEditSchedule = (e) => {
    e.preventDefault();

    if (phoneEditField.length === 0) {
      setErrorFieldPhone(true);
    }

    if (queueEditField === '0') {
      setErrorFieldQueue(true);
    }

    if (listEditField === '0') {
      setErrorFieldList(true);
    }

    if (campaignEditField === '0') {
      setErrorFieldCampaign(true);
    }

    if (
      phoneEditField.length === 0 ||
      queueEditField === '0' ||
      listEditField === '0' ||
      campaignEditField === '0'
    ) {
      return errorToast(
        t('agents.scheduleAgent.EditSchedule.msgErrorEditSchedule')
      );
    }

    mutateUpdateSchedule({ token: currentToken });
    console.log({
      initTimeEditField,
      endTimeEditField,
      phone: removeMaskPhone(phoneEditField),
      queueEditField,
      listEditField,
      campaignEditField,
    });
    closeModal();
  };

  return (
    <ContainerEditSchedule>
      <div className='titleNewSchedule'>
        <AiOutlineSchedule />
        <h2>{t('agents.scheduleAgent.EditSchedule.titleEditSchedule')}</h2>
      </div>
      <FormEditSchedule onSubmit={handleSubmitEditSchedule}>
        <div className='mainForm'>
          <div className='timers'>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={ptBR}
            >
              {/* <TimePickerComponent
                initializedDate={new Date()}
                labelPicker={t('agents.scheduleAgent.EditSchedule.initialTime')}
                selectedStatedTime={setInitTimeEditField}
              />
            </LocalizationProvider>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={ptBR}
            >
              <TimePickerComponent
                initializedDate={new Date()}
                labelPicker={t('agents.scheduleAgent.EditSchedule.finalTime')}
                selectedStatedTime={setEndTimeEditField}
              /> */}
            </LocalizationProvider>
          </div>

          <InputMask
            mask='(999) 999 9999'
            value={phoneEditField}
            onChange={(e) => setPhoneEditField(e.target.value)}
          >
            {() => (
              <InputComponent
                label={t('agents.scheduleAgent.newSchedule.phoneSchedule')}
                helperText={
                  errorFieldPhone
                    ? t(
                        'Agents.scheduleAgent.newSchedule.defaultOptionPhoneSchedule'
                      )
                    : ' '
                }
                error={errorFieldPhone}
              />
            )}
          </InputMask>

          <SelectComponent
            defaultValue='0'
            labelSelect={t('agents.scheduleAgent.EditSchedule.queue')}
            value={queueEditField}
            onChange={(event) => setQueueEditField(event.target.value)}
            helperText={errorFieldQueue ? ' ' : ' '}
            error={errorFieldQueue}
          >
            <MenuItem value={'0'}>Select queue to add</MenuItem>
          </SelectComponent>

          <SelectComponent
            defaultValue='0'
            labelSelect={t('agents.scheduleAgent.EditSchedule.list')}
            value={listEditField}
            onChange={(event) => setListEditField(event.target.value)}
            helperText={errorFieldList ? ' ' : ' '}
            error={errorFieldList}
          >
            <MenuItem value={'0'}>Select list to add</MenuItem>
          </SelectComponent>

          <SelectComponent
            defaultValue='0'
            labelSelect={t('agents.scheduleAgent.EditSchedule.campaign')}
            value={campaignEditField}
            onChange={(event) => setCampaignEditField(event.target.value)}
            helperText={errorFieldCampaign ? ' ' : ' '}
            error={errorFieldCampaign}
          >
            <MenuItem value={'0'}>Select campaign to add</MenuItem>
          </SelectComponent>
        </div>
        <div className='footerNewSchedule'>
          <ButtonStyled variantstyle={'secondary'} onClick={closeModal}>
            {t('agents.scheduleAgent.newSchedule.btnCancelNewSchedule')}
          </ButtonStyled>
          <ButtonStyled type='submit' disabled={hasErrorForm}>
            {t('agents.scheduleAgent.newSchedule.btnConfirmNewSchedule')}
          </ButtonStyled>
        </div>
      </FormEditSchedule>
    </ContainerEditSchedule>
  );
};
