import { useEffect } from 'react';
import { Stack } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { useUsersAgents } from '@hooks/UserAgents/useUsersAgents';
import { useAtom } from 'jotai';
import { ButtonStyled } from '@components/FormComponents/ButtonComponent';
import { BackDropCircularLoading } from '@components/layout/LoadingComponent';
import { errorToast, successToast } from '@components/FormComponents/Toast';
import { TitleComponent } from '@components/layout/TitleComponent';
import { ContainerRemoveUserAgent, FormRemoveUserAgent } from './styled';
import { dataSelectedUser } from '@/store/UsersAgentsStore/userAgentsStore';

export const RemoveUserAgent = ({ closeDialog }) => {
  const { t } = useTranslation();
  const { mutateRemoveUserAgent, loadingRemoveUserAgent } = useUsersAgents();
  const [dataRow, setDataSelected] = useAtom(dataSelectedUser);

  useEffect(() => {
    return () => setDataSelected(null);
  }, []);

  const handleRemoveUserAgent = (e) => {
    e.preventDefault();
    mutateRemoveUserAgent('dataUser', {
      onSuccess: () => {
        closeDialog();
        successToast(t('usersAgentsList.removeUser.messages.successMessage'));
        setDataSelected(null);
      },
      onError: () => {
        errorToast(t('usersAgentsList.removeUser.messages.errorMessage'));
        closeDialog();
        setDataSelected(null);
      },
    });
  };

  return (
    <ContainerRemoveUserAgent>
      {loadingRemoveUserAgent && <BackDropCircularLoading />}

      <TitleComponent
        textTitle={t('usersAgentsList.removeUser.title-remove')}
        iconTitle='userAgents'
      />

      <FormRemoveUserAgent onSubmit={handleRemoveUserAgent}>
        <p>
          {t('usersAgentsList.removeUser.removeSentence')}
          <strong> {dataRow && dataRow.email.toLowerCase()} </strong>?
        </p>
        <Stack direction={`row`} gap={1} justifyContent={'flex-end'}>
          <ButtonStyled variantstyle={'secondary'} onClick={closeDialog}>
            {t('usersAgentsList.removeUser.btn-cancel')}
          </ButtonStyled>
          <ButtonStyled type='submit'>
            {t('usersAgentsList.removeUser.btn-confirm')}
          </ButtonStyled>
        </Stack>
      </FormRemoveUserAgent>
    </ContainerRemoveUserAgent>
  );
};
