import { useAtom } from 'jotai';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { ContainerComplete } from './styled';
import {
  dataErrorsInCsv,
  dataMailing,
  dataPreviewImport,
  fileDataImport,
} from '@/store/LoadList';
import { handleToDialingStore } from '@/store/NavigationDashboard';
import { useStageControlLists } from '../../hooks/useStageControlLists';
import { ButtonStyled } from '@/components/FormComponents/ButtonComponent';

export const CompleteImport = () => {
  const { t } = useTranslation();
  const { setProgress, toStageProgress, setCurrentStateMailing } =
    useStageControlLists();

  const [, redirectToDialingStore] = useAtom(handleToDialingStore);

  const [, setFile] = useAtom(fileDataImport);
  const [, setPreviewList] = useAtom(dataPreviewImport);
  const [, setErrosInCsv] = useAtom(dataErrorsInCsv);
  const [mailingData] = useAtom(dataMailing);

  const handleNewImport = () => {
    setPreviewList(null);
    setFile(null);
    setErrosInCsv([]);
    setProgress(0);
    toStageProgress(0);
    setCurrentStateMailing(0);
  };

  const handleToDialing = () => {
    setErrosInCsv([]);
    setPreviewList(null);
    setFile(null);
    setProgress(0);
    toStageProgress(0);
    setCurrentStateMailing(0);
    redirectToDialingStore();
  };

  return (
    <ContainerComplete initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <motion.div
        className='success-checkmark'
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
      >
        <div className='check-icon'>
          <span className='icon-line line-tip'></span>
          <span className='icon-line line-long'></span>
          <div className='icon-circle'></div>
          <div className='icon-fix'></div>
        </div>
      </motion.div>
      <span>
        Mailing <strong>{mailingData.nameFile}</strong>{' '}
        {t('listLoader.concluded.firstSentence')}{' '}
        <strong>{mailingData.campaign}.</strong>
      </span>
      <span>{t('listLoader.concluded.successSentence')}</span>
      <div className='new_import'>
        <ButtonStyled
          variantstyle={'primary'}
          onClick={handleNewImport}
          size={'small'}
        >
          <span>{t('listLoader.concluded.newImportSentence')}</span>
        </ButtonStyled>
        <ButtonStyled
          variantstyle={'secondary'}
          onClick={handleToDialing}
          size={'small'}
        >
          <span>{t('listLoader.concluded.followMailing')}</span>
        </ButtonStyled>
      </div>
    </ContainerComplete>
  );
};
