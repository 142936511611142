import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ContainerProfile = styled(motion.div)`
  width: 100%;
  height: 100%;
  padding: 2rem;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 1rem;
  overflow: scroll;

  @media (max-height: 550px) {
    height: 86%;
  }
`;

export const TitleUsers = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;

  h2 {
    font-weight: 700;
    font-size: 0.75rem;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: ${(props) => props.theme.colors.typography.baseText};
  }

  svg {
    color: ${(props) => props.theme.colors.typography.baseTitle};
  }
`;

export const DataUser = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 1.2rem;
  min-height: 200px;

  button {
    width: 140px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      line-height: 0;
    }
    svg {
      color: #fff;
    }
  }
`;

export const FormProfile = styled.form`
  width: 100%;
  height: calc(100% - 270px);
  min-height: 220px;
  display: grid;
  grid-template-rows: 40px 1fr 28px;
  gap: 1rem;

  .footerProfiles {
    width: 100%;
    min-height: 30px;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    gap: 1rem;
    button {
      max-width: 200px;
    }
  }
`;
