import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ContainerTableSchedules = styled(motion.div)`
  width: 100%;
  height: 100%;
  min-height: 200px;
  user-select: none;

  .cellDaysWeek {
    display: flex;
    justify-content: flex-start;
    gap: 8px;
    cursor: pointer;

    span {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: ${(props) => props.theme.colors.primary};
      color: ${(props) => props.theme.colors.typography.activeTextSidebar};
      font-weight: 500;
      border-radius: 3px;
    }
  }

  table {
    tbody {
      tr {
        height: 42px;
        td {
          z-index: 1;
          padding: 0 0.8rem;
          width: 20%;

          &:nth-child(4) {
            min-width: 100px;
          }

          &:nth-child(5) {
            min-width: 100px;
          }

          &:nth-child(6) {
            min-width: 300px;
          }

          &:last-child {
            width: 60px;
            .btn-actions {
              padding: 0;
              height: 42px;
              max-width: 60px;
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 0.4rem;
              button {
                min-width: 8px;
                padding: 4px;
              }
            }
          }
        }
      }
    }
  }
`;
