import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ContainerSidebarAgents = styled(motion.div)`
  height: 100%;
  display: grid;
  grid-template-rows: 10fr, 40px;
  box-shadow: 3px 4px 4px rgba(0, 0, 0, 0.25);
  background: ${(props) => props.theme.colors.containers.baseContainer};
  position: relative;
  z-index: 1000;
  width: 300px;

  @media (max-width: 1020px) {
    position: absolute;
    left: 0;
    bottom: 0;
    height: calc(100% - 60px);
  }
`;

export const ContentSidebarAgents = styled(motion.div)`
  position: relative;
  height: 100%;
  z-index: 100;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  overflow-y: scroll;

  @media (max-width: 1020px) {
    position: absolute;
    left: 0;
    bottom: 0;
    height: calc(100%);
  }
`;

export const HeaderSidebarAgents = styled(motion.div)`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  height: 100px;
  margin-bottom: 30px;
  margin-top: 10px;
  user-select: none;

  @media (max-width: 1020px) {
    height: 80px;
    margin-top: 0px;
    margin-bottom: 10px;
    img {
      width: 100%;
      max-width: 180px;
      height: auto;
    }
  }
`;

export const DividerXAgent = styled(motion.div)`
  margin: 10px 0;
  width: 80%;
  height: 1px;
  background: ${(props) => props.theme.colors.typography.baseText};
  opacity: 0.3;
`;

export const ContainerInformationItem = styled.div`
  width: 100%;
  height: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 3rem;
  gap: 2rem;

  @media (max-width: 1600px) {
    padding-top: 1rem;
  }
`;

export const ContainerButtonDisconnect = styled.div`
  flex: 1;
  max-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.4rem;
    background-color: ${(props) =>
      props.theme.colors.agentColors[props.statusList]} !important;

    svg {
      padding-bottom: 1px;
      font-size: 1.2rem;
    }
  }
`;
