import { useCallback, useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import {
  ContainerHeaderFlow,
  MenuItemHistory,
  MenuItemPublished,
  SelectNav,
} from './styled';
import { useAtom } from 'jotai';
import {
  confirmationChangeFlow,
  hasModalOpen,
  isSavedFlow,
  listTemplates,
  stageFlow,
  templateSelected,
  variablesList,
} from '@/store/FlowBuilder';
import { Box, Stack } from '@mui/system';
import { BsBoxes } from 'react-icons/bs';
import {
  Badge,
  Button,
  CircularProgress,
  MenuItem,
  Tooltip,
  Zoom,
} from '@mui/material';
import { renderDate } from '@/utils/formatsDateAndTime';
import { ButtonStyled } from '@/components/FormComponents/ButtonComponent';
import { IoMdSave } from 'react-icons/io';
import { useSaveFlow } from '@/hooks/FlowBuilder/useSaveFlow';
import { ConfirmationComponent } from '@/components/layout/ConfirmationComponent';
import { useHandleFlow } from '@/hooks/FlowBuilder/useHandlerFlow';
import { errorToast, successToast } from '@/components/FormComponents/Toast';
import { useTranslation } from 'react-i18next';
import { shortNameRender } from '../../../utils/shortNameRender';
import { HiOutlineInformationCircle } from 'react-icons/hi';
import { ConfirmationToSave } from '../../NavigationFlow/ConfirmationToSave';
import { usePermissionsFlow } from '@/hooks/FlowBuilder/usePermissionsFlow';
import { user } from '@/store/AuthenticatorStore';
import { DetailFlow } from './detailFlow';
import { useUser } from '@/hooks/UserAgents/useUser';

export const HeaderFlow = ({ dataNodes }) => {
  const { t } = useTranslation();
  const { permissionsEdit, permissionsCreate, isSysAdmin } =
    usePermissionsFlow();
  const {
    mutateSetStatusTemplate,
    mutateImportFlow,
    loadingSetStatus,
    loadingImportFlow,
  } = useHandleFlow();
  const { handleRenderNodes, loadingHandleFlow, checkNullableNodes } =
    useSaveFlow(dataNodes);

  const { renderLastUpdatedUserFlow } = useUser();
  const [, setListVariables] = useAtom(variablesList);
  const [isSaved, setIsSaved] = useAtom(isSavedFlow);
  const [template] = useAtom(templateSelected);
  const [listTemplate] = useAtom(listTemplates);
  const [confirmationChangedFlow, setConfirmationChangedFlow] = useAtom(
    confirmationChangeFlow
  );

  const [currentUser] = useAtom(user);

  const [confirmationDialogSaveFlow, setConfirmationDialogSaveFlow] =
    useState(false);
  const [countSave, setCountSave] = useState(0);
  const [idRenderHistoric, setIdRenderHistoric] = useState('');
  const [statusFlow, setStatusFlow] = useState(false);
  const [nameFlow, setNameFlow] = useState('Name');
  const [historicFlow, setHistoricFlow] = useState('0');
  const [idFlow, setIdFlow] = useState('#');

  const [, setModalIsOpened] = useAtom(hasModalOpen);

  const [, setCurrentStage] = useAtom(stageFlow);

  useEffect(() => {
    if (template) {
      setTimeout(() => {
        setIsSaved(true);
      }, 200);
    }

    return () => {
      clearTimeout();
    };
  }, [template]);

  const checkStatus = (dataStatus) => {
    if (dataStatus) {
      return 'published';
    }
    return 'publish';
  };

  useEffect(() => {
    if (Object.entries(template).length > 0) {
      setNameFlow(template.nameIVR);
      setIdFlow(template._id);
      setStatusFlow(checkStatus(template.published ?? false));
    }
  }, [template]);

  const renderListTemplateByName = ((listTemplate, nameRendered) => {
    const separateNameTemplate = listTemplate
      .filter((item) => item.nameIVR === nameRendered)
      .sort((a, b) => b.lastUpdate - a.lastUpdate);

    return separateNameTemplate;
  })(listTemplate, template.nameIVR);

  useEffect(() => {
    if (countSave === 0) {
      const listOptions = renderListTemplateByName
        .filter((item) => item.published)
        .map((item) => item.relatedObjectId)
        .toString();

      if (listOptions === '') {
        const currentListOptions = renderListTemplateByName.map(
          (item) => item.relatedObjectId
        );

        return currentListOptions.length > 0
          ? setHistoricFlow(currentListOptions[0].toString())
          : setHistoricFlow('0');
      }

      return setHistoricFlow(
        renderListTemplateByName
          .filter((item) => item.published)
          .map((item) => item.relatedObjectId)
          .toString()
      );
    } else {
      setHistoricFlow(template._id);
    }
  }, [listTemplate, countSave]);

  useEffect(() => {
    return () => {
      setNameFlow('');
      setIdFlow('');
      setStatusFlow(false);
    };
  }, []);

  function removeDuplicates(arr) {
    const seenIds = new Set();
    const seenNames = new Set();
    return arr.filter((item) => {
      if (seenIds.has(item.id) || seenNames.has(item.name)) {
        return false;
      } else {
        seenIds.add(item.id);
        seenNames.add(item.name);
        return true;
      }
    });
  }

  // RENDER FLOW ON CHANGE SELECT HIST
  const uploadFlow = useCallback(
    (dataJson) => {
      const { nodes, edges, flow } = dataJson;

      let changeSelectedToFalse = nodes.map((node) => {
        return { ...node, selected: false };
      });

      let changeEdgeSelectedToFalse = edges.map((edge) => {
        return { ...edge, selected: false };
      });

      const filterPropsVariables = Object.entries(flow)
        .filter(
          ([, value]) =>
            value.handler === 'dialplanVariables' && value.listVariables
        )
        .map(([, value]) => value.listVariables)
        .flat();

      // ADDED CONDITIONAL VARS
      const filterConditionsVariablesUtterance = Object.entries(flow)
        .filter(([, value]) => value.handler === 'recVoz' && value.utterance)
        .map(([, value]) => value.utterance);

      const filterConditionsVariablesSlot = Object.entries(flow)
        .filter(([, value]) => value.handler === 'recVoz' && value.slot)
        .map(([, value]) => value.slot);

      const dataConditionsVars = [
        ...filterConditionsVariablesUtterance,
        ...filterConditionsVariablesSlot,
      ];

      const mapCondVars = dataConditionsVars.map((item) => {
        const importVar = {
          id: item,
          name: item,
          value: item,
          parent: '',
          referNode: false,
        };

        return importVar;
      });

      const uniqueList = removeDuplicates([
        ...mapCondVars,
        ...filterPropsVariables,
      ]);

      const updatedListVars = uniqueList;

      updatedListVars.forEach((item) => {
        const exists = filterPropsVariables.some(
          (varItem) => varItem.id === item.id
        );

        if (!exists) {
          filterPropsVariables.push(item);
        }
      });

      setListVariables(updatedListVars);

      const { setNodes, setEdges } = dataNodes;
      setNodes(changeSelectedToFalse);
      setEdges(changeEdgeSelectedToFalse);
      successToast(t('flowBuilder.flow.msg-flow-import'));
      setIsSaved(true);
    },
    [dataNodes, setIsSaved]
  );

  const processSaveOnChangeFlow = () => {
    mutateImportFlow(idRenderHistoric, {
      onSuccess: (data) => {
        setHistoricFlow(data._id);
        renderLastUpdatedUserFlow(data.user_id);
        uploadFlow(data);
        setIsSaved(true);
        setConfirmationChangedFlow(false);
      },
    });
  };

  const validationHistoricFlow = (value) => {
    setIdRenderHistoric(value);
    if (!isSaved) {
      return setConfirmationChangedFlow(true);
    }

    mutateImportFlow(value, {
      onSuccess: (data) => {
        renderLastUpdatedUserFlow(data.user_id);
        setHistoricFlow(data._id);
        uploadFlow(data);
        setIsSaved(true);
      },
    });
  };

  const handlePublished = () => {
    const checkPersona = dataNodes.nodes.filter(
      (item) => item.type === 'dialplanVariables'
    );

    if (checkPersona.length === 0) {
      errorToast(t('flowBuilder.flow.msg-error-noPersona'));
      return;
    }

    const dataStatus = {
      id: template._id,
      nameIVR: template.nameIVR,
      last_user_published: currentUser.client_id,
    };

    mutateSetStatusTemplate(dataStatus, {
      onSuccess: () => {
        mutateImportFlow(template._id);
        successToast(t('flowBuilder.flow.msg-flow-published'));
        setIsSaved(true);
      },
    });
  };

  const confirmationSaveFlow = () => {
    const isNoNull = checkNullableNodes();
    if (!isNoNull) {
      return;
    }
    setModalIsOpened(true);
    dataNodes.removeSelectedOnSaveTemplate();
    return setConfirmationDialogSaveFlow(true);
  };

  const closeConfirmationSaveFlow = () => {
    setModalIsOpened(false);
    setConfirmationDialogSaveFlow(false);
  };

  const handleSave = (description, isSameCustomer) => {
    const save = handleRenderNodes(description);
    if (save && !isSameCustomer) {
      setCurrentStage(0);
      setCountSave((countSave) => countSave + 1);
      return;
    }
    return setCountSave((countSave) => countSave + 1);
  };

  const permissionsUser = permissionsCreate || permissionsEdit || isSysAdmin;

  return (
    <ContainerHeaderFlow
      status={statusFlow}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      {loadingHandleFlow ||
        loadingSetStatus ||
        (loadingImportFlow && <div className='disabled-cursor'></div>)}
      <Stack
        direction={'row'}
        alignItems={'center'}
        spacing={1}
        className='container-title'
      >
        <BsBoxes />
        <div className='contentTitleHeader'>
          <h4>{shortNameRender(nameFlow, 50)}</h4>
          <span>{idFlow}</span>
        </div>

        <Tooltip TransitionComponent={Zoom} arrow title={<DetailFlow />}>
          <Button>
            <HiOutlineInformationCircle />
          </Button>
        </Tooltip>
      </Stack>

      <div className='content-status-flow'>
        <span>
          {statusFlow === 'published'
            ? t('flowBuilder.flow.navigationFlow.status-card-flow-published')
            : t('flowBuilder.flow.navigationFlow.status-card-flow-publish')}
        </span>
      </div>

      <Stack
        direction={'row'}
        alignItems={'center'}
        spacing={1}
        justifyContent={'center'}
      >
        <Box width={'300px'}>
          <SelectNav
            size='small'
            value={historicFlow}
            onChange={(e) => validationHistoricFlow(e.target.value)}
            disabled={
              loadingHandleFlow ||
              loadingSetStatus ||
              loadingImportFlow ||
              !permissionsUser
            }
          >
            <MenuItem value={'0'} dense disabled>
              {t('flowBuilder.flow.navigationFlow.label-historic-flow')}
            </MenuItem>

            {renderListTemplateByName.map((item, i) =>
              item.published ? (
                <MenuItemPublished value={item.relatedObjectId} key={i} dense>
                  <span>{renderDate(item.lastUpdate)} - </span>
                  <span>
                    {shortNameRender(
                      !item.descriptionIVR || item.descriptionIVR === ''
                        ? t(
                            'flowBuilder.flow.navigationFlow.option-no-description'
                          )
                        : item.descriptionIVR,
                      30
                    )}
                  </span>
                </MenuItemPublished>
              ) : (
                <MenuItemHistory value={item.relatedObjectId} key={i} dense>
                  <span>{renderDate(item.lastUpdate)} - </span>
                  <span>
                    {shortNameRender(
                      !item.descriptionIVR || item.descriptionIVR === ''
                        ? t(
                            'flowBuilder.flow.navigationFlow.option-no-description'
                          )
                        : item.descriptionIVR,
                      30
                    )}
                  </span>
                </MenuItemHistory>
              )
            )}
          </SelectNav>
        </Box>
        <div className='btn-current-flow'>
          <Badge
            color='error'
            variant='dot'
            invisible={isSaved}
            badgeContent=''
          >
            <ButtonStyled
              size={'small'}
              variantstyle='success'
              onClick={confirmationSaveFlow}
              disabled={
                isSaved ||
                loadingHandleFlow ||
                loadingSetStatus ||
                loadingImportFlow ||
                !permissionsUser
              }
            >
              {loadingHandleFlow || loadingImportFlow ? (
                <CircularProgress size={20} />
              ) : (
                <IoMdSave size={20} />
              )}
            </ButtonStyled>
          </Badge>
          <ButtonStyled
            size={'small'}
            disabled={
              template.published ||
              !isSaved ||
              dataNodes.edges.length === 0 ||
              dataNodes.nodes.length <= 1 ||
              historicFlow === '0' ||
              historicFlow === '' ||
              loadingHandleFlow ||
              loadingSetStatus ||
              loadingImportFlow ||
              !permissionsUser
            }
            onClick={handlePublished}
          >
            {loadingSetStatus ? (
              <CircularProgress size={18} sx={{ color: '#fff' }} />
            ) : (
              t('flowBuilder.flow.navigationFlow.status-card-flow-publish')
            )}
          </ButtonStyled>
        </div>
      </Stack>

      <ConfirmationComponent
        actionConfirm={processSaveOnChangeFlow}
        close={() => setConfirmationChangedFlow(false)}
        txtBtnSuccess={'OK'}
        messageConfirm={t('flowBuilder.flow.msg-confirmation-exit')}
        open={confirmationChangedFlow}
      />
      <ConfirmationToSave
        actionConfirm={handleSave}
        close={closeConfirmationSaveFlow}
        open={confirmationDialogSaveFlow}
      />
    </ContainerHeaderFlow>
  );
};
