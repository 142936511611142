import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useControlTimeAgent } from '@hooks/Agents/useControlTimeAgent';
import { ButtonStyled } from '@components/FormComponents/ButtonComponent';
import { StopWatchComponent } from '@components/StopWatchComponent';
import { Stack } from '@mui/material';
import { MdPlayCircleFilled, MdOutlinePauseCircleFilled } from 'react-icons/md';

export const PauseStatus = ({
  handleChangePauseOnLoad,
  dataCall,
  setPauseDialog,
}) => {
  const { t } = useTranslation();

  const { manegeStopWatch, removeLocalStatus } = useControlTimeAgent();

  useEffect(() => {
    removeLocalStatus('pauseStatus');
  }, []);

  return (
    <div className='dial_part'>
      <Stack direction={`row`} spacing={2}>
        <ButtonStyled onClick={() => setPauseDialog(true)}>
          <MdOutlinePauseCircleFilled />
          <span>{t('agents.panelAgents.btnChangePause')}</span>
        </ButtonStyled>
        <ButtonStyled onClick={handleChangePauseOnLoad}>
          <MdPlayCircleFilled />
          <span>{t('agents.panelAgents.btnExitPause')}</span>
        </ButtonStyled>
        <StopWatchComponent
          statusQueue={dataCall && dataCall.estado}
          durationStage={manegeStopWatch('@pauseStatus')}
        />
      </Stack>
    </div>
  );
};
