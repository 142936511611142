import { Box, Divider, Stack } from '@mui/material';
import { PanelLineControl } from './styled';
import { motion } from 'framer-motion';
import { FaArrowLeft } from 'react-icons/fa';
import { ButtonStyled } from '@/components/FormComponents/ButtonComponent';
import { MdAdd, MdOutlinePlaylistAdd } from 'react-icons/md';
import { dataSelectedLinesControl, remainingLines } from '@/store/LineControl';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

export const PanelSelectedLine = ({
  controlStageSelectedLine,
  controlMainStage,
}) => {
  const { t } = useTranslation();
  const [remaining] = useAtom(remainingLines);
  const [selected] = useAtom(dataSelectedLinesControl);

  const handleBackMainStage = () => {
    controlMainStage.toStage(0);
  };

  const currentStage = controlStageSelectedLine?.currentStage ?? 0;

  return (
    <PanelLineControl>
      <Stack direction='column' spacing={2} padding={1}>
        <Stack direction='row' spacing={2}>
          <motion.div
            whileTap={{ scale: 0.8 }}
            className='icon_handle_back'
            onClick={handleBackMainStage}
          >
            <FaArrowLeft size='16' />
          </motion.div>
          <div className='itemPanel'>
            <span>{selected?.campanha ?? '-'}</span>
          </div>
        </Stack>
        <Divider />
        <Stack
          direction='row'
          spacing={2}
          alignItems={'center'}
          justifyContent={'space-between'}
          width={'100%'}
        >
          <Box
            className='status_queue'
            display={'flex'}
            gap={0.4}
            alignItems={'center'}
          >
            <span>{selected?.fila ?? '-'} - </span>
            <span>{selected?.contexto_ura ?? '-'}</span>
          </Box>
          <Box
            className='status_queue'
            display={'flex'}
            gap={0.5}
            alignItems={'center'}
          >
            <span>
              {t(
                'dialControl.lineControl.selectedCampaign.panelSelectedLine.totalLines'
              )}
              :
            </span>
            <strong>{selected?.maxLinhasTotal ?? '-'}</strong>
          </Box>
          <Box
            className={
              remaining < 0 ? 'status_queue no_lines_available' : 'status_queue'
            }
            display={'flex'}
            gap={0.5}
            alignItems={'center'}
          >
            <span>
              {t(
                'dialControl.lineControl.selectedCampaign.panelSelectedLine.remainingLines'
              )}
              :
            </span>
            <strong>{remaining}</strong>
          </Box>
        </Stack>
      </Stack>

      <div
        className={
          remaining < 0 && currentStage === 0
            ? 'btn-add-content active-btb'
            : 'btn-add-content'
        }
      >
        {currentStage === 0 ? (
          <ButtonStyled
            onClick={() => controlStageSelectedLine.toStage(1)}
            title='release lines'
          >
            <MdAdd />
            <motion.span
              initial={{ display: 'none', opacity: 0 }}
              animate={{
                display: remaining < 0 ? 'block' : 'none',
                opacity: 1,
              }}
              transition={{ delay: remaining < 0 ? 0.2 : 0 }}
            >
              Liberar Linhas
            </motion.span>
          </ButtonStyled>
        ) : (
          <ButtonStyled variantstyle='secondary' title='Request more lines'>
            <MdOutlinePlaylistAdd />
          </ButtonStyled>
        )}
      </div>
    </PanelLineControl>
  );
};
