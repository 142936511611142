import { ButtonStyled } from '@/components/FormComponents/ButtonComponent';
import { FooterImportMailing } from './styled';
import { useTranslation } from 'react-i18next';

export const FooterImport = ({
  handlePreviousStage = () => {},
  handleNextStage = () => {},
  showNext = true,
  disabledNext = false,
}) => {
  const { t } = useTranslation();
  return (
    <FooterImportMailing>
      <ButtonStyled
        variantstyle={'secondary'}
        onClick={handlePreviousStage}
        size={'small'}
      >
        <span>{t('listLoader.footer.btn-preview')}</span>
      </ButtonStyled>

      {showNext && (
        <ButtonStyled
          type='button'
          onClick={handleNextStage}
          size={'small'}
          disabled={disabledNext}
        >
          <span>{t('listLoader.footer.btn-next')}</span>
        </ButtonStyled>
      )}
    </FooterImportMailing>
  );
};
