export async function getCallHistory() {
  try {
    const data = [
      {
        id: 1,
        agent: '1 Joe Doe',
        start: '00:00:00',
        end: '00:00:00',
        phone: '    00000   00000',
        queue: 300,
        list: 'list name 1',
        campaign: 'name campaign 1',
      },
      {
        id: 2,
        agent: '2 Joe Doe',
        start: '00:00:00',
        end: '00:00:00',
        phone: '1111111111',
        queue: 301,
        list: 'list name 2',
        campaign: 'name campaign 2',
      },
      {
        id: 3,
        agent: '3 Joe Doe',
        start: '00:00:00',
        end: '00:00:00',
        phone: '2222222222',
        queue: 302,
        list: 'list name 3',
        campaign: 'name campaign 3',
      },
      {
        id: 4,
        agent: '4 Joe Doe',
        start: '00:00:00',
        end: '00:00:00',
        phone: '1234567890',
        queue: 400,
        list: 'list name 4',
        campaign: 'name campaign 4',
      },
      {
        id: 5,
        agent: '5 Joe Doe',
        start: '00:00:00',
        end: '00:00:00',
        phone: '0987654321',
        queue: 500,
        list: 'list name 5',
        campaign: 'name campaign 5',
      },
      {
        id: 6,
        agent: '6 Joe Doe',
        start: '00:00:00',
        end: '00:00:00',
        phone: '0000000000',
        queue: 333,
        list: 'list name 6',
        campaign: 'name campaign 6',
      },
      {
        id: 7,
        agent: '7 Joe Doe',
        start: '00:00:00',
        end: '00:00:00',
        phone: '0000000000',
        queue: 900,
        list: 'list name 7',
        campaign: 'name campaign 7',
      },
      {
        id: 8,
        agent: '8 Joe Doe',
        start: '00:00:00',
        end: '00:00:00',
        phone: '0000000000',
        queue: 300,
        list: 'list name 8',
        campaign: 'name campaign 8',
      },
      {
        id: 9,
        agent: '9 8 Joe Doe',
        start: '00:00:00',
        end: '00:00:00',
        phone: '0000000000',
        queue: 300,
        list: 'list name 9',
        campaign: 'name campaign 9',
      },
      {
        id: 10,
        agent: '10 Joe Doe',
        start: '00:00:00',
        end: '00:00:00',
        phone: '0000000000',
        queue: 300,
        list: 'list name 10',
        campaign: 'name campaign 10',
      },
      {
        id: 11,
        agent: '1 Joe Doe',
        start: '00:00:00',
        end: '00:00:00',
        phone: '    00000   00000',
        queue: 300,
        list: 'list name 1',
        campaign: 'name campaign 1',
      },
      {
        id: 12,
        agent: '2 Joe Doe',
        start: '00:00:00',
        end: '00:00:00',
        phone: '1111111111',
        queue: 301,
        list: 'list name 2',
        campaign: 'name campaign 2',
      },
      {
        id: 13,
        agent: '3 Joe Doe',
        start: '00:00:00',
        end: '00:00:00',
        phone: '2222222222',
        queue: 302,
        list: 'list name 3',
        campaign: 'name campaign 3',
      },
      {
        id: 14,
        agent: '4 Joe Doe',
        start: '00:00:00',
        end: '00:00:00',
        phone: '1234567890',
        queue: 400,
        list: 'list name 4',
        campaign: 'name campaign 4',
      },
      {
        id: 15,
        agent: '5 Joe Doe',
        start: '00:00:00',
        end: '00:00:00',
        phone: '0987654321',
        queue: 500,
        list: 'list name 5',
        campaign: 'name campaign 5',
      },
      {
        id: 16,
        agent: '6 Joe Doe',
        start: '00:00:00',
        end: '00:00:00',
        phone: '0000000000',
        queue: 333,
        list: 'list name 6',
        campaign: 'name campaign 6',
      },
      {
        id: 17,
        agent: '7 Joe Doe',
        start: '00:00:00',
        end: '00:00:00',
        phone: '0000000000',
        queue: 900,
        list: 'list name 7',
        campaign: 'name campaign 7',
      },
      {
        id: 18,
        agent: '8 Joe Doe',
        start: '00:00:00',
        end: '00:00:00',
        phone: '0000000000',
        queue: 300,
        list: 'list name 8',
        campaign: 'name campaign 8',
      },
      {
        id: 19,
        agent: '9 8 Joe Doe',
        start: '00:00:00',
        end: '00:00:00',
        phone: '0000000000',
        queue: 300,
        list: 'list name 9',
        campaign: 'name campaign 9',
      },
      {
        id: 20,
        agent: '10 Joe Doe',
        start: '00:00:00',
        end: '00:00:00',
        phone: '0000000000',
        queue: 300,
        list: 'list name 10',
        campaign: 'name campaign 10',
      },
    ];

    return data;
  } catch (err) {
    throw new Error(err?.response?.data?.message);
  }
}
