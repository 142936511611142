import { IoIosCloseCircleOutline } from 'react-icons/io';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const ContainerItemConditional = styled.div`
  background: ${(props) => props.theme.colors.bases.primaryLight};
  box-shadow: 6px 4px 5px -3px rgba(0, 0, 0, 0.24);

  width: 100%;
  padding: 4px;
  border-radius: 3px;
  display: grid;
  grid-template-columns: 1fr 40px;
  .description {
    span {
      font-size: 0.8rem;
      color: ${(props) => props.theme.colors.typography.textHeaderTable};
      font-weight: 600;
    }
  }

  .panel-item {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 4px;

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: transparent;
      border: none;

      svg {
        font-size: 0.9rem;
        color: ${(props) => props.theme.colors.bases.baseWhite};
      }
    }
  }
`;

export const ItemConditional = ({
  typeCondition,
  valueCondition,
  handleRemoveCondition,
}) => {
  const { t } = useTranslation();
  return (
    <ContainerItemConditional>
      <div className='description'>
        <span>
          {typeCondition} {valueCondition}
        </span>
      </div>
      <div className='panel-item'>
        <motion.button
          onClick={handleRemoveCondition}
          whileTap={{ scale: 0.85 }}
          type='button'
          title={t('flowBuilder.flow.nodes.conditions.menu.title-btn-remove')}
        >
          <IoIosCloseCircleOutline size={'1.05rem'} />
        </motion.button>
      </div>
    </ContainerItemConditional>
  );
};
