import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ContainerSaveTemplateMailing = styled.div`
  width: 100%;
  height: 100%;
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;

  button {
    line-height: 1.4 !important;

    span {
      padding: 2px 0px 0px 0px !important;
    }
  }
`;

export const ContentConfirmation = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  p {
    font-size: 0.9rem;
    color: ${(props) => props.theme.colors.typography.baseText}AA;
  }
`;

export const DataConfirmationContent = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const BtnSaveConfirmation = styled(motion.form)`
  all: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  svg {
    font-size: 1.1rem;
    color: ${(props) => props.theme.colors.defaultColors.white};
  }
  background-color: ${(props) => props.theme.colors.success};
  padding: 6px;
  border-radius: 3px;
`;

export const FormSaveConfirmation = styled(motion.form)``;
