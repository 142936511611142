import { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  dataSelectedSecurityProfiles,
  stateRenderSecurityProfiles,
} from '@store/UsersAgentsStore/securityProfiles';
import { FormEditSecurityProfile } from './styled';
import { ControlInput } from '@components/FormComponents/InputComponent';
import { ButtonStyled } from '@components/FormComponents/ButtonComponent';
import { TitleComponent } from '@components/layout/TitleComponent';

import { ListPermissionsEdit } from './ListPermissionsEdit';
import {
  dataOptionsClients,
  stateRenderUsers,
} from '@/store/UsersAgentsStore/userAgentsStore';
import { PiWarningCircle } from 'react-icons/pi';

export const EditSecurityProfile = ({ origin = false }) => {
  const { t } = useTranslation();
  const [, setCurrentStateSecurity] = useAtom(stateRenderSecurityProfiles);
  const [selectSecurity] = useAtom(dataSelectedSecurityProfiles);
  const [, setStateRenderUsersAgents] = useAtom(stateRenderUsers);
  const [dataScreensList, setDataScreensList] = useState({});
  const [dataClients] = useAtom(dataOptionsClients);

  const renderClientFilter = (idClient) => {
    const filterClient = dataClients
      .filter((item) => item.id === idClient)
      .map((item) => item.commercial_name)
      .toString();
    return filterClient ?? '-';
  };

  useEffect(() => {
    if (selectSecurity) {
      setDataScreensList(selectSecurity);
    }
  }, []);

  useEffect(() => {
    return () => {
      setCurrentStateSecurity(0);
    };
  }, []);

  const securityProfileSchema = yup.object().shape({
    nameSecurityProfile: yup.string().required(),
    clienteId: yup.string().required(),
  });

  const {
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(securityProfileSchema),
    defaultValues: {
      nameSecurityProfile: selectSecurity.name,
      clienteId: renderClientFilter(selectSecurity.client_id),
    },
  });

  const handleBackStage = () => {
    if (origin === 'userAgents') {
      return setStateRenderUsersAgents(0);
    }
    return setCurrentStateSecurity(0);
  };

  const checkPermissions = dataScreensList?.permissions?.length;

  return (
    <FormEditSecurityProfile initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <div className='headerForm'>
        <TitleComponent
          textTitle={t('securityProfile.editSecurityProfiles.title')}
          iconTitle='securityProfile'
        />

        <ControlInput
          control={control}
          nameControl={'nameSecurityProfile'}
          label={t('securityProfile.addSecurityProfiles.label-name')}
          placeholder={t('securityProfile.addSecurityProfiles.label-name')}
          error={Boolean(errors.nameSecurityProfile)}
          InputProps={{
            readOnly: true,
          }}
        />

        <ControlInput
          control={control}
          nameControl={'clienteId'}
          label={'Client'}
          InputProps={{
            readOnly: true,
          }}
        />
      </div>

      <div className='mainForm'>
        <TitleComponent
          textTitle={t(
            'securityProfile.addSecurityProfiles.subTitleSecurityProfile'
          )}
        />
        {!checkPermissions ?? checkPermissions === 0 ? (
          <div className='noPermissionList'>
            <PiWarningCircle />
            <p>
              {t(
                'securityProfile.homeSecurityProfiles.messages.msg-noDataPermissions'
              )}
            </p>
          </div>
        ) : (
          <ListPermissionsEdit
            dataScreensList={dataScreensList}
            setDataScreensList={setDataScreensList}
          />
        )}
      </div>
      <div className='footerForm'>
        <ButtonStyled
          variantstyle={'secondary'}
          onClick={handleBackStage}
          size={'small'}
        >
          {t('securityProfile.editSecurityProfiles.btn-cancel')}
        </ButtonStyled>
      </div>
    </FormEditSecurityProfile>
  );
};
