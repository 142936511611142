import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import { useAgents } from '@hooks/Agents/useAgents';

import {
  currentStateAgentsDial,
  dataCallInformation,
  isLoadingComponent,
  pauseDialog,
} from '@store/AgentsStore';
import { user } from '@store/AuthenticatorStore';

import { DialogComponent } from '@components/DialogComponent';
import { ButtonStyled } from '@components/FormComponents/ButtonComponent';

import { PauseStatusDialog } from './PauseStatusDialog';
import { FreeStatus } from './FreeStatus';
import { PauseStatus } from './PauseStatus';
import { CallStatus } from './CallStatus';

import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import { AiOutlineSchedule } from 'react-icons/ai';
import { MdList, MdPhoneInTalk } from 'react-icons/md';

import { ContainerPanelControl } from './styled';

export const PanelControlAgents = () => {
  const { t } = useTranslation();
  const [currentUser] = useAtom(user);
  const [dataCall] = useAtom(dataCallInformation);
  const [currentStateAgents, setCurrentStateAgents] = useAtom(
    currentStateAgentsDial
  );
  const {
    mutateOpenAudioCall,
    handleChangePause,
    mutateCloseCurrentAudio,
    mutateGetAgents,
    mutateSetPause,
  } = useAgents();
  const [isLoading, setIsLoadingAgent] = useAtom(isLoadingComponent);
  const [openPauseDialog, setPauseDialog] = useAtom(pauseDialog);

  const changeCurrentState = (state) => {
    setCurrentStateAgents(state);
  };

  const handleChangePauseOnLoad = () => {
    setIsLoadingAgent(true);
    setCurrentStateAgents(null);
    mutateGetAgents(
      {
        extension: currentUser.extension,
        queue: currentUser.queue,
      },
      {
        onSuccess: (data) => {
          if (data.estado === 'PAUSA') {
            setIsLoadingAgent(true);

            mutateSetPause({
              extension: currentUser.extension,
              queue: currentUser.queue,
              status: 'livre',
            });
          }
        },
      }
    );
  };

  const handleOpenAudio = () => {
    setIsLoadingAgent(true);
    setCurrentStateAgents(null);
    mutateOpenAudioCall({
      extension: currentUser.extension,
      agentLogin: dataCall.agente_login,
      username: dataCall.nome,
    });
  };

  const handlePause = (pauseType) => {
    setIsLoadingAgent(true);
    handleChangePause(pauseType);
  };

  const handleChangeSoundCall = () => {
    setIsLoadingAgent(true);
    mutateCloseCurrentAudio(
      {
        extension: currentUser.extension,
      },
      {
        onSuccess: () => {
          new Promise((resolve) => {
            setTimeout(() => {
              resolve(
                mutateGetAgents({
                  extension: currentUser.extension,
                  queue: currentUser.queue,
                })
              );
            }, 500);
          });
        },
      }
    );
  };

  const renderButtonsPanel = () => {
    if (dataCall.estado === 'EMLIG' || dataCall.estado === 'POSLIG') {
      return <div className='panel_part'></div>;
    } else if (dataCall.estado === 'AGLIG') {
      return (
        <div className='panel_part'>
          <div className='btn_out_dialer'>
            <ButtonStyled onClick={handleChangeSoundCall}>
              <VolumeOffIcon />
              <span>{t('agents.panelAgents.btnOutOfDialer')}</span>
            </ButtonStyled>
          </div>
        </div>
      );
    } else {
      return (
        <div className='panel_part'>
          <ButtonStyled
            onClick={() => changeCurrentState(0)}
            isActive={currentStateAgents === 0 ? 1 : 0}
            className={
              currentStateAgents === 0
                ? 'active_button opacity_button'
                : 'opacity_button'
            }
          >
            <AiOutlineSchedule />
            <span>{t('agents.panelAgents.btnSchedule')}</span>
          </ButtonStyled>
          <ButtonStyled
            onClick={() => changeCurrentState(1)}
            isActive={currentStateAgents === 1 ? 1 : 0}
            className={
              currentStateAgents === 1
                ? 'active_button opacity_button'
                : 'opacity_button'
            }
          >
            <MdList />
            <span>{t('agents.panelAgents.btnCallHistory')}</span>
          </ButtonStyled>
          <ButtonStyled
            onClick={() => changeCurrentState(2)}
            isActive={currentStateAgents === 2 ? 1 : 0}
            className={
              currentStateAgents === 2
                ? 'active_button opacity_button'
                : 'opacity_button'
            }
          >
            <MdPhoneInTalk />
            <span>{t('agents.panelAgents.btnDial')}</span>
          </ButtonStyled>
        </div>
      );
    }
  };

  const renderDialPanel = () => {
    switch (dataCall.estado) {
      case 'AGLIG':
        return (
          <CallStatus dataCall={dataCall} setPauseDialog={setPauseDialog} />
        );

      case 'EMLIG':
        return (
          <CallStatus dataCall={dataCall} setPauseDialog={setPauseDialog} />
        );

      case 'POSLIG':
        return (
          <CallStatus dataCall={dataCall} setPauseDialog={setPauseDialog} />
        );

      case 'PAUSA':
        return (
          <PauseStatus
            dataCall={dataCall}
            handleChangePauseOnLoad={handleChangePauseOnLoad}
            setPauseDialog={setPauseDialog}
          />
        );

      case 'LIVRE':
        return (
          <FreeStatus
            dataCall={dataCall}
            handleOpenAudio={handleOpenAudio}
            isLoading={isLoading}
            setPauseDialog={setPauseDialog}
          />
        );
    }
  };

  return (
    <ContainerPanelControl>
      <div className='panel_control'>
        {renderButtonsPanel()}
        {renderDialPanel()}
      </div>
      <DialogComponent
        open={openPauseDialog}
        onClose={() => setPauseDialog(false)}
        fullWidth={true}
      >
        <PauseStatusDialog
          closeDialog={() => setPauseDialog(false)}
          handleChangePause={handlePause}
        />
      </DialogComponent>
    </ContainerPanelControl>
  );
};
