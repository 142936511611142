import { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import * as yup from 'yup';
import { Alert, MenuItem, Stack, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ControlSelect } from '@/components/FormComponents/SelectInput';
import { FormEditSchedules } from './styled';
import { ButtonStyled } from '@/components/FormComponents/ButtonComponent';
import { errorToast, successToast } from '@/components/FormComponents/Toast';
import { motion } from 'framer-motion';

import { ControlTimePicker } from '@/components/FormComponents/TimePicker';
import useFormControl from '@/hooks/useFormControl';
import { WeekDaysSelector } from '@/components/FormComponents/WeekDaysSelector';
import { useSchedulesList } from '@/hooks/dialControl/Schedules/useSchedulesList';
import { BackDropCircularLoading } from '@/components/layout/LoadingComponent';
import { selectedScheduleQueue } from '@/store/ScheduleStore/SchedulerList';
import { DescriptionInformation } from '@/components/layout/DescriptionInformation';
import { dataDispositionsDialing } from '@/store/DialingStore';
import { useDispositionsDialing } from '@/hooks/Dispositions/useDispositionsDialing';
import { ControlInput } from '@/components/FormComponents/InputComponent';
import { format } from 'date-fns';
import { TitleComponent } from '@/components/layout/TitleComponent';
import { DragTransferListComponent } from '../../components/DragTransferList';

export const EditSchedule = ({ controlStage }) => {
  const { t } = useTranslation();
  const { mutateEditSchedule, loadingEditSchedule, loadingDeleteSchedule } =
    useSchedulesList();

  const { loadingDataDisposition } = useDispositionsDialing();

  const [listsDispositions] = useAtom(dataDispositionsDialing);
  const [schedulesSelected, setScheduleSelected] = useAtom(
    selectedScheduleQueue
  );
  const [selectedDays, setSelectedDays] = useState([]);
  const [dataCodList, setDataCodList] = useState([]);
  const [stageRenderEdit, setStageRenderEdit] = useState(0);

  function removeDuplicateIds(arr) {
    const ids = new Set();
    return arr.filter((item) => {
      if (ids.has(item.cod_lig)) {
        return false;
      } else {
        ids.add(item.cod_lig);
        return true;
      }
    });
  }

  const renderUsedDispositions = (usedList) => {
    if (usedList) {
      const used = [];
      for (let i = 1; i <= 10; i++) {
        const codDisposition = usedList[`cod_lig${i}`];
        const filterDisposition = listsDispositions.filter(
          (disposition) => disposition.cod_lig === codDisposition
        )[0];
        filterDisposition && used.push(filterDisposition);
      }
      return removeDuplicateIds(used);
    }
    return [];
  };

  useEffect(() => {
    const usedDispositions = renderUsedDispositions(schedulesSelected);
    setDataCodList(
      usedDispositions.map(
        (itemAvailable) =>
          `${itemAvailable.cod_lig} - ${itemAvailable.descricao}`
      )
    );
  }, [listsDispositions, stageRenderEdit]);

  const renderTxt = (word) => {
    return t(`dialControl.schedulesList.editSchedule.${word}`);
  };

  const createNewListSchema = yup.object().shape({
    queue: yup.string().required(),
    list: yup.string().required(),
    timeInit: yup.date(),
    timeEnd: yup.date(),
    positionList: yup.string().required(),
    positionQueue: yup.string().required(),
  });

  const { handleSubmit, control, errors, reset } =
    useFormControl(createNewListSchema);

  const renderDaysSchedule = (selectedDays) => {
    if (selectedDays) {
      setSelectedDays([selectedDays]);
    }
  };

  useEffect(() => {
    renderDaysSchedule(schedulesSelected.dia_semana);
    return () => setScheduleSelected(null);
  }, []);

  const handleBack = () => {
    controlStage.toStage(1);
    setScheduleSelected(null);
  };

  const renderDispositions = (listDispositions, position) => {
    if (listDispositions[position]) {
      const [disposition] = listDispositions[position].split('-');
      return disposition ? disposition.trim() : null;
    }
    return null;
  };

  const formatTime = (timestamp) => {
    return format(new Date(timestamp), 'HH:mm:00');
  };

  const handleUpdate = (data) => {
    if (selectedDays.length === 0) {
      return errorToast('Selecione pelo menos um dia da semana!');
    }

    const dataUpdate = {
      id: schedulesSelected.id,
      executado_dt: schedulesSelected.executado_dt,
      encerrado_dt: schedulesSelected.encerrado_dt,
      dia_semana: selectedDays.length > 0 && selectedDays[0],
      horario: formatTime(data.timeInit),
      dia_semana_termino: 1,
      horario_termino: formatTime(data.timeEnd),
      fila: schedulesSelected.fila,
      posicao: Number(data.positionQueue),
      id_tabela_lista: schedulesSelected.id_tabela_lista,
      cod_lig1: renderDispositions(dataCodList, 0),
      cod_lig2: renderDispositions(dataCodList, 1),
      cod_lig3: renderDispositions(dataCodList, 2),
      cod_lig4: renderDispositions(dataCodList, 3),
      cod_lig5: renderDispositions(dataCodList, 4),
      cod_lig6: renderDispositions(dataCodList, 5),
      cod_lig7: renderDispositions(dataCodList, 6),
      cod_lig8: renderDispositions(dataCodList, 7),
      cod_lig9: renderDispositions(dataCodList, 8),
      cod_lig10: renderDispositions(dataCodList, 9),
      fone_pos: Number(data.positionList),
    };

    mutateEditSchedule(dataUpdate, {
      onSuccess: () => {
        reset();
        controlStage.toStage(1);
        successToast(renderTxt('msg-success'));
      },
    });
  };

  const renderAvailableDispositions = (listDispositions, usedDispositions) => {
    const usedIds = new Set(usedDispositions.map((item) => item.cod_lig));
    const available = listDispositions.filter(
      (item) => !usedIds.has(item.cod_lig)
    );
    return removeDuplicateIds(available);
  };

  const isLoading =
    loadingEditSchedule || loadingDeleteSchedule || loadingDataDisposition;

  return (
    <FormEditSchedules
      onSubmit={handleSubmit(handleUpdate)}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      {isLoading && <BackDropCircularLoading />}

      <div className='header'>
        <nav>
          <Stack direction={'row'} spacing={1}>
            <ButtonStyled
              size='small'
              onClick={() => setStageRenderEdit(0)}
              variantstyle={stageRenderEdit !== 0 ? 'active' : 'primary'}
            >
              {renderTxt('btn-nav-details')}
            </ButtonStyled>
            <ButtonStyled
              size='small'
              onClick={() => setStageRenderEdit(1)}
              variantstyle={stageRenderEdit !== 1 ? 'active' : 'primary'}
            >
              {renderTxt('btn-nav-dispositions')}
            </ButtonStyled>
          </Stack>
        </nav>
      </div>
      <div className='main'>
        {stageRenderEdit === 0 && (
          <Stack
            direction={'column'}
            spacing={4}
            width={'100%'}
            style={{ paddingTop: '2rem' }}
          >
            <ControlInput
              control={control}
              nameControl={'queue'}
              label={renderTxt('label-queue')}
              error={Boolean(errors.queue)}
              defaultValue={schedulesSelected?.fila}
              InputProps={{
                readOnly: true,
              }}
            />

            <ControlInput
              control={control}
              nameControl={'list'}
              label={renderTxt('label-list')}
              defaultValue={schedulesSelected?.id_tabela_lista}
              error={Boolean(errors.list)}
              InputProps={{
                readOnly: true,
              }}
            />

            <Stack direction={'row'} spacing={2} width={'100%'}>
              <ControlTimePicker
                control={control}
                nameControl={'timeInit'}
                defaultValue={new Date(schedulesSelected?.horario)}
                label={renderTxt('label-init')}
              />

              <ControlTimePicker
                control={control}
                nameControl={'timeEnd'}
                defaultValue={new Date(schedulesSelected?.horario_termino)}
                label={renderTxt('label-concluded')}
              />
            </Stack>

            <Stack direction={'row'} spacing={2} width={'100%'}>
              <ControlSelect
                control={control}
                nameControl={'positionList'}
                label={renderTxt('label-position-list')}
                error={Boolean(errors.positionList)}
                defaultValue={schedulesSelected?.fone_pos}
              >
                {new Array(10).fill(null).map((_, i) => (
                  <MenuItem value={i + 1} key={i + 1}>
                    {i + 1}
                  </MenuItem>
                ))}
              </ControlSelect>

              <ControlSelect
                control={control}
                nameControl={'positionQueue'}
                label={renderTxt('label-position-queue')}
                error={Boolean(errors.positionQueue)}
                defaultValue={schedulesSelected?.posicao}
              >
                {new Array(10).fill(null).map((_, i) => (
                  <MenuItem value={i + 1} key={i + 1}>
                    {i + 1}
                  </MenuItem>
                ))}
              </ControlSelect>
            </Stack>

            <WeekDaysSelector
              errorSelectedDays={false}
              selectedDays={selectedDays}
              setSelectedDays={setSelectedDays}
              hasEditorScheduler
              currentDay={schedulesSelected?.dia_semana}
            />

            <Stack spacing={2}>
              <TitleComponent textTitle={renderTxt('btn-nav-dispositions')} />

              <Tooltip title='Ir para disposições' arrow>
                <div
                  className='content-dispositions'
                  onClick={() => setStageRenderEdit(1)}
                >
                  <div className='item-disposition'>
                    <span>01º</span>
                    <span>{dataCodList[0] ?? '-'}</span>
                  </div>
                  <div className='item-disposition'>
                    <span>02º</span>
                    <span>{dataCodList[1] ?? '-'}</span>
                  </div>
                  <div className='item-disposition'>
                    <span>03º</span>
                    <span>{dataCodList[2] ?? '-'}</span>
                  </div>
                  <div className='item-disposition'>
                    <span>04º</span>
                    <span>{dataCodList[3] ?? '-'}</span>
                  </div>
                  <div className='item-disposition'>
                    <span>05º</span>
                    <span>{dataCodList[4] ?? '-'}</span>
                  </div>
                  <div className='item-disposition'>
                    <span>06º</span>
                    <span>{dataCodList[5] ?? '-'}</span>
                  </div>
                  <div className='item-disposition'>
                    <span>07º</span>
                    <span>{dataCodList[6] ?? '-'}</span>
                  </div>
                  <div className='item-disposition'>
                    <span>08º</span>
                    <span>{dataCodList[7] ?? '-'}</span>
                  </div>
                  <div className='item-disposition'>
                    <span>09º</span>
                    <span>{dataCodList[8] ?? '-'}</span>
                  </div>
                  <div className='item-disposition'>
                    <span>10º</span>
                    <span>{dataCodList[9] ?? '-'}</span>
                  </div>
                </div>
              </Tooltip>
            </Stack>
          </Stack>
        )}

        {stageRenderEdit === 1 && (
          <Stack
            direction={'column'}
            spacing={3}
            width={'100%'}
            style={{ paddingTop: '2rem' }}
          >
            <DescriptionInformation
              textDescription={renderTxt('descriptions-edit-dispositions')}
            />

            <motion.div
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: dataCodList.length === 0 ? 1 : 0, y: 0 }}
            >
              <Alert severity='error'>
                {renderTxt('msg-erro-no-dispositions')}
              </Alert>
            </motion.div>

            {listsDispositions.length > 0 && (
              <DragTransferListComponent
                codeList={renderAvailableDispositions(
                  listsDispositions,
                  renderUsedDispositions(schedulesSelected)
                )}
                changeDataCodeList={setDataCodList}
                usedDispositions={dataCodList}
              />
            )}
          </Stack>
        )}
      </div>

      <div className='footer'>
        <Stack direction={'row'} spacing={1}>
          <ButtonStyled
            variantstyle='secondary'
            size='small'
            onClick={handleBack}
          >
            {renderTxt('btn-cancel')}
          </ButtonStyled>
          <ButtonStyled
            type='submit'
            size='small'
            disabled={dataCodList.length === 0}
          >
            {renderTxt('btn-update')}
          </ButtonStyled>
        </Stack>
      </div>
    </FormEditSchedules>
  );
};
