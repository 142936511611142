import { useEffect, useState } from 'react';
import { ContainerCustomFunction } from './styled';
import { Stack } from '@mui/system';

import AceEditor from 'react-ace';
import ace from 'ace-builds/src-noconflict/ace';
import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-noconflict/ext-language_tools';

ace.config.set(
  'basePath',
  'https://cdn.jsdelivr.net/npm/ace-builds@1.4.3/src-noconflict/'
);
ace.config.setModuleUrl(
  'ace/mode/javascript_worker',
  'https://cdn.jsdelivr.net/npm/ace-builds@1.4.3/src-noconflict/worker-javascript.js'
);

export const CustomFunction = ({
  errorFunc,
  setErrorFunc,
  idFilterRender,
  itemPreview,
  listFilter,
  setListFilter,
}) => {
  const [resultText, setResultText] = useState(
    itemPreview ? itemPreview : 'String example'
  );
  const [displayedText, setDisplayedText] = useState(
    itemPreview ? itemPreview : 'String example'
  );
  const [typeCase, setTypeCase] = useState('');

  const [wordReplace, setWordReplace] = useState('');
  const [newWord, setNewWord] = useState('');

  const filterDataRender = listFilter.filter(
    (list) => list.id === idFilterRender
  );

  useEffect(() => {
    if (filterDataRender.length > 0) {
      if (filterDataRender[0].sequence === 1) {
        return setResultText(itemPreview);
      }
      const currentSequence = filterDataRender[0].sequence;

      const sequenceList = listFilter
        .map((list) => list.sequence)
        .filter((seq) => seq !== currentSequence)
        .sort((a, b) => a - b);

      const previousClosest = sequenceList
        .filter((seq) => seq < currentSequence)
        .reduce((prev, curr) => (curr > prev ? curr : prev), -Infinity);

      const getPreviousValue = listFilter.filter(
        (list) => list.sequence === previousClosest
      );

      if (getPreviousValue.length > 0) {
        setResultText(getPreviousValue[0].result);
      }
    }
  }, [filterDataRender, listFilter]);

  useEffect(() => {
    if (filterDataRender.length > 0) {
      //   setWordReplace(filterDataRender[0].data[1] ?? 0);
      //   setNewWord(filterDataRender[0].data[2] ?? 0);
      //   setTypeCase(filterDataRender[0].data[3] ?? 0);
    }
  }, []);

  useEffect(() => {
    if (displayedText === '') {
      if (!errorFunc.includes(idFilterRender)) {
        setErrorFunc((previous) => [...previous, idFilterRender]);
      }
    } else {
      if (errorFunc.includes(idFilterRender)) {
        setErrorFunc((previous) =>
          previous.filter((error) => error !== idFilterRender)
        );
      }
    }
  }, [displayedText, errorFunc, idFilterRender]);

  const rendeCaseSensitive = (typeCase, dataChangeCase) => {
    switch (typeCase) {
      case 'uppercase':
        return dataChangeCase.toUpperCase();

      case 'lowercase':
        return dataChangeCase.toLowerCase();

      case 'capitalize':
        return dataChangeCase
          .split(' ')
          .map(
            (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
          )
          .join(' ');

      default:
        return dataChangeCase;
    }
  };

  const changeCaseSensitive = (typeCaseSelected) => {
    if (typeCaseSelected === typeCase) {
      return setTypeCase('');
    }
    setTypeCase(typeCaseSelected);
  };

  useEffect(() => {
    const output = resultText.replaceAll(wordReplace, newWord);
    setDisplayedText(rendeCaseSensitive(typeCase, output));

    const updatedList = listFilter.map((list) => {
      if (list.id === idFilterRender) {
        return {
          ...list,
          data: ['custom', '', '', ''],
          result: rendeCaseSensitive(typeCase, output),
        };
      }
      return list;
    });
    setListFilter(updatedList);
  }, [resultText, wordReplace, newWord, typeCase]);

  const hasError = errorFunc.includes(idFilterRender) ? 1 : 0;

  const [jsValue, updateJsValue] = useState('');

  return (
    <ContainerCustomFunction errorString={hasError}>
      <div className='input-content'>
        <span>Input:</span>
        <span>
          <strong>{resultText}</strong>
        </span>
      </div>
      <Stack direction={'row'}>
        <AceEditor
          mode='javascript'
          theme={'github'}
          width='100%'
          height={'240px'}
          name='custom_editor'
          fontSize={14}
          showPrintMargin={true}
          showGutter={true}
          highlightActiveLine={true}
          wrapEnabled={true}
          setOptions={{
            useWorker: false,
            enableBasicAutocompletion: false,
            enableLiveAutocompletion: false,
            enableSnippets: false,
            showLineNumbers: false,
          }}
          onChange={(e) => {
            updateJsValue(e);
          }}
          value={jsValue}
        />
      </Stack>
      <div className='result-content'>
        <span>Output:</span>
        <span>
          <strong>{displayedText}</strong>
        </span>
      </div>
    </ContainerCustomFunction>
  );
};
