import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ContainerCallHistory = styled(motion.div)`
  width: 100%;
  height: auto;
  display: grid;
  grid-template-rows: auto 1fr 50px;
  gap: 2rem;
`;

export const PanelSchedule = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 0.5rem;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    padding: 0.6rem;

    span {
      line-height: 0;
    }
  }
`;

export const ContentTable = styled.div`
  width: 100%;
  height: 58vh;
  user-select: none;

  overflow-x: auto;

  @media (max-width: 1620px) {
    height: calc(48vh);
  }

  @media (max-width: 1440px) {
    height: calc(48vh);
  }

  @media (max-width: 1100px) {
    height: calc(48vh);
  }
`;

export const FooterCall = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 0.5rem;
`;
