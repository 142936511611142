import styled from 'styled-components';

export const AgentsContent = styled.div`
  .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 0.4rem;

    h2 {
      font-weight: 700;
      font-size: 0.75rem;
      line-height: 14px;
      letter-spacing: 0.1em;
      text-transform: capitalize;

      color: ${(props) => props.theme.colors.typography.baseText};
    }

    svg {
      font-size: 1rem;
      color: ${(props) => props.theme.colors.primary};
    }
  }
`;
