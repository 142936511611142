import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useControlTimeAgent } from '@hooks/Agents/useControlTimeAgent';
import { ButtonStyled } from '@components/FormComponents/ButtonComponent';
import { StopWatchComponent } from '@components/StopWatchComponent';
import { MdPlayCircleFilled } from 'react-icons/md';

export const CallStatus = ({ setPauseDialog, dataCall }) => {
  const { t } = useTranslation();
  const { manegeStopWatch } = useControlTimeAgent();

  const renderTime = () => {
    switch (dataCall.estado) {
      case 'AGLIG':
        return manegeStopWatch('@availableStatus');
      case 'EMLIG':
        return manegeStopWatch('@inCallStatus');
      case 'POSLIG':
        return manegeStopWatch('@postCallStatus');
    }
  };

  return (
    <div className='dial_part'>
      <Stack direction={`row`} spacing={2}>
        <ButtonStyled onClick={() => setPauseDialog(true)}>
          <MdPlayCircleFilled />
          <span>{t('agents.panelAgents.btnStartPause')}</span>
        </ButtonStyled>

        <StopWatchComponent
          statusQueue={dataCall && dataCall.estado}
          durationStage={renderTime()}
        />
      </Stack>
    </div>
  );
};
