import styled from 'styled-components';

export const ContentVariables = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 0.9rem;

  h4 {
    font-size: 0.7rem;
    font-weight: 400;
    text-transform: uppercase;
    color: ${(props) => props.theme.colors.typography.baseText};
  }

  .warning-description {
    svg {
      color: ${(props) => props.theme.colors.secondary};
    }
    span {
      font-size: 0.7rem;
      font-weight: 400;
      color: ${(props) => props.theme.colors.typography.baseText};
      font-style: italic;
    }
  }

  div {
    button {
      min-width: 32px;
      max-height: 40px;
      padding: 0;
      svg {
        font-size: 1.1rem;
      }
    }
  }
`;
